import erlClient from './';
import {
  sortOrderTypes,
  sortableColsIds,
} from 'pages/MyAppointments/constants/my-appointments.constants';

export const getMyAppointmentsEnd = async (page, sortParams, searchParams) => {
  const { sortableCol, sortOrder } = sortParams || {};
  const { selectedSlKey, selectedTerminalKey, containerNumberInputValue } =
    searchParams || {};

  let sorting = '';

  if (sortableCol === sortableColsIds.shift) {
    sorting = `&order_by=${
      sortOrder === sortOrderTypes.asc ? '-' : ''
    }slot__shift,${
      sortOrder === sortOrderTypes.asc ? '-' : ''
    }slot__shift_date`;
  } else if (sortableCol === sortableColsIds.datetime) {
    sorting = `&order_by=${
      sortOrder === sortOrderTypes.asc ? '-' : ''
    }slot__terminal_date,${
      sortOrder === sortOrderTypes.asc ? '-' : ''
    }slot__start_time`;
  } else {
    sorting = sortableCol
      ? `&order_by=${sortOrder === sortOrderTypes.asc ? '-' : ''}${sortableCol}`
      : '';
  }

  let search = '';
  if (selectedSlKey && selectedTerminalKey) {
    search = `&shipping_line=${selectedSlKey}&terminal=${selectedTerminalKey}`;
  } else if (selectedSlKey && !selectedTerminalKey) {
    search = `&shipping_line=${selectedSlKey}`;
  } else if (!selectedSlKey && selectedTerminalKey) {
    search = `&terminal=${selectedTerminalKey}`;
  } else {
    search = '';
  }

  if (containerNumberInputValue) {
    search = `&container_number=${containerNumberInputValue}`;
  }

  const path = `/core/api/v1/appointments/my_appointments${
    page ? `?page=${page}` : ''
  }${sorting}${search}`;

  const res = await erlClient.get(path);
  return res.data;
};
