import React from 'react';
import PropTypes from 'prop-types';
import './StopTheClockFeedbackMsg.style.css';

const StopTheClockFeedbackMsg = ({ stopTheClockFeedbackMsg }) => {
  return (
    <div className="stop-the-clock-feedback">
      <div className="stop-the-clock-feedback__content">
        {stopTheClockFeedbackMsg}
      </div>
    </div>
  );
};

StopTheClockFeedbackMsg.propTypes = {
  stopTheClockFeedbackMsg: PropTypes.string.isRequired,
};

export default StopTheClockFeedbackMsg;
