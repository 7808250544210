import React, { useContext, createContext, useState } from 'react';
import { Alert, Snackbar, Grow } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { themeProvider } from 'themeProvider';

const SnackbarServiceContext = createContext(Promise.reject);

export const useSnackbar = () => useContext(SnackbarServiceContext);

export const DEFAULT_OPTIONS = {
  showIcon: false,
  severity: 'success',
  text: null,
  html: null,
  autoHideDuration: 5000,
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  TransitionComponent: Grow,
};

export const SnackbarServiceProvider = ({ children }) => {
  const awaitingPromiseRef = React.useRef();

  const [snackbarState, setSnackbarState] = useState(DEFAULT_OPTIONS);

  const openSnackbar = options => {
    setSnackbarState({ ...DEFAULT_OPTIONS, ...options, open: true });
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (
      snackbarState &&
      snackbarState.catchOnCancel &&
      awaitingPromiseRef.current
    ) {
      awaitingPromiseRef.current.reject();
    }
    setSnackbarState(null);
  };

  const {
    showIcon,
    severity,
    html,
    text,
    ...snackbarProps
  } = snackbarState || { open: false };
  return (
    <>
      <SnackbarServiceContext.Provider
        children={children}
        value={openSnackbar}
      />
      <ThemeProvider theme={themeProvider}>
        <Snackbar onClose={handleClose} {...snackbarProps}>
          <Alert icon={showIcon} severity={severity} onClose={handleClose}>
            {html ? html() : text}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </>
  );
};
