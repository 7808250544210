import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'common/hooks/snackbar';
import GridTwoCol from '../../../layouts/Grids/GridTwoCol';
import CheckoutCard from './CheckoutCard';
import BuildPlan from './BuildPlan';
import PaymentForm from './Checkout/PaymentForm';
import MoreInformationsCard from './Checkout/MoreInformationsCard';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { checkout } from 'services/clients/subscription';
import _ from 'lodash';
import { CHECKOUT_STEPS } from './constants';
import { SegmentService } from 'services/helpers/segment';

const processCheckout = async (
  formData,
  selectedVolume,
  selectedPack,
  period,
  couponCode,
  displaySnackbar,
  isNewCard
) => {
  let paymentMethodId = null;
  if (isNewCard) {
    const {
      stripe,
      stripeElements,
      cardName,
      address1,
      address2,
      zipCode,
      city,
      state,
      country,
    } = formData.values;
    const {
      error: paymentMethodError,
      paymentMethod,
    } = await stripe.createPaymentMethod({
      type: 'card',
      card: stripeElements.getElement(CardNumberElement),
      billing_details: {
        name: cardName,
        address: {
          line1: address1,
          line2: address2,
          postal_code: zipCode,
          city,
          state,
          country,
        },
      },
    });
    if (paymentMethodError) {
      displaySnackbar({
        severity: 'error',
        text:
          'Error while processing your credit card. Please enter a different credit card.',
      });
    }
    paymentMethodId = paymentMethod.id;
  } else {
    paymentMethodId = _.get(formData, 'values.paymentMethodId', null);
  }

  const { error } = await checkout(
    selectedPack.id,
    selectedVolume,
    period,
    couponCode,
    paymentMethodId
  );
  if (error) {
    displaySnackbar({
      severity: 'error',
      text:
        'Error while processing your payment. Make sure you have the sufficient funds to process the transaction. Or contact customer success.',
    });
  }
  return error;
};

const CheckoutPage = ({ user, selectedPack, onCheckout, onUpgrade, step }) => {
  const [formData, setFormData] = useState({ isValid: true, values: {} });
  const [couponCode, setCouponCode] = useState(null);
  const [checkoutIsLoading, setCheckoutIsLoading] = useState(false);
  const displaySnackbar = useSnackbar();
  const [period, setPeriod] = useState('monthly');
  const [availableVolumes, setAvailableVolumes] = useState(
    selectedPack.monthlyAvailableVolumes
  );
  const isStarter = selectedPack.id === 'starter';
  const volumeList = Object.keys(availableVolumes).map(v => parseInt(v));
  const packContainerVolume = _.get(user, 'company.pack_container_volume', 0);
  const minAvailableVolume = Math.min(...volumeList);
  const [selectedVolume, setSelectedVolume] = useState(minAvailableVolume);
  const currentVolume =
    packContainerVolume === 0
      ? null
      : packContainerVolume < minAvailableVolume
      ? minAvailableVolume
      : packContainerVolume;

  let preSelectedVolumeIndex = volumeList.indexOf(
    currentVolume || selectedVolume
  );
  preSelectedVolumeIndex =
    preSelectedVolumeIndex === -1
      ? 0
      : currentVolume
      ? preSelectedVolumeIndex + 1
      : preSelectedVolumeIndex;
  const preSelectedVolume =
    preSelectedVolumeIndex >= volumeList.length
      ? null
      : volumeList[preSelectedVolumeIndex];

  useEffect(() => {
    setSelectedVolume(preSelectedVolume);
  }, [selectedPack, period, preSelectedVolume]);

  useEffect(() => {
    if (period === 'monthly') {
      setAvailableVolumes(selectedPack.monthlyAvailableVolumes);
    } else if (period === 'yearly') {
      setAvailableVolumes(selectedPack.yearlyAvailableVolumes);
    }
  }, [period, selectedPack]);

  const handleCheckout = async isFreePlan => {
    const {
      email,
      first_name,
      last_name,
      phone_number,
      pack,
      company_scac,
    } = user;

    const analyticsProperties = {
      email: email,
      firstName: first_name,
      lastName: last_name,
      phone: phone_number,
      plan: pack,
      companyScac: company_scac,
      currentPack: pack,
      selectedPlanName: selectedPack.displayName,
    };

    if (step === CHECKOUT_STEPS.BUILD_PLAN) {
      SegmentService.trackEvent(
        'Proceed to checkout clicked',
        analyticsProperties
      );
    }

    if (step === CHECKOUT_STEPS.PAYMENT_METHOD) {
      SegmentService.trackEvent('Place order clicked', analyticsProperties);
    }

    if (isFreePlan) {
      setCheckoutIsLoading(true);

      const error = await processCheckout(
        {},
        selectedVolume,
        selectedPack,
        period,
        couponCode,
        displaySnackbar,
        false
      );

      setCheckoutIsLoading(false);
      if (!error) {
        onCheckout(isFreePlan);
      }
      return;
    }

    if (step === CHECKOUT_STEPS.PAYMENT_METHOD) {
      setCheckoutIsLoading(true);
      const error = await processCheckout(
        formData,
        selectedVolume,
        selectedPack,
        period,
        couponCode,
        displaySnackbar,
        formData.values.type === 'new_card'
      );

      setCheckoutIsLoading(false);
      if (!error) {
        onCheckout();
      }
    } else {
      onCheckout();
    }
  };

  return (
    <GridTwoCol
      leftContent={
        step === CHECKOUT_STEPS.BUILD_PLAN ? (
          <BuildPlan
            currentVolume={currentVolume}
            availableVolumes={availableVolumes}
            volumeList={volumeList}
            preSelectedVolume={preSelectedVolume}
            selectedPack={selectedPack}
            isCurrentPack={selectedPack.id === _.get(user, 'company.pack')}
            period={period}
            onVolumeChange={volume => setSelectedVolume(volume)}
            isStarter={isStarter}
            onUpgrade={onUpgrade}
          />
        ) : (
          <PaymentForm
            onFormIsValid={(isValid, values) =>
              setFormData({ isValid, values })
            }
          />
        )
      }
      rightContent={
        _.isNil(selectedVolume) ? (
          <MoreInformationsCard
            user={user}
            defaultNbContainers={volumeList.at(-1)}
          />
        ) : (
          <CheckoutCard
            user={user}
            selectedPack={selectedPack}
            selectedVolume={selectedVolume}
            onCheckout={handleCheckout}
            formData={formData}
            step={step}
            onCoupon={couponCode => setCouponCode(couponCode)}
            checkoutIsLoading={checkoutIsLoading}
            onPeriodChange={period => setPeriod(period)}
            period={period}
          />
        )
      }
    />
  );
};

export default CheckoutPage;
