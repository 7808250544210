import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import MyAppointmentsTable from './MyAppointmentsTable';
import MyAppointmentsSearch from './MyAppointmentsSearch';
import CredentialsSpinner from 'pages/Settings/Credentials/CredentialsSpinner';
import MyAppointmentsDetailsModal from './MyAppointmentsDetailsModal';
import {
  tableCols,
  sortOrderTypes,
} from './constants/my-appointments.constants';

const initialSortingPagination = {
  currentPage: 1,
  sortableCol: '',
  sortOrder: '',
};

const initialSlTerminal = {
  selectedSL: null,
  selectedTerminal: null,
};

class MyAppointments extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    importData: PropTypes.func.isRequired,
    getMyAppointments: PropTypes.func.isRequired,
    clearMyAppointments: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    terminals: PropTypes.array,
    refreshToken: PropTypes.bool,
    shippingLines: PropTypes.array,
    myAppointments: PropTypes.object.isRequired,
    isMyAppointmentsLoading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...initialSortingPagination,
      ...initialSlTerminal,
      containerNumberInputValue: '',
      appointmentDetailsModalData: null,
    };
  }

  componentDidMount() {
    const { importData } = this.props;
    importData();
    this.handleGetMyAppointments();
  }

  componentDidUpdate(prevProps) {
    const { refreshToken, importData } = this.props;
    if (prevProps.refreshToken !== refreshToken) {
      importData();
      this.handleGetMyAppointments();
    }
  }

  componentWillUnmount() {
    const { clearMyAppointments } = this.props;
    clearMyAppointments();
  }

  handleGetMyAppointments = () => {
    const { getMyAppointments } = this.props;
    const {
      currentPage,
      sortableCol,
      sortOrder,
      selectedSL,
      selectedTerminal,
      containerNumberInputValue,
    } = this.state;
    const sortParams = { sortableCol, sortOrder };
    const { key: selectedSlKey } = selectedSL || {};
    const { key: selectedTerminalKey } = selectedTerminal || {};
    const searchParams = {
      containerNumberInputValue,
      selectedSlKey,
      selectedTerminalKey,
    };
    getMyAppointments(currentPage, sortParams, searchParams);
  };

  paginationChange = (event, page) => {
    this.setState({ currentPage: page }, () => {
      this.handleGetMyAppointments();
    });
  };

  onHandleSort = (sortableCol, sortOrder) => {
    let newState =
      sortableCol && sortOrder === sortOrderTypes.none
        ? {
            ...initialSortingPagination,
          }
        : {
            sortableCol,
            sortOrder,
            currentPage: initialSortingPagination.currentPage,
          };
    this.setState(newState, () => this.handleGetMyAppointments());
  };

  handleContainerNumberChange = value => {
    this.setState({ containerNumberInputValue: value });
  };

  handleShippingLineChange = (event, sl) => {
    this.setState(
      {
        ...initialSortingPagination,
        containerNumberInputValue: '',
        selectedSL: sl,
      },
      () => {
        this.handleGetMyAppointments();
      }
    );
  };

  handleTerminalChange = (event, terminal) => {
    this.setState(
      {
        ...initialSortingPagination,
        containerNumberInputValue: '',
        selectedTerminal: terminal,
      },
      () => {
        this.handleGetMyAppointments();
      }
    );
  };

  handleSearchClick = () => {
    this.setState(
      {
        ...initialSortingPagination,
        ...initialSlTerminal,
      },
      () => {
        this.handleGetMyAppointments();
      }
    );
  };

  handleRowClick = appointment => {
    this.setState({ appointmentDetailsModalData: appointment });
  };

  handleAppointmentDetailsModalClose = () => {
    this.setState({ appointmentDetailsModalData: null });
  };

  render() {
    const {
      user,
      classes,
      terminals,
      shippingLines,
      myAppointments,
      isMyAppointmentsLoading,
    } = this.props;

    const {
      currentPage,
      sortableCol,
      sortOrder,
      selectedSL,
      selectedTerminal,
      containerNumberInputValue,
      appointmentDetailsModalData,
    } = this.state;

    const { beta_access } = user;
    if (!beta_access) {
      return <Navigate replace to="/" />;
    }

    const { count, results } = myAppointments || {};
    const pageCount = Math.round(count / 10);

    return (
      <>
        {isMyAppointmentsLoading && (
          <CredentialsSpinner customMessage={'Appointments loading...'} />
        )}

        {appointmentDetailsModalData && (
          <MyAppointmentsDetailsModal
            appointmentDetailsModalData={appointmentDetailsModalData}
            onHandleAppointmentDetailsModalClose={
              this.handleAppointmentDetailsModalClose
            }
          />
        )}

        <MyAppointmentsSearch
          classes={classes}
          isLoading={isMyAppointmentsLoading}
          value={containerNumberInputValue}
          terminals={terminals}
          selectedSL={selectedSL}
          shippingLines={shippingLines}
          onHandleSearchClick={this.handleSearchClick}
          onShippingLineChange={this.handleShippingLineChange}
          selectedTerminal={selectedTerminal}
          onHandleTerminalChange={this.handleTerminalChange}
          onHandleContainerNumberChange={this.handleContainerNumberChange}
        />

        <div className="my-appt-wrapper">
          <div className="my-appt">
            <MyAppointmentsTable
              tableCols={tableCols}
              sortOrder={sortOrder}
              sortableCol={sortableCol}
              appointments={results}
              onHandleSort={this.onHandleSort}
              onHandleRowClick={this.handleRowClick}
            />
          </div>

          {results && results.length ? (
            <div className="my-appt-pagination">
              <Pagination
                count={pageCount || 1}
                page={currentPage}
                onChange={this.paginationChange}
              />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default MyAppointments;
