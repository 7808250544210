import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import mediaQueries from '../../mediaqueries';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';
import MobileMenu from './MobileMenu';
import Banners from './Banners';
import { NAV_HEIGHT, NAV_TABS, USER_PROFILE_DROPDOWN } from './utils/config';
import fonts from '../../styles/typography.module.css';
import { useUserContext } from 'common/contexts/UserContext';
import { useNavHeightContext } from 'common/contexts/NavHeightContext';
import { useLocation } from 'react-router-dom';
import { SegmentService } from 'services/helpers/segment';
import TrialDialogs from 'components/TrialDialogs';
import RequestToJoinCompany from 'components/RequestToJoinCompany';
import { getPage } from 'constants/pages';

const AppPageTemplate = ({ children }) => {
  const { user, fetchUser } = useUserContext();
  const { marginTop } = useNavHeightContext();
  const location = useLocation();
  const tablet = useMediaQuery(`(max-width:${mediaQueries.md})`);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(async () => {
    fetchUser();
    const page = getPage(location.pathname);

    document.title = page.title;

    SegmentService.trackPage(location.pathname);
    SegmentService.identifyUser({
      ...user,
    });
  }, [location]);

  return (
    <>
      <div
        className={`w-screen ${fonts.font}`}
        style={{
          position: 'fixed',
          top: '0px',
          zIndex: '1500',
          height: NAV_HEIGHT,
        }}
      >
        {!tablet && <DesktopNav tabs={NAV_TABS} user={user} />}
        {tablet && (
          <>
            <MobileNav
              tabls={NAV_TABS}
              user={user}
              handleMenuOpen={handleMenuOpen}
              isMenuOpen={isMenuOpen}
            />
            <Banners user={user} />
          </>
        )}
      </div>
      {tablet && !isMenuOpen && (
        <div style={{ marginTop: `${marginTop}px` }}>{children}</div>
      )}
      {tablet && isMenuOpen && (
        <div style={{ marginTop: `${marginTop}px` }}>
          <MobileMenu
            tabs={NAV_TABS}
            settingsMenu={USER_PROFILE_DROPDOWN}
            user={user}
            handleMenuOpen={handleMenuOpen}
          />
        </div>
      )}
      {!tablet && (
        <div>
          <div
            style={{ top: `${NAV_HEIGHT}px`, zIndex: 500 }}
            className="fixed w-screen"
          >
            <Banners user={user} />
          </div>
          <div style={{ marginTop: `${marginTop}px` }} className="relative">
            {children}
          </div>
        </div>
      )}
      <RequestToJoinCompany />
      <TrialDialogs />
    </>
  );
};

export default AppPageTemplate;
