export const STICKY_IE_CLASS = 'sticky';
export const isIE =
  !!navigator.userAgent.match(/Trident/g) ||
  !!navigator.userAgent.match(/MSIE/g);
export const TABLE_HEADER_HEIGHT = 155;
export const LARGE_TABLE_CELL_WIDTH = 95;
export const SHIPPING_LINE_TABLE_CELL_WIDTH = 110;
export const SMALL_TABLE_CELL_WIDTH = 150;

export const CELL_WIDTH_130 = 130;
export const CELL_WIDTH_192 = 192;
export const CELL_WIDTH_190 = 190;
