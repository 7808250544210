import React, { useEffect, useState, useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import Switch from 'components/Switch';
import { getInvoiceAmounts } from 'services/clients/subscription';
import { formatCurrencyNumber, formatNumber } from 'utils/common';
import CouponInput from './components/CouponInput';
import LoadingComponent from 'components/LoadingComponent';
import _ from 'lodash';
import { CHECKOUT_STEPS } from './constants';

const AbortController = window.AbortController;

const CheckoutCard = ({
  user,
  selectedPack,
  selectedVolume,
  onCheckout,
  formData,
  checkoutIsLoading,
  onCoupon,
  period,
  step,
  onPeriodChange = () => {},
}) => {
  const availablePeriods =
    Object.keys(selectedPack.yearlyAvailableVolumes).length > 0
      ? ['monthly', 'yearly']
      : ['monthly'];
  const [switchActiveItem, setSwitchActiveItem] = useState(0);
  const [invoiceAmounts, setInvoiceAmounts] = useState({});
  const [couponCode, setCouponCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let abortController = useRef(AbortController ? new AbortController() : null);
  const isMonthly = period === 'monthly';
  const currentPack = _.get(user, 'company.pack');
  const periodSwitchAllowed =
    step === CHECKOUT_STEPS.BUILD_PLAN ||
    (Object.keys(selectedPack.yearlyAvailableVolumes).includes(
      selectedVolume.toString()
    ) &&
      Object.keys(selectedPack.monthlyAvailableVolumes).includes(
        selectedVolume.toString()
      ));

  if (currentPack === 'starter' && selectedPack.id === 'starter') {
    formData.isValid = false;
  } else if (step === CHECKOUT_STEPS.BUILD_PLAN) {
    formData.isValid = true;
  }

  const abortAndRecreate = () => {
    if (abortController.current && Object.keys(invoiceAmounts).length > 0) {
      abortController.current.abort();
      abortController.current = AbortController ? new AbortController() : null;
    }
  };

  const handleOnItemSelected = (idx, item) => {
    setSwitchActiveItem(idx);
    onPeriodChange(item);
  };

  useEffect(async () => {
    let data = null;
    abortAndRecreate();
    try {
      setIsLoading(true);
      data = await getInvoiceAmounts(
        selectedPack.id,
        selectedVolume,
        period,
        couponCode,
        abortController.current && abortController.current.signal
      );
    } catch {
      setIsLoading(false);
      return;
    }
    if (couponCode) {
      onCoupon(couponCode);
    }
    setIsLoading(false);
    setInvoiceAmounts(data);
  }, [selectedVolume, selectedPack, period, couponCode]);

  useEffect(() => {
    setSwitchActiveItem(availablePeriods.indexOf(period));
  }, [period]);

  const isFreePlan =
    invoiceAmounts.amountDueNextMonth === 0 &&
    invoiceAmounts.amountDueNow === 0;
  return (
    <div className="bg-white shadow-sm rounded-md p-4">
      <div className="flex flex-col space-y-8 items-start">
        {isFreePlan ? (
          <CheckoutCardFreePlan
            selectedPack={selectedPack}
            selectedVolume={selectedVolume}
          />
        ) : (
          <>
            <Switch
              items={availablePeriods}
              activeItemIdx={switchActiveItem}
              onItemSelected={handleOnItemSelected}
              disabled={!periodSwitchAllowed}
            />
            <CheckoutCardPaidPlan
              selectedPack={selectedPack}
              selectedVolume={selectedVolume}
              invoiceAmounts={invoiceAmounts}
              isMonthly={isMonthly}
              availablePeriods={availablePeriods}
              isLoading={isLoading}
              onCouponCode={couponCode => setCouponCode(couponCode)}
              onItemSelected={handleOnItemSelected}
              periodSwitchAllowed={periodSwitchAllowed}
            />
          </>
        )}

        <div className="w-full flex flex-col space-y-4">
          <LoadingButton
            variant="contained"
            loading={checkoutIsLoading}
            color="primary"
            disabled={!formData.isValid}
            onClick={() => onCheckout(isFreePlan)}
          >
            {isFreePlan
              ? selectedPack.id === currentPack
                ? 'Already subscribed to this plan'
                : 'Place order'
              : step === CHECKOUT_STEPS.PAYMENT_METHOD
              ? 'Place order'
              : 'Proceed to checkout'}
          </LoadingButton>
          {['starter', 'pioneer', 'trial_end'].includes(currentPack) &&
            !isFreePlan && (
              <h4 className="text-gray-200 font-bold text-center">
                You can adjust or cancel your plan the first 30 days
              </h4>
            )}
        </div>
      </div>
    </div>
  );
};

const CheckoutCardPaidPlan = ({
  selectedPack,
  selectedVolume,
  invoiceAmounts,
  isMonthly,
  availablePeriods,
  isLoading,
  onCouponCode,
  onItemSelected,
  periodSwitchAllowed,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      <h4 className="font-bold text-gray-300">Bill Summary</h4>
      <div className="flex flex-col space-y-1">
        <h4 className="font-medium text-dark">
          {selectedPack.displayName} + {formatNumber(selectedVolume)} container
          transactions:
        </h4>
        <h4 className="font-bold text-gray-200">12 months commitment</h4>
        <h4 className="font-bold text-gray-200">
          <LoadingComponent isLoading={isLoading}>
            {formatCurrencyNumber(invoiceAmounts.amountDueNextMonth / 100)}
          </LoadingComponent>{' '}
          billed every {isMonthly ? 'month' : 'year'}
        </h4>
      </div>
      <hr
        className="w-full bg-blue-500 border-none my-4 rounded-md"
        style={{ height: '1px' }}
      />
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-1  text-dark">
          <h4 className="font-bold">
            Your bill next {isMonthly ? 'month' : 'year'}
          </h4>
          <h2>
            <LoadingComponent isLoading={isLoading}>
              {formatCurrencyNumber(invoiceAmounts.amountDueNextMonth / 100)}
            </LoadingComponent>
          </h2>
          <h4 className="text-gray-200">
            <LoadingComponent isLoading={isLoading}>
              {formatCurrencyNumber(invoiceAmounts.amountDueNow / 100)}
            </LoadingComponent>{' '}
            billed today
          </h4>
        </div>
        {periodSwitchAllowed &&
          isMonthly &&
          availablePeriods.includes('yearly') && (
            <div
              className="text-blue font-medium"
              onClick={() => onItemSelected(1, 'yearly')}
            >
              <h4 className="cursor-pointer">Prepay upfront and save 10%</h4>
            </div>
          )}
        <CouponInput
          selectedPack={selectedPack}
          onCouponCode={v => onCouponCode(v)}
        />
      </div>
    </div>
  );
};

const CheckoutCardFreePlan = ({ selectedPack, selectedVolume }) => {
  return (
    <div className="flex flex-col space-y-4">
      <h4 className="font-bold text-gray-300">Bill Summary</h4>
      <div className="flex flex-col space-y-1">
        <h4 className="font-medium text-dark">
          {selectedPack.displayName} plan license +{' '}
          {formatNumber(selectedVolume)} complimentary container transactions:
        </h4>
        <h4 className="font-bold text-gray-200">Free</h4>
      </div>
      <hr
        className="w-full bg-blue-500 border-none my-4 rounded-md"
        style={{ height: '1px' }}
      />
      <div className="flex flex-col space-y-1 font-bold text-dark">
        <h4>Total</h4>
        <h2>Free</h2>
      </div>
    </div>
  );
};

export default CheckoutCard;
