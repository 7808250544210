import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import { credsStatuses } from './constants/credentials.constants';

export default class CredentialsForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onHandleFormSubmit: PropTypes.func.isRequired,
    onSkipThisStepClick: PropTypes.func.isRequired,
    terminalKey: PropTypes.string.isRequired,
    terminalsNames: PropTypes.object.isRequired,
    currentTerminalStatus: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      loginValue: '',
      passwordValue: '',
      warning: '',
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyPressListener);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.terminalKey !== this.props.terminalKey) {
      this.setState({
        loginValue: '',
        passwordValue: '',
        warning: '',
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPressListener);
  }

  keyPressListener = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onSubmit();
    }
  };

  handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value, warning: '' });
  };

  onSubmit = () => {
    const { onHandleFormSubmit } = this.props;
    const { loginValue, passwordValue } = this.state;

    if (!loginValue || !passwordValue) {
      this.setState({ warning: 'Login and password should not be empty' });
      return;
    }

    onHandleFormSubmit(
      { login: loginValue, password: passwordValue },
      error => {
        this.setState({ warning: error, passwordValue: '' });
        // this.setState({ loginValue: '', passwordValue: '' });
      }
    );
  };

  render() {
    const { loginValue, passwordValue, warning } = this.state;
    const {
      classes,
      terminalKey,
      terminalsNames,
      onSkipThisStepClick,
      currentTerminalStatus,
    } = this.props;
    const isCurrentTerminalValid =
      currentTerminalStatus === credsStatuses.valid;

    return (
      <div className="creds-form">
        <div className="creds-form-block">
          <div className="creds-form-input-title">Login</div>
          <div className="creds-form-input-container">
            <div className="creds-form-input">
              {isCurrentTerminalValid && (
                <div className="creds-radiobuttons-icon creds-form-validated-icon">
                  <CheckIcon className={classes.radiobuttonIcon} />
                </div>
              )}
              <form autoComplete="off">
                <input
                  type="text"
                  name={'loginValue'}
                  value={loginValue}
                  onChange={this.handleInputChange}
                />
              </form>
            </div>
            <div
              className={`creds-form-tip${
                isCurrentTerminalValid ? ' valid' : ''
              }`}
            >
              {isCurrentTerminalValid
                ? 'Credential is already valid! Enter a new one, only if you need to update it'
                : `Tip: ${terminalsNames[terminalKey].tip}`}
            </div>
          </div>
        </div>

        <div className="creds-form-block">
          <div className="creds-form-input-title">Password</div>
          <div className="creds-form-input">
            {isCurrentTerminalValid && (
              <div className="creds-radiobuttons-icon creds-form-validated-icon">
                <CheckIcon className={classes.radiobuttonIcon} />
              </div>
            )}
            <form autoComplete="off">
              <input
                type="password"
                name={'passwordValue'}
                value={passwordValue}
                onChange={this.handleInputChange}
              />
            </form>
          </div>

          {warning && <div className="creds-form-warning">{warning}</div>}
        </div>

        <div className="creds-form-button-container">
          <div className="creds-form-privacy-policy">
            By entering the information on this page, you agree to our{' '}
            <Link
              target="_blank"
              to="/terms-of-use"
              className="creds-form-privacy-link"
            >
              Terms of Use
            </Link>{' '}
            and assent to our data collection and use practices in our{' '}
            <Link
              target="_blank"
              to="/privacy-policy"
              className="creds-form-privacy-link"
            >
              Privacy Policy
            </Link>
          </div>
          <div className="creds-form-button-wrapper">
            <button className="creds-form-button" onClick={this.onSubmit}>
              SUBMIT
            </button>
            <div
              className="creds-form-skip"
              onClick={() => onSkipThisStepClick(terminalKey)}
            >
              Skip this step
            </div>
          </div>
        </div>
      </div>
    );
  }
}
