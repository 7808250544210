import layerImage from 'assets/images/image.png';
import backgroundImage from 'assets/images/container_image.png';

export default theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
  },
  content: {
    zIndex: 2,
    textAlign: 'center',
  },
  backButton: {
    position: 'absolute',
    top: theme.spacing.unit,
    left: theme.spacing.unit,
    backgroundColor: '#F1A852',
    color: 'white',
  },
  background: {
    position: 'absolute',
    background: `url(${backgroundImage})`,
    filter: 'blur(4px)',
    top: 500,
    height: 683,
    maxHeight: 'calc(100vh - 500px)',
    width: '100%',
  },
  smog: {
    position: 'absolute',
    width: '100%',
    height: 1178,
    top: 400,
    maxHeight: 'calc(100vh - 400px)',
    filter: 'blur(4px)',
    backgroundImage: `url(${layerImage})`,
    mixBlendMode: 'hard-light',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
  },
  colorFilter: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'black',
    opacity: 0.3,
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: 683,
    left: 0,
    top: 500,
    maxHeight: 'calc(100vh - 500px)',
    background:
      'linear-gradient(180deg, rgba(250, 250, 250, 0.7) 0%, rgba(250, 250, 250, 0) 51.37%, #FAFAFA 75%);',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
  },
});
