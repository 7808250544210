export default theme => ({
  footer: {
    padding: 2 * theme.spacing.unit,
    width: '100%',
    backgroundColor: theme.palette.white.background,
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    left: 0,
    right: 0,
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  verticalDivider: {
    height: 15,
    width: 1,
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    color: theme.palette.primary.light,
    fontSize: 12,
    letterSpacing: '0.36em',
    lineHeight: '30px',
  },
  textUnderline: {
    textDecoration: 'underline',
  },
});
