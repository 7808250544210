import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/customDesign/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from 'components/customDesign/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Link } from 'react-router-dom';

export default class Formular extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const {
      handleSubmit,
      errors,
      touched,
      handleChange,
      handleBlur,
      values,
      classes,
      isSubmitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className={classes.form}>
        <FormControl
          required
          margin="normal"
          fullWidth
          className={classes.field}
          error={errors.password && touched.password}
        >
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="Your new password"
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          <FormHelperText>
            {errors.password && touched.password && errors.password}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth error={errors.other}>
          <FormHelperText>{errors.other && errors.other}</FormHelperText>
        </FormControl>
        <Button
          type="submit"
          size="large"
          fullWidth
          className={classes.submit}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
        >
          Reset Password
        </Button>
        {errors.other && (
          <Button
            size="large"
            variant="contained"
            component={Link}
            to="/reset-password"
            fullWidth
            className={classes.submit}
            disabled={isSubmitting}
          >
            Get a new reset password link
          </Button>
        )}
      </form>
    );
  }
}
