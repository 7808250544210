import Table from './Table';
import styles from './Table.style';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getPorts } from 'ducks/erlData';
import { getAvailabilities } from 'ducks/availabilities';

const mapStateToProps = state => ({
  ports: getPorts(state),
  availabilities: state.availabilities.data,
  selectedShift: state.availabilities.selectedShift,
  functionalParsers: state.availabilities.functionalParsers,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAvailabilities: () =>
    dispatch(
      getAvailabilities([ownProps.shippingLine], [ownProps.containerType])
    ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Table);
