import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { resetPassword } from 'services/clients/user';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

export default class ChangePasswordButton extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    email: PropTypes.string.isRequired,
  };

  state = {
    isLoading: false,
    emailSent: false,
    error: false,
  };

  changePassword = () => {
    this.setState({ isLoading: true });
    resetPassword({ email: this.props.email })
      .then(() => this.setState({ isLoading: false, emailSent: true }))
      .catch(() => this.setState({ error: true }));
  };

  render() {
    const { classes } = this.props;
    const { isLoading, emailSent, error } = this.state;
    if (emailSent)
      return (
        <div className={classNames(classes.success, classes.root)}>
          <CheckIcon fontSize="small" />
          <Typography className={classes.success}>
            We've sent you an email
          </Typography>
        </div>
      );
    if (error)
      return (
        <div className={classNames(classes.fail, classes.root)}>
          <CloseIcon fontSize="small" />
          <Typography className={classes.fail}>
            Fail to change your password
          </Typography>
        </div>
      );

    return (
      <Typography
        className={classNames(classes.button, classes.change, classes.root)}
        onClick={this.changePassword}
      >
        {isLoading ? (
          <CircularProgress color="secondary" size={20} />
        ) : (
          'Reset password'
        )}
      </Typography>
    );
  }
}
