export default theme => ({
  selected: {
    backgroundColor: '#fff !important',
    height: '55px !important',
    borderBottom: 'unset !important',
    opacity: '1 !important',
  },
  label: {
    color: '#8E98A4',
    textTransform: 'none',
  },
  labelSelected: {
    color: theme.palette.primary.main,
  },
  root: {
    backgroundColor: theme.palette.inputBackground.main,
    borderBottomRightRadius: '0 !important',
    borderBottomLeftRadius: '0 !important',
    borderTopRightRadius: '5px !important',
    borderTopLeftRadius: '5px !important',
    marginRight: -1,
    border: '1px solid #D5D8DF',
    boxSizing: 'border-box',
    borderRadius: 5,
    paddingLeft: 4 * theme.spacing.unit,
    paddingRight: 4 * theme.spacing.unit,
    height: 45,
    opacity: 1,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      opacity: 1,
      backgroundColor: 'unset',
    },
    '&:focus': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  buttonGroup: {
    backgroundColor: 'transparent',
    borderRadius: '2px 2px 0px 0px',
    boxShadow: 'none',
    paddingRight: 1,
    alignItems: 'flex-end',
    display: 'flex',
  },
  container: {
    display: 'flex',
  },
  reload: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: theme.spacing.unit,
  },
});
