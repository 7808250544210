import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import erlClient from 'services/clients';

const Needvalidation = props => {
  const { classes, scac } = props;
  const [emails, setEmails] = useState(null);
  const [APICalled, setAPICalled] = useState(false);
  useEffect(() => {
    !APICalled &&
      erlClient(`core/api/v1/auth/company/${scac}/admins`)
        .then(res => {
          setAPICalled(true);
          setEmails(res.data);
        })
        .catch(console.error);
  });
  if (emails)
    return (
      <Typography align="center" className={classes.alert}>
        You will automatically be added to the team once they confirm it. <br />
        Do not hesitate to ask {emails.join(', ')} to add you in person!
      </Typography>
    );
  else return null;
};

Needvalidation.propTypes = {
  classes: PropTypes.object.isRequired,
  scac: PropTypes.string.isRequired,
};

const styles = theme => ({
  alert: {
    color: theme.palette.alert.main,
    backgroundColor: theme.palette.alert.light,
    // border: `solid 1px ${theme.palette.alert.main}`,
    borderRadius: 5,
    padding: 2 * theme.spacing.unit,
    width: 500,
    margin: 'auto',
    marginTop: 2 * theme.spacing.unit,
  },
});

export default withStyles(styles)(Needvalidation);
