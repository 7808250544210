import { createTheme } from '@mui/material/styles';
import { brandColors } from './brandColors';

let theme = createTheme({
  palette: {
    primary: {
      main: brandColors.colors.blue.DEFAULT,
      contrastText: '#ffffff',
    },
    secondary: {
      main: brandColors.colors.orange.DEFAULT,
      contrastText: '#ffffff',
    },
    error: {
      main: brandColors.colors.red.DEFAULT,
    },
    info: {
      main: brandColors.colors.blue[600],
      contrastText: brandColors.colors.gray[400],
    },
    success: {
      main: brandColors.colors.green.DEFAULT,
    },
    lightBlue: {
      main: brandColors.colors.blue[200],
      contrastText: '#ffffff',
    },
    import: {
      main: brandColors.colors.import.DEFAULT,
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

theme = createTheme(theme, {
  components: {
    MuiInput: {
      variants: [
        {
          props: { variant: 'chip-input' },
          style: {
            marginRight: '0.5rem',
            borderRadius: '3px',
            padding: '0.125rem 0.25rem',
            height: '1.125rem',
            fontWeight: '500',
            backgroundColor: brandColors.colors.blue[600],
            color: brandColors.colors.blue.DEFAULT,
            border: `0.5px solid ${brandColors.colors.blue.DEFAULT}`,
            input: {
              fontSize: '0.75rem',
              lineHeight: '0.75rem',
            },
          },
        },
      ],
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          color: brandColors.colors.white,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
      variants: [
        {
          props: { variant: 'tag-default' },
          style: {
            textTransform: 'capitalize',
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: brandColors.colors.dark,
            background: brandColors.colors.gray[600],
            padding: '0px 6px',
            '&:disabled': {
              opacity: 0.4,
              background: brandColors.colors.blue[600],
              color: brandColors.colors.gray[300],
            },
            '&:hover': {
              color: brandColors.colors.dark,
              background: brandColors.colors.gray[600],
            },
          },
        },
        {
          props: { variant: 'tag-primary' },
          style: {
            textTransform: 'capitalize',
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: `${brandColors.colors.blue.DEFAULT}`,
            background: brandColors.colors.blue[400],
            padding: '0px 6px',
            minWidth: '0px',
            '&:disabled': {
              opacity: 0.4,
              background: brandColors.colors.blue[600],
              color: brandColors.colors.blue.DEFAULT,
            },
            '&:hover': {
              color: `${brandColors.colors.blue.DEFAULT}`,
              background: brandColors.colors.blue[400],
            },
          },
        },
        {
          props: { variant: 'tag-secondary' },
          style: {
            textTransform: 'capitalize',
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: `${brandColors.colors.orange.DEFAULT}`,
            background: brandColors.colors.orange[600],
            padding: '0px 6px',
            minWidth: '0px',
            '&:disabled': {
              opacity: 0.4,
              background: brandColors.colors.orange[600],
              color: brandColors.colors.orange.DEFAULT,
            },
            '&:hover': {
              color: `${brandColors.colors.orange.DEFAULT}`,
              background: brandColors.colors.orange[600],
            },
          },
        },
        {
          props: { variant: 'tag-alert' },
          style: {
            textTransform: 'capitalize',
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: `${brandColors.colors.red.DEFAULT}`,
            background: brandColors.colors.red[600],
            padding: '0px 6px',
            minWidth: '0px',
            '&:disabled': {
              opacity: 0.4,
              background: brandColors.colors.red[600],
              color: brandColors.colors.red.DEFAULT,
            },
            '&:hover': {
              color: `${brandColors.colors.red.DEFAULT}`,
              background: brandColors.colors.red[600],
            },
          },
        },
        {
          props: { variant: 'tag-loading' },
          style: {
            textTransform: 'capitalize',
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: `${brandColors.colors.blue.DEFAULT}`,
            background: brandColors.colors.gray[600],
            padding: '0px 6px',
            minWidth: '0px',
            '&:disabled': {
              opacity: 0.4,
              background: brandColors.colors.blue[600],
              color: brandColors.colors.blue.DEFAULT,
            },
            '&:hover': {
              color: `${brandColors.colors.blue.DEFAULT}`,
              background: brandColors.colors.gray[600],
            },
          },
        },
        {
          props: { variant: 'tag-gated-out' },
          style: {
            textTransform: 'capitalize',
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: brandColors.colors.dark,
            background: brandColors.colors.gatedOut[600],
            padding: '0px 6px',
            minWidth: '0px',
            '&:disabled': {
              opacity: 0.4,
              color: brandColors.colors.dark,
              background: brandColors.colors.gatedOut[600],
            },
            '&:hover': {
              color: brandColors.colors.dark,
              background: brandColors.colors.gatedOut[600],
            },
          },
        },
        {
          props: { variant: 'tag-on-vessel' },
          style: {
            textTransform: 'capitalize',
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: brandColors.colors.dark,
            background: brandColors.colors.vessel[600],
            padding: '0px 6px',
            minWidth: '0px',
            '&:disabled': {
              opacity: 0.4,
              color: brandColors.colors.dark,
              background: brandColors.colors.vessel[600],
            },
            '&:hover': {
              color: brandColors.colors.dark,
              background: brandColors.colors.vessel[600],
            },
          },
        },
        {
          props: { variant: 'tag-import' },
          style: {
            textTransform: 'capitalize',
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: brandColors.colors.dark,
            background: brandColors.colors.import[600],
            padding: '0px 6px',
            minWidth: '0px',
            '&:disabled': {
              opacity: 0.4,
              color: brandColors.colors.dark,
              background: brandColors.colors.import[600],
            },
            '&:hover': {
              color: brandColors.colors.dark,
              background: brandColors.colors.import[600],
            },
          },
        },
        {
          props: { variant: 'tag-email-default' },
          style: {
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: brandColors.colors.gray[300],
            background: brandColors.colors.blue[600],
            border: `1px solid ${brandColors.colors.gray[300]}`,
            padding: '0px 6px',
            minWidth: '0px',
            '&:disabled': {
              color: brandColors.colors.gray[300],
              background: brandColors.colors.blue[600],
              border: `1px solid ${brandColors.colors.gray[300].DEFAULT}`,
            },
            '&:hover': {
              background: brandColors.colors.blue[400],
              color: brandColors.colors.blue.DEFAULT,
            },
          },
        },
        {
          props: { variant: 'tag-email-selected' },
          style: {
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: brandColors.colors.blue.DEFAULT,
            background: brandColors.colors.blue[600],
            border: `1px solid ${brandColors.colors.blue.DEFAULT}`,
            padding: '0px 6px',
            minWidth: '0px',
            '&:disabled': {
              color: brandColors.colors.gray[300],
              background: brandColors.colors.blue[600],
              border: `1px solid ${brandColors.colors.gray[300]}`,
            },
            '&:hover': {
              background: brandColors.colors.blue[400],
            },
          },
        },
        {
          props: { variant: 'per-diem' },
          style: {
            textTransform: 'uppercase',
            fontWeight: 'medium',
            fontSize: '0.625rem',
            color: brandColors.colors.blue.DEFAULT,
            border: `${brandColors.colors.blue.DEFAULT} 1px solid`,
            background: brandColors.colors.blue[600],
            padding: '2px 8px',
            boxShadow: brandColors.boxShadow.sm,
            '&:disabled': {
              color: brandColors.colors.gray[200],
              border: `1px solid ${brandColors.colors.gray[200]}`,
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            fontSize: '0.75rem',
            color: brandColors.colors.gray[200],
            textDecoration: 'underline',
            padding: 0,
            '&:hover': {
              background: 'none',
              textDecoration: 'underline',
            },
            span: {
              marginRight: '0.25rem',
            },
          },
        },
        {
          props: { variant: 'outlined-import' },
          style: {
            fontSize: '0.75rem',
            background: brandColors.colors.import[600],
            border: `1px solid ${brandColors.colors.import.DEFAULT}`,
            minWidth: 160,
            '&:hover': {
              background: brandColors.colors.import[300],
            },
          },
        },
        {
          props: { variant: 'outlined-info' },
          style: {
            fontSize: '0.75rem',
            background: brandColors.colors.blue[600],
            border: `1px solid ${brandColors.colors.gray[500]}`,
            minWidth: 160,
            '&:hover': {
              background: brandColors.colors.blue[500],
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          textTransform: 'initial',
          borderRadius: '3px',
        },
        containedPrimary: {
          '&:disabled': {
            background: brandColors.colors.blue[400],
            color: brandColors.colors.white,
          },
          '&:hover': {
            background: brandColors.colors.blue[100],
            boxShadow: 'none',
          },
        },
        containedSecondary: {
          '&:disabled': {
            opacity: 0.4,
            background: brandColors.colors.orange.DEFAULT,
            color: brandColors.colors.white,
          },
          '&:hover': {
            background: brandColors.colors.orange[100],
            boxShadow: 'none',
          },
        },
        containedError: {
          '&:disabled': {
            background: brandColors.colors.gray[600],
            color: brandColors.colors.gray[500],
          },
          '&:hover': {
            background: brandColors.colors.red.light,
            color: brandColors.colors.red.DEFAULT,
            boxShadow: 'none',
          },
        },
        containedInfo: {
          border: `${brandColors.colors.blue[400]} 1px solid`,
          '&:hover': {
            background: brandColors.colors.blue[400],
            color: brandColors.colors.gray[200],
            boxShadow: 'none',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          marginRight: '0.5rem',
          borderRadius: '3px',
          padding: '0.125rem 0.25rem',
          height: '1.125rem',
        },
        label: {
          padding: 0,
          fontWeight: '500',
          fontSize: '0.75rem',
          lineHeight: '0.75rem',
        },
      },
      variants: [
        {
          props: { variant: 'chip-primary' },
          style: {
            backgroundColor: brandColors.colors.blue[600],
            border: `0.5px solid ${brandColors.colors.blue[400]}`,

            '&:hover, &.hovered': {
              border: `0.5px solid ${brandColors.colors.blue.DEFAULT}`,
              color: brandColors.colors.blue.DEFAULT,
              backgroundColor: brandColors.colors.blue[500],

              '.MuiSvgIcon-root': {
                color: brandColors.colors.blue.DEFAULT,
              },
            },

            '.MuiSvgIcon-root': {
              color: brandColors.colors.gray[500],
            },
          },
        },
        {
          props: { variant: 'chip-error' },
          style: {
            backgroundColor: brandColors.colors.red[100],
            border: `0.5px solid ${brandColors.colors.red.DEFAULT}`,
            color: brandColors.colors.red.DEFAULT,

            '&:hover, &.hovered': {
              border: `0.5px solid ${brandColors.colors.red.DEFAULT}`,
              color: brandColors.colors.white,
              backgroundColor: brandColors.colors.red.DEFAULT,

              '.MuiSvgIcon-root': {
                color: brandColors.colors.white,
              },
            },

            '.MuiSvgIcon-root': {
              color: brandColors.colors.red.DEFAULT,
            },
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontsize: '16px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: brandColors.colors.white,
          border: `${brandColors.colors.blue[400]} 1px solid`,
          '&:hover': {
            border: `${brandColors.colors.blue.DEFAULT} 1px solid`,
          },
        },
        notchedOutline: {
          border: `none !important`,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: brandColors.colors.gray[300],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: brandColors.colors.blue.DEFAULT,
        },
        arrow: {
          color: brandColors.colors.blue.DEFAULT,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          background: brandColors.colors.white,
          border: `1px solid ${brandColors.colors.blue[400]}`,
          '& .MuiDataGrid-columnHeader:nth-of-type(1), .MuiDataGrid-cell:nth-of-type(1)': {
            borderRight: `1px solid ${brandColors.colors.blue[400]}`,
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: '0px',
          },
          '& .MuiDataGrid-columnHeader': {
            padding: '0px',
            background: brandColors.colors.blue[600],
          },
          '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid ${brandColors.colors.blue[400]}`,
            padding: '0px',
          },
          '& .MuiPaginationItem-root': {
            borderRadius: 0,
          },
          '.MuiDataGrid-row.Mui-selected': {
            backgroundColor: brandColors.colors.white,
          },
          '.MuiDataGrid-checkboxInput': {
            color: brandColors.colors.gray[500],
            '& .Mui-checked': {
              color: brandColors.colors.blue.DEFAULT,
            },
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            borderRight: 'none !important',
            paddingLeft: '0',

            ':focus-within': {
              outline: 'none',
            },

            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          },
          '.MuiDataGrid-overlay': {
            zIndex: 1100,
          },
          '& > *': {
            justifyContent: 'left',
            display: 'flex',
            width: '100%',
            background: brandColors.colors.blue[600],
          },
        },
        columnHeaders: {
          borderBottom: `1px solid ${brandColors.colors.blue[400]}`,
        },
        cellCheckbox: {
          borderRight: 'none !important',
          paddingTop: '1em',
          alignItems: 'start',
          backgroundColor: brandColors.colors.white,

          checkboxInput: {
            paddingTop: '0',
            marginTop: '-1px', // Remove the 1px paddingTop from the input drawing
          },
        },
        cell: {
          ':focus-within': {
            outline: 'none !important',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginTopCenter: {
          [theme.breakpoints.up('sm')]: {
            top: '88px',
          },
        },
        anchorOriginTopRight: {
          [theme.breakpoints.up('sm')]: {
            top: '88px',
          },
        },
        anchorOriginTopLeft: {
          [theme.breakpoints.up('sm')]: {
            top: '88px',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: brandColors.colors.black,
          fontsize: '14px',
        },
      },
      variants: [
        {
          props: { severity: 'success' },
          style: {
            backgroundColor: brandColors.colors.green.light,
            border: `1px solid ${brandColors.colors.green.DEFAULT}`,
          },
        },
        {
          props: { severity: 'info' },
          style: {
            backgroundColor: brandColors.colors.blue[500],
            border: `1px solid ${brandColors.colors.blue.DEFAULT}`,
          },
        },
        {
          props: { severity: 'error' },
          style: {
            backgroundColor: brandColors.colors.red.light,
            border: `1px solid ${brandColors.colors.red.DEFAULT}`,
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'modal' && {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }),
        }),
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'modal' && {
            padding: '16px 21px',
            borderBottom: `1px solid ${brandColors.colors.blue[500]}`,
          }),
        }),
        title: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'modal' && {
            fontSize: '16px',
            textAlign: 'center',
            fontWeight: '500 !important',
            color: brandColors.colors.dark.DEFAULT,
            '.MuiCardHeader-action': {
              margin: 0,
              cursor: 'pointer',
            },
          }),
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'modal' && {
            padding: '35px 64px',
            flexGrow: 1,
          }),
        }),
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'modal' && {
            borderTop: `1px solid ${brandColors.colors.blue[500]}`,
            display: 'flex',
            justifyContent: 'end',
            padding: '16px 32px',
          }),
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          alignItems: 'start',
          zIndex: '100',
        },
        iconContainer: {
          zIndex: '100',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          position: 'absolute',
          left: 'calc(-100% + 20px)',
          right: 'calc(+100% - 20px)',
        },
      },
    },
  },
});

export { theme as themeProvider };
