import moment from 'moment-timezone';
const format = 'hh:mm A MM/DD/YYYY';
const timezone = 'America/Los_Angeles';

export const formatDateTimeLaTz = (date, time) => {
  if (date && time) {
    return moment.tz(`${date} ${time}`, timezone).format(format);
  }
  return moment.tz(date, timezone).format(format);
};
