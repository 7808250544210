import React from 'react';
import { CONTAINER_STATUS_ENUM } from '../container-config';
import LaunchIcon from '@mui/icons-material/Launch';
import { gateTransactionText, DisplayTimeRange } from '../utils/cells';

interface Props {
  status: string;
  releaseBookedAppointment: boolean;
  showBookedAppointment: boolean;
  gatedOutDate?: Date;
  gatedOutTerminalName?: string;
  pickupTerminalName?: string;
  pickupTerminalAppointmentSystemLink?: string;
  pickupAvailable?: string;
  chassis?: string;
  showBookedAppointmentPending?: string;
  bookedAppointmentStartDate?: Date;
  bookedAppointmentEndDate?: string;
}

export default function PickupCell({
  status,
  releaseBookedAppointment,
  gatedOutDate,
  gatedOutTerminalName,
  pickupTerminalName,
  pickupTerminalAppointmentSystemLink,
  pickupAvailable,
  chassis,
  showBookedAppointmentPending,
  showBookedAppointment,
  bookedAppointmentStartDate,
  bookedAppointmentEndDate,
}: Props): JSX.Element {
  // When we have a pickup that says it's been delivered but we have not found a gated out date for it
  if (status === CONTAINER_STATUS_ENUM.GATED_OUT && !gatedOutDate) {
    return (
      <div className="leading-none flex h-full items-start py-4 px-2">
        <div className="flex flex-col space-y-2">
          <h5 className="font-semibold text-gray-200 uppercase">
            {pickupTerminalName}
          </h5>
          <div className="flex space-x-2">
            <h6 className="uppercase font-medium text-gray-300">picked up</h6>
          </div>
        </div>
      </div>
    );
  }

  // TODO: tidy this up when we release booked appointments
  if (
    status === CONTAINER_STATUS_ENUM.IMPORT ||
    status === CONTAINER_STATUS_ENUM.ON_VESSEL
  ) {
    return (
      <div className="leading-none flex h-full items-start py-4 px-2">
        <div className="flex flex-col space-y-2">
          {/* TODO: terminal name can be removed when we remove the feature flag.  */}
          {!releaseBookedAppointment && (
            <h5 className="font-bold text-gray-200">{pickupTerminalName} </h5>
          )}
          {releaseBookedAppointment && showBookedAppointmentPending && (
            <a
              href={pickupTerminalAppointmentSystemLink}
              target="_blank"
              className="flex items-center underline text-blue space-x-1"
              rel="noreferrer"
            >
              <LaunchIcon style={{ fontSize: 14 }} />
              <h5 className="font-semibold">{pickupTerminalName}</h5>
            </a>
          )}
          {releaseBookedAppointment && showBookedAppointment && (
            <h5 className="font-semibold text-gray-200">
              <span className="uppercase">{pickupTerminalName} </span>
              <DisplayTimeRange
                startDateTime={bookedAppointmentStartDate}
                endDateTime={bookedAppointmentEndDate}
              />
            </h5>
          )}
          {!releaseBookedAppointment && <h6>-</h6>}
          {releaseBookedAppointment && showBookedAppointmentPending && (
            <h6
              style={{
                marginTop:
                  'calc(0.5rem * calc(1 - var(--tw-space-y-reverse)) - 4px)',
              }}
            >
              PENDING BOOKING
            </h6>
          )}
          {releaseBookedAppointment && showBookedAppointment && (
            <h6>APPOINTMENT SCHEDULED</h6>
          )}
          <h6
            className={`uppercase max-w-max font-medium px-1 py-0.5 rounded-sm ${
              pickupAvailable
                ? 'text-green bg-green-light'
                : 'text-red bg-red-light'
            }`}
          >
            {pickupAvailable
              ? 'Available for pickup'
              : 'Not available for pickup'}
          </h6>
        </div>
      </div>
    );
  }

  return (
    <div className="leading-none flex h-full items-start py-4 px-2">
      <div className="flex flex-col space-y-2">
        <h5 className="font-semibold text-gray-200">
          {gateTransactionText(gatedOutTerminalName, gatedOutDate)}
        </h5>
        {gatedOutDate && (
          <div className="flex space-x-2">
            <h6 className="uppercase font-medium text-gray-300">picked up </h6>
            {chassis && chassis !== ' ' && (
              <div className="bg-gray-600 px-2 py-1 rounded-sm relative bottom-1">
                <h6>{chassis}</h6>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
