import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';

import CheckIcon from '@material-ui/icons/Check';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DualIcon from '@material-ui/icons/CompareArrows';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';

const iconFontSize = 18;

export default class WithLegendPopover extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    warnings: PropTypes.array.isRequired,
    children: PropTypes.node,
    status: PropTypes.string,
    isShown: PropTypes.bool,
    appointments: PropTypes.number,
    formattedTimeSlot: PropTypes.string,
    isPTSC: PropTypes.bool,
  };
  static defaultProps = {
    warnings: [],
  };

  state = {
    anchorEl: null,
  };

  handlePopoverOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  renderInstruction = () => {
    const { status, classes, isPTSC } = this.props;

    let icon;
    let message;

    switch (status) {
      case 'clock':
        icon = (
          <AccessTimeIcon
            style={{ fontSize: iconFontSize }}
            className={classes.grey}
          />
        );
        message = 'Pending information from terminal on return restrictions';
        break;
      case 'yes':
        icon = (
          <CheckIcon
            style={{ fontSize: iconFontSize }}
            className={classes.green}
          />
        );
        message = isPTSC
          ? 'Please confirm with SSL before returning your container'
          : 'Open for receiving';
        break;
      case 'dual':
        icon = (
          <DualIcon
            style={{ fontSize: iconFontSize }}
            className={classes.yellow}
          />
        );
        message = 'Restrictions: dual transactions only';
        break;
      case 'no':
        icon = (
          <CloseIcon
            style={{ fontSize: iconFontSize }}
            className={classes.red}
          />
        );
        message = 'Closed for receiving';
        break;
      default:
        icon = <div></div>;
        message = '';
    }

    return (
      <div className={classes.popoverBlock}>
        <span className={classes.popoverIcon}>{icon}</span>
        <span className={classes.popoverTextBold}>{message}</span>
      </div>
    );
  };

  render() {
    const {
      isShown,
      classes,
      warnings,
      children,
      formattedTimeSlot,
      appointments,
    } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <div
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
        >
          {children}
          {isShown && (
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              disableRestoreFocus
              disableScrollLock
            >
              {warnings.length ? (
                <div className={classes.popoverBlock}>
                  <span className={classes.popoverIcon}>
                    <WarningIcon
                      style={{ fontSize: iconFontSize }}
                      className={classes.yellow}
                    />
                  </span>
                  <span className={classes.popoverTextBold}>
                    <div>{warnings[0]}</div>
                  </span>
                </div>
              ) : null}

              {this.renderInstruction()}
              <div className={classes.popoverBlockTimeSlot}>
                <span className={classes.popoverIcon}>
                  <DateRangeIcon
                    style={{ fontSize: iconFontSize }}
                    className={classes.calendar}
                  />
                </span>
                <span className={classes.popoverText}>
                  <div>First appointment:</div>
                </span>
              </div>

              <div className={classes.popoverBlock}>
                <span
                  className={classes.popoverIcon}
                  style={{ minWidth: iconFontSize }}
                ></span>
                <span className={classes.popoverTextBold}>
                  <div>{formattedTimeSlot}</div>
                </span>
              </div>

              <div className={classes.popoverBlock}>
                <span
                  className={classes.popoverIcon}
                  style={{ minWidth: iconFontSize }}
                ></span>
                <span className={classes.popoverText}>
                  <span>{'Total appointments during the shift: '}</span>
                  <span className={classes.popoverTextBold}>{`${appointments ||
                    'N/A'}`}</span>
                </span>
              </div>
            </Popover>
          )}
        </div>
      </div>
    );
  }
}
