import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getFormatedShiftDate } from '../../../../services/helpers/shift';
import StatusIcon from 'components/StatusIcon';
import { NextAppointmentsService } from './services/next-appointments.service';
import {
  SHIFTS_REF,
  MESSAGES,
  clockStatus,
  everportTerminalKey,
} from './constants/next-appointments.constants';
import { HatchedCell } from './components/HatchedCell';
import WithLegendPopover from 'components/WithLegendPopover';
import Chassis from './components/Chassis';
import { ShowHideNextShifts } from './ShowHideNextShifts';
import { SegmentService } from 'services/helpers/segment';

const NextAppintments = ({
  user,
  classes,
  terminals,
  availabilities,
  currentSLKey,
  currentContainerKey,
  availabilityCredentials,
  onOpenBookAppointmentModal,
}) => {
  const availableAppointments = availabilities.availableAppointment;

  const [isAllShiftsShown, toggleShowHideAllShifts] = useState(false);

  const { data } = availabilities;
  const dataBySL = data[currentSLKey];
  const dataBySlAndContainer = dataBySL && dataBySL[currentContainerKey];

  if (!terminals || !availableAppointments) {
    return <div>Loading...</div>;
  }

  const keys = terminals.map(item => item.key);

  const availableAppointmentRestructured = Object.keys(
    availableAppointments
  ).map(key => ({
    terminal: key,
    ...availableAppointments[key],
  }));

  if (keys.includes(everportTerminalKey)) {
    availableAppointmentRestructured.push({ terminal: everportTerminalKey });
  }

  const filteredAppointments = availableAppointmentRestructured.filter(item =>
    keys.includes(item.terminal)
  );

  const createRows = () => {
    if (!terminals && !availableAppointments) return;

    let isFirstTagRendered = false;
    const availableShifts = NextAppointmentsService.createShiftsList(
      filteredAppointments,
      currentContainerKey,
      currentSLKey
    );

    let is6Item = 0;
    const first6 = availableShifts.slice(0, 6);
    const renderedRows = isAllShiftsShown ? availableShifts : first6;

    const result = renderedRows.map((item, i) => {
      return createRow(item, isFirstTagRendered, () => {
        isFirstTagRendered = true;
      });
    });

    result.forEach((item, i) => {
      is6Item = i;
      if (is6Item === 6) {
        result.splice(
          i,
          0,
          <ShowHideNextShifts
            key={Math.random()}
            classes={classes}
            terminalsLength={terminals.length + 1}
            isAllShiftsShown={isAllShiftsShown}
            toggleShowHideAllShifts={toggleShowHideAllShifts}
          />
        );
      }
    });

    if (!isAllShiftsShown) {
      result.push(
        <ShowHideNextShifts
          key={Math.random()}
          classes={classes}
          terminalsLength={terminals.length + 1}
          isAllShiftsShown={isAllShiftsShown}
          toggleShowHideAllShifts={toggleShowHideAllShifts}
        />
      );
    }

    return result;
  };

  const createRow = (shift, isFirstTagRendered, cb) => {
    if (shift.shift === SHIFTS_REF.shift2) {
      return createRowWith(classes.fatRow, shift, isFirstTagRendered, cb);
    }
    return createRowWith(classes.row, shift, isFirstTagRendered, cb);
  };

  const createRowWith = (classes, shift, isFirstTagRendered, cb) => {
    return (
      <TableRow key={Math.random()} className={classes}>
        {createHeaderCell(shift, isFirstTagRendered, cb)}
        {createCells(shift)}
      </TableRow>
    );
  };

  const renderHeaderCellTag = text => {
    const classNames = {
      triangleLeft:
        text === 'FIRST' ? classes.triangleLeftGreen : classes.triangleLeft,
      headerCellTagContent:
        text === 'FIRST'
          ? classes.headerCellTagContentGreen
          : classes.headerCellTagContent,
      triangleRight:
        text === 'FIRST' ? classes.triangleRightGreen : classes.triangleRight,
    };
    return (
      <div className={classes.headerCellTag}>
        <div className={classNames.triangleLeft}></div>
        <div className={classNames.headerCellTagContent}>{text}</div>
        <div className={classNames.triangleRight}></div>
      </div>
    );
  };

  const createHeaderCell = (shift, isFirstTagRendered, cb) => {
    const cells = terminals.map((item, index) => {
      const terminalAvailability =
        dataBySlAndContainer && dataBySlAndContainer[item.key];

      const { availability } = terminalAvailability || {};

      const shiftsByDay = availability && availability[shift.day];
      const instructions = shiftsByDay && shiftsByDay[shift.shift];
      const cellData = {
        ...item,
        instructions,
      };

      return createCell(cellData, shift, index, true);
    });

    const availableCell = cells.find(
      cell =>
        cell &&
        cell.instructions &&
        (cell.instructions.status === 'yes' ||
          cell.instructions.status === 'dual')
    );

    if (availableCell && !isFirstTagRendered) {
      cb();
    }

    const shiftName = shift.shift === SHIFTS_REF.shift1 ? 'shift 1' : 'shift 2';

    // BCIC-179 Add a tag to the shifts of the current day (in the row name cell)
    const isShiftToday = NextAppointmentsService.checkIsShiftToday(shift.day);

    return (
      <TableCell
        key={Math.random()}
        className={classes.headerCell1}
        align="center"
      >
        {isShiftToday && renderHeaderCellTag('TODAY')}
        {availableCell && !isFirstTagRendered && renderHeaderCellTag('FIRST')}
        {getFormatedShiftDate(shift.day)}
        <br />
        <b> {shiftName.toUpperCase()}</b>
      </TableCell>
    );
  };

  const createCells = shift => {
    const cells = terminals.map((item, index) => {
      const terminalAvailability =
        dataBySlAndContainer && dataBySlAndContainer[item.key];
      const { availability } = terminalAvailability || {};
      const shiftsByDay = availability && availability[shift.day];
      const instructions = shiftsByDay && shiftsByDay[shift.shift];
      const cellData = {
        ...item,
        instructions,
      };
      return createCell(cellData, shift, index);
    });
    return cells;
  };

  const createCell = (terminal, shift, index, isHeader) => {
    const {
      terminal_portal,
      instructions,
      appointment_system_name,
      is_booking_available,
    } = terminal;
    const key = terminal.key + shift.day + shift.shift + index;

    const currentTerminal = filteredAppointments.find(
      item => item.terminal === terminal.key
    );

    const isPTSC = NextAppointmentsService.checkIsPtscTerminal(terminal);

    const isMatson = NextAppointmentsService.checkIsMatsonTerminal(terminal);

    if (isPTSC || isMatson) {
      return renderCellForITS(
        key,
        terminal.key,
        shift,
        terminal_portal,
        instructions,
        isHeader,
        isPTSC
      );
    }

    const isEverport = NextAppointmentsService.checkIsEverport(terminal.key);

    if (isEverport) {
      return renderCellForEverport(
        key,
        terminal.key,
        shift,
        terminal_portal,
        instructions,
        isHeader
      );
    }

    // BCIC-190 Add a link "Check appointments portal" where the list of available appointments is missing
    const isTerminalDataNotCorrect = NextAppointmentsService.checkIsTerminalDataNotCorrect(
      currentTerminal,
      currentSLKey,
      terminal,
      isHeader
    );
    if (isTerminalDataNotCorrect) {
      return createCheckAppointmentsPortalCell(
        key,
        shift,
        terminal.key,
        terminal_portal,
        instructions
      );
    }

    // BCIC-404 For FMS, YTI, and TTI, convert appointments to "no appointments available" when empty restriction=CLOSED
    // BCIC-709 Remove logic "no appoitnment" for FMS and YTI, and apply to APM 20.10.2020
    const isApmTti = NextAppointmentsService.checkIsApmTti(terminal.key);
    if (isApmTti && instructions && instructions.status === 'no') {
      return createNoAppointmentCell(
        key,
        terminal_portal,
        shift,
        terminal.key,
        instructions
      );
    }

    let currentDay = null;

    if (currentTerminal) {
      if (
        currentTerminal[currentSLKey] &&
        currentTerminal[currentSLKey][currentContainerKey]
      ) {
        currentDay = currentTerminal[currentSLKey][
          currentContainerKey
        ].availability.find(item => item[shift.day]);
      } else {
        currentDay = null;
      }
    } else {
      return null;
    }

    if (currentDay) {
      const shiftsForDay = currentDay[shift.day];

      if (shift.shift === SHIFTS_REF.shift1) {
        if (
          shiftsForDay &&
          shiftsForDay.shift1 &&
          shiftsForDay.shift1.total_appointments &&
          shiftsForDay.shift1.total_appointments === 0 &&
          shiftsForDay.shift1.slots &&
          shiftsForDay.shift1.slots.length === 0
        ) {
          return createNoAppointmentCell(
            key,
            terminal_portal,
            shift,
            terminal.key,
            instructions
          );
        }
        return createAppointmentCell(
          shiftsForDay.shift1,
          key,
          terminal.pier,
          terminal.name,
          terminal_portal,
          is_booking_available,
          appointment_system_name,
          shift,
          terminal.key,
          instructions,
          isHeader
        );
      } else if (shift.shift === SHIFTS_REF.shift2) {
        if (
          shiftsForDay &&
          shiftsForDay.shift2 &&
          shiftsForDay.shift2.total_appointments === 0 &&
          shiftsForDay.shift2.slots.length === 0
        ) {
          return createNoAppointmentCell(
            key,
            terminal_portal,
            shift,
            terminal.key,
            instructions
          );
        }
        return createAppointmentCell(
          shiftsForDay.shift2,
          key,
          terminal.pier,
          terminal.name,
          terminal_portal,
          is_booking_available,
          appointment_system_name,
          shift,
          terminal.key,
          instructions,
          isHeader
        );
      } else {
        return createNoAppointmentCell(
          key,
          terminal_portal,
          shift,
          terminal.key,
          instructions
        );
      }
    } else {
      return createNoAppointmentCell(
        key,
        terminal_portal,
        shift,
        terminal.key,
        instructions
      );
    }
  };

  const getFormattedTimeSlot = (start_time, end_time) => {
    // BCIC-402 If appointments are after midnight, add a * before the time slot
    const isAfterMidnight = NextAppointmentsService.checkIsSlotAfterMidnight(
      start_time,
      end_time
    );
    return `${isAfterMidnight ? '*' : ''}${start_time.substr(
      0,
      5
    )} - ${end_time.substr(0, 5)}`;
  };

  const renderShiftTotal = appointments => {
    return appointments ? (
      <div className={classes.shiftTotal}>{`shift total: ${appointments}`}</div>
    ) : null;
  };

  const createAppointmentCell = (
    shiftInfo,
    key,
    pier,
    name,
    terminalUrl,
    is_booking_available,
    appointment_system_name,
    shift,
    terminalKey,
    instructions,
    isHeader
  ) => {
    const { status, warnings, chassis_restrictions } = instructions || {};

    if (
      shiftInfo &&
      shiftInfo.slots &&
      shiftInfo.slots.length &&
      shiftInfo.slots[0]
    ) {
      const { total_appointments } = shiftInfo || {};

      if (isHeader)
        return {
          shift,
          terminalKey: key,
          instructions,
        };

      return renderAppointmentCell(
        key,
        pier,
        name,
        terminalKey,
        terminalUrl,
        is_booking_available,
        appointment_system_name,
        shift,
        status,
        warnings,
        chassis_restrictions,
        total_appointments,
        shiftInfo.slots[0].start_time,
        shiftInfo.slots[0].end_time,
        shiftInfo.slots[0].start_date,
        shiftInfo.slots[0].appointment_uuid,
        shiftInfo.slots[0].terminal_date
      );
    } else {
      return createNoAppointmentCell(
        key,
        terminalUrl,
        shift,
        terminalKey,
        instructions
      );
    }
  };

  const renderAppointmentCell = (
    key,
    pier,
    name,
    terminalKey,
    terminalUrl,
    is_booking_available,
    appointment_system_name,
    shift,
    status,
    warnings,
    chassis_restrictions,
    appointments,
    start_time,
    end_time,
    start_date,
    appointment_uuid,
    terminal_date
  ) => {
    const formattedTimeSlot = getFormattedTimeSlot(start_time, end_time);

    const renderedData = (
      <div>
        <div>{formattedTimeSlot}</div>
        {renderShiftTotal(appointments)}
      </div>
    );

    //BCIC-405 Add a grey filter if there is empty restriction = CLOSED
    let className;
    let isGrey = false;

    if (status && (status === 'yes' || status === 'dual')) {
      className =
        shift.shift === SHIFTS_REF.shift2
          ? classes.cellAppointmentsFat
          : classes.cell;
    } else {
      isGrey = true;
      className =
        shift.shift === SHIFTS_REF.shift2
          ? classes.cellAppointmentsGreyFat
          : classes.cellAppointmentsGrey;
    }

    // TODO: remove all my appointment dead code.
    // const { beta_access } = user;
    // const isClickToBookShown =
    //   beta_access && is_booking_available && status !== 'no';
    const isClickToBookShown = false;

    return (
      <TableCell
        key={key}
        className={className}
        align="center"
        onClick={() => {
          NextAppointmentsService.fireGAcellClickEvent(
            currentSLKey,
            currentContainerKey,
            user,
            terminalKey,
            shift
          );

          SegmentService.trackEvent('Empty-appointment Cell Clicked', {
            shippingLine: currentSLKey,
            containerType: currentContainerKey,
            terminal: terminalKey,
            date: shift.day,
            shift: shift.shift,
          });

          isClickToBookShown
            ? onOpenBookAppointmentModal({
                key,
                pier,
                name,
                terminalKey,
                terminalUrl,
                appointment_system_name,
                shift,
                status,
                warnings,
                appointments,
                start_time,
                end_time,
                start_date,
                appointment_uuid,
                terminal_date,
              })
            : redirectTo(terminalUrl);
        }}
      >
        <WithLegendPopover
          isShown={true}
          status={status || clockStatus}
          warnings={warnings}
          formattedTimeSlot={formattedTimeSlot}
          appointments={appointments}
        >
          {status !== 'no' && renderedData}
          {currentContainerKey && (
            <StatusIcon
              status={status || clockStatus}
              warnings={warnings}
              isGrey={isGrey}
              isEmptyAppointments
            />
          )}
          {isClickToBookShown && (
            <div style={{ textDecoration: 'underline', color: '#49a7c8' }}>
              Click to book
            </div>
          )}
          {status !== 'no' &&
            chassis_restrictions &&
            !_.isEmpty(chassis_restrictions) && (
              <Chassis
                chassis_restrictions={chassis_restrictions}
                isGrey={isGrey}
              />
            )}
        </WithLegendPopover>
      </TableCell>
    );
  };

  const createNoAppointmentCell = (
    key,
    terminalUrl,
    shift,
    terminalKey,
    instructions
  ) => {
    const { status, warnings, chassis_restrictions } = instructions || {};
    let message = MESSAGES.noAppointmetnAvailable;

    if (shift && terminalKey) {
      const gateData = NextAppointmentsService.getGateData(
        availabilities.gateHours,
        terminalKey,
        shift
      );
      if (NextAppointmentsService.checkIsGateClosed(gateData)) {
        message = MESSAGES.gateClosed;
      }
    }

    if (message && message === MESSAGES.gateClosed) {
      return renderGateClosedCell(key, message, shift);
    }

    const className =
      shift.shift === SHIFTS_REF.shift2
        ? classes.cellNoAppointmentsFat
        : classes.cellNoAppointments;

    return (
      <TableCell key={key} className={className} align="center">
        <WithLegendPopover
          isShown={true}
          status={status || clockStatus}
          warnings={warnings}
          formattedTimeSlot={_.capitalize(message)}
          appointments={null}
        >
          {status !== 'no' && <div>{message}</div>}
          {currentContainerKey && (
            <StatusIcon
              status={status || clockStatus}
              warnings={warnings}
              isGrey={true}
              isEmptyAppointments
            />
          )}
          {status !== 'no' &&
            chassis_restrictions &&
            !_.isEmpty(chassis_restrictions) && (
              <Chassis
                chassis_restrictions={chassis_restrictions}
                isGrey={true}
              />
            )}
        </WithLegendPopover>
      </TableCell>
    );
  };

  const createCheckAppointmentsPortalCell = (
    key,
    shift,
    terminalKey,
    terminalUrl,
    instructions
  ) => {
    const { status, warnings } = instructions || {};

    let message = MESSAGES.noData;

    if (shift && terminalKey) {
      const gateData = NextAppointmentsService.getGateData(
        availabilities.gateHours,
        terminalKey,
        shift
      );
      if (NextAppointmentsService.checkIsGateClosed(gateData)) {
        message = MESSAGES.gateClosed;
      }
    }

    const className =
      shift.shift === SHIFTS_REF.shift2
        ? classes.checkAppointmentsPortalFatGrey
        : classes.checkAppointmentsPortalGrey;

    if (message && message === MESSAGES.gateClosed) {
      return renderGateClosedCell(key, message, shift);
    }

    return (
      <TableCell key={key} className={className} align="center">
        <WithLegendPopover
          isShown={true}
          status={status || clockStatus}
          warnings={warnings}
          formattedTimeSlot={_.capitalize(message)}
          appointments={null}
        >
          <HatchedCell />
          <div>{message}</div>
          {currentContainerKey && (
            <StatusIcon
              status={status || clockStatus}
              warnings={warnings}
              isGrey={true}
              isEmptyAppointments
            />
          )}
        </WithLegendPopover>
      </TableCell>
    );
  };

  const renderCellForEverport = (
    key,
    terminalKey,
    shift,
    terminalUrl,
    instructions,
    isHeader
  ) => {
    if (isHeader)
      return {
        shift,
        terminalKey: key,
        instructions,
      };
    const { status, warnings } = instructions || {};
    let message = moment(shift.day).format('MM/DD/YYYY');

    if (shift && terminalKey) {
      const gateData = NextAppointmentsService.getGateData(
        availabilities.gateHours,
        terminalKey,
        shift
      );
      if (NextAppointmentsService.checkIsGateClosed(gateData)) {
        message = MESSAGES.gateClosed;
      }
    }

    let className;

    let isGrey = false;
    if (status && (status === 'yes' || status === 'dual')) {
      className =
        shift.shift === SHIFTS_REF.shift2
          ? classes.cellAppointmentsFat
          : classes.cell;
    } else {
      isGrey = true;
      className =
        shift.shift === SHIFTS_REF.shift2
          ? classes.cellAppointmentsGreyFat
          : classes.cellAppointmentsGrey;
    }

    if (message && message === MESSAGES.gateClosed) {
      return renderGateClosedCell(key, message, shift);
    }

    return (
      <TableCell
        key={key}
        className={className}
        align="center"
        onClick={() => {
          NextAppointmentsService.fireGAcellClickEvent(
            currentSLKey,
            currentContainerKey,
            user,
            terminalKey,
            shift
          );
          redirectTo(terminalUrl);
        }}
      >
        <WithLegendPopover
          isShown={true}
          status={status || clockStatus}
          warnings={warnings}
          formattedTimeSlot={message}
          appointments={null}
        >
          {status !== 'no' && <div>{message}</div>}
          {currentContainerKey && (
            <StatusIcon
              status={status || clockStatus}
              warnings={warnings}
              isGrey={isGrey}
              isEmptyAppointments
            />
          )}
        </WithLegendPopover>
      </TableCell>
    );
  };

  const renderCellForITS = (
    key,
    terminalKey,
    shift,
    terminalUrl,
    instructions,
    isHeader,
    isPTSC
  ) => {
    if (isHeader)
      return {
        shift,
        terminalKey: key,
        instructions,
      };

    const { status, warnings } = instructions || {};
    let message = MESSAGES.noAppointmentRequired;
    if (shift && terminalKey) {
      const gateData = NextAppointmentsService.getGateData(
        availabilities.gateHours,
        terminalKey,
        shift
      );
      if (NextAppointmentsService.checkIsGateClosed(gateData)) {
        message = MESSAGES.gateClosed;
      }
    }

    if (message && message === MESSAGES.gateClosed) {
      return renderGateClosedCell(key, message, shift);
    }

    let className;
    let isGrey = false;
    if (status && (status === 'yes' || status === 'dual')) {
      className =
        shift.shift === SHIFTS_REF.shift2
          ? classes.cellAppointmentsFat
          : classes.cell;
    } else {
      isGrey = true;
      className =
        shift.shift === SHIFTS_REF.shift2
          ? classes.cellAppointmentsGreyFat
          : classes.cellAppointmentsGrey;
    }

    return (
      <TableCell
        key={key}
        className={className}
        align="center"
        onClick={() => {
          NextAppointmentsService.fireGAcellClickEvent(
            currentSLKey,
            currentContainerKey,
            user,
            terminalKey,
            shift
          );
          redirectTo(terminalUrl);
        }}
      >
        <WithLegendPopover
          isShown={true}
          status={status || clockStatus}
          warnings={warnings}
          formattedTimeSlot={_.capitalize(message)}
          appointments={null}
          isPTSC={isPTSC}
        >
          {status !== 'no' && <div>{message}</div>}
          {currentContainerKey && (
            <StatusIcon
              status={status || clockStatus}
              warnings={warnings}
              isGrey={isGrey}
              isEmptyAppointments
            />
          )}
        </WithLegendPopover>
      </TableCell>
    );
  };

  const renderGateClosedCell = (key, message, shift) => {
    const className =
      shift.shift === SHIFTS_REF.shift2
        ? classes.cellNoAppointmentsFat
        : classes.cellNoAppointments;
    return (
      <TableCell key={key} className={className} align="center">
        {message}
      </TableCell>
    );
  };

  const redirectTo = url => {
    window.open(url);
  };

  return (
    <Table className={classes.table} aria-label="next appointment table">
      <TableBody>{createRows()}</TableBody>
    </Table>
  );
};

NextAppintments.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  terminals: PropTypes.array.isRequired,
  availabilities: PropTypes.object.isRequired,
  currentSLKey: PropTypes.string.isRequired,
  currentContainerKey: PropTypes.string.isRequired,
  availabilityCredentials: PropTypes.object.isRequired,
  onOpenBookAppointmentModal: PropTypes.func.isRequired,
};

export default NextAppintments;
