import React from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';

import Button from 'components/customDesign/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReactCodeInput from 'react-code-input';

import erlClient from 'services/clients';

function CodeForm({ classes, email, successCallback }) {
  const initialValues = { code: '', email };
  const validate = values => {
    const errors = {};
    if (values.code.length !== 6) {
      errors.code = 'Invalid code';
    }
    return errors;
  };
  const submit = (values, { setSubmitting, setValues, setErrors }) => {
    erlClient
      .post('/core/api/v1/auth/email-validation/validate', values, {
        headers: { Authorization: '' },
      })
      .then(res => {
        setSubmitting(false);
        setValues({ ...values, code: '' });
        successCallback();
      })
      .catch(err => {
        setErrors({ code: err.response.data });
        setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={submit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => {
        const onChange = value => {
          if (value.length <= 6 && !isNaN(value))
            handleChange({ target: { value, name: 'code' } });
        };
        if (
          values.code.length === 6 &&
          !isSubmitting &&
          Object.keys(errors).length === 0
        )
          handleSubmit();
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FormControl
              required
              disabled={isSubmitting}
              error={errors.email && touched.email}
              className={classes.fields}
            >
              <ReactCodeInput
                type="number"
                fields={6}
                name="code"
                value={values.code}
                onChange={onChange}
                isValid={!Boolean(errors.code && touched.code)}
              />
              <FormHelperText className={classes.error}>
                {errors.code && touched.code && errors.code}
              </FormHelperText>
            </FormControl>
            <Button
              type="submit"
              disabled={Boolean(errors.code) || isSubmitting}
              isLoading={isSubmitting}
            >
              Validate
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

CodeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  successCallback: PropTypes.func.isRequired,
};

CodeForm.defaultProps = {
  successCallback: () => {},
};

const styles = theme => ({
  form: {
    marginTop: 2 * theme.spacing.unit,
  },
  fields: {
    marginBottom: 3 * theme.spacing.unit,
  },
  error: {
    color: theme.palette.alert.main,
    marginLeft: theme.spacing.unit,
  },
});

export default withStyles(styles)(CodeForm);
