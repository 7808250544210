import { availableShifts } from '../services/helpers/shift';
import {
  getData,
  getOverwrittenRules,
  getFunctionalParser,
  getShippingLinesMapping,
  getGateHours,
  getFirstAppointment,
  getAvailabilityAppointments,
} from '../services/clients/availability';
import { formatData } from '../services/helpers/data';
import merge from 'lodash/merge';
import { startLoading, endLoading } from './loader';
import { terminalData } from '../constants/data';
import ReactGA from 'react-ga';
import { createSelector } from 'reselect';
import { availabilityAtomicUpdater } from '../services/helpers/data';

// Action Types
export const ADD_AVAILABILITIES_DATA =
  'core/api/v1/erl/availabilities/ADD_AVAILABILITIES_DATA';
export const UPDATE_AVAILABILITIES_DATA =
  'core/api/v1/erl/availabilities/UPDATE_AVAILABILITIES_DATA';
export const CLEAR_AVAILABILITIES_DATA =
  'core/api/v1/erl/availabilities/CLEAR_AVAILABILITIES_DATA';
export const CHANGE_SELECTED_SHIFT =
  'core/api/v1/erl/availabilities/CHANGE_SELECTED_SHIFT';
export const SET_AVAILABILITIES_DATA =
  'core/api/v1/erl/availabilities/SET_AVAILABILITIES_DATA';
export const UPDATE_COLUMNS_LAYOUT =
  'core/api/v1/erl/availabilities/UPDATE_COLUMNS_LAYOUT';
export const SET_FUNCTIONAL_PARSERS =
  'core/api/v1/erl/availabilities/SET_FUNCTIONAL_PARSERS';
export const SET_SHIPPING_LINE_MAPPING =
  'core/api/v1/erl/availabilities/SET_SHIPPING_LINE_MAPPING';
export const SET_GATE_HOURS = 'core/api/v1/erl/availabilities/SET_GATE_HOURS';
export const SET_FIRST_APPOINTMENT =
  'core/api/v1/erl/availabilities/SET_FIRST_APPOINTMENT';
export const SET_AVAILABLE_APPOINTMENTS =
  'core/api/v1/erl/availabilities/SET_AVAILABLE_APPOINTMENTS';
export const SET_SL_AND_CONTAINER_TYPE_BY_CONTAINER_ID =
  'core/api/v1/erl/availabilities/SET_SL_AND_CONTAINER_TYPE_BY_CONTAINER_ID';

// Reducer
const initialState = {
  data: {},
  selectedShift: availableShifts[0],
  functionalParsers: [],
  columnsLayout: terminalData,
  mappingShippingLine: [],
  gateHours: [],
  firstAppointment: [],
  slAndContainerTypeByContainerId: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_AVAILABILITIES_DATA:
      return { ...state, data: {} };
    case ADD_AVAILABILITIES_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.shippingLine]: {
            ...state.data[action.payload.shippingLine],
            [action.payload.containerType]: action.payload.data,
          },
        },
      };
    case UPDATE_AVAILABILITIES_DATA:
      const updated_state = availabilityAtomicUpdater(state)(action.payload);
      return updated_state;
    case SET_AVAILABILITIES_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case CHANGE_SELECTED_SHIFT:
      return {
        ...state,
        selectedShift: action.payload.shift,
      };
    case UPDATE_COLUMNS_LAYOUT:
      return {
        ...state,
        columnsLayout: action.payload,
      };
    case SET_FUNCTIONAL_PARSERS:
      return {
        ...state,
        functionalParsers: action.payload,
      };
    case SET_SHIPPING_LINE_MAPPING:
      return {
        ...state,
        mappingShippingLine: action.payload,
      };
    case SET_GATE_HOURS:
      return {
        ...state,
        gateHours: action.payload,
      };
    case SET_FIRST_APPOINTMENT:
      return {
        ...state,
        firstAppointment: action.payload,
      };
    case SET_AVAILABLE_APPOINTMENTS:
      return {
        ...state,
        availableAppointment: action.payload,
      };
    case SET_SL_AND_CONTAINER_TYPE_BY_CONTAINER_ID:
      return {
        ...state,
        slAndContainerTypeByContainerId: action.payload,
      };
    default:
      return state;
  }
};

// Action creators
export const setAvailabilitiesData = data => ({
  type: SET_AVAILABILITIES_DATA,
  payload: data,
});

export const changeSelectedShift = shift => ({
  type: CHANGE_SELECTED_SHIFT,
  payload: shift,
});

export const setFunctionalParsers = terminals => ({
  type: SET_FUNCTIONAL_PARSERS,
  payload: terminals,
});

export const updateColumnsLayout = layout => {
  ReactGA.event({
    category: 'Availabilities',
    action: 'Filter terminals',
    label: layout.map(t => t.key).join(', '),
  });
  return {
    type: UPDATE_COLUMNS_LAYOUT,
    payload: layout,
  };
};

export const setShippingLineMapping = mapping => {
  return {
    type: SET_SHIPPING_LINE_MAPPING,
    payload: mapping,
  };
};

export const setGateHours = gateHours => {
  return {
    type: SET_GATE_HOURS,
    payload: gateHours,
  };
};

export const addAvailabilityData = (shippingLine, containerType, data) => {
  return {
    type: ADD_AVAILABILITIES_DATA,
    payload: { shippingLine, containerType, data },
  };
};
export const updateAvailabilitiesData = ({ ...params }) => {
  return {
    type: UPDATE_AVAILABILITIES_DATA,
    payload: params,
  };
};

export const setFirstAppointment = data => {
  return {
    type: SET_FIRST_APPOINTMENT,
    payload: data,
  };
};
export const saveAvailableAppointments = data => {
  return {
    type: SET_AVAILABLE_APPOINTMENTS,
    payload: data,
  };
};

// Side effects
export const getAvailabilities = (shippingLines, containerTypes) => async (
  dispatch,
  getState
) => {
  dispatch(startLoading('availabilities'));
  const data = await getData(
    shippingLines.map(sl => sl.key),
    containerTypes.map(ct => ct.key)
  );
  const overwrittenRules = await getOverwrittenRules(
    shippingLines.map(sl => sl.key),
    containerTypes.map(ct => ct.key)
  );
  const functionalParsers = await getFunctionalParser();
  const mapping = await getShippingLinesMapping();
  const gateHours = await getGateHours();
  let formattedData = formatData(data);
  let formattedRules = formatData(overwrittenRules);

  dispatch(setFunctionalParsers(functionalParsers));
  dispatch(setShippingLineMapping(mapping));
  dispatch(setGateHours(gateHours));
  dispatch(setAvailabilitiesData(merge(formattedData, formattedRules)));
  dispatch(endLoading('availabilities'));
};

export const getFirstAppointmentAvailable = () => async (
  dispatch,
  getState
) => {
  dispatch(startLoading('firstAppointment'));
  const data = await getFirstAppointment();
  dispatch(setFirstAppointment(data));
  dispatch(endLoading('firstAppointment'));
};

export const reSetFunctionalParsers = () => async (dispatch, getState) => {
  const functionalParsers = await getFunctionalParser();
  dispatch(setFunctionalParsers(functionalParsers));
};

export const setAvailableAppointments = (line, container_type) => async (
  dispatch,
  getState
) => {
  dispatch(startLoading('firstAppointment'));
  const data = await getAvailabilityAppointments(line, container_type);
  dispatch(saveAvailableAppointments(data));
  dispatch(endLoading('firstAppointment'));
};

// Selectors
export const getCurrentGateHour = createSelector(
  state => state.availabilities.gateHours,
  state => state.availabilities.selectedShift,
  (state, props) => props,
  (gateHours, selectedShift, terminalKey) =>
    gateHours
      .filter(
        gh => gh.date === selectedShift.day && gh.shift === selectedShift.shift
      )
      .find(gh => gh.port === terminalKey)
);

export const getFirstAppointmentByTerminal = createSelector(
  state => state.availabilities.firstAppointment,
  state => state.availabilities.selectedShift,
  (state, props) => props,
  (appointments, selectedShift, terminalKey) => {
    const currentTerminalApp = appointments.find(
      app => app.port === terminalKey
    );
    if (currentTerminalApp)
      return {
        ...currentTerminalApp,
        currentShift:
          selectedShift.day === currentTerminalApp.date &&
          selectedShift.shift === currentTerminalApp.shift,
      };
    return null;
  }
);
