import React from 'react';
import { PICKUP_HOLD_KEYS, PICKUP_STATUS_ENUM } from '../container-config';

interface Holds {
  [key: string]: {
    note: string;
    status: string;
  };
}

interface Props {
  holds: Holds;
}

export default function HoldsCell({ holds }: Props) {
  return (
    <div className="leading-none flex h-full w-full items-start py-4 px-2">
      <div className="flex">
        {holds &&
          PICKUP_HOLD_KEYS.map(holdColumn => {
            const { note = '-', status = '-' } = holds[holdColumn.name];
            const textValue =
              status === PICKUP_STATUS_ENUM.UNKNOWN
                ? note
                : status.toUpperCase();
            const tagColor =
              status === PICKUP_STATUS_ENUM.HOLD ? 'text-red' : 'text-gray-300';

            return (
              <div key={holdColumn.name} style={{ flex: '0 0 72px' }}>
                <div className="flex flex-col space-y-2">
                  <h5 className="text-gray-200 font-semibold capitalize">
                    {holdColumn.name}
                  </h5>
                  <h6 className={`uppercase font-medium ${tagColor} pr-4`}>
                    {textValue}
                  </h6>
                </div>
              </div>
            );
          })}
        {!holds &&
          PICKUP_HOLD_KEYS.map(holdColumn => {
            return (
              <div key={holdColumn.name} style={{ flex: '0 0 72px' }}>
                <div className="flex flex-col space-y-2">
                  <h5 className="text-gray-200 font-semibold capitalize">
                    {holdColumn.name}
                  </h5>
                  <h6 className={`uppercase font-medium pr-4`}>-</h6>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
