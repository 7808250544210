import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFirstAppointmentAvailable } from 'ducks/availabilities';
import {
  LARGE_TABLE_CELL_WIDTH,
  SHIPPING_LINE_TABLE_CELL_WIDTH,
  SMALL_TABLE_CELL_WIDTH,
} from 'constants/layout';
import classnames from 'classnames';

import Typography from '@material-ui/core/Typography';
import FirstAppointmentCellHeader from 'components/FirstAppointmentCellHeader';
import { ReactComponent as Calendar } from 'assets/images/calendar.svg';
import SocketFirstAppoitnmentClient from 'services/sockets/firstAppointment';
import { retrieveTerminalsWithAppointments } from 'ducks/erlData';

export class FirstAppointmentHeader extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    getFirstAppointment: PropTypes.func.isRequired,
    terminals: PropTypes.array.isRequired,
    displayShift: PropTypes.bool.isRequired,
    retrieveTerminalsWithAppointments: PropTypes.func.isRequired,
  };

  computeFirstCellStyle() {
    const { displayShift } = this.props;
    const baseWidth = SHIPPING_LINE_TABLE_CELL_WIDTH + SMALL_TABLE_CELL_WIDTH;
    return {
      width: displayShift ? baseWidth + LARGE_TABLE_CELL_WIDTH : baseWidth,
      minWidth: displayShift ? baseWidth + LARGE_TABLE_CELL_WIDTH : baseWidth,
    };
  }

  componentDidMount() {
    this.props.getFirstAppointment();
    this.props.retrieveTerminalsWithAppointments();
    this.socket = new SocketFirstAppoitnmentClient();
  }

  componentWillUnmount = () => {
    this.socket.leave();
  };

  render() {
    const { classes, terminals } = this.props;
    return (
      <div className={classnames(classes.wrapper, classes.greyBack)}>
        <div
          style={this.computeFirstCellStyle()}
          className={classnames(classes.terminal, classes.labelCell)}
        >
          <div className={classes.label}>
            <Calendar className={classes.labelIcon} />
            <div className={classes.labelTexts}>
              <Typography variant="caption" className={classes.labelPrimary}>
                Next appointment
              </Typography>
            </div>
          </div>
        </div>
        {terminals.map(terminal => (
          <FirstAppointmentCellHeader
            key={terminal.name}
            terminal={terminal}
            classes={{ root: classes.terminal }}
          />
        ))}
      </div>
    );
  }
}

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  terminal: {
    width: LARGE_TABLE_CELL_WIDTH,
    minWidth: LARGE_TABLE_CELL_WIDTH,
    borderLeft: '0.5px solid rgba(31, 51, 75, 0.1)',
    borderRight: '0.5px solid rgba(31, 51, 75, 0.1)',
    borderBottom: '1px solid rgba(31, 51, 75, 0.1)',
    height: 45,
  },
  greyBack: {
    backgroundColor: 'rgba(31, 51, 75, 0.02)',
  },
  labelCell: {
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  labelIcon: {
    flex: 2,
    fontSize: 20,
    color: 'rgba(31, 51, 75, 0.5)',
  },
  labelTexts: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  labelPrimary: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'rgba(31, 51, 75, 0.5)',
  },
});

const mapDispatchToProps = dispatch => ({
  getFirstAppointment: () => dispatch(getFirstAppointmentAvailable()),
  retrieveTerminalsWithAppointments: () =>
    dispatch(retrieveTerminalsWithAppointments()),
});

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(FirstAppointmentHeader);
