import React from 'react';
import AppPageTemplate from './AppPageTemplate';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { themeProvider } from '../../themeProvider';
import { NavHeightProvider } from 'common/contexts/NavHeightContext';
import { useUserContext } from 'common/contexts/UserContext';
import PackRestrictedRoute from 'components/PrivateRoute/PackRestrictedRoute';

const AppPageTemplateProvider = () => {
  const { user } = useUserContext();
  return (
    <NavHeightProvider>
      <ThemeProvider theme={themeProvider}>
        <AppPageTemplate user={user}>
          <PackRestrictedRoute>
            <Outlet />
          </PackRestrictedRoute>
        </AppPageTemplate>
      </ThemeProvider>
    </NavHeightProvider>
  );
};

export default AppPageTemplateProvider;
