import React from 'react';
import Drawer from '@mui/material/Drawer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from '../../styles/typography.module.css';

const SideDrawer = ({ isFilterOpen, toggleDrawer, title, list }) => {
  return (
    <Drawer
      anchor="right"
      open={isFilterOpen}
      onClose={() => toggleDrawer(false)}
    >
      <div className={styles.font}>
        <div style={{ width: '352px' }} role="presentation" className="pt-4">
          {/* title */}
          <div className="px-4 flex space-x-4 text-dark">
            <ArrowForwardIcon
              style={{ fontSize: '24px' }}
              onClick={() => toggleDrawer(false)}
              className="cursor-pointer"
            />
            <h3 className=" font-medium">{title}</h3>
          </div>
          <hr className="w-full bg-blue-400 border-0.5 border-blue-400 mt-4 mb-2" />
        </div>
        {list}
      </div>
    </Drawer>
  );
};

export default SideDrawer;
