export default theme => ({
  containerContent: {
    padding: theme.spacing.unit,
    minHeight: '70vh',
    position: 'relative',
  },
  containerRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(8),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
  shippingLinesDropDown: {
    '& div': {
      '& label': {
        color: '#8D97A3',
        transform: 'translate(0px, -2px) scale(1) !important',
        zIndex: '0',
      },
    },
    '& fieldset': {
      display: 'none',
    },
  },
  containerTypesDropDown: {
    paddingLeft: '4px',
    '& div': {
      '& label': {
        color: '#8D97A3',
        transform: 'translate(0px, -2px) scale(1) !important',
      },
    },
  },
  inputWrapper: {
    padding: '18px 0px 0px 0px !important',
    '& fieldset': {
      paddingLeft: '29px',
    },
  },
  inputSelected: {
    color: '#FFFFFF',
    background: 'linear-gradient(180deg, #35475D 0%, #1F334B 100%)',
    borderRadius: '4px',
    padding: '8px !important',
  },
  inputClear: {
    color: '#FFFFFF',
    background: 'linear-gradient(180deg, #35475D 0%, #1F334B 100%)',
    borderRadius: '4px',
    padding: '8px !important',
  },
  clearIndicator: {
    color: '#FFFFFF',
  },
  popupIndicator: {
    color: '#FFFFFF',
    '& span': {
      '& svg': {
        width: '1.2em',
        height: '1.2em',
      },
    },
  },
  endAdornment: {
    top: '18px !important',
  },
  tableTitle: {
    marginTop: '16px',
    marginBottom: '6px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  selectEmpty: {
    marginTop: 10,
    color: '#586069',
  },
  inputRoot: {
    paddingTop: 18,
    paddingRigth: 125,
    paddingBottom: 18,
    paddingLeft: 25,
    fontSize: 100,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  grid: {
    marginTop: 100,
  },
  oval: {
    borderRadius: '20px',
    backgroundColor: '#1F334B',
    color: 'white',
    width: '150px',
    fontSize: '20px',
  },
  pt20: {
    fontSize: '20px',
  },
  radiobuttonIcon: {
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  loaderWrapper: {
    textAlign: 'center',
    marginTop: 5 * theme.spacing.unit,
    marginBottom: 5 * theme.spacing.unit,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  groupLabel: {
    color: '#000000',
  },
});
