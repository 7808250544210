import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classnames from 'classnames';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import lodash from 'lodash';
import { getAvailableShifts } from '../../../../services/helpers/shift';

import CellStatus from '../CellStatus';
import TableHeader from '../TableHeader';
import ShippingLineCell from '../ShippingLineCell';
import Caption from '../../../../components/Caption';
import {
  isIE,
  TABLE_HEADER_HEIGHT,
  LARGE_TABLE_CELL_WIDTH,
  SHIPPING_LINE_TABLE_CELL_WIDTH,
  SMALL_TABLE_CELL_WIDTH,
} from '../../../../constants/layout';
import {
  extractTerminal,
  extractAvailability,
  cantBeReturnAnywhere,
} from '../../../../services/helpers/data';
import { computeTableWidth } from '../../../../services/helpers/layout';
import ContainerTypeCell from '../ContainerTypeCell';

export default class AvailabilityTable extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    selectedShifts: PropTypes.array.isRequired,
    displayShift: PropTypes.bool.isRequired,
    shippingLines: PropTypes.array.isRequired,
    containerSize: PropTypes.array.isRequired,
    ports: PropTypes.array.isRequired,
    getAvailabilities: PropTypes.func.isRequired,
    availabilities: PropTypes.object.isRequired,
    onCellClick: PropTypes.func,
    stickySpace: PropTypes.bool.isRequired,
    selectedCell: PropTypes.shape({
      date: PropTypes.string.isRequired,
      shift: PropTypes.string.isRequired,
      port: PropTypes.object.isRequired,
    }),
    disableClickEmpty: PropTypes.bool.isRequired,
    functionalParsers: PropTypes.array.isRequired,
  };
  static defaultProps = {
    displayShift: false,
    selectedShifts: [getAvailableShifts()[0]],
    stickySpace: false,
    disableClickEmpty: false,
  };

  isCellSelected = (day, shift, port) =>
    this.props.selectedCell &&
    this.props.selectedCell.date === day &&
    this.props.selectedCell.shift === shift &&
    this.props.selectedCell.port.key === port.key;

  componentDidUpdate = async prevProps => {
    if (
      !isEqual(
        sortBy(prevProps.containerSize),
        sortBy(this.props.containerSize)
      ) ||
      !isEqual(
        sortBy(prevProps.shippingLines),
        sortBy(this.props.shippingLines)
      )
    )
      this.props.getAvailabilities();
  };

  componentDidMount = async () => {
    this.props.getAvailabilities();
  };

  render() {
    const {
      classes,
      selectedShifts,
      displayShift,
      containerSize,
      shippingLines,
      availabilities,
      ports,
      onCellClick,
      stickySpace,
      disableClickEmpty,
      functionalParsers,
      getAvailabilities,
    } = this.props;

    if (!ports || ports.length <= 0) return null;

    const numberOfShift = selectedShifts.length;
    const activeTerminals = functionalParsers.map(t => t.port);

    const rowLayout = selectedShifts;
    const terminals = lodash(ports)
      .orderBy(['pier', 'name'])
      .groupBy('location')
      .value();
    const { nbTerminals, width } = computeTableWidth(
      displayShift,
      Object.values(terminals).flat()
    );
    return (
      <ScrollSync key={`table-${nbTerminals}`}>
        <div>
          <ScrollSyncPane>
            <TableHeader
              displayShift={displayShift}
              terminals={terminals}
              lrdData={functionalParsers}
              stickySpace={stickySpace}
              refreshAvailabilities={getAvailabilities}
            />
          </ScrollSyncPane>
          <ScrollSyncPane>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} padding="none" style={{ width }}>
                {isIE && <div style={{ height: TABLE_HEADER_HEIGHT }} />}
                <colgroup>
                  <col style={{ width: SHIPPING_LINE_TABLE_CELL_WIDTH }} />
                  <col style={{ width: SMALL_TABLE_CELL_WIDTH }} />
                  {displayShift && (
                    <col style={{ width: LARGE_TABLE_CELL_WIDTH }} />
                  )}
                  {Object.values(terminals).map(terminalD =>
                    terminalD.map(terminal => (
                      <col
                        key={terminal.pier}
                        style={{ width: LARGE_TABLE_CELL_WIDTH }}
                      />
                    ))
                  )}
                </colgroup>
                <TableBody>
                  {shippingLines.map((sl, slIndex) =>
                    containerSize.map((cType, cTypeIndex) =>
                      rowLayout.map((layout, layoutIndex) => {
                        return (
                          <TableRow
                            key={
                              sl.key + cType.name + layout.day + layout.shift
                            }
                            className={classnames(classes.tableRow, {
                              [classes.greyBackground]: Boolean(slIndex % 2),
                              [classes.redBackground]: cantBeReturnAnywhere(
                                availabilities,
                                sl,
                                cType,
                                layout
                              ),
                            })}
                          >
                            {layoutIndex === 0 && cTypeIndex === 0 && (
                              <ShippingLineCell
                                shippingLine={sl}
                                nbContainerTypes={
                                  containerSize.length * numberOfShift
                                }
                                classes={{
                                  cellRoot: classnames({
                                    [classes.greyBackground]: Boolean(
                                      slIndex % 2
                                    ),
                                    [classes.whiteBackground]: !Boolean(
                                      slIndex % 2
                                    ),
                                  }),
                                }}
                              />
                            )}
                            {layoutIndex === 0 && (
                              <ContainerTypeCell
                                containerType={cType}
                                rowSpan={numberOfShift}
                                borderLeft={Boolean(cTypeIndex % 2)}
                              />
                            )}
                            {displayShift && (
                              <TableCell align="center">
                                <div>{layout.day}</div>
                                <div>{layout.shift}</div>
                              </TableCell>
                            )}
                            {Object.values(terminals).map(terminalD =>
                              terminalD.map(terminal => {
                                const currentTerminal = extractTerminal(
                                  availabilities,
                                  sl,
                                  cType,
                                  terminal
                                );
                                const currentAvailability = extractAvailability(
                                  currentTerminal,
                                  layout
                                );
                                return (
                                  <CellStatus
                                    key={cType.name + terminal.key}
                                    shippingLine={sl}
                                    terminal={terminal}
                                    currentTerminal={currentTerminal}
                                    isBroken={
                                      Boolean(activeTerminals.length) &&
                                      !activeTerminals.includes(terminal.key)
                                    }
                                    layout={layout}
                                    isSelected={this.isCellSelected(
                                      layout.day,
                                      layout.shift,
                                      terminal
                                    )}
                                    onClick={
                                      onCellClick &&
                                      (!disableClickEmpty ||
                                        currentAvailability)
                                        ? () =>
                                            onCellClick(
                                              sl.key,
                                              cType.key,
                                              terminal.key,
                                              layout,
                                              currentAvailability &&
                                                currentAvailability.status
                                            )
                                        : undefined
                                    }
                                  />
                                );
                              })
                            )}
                          </TableRow>
                        );
                      })
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          </ScrollSyncPane>
          <Caption />
        </div>
      </ScrollSync>
    );
  }
}
