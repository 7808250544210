import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1F334B',
      light: '#35475D',
      half: 'rgba(31, 51, 75, 0.5)',
      warning: '#E6AB61',
      text: '#223349',
    },
    secondary: { main: '#f1a852' },
    alert: {
      main: '#FF5023',
      light: 'rgb(255, 238, 233)',
      error: '#ffd3c6',
    },
    green: {
      main: green[800],
      light: green[400],
    },
    grey: {
      light: '#f4f5f6',
      main: grey[700],
      link: '#35475D',
      background: '#EFF0F1',
      warningGrey: '#9e9e9e',
    },
    white: {
      main: '#FAFAFA',
      background: '#EFF0F1',
    },
    inputBackground: {
      main: 'rgba(31, 51, 75, 0.1)',
    },
  },
  layout: {
    popupWidth: 400,
  },
  size: {
    portIconLink: {
      height: 62,
    },
    lastRefresh: {
      height: 38,
    },
  },
  background: {
    greyHatched: `repeating-linear-gradient( -54deg, transparent, transparent 5px, ${grey[300]} 2px, ${grey[300]} 7px)`,
  },
  typography: {
    useNextVariants: true,
    h2: {
      color: '#1F334B',
      fontSize: 30,
      fontWeight: 'bold',
    },
    h3: {
      color: '#1F334B',
      fontSize: 14,
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 12,
      color: 'rgba(31, 51, 75, 0.5)',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '30px',
      color: '#1F334B',
    },
    h6: {
      color: '#001644',
    },
    subtitle1: {
      color: '#001644',
    },
    subtitle2: {
      color: '#001644',
      fontWeight: 'bold',
    },
    body1: {
      color: '#1F334B',
      fontFamily: 'Arial',
      fontSize: 14,
    },
    caption: {
      color: '#8E98A4',
      fontSize: 12,
    },
  },
  overrides: {
    MuiChip: {
      root: {
        height: 26,
        bagroundColor: 'rgba(31, 51, 75, 0.05)',
      },
      deleteIcon: {
        fontSize: 16,
      },
      label: {
        color: '#8E98A4',
        fontSize: 12,
      },
    },
    MuiSwitch: {
      switchBase: {
        '&$checked': {
          opacity: 1,
          '& + $bar': {
            opacity: 0.2,
          },
        },
        color: '#1F334B',
        opacity: 0.3,
        width: 55,
        transform: 'translateX(-13px)',
      },
      bar: {
        width: 52,
        height: 26,
        borderRadius: 13,
        top: '38%',
        left: '32%',
        backgroundColor: 'rgb(31, 51, 75)',
        opacity: 0.2,
      },
      icon: {
        width: 26,
        height: 26,
      },
    },
    MuiTypography: {
      colorTextPrimary: {
        color: 'white',
      },
    },
    MuiButton: {
      containedSecondary: {
        color: '#eee',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(31, 51, 75, 0.1)',
      },
      paddingNone: {
        paddingLeft: 8,
        paddingRight: 8,
        '&:last-child': {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
    },
  },
});

export default theme;
