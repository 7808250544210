export const globalTheme = {
  colors: {
    white: '#FFFFFF',
    blue: {
      DEFAULT: '#0025F1',
      100: '#3554ff',
      200: '#798dff',
      300: '#bcc6ff',
      400: '#dde3ff',
      500: '#ebeeff',
      600: '#f8f9ff',
    },
    orange: {
      DEFAULT: '#F19100',
      100: '#ffa318',
      200: '#ffb23f',
      300: '#ffc265',
      400: '#ffd18c',
      500: '#ffe0b2',
      600: '#fff0d9',
    },
    green: {
      DEFAULT: '#53BE7A',
      light: '#e9f7ee',
    },
    red: {
      DEFAULT: '#F10000',
      light: '#ffdddd',
    },
    dark: {
      DEFAULT: '#000106',
    },
    gray: {
      DEFAULT: '#333438',
      100: '#4D4E51',
      200: '#66676A',
      300: '#7F7F82',
      400: '#99999B',
      500: '#B3B3B5',
      600: '#E6E6E7',
    },
  },
  boxShadow: {
    sm: '1px 2px 4px rgba(0, 0, 0, 0.11)',
    md: '4px 8px 16px rgba(0, 0, 0, 0.1);',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    none: 'none',
  },
};
