import ChangePasswordButton from './ChangePasswordButton';
import { withStyles } from '@material-ui/core/styles';
import styles from './ChangePasswordButton.style';
import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = state => ({
  email: state.user.email,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(ChangePasswordButton);
