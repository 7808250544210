import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeProvider } from '../../themeProvider';
import ContainerTrackingPage from './ContainerTrackingPage';
import { useParams } from 'react-router-dom';
import { SelectedContainerContextProvider } from './ContainerDetail/SelectedContainerContextProvider';
import { useUserContext } from 'common/contexts/UserContext';

export default function ContainerTracking() {
  const { containerNumber } = useParams();
  const { user, fetchUser } = useUserContext();
  return (
    <ThemeProvider theme={themeProvider}>
      <SelectedContainerContextProvider containerNumber={containerNumber}>
        <ContainerTrackingPage
          currentUser={user}
          fetchUser={fetchUser}
          selectedContainerNumber={containerNumber}
        />
      </SelectedContainerContextProvider>
    </ThemeProvider>
  );
}
