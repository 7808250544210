export default theme => ({
  root: {},
  button: {
    margin: 3 * theme.spacing.unit,
  },
  change: {
    color: theme.palette.primary.main,
    opacity: 0.5,
    fontSize: 10,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  success: {
    color: theme.palette.green.main,
    margin: theme.spacing.unit,
    display: 'flex',
    fontSize: 10,
    alignItems: 'center',
  },
  fail: {
    color: theme.palette.alert.main,
    margin: theme.spacing.unit,
    display: 'flex',
    fontSize: 10,
    alignItems: 'center',
  },
});
