import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '../../../../common/UI/ModalWrapper';
import BookAppointmentModalForm from './BookAppointmentModalForm';
import BookAppointmentModalLoading from './BookAppointmentModalLoading';
import BookAppointmentModalConfirmed from './BookAppointmentModalConfirmed';
import BookAppointmentModalError from './BookAppointmentModalError';
import { chassis } from '../NextAppointments/constants/next-appointments.constants';
import { NextAppointmentsService } from '../NextAppointments/services/next-appointments.service';
import { bookAppointment } from 'services/clients/availability';
import './BookAppointmentModal.style.css';
import { useNavigate } from 'react-router-dom';

let isBookAppointmentModalComponentMounted = false;

const BookAppointmentModal = ({
  user,
  classes,
  history,
  onModalClose,
  appointmentData,
}) => {
  const [modalStatus, setModalStatus] = useState('');
  const [bookErrorBE, setBookErrorBE] = useState('');
  const navigate = useNavigate();

  const [confirmationData, setConfirmationData] = useState(null);

  const checkContainerNumber = value => {
    const isContainerNumberValid = NextAppointmentsService.validateContainerNumber(
      value
    );
    const notValidContainerNumberMsg = 'Please enter a valid container number';
    setIsContainerNumberValidWarning(
      value && !isContainerNumberValid ? notValidContainerNumberMsg : ''
    );
  };

  useEffect(() => {
    isBookAppointmentModalComponentMounted = true;
    const { containerNumberInput } = appointmentData || {};
    checkContainerNumber(containerNumberInput);
    return () => {
      isBookAppointmentModalComponentMounted = false;
    };
  }, []);

  const onCancelLoadingClicked = () => {
    onModalClose();
  };

  const handleCloseConfirmationModalClick = () => {
    onModalClose();
    setBookErrorBE('');
  };

  const handlePickAnotherSlotClick = () => {
    setModalStatus('');
    setBookErrorBE('');
  };

  const redirectToMyAppointments = () => {
    navigate('/my-appointments');
  };

  const { terminal, selectedSL, containerNumberInput } = appointmentData || {};
  const { key: selectedSlKey } = selectedSL || {};
  const {
    terminalKey,
    start_time,
    end_time,
    start_date,
    appointment_uuid,
    terminal_date,
    shift,
  } = terminal || {};
  const { day, shift: currentShift } = shift || {};

  const defaultValue = {
    shift_date: day,
    shift: currentShift,
    start_time,
    end_time,
    appointment_uuid,
    terminal_date,
    start_date,
  };

  const [selectedAppointment, setSelectedAppointment] = useState(defaultValue);

  const [selectedChassis, setSelectedChassis] = useState(chassis.ownChassis);
  const [containerNumber, setContainerNumber] = useState(
    containerNumberInput || ''
  );
  const [chassisNumber, setChassisNumber] = useState('');
  const [licensePlate, setLicensePlate] = useState('');

  const [requiredFieldsWarnings, setRequiredFieldsWarnings] = useState({
    containerNumberRequiredWarning: false,
    appointmentRequiredWarning: false,
    portChassisRequiredWarning: false,
  });

  const [
    isContainerNumberValidWarning,
    setIsContainerNumberValidWarning,
  ] = useState('');

  const onAppointmentChange = (event, newValue) => {
    event.preventDefault();
    setSelectedAppointment(newValue);
    if (newValue) {
      setRequiredFieldsWarnings({
        ...requiredFieldsWarnings,
        appointmentRequiredWarning: false,
      });
    }
  };

  const onContainerNumberChange = event => {
    const { target } = event || {};
    const { value } = target || {};

    checkContainerNumber(value);

    setContainerNumber(value);
    setRequiredFieldsWarnings({
      ...requiredFieldsWarnings,
      containerNumberRequiredWarning: false,
    });
  };

  const setOwnChassis = () => {
    setSelectedChassis(chassis.ownChassis);
    setRequiredFieldsWarnings({
      ...requiredFieldsWarnings,
      portChassisRequiredWarning: false,
    });
  };

  const setPortChassis = () => {
    setSelectedChassis(chassis.portChassis);
  };

  const onChassisNumberChange = event => {
    setChassisNumber(event.target.value);
    setRequiredFieldsWarnings({
      ...requiredFieldsWarnings,
      portChassisRequiredWarning: false,
    });
  };

  const onLicensePlateChange = event => {
    setLicensePlate(event.target.value);
  };

  const handleBookAppointmentClick = () => {
    const containerNumberRequiredWarning = containerNumber ? false : true;
    const appointmentRequiredWarning = selectedAppointment ? false : true;
    const portChassisRequiredWarning =
      selectedChassis === chassis.portChassis && !chassisNumber ? true : false;
    setRequiredFieldsWarnings({
      containerNumberRequiredWarning,
      appointmentRequiredWarning,
      portChassisRequiredWarning,
    });

    const isContainerNumberValid = NextAppointmentsService.validateContainerNumber(
      containerNumber
    );

    if (
      containerNumberRequiredWarning ||
      appointmentRequiredWarning ||
      portChassisRequiredWarning ||
      !isContainerNumberValid
    )
      return;

    const dataToSend = {
      terminalKey,
      shippingLineKey: selectedSlKey,
      containerNumber,
      appointment: {
        shift: selectedAppointment.shift,
        shift_date: selectedAppointment.shift_date,
        start_time: selectedAppointment.start_time,
        end_time: selectedAppointment.end_time,
        uuid: selectedAppointment.appointment_uuid,
        terminal_date: selectedAppointment.terminal_date,
      },
      chassisNumber:
        selectedChassis === chassis.portChassis ? chassisNumber : '', // if not empty - this is port chassis
      truckLicense: licensePlate, // optional
    };

    setModalStatus('loading');

    bookAppointment(dataToSend, (error, res) => {
      if (error) {
        console.log('error ', error);
        if (!isBookAppointmentModalComponentMounted) return;
        const { response } = error || {};
        const { data } = response || {};
        if (data && typeof data === 'string') {
          setBookErrorBE(data);
        }
        setModalStatus('error');
      } else {
        // console.log('SUCCESS res ', res);
        if (!isBookAppointmentModalComponentMounted) return;

        const { data } = res || {};
        const { appointment_id, container_id } = data || {};
        const { terminal } = appointmentData || {};
        const { name, pier, appointment_system_name } = terminal || {};

        const { appointment } = dataToSend || {};
        const { start_time, end_time, terminal_date } = appointment || {};

        NextAppointmentsService.fireGASuccessAppointmentBookingEvent(
          user,
          appointment_id,
          container_id
        );

        setConfirmationData({
          name,
          pier,
          appointment_system_name,
          start_time,
          end_time,
          terminal_date,
          confirmNumber: appointment_id,
          containerNumber: container_id,
        });

        setModalStatus('confirmed');
      }
    });
  };

  const renderModalContent = () => {
    let content = (
      <BookAppointmentModalForm
        data={appointmentData}
        classes={classes}
        onModalClose={onModalClose}
        onBookAppointmentClick={handleBookAppointmentClick}
        selectedAppointment={selectedAppointment}
        selectedChassis={selectedChassis}
        containerNumber={containerNumber}
        chassisNumber={chassisNumber}
        licensePlate={licensePlate}
        requiredFieldsWarnings={requiredFieldsWarnings}
        isContainerNumberValidWarning={isContainerNumberValidWarning}
        onAppointmentChange={onAppointmentChange}
        onContainerNumberChange={onContainerNumberChange}
        setOwnChassis={setOwnChassis}
        setPortChassis={setPortChassis}
        onChassisNumberChange={onChassisNumberChange}
        onLicensePlateChange={onLicensePlateChange}
      />
    );

    if (modalStatus === 'loading') {
      content = (
        <BookAppointmentModalLoading
          onCancelLoadingClicked={onCancelLoadingClicked}
        />
      );
    }

    if (modalStatus === 'confirmed') {
      content = (
        <BookAppointmentModalConfirmed
          confirmationData={confirmationData}
          onRedirectToMyAppointments={redirectToMyAppointments}
          onCloseConfirmationModalClick={handleCloseConfirmationModalClick}
        />
      );
    }

    if (modalStatus === 'error') {
      content = (
        <BookAppointmentModalError
          bookErrorBE={bookErrorBE}
          onPickAnotherSlotClick={handlePickAnotherSlotClick}
          onCloseConfirmationModalClick={handleCloseConfirmationModalClick}
        />
      );
    }

    return content;
  };

  return (
    <ModalWrapper
      width={modalStatus === '' ? 401 : 341}
      withoutBackground
      onModalClose={modalStatus === 'loading' ? null : onModalClose}
    >
      {renderModalContent()}
    </ModalWrapper>
  );
};

BookAppointmentModal.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  onModalClose: PropTypes.func,
  appointmentData: PropTypes.object,
};

export default BookAppointmentModal;
