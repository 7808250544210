import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { withStyles } from '@material-ui/core/styles';
import erlClient from 'services/clients';

import Button from 'components/customDesign/Button';

const ValidateUserDelete = ({ classes, selectedMember, submitCallback }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const removeUser = async () => {
    setError(null);
    try {
      await erlClient.delete('/core/api/v1/auth/company/member/delete', {
        data: {
          email: selectedMember.email,
        },
      });
      close();
      submitCallback && submitCallback();
    } catch (e) {
      console.error(e.response);
      setError(e.response.data.message);
    }
  };
  return (
    <React.Fragment>
      <Typography align="right" onClick={open} className={classes.deleteLink}>
        End membership
      </Typography>
      <Dialog open={isOpen} onClose={close} classes={{ paper: classes.paper }}>
        <Avatar className={classes.avatar}>
          <TrashIcon />
        </Avatar>
        <Typography align="center" variant="h2" gutterBottom>
          Are you sure?
        </Typography>
        <Typography
          align="center"
          variant="caption"
          className={classes.subtitle}
          gutterBottom
        >
          The member will lose access to your team and will be downgraded to
          Starter plan.
        </Typography>
        {error && (
          <Typography variant="caption" className={classes.error} gutterBottom>
            {error}
          </Typography>
        )}
        <Button
          className={classes.button}
          onClick={removeUser}
          rightIcon={
            <Avatar className={classes.avatarButton}>
              <TrashIcon height="12px" />
            </Avatar>
          }
        >
          End membership
        </Button>
      </Dialog>
    </React.Fragment>
  );
};

ValidateUserDelete.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedMember: PropTypes.object.isRequired,
  submitCallback: PropTypes.func,
};

const styles = theme => ({
  paper: {
    paddingTop: 4 * theme.spacing.unit,
    paddingBottom: 4 * theme.spacing.unit,
    paddingLeft: 12 * theme.spacing.unit,
    paddingRight: 12 * theme.spacing.unit,
    textAlign: 'center',
  },
  subtitle: {
    maxWidth: 240,
  },
  error: {
    maxWidth: 240,
    color: theme.palette.alert.main,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    margin: 'auto',
    marginBottom: 2 * theme.spacing.unit,
  },
  avatarButton: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing.unit,
  },
  button: {
    margin: 'auto',
    marginTop: 2 * theme.spacing.unit,
    width: 200,
  },
  deleteLink: {
    fontSize: 10,
    textDecoration: 'underline',
    color: 'rgba(255, 80, 35, 0.5)',
    cursor: 'pointer',
  },
});

export default withStyles(styles)(ValidateUserDelete);
