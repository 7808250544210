import { getMyAppointmentsEnd } from 'services/clients/myAppointments';
import { startLoading, endLoading } from 'ducks/loader';

// Action Types
export const SET_MY_APPOINTMENTS = 'core/api/v1/erl/SET_MY_APPOINTMENTS';
export const CLEAR_MY_APPOINTMENTS = 'core/api/v1/erl/CLEAR_MY_APPOINTMENTS';

// Reducer
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_APPOINTMENTS:
      return action.payload;
    case CLEAR_MY_APPOINTMENTS:
      return initialState;
    default:
      return state;
  }
};

// Action creators
export const setMyAppointments = myAppointments => {
  return {
    type: SET_MY_APPOINTMENTS,
    payload: myAppointments,
  };
};

export const setClearMyAppointments = () => {
  return {
    type: CLEAR_MY_APPOINTMENTS,
  };
};

// Side effects
export const getMyAppointments = (
  page,
  sortParams,
  searchParams
) => async dispatch => {
  try {
    dispatch(startLoading('isMyAppointmentsLoading'));
    const data = await getMyAppointmentsEnd(page, sortParams, searchParams);
    dispatch(setMyAppointments(data));
    // if (callback) {
    //   callback(data);
    // }
    dispatch(endLoading('isMyAppointmentsLoading'));
  } catch (error) {
    console.log('getMyAppointments error ', error);
    dispatch(endLoading('isMyAppointmentsLoading'));
  }
};
