import React from 'react';
import PropTypes from 'prop-types';

export const ChassisIcon = ({ color = 'grey' }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9706 15.8899V18.4345C16.9706 18.7468 17.2208 19 17.5294 19H21.4412C21.7498 19 22 18.7468 22 18.4345V15.8899C22 15.5776 21.7498 15.3244 21.4412 15.3244H20V13V11V9.10419H21.4412C21.7498 9.10419 22 8.85101 22 8.53871V5.99407C22 5.68176 21.7498 5.42859 21.4412 5.42859H17.5294C17.2208 5.42859 16.9706 5.68176 16.9706 5.99407V8.53871C16.9706 8.85101 17.2208 9.10419 17.5294 9.10419H19V11H6V9.10419H7.47059C7.77922 9.10419 8.02941 8.85101 8.02941 8.53871V5.99407C8.02941 5.68176 7.77922 5.42859 7.47059 5.42859H3.55882C3.25019 5.42859 3 5.68176 3 5.99407V8.53871C3 8.85101 3.25019 9.10419 3.55882 9.10419H5V11V13V15.3244H3.55882C3.25019 15.3244 3 15.5776 3 15.8899V18.4345C3 18.7468 3.25019 19 3.55882 19H7.47059C7.77922 19 8.02941 18.7468 8.02941 18.4345V15.8899C8.02941 15.5776 7.77922 15.3244 7.47059 15.3244H6V13H19V15.3244H17.5294C17.2208 15.3244 16.9706 15.5776 16.9706 15.8899Z"
      fill={color}
    />
  </svg>
);

ChassisIcon.propTypes = {
  color: PropTypes.string,
};
