import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from 'react-router-dom';
import { pageTitlesAndUrls } from 'constants/pages';
import { useUserContext } from 'common/contexts/UserContext';
import { SegmentService } from 'services/helpers/segment';

const CheckoutStatus = ({ selectedPack }) => {
  const { user, fetchUser } = useUserContext();

  useEffect(() => {
    fetchUser();
    const {
      email,
      first_name,
      last_name,
      phone_number,
      pack,
      company_scac,
    } = user;
    SegmentService.trackEvent(`User checkout success`, {
      email: email,
      firstName: first_name,
      lastName: last_name,
      phone: phone_number,
      currentPlan: pack,
      companyScac: company_scac,
      selectedPack: selectedPack.displayName,
    });
  }, []);

  return (
    <div className="flex flex-col space-y-8 items-start">
      <div className="flex flex-col">
        <h1 style={{ fontSize: '36px' }} className="font-bold">
          Your successfully subscribed to a {selectedPack.displayName}
        </h1>
        <h2 className="font-medium text-dark mt-8">
          Thank you for your subscription!
        </h2>
      </div>
      <div className="flex space-x-4">
        <Link to={pageTitlesAndUrls.containerTracking.path}>
          <Button variant="contained" color="primary" size="medium">
            <p className="font-bold">
              Track your containers <ArrowRightAltIcon sx={{ fontSize: 17 }} />
            </p>
          </Button>
        </Link>
        <Link to={pageTitlesAndUrls.profile.path}>
          <Button variant="outlined" color="primary" size="medium">
            <p className="font-bold">Invite team members</p>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CheckoutStatus;
