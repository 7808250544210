import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/customDesign/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from 'components/customDesign/Input';
import { ReactComponent as ProfileIcon } from 'assets/images/Profile.svg';

export default class Formular extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const {
      classes,
      handleSubmit,
      isSubmitting,
      errors,
      touched,
      handleChange,
      handleBlur,
      values,
    } = this.props;

    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        <div>
          <FormControl
            required
            margin="normal"
            fullWidth
            error={errors.email && touched.email}
          >
            <Input
              id="email"
              name="email"
              type="email"
              autoFocus
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormControl>
          <FormControl
            required
            margin="normal"
            fullWidth
            error={errors.password && touched.password}
          >
            <Input
              name="password"
              type="password"
              id="password"
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
          </FormControl>
        </div>
        <div className={classes.errors}>{errors.non_field_errors}</div>
        <Button
          id="submit-sign-in"
          type="submit"
          className={classes.submit}
          disabled={isSubmitting}
          classes={{ root: classes.buttonRoot }}
          isLoading={isSubmitting}
          rightIcon={
            <ProfileIcon
              width={20}
              height={20}
              className={classes.buttonIcon}
            />
          }
        >
          Sign in
        </Button>
      </form>
    );
  }
}
