import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ClickAwayListener } from '@mui/material';

const ActionDropdown = ({
  label,
  disabled,
  actionItems,
  height,
  minWidth,
  zIndex,
  active = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClick = disabled => {
    if (!disabled) {
      setIsDropdownOpen(true);
    }
  };

  const disabledDropdownStyle = 'bg-blue-600 border-blue-400 text-gray-400';
  const defaultDropdownStyle =
    'bg-white border-blue-400 text-gray-200 cursor-pointer';
  const activeDropdownStyle = 'bg-white border-blue text-blue cursor-pointer';
  const disabledItemStyle = 'text-gray-500';
  const activeItemStyle =
    'w-full cursor-pointer hover:bg-blue-600 hover:text-blue';
  const firstItemBorderRadius = 'rounded-t';
  const lastItemBorderRadius = 'rounded-b';

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <div
        className="relative flex-shrink-0"
        style={{
          height,
          minWidth,
          zIndex,
        }}
      >
        <div
          className={`w-full h-full flex items-center justify-between px-4 pr-2 border rounded ${
            disabled
              ? disabledDropdownStyle
              : active
              ? activeDropdownStyle
              : defaultDropdownStyle
          }`}
          onClick={event => {
            event.stopPropagation();
            handleDropdownClick(disabled);
          }}
        >
          <h4>{label}</h4>
          <KeyboardArrowDownIcon />
        </div>
        {isDropdownOpen && (
          <div className="absolute w-52 top-0 bg-white border border-blue rounded shadow-sm text-dark">
            {actionItems.map((item, index) => {
              return (
                <div
                  key={item.id}
                  onClick={() => {
                    item.actionCallback(item.disabled);
                    if (!item.disabled) {
                      setIsDropdownOpen(false);
                    }
                  }}
                  className={`${
                    item.disabled ? disabledItemStyle : activeItemStyle
                  } ${index === 0 && firstItemBorderRadius} ${index ===
                    actionItems.length - 1 && lastItemBorderRadius}`}
                >
                  <h4 className="py-2 px-4 font-medium">{item.name}</h4>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default ActionDropdown;
