export const terminals = [
  {
    key: 'lbct',
    name: 'LBCT',
    pier: 'PIER E',
    location: 'USLGB',
    port_name: 'Long Beach',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/lbct.png',
    website: 'https://www.lbct.com/Operations/EmptyRestrictions',
    terminal_portal: 'https://www.lbct.com/ViewMyList',
    appointment_system_name: 'LBCT Portal',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 5,
  },
  {
    key: 'wbct',
    name: 'WBCT',
    pier: 'WBCT',
    location: 'USLAX',
    port_name: 'Los Angeles',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/wbct.png',
    website: 'https://voyagertrack.portsamerica.com/logon?siteId=WBCT_LA',
    terminal_portal:
      'https://voyagertrack.portsamerica.com/logon?siteId=WBCT_LA',
    appointment_system_name: 'WBCT Portal',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 2,
  },
  {
    key: 'tti',
    name: 'TTI',
    pier: 'PIER T',
    location: 'USLGB',
    port_name: 'Long Beach',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/tti.png',
    website: 'http://www.ttilgb.com/main/index.do',
    terminal_portal: 'https://www.ttilgb.com',
    appointment_system_name: 'TTI Portal',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 3,
  },
  {
    key: 'ptsc',
    name: 'PTSC',
    pier: 'Pier S',
    location: 'USLGB',
    port_name: 'Long Beach',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/piers.jpg',
    website: 'https://erl.bluecargo.io/terminals',
    terminal_portal: '',
    appointment_system_name: '',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: false,
    portal: 7,
  },
  {
    key: 'apm',
    name: 'APMT',
    pier: 'PIER 400',
    location: 'USLAX',
    port_name: 'Los Angeles',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/apm.png',
    website:
      'https://www.apmterminals.com/en/los-angeles/practical-information/empty-receivables-and-open-areas',
    terminal_portal: 'https://termpoint.apmterminals.com/',
    appointment_system_name: 'Termpoint',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 6,
  },
  {
    key: 'matson',
    name: 'SSA Matson',
    pier: 'Pier C',
    location: 'USLGB',
    port_name: 'Long Beach',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/matson.png',
    website: 'https://erl.bluecargo.io/terminals',
    terminal_portal: '',
    appointment_system_name: '',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 4,
  },
  {
    key: 'everport',
    name: 'ETS',
    pier: 'Everport',
    location: 'USLAX',
    port_name: 'Los Angeles',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/everport.png',
    website: 'https://www.etslink.com/',
    terminal_portal: 'http://ecp2.emodal.com/login',
    appointment_system_name: 'eModal',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 4,
  },
  {
    key: 'yti',
    name: 'YTI',
    pier: 'YTI',
    location: 'USLAX',
    port_name: 'Los Angeles',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/yti.png',
    website: 'https://yti.com/services/',
    terminal_portal:
      'https://yti.voyagecontrol.com/dashboard/containers-empties-booking/',
    appointment_system_name: 'Voyage Control',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 1,
  },
  {
    key: 'fms',
    name: 'Fenix',
    pier: 'PIER 300',
    location: 'USLAX',
    port_name: 'Los Angeles',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/fms.png',
    website: 'https://www.fenixmarineservices.com/empty-receiving/',
    terminal_portal:
      'https://fenixmarine.voyagecontrol.com/dashboard/containers-empties-booking/',
    appointment_system_name: 'Voyage Control',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 1,
  },
  {
    key: 'trapac',
    name: 'Trapac',
    pier: 'Trapac',
    location: 'USLAX',
    port_name: 'Los Angeles',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/trapac.png',
    website: 'https://losangeles.trapac.com/empty-returns/',
    terminal_portal: 'http://ecp2.emodal.com/login',
    appointment_system_name: 'eModal',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 4,
  },
  {
    key: 'ssa_piera',
    name: 'SSA Pier A',
    pier: 'PIER A',
    location: 'USLGB',
    port_name: 'Long Beach',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/piera.png',
    website:
      'https://piera.tideworks.com/fc-PA/home/default.do?method=page&id=0',
    terminal_portal: 'http://ecp2.emodal.com/login',
    appointment_system_name: 'eModal',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 4,
  },
  {
    key: 'ssa_pct',
    name: 'SSA PCT',
    pier: 'PCT',
    location: 'USLGB',
    port_name: 'Long Beach',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/pct.png',
    website:
      'https://pct.tideworks.com/fc-PCT/home/default.do?method=page&id=0',
    terminal_portal: 'http://ecp2.emodal.com/login',
    appointment_system_name: 'eModal',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: false,
    portal: 4,
  },
  {
    key: 'its',
    name: 'ITS',
    pier: 'PIER G',
    location: 'USLGB',
    port_name: 'Long Beach',
    logo:
      'https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/terminals/logo/its.png',
    website: 'https://www.itslb.com/empty-receiving-updates/',
    terminal_portal: 'http://ecp2.emodal.com/login',
    appointment_system_name: 'eModal',
    no_data: false,
    no_data_appointments: false,
    is_booking_available: true,
    portal: 4,
  },
];
