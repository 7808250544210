import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from 'components/customDesign/Button';
import DialogContent from 'components/customDesign/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import ListInfoItem from 'components/customDesign/ListInfoItem';
import { pageTitlesAndUrls } from 'constants/pages';
import { useNavigate } from 'react-router-dom';

const TrialEndDialog = ({ classes }) => {
  const [isOpen, setIsOpen] = useState(true);
  const close = () => setIsOpen(false);
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(pageTitlesAndUrls.plans.path);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={close} classes={{ paper: classes.root }}>
      <DialogContent
        TopIcon={({ className, ...props }) => (
          <Avatar className={cn(className, classes.avatar)} {...props}>
            !
          </Avatar>
        )}
        title="Time's up!"
        subtitle="Your free trial has ended"
        callToAction={
          <React.Fragment>
            <Button onClick={handleOnClick} classes={{ root: classes.button }}>
              See the plans
            </Button>
            <div>
              <Typography
                className={classes.underline}
                variant="caption"
                component="a"
                align="center"
                href="mailto:support@bluecargo.io,alexandra@bluecargo.io?bcc=laura@bluecargo.io"
              >
                Contact us
              </Typography>
            </div>
          </React.Fragment>
        }
      >
        <ListInfoItem
          primary="Choose a plan to continue"
          secondary="Pick up where you left off with a premium plan"
        />
        <ListInfoItem
          primary="Enter a credit card"
          secondary="A credit card is required to unlock a premium monthly subscription"
        />
        <ListInfoItem
          primary="Need help?"
          secondary={
            <span>
              <a
                className={classes.underline}
                href="mailto:support@bluecargo.io,alexandra@bluecargo.io?bcc=laura@bluecargo.io"
              >
                Contact us
              </a>{' '}
              and find the best plan for your team
            </span>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

TrialEndDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    margin: 'auto',
    marginBottom: 2 * theme.spacing.unit,
    marginTop: 2 * theme.spacing.unit,
  },
  root: {
    padding: 3 * theme.spacing.unit,
    width: theme.layout.popupWidth,
  },
  underline: {
    color: '#8E98A4',
    textDecoration: 'underline',
  },
});

export default withStyles(styles)(TrialEndDialog);
