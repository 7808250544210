import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../UserAvatar';
import EditIcon from '@mui/icons-material/Edit';
import { getFirstLetters } from 'services/helpers/user';

const Member = ({ member, admin, onClick, classes }) => {
  const [displayIcon, setDisplayIcon] = useState(false);
  return (
    <div
      onClick={() => admin && onClick(member)}
      onMouseEnter={() => setDisplayIcon(true)}
      onMouseLeave={() => setDisplayIcon(false)}
      key={member.email}
      className="p-4 flex w-full justify-between items-center hover:bg-blue-600"
    >
      <div className="flex space-x-4 items-center">
        <UserAvatar size="small" letters={getFirstLetters(member)} />
        <div className="flex flex-col space-y-1">
          <h4 className="text-dark">{`${member.first_name} ${
            member.last_name
          } ${member.is_admin ? '(Admin)' : '(Member)'}`}</h4>
          <h4>{member.email}</h4>
        </div>
      </div>
      {admin && displayIcon && (
        <EditIcon style={{ fontSize: '14px' }} className="cursor-pointer" />
      )}
    </div>
  );
};
Member.propTypes = {
  member: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default Member;
