import React, { useState, useCallback } from 'react';
import ContainerTable from './components/ContainerTable';
import PageTitle from 'components/PageTitle/PageTitle';
import styles from '../../styles/typography.module.css';
import ContainerTrackingControls from './components/ContainerTrackingControls';
import BackupIcon from '@mui/icons-material/Backup';
import { Button } from '@mui/material';
import { useSnackbar } from 'common/hooks/snackbar';
import ContainerImportModal from './components/ContainerImportModal';
import { formatNumber } from 'utils/common';
import pluralize from 'pluralize';
import ContainerDetail from './ContainerDetail/ContainerDetailPage';
import { useUserContext } from 'common/contexts/UserContext';
import { BANNER_HEIGHT } from 'layouts/AppPageTemplate/utils/config';

const ContainerTrackingComponent = ({
  containerList,
  handleContainerInputChange,
  refreshContainerData,
  containerListRowCount,
  onPageChange,
  onPageSizeChange,
  onFilterSelection,
  loading,
  pageSize,
  page,
  warningText,
  showSelectionCount,
  actionItems,
  pinnedColumns,
  onDeleteClick,
  toggleDrawer,
  isFilterOpen,
  containerTypes,
  shippingLines,
  removeAllFilters,
  selectedFilters,
  filterCount,
  openAllAppointmentsModal,
  error,
  onGetPerDiemReport,
  currentUser,
  terminals,
  totalContainerWatched,
  filterTotals,
  onExport,
}) => {
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const { user } = useUserContext();
  const displaySnackbar = useSnackbar();
  const onModalClose = useCallback(
    (success, message) => {
      setImportModalIsOpen(false);
      if (success) {
        displaySnackbar({
          severity: 'info',
          html: () => (
            <>
              <h4>
                <span className="font-bold">Processing... </span>
                {message}
              </h4>
            </>
          ),
        });
      }
    },
    [setImportModalIsOpen]
  );

  const handleOnRowSelect = selection => {
    setSelectionModel(selection);
  };

  const handleOnDeleteClick = disabled => {
    if (!disabled) {
      onDeleteClick(selectionModel);
    }
  };

  const handleOnExport = useCallback(
    mode => {
      onExport(selectionModel, mode);
    },
    [selectionModel]
  );

  return (
    <>
      <ContainerImportModal isOpen={importModalIsOpen} onClose={onModalClose} />
      <ContainerDetail />

      <div className={`mb-14 bg-blue-600 ${styles.font} relative`}>
        {/* Sticky Header */}
        <div
          className="w-full border-b border-blue bg-blue-500 flex justify-center items-center"
          style={{ height: `${BANNER_HEIGHT}px` }}
        >
          <h4 className="font-medium text-blue">
            This is a new feature. Your{' '}
            <a
              href="mailto: laura@bluecargo.io"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              feedback
            </a>{' '}
            will help us improve it!
          </h4>
        </div>
        <div>
          <PageTitle
            title="Your Containers"
            subtitle={
              <TotalFilter
                totalContainerWatched={totalContainerWatched}
                countCurrentFilter={containerListRowCount}
                filterTotals={filterTotals}
              />
            }
            actions={
              <Button
                disabled={user.containerCreditsLimitReached}
                variant="link"
                onClick={() => setImportModalIsOpen(true)}
                startIcon={<BackupIcon style={{ fontSize: 14 }} />}
              >
                Import containers
              </Button>
            }
          />
        </div>
        <div className={`w-full h-full z-100`}>
          <ContainerTrackingControls
            handleContainerInputChange={handleContainerInputChange}
            warningText={warningText}
            selectedFilters={selectedFilters}
            filterCount={filterCount}
            onFilterSelection={onFilterSelection}
            showSelectionCount={showSelectionCount}
            selectionCount={containerListRowCount}
            actionItems={actionItems}
            onDeleteClick={handleOnDeleteClick}
            onExport={handleOnExport}
            selectionModel={selectionModel}
            toggleDrawer={toggleDrawer}
            isFilterOpen={isFilterOpen}
            containerTypes={containerTypes}
            shippingLines={shippingLines}
            removeAllFilters={removeAllFilters}
            terminals={terminals}
            currentViewCount={containerListRowCount}
          />
        </div>
        {/* Page Content */}
        <div className={`w-full h-full z-10`}>
          <ContainerTable
            containerList={containerList}
            refreshContainerData={refreshContainerData}
            containerListRowCount={containerListRowCount}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            pageSize={pageSize}
            loading={loading}
            page={page}
            onRowSelect={handleOnRowSelect}
            selectionModel={selectionModel}
            pinnedColumns={pinnedColumns}
            openAllAppointmentsModal={openAllAppointmentsModal}
            error={error}
            onGetPerDiemReport={onGetPerDiemReport}
            currentUser={currentUser}
            filterCount={filterCount}
            setImportModalIsOpen={setImportModalIsOpen}
          />
        </div>
      </div>
    </>
  );
};

const TotalFilter = ({
  totalContainerWatched,
  countCurrentFilter,
  filterTotals,
}) => {
  const filters = [
    {
      tag: 'tag-on-vessel',
      name: 'On vessel',
      totalCount: formatNumber(filterTotals.totalOnVessel || 0),
      currentCount: formatNumber(filterTotals.currentOnVessel || 0),
    },
    {
      tag: 'tag-import',
      name: 'Import',
      totalCount: formatNumber(filterTotals.totalImport || 0),
      currentCount: formatNumber(filterTotals.currentImport || 0),
    },
    {
      tag: 'tag-gated-out',
      name: 'Gated Out',
      totalCount: formatNumber(filterTotals.totalGatedOut || 0),
      currentCount: formatNumber(filterTotals.currentGatedOut || 0),
    },
    {
      tag: 'tag-default',
      name: 'Terminated',
      totalCount: formatNumber(filterTotals.totalTerminated || 0),
      currentCount: formatNumber(filterTotals.currentTerminated || 0),
    },
  ];
  return (
    <div className="flex text-sm font-medium space-x-1">
      <span className="flex items-center">
        {formatNumber(countCurrentFilter || 0)}
        <h6 className="text-gray-300 text-bold">
          /{formatNumber(totalContainerWatched || 0)}
          &nbsp;
        </h6>
        {pluralize('CONTAINER', totalContainerWatched)}
      </span>
      {filters.map((filter, idx) => (
        <React.Fragment key={idx}>
          <span>{'· '}</span>
          <div className="flex items-center space-x-0.5">
            <span className="flex items-center">
              {filter.currentCount}
              <h6 className="text-gray-300 text-bold">/{filter.totalCount}</h6>
            </span>
            <Button variant={filter.tag}>{filter.name}</Button>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ContainerTrackingComponent;
