import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingComponent = ({ children, isLoading, size = 12 }) => {
  if (isLoading) {
    return <CircularProgress size={size} />;
  }
  return children;
};

export default LoadingComponent;
