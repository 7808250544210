import React, { useState, useEffect } from 'react';
import CheckoutStatus from './CheckoutStatus';
import { getPacks } from '../../../services/clients/subscription';
import PlansPage from './PlansPage';
import CheckoutPage from './CheckoutPage';
import DowngradeRequest from './DowngradeRequest';
import _ from 'lodash';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { CALENDLY_LINK, CHECKOUT_STEPS } from './constants';
import { useUserContext } from 'common/contexts/UserContext';
import { SegmentService } from 'services/helpers/segment';

const fetchPacks = async () => {
  const packs = await getPacks();
  packs.forEach(pack => {
    if (!_.isNil(pack.upsellPack)) {
      const upsellPack = packs.find(p => p.id === pack.upsellPack.id);
      upsellPack.downSellPack = pack;
      upsellPack.descriptions.unshift({
        title: `All in ${pack.displayName}`,
      });
    }
  });
  return packs;
};

const PlansAndBillingPage = () => {
  const [packs, setPacks] = useState([]);
  const [selectedPack, setSelectedPack] = useState({});
  const [step, setStep] = useState(0);
  const { user } = useUserContext();

  const {
    email,
    first_name,
    last_name,
    phone_number,
    pack,
    company_scac,
  } = user;

  useEffect(async () => {
    const packs = await fetchPacks();
    setPacks(packs);
  }, []);

  const handlePackSelected = (packId, textButton) => {
    const selectedPack = packs.find(p => p.id === packId);
    SegmentService.trackEvent(`Plan Clicked`, {
      email: email,
      firstName: first_name,
      lastName: last_name,
      phone: phone_number,
      currentPlan: pack,
      companyScac: company_scac,
      packId: selectedPack.id,
      packName: selectedPack.displayName,
      textButton,
    });

    if (packId === 'bc_connect') {
      window.open(CALENDLY_LINK, '_blank');
    } else {
      setSelectedPack(selectedPack);
      setStep(CHECKOUT_STEPS.BUILD_PLAN);
    }
  };
  let subcomponent = (
    <PlansPage packs={packs} user={user} onPackSelected={handlePackSelected} />
  );
  if (
    [CHECKOUT_STEPS.BUILD_PLAN, CHECKOUT_STEPS.PAYMENT_METHOD].includes(step)
  ) {
    subcomponent =
      selectedPack.buttonText === 'downgrade' ? (
        <DowngradeRequest onBack={() => setStep(step - 1)} />
      ) : (
        <CheckoutPage
          selectedPack={selectedPack}
          onUpgrade={handlePackSelected}
          user={user}
          onCheckout={(isFreePlan = false) =>
            isFreePlan ? setStep(step + 2) : setStep(step + 1)
          }
          step={step}
        />
      );
  } else if (step === CHECKOUT_STEPS.CHECKOUT_STATUS) {
    subcomponent = <CheckoutStatus selectedPack={selectedPack} />;
  }

  return (
    <>
      {[CHECKOUT_STEPS.BUILD_PLAN, CHECKOUT_STEPS.PAYMENT_METHOD].includes(
        step
      ) && (
        <div
          className="flex space-x-2 items-center text-gray-300 font-regular cursor-pointer my-4"
          onClick={() => setStep(step - 1)}
        >
          <KeyboardBackspaceIcon />
          <h4>
            Back to{' '}
            {step === CHECKOUT_STEPS.BUILD_PLAN ? 'plans' : 'build your plan'}
          </h4>
        </div>
      )}
      {subcomponent}
    </>
  );
};

export default PlansAndBillingPage;
