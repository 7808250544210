export const userPlanNames = {
  pioneer: 'pioneer', //"Free trial"
  professional: 'per_diem_fighter_monthly', //"Per diem fighter"
  starter: 'starter', //"Starter"
  team: 'team', //"Dispatcher shop"
  trial_end: 'trial_end', //"Trial ended"
};

export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'access_token_local_storage';
export const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refresh_token_local_storage';
export const DISALLOWED_PER_DIEM_PACKS = ['starter', 'team', 'trial_end'];
export const SHIPPER_COMPANY_TYPE = 'shipper';
export const USER_CONTEXT_KEY = 'bc_user_context';
