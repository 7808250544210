import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'ducks/user';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/KeyboardArrowDown';
import { MENU_DROPDOWNS } from './utils/config';
import fonts from '../../styles/typography.module.css';
import { useUserContext } from 'common/contexts/UserContext';
import { checkPermissions } from 'components/PrivateRoute/permissions-utils';

const MobileMenu = ({ tabs, handleMenuOpen, settingsMenu }) => {
  const dispatch = useDispatch();
  const user = useUserContext();

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <div className={fonts.font}>
      <div className="h-screen w-full bg-blue p-8">
        <ul className="flex flex-col space-y-8">
          {tabs.map(({ path, title, isTabShown }) => {
            return (
              <li key={title} onClick={handleMenuOpen}>
                <Link to={path}>
                  <h1
                    className={`py-1 px-4 rounded-md text-blue-400 font-semibold hover:bg-dark-200`}
                  >
                    {title}
                  </h1>
                </Link>
              </li>
            );
          })}
          {MENU_DROPDOWNS.map(menu => {
            return (
              <li key={menu.title}>
                <Accordion
                  disableGutters
                  elevation={0}
                  defaultExpanded={false}
                  sx={[
                    () => ({
                      '&:before': {
                        backgroundColor: 'white',
                      },
                      background: 'none',
                    }),
                  ]}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      margin: 0,
                      padding: 0,
                      minHeight: '0px',
                      '& .MuiAccordionSummary-content': {
                        margin: '0px',
                      },
                    }}
                  >
                    <div
                      className={`w-full flex justify-between items-center py-1 px-4 rounded-md text-blue-400 font-semibold hover:bg-dark-200`}
                    >
                      <h1>{menu.title}</h1>
                      <ExpandMoreIcon style={{ fontSize: '36px' }} />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ margin: 0, padding: 0 }}>
                    <ul className="flex flex-col space-y-2 mt-4">
                      {menu.children
                        .filter(item => checkPermissions(user, item.path))
                        .map(item => {
                          return (
                            <li
                              key={item.title}
                              className="pl-4"
                              onClick={handleMenuOpen}
                            >
                              <Link key={item.title} to={item.path}>
                                <h2
                                  className={`py-1 px-4 rounded-md text-blue-400 font-semibold hover:bg-dark-200`}
                                >
                                  {item.title}
                                </h2>
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </li>
            );
          })}
          {settingsMenu.map(menu => {
            return (
              <li key={menu.title}>
                <Accordion
                  disableGutters
                  elevation={0}
                  defaultExpanded={false}
                  sx={[
                    () => ({
                      '&:before': {
                        backgroundColor: 'white',
                      },
                      background: 'none',
                    }),
                  ]}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      margin: 0,
                      padding: 0,
                      minHeight: '0px',
                      '& .MuiAccordionSummary-content': {
                        margin: '0px',
                      },
                    }}
                  >
                    <div
                      className={`w-full flex justify-between items-center py-1 px-4 rounded-md text-blue-400 font-semibold hover:bg-dark-200`}
                    >
                      <h1>{menu.title}</h1>
                      <ExpandMoreIcon style={{ fontSize: '36px' }} />
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ margin: 0, padding: 0 }}>
                    <ul className="flex flex-col space-y-2 mt-4">
                      {menu.children
                        .filter(item => checkPermissions(user, item.path))
                        .map(item => {
                          return (
                            <li
                              key={item.title}
                              className="pl-4"
                              onClick={handleMenuOpen}
                            >
                              <Link key={item.title} to={item.path}>
                                <h2
                                  className={`py-1 px-4 rounded-md text-blue-400 font-semibold hover:bg-dark-200`}
                                >
                                  {item.title}
                                </h2>
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </li>
            );
          })}
          <div className="w-full flex justify-between items-center py-1 px-4 rounded-md text-blue-400 font-semibold hover:bg-dark-200">
            <h2 onClick={handleLogout}>Log Out</h2>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
