import React from 'react';
import SingleContainerTypePicker from './SingleContainerTypePicker';
import { withStyles } from '@material-ui/core/styles';
import styles from './SingleContainerTypePicker.style';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setShippingLines, setContainerTypes } from 'ducks/erlData';
import { getShippingLinesList, getContainerTypesList } from 'ducks/erlData';
import { useNavigate } from 'react-router-dom';

const mapStateToProps = state => ({
  shippingLines: getShippingLinesList(state),
  containerTypes: getContainerTypesList(state),
  isLoadingShippingLines: state.loader.shippingLines,
  isLoadingContainerTypes: state.loader.containerTypes,
});

const mapDispatchToProps = dispatch => ({
  importData: () => {
    dispatch(setShippingLines());
    dispatch(setContainerTypes());
  },
});

const withHooksHOC = Component => {
  return props => {
    const navigate = useNavigate();
    return <Component navigate={navigate} {...props} />;
  };
};

export default compose(
  withHooksHOC,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SingleContainerTypePicker);
