import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';
import { CAPTION_ITEMS } from '../Caption/Caption';

export default class WithWarning extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    warnings: PropTypes.array.isRequired,
    children: PropTypes.node,
    status: PropTypes.string,
    isGrey: PropTypes.bool,
    isEmptyAppointments: PropTypes.bool,
  };
  static defaultProps = {
    warnings: [],
  };

  state = {
    anchorEl: null,
  };

  timerId = null;

  handlePopoverOpen = event => {
    const { isEmptyAppointments } = this.props;
    if (isEmptyAppointments) return;
    this.setState({
      anchorEl: event.currentTarget,
    });
    this.timerId = setTimeout(this.handlePopoverClose, 3000);
  };

  handlePopoverClose = () => {
    if (this.timerId) {
      this.setState({
        anchorEl: null,
      });
    }
  };

  componentWillUnmount() {
    this.timerId = null;
  }

  defineWarningIconStyle = () => {
    const { classes, isGrey, isEmptyAppointments } = this.props;
    let warningIconStyle = '';
    if (isGrey && isEmptyAppointments) {
      warningIconStyle = classes.warningIconEAGrey;
    } else if (isEmptyAppointments && !isGrey) {
      warningIconStyle = classes.warningIconEA;
    } else {
      warningIconStyle = classes.warningIcon;
    }
    return warningIconStyle;
  };

  render() {
    const {
      classes,
      warnings,
      children,
      status,
      isEmptyAppointments,
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const caption = CAPTION_ITEMS.find(i => i.key === status);
    if (!caption) return children;

    return (
      <div>
        <div
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
          aria-owns={open ? 'warning-popper' : undefined}
          aria-haspopup="true"
          className={cn(classes.withInstruction, 'warning-cell')}
        >
          {children}
          {warnings.length > 0 && (
            <WarningIcon
              width="24"
              height="24"
              fontSize="small"
              className={this.defineWarningIconStyle()}
            />
          )}
        </div>
        {!isEmptyAppointments && (
          <Popper
            id="warning-popper"
            open={open}
            anchorEl={anchorEl}
            className={classes.popover}
            onClose={this.handlePopoverClose}
          >
            <Paper className={classes.paper} square>
              {status && (
                <Typography className={classes.warningText}>
                  {caption.caption}
                </Typography>
              )}
              {warnings.map(warning => (
                <Typography
                  key={warning}
                  variant="caption"
                  className={classes.warningText}
                >
                  {warning}
                </Typography>
              ))}
            </Paper>
          </Popper>
        )}
      </div>
    );
  }
}
