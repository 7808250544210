import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Form from './components/Form';
import ShiftTabs from './components/ShiftTabs';
import Table from './components/Table';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import BlueCargoLogo from 'components/BlueCargoLogo';

export default class Availability extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    shippingLine: PropTypes.object,
    importData: PropTypes.func.isRequired,
    containerType: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
  };

  componentDidMount = () => {
    this.props.importData();
  };

  render() {
    const { classes, shippingLine, containerType, isLoading } = this.props;
    const initialValues =
      shippingLine && containerType
        ? { shippingLine: shippingLine.name, containerType: containerType.name }
        : undefined;
    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton id="back-home" component={Link} to="/">
              <BackIcon className={classes.backIcon} />
            </IconButton>
            <Form initialValues={initialValues} />
          </Toolbar>
          <ShiftTabs />
        </AppBar>
        <div className={classes.content}>
          {isLoading && (
            <CircularProgress size={70} className={classes.loader} />
          )}
          {shippingLine && containerType && (
            <Table shippingLine={shippingLine} containerType={containerType} />
          )}
        </div>
        <BlueCargoLogo classes={{ BC: classes.logo }} />
      </div>
    );
  }
}
