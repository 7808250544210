import React from 'react';

export const HatchedCell = () => (
  <div style={{ position: 'absolute', top: 0, left: 0 }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="130" height="89">
      <defs>
        <pattern
          id="transformedPattern"
          x="0"
          y="0"
          width="2"
          height="7"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(135)"
        >
          <circle
            cx="1"
            cy="1"
            r="1"
            style={{
              stroke: 'none',
              fill: '#c7c7c7',
            }}
          ></circle>
        </pattern>
      </defs>
      <rect
        x="0"
        y="0"
        width="130"
        height="89"
        style={{
          fill: 'url(#transformedPattern)',
        }}
      ></rect>
    </svg>
  </div>
);
