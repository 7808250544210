import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import TableItem from './RowEditor';

const TableBody = props => {
  const { rows, headers } = props;

  return (
    <tbody>
      {rows.map((rowData, index) => (
        <TableRow
          key={index}
          rowData={rowData.data}
          container={rowData.container}
          headers={headers}
        />
      ))}
    </tbody>
  );
};

const TableRow = props => {
  const { rowData, container, headers } = props;
  return (
    <tr>
      <td>{container}</td>
      {rowData.map((currentValue, index) => (
        <TableItem
          key={index}
          shiftIndex={index}
          currentValue={currentValue}
          container={container}
          headers={headers}
        />
      ))}
    </tr>
  );
};

TableBody.propTypes = {
  rows: PropTypes.array,
  headers: PropTypes.array,
};

TableRow.propTypes = {
  rowData: PropTypes.array,
  container: PropTypes.string,
  headers: PropTypes.array,
};

const mapStateToProps = state => ({
  rows: state.admin.ptscSchedule.rows,
  headers: state.admin.ptscSchedule.headers,
});

export default connect(mapStateToProps, {})(TableBody);
