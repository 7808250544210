import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InformationPage from '../../components/InformationPage';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import Avatar from '@material-ui/core/Avatar';
import Formular from './components/Formular';
import { changePassword } from '../../services/clients/user';
import { Link } from 'react-router-dom';
import { ReactComponent as KeyIcon } from 'assets/images/key.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';

export default class ChangePassword extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
  state = {
    reseted: false,
  };

  validate = values => {
    let errors = {};
    if (!values.password) errors.password = 'Required';
    else if (values.password.length < 8)
      errors.password = 'Your password must at least contain 8 characters';

    return errors;
  };

  submit = (values, { setSubmitting, setErrors }) => {
    changePassword(values)
      .then(() => {
        setSubmitting(false);
        this.setState({ reseted: true });
      })
      .catch(e => {
        const errors = e;

        setErrors(errors);
        setSubmitting(false);
      });
  };

  render() {
    const { classes, searchParams } = this.props;
    const token = searchParams.get('token');

    return (
      <InformationPage>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <KeyIcon />
            </Avatar>
            <Typography component="h1" variant="h5" gutterBottom>
              Password
            </Typography>
            <Typography variant="caption" gutterBottom>
              Password reset
            </Typography>

            {this.state.reseted ? (
              <div className="">
                <div className="w-full flex justify-center items-center">
                  <SuccessIcon className={classes.successIcon} />
                </div>
                <Typography variant="caption" gutterBottom>
                  {'You’re all set! '}
                </Typography>
                <Typography variant="caption">
                  <Link className={classes.link} to="/signin">
                    Log in
                  </Link>{' '}
                  to start using Blue Cargo
                </Typography>
              </div>
            ) : (
              <Formik
                initialValues={{ password: '', token }}
                validate={this.validate}
                onSubmit={this.submit}
              >
                {props => <Formular {...props} />}
              </Formik>
            )}
          </Paper>
        </main>
      </InformationPage>
    );
  }
}
