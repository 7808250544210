import React from 'react';

import css from './gateSchedule.module.css';
import GateScheduleContainer from './GateSchedule';

const GateSchedulesContainer = () => {
  return (
    <div className={css.scheduleWrapper}>
      <GateScheduleContainer />
    </div>
  );
};

export default GateSchedulesContainer;
