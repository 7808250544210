import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InformationPage from 'components/InformationPage';
import { Link } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from 'assets/images/success.svg';
import { welcomeVideo } from 'constants/youtube';
import { useSearchParams } from 'react-router-dom';

const Welcome = ({ classes }) => {
  const [searchParams] = useSearchParams();
  const company = searchParams.get('company');

  return (
    <InformationPage classes={{ container: classes.root }}>
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <SuccessIcon width={36} height={36} />
          <Typography
            component="h1"
            variant="h5"
            className={classes.title}
            gutterBottom
          >
            Thank you
          </Typography>
          <Typography
            variant="caption"
            className={classes.subtitle}
            gutterBottom
          >
            You're all set
          </Typography>
          <iframe
            className={classes.iframe}
            title="Demo"
            width="380"
            height="200"
            src={welcomeVideo}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <Typography component={Link} to="/" className={classes.link}>
            {company ? 'Continue to site' : 'Got it'}
          </Typography>
        </Paper>
      </main>
    </InformationPage>
  );
};

Welcome.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    justifyContent: 'unset',
  },
  subtitle: {
    marginTop: theme.spacing.unit,
    marginBottom: 3 * theme.spacing.unit,
  },
  iframe: {
    marginTop: 2 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
  },
  main: {
    width: 'auto',
    zIndex: 2,
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 12,
    marginBottom: theme.spacing.unit * 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    boxShadow: 'unset',
  },
  title: {
    marginTop: theme.spacing.unit * 3,
  },
  logo: {
    marginTop: 10 * theme.spacing.unit,
    marginBottom: 5 * theme.spacing.unit,
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.grey.link,
    fontSize: 12,
    marginTop: 5 * theme.spacing.unit,
  },
});

export default withStyles(styles)(Welcome);
