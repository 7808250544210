const styles = theme => ({
  root: {
    justifyContent: 'unset',
  },
  main: {
    width: 'auto',
    zIndex: 2,
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 12,
    marginBottom: theme.spacing.unit * 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    boxShadow: 'unset',
  },
  button: {
    marginTop: 2 * theme.spacing.unit,
  },
  title: {
    marginTop: theme.spacing.unit * 3,
  },
  link: {
    fontSize: 12,
    margin: theme.spacing.unit,
    textDecorationLine: 'underline',
    color: theme.palette.grey.link,
  },
});

export default styles;
