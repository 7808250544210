import CookieConsent from 'react-cookie-consent';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { getWidth } from 'services/helpers/layout';

function CookieBanner({ classes, theme }) {
  const width = getWidth(theme);
  const buttonSize = width === 'xs' ? 'small' : 'medium';
  return (
    <CookieConsent
      location="bottom"
      buttonText="I agree"
      expires={150}
      containerClasses={classes.container}
      contentClasses={classes.content}
      buttonClasses={classes.button}
      buttonId="agree-cookie"
      ButtonComponent={props => (
        <Button
          variant="outlined"
          color="secondary"
          size={buttonSize}
          {...props}
        />
      )}
      disableButtonStyles
    >
      By continuing to browse this website, you consent to the use of Cookies
      used to monitor site traffic. To find out more and set cookies,{' '}
      <Link to="/privacy-policy" className={classes.link}>
        click here.
      </Link>
    </CookieConsent>
  );
}

CookieBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const styles = theme => ({
  container: {
    alignItems: 'center !important',
  },
  button: {
    margin: theme.spacing.unit,
    [theme.breakpoints.up('xs')]: {
      marginRight: 150,
    },
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      fontSize: 'small',
    },
  },
  link: {
    color: theme.palette.secondary.main,
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing.unit}px !important`,
      fontSize: 'x-small',
      flex: '3.5 !important',
    },
  },
});

export default withStyles(styles, { withTheme: true })(CookieBanner);
