import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InformationPage from 'components/InformationPage';
import Typography from '@material-ui/core/Typography';
import BlueCargoLogo from 'components/BlueCargoLogo';
import Form from './components/Form';

export default class Home extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <InformationPage hideBackButton hideFooter>
        <div className={classes.logoWrapper}>
          <BlueCargoLogo />
        </div>
        <Typography variant="h5" gutterBottom color="textPrimary">
          Where to return your empty container?
        </Typography>
        <Form />
      </InformationPage>
    );
  }
}
