import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import CredentialsForm from '../CredentialsForm';
import CompanyForm from '../CompanyForm';
import PersonalForm from '../PersonalForm';

import { Link } from 'react-router-dom';
import { Persist } from 'services/helpers/formik-persist';

export default class Formular extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    forceStep: PropTypes.number,
    values: PropTypes.object.isRequired,
    activeStep: PropTypes.number.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
    secondComer: PropTypes.bool.isRequired,
    onFirstBack: PropTypes.func.isRequired,
  };

  isNextDisabled = () => {
    const { errors, values } = this.props;
    switch (this.props.activeStep) {
      case 0:
        return Boolean(
          errors.email ||
            errors.password ||
            errors.password_confirmation ||
            errors.terms_of_uses ||
            errors.privacy_policy ||
            !values.email ||
            !values.password ||
            !values.password_confirmation ||
            !values.terms_of_uses ||
            !values.privacy_policy
        );
      case 1:
        return Boolean(
          errors.first_name ||
            errors.last_name ||
            errors.phone_number ||
            errors.company_role ||
            !values.first_name ||
            !values.last_name ||
            !values.company_role ||
            !values.phone_number
        );
      case 2:
        return Boolean(
          errors.company_name ||
            errors.company_nature ||
            errors.company_scac ||
            errors.nb_empty_return ||
            !values.company_name ||
            !values.company_nature ||
            !values.company_scac ||
            !values.nb_empty_return
        );
      default:
        return false;
    }
  };

  componentDidUpdate = () => {
    // Force form to render a particular step
    const { activeStep } = this.props;
    if (this.props.forceStep !== null && this.props.forceStep !== activeStep) {
      this.props.setStep(this.props.forceStep);
    }
  };

  handleChange = event => {
    this.props.setFieldError(event.target.name, null);

    this.props.handleChange(event);
  };
  render() {
    const {
      classes,
      handleSubmit,
      isSubmitting,
      errors,
      values,
      secondComer,
    } = this.props;

    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        <Persist isSessionStorage={true} name="signup-form" />

        <div className={classes.fields}>
          {this.props.activeStep === 0 && (
            <CredentialsForm {...this.props} handleChange={this.handleChange} />
          )}
          {this.props.activeStep === 1 && (
            <PersonalForm {...this.props} handleChange={this.handleChange} />
          )}
          {this.props.activeStep === 2 && (
            <CompanyForm
              {...this.props}
              secondComer={secondComer}
              handleChange={this.handleChange}
            />
          )}
        </div>

        {this.props.activeStep < 3 && (
          <MobileStepper
            variant="progress"
            steps={4}
            position="static"
            activeStep={this.props.activeStep + 1}
            className={classes.root}
            LinearProgressProps={{
              classes: { root: classes.stepperRoot, bar: classes.stepperBar },
            }}
            nextButton={
              this.props.activeStep === 2 ||
              (Boolean(values.token) && this.props.activeStep === 1) ? (
                <Button
                  key="submit"
                  type="submit"
                  size="small"
                  className={classes.progressButton}
                  disabled={isSubmitting || Object.keys(errors).length !== 0}
                >
                  {isSubmitting ? (
                    <CircularProgress color="secondary" size={25} />
                  ) : (
                    'Done'
                  )}
                </Button>
              ) : (
                <Button
                  key="next"
                  size="small"
                  component={
                    this.props.activeStep === 0 && !values.token
                      ? Link
                      : undefined
                  }
                  to={`/confirmation?email=${values.email}&redirect=/signup`}
                  onClick={this.props.handleNext}
                  disabled={this.isNextDisabled()}
                  className={classes.progressButton}
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              )
            }
            backButton={
              <Button
                size="small"
                onClick={
                  this.props.activeStep === 0
                    ? this.props.onFirstBack
                    : this.props.handleBack
                }
                className={classes.progressButton}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        )}
      </form>
    );
  }
}
