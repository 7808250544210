import React from 'react';

const Container = ({ id, children, bg }) => {
  return (
    <div id={id} className={`px-8 ${bg}`}>
      {children}
    </div>
  );
};

export default Container;
