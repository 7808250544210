export default theme => ({
  progressButton: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  form: {
    width: 280, // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  fields: {
    height: 380,
  },
  stepperRoot: {
    height: 10,
    backgroundColor: 'rgba(31, 51, 75, 0.05)',
  },
  stepperBar: {
    backgroundColor: 'rgba(31, 51, 75, 0.45)',
  },
  subtitle: {
    marginBottom: 3 * theme.spacing.unit,
  },
  root: {
    backgroundColor: theme.palette.inputBackground.main,
  },
  select: {
    textAlign: 'left',
  },
  selectItem: {
    fontSize: 12,
    height: 15,
  },
});
