import React, { Component } from 'react';
import ReactGA from 'react-ga';
import SocketAvailabilityClient from 'services/sockets/availability';
import FullTable from './components/FullTable';
import FreeTable from 'pages/FreeTable/components/Table';
import fonts from 'styles/typography.module.css';
import PageTitle from 'components/PageTitle/PageTitle';

export default class Terminals extends Component {
  state = {
    isFilterMenuOpen: false,
    shippingLine: '',
    containerType: '',
    port: '',
    shift: {},
  };
  openCreateNotif = (
    shippingLine,
    containerType,
    port,
    shift,
    availability
  ) => {
    if (['yes', 'dual'].includes(availability)) {
      ReactGA.event({
        category: 'Notification',
        action: 'Open from availabilitiers table',
      });
      this.setState({
        createNotifOpen: true,
        shippingLine,
        containerType,
        port,
        shift,
      });
    }
  };

  componentDidMount = () => {
    this.props.importData();
    this.socket = new SocketAvailabilityClient();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.refreshToken !== this.props.refreshToken) {
      this.props.importData();
    }
  }

  componentWillUnmount = () => {
    this.socket.leave();
  };

  render() {
    const {
      shippingLines,
      containerTypes,
      isLoading,
      isLogged,
      user,
      freeContainerTypes,
      freeShippingLines,
      location,
    } = this.props;
    containerTypes.length > 0 &&
      shippingLines.length > 0 &&
      !isLoading &&
      ReactGA.event({
        category: 'Search',
        action: 'Empty search',
        label: `${shippingLines.map(sl => sl.name)}`,
      });

    return (
      <div className={fonts.font}>
        <PageTitle title="Empty Receiving Instructions - LA/LB" />
        {user.permissions.access_table ? (
          <FullTable
            isLoading={isLoading}
            containerTypes={containerTypes}
            shippingLines={shippingLines}
            isLogged={isLogged}
            openCreateNotif={this.openCreateNotif}
          />
        ) : (
          <FreeTable
            isLoading={isLoading}
            containerTypes={freeContainerTypes.slice(0, 1)}
            shippingLines={freeShippingLines.slice(0, 1)}
            isLogged={isLogged}
            displaySelector
            isAdmin={user.is_admin}
            location={location}
          />
        )}
      </div>
    );
  }
}
