import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from 'components/customDesign/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import 'assets/styles/react-phone-number-input.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const company_roles = [
  'Dispatch',
  'Equipment control',
  'Customer service',
  'Accounting',
  'Operations manager',
  'Owner or president',
  'Other',
];
export default class CredentialsForm extends Component {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const {
      classes,
      errors,
      touched,
      handleChange,
      handleBlur,
      values,
    } = this.props;

    return (
      <div>
        <Typography variant="caption" className={classes.subtitle}>
          Personal account info
        </Typography>
        <FormControl
          required
          fullWidth
          error={errors.first_name && touched.first_name}
        >
          <Input
            id="first_name"
            name="first_name"
            placeholder="First name *"
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
          />
          <FormHelperText>
            {errors.first_name && touched.first_name && errors.first_name}
          </FormHelperText>
        </FormControl>
        <FormControl
          required
          fullWidth
          error={errors.last_name && touched.last_name}
        >
          <Input
            name="last_name"
            id="last_name"
            placeholder="Last name *"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
          />
          <FormHelperText>
            {errors.last_name && touched.last_name && errors.last_name}
          </FormHelperText>
        </FormControl>

        <FormControl required fullWidth error={errors.phone_number}>
          <PhoneInput
            defaultCountry="US"
            placeholder="Enter phone number *"
            value={values.phone_number}
            name="phone_number"
            onChange={value =>
              handleChange({ target: { name: 'phone_number', value } })
            }
          />
          <FormHelperText classes={classes.phoneNumberValidation}>
            {errors.phone_number}
          </FormHelperText>
        </FormControl>

        <FormControl
          required
          fullWidth
          error={errors.company_role && touched.company_role}
        >
          <Select
            name="company_role"
            id="company_role"
            value={values.company_role}
            onChange={handleChange}
            input={<Input name="company_role" id="company_role" />}
            className={classes.select}
            displayEmpty
          >
            <MenuItem value={''} disabled className={classes.selectItem}>
              Select your role/responsibility? *
            </MenuItem>
            {company_roles.map(role => (
              <MenuItem value={role} key={role} className={classes.selectItem}>
                {role}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {errors.company_role && touched.company_role && errors.company_role}
          </FormHelperText>
        </FormControl>
      </div>
    );
  }
}
