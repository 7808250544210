export default theme => ({
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
  },
  tableWrapper: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      //Avoid scrollbar to be displayed on chrome windows
      width: '0px !important',
    },
  },
  greyBackground: {
    backgroundColor: theme.palette.grey.light,
  },
  whiteBackground: {
    backgroundColor: '#fff',
  },
  redBackground: {
    backgroundColor: theme.palette.alert.light,
  },
  tableRow: {
    height: 38,
  },
});
