export default theme => ({
  containerContent: {
    padding: theme.spacing.unit,
    minHeight: '70vh',
    position: 'relative',
  },
  containerRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});
