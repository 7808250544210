import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import TableItem from './RowEditor';

const TableBody = props => {
  const { rows, headers } = props;

  return (
    <tbody>
      {rows.map((rowData, index) => (
        <TableRow
          key={index}
          rowData={rowData.data}
          terminal={rowData.terminal}
          headers={headers}
        />
      ))}
    </tbody>
  );
};

const TableRow = props => {
  const { rowData, terminal } = props;
  return (
    <tr>
      <td>{terminal}</td>
      {rowData.map((currentValue, index) => (
        <TableItem
          key={index}
          dateIndex={index + 1}
          currentValue={currentValue}
          terminal={terminal}
          headers={props.headers}
        />
      ))}
    </tr>
  );
};

TableBody.propTypes = {
  rows: PropTypes.array,
  headers: PropTypes.array,
};

TableRow.propTypes = {
  rowData: PropTypes.array,
  terminal: PropTypes.string,
  headers: PropTypes.array,
};

const mapStateToProps = state => ({
  rows: state.admin.gateSchedule.rows,
  headers: state.admin.gateSchedule.headers,
});

export default connect(mapStateToProps, {})(TableBody);
