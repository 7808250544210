export default theme => ({
  withInstruction: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
  },
  warningText: {
    color: '#8E98A4',
    fontSize: 12,
  },
  warningIcon: {
    fontSize: 10,
    color: theme.palette.grey.main,
    position: 'absolute',
    right: '17px',
  },
  warningIconEA: {
    fontSize: 10,
    color: theme.palette.primary.warning,
  },
  warningIconEAGrey: {
    fontSize: 10,
    color: theme.palette.grey.warningGrey,
  },
  green: {
    color: theme.palette.green.main,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
});
