import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from 'components/customDesign/Tooltip';
import AdminDesc from './RolesDescription/AdminDesc';
import MemberDesc from './RolesDescription/MemberDesc';

const TooltipText = ({ classes }) => {
  return (
    <div className={classes.tooltipContent}>
      <MemberDesc classes={{ root: classes.memberRoot }} />
      <AdminDesc />
    </div>
  );
};

TooltipText.propTypes = {
  classes: PropTypes.object.isRequired,
};

const MembershipText = ({ profile, classes }) => {
  if (!profile) return null;
  return (
    <div>
      <Typography className={classes.typography}>
        {profile.is_admin ? 'Admin' : 'Team Member'}
        <Tooltip tooltipText={<TooltipText classes={classes} />}>
          <InfoIcon className={classes.icon} fontSize="small" />
        </Tooltip>
      </Typography>
    </div>
  );
};

const styles = theme => ({
  typography: {
    color: theme.palette.primary.half,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginLeft: theme.spacing.unit,
    verticalAlign: 'middle',
  },
  tooltipContent: {},
  memberRoot: {
    marginBottom: theme.spacing.unit,
  },
});

MembershipText.propTypes = {
  profile: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MembershipText);
