import React from 'react';
import PropTypes from 'prop-types';
import PlanForm from 'pages/SignUp/components/PlanForm';
import Button from 'components/customDesign/Button';

export const PLAN_MAP = ['starter', 'team', 'enterprise'];

const Form = ({ classes, currentPlan, ...props }) => {
  const isCurrentPlan = PLAN_MAP[props.values.pricing_plan] === currentPlan;
  return (
    <form onSubmit={props.handleSubmit} className={classes.form}>
      <PlanForm {...props} hideSubtitle />
      <Button
        type="submit"
        disabled={isCurrentPlan}
        classes={{ root: classes.buttonRoot }}
      >
        {isCurrentPlan ? "It's your current plan" : 'Change plan'}
      </Button>
    </form>
  );
};
Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  currentPlan: PropTypes.string.isRequired,
};

export default Form;
