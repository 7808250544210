import React from 'react';
import * as cells from './cells';
import {
  CONTAINER_CELL_WIDTH,
  getReleaseBookedAppointments,
} from '../container-config';
import HoldsCell from '../components/HoldsCell';
import LastFreeDayCell from '../components/LastFreeDayCell';
import PickupCell from '../components/PickupCell';

export function getColumns(
  onGetPerDiemReport,
  openAllAppointmentsModal,
  currentUser
) {
  const releaseBookedAppointment = getReleaseBookedAppointments(currentUser);
  const columns = [
    {
      field: 'containers',
      headerName: 'Containers',
      sortable: false,
      renderHeader: params => <TableHeader name={params.colDef.headerName} />,
      minWidth: CONTAINER_CELL_WIDTH,
      flex: 0.5,
      renderCell: params => cells.getContainer(params),
      cellClassName: 'border-r border-blue-400 bg-white w-full',
    },
    {
      field: 'holds',
      headerName: 'Holds',
      sortable: false,
      renderHeader: params => <TableHeader name={params.colDef.headerName} />,
      minWidth: 192,
      flex: 0.5,
      renderCell: ({ row: { holds } }) => <HoldsCell holds={holds} />,
      cellClassName: 'bg-white border-r border-blue-400',
    },
    {
      field: 'pickupLastFreeDay',
      headerName: 'Last Free Day',
      sortable: false,
      renderHeader: params => <TableHeader name={params.colDef.headerName} />,
      minWidth: 192,
      flex: 0.5,
      renderCell: ({ row }) => (
        <LastFreeDayCell
          pickupLastFreeDay={row.pickupLastFreeDay}
          status={row.status}
          gatedOutDate={row.gatedOutDate}
          pickupDemurrageFeeUsCents={row.pickupDemurrageFeeUsCents}
          pickupDemurrageDue={row.pickupDemurrageDue}
        />
      ),
      cellClassName: 'bg-white',
    },
    {
      field: 'terminalPickup',
      headerName: 'terminal pickup',
      sortable: false,
      renderHeader: params => <TableHeader name={params.colDef.headerName} />,
      minWidth: 290,
      flex: 1,
      renderCell: params => (
        <PickupCell
          {...params.row}
          releaseBookedAppointment={releaseBookedAppointment}
        />
      ),
      cellClassName: 'bg-white',
    },
    {
      field: 'emptyReturn',
      headerName: 'Empty Return',
      sortable: false,
      renderHeader: params => <TableHeader name={params.colDef.headerName} />,
      minWidth: 288,
      flex: 1,
      renderCell: params => cells.getReturn(params, openAllAppointmentsModal),
      cellClassName: 'bg-white',
    },
    {
      field: 'days',
      headerName: 'Detention',
      sortable: false,
      renderHeader: params => <TableHeader name={params.colDef.headerName} />,
      minWidth: 155,
      flex: 0.4,
      renderCell: params =>
        cells.getDetention(params, onGetPerDiemReport, currentUser),
      cellClassName: 'bg-white',
    },
    {
      field: 'currentOpenings',
      headerName: 'Open return locations for the current shift',
      sortable: false,
      renderHeader: params => <TableHeader name={params.colDef.headerName} />,
      minWidth: 320,
      flex: 1,
      renderCell: cells.getCurrentOpenings,
      cellClassName: 'bg-white',
    },
  ];
  return columns;
}

const TableHeader = ({ name }) => {
  return (
    <div className="px-2 flex space-x-2">
      <h6 className="text-dark uppercase font-bold tracking-wide">{name}</h6>
    </div>
  );
};
