const basiclegendBlock = {
  fontSize: '12px',
  display: 'flex',
  alignItems: 'end',
  marginBottom: '10px',
};

export default theme => ({
  buttonLegend: {
    position: 'fixed',
    top: 456,
    left: -30,
    transform: 'rotate(-90deg)',
    width: 106,
    height: 40,
    fontSize: '16px',
    color: '#000000',
    background: '#FFFFFF',
    border: '1px solid #EFF0F1',
    borderRadius: '4px',
    textTransform: 'capitalize',
  },
  rootLegend: {
    position: 'fixed',
    top: 325,
    left: 0,
    backgroundColor: '#fff',
    padding: '45px 30px 10px 30px',
    border: '1px solid #EFF0F1',
    borderRadius: '4px 4px 4px 4px',
    boxShadow: theme.shadows[2],
    zIndex: 3,
  },
  legendBlock: {
    ...basiclegendBlock,
  },
  legendBlockTimeSlot: {
    ...basiclegendBlock,
    marginBottom: '-6px',
  },
  legendText: {
    maxWidth: '164px',
  },
  legendTextBold: {
    maxWidth: '164px',
    fontWeight: 'bold',
  },
  legendIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '16px',
  },
  legendTitle: {
    fontSize: '16px',
    position: 'absolute',
    top: '10px',
  },
  legengGrey: {
    color: theme.palette.grey[500],
  },

  root: {
    position: 'fixed',
    bottom: 10,
    left: 0,
    backgroundColor: '#fff',
    padding: theme.spacing.unit,
    borderRadius: '0px 5px 5px 0px',
    boxShadow: theme.shadows[2],
    zIndex: 3,
  },
  button: {
    position: 'fixed',
    bottom: 40,
    left: -30,
    transform: 'rotate(90deg)',
    width: 100,
    height: 40,
  },
  buttonRoot: {
    backgroundColor: '#eee',
    borderRadius: '4px 4px 0px 0px',
    zIndex: 3,
  },
  green: {
    color: theme.palette.green.main,
  },
  caption: {
    display: 'flex',
    alignItems: 'center',
  },
  red: {
    color: theme.palette.alert.main,
  },
  yellow: {
    color: theme.palette.secondary.main,
  },
  grey: {
    color: theme.palette.grey.main,
  },
  broken: {
    background: theme.background.greyHatched,
    width: 24,
    height: 24,
  },
  icon: {
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});
