import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DROPDOWN_FILTERS } from '../container-config';
import _ from 'lodash';
import pluralize from 'pluralize';

function isFilterOn(activeFilter, filterParams) {
  return _.isMatch(activeFilter, filterParams, (a, b) => {
    if (_.isArray(a) && _.isArray(b)) {
      return _.includes(a, b);
    }
  });
}

export function getFilterCount(selectedfilter) {
  const checkedFilters = getCheckedFilters(selectedfilter, true);
  return _.reduce(checkedFilters, (sum, isOn) => sum + isOn);
}

export function getCheckedFilters(selectedFilters, excludeTotal = false) {
  let checkedFilters = {};
  let totalPerCategory = {
    totalContainerStatus: 0,
    totalContainerType: 0,
    totalShippingLine: 0,
    totalTerminals: 0,
  };

  for (let filter of DROPDOWN_FILTERS) {
    const isOn = isFilterOn(selectedFilters, filter.searchParams);
    checkedFilters[filter.id] = isOn;
    totalPerCategory.totalContainerStatus += isOn;
  }

  for (let containerType of _.get(selectedFilters, 'containerType', [])) {
    checkedFilters[containerType] = true;
    totalPerCategory.totalContainerType += 1;
  }

  for (let shippingLine of _.get(selectedFilters, 'shippingLine', [])) {
    checkedFilters[shippingLine] = true;
    totalPerCategory.totalShippingLine += 1;
  }

  for (let terminal of _.get(selectedFilters, 'terminals', [])) {
    checkedFilters[terminal] = true;
    totalPerCategory.totalTerminals += 1;
  }

  if (excludeTotal) {
    return checkedFilters;
  }
  return { ...checkedFilters, ...totalPerCategory };
}

function combineFilters(activeFilters, newFilter, addFilter) {
  const combinedFilters = _.mergeWith({}, newFilter, activeFilters, function(
    a,
    b
  ) {
    if (_.isArray(a)) {
      if (addFilter) {
        return _.concat(b, a);
      } else {
        const newArray = _.difference(b, a);
        return newArray.length === 0 ? null : newArray;
      }
    } else if (_.isBoolean(a)) {
      return addFilter;
    }
  });
  return _.omitBy(combinedFilters, _.isNil);
}

const ContainerFilters = ({
  onFilterSelection,
  containerTypes,
  shippingLines,
  selectedFilters,
  terminals,
}) => {
  function handleOnFilterSelection(filters, checkboxValue) {
    const combinedFilters = combineFilters(
      selectedFilters,
      filters,
      checkboxValue
    );
    onFilterSelection(combinedFilters);
  }

  const checkedFilters = getCheckedFilters(selectedFilters);

  return (
    <>
      {/* Container Status */}
      <div>
        <Accordion disableGutters elevation={0} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4 className="font-medium text-dark">
              Container Status{' '}
              {checkedFilters.totalContainerStatus > 0
                ? `(${checkedFilters.totalContainerStatus})`
                : ''}
            </h4>
          </AccordionSummary>
          <AccordionDetails>
            <div
              className="flex flex-col space-y-2 items-start overflow-y-scroll"
              style={{ height: '192px' }}
            >
              {DROPDOWN_FILTERS.map(filter => {
                return (
                  <FormControlLabel
                    key={filter.id}
                    control={<Checkbox checked={checkedFilters[filter.id]} />}
                    label={<Button variant={filter.tag}>{filter.name}</Button>}
                    onClick={e =>
                      handleOnFilterSelection(
                        filter.searchParams,
                        checkedFilters[filter.id]
                          ? !checkedFilters[filter.id]
                          : true
                      )
                    }
                  />
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* Container Type */}
      <MultipleSelectFilter
        title="Container Type"
        filterCount={checkedFilters.totalContainerType}
        filterValues={checkedFilters}
        filterList={containerTypes}
        onClick={(checked, key) =>
          handleOnFilterSelection({ containerType: [key] }, checked)
        }
      />
      {/* Shipping Line */}
      <MultipleSelectFilter
        title="Shipping Line"
        filterCount={checkedFilters.totalShippingLine}
        filterValues={checkedFilters}
        filterList={shippingLines}
        onClick={(checked, key) =>
          handleOnFilterSelection({ shippingLine: [key] }, checked)
        }
      />
      {/* Terminals */}
      <MultipleSelectFilter
        title="Terminal Pickup"
        filterCount={checkedFilters.totalTerminals}
        filterValues={checkedFilters}
        filterList={terminals}
        onClick={(checked, key) =>
          handleOnFilterSelection({ terminals: [key] }, checked)
        }
      />
    </>
  );
};

const MultipleSelectFilter = ({
  title,
  onClick,
  filterCount,
  filterList,
  filterValues,
}) => {
  return (
    <div>
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h4 className="font-medium text-dark">
            {title} {filterCount > 0 ? `(${filterCount})` : ''}
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="flex flex-col space-y-2 items-start overflow-y-scroll"
            style={{ height: '240px' }}
          >
            {filterList.map(filter => {
              return (
                <FormControlLabel
                  key={filter.key}
                  control={
                    <Checkbox
                      defaultChecked={filterValues[filter.key]}
                      onClick={e => onClick(e.target.checked, filter.key)}
                    />
                  }
                  label={<h5 className="font-medium">{filter.name}</h5>}
                />
              );
            })}
          </div>
          <h5 className="font-regular text-blue mt-4">
            Scroll to see more {pluralize(title.toLowerCase(), 2)}
          </h5>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ContainerFilters;
