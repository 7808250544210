import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import CustomSelect from 'components/Form/Select';
import queryString from 'query-string';
import { pageTitlesAndUrls } from 'constants/pages';

const initialValues = { shippingLine: '', containerType: '' };
export default class Form extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    shippingLines: PropTypes.array.isRequired,
    containerTypes: PropTypes.array.isRequired,
    importData: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount = () => this.props.importData();
  validate = values => {
    let errors = {};
    if (!values.shippingLine) errors.shippingLine = 'Required';
    if (!values.containerType) errors.containerType = 'Required';
    return errors;
  };

  submit = (values, { setSubmitting }) => {
    const query = {
      'shipping-line': values.shippingLine,
      'container-type': values.containerType,
    };
    this.props.navigate({
      pathname: pageTitlesAndUrls.emptyReturnLocations.path,
      search: queryString.stringify(query),
    });
  };

  render() {
    const { classes, shippingLines, containerTypes } = this.props;
    return (
      <Formik
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={this.submit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          isValid,
        }) => {
          !isSubmitting && !isValidating && isValid && handleSubmit();
          return (
            <form onSubmit={handleSubmit}>
              <CustomSelect
                disableLabel
                mobile
                values={shippingLines.map(a => a.name)}
                selected={values.shippingLine}
                handleChange={handleChange}
                label="Shipping line"
                id="shippingLine"
                classes={classes}
                isLoading={false}
                disabled={isSubmitting}
                isError={errors.shippingLine && touched.shippingLine}
                errorMessage={
                  errors.shippingLine &&
                  touched.shippingLine &&
                  errors.shippingLine
                }
              />
              <CustomSelect
                disableLabel
                mobile
                values={containerTypes.map(a => a.name)}
                selected={values.containerType}
                handleChange={e => {
                  handleChange(e);
                  isValid && handleSubmit();
                }}
                label="Container type"
                id="containerType"
                isLoading={false}
                classes={classes}
                disabled={isSubmitting}
                isError={errors.containerType && touched.containerType}
                errorMessage={
                  errors.containerType &&
                  touched.containerType &&
                  errors.containerType
                }
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}
