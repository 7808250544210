import io from 'socket.io-client';
import { store } from 'store';
import {
  updateAvailabilitiesData,
  setFunctionalParsers,
} from 'ducks/availabilities';
import { setPorts } from 'ducks/erlData';
import config from 'config';

export default class SocketAvailabilityClient {
  constructor() {
    this.socket = io(config.wsUrl, {
      query: {
        roomName: 'availability',
      },
    });

    this.socket.on('terminal instruction update', function(msg) {
      store.dispatch(setPorts(true));
    });

    this.socket.on('availability update', function(msg) {
      store.dispatch(updateAvailabilitiesData(msg));
    });

    this.socket.on('parser status', msg =>
      store.dispatch(setFunctionalParsers(msg))
    );
  }

  leave() {
    this.socket.close();
  }
}
