import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import PricingPresentation from 'components/PricingPresentation';
import Button from 'components/customDesign/Button';
import ReactGA from 'react-ga';
import Stickyfill from 'stickyfilljs';
import { STICKY_IE_CLASS } from 'constants/layout';
import SocketAvailabilityClient from 'services/sockets/availability';
import { Navigate, Link } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile_icon.svg';
import Table from './components/Table';
import CheckIcon from '@material-ui/icons/Check';
import { pageTitlesAndUrls } from 'constants/pages';

export default class FreeTable extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    shippingLines: PropTypes.array.isRequired,
    containerTypes: PropTypes.array.isRequired,
    importData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };
  state = {
    createNotifOpen: false,
    shippingLine: '',
    containerType: '',
    port: '',
    shift: {},
  };

  componentDidMount() {
    this.props.importData();

    var elements = document.querySelectorAll(`.${STICKY_IE_CLASS}`);
    Stickyfill.add(elements);
  }
  closeCreateNotif = () => this.setState({ createNotifOpen: false });

  componentDidMount = () => {
    this.props.importData();
    this.socket = new SocketAvailabilityClient();
  };

  componentWillUnmount = () => {
    this.socket.leave();
  };

  render() {
    const {
      classes,
      shippingLines,
      containerTypes,
      isLoading,
      isLogged,
      isAdmin,
      location,
    } = this.props;

    if (isLogged)
      return (
        <Navigate replace to={pageTitlesAndUrls.emptyReturnLocations.path} />
      );

    containerTypes.length > 0 &&
      shippingLines.length > 0 &&
      !isLoading &&
      ReactGA.event({
        category: 'Search',
        action: 'Empty search',
        label: `${shippingLines.map(sl => sl.name)} / ${shippingLines.map(
          sl => sl.name
        )}`,
      });

    return (
      <>
        <Typography
          align="center"
          variant="h2"
          className={classes.transitionText}
        >
          Thanks for trying!
        </Typography>
        <Table
          shippingLines={shippingLines.slice(0, 1)}
          containerTypes={containerTypes.slice(0, 1)}
          isLoading={isLoading}
          isLogged={isLogged}
          location={location}
        />
        <div className={classes.transistion}>
          <Typography
            align="center"
            variant="h2"
            className={classes.transitionText}
          >
            <b>Ready to use?</b> Access the full platform now!
          </Typography>
          <div className={classes.checklist}>
            <Typography className={classes.checklistItem}>
              <CheckIcon />
              15 days trial
            </Typography>
            <Typography className={classes.checklistItem}>
              <CheckIcon />
              No credit card required
            </Typography>
            <Typography className={classes.checklistItem}>
              <CheckIcon />
              Cancel anytime
            </Typography>
          </div>
          <Button
            classes={{ root: classes.buttonRoot }}
            rightIcon={<ProfileIcon className={classes.buttonIcon} />}
            component={Link}
            to="/signup"
          >
            Try 15 days free
          </Button>
        </div>
        <PricingPresentation
          isAdmin={isAdmin}
          showPlanButtons={false}
          showFreeTrialButtons={true}
        />
      </>
    );
  }
}
