import React from 'react';
import { User } from 'types/User';
import { useUserContext } from 'common/contexts/UserContext';

function ComponentPreview() {
  const { user }: { user: User } = useUserContext();
  return (
    <>
      <h1>TypeScript</h1>
      <div>REACT_APP_ENV: {process.env.REACT_APP_ENV}</div>ow to
      <pre>{JSON.stringify(user, null, 2)}</pre>
    </>
  );
}

export default ComponentPreview;
