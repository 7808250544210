import React from 'react';
import Typography from '@material-ui/core/Typography';
import InformationPage from '../components/InformationPage';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <InformationPage>
      <Typography variant="h2" paragraph color="secondary">
        Opps! This page was not found.
      </Typography>
      <Typography variant="h6" gutterBottom color="textSecondary">
        We've logged this error. Please feel free to head back to our{' '}
        <Link style={{ textDecoration: 'underline', color: 'white' }} to={'/'}>
          home page
        </Link>{' '}
        or contact support
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        color="textPrimary"
        component="a"
        href="mailto:contact@bluecargo.io"
      >
        contact@bluecargo.io
      </Typography>
    </InformationPage>
  );
}
