import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { NextAppointmentsService } from '../NextAppointments/services/next-appointments.service';
import './BookAppointmentModalConfirmed.style.css';

const copyIconStyle = {
  fontSize: '15px',
  cursor: 'pointer',
};

const BookAppointmentModalConfirmed = ({
  confirmationData,
  onRedirectToMyAppointments,
  onCloseConfirmationModalClick,
}) => {
  const {
    name,
    appointment_system_name,
    start_time,
    end_time,
    terminal_date,
    confirmNumber,
    containerNumber,
  } = confirmationData || {};

  const formattedDate = moment(terminal_date, 'YYYY-MM-DD').format(
    'YYYY/MM/DD'
  );
  const formattedStartTime = moment(start_time, 'HH:mm:ss').format('HH:mm');
  const formattedEndTime = moment(end_time, 'HH:mm:ss').format('HH:mm');
  const appoitnment = `${formattedDate} ${formattedStartTime} - ${formattedEndTime}`;

  const [
    isAppointmentCopySuccessMsgShown,
    setAppointmentSuccessCopyMsg,
  ] = useState(false);
  const [
    isConfirmNumberCopySuccessMsgShown,
    setConfirmNumberSuccessCopyMsg,
  ] = useState(false);

  const copyToClipBoardAppointment = () => {
    if (isAppointmentCopySuccessMsgShown) return;
    copyDataToClipBoard(appoitnment, 'appoitnment');
  };

  const copyToClipBoardConfirmNumber = () => {
    if (isConfirmNumberCopySuccessMsgShown) return;
    copyDataToClipBoard(confirmNumber);
  };

  const copyDataToClipBoard = async (data, type) => {
    try {
      await NextAppointmentsService.copyDataToClipBoard(data);

      if (type === 'appoitnment') {
        setAppointmentSuccessCopyMsg(true);
        setTimeout(() => setAppointmentSuccessCopyMsg(false), 500);
      } else {
        setConfirmNumberSuccessCopyMsg(true);
        setTimeout(() => setConfirmNumberSuccessCopyMsg(false), 500);
      }
    } catch (error) {
      console.error('Could not copy text: ', error);
    }
  };

  return (
    <div className="book-appointment-modal-confirmed-wrapper">
      <div className="book-appointment-modal-confirmed-title">
        Your appointment at{' '}
        <b>{`${_.toUpper(name)} (${appointment_system_name})`}</b> is confirmed!
      </div>

      <div className="book-appointment-modal-confirmed-container-number">
        <div>Container number:</div>
        <div>{containerNumber}</div>
      </div>

      <div className="book-appointment-modal-confirmed-appoitnment">
        <div className="book-appointment-modal-confirmed-appoitnment-row">
          <div className="book-appointment-modal-confirmed-appoitnment-value">
            {appoitnment}
          </div>
          <FileCopyOutlinedIcon
            style={copyIconStyle}
            onClick={copyToClipBoardAppointment}
          />
          {isAppointmentCopySuccessMsgShown && (
            <div className="book-appointment-modal-confirmed-confirmation-copied appoitnment">
              Copied!
            </div>
          )}
        </div>
      </div>

      <div className="book-appointment-modal-confirmed-confirmation-number">
        <div>Your confirmation number is</div>
        <div className="book-appointment-modal-confirmed-confirmation-number-value-row">
          <div className="book-appointment-modal-confirmed-confirmation-number-value">
            <b>{confirmNumber}</b>
          </div>
          <FileCopyOutlinedIcon
            style={copyIconStyle}
            onClick={copyToClipBoardConfirmNumber}
          />
          {isConfirmNumberCopySuccessMsgShown && (
            <div className="book-appointment-modal-confirmed-confirmation-copied">
              Copied!
            </div>
          )}
        </div>
      </div>

      <div className="book-appointment-modal-confirmed-button-wrapper">
        <div
          className="book-appointment-modal-button go-to-appoitnments"
          onClick={onRedirectToMyAppointments}
        >
          GO TO MY APPOINTMENTS
        </div>
      </div>

      <div className="book-appointment-modal-confirmed-button-wrapper">
        <div
          className="book-appointment-modal-button close"
          onClick={onCloseConfirmationModalClick}
        >
          CLOSE
        </div>
      </div>
    </div>
  );
};

BookAppointmentModalConfirmed.propTypes = {
  onCloseConfirmationModalClick: PropTypes.func,
  onRedirectToMyAppointments: PropTypes.func,
  confirmationData: PropTypes.object,
};

export default BookAppointmentModalConfirmed;
