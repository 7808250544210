import React from 'react';
import PropTypes from 'prop-types';
import './BookAppointmentModalError.style.css';

const BookAppointmentModalError = ({
  bookErrorBE,
  onPickAnotherSlotClick,
  onCloseConfirmationModalClick,
}) => {
  return (
    <div className="book-appointment-modal-error-wrapper">
      <div className="book-appointment-modal-error-title">Uh Oh...</div>

      <div className="book-appointment-modal-error-subtitle">
        {bookErrorBE || 'Please check your data'}
      </div>

      <div className="book-appointment-modal-error-button-wrapper pick-another-slot-button-container">
        <div
          className="book-appointment-modal-button pick-another-slot"
          onClick={onPickAnotherSlotClick}
        >
          PICK ANOTHER SLOT
        </div>
      </div>

      <div className="book-appointment-modal-error-button-wrapper">
        <div
          className="book-appointment-modal-button close"
          onClick={onCloseConfirmationModalClick}
        >
          CLOSE
        </div>
      </div>
    </div>
  );
};

BookAppointmentModalError.propTypes = {
  bookErrorBE: PropTypes.string,
  onPickAnotherSlotClick: PropTypes.func,
  onCloseConfirmationModalClick: PropTypes.func,
};

export default BookAppointmentModalError;
