import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import css from './ptscSchedule.module.css';

const TableHeaders = props => {
  const { headersData } = props;

  return (
    <thead>
      <tr>
        {headersData.map((shift, index) => (
          <TableHeader key={index} shift={shift} />
        ))}
      </tr>
    </thead>
  );
};

const TableHeader = props => {
  const { shift } = props;

  const shiftRepresentation = shift ? 'Shift ' + shift : '';

  let shiftCss = css.tableHeaderShift1;
  if (shift === 2) shiftCss = css.tableHeaderShift2;

  return (
    <th className={css.tableHeaders}>
      <div className={shiftCss}>{shiftRepresentation}</div>
    </th>
  );
};

TableHeaders.propTypes = {
  headersData: PropTypes.array,
};

TableHeader.propTypes = {
  date: PropTypes.string,
  shift: PropTypes.number,
};

const mapStateToProps = state => ({
  headersData: state.admin.ptscSchedule.headers,
});

export default connect(mapStateToProps, {})(TableHeaders);
