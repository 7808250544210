import MuiButton from '@material-ui/core/Button';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import cn from 'classnames';

const styles = theme => ({
  root: {
    height: 59,
    width: 170,
    boxShadow: 'unset',
    borderRadius: 0,
    backgroundColor: 'rgba(31, 51, 75, 0.2)',
    margin: theme.spacing.unit,
    '&:hover': {
      backgroundColor: 'rgba(31, 51, 75, 0.45)',
    },
  },
  rootSuccess: {
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'inherit',
      opacity: 0.5,
    },
  },
  label: {
    textTransform: 'none',
  },
  labelSuccess: {
    color: theme.palette.green.light,
  },
  icon: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
  },
});

export class Button extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    icon: PropTypes.element,
    rightIcon: PropTypes.element,
    isLoading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
  };
  static defaultProps = {
    isLoading: false,
    success: false,
  };

  render() {
    const {
      classes,
      icon,
      children,
      rightIcon,
      isLoading,
      success,
      ...otherProps
    } = this.props;
    return (
      <MuiButton
        variant="contained"
        classes={{
          root: cn(classes.root, { [classes.rootSuccess]: success }),
          label: cn(classes.label, { [classes.labelSuccess]: success }),
        }}
        {...otherProps}
      >
        {isLoading ? (
          <CircularProgress color="primary" size={25} />
        ) : (
          <React.Fragment>
            {icon && icon}
            {success && <CheckIcon className={classes.icon} />}
            {children}
            {rightIcon && rightIcon}
          </React.Fragment>
        )}
      </MuiButton>
    );
  }
}

export default withStyles(styles)(Button);
