import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from 'components/customDesign/Button';
import PhoneInput from 'react-phone-number-input';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormHelperText } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { ReactComponent as SaveCircleIcon } from 'assets/icons/save_circle.svg';
import { updateProfile } from 'services/clients/user';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setUserData } from 'ducks/user';
import 'react-phone-number-input/style.css';
import 'assets/styles/react-phone-number-input.css';

export class EditProfileForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    updateUserData: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
  };

  validate = values => {
    let errors = {};
    if (!values.first_name) errors.first_name = 'Required';
    if (!values.last_name) errors.last_name = 'Required';
    return errors;
  };

  onSubmit = async (
    values,
    { setSubmitting, setValues, setErrors, setStatus }
  ) => {
    try {
      const res = await updateProfile(values);
      setValues(res);
      this.props.updateUserData(res);
      setSubmitting(false);
      setStatus('success');
    } catch (e) {
      setErrors(e.response.data);
      setSubmitting(false);
    }
  };

  render() {
    const { initialValues } = this.props;

    if (!initialValues) return <CircularProgress />;
    return (
      <Formik
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={this.onSubmit}
        initialStatus={'pending'}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setStatus,
          status,
        }) => {
          const onChange = event => {
            handleChange(event);
            setStatus('pending');
          };
          return (
            <div>
              <form onSubmit={handleSubmit} className="">
                <div className="flex flex-col space-y-8">
                  {/* Name */}
                  <div className="w-full flex space-x-8">
                    <FormControl
                      required
                      fullWidth
                      error={Boolean(errors.first_name)}
                    >
                      <div className="flex flex-col space-y-2">
                        <div className="flex space-x-2 items-center">
                          <PersonIcon />
                          <h4>First Name</h4>
                        </div>
                        <TextField
                          variant="outlined"
                          id="first_name"
                          name="first_name"
                          placeholder="First name"
                          value={values.first_name}
                          onChange={onChange}
                          sx={{ width: '256px' }}
                        />
                      </div>
                      <FormHelperText>{errors.first_name}</FormHelperText>
                    </FormControl>
                    <FormControl
                      required
                      fullWidth
                      error={Boolean(errors.last_name)}
                    >
                      <div className="flex flex-col space-y-2">
                        <div className="flex space-x-2 items-center">
                          <PersonIcon />
                          <h4>Last Name</h4>
                        </div>
                        <TextField
                          variant="outlined"
                          id="last_name"
                          name="last_name"
                          placeholder="Last name"
                          value={values.last_name}
                          onChange={onChange}
                          sx={{ width: '256px' }}
                        />
                      </div>
                      <FormHelperText>{errors.first_name}</FormHelperText>
                    </FormControl>
                  </div>
                  {/* email and phone */}
                  <div className="w-full flex space-x-8">
                    <FormControl
                      required
                      fullWidth
                      error={Boolean(errors.first_name)}
                    >
                      <div className="flex flex-col space-y-2">
                        <div className="flex space-x-2 items-center">
                          <EmailIcon />
                          <h4>Email</h4>
                        </div>
                        <TextField
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={onChange}
                          variant="outlined"
                          sx={{ width: '256px' }}
                          disabled
                        />
                      </div>
                      <FormHelperText>{errors.first_name}</FormHelperText>
                    </FormControl>
                    <FormControl
                      required
                      fullWidth
                      error={Boolean(errors.last_name)}
                    >
                      <div className="flex flex-col space-y-2">
                        <div className="flex space-x-2 items-center">
                          <PhoneIphoneIcon />
                          <h4>Phone number</h4>
                        </div>
                        <PhoneInput
                          defaultCountry="US"
                          placeholder="Enter phone number"
                          value={values.phone_number}
                          onChange={value =>
                            onChange({
                              target: {
                                name: 'phone_number',
                                value: value || '',
                              },
                            })
                          }
                          name="phone_number"
                          error={errors.phone_number}
                          style={{ width: '256px', background: 'white' }}
                        />
                      </div>
                      <FormHelperText>{errors.first_name}</FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  success={status === 'success'}
                  rightIcon={
                    status !== 'success' && (
                      <SaveCircleIcon
                        style={{ fontSize: '32px', marginLeft: '16px' }}
                      />
                    )
                  }
                >
                  {status === 'success' ? 'Success' : 'Save'}
                </Button>
              </form>
            </div>
          );
        }}
      </Formik>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateUserData: data => dispatch(setUserData(data)),
});

export default compose(connect(null, mapDispatchToProps))(EditProfileForm);
