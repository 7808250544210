export default theme => ({
  radio: {
    padding: 0,
    '&$checked': {
      color: '#49A7C8',
    },
  },
  checked: {},

  terminalLabel: {
    color: '#0740D3',
    fontSize: '16px',
    marginLeft: '9px',
  },

  terminalLabelActive: {
    color: '#000000',
    fontSize: '16px',
    marginLeft: '9px',
  },

  termimalLabelRoot: {},

  radiobuttonIcon: {
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
  },
});
