import React from 'react';
import { getDeltaLastFreeDay } from '../utils/pickup';
import { laTimezone } from '../../../services/helpers/data';
import {
  CONTAINER_STATUS_ENUM,
  DATE_DISPLAY_FORMAT,
} from '../container-config';

interface Props {
  status: string;
  pickupLastFreeDay?: Date;
  gatedOutDate?: Date;
  pickupDemurrageDue?: boolean;
  pickupDemurrageFeeUsCents?: number;
  showDelta?: boolean;
}

export default function LastFreeDayCell({
  pickupLastFreeDay,
  status,
  gatedOutDate,
  pickupDemurrageFeeUsCents,
  pickupDemurrageDue,
  showDelta = true,
}: Props) {
  let deltaLastFreeDay = null;
  if (pickupLastFreeDay) {
    const compareTo =
      status !== CONTAINER_STATUS_ENUM.IMPORT ? gatedOutDate : undefined;
    deltaLastFreeDay = getDeltaLastFreeDay(pickupLastFreeDay, compareTo);

    if (
      status !== CONTAINER_STATUS_ENUM.IMPORT &&
      deltaLastFreeDay.nbDays === 0
    ) {
      deltaLastFreeDay = null;
    }
  }
  return (
    <div className="leading-none flex h-full items-start py-4 px-2">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          <h5 className="font-semibold text-gray-200">
            {pickupLastFreeDay
              ? laTimezone(pickupLastFreeDay).format(DATE_DISPLAY_FORMAT)
              : '-'}
          </h5>
          {showDelta && deltaLastFreeDay != null && (
            <h6
              className={`px-1 py-0.5 rounded-sm ${
                deltaLastFreeDay.nbDays >= 0
                  ? 'text-red bg-red-light'
                  : 'text-green bg-green-light'
              } ${
                status !== CONTAINER_STATUS_ENUM.IMPORT
                  ? 'text-gray-400 bg-gray-600'
                  : ''
              }`}
            >
              {deltaLastFreeDay.text}
            </h6>
          )}
        </div>

        {pickupDemurrageDue && (
          <h6
            className={`uppercase font-medium text-red ${
              status !== CONTAINER_STATUS_ENUM.IMPORT ? 'text-gray-400' : ''
            }`}
            style={{
              marginTop:
                'calc(0.5rem * calc(1 - var(--tw-space-y-reverse)) - 2px)',
            }}
          >
            Demurrage {status === CONTAINER_STATUS_ENUM.IMPORT ? 'Due' : ''}{' '}
            {pickupDemurrageFeeUsCents !== 0 &&
              formatDemurrageFee(pickupDemurrageFeeUsCents)}
          </h6>
        )}
      </div>
    </div>
  );
}

function formatDemurrageFee(fee?: number): string {
  if (!fee) return '';
  return `($${(fee / 100).toLocaleString()})`;
}
