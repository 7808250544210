import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import InformationPage from '../../components/InformationPage';

export default class ContactUs extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <InformationPage>
        <Typography variant="h2" paragraph color="secondary">
          CONTACT US
        </Typography>
        <Typography
          variant="h5"
          gutterBottom
          color="textPrimary"
          className={classes.text}
        >
          Feel free to give us feedback on our platform by sending an email to
        </Typography>
        <Typography
          variant="h3"
          gutterBottom
          color="textPrimary"
          component="a"
          href="mailto:contact@bluecargo.io"
        >
          contact@bluecargo.io
        </Typography>
      </InformationPage>
    );
  }
}
