import erlClient from './';

export const getData = async (shippingLines, containerTypes) => {
  const res = await erlClient.post(`/core/api/v1/erl/table`, {
    shipping_lines: shippingLines,
    container_types: containerTypes,
  });
  return res.data;
};

export const getOverwrittenRules = async (shippingLines, containerTypes) => {
  const res = await erlClient.post(`/core/api/v1/erl/table_overwrite`, {
    shipping_lines: shippingLines,
    container_types: containerTypes,
  });
  return res.data;
};

export const getShippingLines = async () => {
  const res = await erlClient.get(`/core/api/v1/erl/shipping_line`);
  return sortOnName(res.data);
};

export function sortOnName(rows) {
  return rows.sort((a, b) => {
    const aName = (a.name || '').toUpperCase();
    const bName = (b.name || '').toUpperCase();
    return aName === bName ? 0 : aName < bName ? -1 : 1;
  });
}

export const getContainerTypes = async () => {
  const res = await erlClient.get(`/core/api/v1/erl/container_type`);
  return res.data;
};

export const getPorts = async () => {
  const res = await erlClient.get(`/core/api/v1/erl/port`);
  return res.data;
};

export const getSingleAvailability = async (
  shippingLine,
  containerType,
  shift,
  port,
  date
) => {
  const res = await erlClient.post('/core/api/v1/erl/availability', {
    shipping_line: shippingLine,
    container_type: containerType,
    shift,
    port,
    date,
  });
  return res.data;
};

export const getFunctionalParser = async () => {
  const res = await erlClient.get('core/api/v1/erl/functional_parsers');
  return res.data;
};
export const getShippingLinesMapping = async () => {
  const res = await erlClient.get('/core/api/v1/erl/mapping_shipping_line', {
    headers: { Authorization: '' },
  });
  return res.data;
};

export const getGateHours = async () => {
  const res = await erlClient.get('/core/api/v1/erl/gate_hours');
  return res.data;
};

export const getFirstAppointment = async () => {
  const res = await erlClient.get('/core/api/v1/tas/first_appointment');
  return res.data;
};

export const getAvailabilityAppointments = async (line, container_type) => {
  try {
    const res = await erlClient.get(
      '/core/api/v1/tas/availability-appointments',
      {
        params: {
          line,
          container_type,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAvailabilityCredentialsEnd = async () => {
  const res = await erlClient.get('/core/api/v1/appointments/credentials');
  return res.data;
};

export const getSlAndContainerTypeByContainerIdEnd = async container_id => {
  const res = await erlClient.get(
    `/core/api/v1/container/get_shipping_line?container_id=${container_id}`
  );
  return res.data;
};

export const bookAppointment = async (appointmentData, callback) => {
  try {
    // setTimeout(() => {
    //   callback(null, {
    //     data: {
    //       appointment_id: 'zzzz222222', container_id: 'qwerty123'
    //     }
    //   });
    // }, 1000);
    const res = await erlClient.post(
      '/core/api/v1/appointments/book_slot',
      appointmentData
    );
    callback(null, res);
  } catch (error) {
    console.error(error);
    callback(error);
  }
};
