import React, { Component } from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import {
  TableCell,
  Table,
  TableBody,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as RefreshIcon } from 'assets/images/refresh.svg';
import { NextAppointmentsService } from '../NextAppointments/services/next-appointments.service';
import { MESSAGES } from '../NextAppointments/constants/next-appointments.constants';

export class LastRefreshHeader extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    displayShift: PropTypes.bool.isRequired,
    terminals: PropTypes.array.isRequired,
    lastRefreshData: PropTypes.object.isRequired,
    selectedSlKey: PropTypes.string.isRequired,
    availabilityCredentials: PropTypes.object.isRequired,
  };

  static defaultProps = {
    lastRefreshData: {},
  };

  renderNoData = () => {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Tooltip
          title={
            <Typography variant="caption" className={classes.tooltipText}>
              This information cannot be retrieve right now. Please manually
              verify the instructions on terminal website. All BlueCargo Team
              apologize for any inconvenience caused.
            </Typography>
          }
        >
          <Typography variant="caption" className={classes.lastRefresh}>
            No data
          </Typography>
        </Tooltip>
      </div>
    );
  };

  renderLastRefreshCell = terminal => {
    const { classes } = this.props;

    // BCIC-469 Apply Everport's rules above other table's rules
    const isEverport = NextAppointmentsService.checkIsEverport(terminal.key);
    if (isEverport) {
      return (
        <TableCell key={Math.random()} className={classes.cell}>
          <Typography variant="caption" className={classes.lastRefresh}>
            {MESSAGES.registrationRequired}
          </Typography>
        </TableCell>
      );
    }

    return (
      <TableCell key={Math.random()} className={classes.cell}>
        {this.getFormatedLastRefreshTimeFor(terminal)}
      </TableCell>
    );
  };

  getFormatedLastRefreshTimeFor = terminal => {
    const { classes } = this.props;
    const isMatson = NextAppointmentsService.checkIsMatsonTerminal(terminal);

    if (isMatson) {
      return (
        <Typography variant="caption" className={classes.lastRefresh}>
          No appointment required
        </Typography>
      );
    }

    const isPTSC = NextAppointmentsService.checkIsPtscTerminal(terminal);
    if (isPTSC) {
      return (
        <Typography variant="caption" className={classes.lastRefresh}>
          Confirm with SSL
        </Typography>
      );
    }

    if (terminal && terminal.no_data_appointments) {
      // return this.renderNoData();
      return this.renderCheckSystemName(terminal);
    }
    if (!terminal) {
      return <>no terminal selected</>;
    }

    const { lastRefreshData, selectedSlKey } = this.props;

    const dataByTerminal = lastRefreshData[terminal.key];
    let lastRefreshTime = '';
    if (dataByTerminal && dataByTerminal[selectedSlKey]) {
      lastRefreshTime = dataByTerminal[selectedSlKey].last_recording_time;
    }

    if (lastRefreshTime) {
      const refreshDate = moment(lastRefreshTime);
      // BCIC-806 reduce outdatedHours from 24 to 2
      // if (moment(refreshDate).isSame(today, 'day')) {
      const isLastRefreshOutdated = NextAppointmentsService.checkIsLastRefreshOutdated(
        lastRefreshTime
      );
      if (isLastRefreshOutdated) {
        return this.renderCheckSystemName(terminal);
        // return moment(refreshDate, ['hh:mm']).format('hh:mm A');
      } else {
        return moment(refreshDate, ['hh:mm']).format('hh:mm A');
        // return this.renderCheckSystemName(terminal);
      }
    } else {
      return null;
    }
  };

  renderCheckSystemName = terminal => {
    const { classes } = this.props;
    return (
      <a
        className={classes.checkTerminalPortalHeader}
        href={terminal.terminal_portal}
        target="_blank"
        rel="noopener noreferrer"
      >{`Check ${terminal.appointment_system_name} portal`}</a>
    );
  };

  render() {
    const { classes, terminals } = this.props;

    return (
      <Table className={classes.table} aria-label="last refresh table">
        <TableBody>
          <TableRow className={classes.row}>
            <TableCell className={classnames(classes.headerCell)}>
              <div className="flex space-x-2 items-center">
                <RefreshIcon className={classes.icon} />
                <span>Last refresh</span>
              </div>
            </TableCell>
            {terminals.map(terminal => this.renderLastRefreshCell(terminal))}
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default LastRefreshHeader;
