import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import Fab from '@material-ui/core/Fab';

const ScrollTopButton = ({ classes }) => {
  const scrollTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <Fab
      color="primary"
      aria-label="scroll-top"
      className={classes.fab}
      onClick={scrollTop}
    >
      <UpIcon fontSize="large" />
    </Fab>
  );
};

ScrollTopButton.propTypes = {
  classes: PropTypes.object.isRequired,
};
const styles = theme => ({
  fab: {
    marginTop: theme.spacing.unit,
    position: 'absolute',
    right: theme.spacing.unit,
  },
});

export default withStyles(styles)(ScrollTopButton);
