export const credsStatuses = {
  valid: 'valid',
  invalid: 'invalid',
  none: 'none',
};

export const credsErrorStatuses = [406, 409, 412, 424];

export const terminalsNames = {
  trapac: {
    key: 'trapac',
    name: 'Trapac',
    pier: 'Trapac',
    helper:
      'These credentials will allow you to check appointment availability for Trapac, Everport, Pier A and PCT.',
    tip: 'The login for eModal is a username or an email address.',
  },
  everport: {
    key: 'everport',
    name: 'ETS',
    pier: 'Everport',
    helper:
      'These credentials will allow you to check appointment availability for Trapac, Everport, Pier A and PCT.',
    tip: 'The login for eModal is a username or an email address.',
  },
  ssa_pct: {
    key: 'ssa_pct',
    name: 'SSA PCT',
    pier: 'PCT',
    helper:
      'These credentials will allow you to check appointment availability for Trapac, Everport, Pier A and PCT.',
    tip: 'The login for eModal is a username or an email address.',
  },
  ssa_piera: {
    key: 'ssa_piera',
    name: 'SSA Pier A',
    pier: 'PIER A',
    helper:
      'These credentials will allow you to check appointment availability for Trapac, Everport, Pier A and PCT.',
    tip: 'The login for eModal is a username or an email address.',
  },
  its: {
    key: 'its',
    name: 'ITS',
    pier: 'PIER G',
    helper:
      'These credentials will allow you to check appointment availability for Trapac, Everport, Pier A and PCT.',
    tip: 'The login for eModal is a username or an email address.',
  },
  yti: {
    key: 'yti',
    name: 'YTI',
    pier: 'YTI',
    helper:
      'These credentials will allow you to check appointment availability for YTI and Fenix.',
    tip: 'The login for Voyage Control is an email address.',
  },
  fms: {
    key: 'fms',
    name: 'Fenix',
    pier: 'PIER 300',
    helper:
      'These credentials will allow you to check appointment availability for YTI and Fenix.',
    tip: 'The login for Voyage Control is an email address.',
  },
  apm: {
    key: 'apm',
    name: 'APMT',
    pier: 'PIER 400',
    helper:
      'These credentials will allow you to check appointment availability for APMT.',
    tip: 'The login for Termpoint is a username',
  },
  lbct: {
    key: 'lbct',
    name: 'LBCT',
    pier: 'PIER E',
    helper:
      'These credentials will allow you to check appointment availability for LBCT.',
    tip: 'The login for LBCT is an email address.',
  },
  tti: {
    key: 'tti',
    name: 'TTI',
    pier: 'PIER T',
    helper:
      'These credentials will allow you to check appointment availability for TTI.',
    tip: 'The login for TTI is a username.',
  },
  wbct: {
    key: 'wbct',
    name: 'WBCT',
    pier: 'WBCT',
    helper:
      'These credentials will allow you to check appointment availability for WBCT.',
    tip: 'The login for Voyager Track is a username.',
  },
};

export const terminalsOrdered = [
  {
    ...terminalsNames.trapac,
    isDisabled: false,
    isMain: true,
  },
  {
    ...terminalsNames.everport,
    isDisabled: false,
  },
  {
    ...terminalsNames.ssa_pct,
    isDisabled: false,
  },
  {
    ...terminalsNames.ssa_piera,
    isDisabled: false,
  },
  {
    ...terminalsNames.yti,
    isDisabled: false,
    isMain: true,
  },
  {
    ...terminalsNames.fms,
    isDisabled: false,
  },
  {
    ...terminalsNames.apm,
    isDisabled: false,
    isMain: true,
  },
  {
    ...terminalsNames.lbct,
    isDisabled: false,
    isMain: true,
  },
  {
    ...terminalsNames.tti,
    isDisabled: false,
    isMain: true,
  },
  {
    ...terminalsNames.wbct,
    isDisabled: false,
    isMain: true,
  },
];
