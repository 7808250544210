import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InformationPage from '../../components/InformationPage';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import Avatar from '@material-ui/core/Avatar';
import { validateEmail } from '../../services/helpers/user';
import Formular from './components/Formular';
import { resetPassword } from '../../services/clients/user';
import { ReactComponent as KeyIcon } from 'assets/images/key.svg';
import { ReactComponent as MailIcon } from 'assets/images/Checkmark.svg';

export default class ResetPassword extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
  state = {
    emailSent: false,
  };

  validate = values => {
    let errors = {};
    if (!values.email) errors.email = 'Required';
    else if (!validateEmail(values.email))
      errors.email = 'Invalid email address';

    return errors;
  };

  submit = (values, { setSubmitting, setErrors }) => {
    resetPassword(values)
      .then(() => {
        setSubmitting(false);
        this.setState({ emailSent: true });
      })
      .catch(e => {
        const errors = e;

        setErrors(errors);
        setSubmitting(false);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <InformationPage>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <KeyIcon />
            </Avatar>
            <Typography component="h1" variant="h5" gutterBottom>
              Password
            </Typography>
            <Typography variant="caption" gutterBottom>
              Password reset
            </Typography>
            {this.state.emailSent ? (
              <div>
                <div className="w-full flex justify-center items-center">
                  <MailIcon className={classes.mailIcon} />
                </div>
                <Typography variant="caption" gutterBottom>
                  We’ve sent you an email with a link.
                </Typography>
                <Typography variant="caption">
                  {' Click on the link to reset the password.'}
                </Typography>
              </div>
            ) : (
              <Formik
                initialValues={{ email: '' }}
                validate={this.validate}
                onSubmit={this.submit}
              >
                {props => <Formular {...props} />}
              </Formik>
            )}
          </Paper>
        </main>
      </InformationPage>
    );
  }
}
