import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InformationPage from '../../components/InformationPage';

export default class TermsOfUse extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;
    return (
      <InformationPage>
        <Paper className={classes.paper}>
          <div className={classes.title}>
            <div
              style={{
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              BlueCargo
            </div>
            <Typography variant="h3" align="left">
              Terms of Use
            </Typography>
            <Typography gutterBottom variant="caption" align="left">
              Last revised: November 3nd, 2020
            </Typography>
          </div>
          <div className={classes.scrollable}>
            <Typography variant="body2" paragraph align="left">
              These terms of use (these "<b>Terms</b>") apply to your access to
              and use of our online empty return location platform, including
              any application programming interfaces ("<b>APIs</b>")
              (collectively, the "<b>Service</b>") that BlueCargo, Inc. provides
              through our website located at{' '}
              <a
                href="https://erl.bluecargo.io/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#0000EE' }}
              >
                https://erl.bluecargo.io
              </a>{' '}
              (the "<b>Site</b>") and our mobile application (the "<b>App</b>").
            </Typography>
            <Typography variant="body2" paragraph align="left">
              By registering to use the Service or accessing the Service, you
              agree and acknowledge that you have read, understand, and agree to
              be legally bound by these Terms. If you are using the Service on
              behalf of another person or entity, then (a) all references to
              "you" throughout these Terms will include that person or entity,
              (b) you represent that you are authorized to accept these Terms on
              that person or entity’s behalf, and (c) in the event that you or
              that person or entity violates these Terms, that person or entity
              is responsible to us.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              These Terms are effective as of the date you first access the
              Service.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                PLEASE READ THESE TERMS CAREFULLY, INCLUDING THE MANDATORY
                ARBITRATION AGREEMENT IN THE "ARBITRATION" SECTION OF THESE
                TERMS, WHICH REQUIRES THAT DISPUTES BE RESOLVED BY FINAL AND
                BINDING ARBITRATION ON AN INDIVIDUAL BASIS, NOT A CLASS-WIDE OR
                CONSOLIDATED BASIS. IF YOU DO NOT WISH TO BE SUBJECT TO
                ARBITRATION, YOU MAY OPT OUT OF THE ARBITRATION AGREEMENT BY
                FOLLOWING THE INSTRUCTIONS PROVIDED IN THE "ARBITRATION" SECTION
                OF THESE TERMS.
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We reserve the right to change these Terms at any time in our sole
              discretion by providing notice that these Terms have been changed.
              We may provide notice by sending an email, posting a notice on the
              Site or the App, posting the revised Terms on the Site or the App
              and revising the date at the top of these Terms, or another form
              of notice. Any changes or modifications will be effective
              immediately and your continued use of the Service following the
              notice will constitute your acceptance of the changes.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              Privacy
            </Typography>
            <Typography variant="body2" paragraph align="left">
              For information about how we collect, use and share information
              about you, please see our Privacy Policy.{' '}
              <a
                href="https://erl.bluecargo.io/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#0000EE' }}
              >
                https://erl.bluecargo.io/privacy-policy
              </a>
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              THE SERVICE
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                <u>Access to the Service (including APIs)</u>
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We grant to you a non-exclusive, non-transferable,
              non-sublicensable, revocable, and limited license to access and
              use the Service subject to these Terms. We may make available APIs
              to you. If we make available APIs, then we grant you a
              non-exclusive, non-transferable, non-sublicensable, revocable and
              limited license to access, use, and integrate into your systems
              such APIs solely as necessary for you to use the Service and
              subject to these Terms.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                <u>Registration</u>
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              In order to access the Service, you must register with us by
              completing the registration forms that we make available to you.
              You agree to (a) provide accurate, current, and complete
              information as may be prompted by the registration forms ("
              <b>Registration Data</b>"), (b) maintain the security of your
              account password, (c) maintain and promptly update the
              Registration Data, and any other information you provide to us, to
              keep it accurate, current, and complete, and (d) accept all risks
              of unauthorized access to the Registration Data and any other
              information you provide to us.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You are responsible for safeguarding the password you use to
              access the Service and agree to be fully responsible for
              activities or transactions that relate to your account or
              password. You must notify us immediately if you learn of an
              unauthorized use of your account or password.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We may remove you from all or part of the Service at our sole
              discretion.
            </Typography>

            <Typography variant="body2" paragraph align="left">
              <b>
                <u>Subscriptions</u>
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You may subscribe to the Service under one of our subscription
              plans found on the Site. We will collect from you the subscription
              fees as set forth in your selected subscription plan. All payments
              must be made in U.S. Dollars by credit or debit card via our
              authorized payment processor. You hereby authorize us (or our
              authorized payment processor) to charge the credit or debit card
              number that you provide via the Service in accordance with the
              subscription plan selected and you represent and warrant that you
              are authorized to use, and have fees charged to, the credit or
              debit card number provided to us. You understand that you may
              withdraw such authorization by contacting us at
              contact@bluecargo.io.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                UNLESS OTHERWISE EXPRESSLY SET FORTH IN THE SUBSCRIPTION PLAN
                YOU SELECT, OUR SUBSCRIPTION PLANS WILL AUTOMATICALLY RENEW FOR
                ADDITIONAL PERIODS EQUIVALENT IN LENGTH TO THE INITIAL
                SUBSCRIPTION PERIOD SELECTED BY YOU UNTIL CANCELLED BY YOU. YOU
                MAY CHANGE OR CANCEL SUBSCRIPTION PLANS AT ANY TIME BY FOLLOWING
                THE INSTRUCTIONS{' '}
                <a
                  href="https://erl.bluecargo.io/profile/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#0000EE' }}
                >
                  HERE
                </a>{' '}
                OR THE INSTRUCTIONS INCLUDED IN YOUR SUBSCRIPTION PLAN. NO
                REFUNDS WILL BE ISSUED.
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                <u>User Content</u>
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              The Service will allow you and other users to create, post, store
              and share content, including messages, text, photos, videos, and
              other materials (collectively, "<b>User Content</b>"). Except for
              the license you grant below, you retain all rights in and to your
              User Content, as between you and us.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You grant us a perpetual, irrevocable, nonexclusive, royalty-free,
              worldwide, fully paid, and sublicensable license to use,
              reproduce, modify, adapt, publish, translate, create derivative
              works from, distribute, publicly perform and display your User
              Content and any name, username or likeness provided in connection
              with your User Content in all media formats and channels now known
              or later developed without compensation to you. When you post or
              otherwise share User Content on or through the Service, you
              understand that your User Content and any associated information
              (such as your username or profile photo) may be visible to others.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You may not create, post, store or share any User Content that
              violates these Terms or for which you do not have all the rights
              necessary to grant us the license described above. You represent
              and warrant that your User Content, and our use of such content as
              permitted by these Terms, will not violate any rights of or cause
              injury to any person or entity. Although we have no obligation to
              screen, edit or monitor User Content, we may delete or remove User
              Content at any time and for any reason with or without notice.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                <u>Restrictions</u>
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              Except as expressly authorized by these Terms, you may not
            </Typography>
            <div style={{ marginBottom: '16px', textAlign: 'left' }}>
              <ul>
                <li style={{ marginBottom: '10px' }}>
                  modify, disclose, alter, translate, or create derivative works
                  the Service (or any components thereof),
                </li>
                <li style={{ marginBottom: '10px' }}>
                  license, sublicense, resell, distribute, lease, rent, lend,
                  transfer, assign, or otherwise dispose of the Service (or any
                  components thereof),
                </li>
                <li style={{ marginBottom: '10px' }}>
                  disassemble, decompile, or reverse engineer the software
                  components of the Service,
                </li>
                <li style={{ marginBottom: '10px' }}>
                  use the Service to store or transmit infringing, libelous, or
                  otherwise unlawful or tortious material, or to store or
                  transmit material in violation of third-party privacy rights,
                </li>
                <li style={{ marginBottom: '10px' }}>
                  use the Service to store or transmit any viruses, software
                  routines, or other code designed to permit unauthorized
                  access, disable, erase, or otherwise harm software, hardware,
                  or data, or to perform any other harmful actions,
                </li>
                <li style={{ marginBottom: '10px' }}>
                  copy, frame, or mirror any part or content of the Service,
                </li>
                <li style={{ marginBottom: '10px' }}>
                  build a competitive product or service, or copy any features
                  or functions of the Service,
                </li>
                <li style={{ marginBottom: '10px' }}>
                  interfere with or disrupt the integrity or performance of the
                  Service,
                </li>
                <li style={{ marginBottom: '10px' }}>
                  attempt to gain unauthorized access to the Service or their
                  related systems or networks,
                </li>
                <li style={{ marginBottom: '10px' }}>
                  remove, alter, or obscure any proprietary notices in or on the
                  Service including copyright notices,
                </li>
                <li style={{ marginBottom: '10px' }}>
                  interfere in any manner with the enjoyment of the Service of
                  any other user, or
                </li>
                <li style={{ marginBottom: '10px' }}>
                  cause or permit any third party to do any of the foregoing.
                </li>
              </ul>
            </div>
            <Typography variant="body2" paragraph align="left">
              <b>
                <u>General Grant and Ownership</u>
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              Unless otherwise expressly indicated in these Terms, (a) all user
              profiles and user contact information and (b) all information,
              materials, and content of the Service, including text, graphics,
              data, formatting, graphs, designs, HTML, look and feel,
              photographs, music, sounds, images, software, videos, designs,
              typefaces, source and object code, format, queries, algorithms,
              and other content (collectively, our “<b>Materials</b>”) are
              exclusively owned by us or are used with permission. You may not
              use or disclose any of our Materials without our express prior
              written consent.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              CONFIDENTIALITY
            </Typography>
            <Typography variant="body2" paragraph align="left">
              Each party agrees not to disclose the terms and conditions of
              these Terms to any third party; provided, however, each party may
              disclose the terms and conditions of these Terms in confidence (a)
              to such party’s legal counsel, accountants, banks, financing
              sources and their advisors, (b) in connection with the enforcement
              of these Terms or rights under these Terms, or (c) in connection
              with an actual or proposed merger, acquisition or similar
              transaction.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              INDUSTRY CREDENTIALS
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You may share your terminal operator credentials (e.g., user name,
              email address, and password) ("<b>Industry Credentials</b>") with
              us, which we may use to log into third-party terminal operator
              websites or platforms ("<b>Terminal Sites</b>") in order for us to
              provide certain features of the Service. You authorize us to use
              your Industry Credentials in accordance with these Terms and our
              Privacy Policy. All Industry Credentials constitute User Content,
              as defined in these Terms.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              Although we will use your Industry Credentials on Terminal Sites
              with the intent of improving the Service as provided to you, when
              we use your Industry Credentials on Terminal Sites, the
              third-party terminal operators may, in whole or in part, block or
              disable your access to their Terminal Sites.{' '}
              <b>
                You acknowledge and agree that if you do not provide your
                Industry Credentials, certain features of the Service may be
                unavailable to you. You further acknowledge and agree that we
                assume no liability or responsibility for acts or omissions of
                these third-party terminal operators, whether in response to us
                using your Industry Credentials on their Terminal Sites or
                otherwise.
              </b>
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              FEEDBACK AND USAGE DATA
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We will be free to use, disclose, reproduce, license, and
              otherwise distribute and exploit any suggestions, comments, or
              other feedback provided by you to us with respect to the Service
              as we see fit, entirely without obligation or restriction of any
              kind, on account of intellectual property rights or otherwise.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We may collect and analyze data and other information relating to
              the provision, use and performance of various aspects of the
              Service and related systems and technologies (including, without
              limitation, information concerning User Content and data derived
              therefrom) ("<b>Usage Data</b>"), and we may (a) use such Usage
              Data to improve and enhance the Service and for other development,
              diagnostic and corrective purposes in connection with the Service
              and other BlueCargo offerings and (b) disclose such Usage Data
              solely in aggregate or other de-identified form in connection with
              our business.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              THIRD-PARTY SITES
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We make no claim or representation regarding, and accept no
              responsibility for, the quality, content, nature, or reliability
              of third-party sites accessible by link from the Service, App, or
              Site. We provide these links to you only as a convenience and the
              inclusion of any link does not imply our affiliation, endorsement,
              or adoption of the corresponding site or any information contained
              in (or made available via) that site. When you leave the Site or
              App, as applicable, our terms and policies no longer govern. You
              should review the applicable terms and policies, including privacy
              and data-gathering practices, of any site to which you navigate
              from the Site or App.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              Trademarks
            </Typography>
            <Typography variant="body2" paragraph align="left">
              Our name, trademarks, logos, and any other of our products,
              service names, or slogans included in the Service are our property
              and may not be copied, imitated, or used (in whole or in part)
              without our prior written consent. The look and feel of the
              Service, the App, and the Site, including all custom graphics,
              button icons, and scripts constitute our service marks,
              trademarks, or trade dress and may not be copied, imitated, or
              used (in whole or in part) without our prior written consent. All
              other trademarks, registered trademarks, product names, and
              company names or logos mentioned in the Service ("
              <b>Third-Party Trademarks</b>") are the property of their
              respective owners, and the use of such Third-Party Trademarks
              inures to the benefit of each owner. The use of such Third-Party
              Trademarks is intended to denote interoperability and does not
              constitute an affiliation by us or our licensors with such company
              or an endorsement or approval by such company of us or our
              licensors or their respective products or services.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              SUSPENSION OR TERMINATION
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We may, at our sole discretion, suspend or terminate your license
              to access or use part or all of the Service at any time and for
              any reason without notice. You must stop accessing or using the
              Service immediately if we suspend or terminate your license to
              access or use the Service. We reserve the right, but do not
              undertake any duty, to take appropriate legal action including,
              without limitation, pursuing civil, criminal, or injunctive
              redress against you for continuing to use the Service during
              suspension or after termination. We may recover our reasonable
              attorneys’ fees and court costs from you for such actions. These
              Terms will remain enforceable against you while your license to
              access or use the Service is suspended and after it is terminated.
              Except for the license granted to you to access and use the
              Service and all payment terms, all of the terms, conditions, and
              restrictions set forth in these Terms will survive the termination
              of these Terms.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              DISCLAIMER AND LIMITATION OF LIABILITY
            </Typography>
            <Typography variant="body2" paragraph align="left">
              YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
              PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE DISCLAIM ALL
              WARRANTIES AND REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR
              WRITTEN) WITH RESPECT TO THE SERVICE, WHETHER ALLEGED TO ARISE BY
              OPERATION OF LAW, BY REASON OF CUSTOM OR USAGE IN THE TRADE, BY
              COURSE OF DEALING OR OTHERWISE, INCLUDING ANY WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR ANY PURPOSE, NON-INFRINGEMENT, AND
              CONDITION OF TITLE.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE WILL NOT BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INCIDENTAL, SPECIAL,
              INDIRECT, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHATSOEVER
              ARISING OUT OF OR RELATED TO THE SERVICE, HOWEVER CAUSED,
              REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, WARRANTY, TORT
              (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED),
              PRODUCT LIABILITY, STRICT LIABILITY, OR OTHER THEORY), EVEN IF WE
              HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              IN NO EVENT WILL OUR AGGREGATE LIABILITY ARISING OUT OF OR RELATED
              TO THE SERVICE EXCEED THE AMOUNT OF SUBSCRIPTION FEES ACTUALLY
              PAID BY YOU TO US DURING THE SIX-MONTH PERIOD PRECEDING THE
              INCIDENT GIVING RISE TO SUCH LIABILITY.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
              OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              INDEMNIFICATION
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You agree, at your sole expense, to defend, indemnify and hold us
              (and our directors, officers, employees, consultants and agents)
              harmless from and against any and all actual or threatened suits,
              actions, proceedings (at law or in equity), claims, damages,
              payments, deficiencies, fines, judgments, settlements,
              liabilities, losses, costs and expenses (including, but not
              limited to, reasonable attorneys’ fees, costs, penalties, interest
              and disbursements) that we (or our directors, officers, employees,
              consultants, or agents, individually or collectively) incur and
              are caused by, arise out of, result from, are attributable to, or
              are in any way incidental to any of your conduct or any actual or
              alleged breach of any of your obligations under these Terms
              (including, but not limited to, any actual or alleged breach of
              any of your representations or warranties as set forth in these
              Terms).
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              ARBITRATION
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES
                YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH US AND LIMITS
                THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US, UNLESS YOU OPT
                OUT OF ARBITRATION BY FOLLOWING THE INSTRUCTIONS SET FORTH
                BELOW. NO CLASS OR REPRESENTATIVE ACTIONS OR ARBITRATIONS ARE
                ALLOWED UNDER THIS ARBITRATION AGREEMENT. IN ADDITION,
                ARBITRATION PRECLUDES YOU FROM SUING IN COURT OR HAVING A JURY
                TRIAL.
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                No Representative Actions. The parties agree that any dispute
                arising out of or related to these Terms or the Service is
                personal to the parties and that any dispute will be resolved
                solely through individual action, and will not be brought as a
                class arbitration, class action or any other type of
                representative proceeding.
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>Arbitration of Disputes.</b> Except for small claims disputes
              in which a party seeks to bring an individual action in small
              claims court located in the county of your billing address or
              disputes in which a party seeks injunctive or other equitable
              relief for the alleged unlawful use of intellectual property,{' '}
              <b>
                each party waives its rights to a jury trial and to have any
                dispute arising out of or related to these Terms or the Service
                resolved in court
              </b>
              . Instead, for any dispute or claim that you have against us or
              relating in any way to the Service, you agree to first contact us
              and attempt to resolve the claim informally by sending a written
              notice of your claim ("<b>Notice</b>") to us by email at
              contact@bluecargo.io or by certified mail addressed to BlueCargo,
              Inc., 340 S. Lemon Ave. #6606, Walnut, CA 91789. The Notice must
              (a) include your name, residence address, email address, and
              telephone number, (b) describe the nature and basis of the claim,
              and (c) set forth the specific relief sought. Our notice to you
              will be similar in form to that described above. If the parties
              cannot reach an agreement to resolve the claim within thirty (30)
              days after such Notice is received, then either party may submit
              the dispute to binding arbitration administered by JAMS or, under
              the limited circumstances set forth above, in court. All disputes
              submitted to JAMS will be resolved through confidential, binding
              arbitration before one arbitrator. Arbitration proceedings will be
              held in Los Angeles, California in accordance with the JAMS
              Streamlined Arbitration Rules and Procedures ("<b>JAMS Rules</b>
              "). The most recent version of the JAMS Rules are available on the{' '}
              <a
                href="https://www.jamsadr.com/rules-streamlined-arbitration/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#0000EE' }}
              >
                JAMS website
              </a>{' '}
              and are hereby incorporated by reference. You either acknowledge
              and agree that you have read and understand the JAMS Rules or
              waive your opportunity to read the JAMS Rules and waive any claim
              that the JAMS Rules are unfair or should not apply for any reason.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              The parties agree that these Terms affect interstate commerce and
              that the enforceability of this arbitration agreement will be
              substantively and procedurally governed by the Federal Arbitration
              Act, 9 U.S.C. § 1, et seq. (the "FAA"), to the maximum extent
              permitted by applicable law. As limited by the FAA, these Terms
              and the JAMS Rules, the arbitrator will have exclusive authority
              to make all procedural and substantive decisions regarding any
              dispute and to grant any remedy that would otherwise be available
              in court, including the power to determine the question of
              arbitrability. The arbitrator may conduct only an individual
              arbitration and may not consolidate more than one individual’s
              claims, preside over any type of class or representative
              proceeding or preside over any proceeding involving more than one
              individual.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              The arbitrator and the parties will maintain the confidentiality
              of any arbitration proceedings, judgments and awards, including,
              but not limited to, all information gathered, prepared and
              presented for purposes of the arbitration or related to the
              dispute(s) therein. The arbitrator will have the authority to make
              appropriate rulings to safeguard confidentiality, unless the law
              provides to the contrary. The duty of confidentiality does not
              apply to the extent that disclosure is necessary to prepare for or
              conduct the arbitration hearing on the merits, in connection with
              a court application for a preliminary remedy or in connection with
              a judicial challenge to an arbitration award or its enforcement,
              or to the extent that disclosure is otherwise required by law or
              judicial decision.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              The parties agree that for any arbitration you initiate, you will
              pay the filing fee and we will pay the remaining JAMS fees and
              costs. For any arbitration initiated by us, we will pay all JAMS
              fees and costs. The parties agree that the state or federal courts
              of the State of California and the United States sitting in Los
              Angeles County, California have exclusive jurisdiction over any
              appeals and the enforcement of an arbitration award.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                Any claim arising out of or related to these Terms or the
                Service must be filed within one year after such claim arose;
                otherwise, the claim is permanently barred, which means that
                neither party will have the right to assert the claim.
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                You have the right to opt out of binding arbitration within 30
                days of the date you first accepted the terms of this
                arbitration agreement by sending an email to
                contact@bluecargo.io.
              </b>{' '}
              In order to be effective, the opt-out notice must include your
              full name and address and clearly indicate your intent to opt out
              of binding arbitration. By opting out of binding arbitration, you
              are agreeing to resolve disputes in accordance with the "Governing
              Law and Venue" section of these Terms.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              If any portion of this arbitration agreement is found to be
              unenforceable or unlawful for any reason, (i) the unenforceable or
              unlawful provision will be severed from these Terms, (ii)
              severance of the unenforceable or unlawful provision will have no
              impact whatsoever on the remainder of this arbitration agreement
              or the parties’ ability to compel arbitration of any remaining
              claims on an individual basis pursuant to this arbitration
              agreement, and (iii) to the extent that any claims must therefore
              proceed on a class, collective, consolidated, or representative
              basis, such claims must be litigated in a civil court of competent
              jurisdiction and not in arbitration, and the parties agree that
              litigation of those claims will be stayed pending the outcome of
              any individual claims in arbitration. Further, if any part of this
              arbitration agreement is found to prohibit an individual claim
              seeking public injunctive relief, that provision will have no
              effect to the extent such relief is allowed to be sought out of
              arbitration, and the remainder of this arbitration agreement will
              be enforceable.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              MISCELLANEOUS
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <u>Governing Law and Venue</u>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              Any dispute arising from these Terms and your use of the Service
              will be governed by and construed and enforced in accordance with
              the laws of the State of California, except to the extent
              preempted by U.S. federal law, without regard to conflict of law
              rules or principles (whether of the State of California or any
              other jurisdiction) that would cause the application of the laws
              of any other jurisdiction. Any dispute between the parties that is
              not subject to arbitration or cannot be heard in small claims
              court will be resolved in the state or federal courts of the State
              of California and the United States, respectively, sitting in Los
              Angeles County, California.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <u>Independent Contractors</u>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              Neither party will, for any purpose, be deemed to be an agent,
              franchisor, franchisee, employee, representative, owner, or
              partner of the other party, and the relationship between the
              parties will only be that of independent contractors. Neither
              party will have any right or authority to assume or create any
              obligations or to make any representations or warranties on behalf
              of any other party, whether express or implied, or to bind the
              other party in any respect whatsoever.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <u>Assignment</u>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You may not assign, delegate, or transfer (by sale, merger,
              operation of law, or otherwise) these Terms or any right, title,
              interest, or obligation hereunder without our prior written
              consent. Any attempted or purported assignment, delegation, or
              transfer in violation of the foregoing will be null and void and
              without effect. We may assign these Terms without your prior
              written consent. These Terms will be binding and inure to the
              benefit of such assignees, transferees, and other successors in
              the interest of the parties in the event of an assignment or other
              transfer made consistent with the provisions of these Terms.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <u>Third-Party Infrastructure</u>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              Notwithstanding any terms to the contrary in these Terms, you
              acknowledge and agree that we use a third party hosting
              infrastructure in connection with the Service ("
              <b>Third-Party Infrastructure</b>"), the provider(s) of the
              Third-Party Infrastructure disclaim and make no representation or
              warranty with respect to such Third-Party Infrastructure, and we
              assume no liability for any claim that may arise with respect to
              such Third-Party Infrastructure.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <u>Electronic Communications</u>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              By using the Service, you agree that we may communicate with you
              electronically regarding your use of the Service and that any
              notices, agreements, disclosures or other communications that we
              send to you electronically will satisfy any legal communication
              requirements, including that the communications be in writing. To
              withdraw your consent from receiving electronic notice, please
              notify us at contact@bluecargo.io.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <u>Severability</u>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              If any provision of these Terms is invalid, illegal, or incapable
              of being enforced by any rule of law or public policy, all other
              provisions of these Terms will nonetheless remain in full force
              and effect so long as the economic and legal substance of the
              transactions contemplated by these Terms is not affected in any
              manner adverse to any party. Upon such determination that any
              provision is invalid, illegal, or incapable of being enforced, the
              parties will negotiate in good faith to modify these Terms so as
              to effect the original intent of the parties as closely as
              possible in an acceptable manner to the end that the transactions
              contemplated hereby are fulfilled.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <u>Force Majeure</u>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We are not responsible for any failure to perform or delay
              attributable in whole or in part to any cause beyond its
              reasonable control including, but not limited to, acts of God
              (fire, storm, floods, earthquakes, etc.), acts of terrorism, civil
              disturbances, disruption of telecommunications, disruption of
              power or other essential services, interruption or termination of
              services provided by any service providers used by us, labor
              disturbances, vandalism, cable cut, computer viruses or other
              similar occurrences, or any malicious or unlawful acts of any
              third party.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <u>Notice for California Users</u>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              If you are a California resident, you may have these Terms mailed
              to you electronically by sending a letter to BlueCargo, Inc., 340
              S. Lemon Ave. #6606, Walnut, CA 91789 with your electronic mail
              address and a request for these Terms. Under California Civil Code
              Section 1789.3, California Website users are entitled to the
              following specific consumer rights notice: The Complaint
              Assistance Unit of the Division of Consumer Services of the
              California Department of Consumer Affairs may be contacted in
              writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
              California 95834, or by telephone at (800) 952-5210.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              ADDITIONAL TERMS APPLICABLE TO MOBILE DEVICES
            </Typography>
            <Typography variant="body2" paragraph align="left">
              The following terms apply if you install, access or use the App on
              any device that contains the iOS mobile operating system (the "
              <b>iOS App</b>") developed by Apple Inc. ("<b>Apple</b>").
            </Typography>
            <div style={{ marginBottom: '16px', textAlign: 'left' }}>
              <ul>
                <li style={{ marginBottom: '10px' }}>
                  <u>Acknowledgement.</u> You acknowledge that these Terms are
                  concluded solely between you and us, and not with Apple, and
                  we, not Apple, are solely responsible for the iOS App and the
                  content thereof. You further acknowledge that the usage rules
                  for the iOS App are subject to any additional restrictions set
                  forth in the Usage Rules for the Apple iOS App Store Terms of
                  Service as of the date you download the iOS App, and in the
                  event of any conflict, the Usage Rules in the App Store will
                  govern if they are more restrictive. You acknowledge and agree
                  that you have had the opportunity to review the Usage Rules.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <u>Scope of License.</u> The license granted to you is limited
                  to a non-transferable license to use the iOS App on any
                  iPhone, iPod touch or iPad that you own or control as
                  permitted by the Usage Rules set forth in the Apple App Store
                  Terms of Service.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <u>Maintenance and Support.</u> The parties acknowledge that
                  Apple has no obligation whatsoever to furnish any maintenance
                  and support services with respect to the iOS App.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <u>Warranty.</u> You acknowledge that Apple is not responsible
                  for any product warranties, whether express or implied by law,
                  with respect to the iOS App. In the event of any failure of
                  the iOS App to conform to any applicable warranty, you may
                  notify Apple, and Apple will refund the purchase price, if
                  any, paid to Apple for the iOS App by you; and to the maximum
                  extent permitted by applicable law, Apple will have no other
                  warranty obligation whatsoever with respect to the iOS App.
                  The parties acknowledge that to the extent that there are any
                  applicable warranties, any other claims, losses, liabilities,
                  damages, costs or expenses attributable to any failure to
                  conform to any such applicable warranty would be our sole
                  responsibility. However, you understand and agree that in
                  accordance with these Terms, we have disclaimed all warranties
                  of any kind with respect to the iOS App, and therefore, there
                  are no warranties applicable to the iOS App.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <u>Product Claims.</u> The parties acknowledge that as between
                  Apple and us, we, not Apple, are responsible for addressing
                  any claims relating to the iOS App or your possession and/or
                  use of the iOS App, including, but not limited to (a) product
                  liability claims, (b) any claim that the iOS App fails to
                  conform to any applicable legal or regulatory requirement, and
                  (c) claims arising under consumer protection or similar
                  legislation.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <u>Intellectual Property Rights.</u> The parties acknowledge
                  that, in the event of any third-party claim that the iOS App
                  or your possession and use of the iOS App infringe that third
                  party’s intellectual property rights, we, and not Apple, will
                  be solely responsible for the investigation, defense,
                  settlement and discharge of any such intellectual property
                  infringement claim to the extent required under these Terms.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <u>Legal Compliance.</u> You represent and warrant that (a)
                  you are not located in a country that is subject to a U.S.
                  Government embargo, or that has been designated by the U.S.
                  Government as a “terrorist supporting” country, and (b) you
                  are not listed on any U.S. Government list of prohibited or
                  restricted parties.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <Typography variant="body2" paragraph align="left">
                    <u>Developer Name and Address.</u> Any questions, complaints
                    or claims with respect to the iOS App should be directed to:
                  </Typography>
                  <div style={{ marginBottom: '16px', textAlign: 'left' }}>
                    <p style={{ margin: '0', paddingLeft: '20px' }}>
                      BlueCargo, Inc.
                    </p>
                    <p style={{ margin: '0', paddingLeft: '20px' }}>
                      340 S. Lemon Ave. #6606
                    </p>
                    <p style={{ margin: '0', paddingLeft: '20px' }}>
                      Walnut, CA 91789
                    </p>
                    <p style={{ margin: '0', paddingLeft: '20px' }}>
                      contact@bluecargo.io
                    </p>
                  </div>
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <u>Third-Party Terms of Agreement.</u> You agree to comply
                  with any applicable third-party terms when using the Service.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <u>Third-Party Beneficiary.</u> We and you acknowledge and
                  agree that Apple, and Apple’s subsidiaries, are third-party
                  beneficiaries of these Terms, and that, upon your acceptance
                  of these Terms, Apple will have the right (and will be deemed
                  to have accepted the right) to enforce these Terms against you
                  as a third-party beneficiary thereof:
                </li>
              </ul>
            </div>
            <Typography variant="body2" paragraph align="left">
              The following terms apply if you install, access, or use the App
              on any device that contains the Android mobile operating system ("
              <b>Android App</b>") developed by Google, Inc. ("<b>Google</b>")
            </Typography>
            <div style={{ marginBottom: '16px', textAlign: 'left' }}>
              <ul>
                <li style={{ marginBottom: '10px' }}>
                  You acknowledge that these Terms are between you and us only,
                  and not with Google.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Your use of the Android App must comply with Google’s
                  then-current Android Market Terms of Service.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Google is only a provider of the Android Market where you
                  obtained the Android App. We, and not Google, are solely
                  responsible for the Android App and the services and content
                  available thereon. Google has no obligation or liability to
                  you with respect to the Android App or these Terms.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  You acknowledge and agree that Google is a third-party
                  beneficiary to these Terms as they relate to the Android App.
                </li>
              </ul>
            </div>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              QUESTIONS
            </Typography>
            <Typography variant="body2" paragraph align="left">
              If you have any questions regarding the use of the Service, please
              email us at contact@bluecargo.io.
            </Typography>
          </div>
        </Paper>
      </InformationPage>
    );
  }
}
