import React from 'react';
import loadingSpinner from '../../assets/gif/loading-spinner.gif';

const LoadingSpinner = () => {
  return (
    <div>
      <img
        src={loadingSpinner}
        alt="loading spinner"
        width="150px"
        height="150px"
      />
    </div>
  );
};

export default LoadingSpinner;
