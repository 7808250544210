export const brandColors = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    blue: {
      DEFAULT: '#0025F1',
      100: '#3554ff',
      200: '#798dff',
      300: '#bcc6ff',
      400: '#dde3ff',
      500: '#ebeeff',
      600: '#f8f9ff',
      700: '#FBFBFF',
    },
    orange: {
      DEFAULT: '#F19100',
      100: '#ffa318',
      200: '#ffb23f',
      300: '#ffc265',
      400: '#ffd18c',
      500: '#ffe0b2',
      600: '#FFF8EE',
      dark: '#794900',
    },
    red: {
      DEFAULT: '#F10000',
      light: '#ffdddd',
      100: '#FFDDDD',
    },
    green: {
      DEFAULT: '#53BE7A',
      light: '#e9f7ee',
    },
    dark: {
      DEFAULT: '#000106',
      100: '#001279',
    },
    gray: {
      DEFAULT: '#030303',
      100: '#494B55',
      200: '#66676A',
      300: '#767A8B',
      400: '#9295A2',
      500: '#AEB0BA',
      600: '#E5E5E8',
    },
    gatedOut: {
      DEFAULT: '#F1E700',
      100: '#FFF718',
      200: '#FFF93F',
      300: '#FFFA65',
      400: '#FFFB8C',
      500: '#FFFCB2',
      600: '#FFFED9',
    },
    import: {
      DEFAULT: '#00F1F1',
      100: '#18FFFF',
      200: '#3FFFFF',
      300: '#65FFFF',
      400: '#8CFFFF',
      500: '#B2FFFF',
      600: '#D9FFFF',
    },
    vessel: {
      DEFAULT: '#5C00F1',
      100: '#7118FF',
      200: '#893FFF',
      300: '#A065FF',
      400: '#B88CFF',
      500: '#D0B2FF',
      600: '#E8D9FF',
    },
    export: {
      DEFAULT: '#F100BC',
      100: '#FF18CD',
      200: '#FF3FD5',
      300: '#FF65DE',
      400: '#FF8CE6',
      500: '#FFB2EE',
      600: '#FFD9F7',
    },
  },
  boxShadow: {
    sm: '1px 2px 4px rgba(0, 0, 0, 0.11)',
    md: '4px 8px 16px rgba(0, 0, 0, 0.1);',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    none: 'none',
  },
};
