import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

import Paper from '@material-ui/core/Paper';

import AvailabilityTable from 'pages/Terminals/components/AvailabilityTable';
import ShiftPicker from 'pages/Terminals/components/ShiftPicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import { STICKY_IE_CLASS } from 'constants/layout';
import classnames from 'classnames';
import SingleContainerTypePicker from 'components/SingleContainerTypePicker';
import { connect } from 'react-redux';
import {
  setShippingLinesFiltersAndReduceTerminals,
  clearFilters,
} from 'ducks/instructionFilters';
import isEqual from 'lodash/isEqual';

class Table extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    shippingLines: PropTypes.array.isRequired,
    containerTypes: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    displaySelector: PropTypes.bool,
    terminals: PropTypes.array,
    setShippingLinesFiltersAndReduceTerminals: PropTypes.func.isRequired,
    mapping: PropTypes.array,
    clearFilters: PropTypes.func.isRequired,
  };
  state = {
    filterAppplied: false,
  };

  componentDidUpdate(props) {
    if (
      (!this.state.filterAppplied ||
        !isEqual(this.props.shippingLines, props.shippingLines)) &&
      this.props.terminals &&
      this.props.shippingLines &&
      this.props.shippingLines.length &&
      this.props.mapping &&
      this.props.mapping.length
    ) {
      this.props.setShippingLinesFiltersAndReduceTerminals(
        this.props.shippingLines
      );
      this.setState({ filterAppplied: true });
    }
  }

  componentWillUnmount() {
    this.props.clearFilters();
  }

  render() {
    const {
      classes,
      shippingLines,
      containerTypes,
      isLoading,
      displaySelector,
      location,
    } = this.props;

    const shippingLine = shippingLines.length ? shippingLines[0] : null;
    const containerType = containerTypes.length ? containerTypes[0] : null;
    const defaultValue =
      shippingLine && containerType
        ? {
            shippingLine,
            containerType,
          }
        : undefined;

    return (
      <React.Fragment>
        {displaySelector && defaultValue && (
          <SingleContainerTypePicker
            classes={{ root: classes.containerType }}
            defaultValue={defaultValue}
            destination={location.pathname}
          />
        )}
        {isLoading && (
          <div className={classes.loaderWrapper}>
            <CircularProgress size={70} />
          </div>
        )}
        {containerTypes.length > 0 && shippingLines.length > 0 && (
          <div className={classes.table}>
            <Paper className={classes.paper}>
              <div className={classnames(classes.tableOver, STICKY_IE_CLASS)}>
                <ShiftPicker />
              </div>
              <AvailabilityTable
                shippingLines={shippingLines}
                containerSize={containerTypes}
                stickySpace
                disableClickEmpty
                // noFilter={true}
              />
            </Paper>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  table: {
    margin: 'auto',
    marginTop: theme.spacing.unit * 5,
    textAlign: 'center',
    marginBottom: theme.spacing.unit * 5,
  },
  loaderWrapper: {
    textAlign: 'center',
    marginTop: 5 * theme.spacing.unit,
    marginBottom: 5 * theme.spacing.unit,
  },
  tableOver: {
    top: -55,
    width: '100%',
    marginLeft: theme.spacing.unit,
    position: 'absolute',
  },
  paper: {
    marginTop: 55,
    position: 'relative',
    display: 'inline-block',
    maxWidth: '100%',
  },
  containerType: {
    width: 780,
    margin: 'auto',
  },
});

const mapStateToProps = state => ({
  terminals: state.erlData.ports,
  mapping: state.availabilities.mappingShippingLine,
});

const mapDispatchToProps = dispatch => ({
  setShippingLinesFiltersAndReduceTerminals: shippingLines =>
    dispatch(setShippingLinesFiltersAndReduceTerminals(shippingLines)),
  clearFilters: () => dispatch(clearFilters()),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Table);
