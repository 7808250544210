import React from 'react';
import Form from './components/Form';
import theme from 'theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

const NotificationSettings = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="flex flex-col space-y-4">
        <h3 className="font-bold text-dark text-center">
          Set the preferences for notifications you want to receive from us:
        </h3>
        <Form />
      </div>
    </MuiThemeProvider>
  );
};

export default NotificationSettings;
