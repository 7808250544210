import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Stickyfill from 'stickyfilljs';
import { STICKY_IE_CLASS } from 'constants/layout';
import FirstAppointmentHeader from 'components/FirstAppointmentHeader';
import TerminalHeader from 'components/TerminalHeader';
import LastUpdateHeader from 'components/LastUpdateHeader';
import GateStatusHeader from 'components/GateStatusHeader';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isLogged } from 'ducks/user';
import { computeTableWidth } from 'services/helpers/layout';
import { useUserContext } from 'common/contexts/UserContext';
import _ from 'lodash';
export class TableHeader extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    displayShift: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    terminals: PropTypes.object.isRequired,
    lrdData: PropTypes.array.isRequired,
    refreshAvailabilities: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    canAccessFirstAppointment: PropTypes.bool.isRequired,
  };
  static defaultProps = {
    canAccessFirstAppointment: false,
  };

  componentDidMount() {
    var elements = document.querySelectorAll(`.${STICKY_IE_CLASS}`);
    Stickyfill.add(elements);
  }

  render() {
    const {
      user,
      displayShift,
      classes,
      terminals,
      lrdData,
      isLoggedIn,
      canAccessFirstAppointment,
    } = this.props;
    const terminalsList = Object.values(terminals).flat();

    return (
      <div className={classnames(classes.root, STICKY_IE_CLASS)}>
        <div
          id="availability-table-header"
          style={{
            width: computeTableWidth(displayShift, terminalsList).width,
          }}
          className={classes.header}
        >
          <TerminalHeader
            user={user}
            terminals={terminalsList}
            displayShift={displayShift}
          />

          {isLoggedIn && canAccessFirstAppointment && (
            <FirstAppointmentHeader
              terminals={terminalsList}
              displayShift={displayShift}
            />
          )}
          <GateStatusHeader
            terminals={terminalsList}
            displayShift={displayShift}
          />
          <LastUpdateHeader
            terminals={terminalsList}
            displayShift={displayShift}
            lrdData={lrdData}
          />
        </div>
      </div>
    );
  }
}
const styles = theme => ({
  root: {
    position: 'sticky',
    top: '64px',
    zIndex: 1,
    backgroundColor: '#fff',
    overflowX: 'hidden',
  },
  header: {
    zIndex: 1,
    overflow: 'hidden',
  },
});

const mapStateToProps = state => ({
  isLoggedIn: isLogged(state),
});

const withHooksHOC = Component => {
  return props => {
    const { user } = useUserContext();
    const canAccessFirstAppointment = _.get(
      user,
      'permissions.access_first_appointment'
    );
    return (
      <Component
        {...props}
        user={user}
        canAccessFirstAppointment={canAccessFirstAppointment}
      />
    );
  };
};

export default compose(
  withHooksHOC,
  connect(mapStateToProps),
  withStyles(styles)
)(TableHeader);
