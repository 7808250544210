import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';

const ListInfoItem = ({ classes, primary, secondary }) => {
  return (
    <div className={classes.infoItem}>
      <Avatar className={classes.infoItemAvatar}>
        <CheckIcon />
      </Avatar>
      <div>
        <Typography variant="h3" className={classes.infoTitle} gutterBottom>
          {primary}
        </Typography>
        <Typography variant="caption" gutterBottom>
          {secondary}
        </Typography>
      </div>
    </div>
  );
};

ListInfoItem.propTypes = {
  classes: PropTypes.object.isRequired,
  primary: PropTypes.any,
  secondary: PropTypes.any,
};

const styles = theme => ({
  infoTitle: {
    fontWeight: 'bold',
  },
  infoItem: {
    marginBottom: 2 * theme.spacing.unit,
    display: 'flex',
  },
  infoItemAvatar: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: 2 * theme.spacing.unit,
    marginRight: 2 * theme.spacing.unit,
    width: 25,
    height: 25,
  },
});

export default withStyles(styles)(ListInfoItem);
