import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SnapshotPreview from './SnapshotPreview';
import Download from './Download';
import { formatDateTimeLaTz } from 'services/helpers/format-date';

const TabContent = ({ snapshotUrl, terminal, classes }) => {
  if (!snapshotUrl) return <div className={classes.noData}>No data</div>;
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <img src={terminal.logo} alt={terminal.name} className={classes.logo} />
        <Typography className={classes.snapshotdDate}>
          {snapshotUrl.time
            ? `${formatDateTimeLaTz(
                snapshotUrl.date,
                snapshotUrl.time
              )}: terminal instructions`
            : 'Not available'}
        </Typography>
      </div>
      <div className={classes.right}>
        <div className={classes.snapshotWrapper}>
          <SnapshotPreview url={snapshotUrl.url} />
        </div>
        {snapshotUrl.url && <Download snapshotKey={snapshotUrl.key} />}
      </div>
    </div>
  );
};

TabContent.propTypes = {
  snapshotUrl: PropTypes.object.isRequired,
  terminal: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    height: 625,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
    border: '1px solid #D5D8DF',
    overflow: 'hidden',
    marginBottom: 50,
  },
  logo: {
    marginTop: 13 * theme.spacing.unit,
    marginLeft: 8 * theme.spacing.unit,
    height: 35,
  },
  snapshotdDate: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginLeft: 10 * theme.spacing.unit,
    marginTop: 5 * theme.spacing.unit,
  },
  snapshotWrapper: {
    width: 340,
    height: 255,
    marginTop: 13 * theme.spacing.unit,
  },
  left: {
    flex: 1,
    backgroundColor: 'white',
  },
  right: {
    flex: 1,
    backgroundColor: '#F4F5F6',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
});

export default withStyles(styles)(TabContent);
