import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from 'components/customDesign/Button';
import { Link } from 'react-router-dom';
import erlClient from 'services/clients';
import ListInfoItem from 'components/customDesign/ListInfoItem';

const Needvalidation = props => {
  const { classes, scac } = props;
  const [emails, setEmails] = useState(null);
  const [APICalled, setAPICalled] = useState(false);
  useEffect(() => {
    !APICalled &&
      erlClient(`core/api/v1/auth/company/${scac}/admins`)
        .then(res => {
          setAPICalled(true);
          setEmails(res.data);
        })
        .catch(console.error);
  });

  return (
    <div className={classes.root}>
      <Typography variant="caption" align="center" className={classes.subtitle}>
        Join a company
      </Typography>
      {/* <TeamIcon className={classes.logo} /> */}
      <div className={classes.detail}>
        <ListInfoItem
          primary="Your company is already a client"
          secondary="We've asked your team to grant you membership"
        />
        <ListInfoItem
          primary="Wait for approval"
          secondary="You will automatically have access to the platform once you're approved"
        />
        <ListInfoItem
          primary="In a hurry?"
          secondary={
            emails && (
              <span>
                Ask in person {emails.join(', ')} to accept your request!
              </span>
            )
          }
        />
      </div>
      <Button
        component={Link}
        to="/welcome?company=true"
        classes={{ root: classes.button }}
      >
        Ask to join the company
      </Button>
      <div>
        <Typography
          align="center"
          component="a"
          href="mailto:support@bluecargo.io"
          className={classes.link}
        >
          Contact us
        </Typography>
      </div>
    </div>
  );
};

Needvalidation.propTypes = {
  classes: PropTypes.object.isRequired,
  scac: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    paddingRight: 2 * theme.spacing.unit,
    paddingLeft: 2 * theme.spacing.unit,
    textAlign: 'center',
  },
  button: {
    marginTop: 2 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    width: 250,
  },
  logo: {
    marginTop: 5 * theme.spacing.unit,
    marginBottom: 5 * theme.spacing.unit,
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.grey.link,
    fontSize: 12,
    marginBottom: 2 * theme.spacing.unit,
  },
  detail: {
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    textAlign: 'left',
    paddingLeft: 2 * theme.spacing.unit,
  },
});

export default withStyles(styles)(Needvalidation);
