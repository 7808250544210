import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { displayOnlyShift } from 'services/helpers/shift';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import cn from 'classnames';
import SnapshotPreview from './SnapshotPreview';
import Download from './Download';
import { ReactComponent as PictureIcon } from 'assets/icons/picture.svg';
import orderBy from 'lodash/orderBy';
import { formatDateTimeLaTz } from 'services/helpers/format-date';

const PerDiemWording = ({
  perDiem,
  shippingLines,
  containerTypes,
  classes,
}) => {
  const availabilityMapping = {
    yes: 'accepts',
    no: 'no longer accepts',
    dual: 'only accepts dual transaction for',
    na: 'acceptance is unknown for',
    iksu: 'only accepts IKSU for',
  };
  if (!perDiem.new_last_update) return 'Not available';
  const containerType = containerTypes.find(
    ct => ct.key === perDiem.container_type
  ).name;
  const shippingLine = shippingLines.find(
    sl => sl.key === perDiem.shipping_line
  ).name;
  return (
    <React.Fragment>
      <b>{formatDateTimeLaTz(perDiem.new_last_update)}</b>
      {': '}
      <span
        className={cn({
          [classes.red]: perDiem.new_availability === 'no',
          [classes.green]: perDiem.new_availability === 'yes',
          [classes.yellow]: perDiem.new_availability === 'dual',
        })}
      >
        {availabilityMapping[perDiem.new_availability]}
      </span>
      {` ${containerType} - ${shippingLine} on ${moment(perDiem.date).format(
        'MM/DD/YYYY'
      )} ${displayOnlyShift(perDiem.shift)}`}
    </React.Fragment>
  );
};
PerDiemWording.propTypes = {
  perDiem: PropTypes.object.isRequired,
  terminals: PropTypes.array.isRequired,
  shippingLines: PropTypes.array.isRequired,
  containerTypes: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

const wordingStyles = theme => ({
  green: {
    color: theme.palette.green.main,
  },
  red: {
    color: theme.palette.alert.main,
  },
  yellow: {
    color: theme.palette.secondary.main,
  },
});
const ConnectedPerDiemWording = withStyles(wordingStyles)(PerDiemWording);

const TabContent = ({
  perDiem,
  terminal,
  classes,
  snapshotUrl,
  shippingLines,
  containerTypes,
}) => {
  if (!perDiem) return <div className={classes.noData}>No data</div>;

  if (perDiem.length === 0) return null;
  const orderedPerDiem = orderBy(perDiem, 'new_last_update', 'desc');
  const [selected, setSelected] = useState(orderedPerDiem[0].key);
  const snapshot =
    snapshotUrl.key === selected
      ? snapshotUrl
      : orderedPerDiem.find(pd => pd.key === selected).snapshot;
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <img src={terminal.logo} alt={terminal.name} className={classes.logo} />
        <div className={classes.list}>
          {orderedPerDiem.map(pd => (
            <div
              className={classes.item}
              key={pd.key}
              onClick={() => setSelected(pd.key)}
            >
              <div
                className={cn({
                  [classes.selectedBullet]: pd.key === selected,
                  [classes.bullet]: pd.key !== selected,
                })}
              />
              <Typography
                className={cn(classes.snapshotdDate, {
                  [classes.selected]: pd.key === selected,
                })}
              >
                <PictureIcon style={{ marginRight: 10 }} />
                <ConnectedPerDiemWording
                  shippingLines={shippingLines}
                  containerTypes={containerTypes}
                  perDiem={pd}
                />
              </Typography>
            </div>
          ))}
          {snapshotUrl && (
            <div
              className={classes.item}
              onClick={() => setSelected(snapshotUrl.key)}
            >
              <div
                className={cn({
                  [classes.selectedBullet]: snapshotUrl.key === selected,
                  [classes.bullet]: snapshotUrl.key !== selected,
                })}
              />
              <Typography
                className={cn(classes.snapshotdDate, {
                  [classes.selected]: snapshotUrl.key === selected,
                })}
              >
                {snapshotUrl.time
                  ? `${formatDateTimeLaTz(
                      snapshotUrl.date,
                      snapshotUrl.time
                    )}: terminal instructions`
                  : 'Not available'}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className={classes.right}>
        <div className={classes.snapshotWrapper}>
          <SnapshotPreview key={selected} url={snapshot.url} />
        </div>
        {snapshot.url && <Download snapshotKey={snapshot.key} />}
      </div>
    </div>
  );
};

TabContent.propTypes = {
  perDiem: PropTypes.array,
  terminal: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  snapshotUrl: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    minHeight: 625,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
    border: '1px solid #D5D8DF',
    overflow: 'hidden',
    marginBottom: 50,
  },
  item: {
    position: 'relative',
  },
  selectedBullet: {
    height: 20,
    width: 20,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    position: 'absolute',
    left: -10,
  },
  bullet: {
    height: 10,
    width: 10,
    backgroundColor: 'white',
    borderRadius: 5,
    border: `1px solid #8E98A4`,
    position: 'absolute',
    left: -5,
    top: 5,
  },
  logo: {
    marginTop: 13 * theme.spacing.unit,
    marginLeft: 8 * theme.spacing.unit,
    height: 35,
  },
  list: {
    marginLeft: 10 * theme.spacing.unit,
    borderLeft: `1px solid #8E98A4`,
  },
  selected: {
    fontWeight: 'bold',
    opacity: `1 !important`,
  },
  snapshotdDate: {
    cursor: 'pointer',
    fontSize: 14,
    color: theme.palette.primary.main,
    opacity: 0.5,
    marginLeft: 3 * theme.spacing.unit,
    marginTop: 3 * theme.spacing.unit,
    '&:hover': {
      opacity: 0.7,
    },
  },
  snapshotWrapper: {
    width: 340,
    height: 255,
    marginTop: 13 * theme.spacing.unit,
  },
  left: {
    flex: 1,
    backgroundColor: 'white',
    paddingBottom: 50,
  },
  right: {
    flex: 1,
    backgroundColor: '#F4F5F6',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
});

export default withStyles(styles)(TabContent);
