import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import queryString from 'query-string';
import { pageTitlesAndUrls } from 'constants/pages';

const initialValues = { shippingLine: '', containerType: '' };
export default class Form extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    shippingLines: PropTypes.array.isRequired,
    containerTypes: PropTypes.array.isRequired,
    importData: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    initialValues: PropTypes.object.isRequired,
  };
  static defaultProps = {
    initialValues,
  };

  componentDidMount = () => this.props.importData();
  validate = values => {
    let errors = {};
    if (!values.shippingLine) errors.shippingLine = 'Required';
    if (!values.containerType) errors.containerType = 'Required';
    return errors;
  };

  submit = (values, { setSubmitting }) => {
    const query = {
      'shipping-line': values.shippingLine,
      'container-type': values.containerType,
    };
    this.props.navigate({
      pathname: pageTitlesAndUrls.emptyReturnLocations.path,
      search: queryString.stringify(query),
    });
  };

  render() {
    const { shippingLines, containerTypes, classes } = this.props;
    return (
      <Formik
        initialValues={this.props.initialValues}
        validate={this.validate}
        onSubmit={this.submit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          isValid,
        }) => {
          !isSubmitting && !isValidating && isValid && handleSubmit();
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FormControl className={classes.formControl}>
                <Select
                  native
                  value={values.shippingLine}
                  onChange={handleChange}
                  displayEmpty
                  name="shippingLine"
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  <option value="" disabled>
                    Shipping line
                  </option>
                  {shippingLines.map(sl => (
                    <option key={sl.name} value={sl.name}>
                      {sl.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <Select
                  native
                  value={values.containerType}
                  onChange={handleChange}
                  displayEmpty
                  name="containerType"
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  <option value="" disabled>
                    Container Type
                  </option>
                  {containerTypes.map(ct => (
                    <option key={ct.name} value={ct.name}>
                      {ct.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </form>
          );
        }}
      </Formik>
    );
  }
}
