export default theme => ({
  submit: {
    marginTop: 3 * theme.spacing.unit,
  },
  form: {
    width: 280,
  },
  buttonRoot: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  buttonLabel: {
    fontSize: 12,
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    height: '85%',
    justifyContent: 'center',
  },
});
