import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import CssBaseline from '@material-ui/core/CssBaseline';

import ErrorSnackbar from './components/ErrorSnackbar';
import NeedPayementSnackbar from './components/NeedPayementSnackbar';
import CookieBanner from './components/CookieBanner';
import AppRoutes from './AppRoutes';
export const history = createBrowserHistory();

const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
      <div className="App">
        <CssBaseline />

        <AppRoutes />

        <ErrorSnackbar />
        <NeedPayementSnackbar />
        <CookieBanner />
      </div>
    </BrowserRouter>
  );
};

export default App;
