import _ from 'lodash';
export const ALERT_DAYS_OUT = 3;
export const OPEN_GATE_STATUS = 'open';
export const INSTRUCTIONS_UNAVAILABLE_ID = 'no';
export const PICKUP_HOLD_KEYS = [
  { key: 'customs_status', name: 'Customs' },
  { key: 'freight_status', name: 'Freight' },
  { key: 'shipping_line_status', name: 'Line' },
  { key: 'tmf_status', name: 'TMF' },
  { key: 'terminal_status', name: 'Terminal' },
  { key: 'holds_status', name: 'Other holds' },
];

export const IMPORT_STATUS_ENUM = {
  VESSEL: 'vessel',
  YARD: 'yard',
  DEPARTED: 'departed',
  RAIL: 'rail',
  UNKNOWN: 'unknown',
};

export const CONTAINER_STATUS_ENUM = {
  TERMINATED: 'terminated',
  GATED_OUT: 'gated_out',
  ON_VESSEL: 'on_vessel',
  IMPORT: 'import',
  PENDING: 'pending',
  LOADING: 'loading',
};

export const PICKUP_STATUS_ENUM = {
  UNKNOWN: 'unknown',
  CLEARED: 'cleared',
  HOLD: 'hold',
};

export const APPOINTMENT_STATUS_ENUM = {
  SCHEDULED: 'scheduled',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  OTHER: 'other',
  RESCHEDULED: 'rescheduled',
};

export const REFRESH_INTERVAL_MS = 30 * 1000;
export const REFRESH_APPOINTMENTS_INTERVAL_MS = 60 * 1000;
export const DEFAULT_PAGE_SIZE = 25;
// Be careful when updating this variable, we currently store the container numbers in the URL
export const CONTAINER_SEARCH_LIMIT = 100;
export const DROPDOWN_FILTERS = [
  {
    id: 'on-vessel',
    tag: 'tag-on-vessel',
    name: 'On Vessel',
    searchParams: {
      importStatus: ['vessel'],
    },
  },
  {
    id: 'import',
    tag: 'tag-import',
    name: 'Import',
    searchParams: {
      importStatus: ['yard', 'rail', 'unknown'],
    },
  },
  {
    id: 'gated-out',
    tag: 'tag-gated-out',
    name: 'Gated Out',
    searchParams: {
      containerStatus: ['gated_out'],
    },
  },
  {
    id: 'terminated',
    tag: 'tag-default',
    name: 'Terminated',
    searchParams: {
      containerStatus: ['terminated'],
    },
  },
];
export const DEFAULT_HOUR_MINUTE_DISPLAY = 'HH:mm';
export const DATE_DISPLAY_FORMAT = 'll';
export const CONTAINER_CELL_WIDTH = 180;
const PREVIEW_COMPANY_SCACS = ['BULK', 'ADXH'];

export function getReleaseBookedAppointments(user) {
  const scac = _.get(user, 'company.scac');
  return (
    process.env.REACT_APP_ENV !== 'production' ||
    PREVIEW_COMPANY_SCACS.includes(scac)
  );
}

// TODO: enable or remove this code path depending on Product's
// decision of Container Detail display.
export const ENABLE_CONTAINER_DETAIL_STEPPER = false;
