import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import {
  CONTAINER_STATUS_ENUM,
  DEFAULT_HOUR_MINUTE_DISPLAY,
  DATE_DISPLAY_FORMAT,
} from '../container-config';
import { ReactComponent as APM } from '../../../assets/terminal-icons/APM.svg';
import { ReactComponent as EVERPORT } from '../../../assets/terminal-icons/EVERPORT.svg';
import { ReactComponent as FMS } from '../../../assets/terminal-icons/FMS.svg';
import { ReactComponent as ITS } from '../../../assets/terminal-icons/ITS.svg';
import { ReactComponent as LBCT } from '../../../assets/terminal-icons/LBCT.svg';
import { ReactComponent as MATSON } from '../../../assets/terminal-icons/MATSON.svg';
import { ReactComponent as PTSC } from '../../../assets/terminal-icons/PTSC.svg';
import { ReactComponent as SSA } from '../../../assets/terminal-icons/SSA.svg';
import { ReactComponent as TRAPAC } from '../../../assets/terminal-icons/TRAPAC.svg';
import { ReactComponent as TTI } from '../../../assets/terminal-icons/TTI.svg';
import { ReactComponent as WBCT } from '../../../assets/terminal-icons/WBCT.svg';
import { ReactComponent as YTI } from '../../../assets/terminal-icons/YTI.svg';
import LaunchIcon from '@mui/icons-material/Launch';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import pluralize from 'pluralize';
import _ from 'lodash';
import { SelectedContainerContext } from '../ContainerDetail/SelectedContainerContextProvider';
import { Link } from 'react-router-dom';
import { pageTitlesAndUrls } from 'constants/pages';
import { useParams } from 'react-router-dom';
import ContainerStatusTag from '../components/ContainerStatusTag';
import { useUserContext } from 'common/contexts/UserContext';

export const getContainer = params => {
  const urlParams = useParams();
  const {
    user: {
      permissions: { accessPickupTrackChanges },
    },
  } = useUserContext();
  const [, setSelectedContainer] = useContext(SelectedContainerContext);
  const {
    containerNumber,
    shippingLine,
    status,
    size,
    pickupImportStatus,
  } = params.row;

  return (
    <div
      className={`leading-none flex w-full h-full items-start py-4 px-2 ${
        urlParams.containerNumber === containerNumber
          ? 'border border-blue'
          : urlParams.containerNumber && 'opacity-40'
      }`}
    >
      <div className="flex flex-col space-y-4 items-start">
        <div className="flex flex-col space-y-2 text-dark uppercase font-semibold tracking-wide">
          {accessPickupTrackChanges ? (
            <h5>
              <Link
                to={`${pageTitlesAndUrls.containerTracking.path}/${containerNumber}`}
                className={`cursor-pointer ${
                  urlParams.containerNumber === containerNumber
                    ? 'text-blue font-bold'
                    : ''
                }`}
                onClick={event => {
                  if (urlParams.containerNumber === containerNumber) return;
                  setSelectedContainer({
                    watchListRow: params.row,
                    return: window.location.search,
                  });
                }}
              >
                {containerNumber}
              </Link>
            </h5>
          ) : (
            <h5>{containerNumber}</h5>
          )}
          <h6>
            {shippingLine} {shippingLine ? '· ' : ''} {size}
          </h6>
        </div>
        <ContainerStatusTag
          status={status}
          pickupImportStatus={pickupImportStatus}
        />
      </div>
    </div>
  );
};

export const getReturn = (params, openAllAppointmentsModal) => {
  const {
    gatedInDate,
    shippingLine,
    size,
    gatedInTerminalName,
    status,
    containerNumber,
    pickupImportStatus,
    appointments,
  } = params.row;

  if (gatedInDate) {
    return (
      <div className="leading-none flex h-full items-start py-4 px-2">
        <div className="flex flex-col space-y-2">
          <h5 className="text-gray-200 font-semibold">
            {gateTransactionText(gatedInTerminalName, gatedInDate)}
          </h5>
          {gatedInDate && (
            <h6 className="uppercase font-medium text-gray-300">returned</h6>
          )}
        </div>
      </div>
    );
  }

  if (
    !shippingLine ||
    !size ||
    status === CONTAINER_STATUS_ENUM.IMPORT ||
    status === CONTAINER_STATUS_ENUM.ON_VESSEL
  )
    return (
      <div className="leading-none flex h-full items-start py-4 px-2">-</div>
    );

  if (appointments.length > 0) {
    const firstAppointment = _.head(appointments);
    return (
      <div className="leading-none flex h-full items-start py-4 px-2">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center underline text-blue space-x-2">
            <a
              target="_blank"
              href={firstAppointment.terminalAppointmentUrl}
              rel="noreferrer"
            >
              <div className="flex space-x-1 items-center">
                <LaunchIcon style={{ fontSize: 14 }} />
                <h5 className="font-semibold">
                  {' '}
                  {firstAppointment.terminalName}{' '}
                  <DisplayTimeRange
                    startDateTime={firstAppointment.periodFromDate}
                    endDateTime={firstAppointment.periodToDate}
                  />{' '}
                  {firstAppointment.slots ? `(${firstAppointment.slots})` : ''}
                </h5>
              </div>
            </a>
            {appointments.length > 1 && (
              <AddCircleIcon
                style={{ fontSize: 14 }}
                onClick={e =>
                  openAllAppointmentsModal(
                    e,
                    size,
                    status,
                    shippingLine,
                    containerNumber,
                    pickupImportStatus,
                    appointments
                  )
                }
                className="cursor-pointer"
              />
            )}
          </div>

          <div className="flex relative bottom-0.5">
            {gatedInDate ? (
              <h6 className="uppercase font-medium text-gray-300">returned</h6>
            ) : (
              <h6 className="uppercase font-medium text-gray-300">
                first available &nbsp;
              </h6>
            )}
            {firstAppointment.availabilityId === null ? (
              <h6 className="uppercase font-medium text-gray-300">
                · Awaiting terminal instructions &nbsp;
              </h6>
            ) : (
              <div className="flex">
                {firstAppointment.availabilityId === 'dual' && (
                  <div className="flex items-center">
                    <h6 className="uppercase font-medium text-gray-300">
                      · dual only &nbsp;
                    </h6>
                  </div>
                )}
                <h6 className="uppercase font-medium text-gray-300">
                  {showChassisRestrictions(
                    firstAppointment.chassisRestrictions
                  )}
                </h6>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="leading-none flex h-full items-start py-4 px-2">
      <div className="flex flex-col space-y-2">
        <h5>-</h5>
        <h6 className="uppercase text-red font-medium">
          No empty available appointment
        </h6>
      </div>
    </div>
  );
};

export const getDetention = (params, onGetPerDiemReport, currentUser) => {
  const { days, alert, containerNumber, isPerDiemGenerating } = params.row;
  const hasPerDiemAccess = _.get(currentUser, 'permissions.access_per_diem');

  const openModal = async e => {
    e.preventDefault();
    e.stopPropagation();
    onGetPerDiemReport(containerNumber);
  };

  return (
    <div className="leading-none flex h-full items-start py-4 px-2">
      <div className="flex flex-col items-start space-y-2">
        <div className="flex items-center space-x-2">
          <h5 className="text-gray-200 font-bold">
            {days > 0
              ? days + ` ${pluralize('day', days)}`
              : days === 0
              ? '< 1 day'
              : '-'}
          </h5>
          {alert && (
            <h6 className="px-1 py-0.5 rounded-sm text-red bg-red-light">
              Per Diem
            </h6>
          )}
        </div>
        {hasPerDiemAccess && alert && (
          <>
            <Button
              variant="per-diem"
              disabled={isPerDiemGenerating}
              onClick={openModal}
            >
              {isPerDiemGenerating ? 'Generating' : 'Get Per Diem Report'}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export const getCurrentOpenings = params => {
  const { status, currentOpenings, gatedInDate } = params.row;

  if (
    currentOpenings.length === 0 ||
    gatedInDate ||
    status === CONTAINER_STATUS_ENUM.IMPORT ||
    status === CONTAINER_STATUS_ENUM.ON_VESSEL
  ) {
    return (
      <div className="w-full leading-none flex h-full items-start py-4 px-2">
        -
      </div>
    );
  }

  return (
    <div className="w-full leading-none flex h-full items-start py-4 px-2">
      <div className="w-full flex space-x-6">
        {currentOpenings.map(opening => {
          return (
            <div key={opening.terminalId} className="flex flex-col space-y-2">
              <div className="flex space-x-2 items-center">
                <div className="w-4 h-4">{mappingTerminalIcon(opening)}</div>
                <h5 className="text-gray-200 font-semibold">
                  {opening.terminalName}
                </h5>
              </div>
              <div className="flex items-center">
                {opening.availabilityId === 'dual' && (
                  <h6 className="uppercase font-medium text-gray-300">
                    dual &nbsp;
                    <span
                      className={`${!opening.chassisRestrictions.length > 0 &&
                        'hidden'}`}
                    >
                      ·
                    </span>
                  </h6>
                )}
                {opening.chassisRestrictions.map((restriction, index) => {
                  return (
                    <div key={index} className="flex items-center">
                      <h6 className="uppercase font-medium text-gray-300">
                        &nbsp;{restriction} &nbsp;
                        <span
                          className={`${index === restriction.length - 1 &&
                            'hidden'}`}
                        >
                          · &nbsp;
                        </span>
                      </h6>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export function gateTransactionText(terminalName, date) {
  if (!date) return '-';
  return `${terminalName} · ${date.format(DATE_DISPLAY_FORMAT)} · ${date.format(
    'HH:mm'
  )}`;
}

export function showChassisRestrictions(chassisRestrictions) {
  if (chassisRestrictions.length === 0) return;
  if (chassisRestrictions.length > 2) return;
  return `· ${chassisRestrictions.join('/')} CHASSIS ONLY`;
}

const mappingTerminalIcon = opening => {
  const { terminalId } = opening;
  switch (terminalId) {
    case 'apm':
      return <APM className="h-4 w-4" />;
    case 'everport':
      return <EVERPORT className="h-4 w-4" />;
    case 'fms':
      return <FMS className="h-4 w-4" />;
    case 'its':
      return <ITS className="h-4 w-4" />;
    case 'lbct':
      return <LBCT className="h-4 w-4" />;
    case 'matson':
      return <MATSON className="h-4 w-4" />;
    case 'ptsc':
      return <PTSC className="h-4 w-4" />;
    case 'ssa_pct':
      return <SSA className="h-4 w-4" />;
    case 'ssa_piera':
      return <SSA className="h-4 w-4" />;
    case 'trapac':
      return <TRAPAC className="h-4 w-4" />;
    case 'tti':
      return <TTI className="h-4 w-4" />;
    case 'wbct':
      return <WBCT className="h-4 w-4" />;
    case 'yti':
      return <YTI className="h-4 w-4" />;
    default:
      return;
  }
};

export function DisplayTimeRange({ startDateTime, endDateTime }) {
  if (!startDateTime) return null;
  const startDate = startDateTime.format(DATE_DISPLAY_FORMAT);

  const startTime = startDateTime.format(DEFAULT_HOUR_MINUTE_DISPLAY);

  const startDateTimeDisplay = ` · ${startDate} · ${startTime}`;

  if (!endDateTime) {
    return <>startDateTimeDisplay</>;
  }

  const endTime = endDateTime.format(DEFAULT_HOUR_MINUTE_DISPLAY);

  return (
    <>
      {startDateTimeDisplay}-{endTime}
    </>
  );
}
