export const dateFormat = {
  input: 'YYYY-MM-DD',
  outputDayOfWeek: 'ddd',
  outputMonthDay: 'MM/DD',
  startEndOfWeek: 'Do MMM',
};

export const lastUpdateDateFormat = {
  input: 'YYYY-MM-DD HH:mm',
  output: 'dddd Do MMMM HH:mm',
};

export const tableIds = {
  currentWeek: 'gateScheduleTableCurrent',
  nextWeek: 'gateScheduleTable',
};
