import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../UserAvatar';
import EditIcon from '@material-ui/icons/Edit';
import PendingInvitationDialog from './PendingInvitationDialog';
import fonts from 'styles/typography.module.css';

const PendingMember = ({ member, classes, refreshPending }) => {
  const [displayIcon, setDisplayIcon] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const close = () => {
    setDialogOpen(false);
    refreshPending();
  };
  const open = () => setDialogOpen(true);
  return (
    <div className={fonts.font}>
      <div
        onMouseEnter={() => setDisplayIcon(true)}
        onMouseLeave={() => setDisplayIcon(false)}
        onClick={!isDialogOpen ? open : null}
        key={member.email}
        className="p-4 flex w-full justify-between items-center hover:bg-blue-600"
      >
        <div className="flex space-x-4 items-center">
          <UserAvatar size="small" letters={member[0].toUpperCase()} />
          <div className="flex flex-col space-y-1">
            <h4 className="text-dark">{member}</h4>
            <h5 className="uppercase text-orange font-bold">
              Pending Invitation
            </h5>
          </div>
        </div>
        {displayIcon && (
          <EditIcon style={{ fontSize: '14px' }} className="cursor-pointer" />
        )}
      </div>
      <PendingInvitationDialog
        isOpen={isDialogOpen}
        close={close}
        member={member}
      />
    </div>
  );
};
PendingMember.propTypes = {
  member: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  refreshPending: PropTypes.func.isRequired,
};

export default PendingMember;
