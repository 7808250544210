import erlClient from './';
import { laTimezone } from '../helpers/data';
import { DeviceUtil } from 'utils/device.util';
import FileSaver from 'file-saver';
const DEFAULT_TIMEOUT_MS = 42 * 1000;

export const downloadDailyReportPdfEnd = async requestBody => {
  const res = await erlClient.post(
    'core/api/v1/erl/snapshots_in_the_past',
    requestBody,
    {
      responseType: 'blob',
      timeout: DEFAULT_TIMEOUT_MS,
    }
  );
  return res;
};

async function downloadPerDiemReport(params) {
  const containersInput = params.container_numbers.join(',');
  const resp = await downloadDailyReportPdfEnd({
    date: params.start_date,
    email: true,
    container_size: params.container_type,
    shipping_line: params.shipping_line,
    emails: params.emails,
    download: params.download,
    containersInput,
    time: params.time,
    only_empty_appointments: !params.include_terminal_screenshots,
    metaData: {
      dateTime: laTimezone(
        `${params.start_date} ${params.time}`,
        'YYYY-MM-DD hh:mm'
      ).format('YYYY-MM-DD hh:mm A'),
      size: params.container_type,
      containerNumber: containersInput,
      shippingLine: params.shipping_line,
    },
  });
  if (params.download) {
    const { data } = resp || {};

    const fileName = `${params.start_date} - ${params.container_type} - ${params.shipping_line} - Per Diem Daily Report`;
    const isIE = DeviceUtil.isIE();
    if (isIE) {
      const fileNameIE = `${fileName}.pdf`;
      const textFileAsBlob = new Blob([data], { type: 'application/pdf' });
      window.navigator.msSaveBlob(textFileAsBlob, fileNameIE);
    } else {
      await FileSaver(data, fileName);
    }
  }
  return resp;
}

export const generatePerDiemReport = async params => {
  const config = { timeout: DEFAULT_TIMEOUT_MS };
  if (params.time && !params.end_date) {
    return downloadPerDiemReport(params);
  }
  const res = await erlClient.post(
    'core/api/v1/erl/per_diem_report_range',
    params,
    config
  );
  return res;
};

export const downloadEaScreenshot = async requestBody => {
  const res = await erlClient.post(
    'core/api/v1/erl/screenshots_to_pdf',
    requestBody,
    {
      responseType: 'blob',
      timeout: DEFAULT_TIMEOUT_MS,
    }
  );
  return res;
};

export const sendScreenshotViaEmailEnd = async requestBody => {
  await erlClient.post('core/api/v1/erl/send_pdf_via_email', requestBody);
};

export const downloadSnapshot = async key => {
  const res = await erlClient.post('core/api/v1/erl/snapshot_pdf', key, {
    responseType: 'blob',
    timeout: DEFAULT_TIMEOUT_MS,
  });
  if (res.status !== 200) throw res.data;

  return res.data;
};

export const getSnapshotUrlsBySL = async (
  shippingLine,
  date,
  time,
  cb,
  end_date
) => {
  try {
    const res = await erlClient.get(
      `core/api/v1/erl/snapshot_urls_by_sl?shipping_line=${shippingLine}&date=${date}&time=${time}${
        end_date ? `&end_date=${end_date}` : ''
      }`
    );
    return res.data;
  } catch (error) {
    if (cb) {
      cb(error);
    }
    console.error(error);
    return [];
  }
};

export const getTimeRangePerDiem = async values => {
  const res = await erlClient.post('/core/api/v1/perdiem/timerange', values);
  if (res.status !== 200) throw res.data;

  return res.data;
};

export const getDailyReportPreviewEnd = async requestBody => {
  const res = await erlClient.post(
    'core/api/v1/erl/ea_table_image',
    requestBody
  );
  return res.data;
};

export const validateContainersEnd = async requestBody => {
  const res = await erlClient.post(
    'core/api/v1/containers/parse-container-numbers',
    requestBody
  );
  return res.data;
};

export const getSnapshotUrlsByShippingLine = async (
  shipping_line,
  date,
  time,
  end_date
) => {
  const res = await erlClient.get(`core/api/v1/erl/snapshot_urls_by_sl`, {
    params: {
      shipping_line,
      date,
      time,
      end_date,
    },
  });
  return res.data;
};
