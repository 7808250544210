import React, { createContext, useState, useContext, useEffect } from 'react';
import { CredentialsService } from 'pages/Settings/Credentials/services/credentials.service';
import { getWithTokenRefresh } from 'services/clients/axios-with-token';
import _ from 'lodash';

const UserContext = createContext<
  { user: any; isLoading: boolean; fetchUser: Function } | undefined
>(undefined);

const getContainerCreditsLimitReached = (profile: any) => {
  const containerCreditsLimit = _.get(
    profile,
    'company.container_credits_limit'
  );
  const containerCreditsUsed = _.get(profile, 'company.container_credits_used');
  if (_.isNil(containerCreditsLimit)) {
    return false;
  }
  return containerCreditsUsed >= containerCreditsLimit;
};

function UserProvider({ children }: any) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUser = async () => {
    const profile = await getWithTokenRefresh('/core/api/v1/auth/profile');

    let credentials = [];
    let allCredentialsEnteredAndValid = true;
    try {
      credentials = await getWithTokenRefresh(
        '/core/api/v1/appointments/credentials'
      );
      allCredentialsEnteredAndValid = CredentialsService.checkAllCredentialsEnteredAndValid(
        credentials
      );
    } catch {}

    let teamMembers = [];
    try {
      teamMembers = await getWithTokenRefresh(
        '/core/api/v1/auth/company/members'
      );
    } catch {}
    const { permissions = {} } = profile;

    const user = {
      ...profile,
      credentials,
      teamMembers,
      containerCreditsLimitReached: getContainerCreditsLimitReached(profile),
      isAllCredentials: allCredentialsEnteredAndValid,
      permissions: {
        ...permissions,
        accessPickupTrackChanges:
          permissions.access_container_tracking && permissions.access_per_diem,
      },
    };
    setUser(user);
    return user;
  };

  useEffect(() => {
    (async () => {
      await fetchUser();
      setIsLoading(false);
    })();
  }, []);
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { user, isLoading, fetchUser };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserContext, UserContext };
