import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { pageTitlesAndUrls } from 'constants/pages';

const CredentialsNotification = ({ closeNotification }) => (
  <div className="creds-notification">
    <div className="creds-notification-close" onClick={closeNotification}>
      <CloseIcon style={{ fontSize: '20px' }} />
    </div>
    <div>Save your credentials</div>
    <div className="creds-notification-text">
      Follow this step-by-step guide to enter your credentials (less than 5
      minutes) and access all{' '}
      <Link
        to={pageTitlesAndUrls.emptyAppointments.path}
        target="_blank"
        className="creds-notification-link"
      >
        empty appointments availabilities
      </Link>
      .
    </div>
  </div>
);

CredentialsNotification.propTypes = {
  closeNotification: PropTypes.func.isRequired,
};

export default CredentialsNotification;
