export default theme => ({
  progressButton: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  form: {
    width: '280px', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: 3 * theme.spacing.unit,
  },
  buttonRoot: {
    backgroundColor: theme.palette.secondary.main,
    height: 52,
    color: theme.palette.primary.main,
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit,
  },
  errors: {
    minHeight: 50,
    color: theme.palette.alert.main,
  },
});
