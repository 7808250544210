import _ from 'lodash';
import {
  terminalsOrdered,
  credsStatuses,
} from '../constants/credentials.constants';

export const CredentialsService = {
  getNextTerminal(currentTerminalKey, statuses) {
    const currentTerminalIndex = _.findIndex(
      terminalsOrdered,
      terminal => terminal.key === currentTerminalKey
    );

    let nextTerminalKey = '';

    terminalsOrdered.forEach((terminal, index) => {
      if (index > currentTerminalIndex) {
        if (
          terminal.isMain &&
          !nextTerminalKey &&
          statuses[terminal.key] !== credsStatuses.valid
        ) {
          nextTerminalKey = terminal.key;
        }
      }
    });

    return nextTerminalKey;
  },

  getCredentialsStatus(terminalKey, availabilityCredentials) {
    return (
      terminalKey &&
      availabilityCredentials &&
      availabilityCredentials[terminalKey]
    );
  },

  checkAllCredentialsEnteredAndValid(availabilityCredentials) {
    const statusesArray = Object.keys(availabilityCredentials).map(
      key => availabilityCredentials[key]
    );
    return statusesArray.every(status => status === credsStatuses.valid);
  },
};
