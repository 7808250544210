import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import ModalWrapper from '../../../../common/UI/ModalWrapper';
import { noCredsVideo } from 'constants/youtube';
import './AppointmentsModal.style.css';

const AppointmentsModal = ({ classes, onGetStartedClick }) => (
  <ModalWrapper width={873}>
    <div className="empty-appoint-modal-wrapper">
      <div className="empty-appoint-modal-main-icon-container">
        <div className="empty-appoint-modal-iframe">
          <iframe
            src={noCredsVideo}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
          />
        </div>
      </div>

      <div className="empty-appoint-modal-content">
        <div className="empty-appoint-modal-title">
          Discover the Empty Appointments Availability tool
        </div>
        <div className="empty-appoint-modal-text-block">
          <div className="empty-appoint-modal-icon-container">
            <CheckIcon className={classes.radiobuttonIcon} />
          </div>
          <div>
            Find the first appointment available without checking multiple
            websites
          </div>
        </div>
        <div className="empty-appoint-modal-text-block">
          <div className="empty-appoint-modal-icon-container">
            <CheckIcon className={classes.radiobuttonIcon} />
          </div>
          <div>Check empty receiving restrictions at the same place</div>
        </div>
        <div className="empty-appoint-modal-text-block">
          <div className="empty-appoint-modal-icon-container">
            <CheckIcon className={classes.radiobuttonIcon} />
          </div>
          <div>Prove your first possibility to return</div>
        </div>
        <div className="empty-appoint-modal-button-container">
          <div
            className="empty-appoint-modal-button"
            onClick={onGetStartedClick}
          >
            GET STARTED
          </div>
        </div>
      </div>
    </div>
  </ModalWrapper>
);

AppointmentsModal.propTypes = {
  classes: PropTypes.object,
  onGetStartedClick: PropTypes.func,
};

export default AppointmentsModal;
