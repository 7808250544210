import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import erlClient from 'services/clients';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from './customDesign/Button';
import { ReactComponent as SaveIcon } from 'assets/icons/save_circle.svg';
import { ReactComponent as ProfilIcon } from 'assets/icons/profile_circle.svg';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import UserAvatar from 'pages/Settings/Profile/components/UserAvatar';
import { getFirstLetters } from 'services/helpers/user';
import { Formik } from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useUserContext } from 'common/contexts/UserContext';

const member_text =
  'Members can access all of the features included in your company’s plan, except billing, editing existing members or adding and approving new members.';
const admin_text =
  "Admins have the same access as you do. They can use all of the features included in your company's plan. They can also edit the existing list of users, add or approve new members.";

const RequestToJoinCompany = ({ classes }) => {
  const { user } = useUserContext();
  if (!user.email) return null;

  const [data, setData] = useState([]);
  const [isClose, setClose] = useState(false);

  const refuse = email =>
    erlClient
      .delete('/core/api/v1/auth/company/membership', { data: { email } })
      .then(() => window.location.reload());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await erlClient.get(
          '/core/api/v1/auth/company/members/request'
        );
        setData(result.data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [user]);

  const isAdmin = user.is_admin;

  return (
    <React.Fragment>
      {data.map(d => (
        <Dialog
          key={d.email}
          onClose={() => setClose(true)}
          open={Boolean(data.length) && !isClose}
          classes={{ paper: classes.root }}
        >
          <div className={classes.closeModalIcon}>
            <IconButton onClick={() => setClose(true)}>
              <CloseIcon style={{ fontSize: 18 }} />
            </IconButton>
          </div>
          <ProfilIcon className={classes.headerIcon} />
          <Typography align="center" variant="h5" gutterBottom>
            A user wants to join your company
          </Typography>
          {isAdmin && (
            <Typography
              align="center"
              variant="caption"
              className={classes.subtitle}
            >
              Select the level of access for the user
            </Typography>
          )}
          <Formik
            initialValues={{ email: d.email, is_admin: false }}
            onSubmit={(values, { setSubmitting }) => {
              erlClient
                .put('/core/api/v1/auth/company/membership', values)
                .then(() => window.location.reload())
                .catch(() => setSubmitting(false));
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.content}>
                  <ListItem className={classes.userInfo}>
                    <UserAvatar size="small" letters={getFirstLetters(d)} />
                    <ListItemText
                      classes={{
                        root: classes.userNameEmail,
                        primary: classes.textPrimary,
                        secondary: classes.textSecondary,
                      }}
                      primary={`${d.first_name} ${d.last_name}`}
                      secondary={d.email}
                    />
                  </ListItem>
                  {isAdmin && (
                    <React.Fragment>
                      <RadioGroup
                        name="is_admin"
                        className={classes.group}
                        value={String(values.is_admin)}
                        onChange={(_, value) => {
                          handleChange({
                            target: {
                              value: value === 'true',
                              name: 'is_admin',
                            },
                          });
                        }}
                      >
                        <FormControlLabel
                          value={'false'}
                          control={<Radio color="primary" />}
                          label={
                            <Typography variant="caption">Member</Typography>
                          }
                        />
                        <FormControlLabel
                          value={'true'}
                          control={<Radio color="primary" />}
                          label={
                            <Typography variant="caption">Admin</Typography>
                          }
                        />
                      </RadioGroup>
                      <Typography
                        variant="caption"
                        className={classes.information}
                      >
                        {values.is_admin ? admin_text : member_text}
                      </Typography>
                    </React.Fragment>
                  )}

                  <Typography
                    align="left"
                    variant="caption"
                    className={classes.error}
                  >
                    {errors.message}
                  </Typography>
                  <div className={classes.refuseRequest}>
                    <Typography
                      align="right"
                      onClick={() => refuse(d.email)}
                      className={classes.deleteLink}
                    >
                      Refuse request
                    </Typography>
                  </div>
                </div>
                <Button
                  type="submit"
                  rightIcon={<SaveIcon className={classes.icon} />}
                  isLoading={isSubmitting}
                >
                  Accept
                </Button>
              </form>
            )}
          </Formik>
        </Dialog>
      ))}
    </React.Fragment>
  );
};

RequestToJoinCompany.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const styles = theme => ({
  root: {
    padding: 4 * theme.spacing.unit,
    textAlign: 'center',
    // width: theme.layout.popupWidth,
  },
  icon: {
    marginLeft: theme.spacing.unit,
  },
  content: {
    width: 300,
    margin: 'auto',
    padding: 2 * theme.spacing.unit,
    backgroundColor: 'rgba(31, 51, 75, 0.05)',
    marginBottom: 2 * theme.spacing.unit,
  },
  information: {
    display: 'block',
    textAlign: 'justify',
  },
  group: {
    flexDirection: 'row',
  },
  subtitle: {
    marginBottom: theme.spacing.unit,
  },
  headerIcon: {
    margin: 'auto',
    marginBottom: theme.spacing.unit,
  },
  error: {
    color: theme.palette.error.main,
  },
  radioLabel: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  deleteLink: {
    fontSize: 10,
    textDecoration: 'underline',
    color: 'rgba(255, 80, 35, 0.5)',
    cursor: 'pointer',
  },
  userInfo: {
    padding: '0',
  },
  userNameEmail: {
    marginLeft: '10px',
  },
  refuseRequest: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  closeModalIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export default withStyles(styles)(RequestToJoinCompany);
