import React from 'react';
import SignIn from './SignIn';
import { withStyles } from '@material-ui/core/styles';
import styles from './SignIn.style';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { signIn, isLogged, clearUserData } from 'ducks/user';
import { useLocation } from 'react-router-dom';

const mapStateToProps = state => ({
  // user: state.user,
  isLogged: isLogged(state),
});

const mapDispatchToProps = dispatch => ({
  signIn: form => dispatch(signIn(form)),
  clearUserData: () => dispatch(clearUserData()),
});

const withHooksHOC = Component => {
  return props => {
    const location = useLocation();

    return <Component location={location} {...props} />;
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withHooksHOC
)(SignIn);
