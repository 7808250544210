import React from 'react';

import css from './ptscSchedule.module.css';
import ScheduleContainer from './PtscSchedule';

const BaseSchedulesContainer = () => {
  return (
    <div className={css.scheduleWrapper}>
      <ScheduleContainer />
    </div>
  );
};

export default BaseSchedulesContainer;
