import moment from 'moment-timezone';

const SHIFTS_REF = {
  shift1: 'shift1',
  shift2: 'shift2',
  shift3: 'shift3',
};
const laTimezone = time => moment(time).tz('America/Los_Angeles');
// To take into account that shift 2 ends the following day at 5:30AM
const refDate = () => {
  return laTimezone()
    .subtract(5, 'hours')
    .subtract(30, 'minutes');
};

const shiftStartHour = () => ({
  shift1: refDate().set({ hour: 5, minute: 30 }),
  shift2: refDate().set({ hour: 17, minute: 30 }),
});

const isValidShift = shift => {
  // Avoid shift from Sunday
  if (moment(shift.day).day() === 0) return false;
  // Avoid shift 2 from Saturday
  if (moment(shift.day).day() === 6 && shift.shift === SHIFTS_REF.shift2)
    return false;
  return true;
};

const isValidShiftByDay = shift => {
  // var options = { weekday: 'long' };
  // const day = new Intl.DateTimeFormat('en-US', options).format(
  //   new Date(shift.day)
  // );
  const day = moment(shift.day).format('dddd');
  if (day === 'Sunday') return false;
  if (day === 'Saturday' && shift.shift === SHIFTS_REF.shift2) return false;
  return true;
  // if (day.toUpperCase() === 'SUNDAY') return false;
  // if (day.toUpperCase() === 'SATURDAY' && shift.shift === SHIFTS_REF.shift2) return false;
  // return true;
};

export const nextShift = shift => {
  if (shift.shift === SHIFTS_REF.shift1) {
    return {
      shift: SHIFTS_REF.shift2,
      day: shift.day,
    };
  } else {
    return {
      shift: SHIFTS_REF.shift1,
      day: moment(shift.day)
        .add(1, 'day')
        .format('YYYY-MM-DD'),
    };
  }
};

export const getShiftEndTime = (day, shift) => {
  if (!Object.values(SHIFTS_REF).includes(shift))
    throw Error(`Unknown shift value: ${shift}`);
  const date = laTimezone(`${day}T00:00:00-07:00`);
  if (shift === SHIFTS_REF.shift1) date.set({ hour: 17, minute: 30 });
  if (shift === SHIFTS_REF.shift2)
    date.add(1, 'day').set({ hour: 5, minute: 30 });

  return date;
};

export const getCurrentShift = () => {
  return {
    shift:
      laTimezone() > shiftStartHour().shift2
        ? SHIFTS_REF.shift2
        : SHIFTS_REF.shift1,
    day: refDate().format('YYYY-MM-DD'),
  };
};

export const getAvailableShifts = (nbShift = 4) => {
  let availableShifts = [];
  let shift = getCurrentShift();
  let max_attempt = 100;
  do {
    if (isValidShift(shift)) availableShifts.push(shift);
    shift = nextShift(shift);
    max_attempt--;
  } while (availableShifts.length < nbShift && max_attempt > 0);
  return availableShifts;
};

export const getAvailableShiftsValidateByDay = (nbShift = 4) => {
  let availableShifts = [];
  let shift = getCurrentShift();
  let max_attempt = 100;
  do {
    if (isValidShiftByDay(shift)) availableShifts.push(shift);
    shift = nextShift(shift);
    max_attempt--;
  } while (availableShifts.length < nbShift && max_attempt > 0);
  return availableShifts;
};

export const compareShift = (shift1, shift2) => {
  if (shift1.day < shift2.day) return true;
  if (shift1.day === shift2.day) return shift1.shift <= shift2.shift;
  return false;
};

export const displayOnlyShift = shift => {
  if (shift === SHIFTS_REF.shift1) return 'Shift 1';
  if (shift === SHIFTS_REF.shift2) return 'Shift 2';
  if (shift === SHIFTS_REF.shift3) return 'Shift 3';
  throw new Error('Unknow shift: ' + shift);
};
export const displayShift = shift => {
  const displayShift = displayOnlyShift(shift.shift);
  return `${moment(shift.day).format('ddd D MMM')} - ${displayShift}`;
};
export const availableShifts = getAvailableShifts();

export const getFormatedShiftDate = shiftDate => {
  return moment(shiftDate).format('dddd MMMM DD');
};
