import React from 'react';
import TrialWillEndDialog from './TrialWillEndDialog';
import TrialEndDialog from './TrialEndDialog';
import _ from 'lodash';
import { useUserContext } from 'common/contexts/UserContext';

const TrialDialogs = () => {
  const { user } = useUserContext();
  const trialEndDate = _.get(user, 'company.trial_end');
  const userPack = user.pack;
  const companyPack = _.get(user, 'company.pack');

  return (
    <>
      {trialEndDate && <TrialWillEndDialog trialEndDate={trialEndDate} />}
      {userPack && userPack === 'trial_end' && companyPack === 'pioneer' && (
        <TrialEndDialog />
      )}
    </>
  );
};

export default TrialDialogs;
