import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  fetchGateSchedule,
  flushEditableGateScheduleItem,
  sendGateScheduleEmails,
} from '../../../ducks/admin';
import { getWeekNumber } from '../../../utils/common';

import TableHeaders from './TableHeaders';
import TableBody from './TableBody';
import css from './gateSchedule.module.css';
import { Button } from '@material-ui/core';

const GateSchedule = props => {
  const [year, week] = getWeekNumber(new Date());
  const [weekNumber, setWeekNumber] = useState(week);

  const { fetchGateSchedule } = props;
  useEffect(() => {
    fetchGateSchedule(weekNumber, year);
  }, [fetchGateSchedule]);

  const onClick = weekSwitch => {
    let toWeek = weekNumber + weekSwitch;
    if (weekSwitch === 0) toWeek = week;
    setWeekNumber(toWeek);
    fetchGateSchedule(toWeek, year);
  };

  return props.isGateScheduleExists ? (
    <>
      <h1>Gate Schedule (week {weekNumber})</h1>
      <ul className={css.weekPageUl}>
        <li>
          <span className={css.weekPageItem} onClick={() => onClick(-1)}>
            Previous
          </span>
        </li>
        <li>
          <span className={css.weekPageItem} onClick={() => onClick(0)}>
            Current
          </span>
        </li>
        <li>
          <span className={css.weekPageItem} onClick={() => onClick(1)}>
            Next
          </span>
        </li>
      </ul>

      <div className={css.tableWrapper}>
        <div className={css.cancelButtonWrapper}>
          <Button
            variant="contained"
            onClick={props.flushEditableGateScheduleItem}
          >
            Cancel editing
          </Button>
        </div>

        <table className={css.table}>
          <TableHeaders />
          <TableBody />
        </table>
        <h3>Last update: {props.lastUpdate}</h3>
        <div className={css.cancelButtonWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={props.sendGateScheduleEmails}
          >
            Send emails
          </Button>
        </div>
      </div>
    </>
  ) : null;
};

GateSchedule.propTypes = {
  fetchGateSchedule: PropTypes.func,
  isGateScheduleExists: PropTypes.bool,
  lastUpdate: PropTypes.string,
  flushEditableGateScheduleItem: PropTypes.func,
  sendGateScheduleEmails: PropTypes.func,
};

const mapStateToProps = state => ({
  isGateScheduleExists: state.admin.gateSchedule.rows.length > 0,
  lastUpdate: state.admin.gateSchedule.lastUpdate,
});

const mapDispatchToProps = {
  fetchGateSchedule,
  flushEditableGateScheduleItem,
  sendGateScheduleEmails,
};

export default connect(mapStateToProps, mapDispatchToProps)(GateSchedule);
