import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';

import {
  getAvailabilities,
  setAvailableAppointments,
  reSetFunctionalParsers,
} from 'ducks/availabilities';
import { startLoading, endLoading } from 'ducks/loader';
import { closeCaption } from 'ducks/display';
import {
  getScreenshots,
  downloadEaScreenshotPDF,
  sendScreenshotViaEmail,
} from 'ducks/backupBank';

import { setError } from 'ducks/error';

import {
  getAvailabilityCredentials,
  setClearAvailabilityCredentials,
} from 'ducks/availabilityCredentials';
import { setShippingLines, setContainerTypes, setPorts } from 'ducks/erlData';
import { globalLoaderSelector } from 'ducks/loader';
import { isLogged, retrieveTeamMember } from 'ducks/user';

import Appointments from './Appointments';
import styles from './Appointments.style';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'common/contexts/UserContext';

const mapStateToProps = (state, ownProps) => {
  const { erlData, availabilityCredentials } = state;
  const { shippingLines, containerTypes, ports } = erlData || {};

  return {
    availabilityCredentials,
    isLoading: globalLoaderSelector(state),
    containerTypes: containerTypes,
    shippingLines: _.sortBy(shippingLines, 'name'),
    isLogged: isLogged(state),
    terminals: ports,
    availabilities: state.availabilities,
    refreshToken: state.refreshToken,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeLegend: () => dispatch(closeCaption()),

  setDownloadWarning: message => {
    dispatch(setError(message));
  },

  getScreenshotsBySL: (slKey, cb) => {
    dispatch(getScreenshots(slKey, cb));
  },

  getEaScreenshotPDF: (requestBody, fileNameData, cb) => {
    dispatch(downloadEaScreenshotPDF(requestBody, fileNameData, cb));
  },

  sendScreenshotViaEmail: (requestBody, cb) => {
    dispatch(sendScreenshotViaEmail(requestBody, cb));
  },

  startLoadingOnContainerNumberGet: () => {
    dispatch(startLoading('containerNumberGet'));
  },

  endLoadingOnContainerNumberGet: () => {
    dispatch(endLoading('containerNumberGet'));
  },

  startLoadingOnDownload: () => {
    dispatch(startLoading('downloadEA'));
  },

  endLoadingOnDownload: () => {
    dispatch(endLoading('downloadEA'));
  },

  clearAvailabilityCredentials: () => {
    dispatch(setClearAvailabilityCredentials());
  },
  getAppointmentsAvalabilityCredentials: () => {
    dispatch(getAvailabilityCredentials());
  },

  importData: () => {
    dispatch(setPorts());
    dispatch(setShippingLines());
    dispatch(setContainerTypes());
    // dispatch(setAvailableAppointments());
  },

  getRecipients: () => dispatch(retrieveTeamMember()),

  getAvailabilityAppointmentsV2: (line, container_size) =>
    dispatch(setAvailableAppointments(line, container_size)),

  resetFunctionalParsers: () =>
    setInterval(() => dispatch(reSetFunctionalParsers()), 120000),
  getAvailabilities: (shippingLines, containerTypes) =>
    dispatch(getAvailabilities(shippingLines, containerTypes)),
});

const withHooksHOC = Component => {
  return props => {
    const navigate = useNavigate();
    const { user } = useUserContext();

    return <Component user={user} navigate={navigate} {...props} />;
  };
};

export default compose(
  withStyles(styles),
  withHooksHOC,
  connect(mapStateToProps, mapDispatchToProps)
)(Appointments);
