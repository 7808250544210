import React from 'react';
import { CONTAINER_STATUS_ENUM } from '../container-config';

const ContainerCardsMobile = ({ containerData }) => {
  const {
    alert,
    chassis,
    containerNumber,
    days,
    gatedInText,
    gatedOutText,
    shippingLine,
    size,
    status,
  } = containerData;
  return (
    <div className="flex flex-col space-y-4 border border-blue-300 shadow-md p-4">
      {/* Container */}
      <div className="w-full flex justify-between pb-4 border-b border-blue-300">
        <h6 className="text-blue-900 uppercase font-extrabold tracking-wider">
          Container
        </h6>
        <div className="flex flex-col items-end">
          <h5 className="font-black underline mb-2 tracking-wider">
            {containerNumber}
          </h5>
          <h6
            className={`uppercase font-semibold px-2 py-1 border rounded-sm tracking-wider ${status ===
              CONTAINER_STATUS_ENUM.TERMINATED &&
              'border border-blue-600 bg-blue-200'} ${status === 'active' &&
              'border border-blue bg-blue-light text-blue'} ${status ===
              CONTAINER_STATUS_ENUM.PENDING &&
              'border border-blue bg-blue-light text-blue'}`}
          >
            {status}
          </h6>
        </div>
      </div>

      {/* Size */}
      <div className="w-full flex justify-between pb-4 border-b border-blue-300">
        <h6 className="text-blue-900 uppercase font-extrabold tracking-wider">
          Size
        </h6>
        <div className="flex flex-col items-end">
          <h5 className="font-medium text-blue-700 mb-2 tracking-wider">
            {size ? size : '-'}
          </h5>
        </div>
      </div>

      {/* Shippine Line */}
      <div className="w-full flex justify-between pb-4 border-b border-blue-300">
        <h6 className="text-blue-900 uppercase font-extrabold tracking-wider">
          Shippine Line
        </h6>
        <div className="flex flex-col items-end">
          <h5 className="font-medium text-blue-700 mb-2 tracking-wider">
            {shippingLine ? shippingLine : '-'}
          </h5>
        </div>
      </div>

      {/* Chassis */}
      <div className="w-full flex justify-between pb-4 border-b border-blue-300">
        <h6 className="text-blue-900 uppercase font-extrabold tracking-wider">
          Chassis
        </h6>
        <div className="flex flex-col items-end">
          <h5 className="font-medium text-blue-700 mb-2 tracking-wider">
            {chassis ? chassis : '-'}
          </h5>
        </div>
      </div>

      {/* Pickup */}
      <div className="w-full flex justify-between pb-4 border-b border-blue-300">
        <h6 className="text-blue-900 uppercase font-extrabold tracking-wider">
          Pickup
        </h6>
        <div className="flex flex-col items-end">
          <h5 className="font-medium text-blue-700 mb-2 tracking-wider">
            {gatedOutText ? gatedOutText : '-'}
          </h5>
          <h6
            className={`uppercase font-semibold text-blue-600 px-2 py-1 border rounded-sm tracking-wider border-blue-600 bg-blue-200
						`}
          >
            gated out
          </h6>
        </div>
      </div>

      {/* Detention */}
      <div className="w-full flex justify-between pb-4 border-b border-blue-300">
        <h6 className="text-blue-900 uppercase font-extrabold tracking-wider">
          detention period
        </h6>

        <div className="flex flex-col items-end">
          <h5 className="mb-2 font-normal">{days ? days + ' days' : '-'}</h5>
          {alert && (
            <>
              <h6 className="uppercase font-semibold tracking-wider text-red px-2 py-1 border rounded-sm border-red bg-red-light">
                per diem
              </h6>
            </>
          )}
        </div>
      </div>

      {/* Empty Return */}
      <div className="w-full flex justify-between pb-4 border-b border-blue-300">
        <h6 className="text-blue-900 uppercase font-extrabold tracking-wider">
          Empty Return
        </h6>
        <div className="flex flex-col items-end">
          <h5 className="font-medium text-blue-700 mb-2 tracking-wider">
            {gatedInText ? gatedInText : '-'}
          </h5>
        </div>
      </div>

      {/* Action */}
      <div className="w-full flex justify-between">
        <h6 className="text-blue-900 uppercase font-extrabold tracking-wider">
          Next Action
        </h6>
        <h5 className="text-blue-700">Next Action</h5>
      </div>
    </div>
  );
};

export default ContainerCardsMobile;
