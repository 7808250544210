import React from 'react';

const Switch = ({
  items,
  activeItemIdx = 0,
  onItemSelected = () => {},
  width,
  disabled,
}) => {
  const style = { height: '32px' };

  if (width) {
    style.width = `${width}px`;
  }
  return (
    <div className="bg-blue-600 border border-blue-500 rounded-full flex items-center">
      {items.map((item, index) => {
        return (
          <div
            key={index}
            className={`h-full rounded-full flex items-center justify-center cursor-pointer capitalize w-32 ${
              index === activeItemIdx
                ? 'bg-blue text-white font-bold'
                : `${disabled &&
                    'cursor-not-allowed'} bg-blue-600 text-gray-300 font-medium`
            }`}
            id={index}
            onClick={() => !disabled && onItemSelected(index, item)}
            style={style}
          >
            <h4>{item}</h4>
          </div>
        );
      })}
    </div>
  );
};

export default Switch;
