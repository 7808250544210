import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiInput from '@material-ui/core/OutlinedInput';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  notchedOutline: {
    display: 'none',
  },
  root: {
    backgroundColor: theme.palette.inputBackground.main,
    fontSize: 12,
    color: theme.palette.primary.main,
    // paddingLeft: 20,
  },
  input: {
    fontFamily: 'sans-serif',
    paddingLeft: 34,
    '&::placeholder': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&:-ms-input-placeholder': {
      color: theme.palette.primary.main,
    },
    '&::-ms-input-placeholder': {
      color: theme.palette.primary.main,
    },
  },
  error: {
    backgroundColor: theme.palette.alert.error,
  },
});
export class Input extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes, ...otherProps } = this.props;
    return (
      <MuiInput
        labelWidth={0}
        classes={classes}
        notched={false}
        {...otherProps}
      />
    );
  }
}

export default withStyles(styles)(Input);
