export default theme => ({
  containerContent: {
    backgroundColor: theme.palette.grey.background,
    padding: theme.spacing.unit,
    minHeight: '70vh',
    position: 'relative',
  },
  containerRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing.unit * 8,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
  },
  subtitle: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit * 2,
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
});
