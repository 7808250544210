import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import BlueCargoLogo from 'components/BlueCargoLogo';
import UserProfileItem from './UserProfileItem';
import { MENU_DROPDOWNS, SHOW_UPGRADE_BUTTON } from './utils/config';
import { pageTitlesAndUrls } from '../../constants/pages';
import { NAV_HEIGHT } from './utils/config';

const DesktopNav = ({ tabs, user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onUpgradeClick = () => {
    navigate(pageTitlesAndUrls.plans.path);
  };

  return (
    <div
      className="w-full bg-blue shadow-md flex items-center pl-4 justify-between"
      style={{ height: NAV_HEIGHT }}
    >
      <div className="flex items-center space-x-8">
        <div className="">
          <BlueCargoLogo />
        </div>
        <div className="px-2 flex items-center">
          <div className="flex items-center space-x-12">
            <div className="flex space-x-2">
              {tabs.map(({ path, title, isTabShown }) => {
                if (isTabShown && !isTabShown(user)) return <></>;
                return (
                  <Link key={title} to={path}>
                    <h4
                      className={`py-1 px-4 rounded-md text-blue-400 font-semibold ${
                        path === location.pathname
                          ? 'bg-dark-200 text-white hover:bg-dark-200'
                          : 'hover:bg-dark-200'
                      }`}
                    >
                      {title}
                    </h4>
                  </Link>
                );
              })}
              {MENU_DROPDOWNS.map(dropdownItem => {
                return (
                  <MenuDropdown
                    key={dropdownItem.title}
                    dropdown={dropdownItem}
                    location={location}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-8 items-center">
        {/* upgrade button */}
        {SHOW_UPGRADE_BUTTON.includes(user.pack) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onUpgradeClick}
            style={{
              letterSpacing: 'normal',
            }}
          >
            <h4 className="font-bold">Upgrade your plan</h4>
          </Button>
        )}
        {/* user profile */}
        <UserProfileItem />
      </div>
    </div>
  );
};

const MenuDropdown = ({ dropdown, location }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLinkActive, setIsLinkActive] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setIsLinkActive(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsLinkActive(false);
  };

  useEffect(() => {
    const paths = dropdown.children.map(item => item.path);
    const isPathActive = paths.includes(location.pathname);
    setIsLinkActive(isPathActive);
  }, [location]);

  return (
    <div>
      <div
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div
          className={`flex space-x-1 items-end py-1 px-4 rounded-md text-blue-400 font-semibold cursor-pointer ${
            isLinkActive
              ? 'bg-dark-200 text-white hover:bg-dark-200'
              : 'hover:bg-dark-200'
          } `}
        >
          <h4>{dropdown.title}</h4>
          <KeyboardArrowDownIcon style={{ fontSize: '20px' }} />
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{
          zIndex: '1500',
        }}
      >
        <ul className="flex flex-col items-start">
          {dropdown.children.map(item => {
            return (
              <li key={item.title} onClick={handleClose} className="w-full">
                <Link to={item.path} className="w-full">
                  <h4 className="pl-4 pr-8 py-2 font-medium text-gray-100 hover:bg-blue-500 hover:text-blue">
                    {item.title}
                  </h4>
                </Link>
              </li>
            );
          })}
        </ul>
      </Menu>
    </div>
  );
};

export default DesktopNav;
