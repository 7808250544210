import { ReactComponent as Diamond } from 'assets/icons/diamond.svg';
import { ReactComponent as BetaAccess } from 'assets/icons/beta-access.svg';
import { SHIPPER_COMPANY_TYPE } from './user';
import _ from 'lodash';
import { User } from 'types/User';

interface Page {
  title: string;
  path: string;
  isPublic?: boolean;
  isTabShown?: (user: User) => boolean;
  PageIcon?: JSX.Element;
  requiredPermission?: Array<string>;
  isSuperUser?: boolean;
}

export const pageTitlesAndUrls: Record<string, Page> = {
  signIn: {
    title: 'Sign in',
    path: '/signin',
    isPublic: true,
  },
  signUp: {
    title: 'Sign up',
    path: '/signup',
    isPublic: true,
  },
  resetPassword: {
    title: 'Reset passowrd',
    path: '/reset-password',
    isPublic: true,
  },
  changePassword: {
    title: 'Reset passowrd',
    path: '/change-password',
    isPublic: true,
  },
  freeTable: {
    title: 'Free Table',
    path: '/free-table',
    isPublic: true,
  },
  emailConfirmation: {
    title: 'Email Confirmation',
    path: '/confirmation',
    isPublic: true,
  },
  welcome: {
    title: 'Welcome',
    path: '/welcome',
    isPublic: true,
  },
  termOfUse: {
    title: 'Term of Use',
    path: '/term-of-use',
    isPublic: true,
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    path: '/privacy-policy',
    isPublic: true,
  },
  contactUs: {
    title: 'Contact Us',
    path: '/contact-us',
    isPublic: true,
  },
  forceLogin: {
    title: 'Force Login',
    path: '/force-login',
  },
  home: {
    title: 'Home',
    path: '/',
  },
  emptyReturnLocations: {
    title: 'Empty Receiving Schedule',
    path: '/terminals',
  },
  gateSchedule: {
    title: 'Gate Schedule',
    path: '/gate-schedule',
  },
  vesselSchedule: {
    title: 'Vessel Schedule',
    path: '/vessel-schedule',
  },
  emptyAppointments: {
    title: 'Dispatch',
    path: '/empty-appointments',
    PageIcon: Diamond,
    requiredPermission: ['access_empty_appointments_search'],
  },
  perDiemFighter: {
    title: 'Per Diem Archive',
    path: '/perdiem-archive',
    PageIcon: Diamond,
    requiredPermission: ['access_per_diem'],
  },
  terminalScreenshots: {
    title: 'Terminal Screenshots',
    path: '/perdiem-archive/terminal-screenshots',
    requiredPermission: ['access_per_diem'],
  },
  profile: {
    title: 'Profile & Users',
    path: '/settings/profile',
  },
  credentials: {
    title: 'Terminal Credentials',
    path: '/settings/credentials',
    requiredPermission: ['access_credentials'],
  },
  notificationSettings: {
    title: 'Notification Settings',
    path: '/settings/notification-settings',
    requiredPermission: ['access_notification'],
  },
  adminGateSchedule: {
    title: 'Admin Gate Schedule',
    path: '/admin/gate-schedule',
    isSuperUser: true,
  },
  adminPTSCSchedule: {
    title: 'Admin PTSC Schedule',
    path: '/admin/terminal-instructions/ptsc-schedule',
    isSuperUser: true,
  },
  myAppointments: {
    title: 'My Appointments',
    path: '/my-appointments',
    isTabShown: user => user && user.beta_access,
  },
  containerTracking: {
    title: 'Containers',
    path: '/containers',
    PageIcon: BetaAccess,
    requiredPermission: ['access_container_tracking'],
    isTabShown: user => showContainerTrackingTab(user),
  },
  plans: {
    title: 'Plans',
    path: '/settings/plans',
  },
  invoiceHistory: {
    title: 'Billing & Invoices',
    path: '/settings/billing-and-invoices',
  },
  settings: {
    title: 'Settings',
    path: '/settings/',
  },
  joinRequestValidation: {
    title: 'Company Requests',
    path: '/company/request/validate',
  },
  componentPreview: {
    title: 'Component Preview',
    path: '/company/request/validate',
  },
};

function showContainerTrackingTab(user: User) {
  if (!user) return true;
  const companyType = _.get(user, 'company.company_type');
  if (companyType !== SHIPPER_COMPANY_TYPE) return true;
  return user.beta_access;
}

export function getPage(path: string) {
  return Object.values(pageTitlesAndUrls).find(page => {
    return page.path === path;
  });
}

export function addPackNameToPlanTitle(item: Page, user: User) {
  if (item.path === pageTitlesAndUrls.plans.path && user.company) {
    return {
      ...item,
      title: `${item.title} (${user.company.pack_display_name})`,
    };
  }
  return item;
}
