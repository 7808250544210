import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DualIcon from '@material-ui/icons/CompareArrows';
import { chassis } from '../NextAppointments/constants/next-appointments.constants';
import { NextAppointmentsService } from '../NextAppointments/services/next-appointments.service';
import { clockStatus } from '../NextAppointments/constants/next-appointments.constants';

const renderStatusIcon = status => {
  let icon = null;
  switch (status) {
    case clockStatus:
      icon = (
        <AccessTimeIcon
          classes={{ root: 'MuiAutocomplete-BookAppointmentModal-icon grey' }}
        />
      );
      break;
    case 'yes':
      icon = (
        <CheckIcon
          classes={{ root: 'MuiAutocomplete-BookAppointmentModal-icon green' }}
        />
      );
      break;
    case 'no':
      icon = (
        <CloseIcon
          classes={{ root: 'MuiAutocomplete-BookAppointmentModal-icon red' }}
        />
      );
      break;
    case 'dual':
      icon = (
        <DualIcon
          classes={{ root: 'MuiAutocomplete-BookAppointmentModal-icon yellow' }}
        />
      );
      break;
    case 'na':
      icon = (
        <AccessTimeIcon
          classes={{ root: 'MuiAutocomplete-BookAppointmentModal-icon grey' }}
        />
      );
      break;
    default:
      icon = null;
  }
  return icon;
};

const BookAppointmentModalForm = ({
  data,
  classes,
  onModalClose,
  onBookAppointmentClick,
  selectedAppointment,
  selectedChassis,
  containerNumber,
  chassisNumber,
  licensePlate,
  requiredFieldsWarnings,
  isContainerNumberValidWarning,
  onAppointmentChange,
  onContainerNumberChange,
  setOwnChassis,
  setPortChassis,
  onChassisNumberChange,
  onLicensePlateChange,
}) => {
  const {
    terminal,
    availability,
    selectedContainer,
    selectedSL,
    instructionsBySlAndContainerType,
  } = data || {};
  const { name: selectedContainerName } = selectedContainer || {};
  const { name: selectedSlName } = selectedSL || {};
  const { name, appointment_system_name } = terminal || {};

  const options = [];

  availability.forEach((item, i) => {
    const date = Object.keys(item)[0];

    const shifts = item[date] || {};
    const shift1Data = shifts['shift1'] || {};
    const shift2Data = shifts['shift2'] || {};
    const { slots: shift1Slots } = shift1Data || {};
    const { slots: shift2Slots } = shift2Data || {};

    shift1Slots &&
      shift1Slots.forEach(
        ({
          start_time,
          end_time,
          start_date,
          appointment_uuid,
          terminal_date,
          appointments,
        }) => {
          options.push({
            shift_date: date,
            shift: 'shift1',
            start_time,
            end_time,
            start_date,
            appointment_uuid,
            terminal_date,
            appointments,
          });
        }
      );

    shift2Slots &&
      shift2Slots.forEach(
        ({
          start_time,
          end_time,
          start_date,
          appointment_uuid,
          terminal_date,
          appointments,
        }) => {
          options.push({
            shift_date: date,
            shift: 'shift2',
            start_time,
            end_time,
            start_date,
            appointment_uuid,
            terminal_date,
            appointments,
          });
        }
      );
  });

  const validateForm = () => {
    const isChassisValid =
      selectedChassis === chassis.ownChassis ||
      (selectedChassis === chassis.portChassis && chassisNumber);
    return (
      containerNumber &&
      selectedAppointment &&
      !isContainerNumberValidWarning &&
      isChassisValid
    );
  };

  const isBookAppointmentButtonEnabled = validateForm();

  return (
    <div className="book-appointment-modal-wrapper">
      <div className="book-appointment-modal-title">
        Book your appointment at{' '}
        <b>{`${_.toUpper(name)} (${appointment_system_name})`}</b>
      </div>

      <div className="book-appointment-modal-block container-number">
        <div className="book-appointment-modal-field-title">
          Container Number
        </div>
        <input
          type="text"
          name="containerNumberInput"
          value={containerNumber}
          maxLength={11}
          onChange={onContainerNumberChange}
          placeholder={`Enter a ${selectedSlName} ${selectedContainerName}`}
          className="book-appointment-modal-input"
        />

        {isContainerNumberValidWarning && (
          <div className="book-appointment-modal-warning">
            {isContainerNumberValidWarning}
          </div>
        )}

        {requiredFieldsWarnings.containerNumberRequiredWarning && (
          <div className="book-appointment-modal-warning">
            This field is required
          </div>
        )}
      </div>

      <div className="book-appointment-modal-block">
        <div className="book-appointment-modal-field-title dropdown-title">
          Select Appointment
        </div>

        <Autocomplete
          id="bookAppointmentModalDropdown"
          classes={classes}
          options={options}
          value={selectedAppointment}
          onChange={onAppointmentChange}
          groupBy={option => `${option.shift_date}_${option.shift}`}
          getOptionLabel={option => {
            const formattedDate = moment(
              option.start_date,
              'YYYY-MM-DD'
            ).format('YYYY/MM/DD');
            const formattedStartTime = moment(
              option.start_time,
              'HH:mm:ss'
            ).format('HH:mm');
            const formattedEndTime = moment(option.end_time, 'HH:mm:ss').format(
              'HH:mm'
            );
            const appointmentsNumber = option.appointments || '';
            return `${formattedDate} - ${formattedStartTime}-${formattedEndTime}${
              appointmentsNumber ? ` (${appointmentsNumber})` : ''
            }`;
          }}
          getOptionSelected={(option, value) => {
            return (
              option.shift_date === value.shift_date &&
              option.shift === value.shift &&
              option.start_time === value.start_time &&
              option.end_time === value.end_time &&
              option.appointment_uuid === value.appointment_uuid &&
              option.start_date === value.start_date
            );
          }}
          renderInput={params => (
            <TextField {...params} label="" variant="outlined" />
          )}
          renderGroup={({ key, group, children }) => {
            const {
              shiftDate,
              shift,
              groupLabel,
            } = NextAppointmentsService.getGroupLabel(group);
            const { availability } = instructionsBySlAndContainerType || {};
            const availabilityByDate =
              (availability && availability[shiftDate]) || {};
            const shiftData = availabilityByDate[shift] || {};
            const { status } = shiftData || {};
            return (
              <div key={key}>
                <div className="book-appointment-modal-dropdown-group-label-icon">
                  <span className="book-appointment-modal-dropdown-group-label">
                    {groupLabel}
                  </span>
                  <span>{renderStatusIcon(status || clockStatus)}</span>
                </div>
                {children}
              </div>
            );
          }}
        />
        {requiredFieldsWarnings.appointmentRequiredWarning && (
          <div className="book-appointment-modal-warning">
            This field is required
          </div>
        )}
      </div>

      <div className="book-appointment-modal-block">
        <div className="book-appointment-modal-checkbox-label">
          <label className="book-appointment-modal-checkbox-container">
            Own chassis (recommended)
            <input
              type="checkbox"
              checked={selectedChassis === chassis.ownChassis}
              onChange={setOwnChassis}
            />
            <span className="book-appointment-modal-checkbox-checkmark"></span>
          </label>
        </div>
      </div>

      <div className="book-appointment-modal-block">
        <div className="book-appointment-modal-checkbox-block">
          <div className="book-appointment-modal-checkbox-label">
            <label className="book-appointment-modal-checkbox-container">
              Port chassis
              <input
                type="checkbox"
                checked={selectedChassis === chassis.portChassis}
                onChange={setPortChassis}
              />
              <span className="book-appointment-modal-checkbox-checkmark"></span>
            </label>
          </div>

          <div className="book-appointment-modal-checkbox-chassis-number">
            <input
              type="text"
              name="portChassisInput"
              value={chassisNumber}
              onChange={onChassisNumberChange}
              placeholder="Enter chassis number"
              maxLength={20}
              className="book-appointment-modal-input"
              disabled={selectedChassis !== chassis.portChassis}
            />
            {requiredFieldsWarnings.portChassisRequiredWarning && (
              <div className="book-appointment-modal-warning chassis-number">
                This field is required
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="book-appointment-modal-block">
        <div className="book-appointment-modal-field-title">
          Truck license (optional)
        </div>
        <input
          type="text"
          value={licensePlate}
          onChange={onLicensePlateChange}
          placeholder="Enter the license plate"
          className="book-appointment-modal-input"
        />
      </div>

      <div className="book-appointment-modal-button-wrapper">
        <div
          className={`book-appointment-modal-button${
            isBookAppointmentButtonEnabled ? '' : ' disabled'
          }`}
          onClick={onBookAppointmentClick}
        >
          BOOK APPOINTMENT
        </div>
      </div>
      <div className="book-appointment-modal-cancel-wrapper">
        <div className="book-appointment-modal-cancel" onClick={onModalClose}>
          Cancel
        </div>
      </div>
    </div>
  );
};

BookAppointmentModalForm.propTypes = {
  classes: PropTypes.object,
  onModalClose: PropTypes.func,
  data: PropTypes.object,
  onBookAppointmentClick: PropTypes.func,
  selectedAppointment: PropTypes.object,
  selectedChassis: PropTypes.string,
  containerNumber: PropTypes.string,
  chassisNumber: PropTypes.string,
  licensePlate: PropTypes.string,
  requiredFieldsWarnings: PropTypes.object,
  onAppointmentChange: PropTypes.func,
  onContainerNumberChange: PropTypes.func,
  setOwnChassis: PropTypes.func,
  setPortChassis: PropTypes.func,
  onChassisNumberChange: PropTypes.func,
  onLicensePlateChange: PropTypes.func,
  isContainerNumberValidWarning: PropTypes.string,
};

export default BookAppointmentModalForm;
