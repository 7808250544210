import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import MobileAvailability from 'pages/Mobile/Availability';
import { pageTitlesAndUrls } from 'constants/pages';
import TermsOfUse from 'pages/TermsOfUse';
import AppPageTemplate from 'layouts/AppPageTemplate';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ContactUs from 'pages/ContactUs';
import NotFound404 from 'pages/NotFound404';
import SignUp from 'pages/SignUp';
import SignIn from 'pages/SignIn';
import ResetPassword from 'pages/ResetPassword';
import ChangePassword from 'pages/ChangePassword';
import FreeTable from 'pages/FreeTable';
import EmailConfirmation from 'pages/EmailConfirmation';
import Welcome from 'pages/Welcome';
import ForceLogin from 'pages/ForceLogin';
import Home from 'pages/Home';
import MobileHome from 'pages/Mobile/Home';
import { useMediaQuery } from '@mui/material';
import { themeProvider } from 'themeProvider';
import Terminals from 'pages/Terminals';
import MyAppointments from 'pages/MyAppointments';
import VesselScheduleV2 from 'pages/VesselSchedule/VesselScheduleV2';
import GateSchedule from 'pages/GateSchedule';
import Appointments from 'pages/T_Appointments';
import TerminalScreenshotsPage from 'pages/PerDiemArchiveV2/TerminalScreenshotsPage';
import PerDiemArchiveV2 from 'pages/PerDiemArchiveV2';
import settingsRoutes from 'pages/Settings/routes';
import Settings from 'pages/Settings';
import JoinRequestValidation from 'pages/JoinRequestValidation';
import ComponentPreview from 'pages/ComponentPreview/ComponentPreview';
import ContainerTracking from 'pages/ContainerTracking';
import GateSchedulesContainer from 'components/Admin/GateSchedule/GateSchedulesContainer';
import PtscScheduleContainer from 'components/Admin/TerminalInstructions/PtscSchedule/PtscSchedulesContainer';
import PrivateRoute from 'components/PrivateRoute';

const SHORTCUT_REDIRECTS = {
  '/c': pageTitlesAndUrls.containerTracking.path,
  '/cp': pageTitlesAndUrls.componentPreview.path,
  '/ea': pageTitlesAndUrls.emptyAppointments.path,
  '/ed': pageTitlesAndUrls.emptyAppointments.path,
  '/pda': pageTitlesAndUrls.perDiemFighter.path,
  '/ma': pageTitlesAndUrls.myAppointments.path,
  '/ers': pageTitlesAndUrls.emptyReturnLocations.path,
  '/t': pageTitlesAndUrls.emptyReturnLocations.path,
  '/vs': pageTitlesAndUrls.vesselSchedule.path,
  '/gs': pageTitlesAndUrls.gateSchedule.path,
  '/s': pageTitlesAndUrls.settings.path,
  '/credentials': pageTitlesAndUrls.credentials.path,
};

const SHORTCUT_ROUTE_OBJECTS = Object.entries(SHORTCUT_REDIRECTS).map(
  ([shortcutUrl, redirectTo]: [string, string]) => {
    return {
      path: shortcutUrl,
      elements: <Navigate replace to={redirectTo} />,
    };
  }
);

const AppRoutes = () => {
  const isMobile = useMediaQuery(themeProvider.breakpoints.down('sm'));

  const routes = useRoutes([
    {
      path: pageTitlesAndUrls.home.path,
      element: isMobile ? <MobileHome /> : <Home />,
    },
    {
      path: pageTitlesAndUrls.termOfUse.path,
      element: <TermsOfUse />,
    },
    {
      path: pageTitlesAndUrls.privacyPolicy.path,
      element: <PrivacyPolicy />,
    },
    {
      path: pageTitlesAndUrls.contactUs.path,
      element: <ContactUs />,
    },
    {
      path: pageTitlesAndUrls.signUp.path,
      element: <SignUp />,
    },
    {
      path: pageTitlesAndUrls.signIn.path,
      element: <SignIn />,
    },
    {
      path: pageTitlesAndUrls.resetPassword.path,
      element: <ResetPassword />,
    },
    {
      path: pageTitlesAndUrls.changePassword.path,
      element: <ChangePassword />,
    },
    {
      path: pageTitlesAndUrls.freeTable.path,
      element: <FreeTable />,
    },
    {
      path: pageTitlesAndUrls.emailConfirmation.path,
      element: <EmailConfirmation />,
    },
    {
      path: pageTitlesAndUrls.welcome.path,
      element: <Welcome />,
    },
    {
      path: pageTitlesAndUrls.forceLogin.path,
      element: <ForceLogin />,
    },
    {
      path: '*',
      element: <NotFound404 />,
    },
    {
      path: '/',
      element: (
        <PrivateRoute>
          <AppPageTemplate />
        </PrivateRoute>
      ),
      children: [
        {
          path: pageTitlesAndUrls.emptyReturnLocations.path,
          element: isMobile ? <MobileAvailability /> : <Terminals />,
        },
        {
          path: pageTitlesAndUrls.myAppointments.path,
          element: <MyAppointments />,
        },
        {
          path: pageTitlesAndUrls.vesselSchedule.path,
          element: <VesselScheduleV2 />,
        },
        {
          path: pageTitlesAndUrls.gateSchedule.path,
          element: <GateSchedule />,
        },
        {
          path: pageTitlesAndUrls.emptyAppointments.path,
          element: <Appointments />,
        },
        {
          path: pageTitlesAndUrls.terminalScreenshots.path,
          element: <TerminalScreenshotsPage />,
        },
        {
          path: pageTitlesAndUrls.perDiemFighter.path,
          element: <PerDiemArchiveV2 />,
        },
        {
          path: pageTitlesAndUrls.settings.path,
          element: <Settings />,
          children: settingsRoutes,
        },
        {
          path: pageTitlesAndUrls.joinRequestValidation.path,
          element: <JoinRequestValidation />,
        },
        {
          path: pageTitlesAndUrls.joinRequestValidation.path,
          element: <ComponentPreview />,
        },
        {
          path: pageTitlesAndUrls.containerTracking.path,
          element: <ContainerTracking />,
          children: [
            {
              path: ':containerNumber',
              element: <ContainerTracking />,
            },
          ],
        },
        // Hopefully one day these components can move to Retool or an internal dashboard
        {
          path: pageTitlesAndUrls.adminGateSchedule.path,
          element: <GateSchedulesContainer />,
        },
        {
          path: pageTitlesAndUrls.adminPTSCSchedule.path,
          element: <PtscScheduleContainer />,
        },
        ...SHORTCUT_ROUTE_OBJECTS,
      ],
    },
  ]);

  return routes;
};

export default AppRoutes;
