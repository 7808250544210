export default theme => ({
  root: {
    paddingTop: 2 * theme.mixins.toolbar.minHeight,
    position: 'relative',
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    marginTop: 3 * theme.spacing.unit,
  },
  logo: {
    color: theme.palette.primary.main,
  },
  backIcon: {
    color: theme.palette.white.main,
  },
});
