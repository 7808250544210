import React from 'react';
import { GridOverlay } from '@mui/x-data-grid-pro';
import { ReactComponent as HookIcon } from 'assets/icons/hook.svg';

const UploadContainersOverlay = ({ setImportModalIsOpen }) => {
  return (
    <GridOverlay>
      <div className="flex flex-col my-16 space-y-1 items-center">
        <HookIcon />
        <h3 className="text-gray-200">Your container watchlist is empty!</h3>
        <p className="text-gray-200" onClick={() => setImportModalIsOpen(true)}>
          <span className="underline cursor-pointer">Import Containers</span> or
          Copy Paste your container numbers in the search bar above.
        </p>
      </div>
    </GridOverlay>
  );
};

export default UploadContainersOverlay;
