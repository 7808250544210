import erlClient from './';

export const getNotificationSettings = async () => {
  const res = await erlClient.get('/core/api/v1/notification/settings');
  return res.data;
};

export const updateNotificationSettings = async values => {
  const res = await erlClient.put('/core/api/v1/notification/settings', values);
  return res.data;
};
