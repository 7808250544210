import React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import InformationPage from 'components/InformationPage';
import ListInfoItem from 'components/customDesign/ListInfoItem';

import { ReactComponent as ProfileIcon } from 'assets/images/success.svg';

const GetStarted = ({ classes, onNextClick }) => {
  return (
    <InformationPage classes={{ container: classes.root }}>
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <ProfileIcon width={35} height={35} />
          <Typography
            component="h1"
            variant="h5"
            className={classes.title}
            gutterBottom
          >
            Get started
          </Typography>
          <Typography variant="caption" className={classes.subtitle}>
            Unlock a <span className={classes.underline}>Premium</span> account
            for 15 days!
          </Typography>
          <div className={classes.content}>
            <div className={classes.list}>
              <ListInfoItem
                primary="No credit card required"
                secondary="We'll remind you few days before your trial ends"
              />
              <ListInfoItem
                primary="Free"
                secondary="You won't be charged at all! Enter a credit card after your 15 days free"
              />
              <ListInfoItem
                primary="Non-binding"
                secondary="No commitment, cancel anytime"
              />
            </div>
            <div className={classes.bottom}>
              <Typography
                className={classes.underline}
                component="a"
                href="/signin"
                variant="caption"
                align="center"
              >
                Already have an account?
              </Typography>
              <MobileStepper
                variant="progress"
                steps={3}
                position="static"
                className={classes.stepper}
                LinearProgressProps={{
                  classes: {
                    root: classes.stepperRoot,
                    bar: classes.stepperBar,
                  },
                }}
                nextButton={
                  <Button
                    key="next"
                    size="small"
                    onClick={onNextClick}
                    className={classes.progressButton}
                  >
                    Next
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    disabled
                    className={classes.progressButton}
                  >
                    <KeyboardArrowLeft />
                    Back
                  </Button>
                }
              />
            </div>
          </div>
        </Paper>
      </main>
    </InformationPage>
  );
};

GetStarted.propTypes = {
  classes: PropTypes.object.isRequired,
  onNextClick: PropTypes.func.isRequired,
};

const styles = theme => ({
  stepper: {
    backgroundColor: theme.palette.inputBackground.main,
    marginTop: 2 * theme.spacing.unit,
  },
  stepperRoot: {
    height: 10,
    backgroundColor: 'rgba(31, 51, 75, 0.05)',
  },
  stepperBar: {
    backgroundColor: 'rgba(31, 51, 75, 0.45)',
  },
  progressButton: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  root: {},
  content: {
    width: 280, // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  main: {},
  paper: {
    minHeight: 585,
    position: 'relative',
  },
  title: {},
  subtitle: {
    marginBottom: 3 * theme.spacing.unit,
  },
  underline: {
    textDecoration: 'underline',
  },
  list: {
    textAlign: 'left',
  },
  bottom: {
    position: 'absolute',
    bottom: 3 * theme.spacing.unit,
    width: 280,
  },
});

export default withStyles(styles)(GetStarted);
