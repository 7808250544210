import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MyAppointmentsTableRow from './MyAppointmentsTableRow';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
  sortOrderTypes,
  arrowStyle,
} from './constants/my-appointments.constants';

class MyAppointmentsTable extends Component {
  static propTypes = {
    appointments: PropTypes.array,
    tableCols: PropTypes.array,
    onHandleSort: PropTypes.func.isRequired,
    sortableCol: PropTypes.string,
    sortOrder: PropTypes.string,
    onHandleRowClick: PropTypes.func.isRequired,
  };

  handleSort = col_id => {
    const { onHandleSort, sortableCol, sortOrder } = this.props;
    if (col_id === sortableCol) {
      if (sortOrder === sortOrderTypes.asc) {
        onHandleSort(col_id, sortOrderTypes.desc);
      } else if (sortOrder === sortOrderTypes.desc) {
        onHandleSort(col_id, sortOrderTypes.none);
      } else {
        onHandleSort(col_id, sortOrderTypes.asc);
      }
    } else {
      onHandleSort(col_id, sortOrderTypes.asc);
    }
  };

  render() {
    const {
      tableCols,
      appointments,
      sortableCol,
      sortOrder,
      onHandleRowClick,
    } = this.props;

    return (
      <div className="my-appt-table">
        <div className="my-appt-table-header">
          {tableCols.map(({ col_id, label, isColSortable }) => {
            const isSortingActive = sortableCol === col_id;
            let upOrDown;
            if (isSortingActive && sortOrder === sortOrderTypes.asc) {
              upOrDown = <ArrowDropDownIcon style={arrowStyle} />;
            }
            if (isSortingActive && sortOrder === sortOrderTypes.desc) {
              upOrDown = <ArrowDropUpIcon style={arrowStyle} />;
            }
            return (
              <div
                key={col_id}
                className={`my-appt-table-header-col ${col_id}${
                  isColSortable ? ' sotrable' : ''
                }`}
                onClick={() => {
                  if (!isColSortable) return;
                  this.handleSort(col_id);
                }}
              >
                <div>{label}</div>
                {isSortingActive ? upOrDown : null}
              </div>
            );
          })}
        </div>
        <div className="my-appt-table-body">
          {appointments && appointments.length ? (
            appointments.map((appointment, index) => {
              const {
                container_number,
                shipping_line,
                pier,
                terminal_name,
                chassis_owner,
                chassis_number,
                truck_license,
                slot,
                approval,
              } = appointment || {};
              const { shift, shift_date, terminal_date, start_time, end_time } =
                slot || {};
              const { appointment_status, appointment_id } = approval || {};
              return (
                <MyAppointmentsTableRow
                  key={index}
                  container_number={container_number}
                  shipping_line={shipping_line}
                  pier={pier}
                  terminal_name={terminal_name}
                  chassis_owner={chassis_owner}
                  chassis_number={chassis_number}
                  truck_license={truck_license}
                  shift={shift}
                  shift_date={shift_date}
                  appointment_status={appointment_status}
                  appointment_id={appointment_id}
                  terminal_date={terminal_date}
                  start_time={start_time}
                  end_time={end_time}
                  onHandleRowClick={onHandleRowClick}
                />
              );
            })
          ) : (
            <div className="my-appt-no-results">No results found</div>
          )}
        </div>
      </div>
    );
  }
}

export default MyAppointmentsTable;
