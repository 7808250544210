import React, { useCallback, useState } from 'react';
import styleFont from 'styles/typography.module.css';
import { brandColors } from 'brandColors';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axiosWithToken from 'services/clients/axios-with-token';
import {
  IconButton,
  Button,
  CardHeader,
  CardContent,
  CardActions,
  Card,
  Modal,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileUpload from 'components/FileUpload';
import { readFileAsync } from 'utils/common';

const style = {
  box: {
    position: 'absolute',
    top: '64px',
    left: '50%',
    transform: 'translate(-50%, -0%)',
    width: 964,
    height: 480,
    backgroundColor: brandColors.colors.white,
  },
  button: {
    height: '33px',
    padding: '8px 16px',
  },
};

const ContainerImportModal = ({ isOpen, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const onFileSelected = useCallback(acceptedFiles => {
    acceptedFiles.length > 0
      ? setSelectedFile(acceptedFiles[0])
      : setSelectedFile(null);
  }, []);

  const uploadFile = useCallback(async () => {
    setIsUploading(true);
    const file = await readFileAsync(selectedFile);
    try {
      const { data } = await axiosWithToken.post(
        '/core/api/v1/containers/upload',
        file,
        {
          headers: {
            'Content-Disposition': `attachment; filename=${selectedFile.name}`,
            'Content-Type': selectedFile.type,
          },
        }
      );
      setIsUploading(false);
      onClose(true, data);
    } catch (error) {
      console.error(error);
      setIsUploading(false);
    }
  }, [selectedFile, setIsUploading]);

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}>
      <Box sx={style.box} className={styleFont.font}>
        <Card variant="modal">
          <CardHeader
            variant="modal"
            title="Upload a CSV File"
            action={
              <IconButton disableRipple onClick={() => onClose(false)}>
                <CloseIcon
                  style={{
                    fontSize: 24,
                    color: brandColors.colors.dark.DEFAULT,
                  }}
                />
              </IconButton>
            }
          />
          <CardContent variant="modal">
            <p className={`text-dark mb-8`}>
              Select a CSV, XLSX or XLS file (only one file at a time).
            </p>
            <h4 className="text-dark mb-2">
              Upload or drag and drop your file here:
            </h4>
            <FileUpload
              fileExtensions={['csv', 'xlsx', 'xls']}
              maxSize={1024 * 1024 * 100}
              onFileSelected={onFileSelected}
            />
          </CardContent>
          <CardActions variant="modal">
            {selectedFile == null ? (
              <Button
                variant="outlined"
                sx={style.button}
                endIcon={<ArrowForwardIosIcon style={{ fontSize: 14 }} />}
                disabled
              >
                <h5 className="leading-3">Next</h5>
              </Button>
            ) : (
              <LoadingButton
                variant="contained"
                loading={isUploading}
                sx={style.button}
                onClick={uploadFile}
              >
                <h5 className="leading-3">Confirm upload</h5>
              </LoadingButton>
            )}
          </CardActions>
        </Card>
      </Box>
    </Modal>
  );
};
export default ContainerImportModal;
