import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ModalWrapper from '../../../../common/UI/ModalWrapper';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { NextAppointmentsService } from '../NextAppointments/services/next-appointments.service';
import { more20ContainersMsg } from '../../constants/appointments.constants';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import BrandButton from '../../../../components/BrandButton/BrandButton';
import './StopTheClockModal.style.css';

import styles from '../../../../styles/typography.module.css';

const baseLabelStyles = {
  fontWeight: 'bold',
  fontSize: '14px',
  color: '#263238',
};

const useStyles = makeStyles({
  label: {
    ...baseLabelStyles,
  },
  labelNotActive: {
    ...baseLabelStyles,
    color: '#8D97A3',
  },
});

const StopTheClockModal = ({
  teamMembers,
  getRecipients,
  onHandleGetReportClick,
  onHandleStopTheClockModalClose,
  fixedContainerList,
  email: currentUserEmail,
}) => {
  const classes = useStyles();
  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
    if (getRecipients) {
      getRecipients();
    }
  }, []);

  const [isDownloadReportChecked, setDownloadReportChecked] = useState(true);
  const [containersInputValue, setContainersInput] = useState('');
  const [containersInputError, setContainersInputError] = useState('');
  const [selectedTeamMembers, setSelectedTeamMembers] = useState(
    [currentUserEmail].filter(x => x)
  );

  const handleTagClick = email => {
    const updatedSelectedTeamMembers = selectedTeamMembers.includes(email)
      ? selectedTeamMembers.filter(
          selectedUserEmail => selectedUserEmail !== email
        )
      : selectedTeamMembers.concat([email]);
    setSelectedTeamMembers(updatedSelectedTeamMembers);
  };

  const onChange = (event, value) => {
    const { target } = event || {};
    const { id } = target || {};
    if (id === 'Download Report') {
      setDownloadReportChecked(value);
    }
  };

  const onGetReportClick = () => {
    const isSendCopyChecked = !_.isEmpty(selectedTeamMembers);
    onHandleGetReportClick(
      isDownloadReportChecked,
      isSendCopyChecked,
      containersInputValue,
      selectedTeamMembers
    );
  };

  const removeExcessContainerNumbers = containerNumbers => {
    setContainersInput(containerNumbers);
    setContainersInputError(more20ContainersMsg);
  };

  const onContainersInputChange = event => {
    const { target } = event || {};
    const { value } = target || {};
    if (value.includes('\n') && value.includes(',')) {
      return;
    }
    let valuesArr;
    if (value.includes('\n')) {
      valuesArr = value.split('\n');
    } else if (value.includes(',')) {
      valuesArr = value.split(',').map(val => val.trim());
    } else {
      valuesArr = [value];
    }
    const isValid = valuesArr.every(
      val =>
        NextAppointmentsService.validateContainerNumber(val.trim()) ||
        val === ''
    );
    if (!isValid) {
      setContainersInputError('Please enter valid container numbers');
    }
    if (!value || isValid) {
      setContainersInputError('');
    }
    if (valuesArr.length > 20 && value.includes('\n')) {
      removeExcessContainerNumbers(valuesArr.slice(0, 20).join('\n'));
    } else if (valuesArr.length > 20 && value.includes(',')) {
      removeExcessContainerNumbers(valuesArr.slice(0, 20).join(', '));
    } else {
      setContainersInput(value);
    }
  };

  const currentUser = teamMembers.find(row => row.email === currentUserEmail);

  const sortedEmails = [currentUser]
    .concat(teamMembers.sort().filter(row => row.email !== currentUserEmail))
    .filter(x => x);

  return (
    <div className={styles.font}>
      <ModalWrapper
        width={960}
        withoutBackground
        onModalClose={onHandleStopTheClockModalClose}
        zIndexModal={'1203'}
        zIndexBackground={'1202'}
      >
        <div className="w-full hg-full">
          {/* Modal Title */}
          <div className="pt-0 pb-4 w-full flex justify-center items-center cursor-pointer border-b border-blue-500">
            <h3 className="font-medium text-dark">Stop-the-clock</h3>
          </div>

          {/* Modal Content */}
          <div className="px-20 py-4">
            {/* Container Input */}
            <div className="pt-2 pb-4">
              <div className="flex flex-col space-y-2">
                <h4 className="font-medium text-dark">
                  {fixedContainerList
                    ? 'Selected container number:'
                    : 'Enter container numbers:'}
                </h4>
                <textarea
                  ref={inputEl}
                  rows={1}
                  tabIndex="1"
                  className={`px-4 py-2 bg-blue-600 border  ${
                    fixedContainerList
                      ? 'border-blue text-blue font-medium'
                      : 'border-blue-500'
                  } rounded-sm resize-none`}
                  placeholder={
                    'Enter a container number, or a list of container numbers to include then in your Automated Per Diem Report.'
                  }
                  value={fixedContainerList || containersInputValue}
                  onChange={onContainersInputChange}
                  maxLength={280}
                  disabled={fixedContainerList}
                />
                {containersInputError && (
                  <div className="stop-the-clock-modal__error">
                    {containersInputError}
                  </div>
                )}
              </div>
            </div>
            {/* Select Team Members */}
            <div className="pt-2 pb-4">
              <div className="flex flex-col space-y-2">
                <h4 className="font-medium text-dark">
                  Send report to{' '}
                  <span className="font-normal">
                    (select team member email)
                  </span>
                </h4>
                <div className="max-h-28 overflow-scroll flex flex-wrap">
                  {sortedEmails.map(teamMember => {
                    const { email } = teamMember;

                    const setVariant = email => {
                      return selectedTeamMembers.includes(email)
                        ? 'tag-primary'
                        : 'tag-default';
                    };

                    const variant = setVariant(email);

                    const label =
                      email === currentUserEmail ? `${email} (me)` : email;

                    return (
                      <div key={email} className="mr-2 mb-2">
                        <BrandButton
                          variant={variant}
                          label={label}
                          onClick={() => handleTagClick(email)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* Select Download*/}
            <div className="pt-2 pb-4">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    id={'Download Report'}
                    checked={isDownloadReportChecked}
                    onChange={onChange}
                  />
                }
                classes={{
                  label: isDownloadReportChecked
                    ? classes.label
                    : classes.labelNotActive,
                }}
                label="Download Report"
                labelPlacement="end"
              />
            </div>
          </div>
          {/* Modal Buttons */}
          <div className="w-full border-t border-blue-500 pt-4">
            <div className="px-10 flex items-center justify-between">
              <BrandButton
                onClick={onHandleStopTheClockModalClose}
                label="Close"
                variant="outlined"
                disableElevation
              />
              <BrandButton
                onClick={onGetReportClick}
                label="Get Report"
                variant="contained"
                color="secondary"
                disableElevation
                disabled={
                  containersInputError ||
                  (!isDownloadReportChecked && _.isEmpty(selectedTeamMembers))
                }
                endIcon={<KeyboardReturnIcon />}
                type="submit"
              />
            </div>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

StopTheClockModal.propTypes = {
  email: PropTypes.string,
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  teamMembers: PropTypes.array.isRequired,
  getRecipients: PropTypes.func,
  onHandleGetReportClick: PropTypes.func.isRequired,
  onHandleStopTheClockModalClose: PropTypes.func.isRequired,
};

export default StopTheClockModal;
