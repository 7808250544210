import React, { Component } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import AdapterMoment from '@mui/lab/AdapterMoment';
import styles from '../../styles/typography.module.css';
import { brandColors } from '../../brandColors';
import { themeProvider } from '../../themeProvider';
// Services & Utils
import { sortOnName } from '../../services/clients/availability';
import { getWithTokenRefresh } from 'services/clients/axios-with-token';

import moment from 'moment';
import {
  BACKEND_DATE_FORMAT,
  TERMINAL_SCREENSHOTS_TIMERANGE_LIMIT_DAYS,
} from './config';
import {
  getSnapshotUrlsByShippingLine,
  getTimeRangePerDiem,
} from '../../services/clients/backupBank';
import _ from 'lodash';
// Layouts & Components
import { ReactComponent as VesselIcon } from '../../assets/andora-icons/vessel.svg';
import { ReactComponent as ContainerIcon } from '../../assets/andora-icons/container.svg';
import TerminalTabs from '../PerDiemArchive/components/TerminalTabs';
import TimeRangeTerminalTabs from '../PerDiemArchive/components/TimeRangeTerminalTabs';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { SegmentService } from 'services/helpers/segment';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'layouts/Containers/Container';

class TerminalScreenshotsPage extends Component {
  state = {
    initialDataLoaded: false,
    loading: true,
    containerTypes: [],
    shippingLines: [],
    selectedRadioButton: 'specific-date',
    selectedShippingLineValue: '',
    selectedContainerTypeValue: '',
    selectedStartDateValue: '',
    selectedEndDateValue: '',
    selectedStartTimeValue: '',
    selectedEndTimeValue: '',
    timeRangeResults: null,
    snapshotUrls: null,
    terminals: [],
    formMissingValues: true,
  };

  async componentDidMount() {
    const shippingLinesUnsorted = await getWithTokenRefresh(
      '/core/api/v1/erl/shipping_line'
    );
    const shippingLines = sortOnName(shippingLinesUnsorted);
    const containerTypes = await getWithTokenRefresh(
      '/core/api/v1/erl/container_type'
    );
    const terminals = await getWithTokenRefresh('/core/api/v1/erl/port');
    await this.setState({
      containerTypes,
      shippingLines,
      terminals,
      initialDataLoaded: true,
    });
  }

  handleRadioButtonChange = event => {
    this.setState({
      selectedRadioButton: event.target.value,
      selectedEndDateValue: '',
    });
    this.loadTerminalScreenshots();
    if (event.target.value === 'specific-date') {
      SegmentService.trackEvent(
        'Specific Date Selected',
        {
          url: '/terminal-screenshots',
        },
        { integrations: { Slack: false } }
      );
    } else {
      SegmentService.trackEvent(
        'Date Range Selected',
        {
          url: '/terminal-screenshots',
        },
        { integrations: { Slack: false } }
      );
    }
  };

  handleSelectionChange = async (key, value) => {
    await this.setState({ [`selected${key}Value`]: value });

    if (
      this.state.selectedRadioButton === 'specific-date' &&
      this.state.selectedShippingLineValue !== '' &&
      this.state.selectedContainerTypeValue !== '' &&
      this.state.selectedStartDateValue !== '' &&
      this.state.selectedStartTimeValue !== ''
    ) {
      SegmentService.trackEvent(
        'Terminal Screenshots Searched',
        {
          radioButton: this.state.selectedRadioButton,
          shippingLine: this.state.selectedShippingLineValue,
          containerType: this.state.selectedContainerTypeValue,
          startDate: this.state.selectedStartDateValue._d,
          endDate:
            this.state.selectedRadioButton === 'specific-date'
              ? ''
              : this.state.selectedEndDateValue._d,
          startTime: this.state.selectedStartTimeValue,
          endTime:
            this.state.selectedRadioButton === 'specific-date'
              ? ''
              : this.state.selectedEndTimeValue,
          numberOfDays:
            this.state.selectedRadioButton === 'specific-date'
              ? ''
              : getDayDiff({
                  selectedStartDateValue: this.state.selectedStartDateValue,
                  selectedEndDateValue: this.state.selectedEndDateValue,
                }),
        },
        { integrations: { Slack: false } }
      );
    }

    if (
      this.state.selectedRadioButton === 'date-range' &&
      this.state.selectedShippingLineValue !== '' &&
      this.state.selectedContainerTypeValue !== '' &&
      this.state.selectedStartDateValue !== '' &&
      this.state.selectedStartTimeValue !== '' &&
      this.state.selectedEndDateValue !== '' &&
      this.state.selectedEndTimeValue !== ''
    ) {
      SegmentService.trackEvent(
        'Terminal Screenshots Searched',
        {
          radioButton: this.state.selectedRadioButton,
          shippingLine: this.state.selectedShippingLineValue,
          containerType: this.state.selectedContainerTypeValue,
          startDate: this.state.selectedStartDateValue._d,
          endDate:
            this.state.selectedRadioButton === 'specific-date'
              ? ''
              : this.state.selectedEndDateValue._d,
          startTime: this.state.selectedStartTimeValue,
          endTime:
            this.state.selectedRadioButton === 'specific-date'
              ? ''
              : this.state.selectedEndTimeValue,
          numberOfDays:
            this.state.selectedRadioButton === 'specific-date'
              ? ''
              : getDayDiff({
                  selectedStartDateValue: this.state.selectedStartDateValue,
                  selectedEndDateValue: this.state.selectedEndDateValue,
                }),
        },
        { integrations: { Slack: false } }
      );
    }
    this.loadTerminalScreenshots();
  };

  loadTerminalScreenshots = async () => {
    const formMissingValues = isAnyValueUnselected(this.state);
    const dateRangeError = getDateRangeError(this.state);
    const dateRangeRestrictionError = getDateRangeRestrictionError(this.state);

    await this.setState({
      loaded: false,
      snapshotUrls: null,
      timeRangeResults: null,
      formMissingValues,
      dateRangeError,
      dateRangeRestrictionError,
    });

    if (formMissingValues || dateRangeError || dateRangeRestrictionError) {
      this.setState({ loaded: true });
      return;
    }
    const {
      selectedShippingLineValue,
      selectedContainerTypeValue,
      shippingLines,
      containerTypes,
      selectedStartDateValue,
      selectedStartTimeValue,
      selectedEndDateValue,
      selectedEndTimeValue,
      selectedRadioButton,
    } = this.state;

    const selectedShippingLine = shippingLines.find(
      line => line.key === selectedShippingLineValue
    );

    const selectedContainerType = containerTypes.find(
      type => type.key === selectedContainerTypeValue
    );

    if (
      selectedRadioButton !== 'specific-date' &&
      (!selectedEndDateValue || !selectedEndTimeValue)
    ) {
      this.setState({ loaded: true });
      return;
    }

    const startDateString = selectedStartDateValue.format(BACKEND_DATE_FORMAT);
    const endDateString = selectedEndDateValue
      ? selectedEndDateValue.format(BACKEND_DATE_FORMAT)
      : undefined;

    try {
      const snapshotUrls = await getSnapshotUrlsByShippingLine(
        selectedShippingLine.key,
        startDateString,
        selectedStartTimeValue,
        endDateString
      );
      let timeRangeResults = {};
      if (selectedEndDateValue) {
        const startDate = getDateForRequest(
          startDateString,
          selectedStartTimeValue
        );
        const endDate = getDateForRequest(endDateString, selectedEndTimeValue);
        timeRangeResults = await getTimeRangePerDiem({
          shipping_line: selectedShippingLine.key,
          container_type: selectedContainerType.key,
          start_date: startDate,
          end_date: endDate,
        });
      }
      this.setState({ loaded: true, snapshotUrls, timeRangeResults });
    } catch (error) {
      console.error(error);
      this.setState({
        loaded: true,
        snapshotUrls: null,
        timeRangeResults: null,
      });
    }
  };

  render() {
    const {
      containerTypes,
      shippingLines,
      error,
      timeRangeResults,
      snapshotUrls,
      loaded,
      selectedStartTimeValue,
      selectedEndTimeValue,
      terminals,
      selectedShippingLineValue,
      selectedContainerTypeValue,
      selectedStartDateValue,
      selectedEndDateValue,
      initialDataLoaded,
      formMissingValues,
      dateRangeError,
      dateRangeRestrictionError,
      selectedRadioButton,
    } = this.state;

    // TODO: fix the display of this error
    if (error) return 'Error';

    return (
      <ThemeProvider theme={themeProvider}>
        <div className={styles.font}>
          {/* page title */}

          <PageTitle title="Retrieve past terminal instructions screenshots" />
          <Container>
            {initialDataLoaded ? (
              <div>
                <div className="mb-6">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="date-selection"
                      defaultValue="specific-date"
                      name="radio-buttons-group"
                      value={selectedRadioButton}
                      onChange={this.handleRadioButtonChange}
                    >
                      <FormControlLabel
                        className="font-normal pr-6"
                        value="specific-date"
                        control={
                          <Radio
                            sx={{
                              color: brandColors.colors.gray[200],
                              '&.Mui-checked': {
                                color: brandColors.colors.blue.DEFAULT,
                              },
                            }}
                          />
                        }
                        label={
                          <h4
                            className={`font-medium ${
                              selectedRadioButton === 'specific-date'
                                ? 'text-dark'
                                : 'text-gray-200'
                            }`}
                          >
                            Specific Date
                          </h4>
                        }
                      />
                      <FormControlLabel
                        value="date-range"
                        control={
                          <Radio
                            sx={{
                              color: brandColors.colors.gray[200],
                              '&.Mui-checked': {
                                color: brandColors.colors.blue.DEFAULT,
                              },
                            }}
                          />
                        }
                        label={
                          <h4
                            className={`font-medium ${
                              selectedRadioButton === 'date-range'
                                ? 'text-dark'
                                : 'text-gray-200'
                            }`}
                          >
                            Date Range
                          </h4>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {/* user selection */}
                <div className="flex flex-col space-y-8 mb-10">
                  {/* shipping line and container type */}
                  <div className="flex space-x-8">
                    <div className="flex flex-col space-y-2">
                      <div className="flex space-x-2 items-center">
                        <VesselIcon className="w-4 h-4" />
                        <h4 className="text-dark font-medium">Shipping Line</h4>
                      </div>

                      {/* Shipping line dropdown */}
                      <FormControl sx={{ minWidth: 350 }}>
                        <Select
                          displayEmpty
                          onChange={event =>
                            this.handleSelectionChange(
                              'ShippingLine',
                              event.target.value
                            )
                          }
                          value={selectedShippingLineValue}
                        >
                          <MenuItem value="" disabled>
                            <p className="text-gray-300">
                              Select a shipping line
                            </p>
                          </MenuItem>

                          {shippingLines.map(listRow => {
                            return (
                              <MenuItem key={listRow.key} value={listRow.key}>
                                {listRow.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex space-x-2 items-center">
                        <ContainerIcon className="w-4 h-4" />
                        <h4 className="text-dark font-medium">
                          Container Type
                        </h4>
                      </div>

                      <FormControl sx={{ minWidth: 350 }}>
                        <Select
                          displayEmpty
                          onChange={event =>
                            this.handleSelectionChange(
                              'ContainerType',
                              event.target.value
                            )
                          }
                          value={selectedContainerTypeValue}
                        >
                          <MenuItem value="" disabled>
                            <p className="text-gray-300">
                              Select a container type
                            </p>
                          </MenuItem>
                          {containerTypes.map(listRow => {
                            return (
                              <MenuItem key={listRow.key} value={listRow.key}>
                                {listRow.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {/* date and time */}
                  <div className="flex space-x-8">
                    <div className="flex flex-col space-y-2">
                      <h4 className="text-dark font-medium">
                        {this.state.selectedRadioButton === 'specific-date' &&
                          'Date'}
                        {this.state.selectedRadioButton === 'date-range' &&
                          'Start Date'}
                      </h4>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                          inputFormat="MM/DD/yyyy"
                          error={error}
                          maxDate={moment()}
                          value={selectedStartDateValue}
                          onChange={value =>
                            this.handleSelectionChange('StartDate', value)
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              inputProps={{
                                style: { fontSize: 16 },
                                ...params.inputProps,
                              }}
                              sx={{
                                minWidth: 350,
                                '& input::placeholder': {
                                  fontSize: '16px',
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <h4 className="text-dark font-medium">Time</h4>
                      <TextField
                        id="startTime"
                        type="time"
                        value={selectedStartTimeValue}
                        onChange={event =>
                          this.handleSelectionChange(
                            'StartTime',
                            event.target.value
                          )
                        }
                        inputProps={{
                          style: {
                            fontSize: 16,
                          },
                        }} // font size of input text
                        sx={{
                          width: `${
                            this.state.selectedRadioButton === 'date-range'
                              ? '150px'
                              : '350px'
                          }`,
                          '& .MuiOutlinedInput-root': {
                            minHeight: '58px',
                          },
                        }}
                      />
                    </div>
                    {/* conditional end date */}
                    {this.state.selectedRadioButton === 'date-range' && (
                      <>
                        <div className="flex flex-col space-y-2">
                          <h4 className="text-dark font-medium">End Date</h4>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                              maxDate={moment()}
                              inputFormat="MM/DD/yyyy"
                              value={selectedEndDateValue}
                              onChange={value =>
                                this.handleSelectionChange('EndDate', value)
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    style: { fontSize: 16 },
                                    ...params.inputProps,
                                  }}
                                  sx={{
                                    minWidth: 350,
                                    '& input::placeholder': {
                                      fontSize: '16px',
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          {dateRangeError && (
                            <Alert severity="warning">
                              Start date must be before end date
                            </Alert>
                          )}
                          {dateRangeRestrictionError && (
                            <Alert severity="warning">
                              Time range cannot be greater than{' '}
                              {TERMINAL_SCREENSHOTS_TIMERANGE_LIMIT_DAYS} days
                            </Alert>
                          )}
                        </div>
                        <div className="flex flex-col space-y-2">
                          <h4 className="text-dark font-medium">Time</h4>
                          <TextField
                            id="endTime"
                            type="time"
                            value={selectedEndTimeValue}
                            onChange={event =>
                              this.handleSelectionChange(
                                'EndTime',
                                event.target.value
                              )
                            }
                            inputProps={{
                              style: {
                                fontSize: 16,
                              },
                            }} // font size of input text
                            sx={{
                              width: `${
                                this.state.selectedRadioButton === 'date-range'
                                  ? '150px'
                                  : '350px'
                              }`,
                              '& .MuiOutlinedInput-root': {
                                minHeight: '58px',
                              },
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {formMissingValues ? (
                  ''
                ) : loaded ? (
                  !timeRangeResults || !snapshotUrls ? (
                    'Could not find results for selection'
                  ) : this.state.selectedRadioButton === 'date-range' ? (
                    <TimeRangeTerminalTabs
                      perDiem={_.groupBy(timeRangeResults, 'port')}
                      snapshotUrls={snapshotUrls}
                      ports={terminals}
                      shippingLines={shippingLines}
                      containerTypes={containerTypes}
                    />
                  ) : (
                    <TerminalTabs
                      ports={terminals}
                      snapshotUrls={snapshotUrls}
                    />
                  )
                ) : (
                  <div className="h-full w-full bg-white flex justify-center py-12">
                    <LoadingSpinner />
                  </div>
                )}
              </div>
            ) : (
              <div className="h-full w-full bg-white flex justify-center py-12">
                <LoadingSpinner />
              </div>
            )}
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

function getDateForRequest(dateString, timeString) {
  const momentDate = moment(
    `${dateString} ${timeString}`,
    'YYYY-MM-DD HH:mm',
    true
  );
  if (!momentDate.isValid()) {
    throw new Error(`Invalid date inputs ${dateString} ${timeString}`);
  }

  return momentDate.format();
}

function isAnyValueUnselected(state) {
  const {
    selectedRadioButton,
    selectedShippingLineValue,
    selectedContainerTypeValue,
    selectedStartDateValue,
    selectedEndDateValue,
    selectedStartTimeValue,
    selectedEndTimeValue,
  } = state;

  const specificDateHasUnselected =
    !selectedShippingLineValue ||
    !selectedContainerTypeValue ||
    !selectedStartDateValue ||
    !selectedStartTimeValue;

  if (selectedRadioButton === 'specific-date') return specificDateHasUnselected;

  return (
    specificDateHasUnselected || !selectedEndDateValue || !selectedEndTimeValue
  );
}

function getDateRangeError({ selectedStartDateValue, selectedEndDateValue }) {
  return (
    selectedStartDateValue &&
    selectedEndDateValue &&
    selectedStartDateValue.isAfter(selectedEndDateValue)
  );
}

function getDayDiff({ selectedStartDateValue, selectedEndDateValue }) {
  if (!selectedStartDateValue || !selectedEndDateValue) return;

  return selectedEndDateValue.diff(selectedStartDateValue, 'days');
}

function getDateRangeRestrictionError({
  selectedStartDateValue,
  selectedEndDateValue,
}) {
  if (!selectedStartDateValue || !selectedEndDateValue) return;

  return (
    getDayDiff({ selectedStartDateValue, selectedEndDateValue }) >
    TERMINAL_SCREENSHOTS_TIMERANGE_LIMIT_DAYS
  );
}

export default TerminalScreenshotsPage;
