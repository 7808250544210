import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  getFilteredContainerTypes,
  getFilteredShippingLine,
  setShippingLines,
  setContainerTypes,
  setPorts,
  extractContainerTypesFromURI,
  extractShippingLinesFromURI,
} from 'ducks/erlData';
import { globalLoaderSelector } from 'ducks/loader';
import { isLogged } from 'ducks/user';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Terminals from './Terminals';
import styles from './Terminals.style';
import { useLocation } from 'react-router-dom';
import { useUserContext } from 'common/contexts/UserContext';

const mapStateToProps = (state, ownProps) => ({
  isLoading: globalLoaderSelector(state),
  containerTypes: getFilteredContainerTypes(state),
  shippingLines: getFilteredShippingLine(state),
  freeContainerTypes: extractContainerTypesFromURI(state, ownProps),
  freeShippingLines: extractShippingLinesFromURI(state, ownProps),
  isLogged: isLogged(state),
  refreshToken: state.refreshToken,
});

const mapDispatchToProps = dispatch => ({
  importData: () => {
    dispatch(setPorts());
    dispatch(setShippingLines());
    dispatch(setContainerTypes());
  },
});

export const withHooksHOC = Component => {
  return props => {
    const location = useLocation();
    const { user } = useUserContext();

    return <Component location={location} user={user} {...props} />;
  };
};

export default compose(
  withHooksHOC,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Terminals);
