export const timeList = [
  {
    key: '00:00',
    name: '12:00AM',
  },
  {
    key: '01:00',
    name: '01:00AM',
  },
  {
    key: '02:00',
    name: '02:00AM',
  },
  {
    key: '03:00',
    name: '03:00AM',
  },
  {
    key: '04:00',
    name: '04:00AM',
  },
  {
    key: '05:00',
    name: '05:00AM',
  },
  {
    key: '06:00',
    name: '06:00AM',
  },
  {
    key: '07:00',
    name: '07:00AM',
  },
  {
    key: '08:00',
    name: '08:00AM',
  },
  {
    key: '09:00',
    name: '09:00AM',
  },
  {
    key: '10:00',
    name: '10:00AM',
  },
  {
    key: '11:00',
    name: '11:00AM',
  },
  {
    key: '12:00',
    name: '12:00PM',
  },
  {
    key: '13:00',
    name: '01:00PM',
  },
  {
    key: '14:00',
    name: '02:00PM',
  },
  {
    key: '15:00',
    name: '03:00PM',
  },
  {
    key: '16:00',
    name: '04:00PM',
  },
  {
    key: '17:00',
    name: '05:00PM',
  },
  {
    key: '18:00',
    name: '06:00PM',
  },
  {
    key: '19:00',
    name: '07:00PM',
  },
  {
    key: '20:00',
    name: '08:00PM',
  },
  {
    key: '21:00',
    name: '09:00PM',
  },
  {
    key: '22:00',
    name: '10:00PM',
  },
  {
    key: '23:00',
    name: '11:00PM',
  },
];
