import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/customDesign/Button';
import queryString from 'query-string';
import Autocomplete from 'components/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { SegmentService } from 'services/helpers/segment';

export default class SingleContainerTypePicker extends Component {
  static propTypes = {
    prop: PropTypes.any,
    classes: PropTypes.object.isRequired,
    defaultValue: PropTypes.shape({
      shippingLine: PropTypes.array,
      containerType: PropTypes.array,
    }),
    importData: PropTypes.func.isRequired,
    shippingLines: PropTypes.array.isRequired,
    containerTypes: PropTypes.array.isRequired,
    isLoadingShippingLines: PropTypes.bool.isRequired,
    isLoadingContainerTypes: PropTypes.bool.isRequired,
    navigate: PropTypes.func.isRequired,
    destination: PropTypes.string.isRequired,
  };
  static defaultProps = {
    defaultValue: {
      shippingLine: [],
      containerType: [],
    },
    destination: '/free-table',
  };

  state = {
    shippingLine: null,
    containerType: null,
    errors: {
      shippingLine: false,
      containerType: false,
    },
  };
  componentDidUpdate = prevProps => {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        shippingLine: {
          value: this.props.defaultValue.shippingLine.key,
          label: this.props.defaultValue.shippingLine.name,
        },
        containerType: {
          value: this.props.defaultValue.containerType.key,
          label: this.props.defaultValue.containerType.name,
        },
      });
    }
  };

  componentDidMount = () => {
    this.props.importData();
  };

  handleChange = name => value => {
    this.setState({ [name]: value, errors: { [name]: false } });
  };

  handleSubmit = event => {
    event.preventDefault();

    let newErrorState = {};
    if (!this.state.shippingLine) newErrorState.shippingLine = true;
    if (!this.state.containerType) newErrorState.containerType = true;

    this.setState({ errors: newErrorState });

    if (Object.keys(newErrorState).length === 0) {
      const query = {
        'shipping-line': this.state.shippingLine.value,
        'container-type': this.state.containerType.value,
      };
      SegmentService.trackEvent(
        'Free ERL Table Searched',
        {
          shippingLine: query['shipping-line'],
          containerType: query['container-type'],
        },
        { integrations: { Slack: false } }
      );
      this.props.navigate({
        pathname: this.props.destination,
        search: queryString.stringify(query),
      });
    }
  };

  render() {
    const {
      classes,
      shippingLines,
      containerTypes,
      isLoadingShippingLines,
      isLoadingContainerTypes,
    } = this.props;
    const isLoading = isLoadingShippingLines || isLoadingContainerTypes;
    const possibleValuesShippingLines = shippingLines.map(line => ({
      value: line.key,
      label: line.name,
    }));
    const possibleValuesContainerTypes = containerTypes.map(containerType => ({
      value: containerType.key,
      label: containerType.name,
    }));

    return (
      <form
        autoComplete="off"
        className={classes.root}
        onSubmit={this.handleSubmit}
      >
        <div className={classes.inputs}>
          <Autocomplete
            placeholder="Pick a shipping line"
            possibleValuesWithKey={possibleValuesShippingLines}
            value={this.state.shippingLine}
            onChange={this.handleChange('shippingLine')}
          />
          <Autocomplete
            placeholder="Pick a container type"
            possibleValuesWithKey={possibleValuesContainerTypes}
            value={this.state.containerType}
            onChange={this.handleChange('containerType')}
          />
          <Button
            type="submit"
            disabled={isLoading}
            classes={{ root: classes.button }}
            icon={<SearchIcon className={classes.buttonIcon} />}
          >
            Search
          </Button>
        </div>
      </form>
    );
  }
}
