import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TerminalCellContent from 'components/TerminalCellContent';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import CellStatus from 'pages/Terminals/components/CellStatus';
import { extractTerminal } from 'services/helpers/data';

export default class Table extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    ports: PropTypes.array.isRequired,
    getAvailabilities: PropTypes.func.isRequired,
    containerType: PropTypes.object.isRequired,
    shippingLine: PropTypes.object.isRequired,
    availabilities: PropTypes.object.isRequired,
    selectedShift: PropTypes.object.isRequired,
    functionalParsers: PropTypes.array.isRequired,
  };
  componentDidUpdate = async prevProps => {
    if (
      !isEqual(
        sortBy(prevProps.containerType),
        sortBy(this.props.containerType)
      ) ||
      !isEqual(sortBy(prevProps.shippingLine), sortBy(this.props.shippingLine))
    )
      this.props.getAvailabilities();
  };

  componentDidMount = async () => {
    this.props.getAvailabilities();
  };

  render() {
    const {
      classes,
      ports,
      availabilities,
      shippingLine,
      containerType,
      selectedShift,
      functionalParsers,
    } = this.props;
    const activeTerminals = functionalParsers.map(t => t.port);

    return (
      <Paper className={classes.root}>
        <MuiTable className={classes.table}>
          <TableBody>
            {ports.map(port => {
              const isBroken =
                activeTerminals.length && !activeTerminals.includes(port.key);
              return (
                <TableRow key={port.key}>
                  <TableCell
                    align="center"
                    className={classes.terminalCell}
                    padding="none"
                  >
                    <TerminalCellContent
                      terminal={port}
                      lrdData={functionalParsers}
                    />
                  </TableCell>
                  <CellStatus
                    shippingLine={shippingLine}
                    terminal={port}
                    currentTerminal={extractTerminal(
                      availabilities,
                      shippingLine,
                      containerType,
                      port
                    )}
                    isBroken={Boolean(isBroken)}
                    layout={selectedShift}
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </Paper>
    );
  }
}
