import React, { useState } from 'react';
import EditUserRightDialog from '../EditUserRightDialog';
import InviteDialog from '../InviteDialog';
import UserAvatar from '../UserAvatar';
import Member from './Member';
import PendingMember from './PendingMember';
import PlanText from './PlanText';

import orderBy from 'lodash/orderBy';

const CompanyMembers = ({
  members,
  company,
  admin,
  pendingMembers,
  refreshPending,
  isOpen,
  handleClose,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const openDialog = member => {
    setSelectedMember(member);
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedMember(null);
  };
  const orderedMembers = orderBy(
    members,
    ['is_admin', 'first_name', 'last_name'],
    ['desc', 'asc', 'asc']
  );

  if (!company) return <div />;

  return (
    <div>
      <EditUserRightDialog
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
        selectedMember={selectedMember}
      />
      <div className="w-full p-4 flex items-center justify-between hover:bg-blue-600">
        <div className="flex space-x-4 items-center">
          <UserAvatar
            size="small"
            letters={company.name.substring(0, 2).toUpperCase()}
          />
          <div className="flex flex-col space-y-1">
            <h4 className="uppercase text-dark">{company.scac}</h4>
            <PlanText pack={company.pack} trialEnd={company.trial_end} />
          </div>
        </div>
      </div>
      <hr className="w-full bg-blue-400 border-0.5 border-blue-400" />

      <ul className="flex flex-col">
        {orderedMembers.map(member => (
          <li key={member.email}>
            <Member member={member} admin={admin} onClick={openDialog} />
            <hr className="w-full bg-blue-400 border-0.5 border-blue-400" />
          </li>
        ))}
        {pendingMembers.map(member => (
          <PendingMember
            key={member}
            member={member}
            refreshPending={refreshPending}
          />
        ))}
      </ul>
      <InviteDialog
        refreshPending={refreshPending}
        isAdmin={admin}
        isOpen={isOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default CompanyMembers;
