import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { setPorts } from 'ducks/erlData';
import {
  getAvailabilityCredentials,
  setClearAvailabilityCredentials,
} from 'ducks/availabilityCredentials';
import { setError } from 'ducks/error';

import Credentials from './Credentials';
import styles from './Credentials.style';
import { useNavigate } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => {
  const { erlData, availabilityCredentials } = state;
  const { ports } = erlData || {};
  return {
    availabilityCredentials,
    terminals: ports,
    user: state.user,
    refreshToken: state.refreshToken,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearAvailabilityCredentials: () => {
    dispatch(setClearAvailabilityCredentials());
  },

  getAppointmentsAvalabilityCredentials: cb => {
    dispatch(getAvailabilityCredentials(cb));
  },

  importData: () => {
    dispatch(setPorts());
  },

  setNetworkError: error => {
    const { response } = error || {};
    const { data } = response || {};
    const message = data || 'Network error';
    dispatch(setError(message, error));
  },
});

const withHooksHOC = Component => {
  return props => {
    const navigate = useNavigate();

    return <Component navigate={navigate} {...props} />;
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withHooksHOC
)(Credentials);
