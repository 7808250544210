import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { getCustomerPortal } from 'services/clients/subscription';
import { SegmentService } from 'services/helpers/segment';

const InvoiceHistoryPage = () => {
  const [customerPortalUrl, setCustomerPortalUrl] = useState();

  useEffect(async () => {
    const data = await getCustomerPortal();
    setCustomerPortalUrl(data.customerPortalUrl);
  });
  return (
    <div className="flex flex-col space-y-8 items-start">
      <div className="flex flex-col space-y-2">
        <h1 style={{ fontSize: '36px' }} className="font-bold">
          Access your customer portal
        </h1>
      </div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={!customerPortalUrl}
        onClick={() => {
          window.open(customerPortalUrl);
          SegmentService.trackEvent(
            `Customer Portal Clicked`,
            {},
            { integrations: { Slack: false } }
          );
        }}
      >
        <p className="font-bold">Customer Portal</p>
      </Button>
    </div>
  );
};

export default InvoiceHistoryPage;
