import React, { useEffect, useState } from 'react';
import { validateCoupon } from 'services/clients/subscription';
import Input from '@mui/material/Input';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';

const CouponInput = ({
  onCouponCode,
  selectedPack,
}: {
  onCouponCode: (couponCode: string | null) => {};
  selectedPack: any;
}) => {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [couponErrorMsg, setCouponErrorMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string | null>(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      verifyCoupon();
    }, 400);

    return () => clearTimeout(delayDebounceFn);
  }, [couponCode]);

  useEffect(() => {
    onCouponCode(isValid ? couponCode : null);
  }, [isValid]);

  const verifyCoupon = async () => {
    if (_.isNil(couponCode)) {
      setIsValid(null);
      return;
    }
    setIsLoading(true);
    try {
      await validateCoupon(selectedPack.id, couponCode);
      setIsValid(true);
    } catch ({ response }) {
      setCouponErrorMsg(response.data.message);
      setIsValid(false);
    }
    setIsLoading(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue === '') {
      setCouponCode(null);
    } else if (inputValue) {
      setCouponCode(inputValue);
    }
  };

  let endAdornment = null;
  if (couponCode && isValid) {
    endAdornment = <CheckIcon sx={{ color: 'darkgreen' }} />;
  } else if (couponCode && isValid === false) {
    endAdornment = <CloseIcon sx={{ color: 'darkred' }} />;
  } else if (isLoading) {
    endAdornment = <CircularProgress size={12} />;
  }

  return (
    <Accordion
      disableGutters
      elevation={0}
      defaultExpanded={false}
      sx={[
        () => ({
          '&:before': {
            backgroundColor: 'white',
          },
          background: 'none',
        }),
      ]}
    >
      <AccordionSummary
        // expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          margin: 0,
          padding: 0,
          minHeight: '0px',
          '& .MuiAccordionSummary-content': {
            margin: '0px',
          },
        }}
      >
        <div className="flex space-x-1 items-center mb-2">
          <h4 className="text-gray-200 font-regular text-center ">
            Coupon code
          </h4>
          <ExpandMoreIcon
            style={{ fontSize: '16px' }}
            className="text-gray-200"
          />
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ margin: 0, padding: 0 }}>
        <Input
          placeholder="Enter coupon here"
          onChange={handleInputChange}
          classes={{
            root:
              'px-4 border border-blue-400 rounded-sm focus:outline-none focus:border focus:border-blue',
          }}
          sx={{
            input: {
              height: '34px',
            },
          }}
          endAdornment={endAdornment}
          disableUnderline
        />
        {couponCode && isValid === false && (
          <h5 className="font-bold pl-4 text-red mt-0.25">{couponErrorMsg}</h5>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CouponInput;
