import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InformationPage from '../../components/InformationPage';

export default class PrivacyPolicy extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  scrollToYourChoices = () => {
    const targetElement = document.getElementById('captureYourChoice');
    targetElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  render() {
    const { classes } = this.props;
    return (
      <InformationPage>
        <Paper className={classes.paper}>
          <div className={classes.title}>
            <div
              style={{
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              BlueCargo
            </div>
            <Typography variant="h3" align="left">
              Privacy Policy
            </Typography>
            <Typography gutterBottom variant="caption" align="left">
              Last Revised: November 3, 2020
            </Typography>
          </div>
          <div className={classes.scrollable}>
            <Typography variant="body2" paragraph align="left">
              This Privacy Policy explains how BlueCargo, Inc. collects, uses,
              and discloses information about you. This Privacy Policy applies
              to our online empty return location platform, including any
              application programming interfaces ("<b>APIs</b>") (collectively,
              the "<b>Service</b>") that we provide through our website located
              at{' '}
              <a
                href="https://erl.bluecargo.io/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#0000EE' }}
              >
                https://erl.bluecargo.io
              </a>{' '}
              and our mobile application
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We may change this Privacy Policy from time to time. If we make
              changes, we will notify you by revising the date at the top of
              this policy and, in some cases, we may provide you with additional
              notice (such as adding a statement to the Service or sending you a
              notification). We encourage you to review this Privacy Policy
              regularly to stay informed about our information practices and the
              choices available to you.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              COLLECTION OF INFORMATION
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>Information You Provide to Us</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We collect information you provide directly to us. For example, we
              collect information when you create an account, subscribe to the
              Service, make a purchase, apply for a job, request customer
              support, or otherwise communicate with us. The types of
              information we may collect include your name, email address,
              postal address, phone number, credit card and other payment
              information, your terminal operator credentials (e.g., user name,
              email address, and password) ("<b>Industry Credentials</b>"), and
              any other information you choose to provide.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>
                Information We Collect Automatically When You Interact with Us
              </b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              When you access or use the Service, we automatically collect
              certain information, including:
            </Typography>
            <div style={{ marginBottom: '16px', textAlign: 'left' }}>
              <ul>
                <li style={{ marginBottom: '10px' }}>
                  <b>Transactional Information:</b> When you make a purchase, we
                  collect information about the transaction, such as Service
                  details, purchase price, and date and location of the
                  transaction.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <b>Device and Usage Information:</b> We collect information
                  about how you access the Service, including data about the
                  device and network you use, such as your hardware model,
                  operating system version, mobile network, IP address, unique
                  device identifiers, browser type, and app version. We also
                  collect information about your activity on the Service, such
                  as access times, pages viewed, links clicked, and the page you
                  visited before navigating to the Service.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  <b>
                    Information Collected by Cookies and Similar Tracking
                    Technologies:
                  </b>{' '}
                  We (and our service providers) use tracking technologies, such
                  as cookies and web beacons, to collect information about you.
                  Cookies are small data files stored on your hard drive or in
                  device memory that help us improve the Service and your
                  experience, see which areas and features of the Service are
                  popular, and count visits. Web beacons (also known as "pixel
                  tags" or "clear GIFs") are electronic images that we use on
                  the Service and in our emails to help deliver cookies, count
                  visits, and understand usage and campaign effectiveness. For
                  more information about cookies and how to disable them, see
                  the{' '}
                  <span
                    style={{ color: '#5acae0', cursor: 'pointer' }}
                    onClick={this.scrollToYourChoices}
                  >
                    Your Choices
                  </span>{' '}
                  section below.
                </li>
              </ul>
            </div>
            <Typography variant="body2" paragraph align="left">
              <b>Information We Collect from Other Sources</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We obtain information from third-party sources. For example, we
              may collect information about you from advertising networks, data
              analytics providers, and mailing list providers.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>Information We Derive</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We may derive information or draw inferences about you based on
              the information we collect. For example, we may make inferences
              about your location based on your IP address.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              USE OF INFORMATION
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We use the information we collect to:
            </Typography>
            <div style={{ marginBottom: '16px', textAlign: 'left' }}>
              <ul>
                <li style={{ marginBottom: '10px' }}>
                  Provide, maintain, and improve the Service (including, without
                  limitation, using your Industry Credentials to log into
                  third-party terminal operator websites or platforms);
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Personalize and improve your experience on the Service;
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Send you technical notices, security alerts, and support and
                  administrative messages;
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Respond to your comments and questions and provide customer
                  service;
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Communicate with you about products, services, and events
                  offered by BlueCargo and others and provide news and
                  information that we think will interest you (see the{' '}
                  <span
                    style={{ color: '#5acae0', cursor: 'pointer' }}
                    onClick={this.scrollToYourChoices}
                  >
                    Your Choices
                  </span>{' '}
                  section below for information about how to opt out of these
                  communications at any time);
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Monitor and analyze trends, usage, and activities in
                  connection with the Service;
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Detect, investigate, and prevent security incidents and other
                  malicious, deceptive, fraudulent, or illegal activity and
                  protect the rights and property of BlueCargo and others;
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Debug to identify and repair errors on the Service;
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Comply with our legal and financial obligations; and
                </li>
                <li style={{ marginBottom: '10px' }}>
                  Carry out any other purpose described to you at the time the
                  information was collected.
                </li>
              </ul>
            </div>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              SHARING OF INFORMATION
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We share personal information in the following circumstances or as
              otherwise described in this Privacy Policy:
            </Typography>
            <div style={{ marginBottom: '16px', textAlign: 'left' }}>
              <ul>
                <li style={{ marginBottom: '10px' }}>
                  We share personal information with vendors, service providers,
                  and consultants that need access to personal information in
                  order to perform services for us, such as companies that
                  assist us with web hosting, fraud prevention, customer
                  service, and marketing and advertising.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  We may disclose personal information if we believe that
                  disclosure is in accordance with, or required by, any
                  applicable law or legal process, including lawful requests by
                  public authorities to meet national security or law
                  enforcement requirements.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  We may share personal information if we believe that your
                  actions are inconsistent with our user agreements or policies,
                  if we believe that you have violated the law, or if we believe
                  it is necessary to protect the rights, property, and safety of
                  BlueCargo, our users, the public, or others.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  We share personal information with our lawyers and other
                  professional advisors where necessary to obtain advice or
                  otherwise protect and manage our business interests.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  We may share personal information in connection with, or
                  during negotiations concerning, any merger, sale of company
                  assets, financing, or acquisition of all or a portion of our
                  business by another company.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  We share personal information with your consent or at your
                  direction.
                </li>
              </ul>
            </div>
            <Typography variant="body2" paragraph align="left">
              We also share aggregated or de-identified information that cannot
              reasonably be used to identify you.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              ANALYTICS
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We allow others to provide analytics services on our behalf across
              the web and in mobile apps. These entities may use cookies, web
              beacons, device identifiers, and other technologies to collect
              information about your use of the Service and other websites and
              applications, including your IP address, web browser, mobile
              network information, pages viewed, time spent on pages or in
              mobile apps, links clicked, and conversion information. This
              information may be used by BlueCargo and others to, among other
              things, analyze and track data, determine the popularity of
              certain content, and better understand your online activity.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              DATA RETENTION
            </Typography>
            <Typography variant="body2" paragraph align="left">
              We store the information we collect on you for as long as is
              necessary for the purpose(s) for which we originally collected it,
              or for other legitimate business purposes, including to meet our
              legal, regulatory, or other compliance obligations.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES
            </Typography>
            <Typography variant="body2" paragraph align="left">
              BlueCargo is headquartered in the United States, and we have
              operations and service providers in the United States. Therefore,
              we and our service providers may transfer your personal
              information to, or store or access it in, jurisdictions that may
              not provide levels of data protection that are equivalent to those
              of your home jurisdiction. We will take steps to ensure that your
              personal information receives an adequate level of protection in
              the jurisdictions in which we process it.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
              id="captureYourChoice"
            >
              YOUR CHOICES
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>Account Information</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You may update and correct certain account information you provide
              to us at any time by logging into your account. If you wish to
              delete or deactivate your account, please email us at
              contact@bluecargo.io, but note that we may retain certain
              information as required by law or for legitimate business
              purposes. We may also retain cached or archived copies of
              information about you for a certain period of time.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>Cookies</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              Most web browsers are set to accept cookies by default. If you
              prefer, you can usually adjust your browser settings to remove or
              reject browser cookies. Please note that removing or rejecting
              cookies could affect the availability and functionality of the
              Service.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>Communications Preferences</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You may opt out of receiving promotional emails and text messages
              from BlueCargo by following the instructions in those
              communications. If you opt out, we may still send you
              non-promotional emails, such as those about your account or our
              ongoing business relations.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>Mobile Push Notifications/Alerts</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              With your consent, we may send promotional and non-promotional
              push notifications or alerts to your mobile device. You can
              deactivate these messages at any time by changing the notification
              settings on your mobile device.
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              ADDITIONAL DISCLOSURES FOR INDIVIDUALS IN EUROPE
            </Typography>
            <Typography variant="body2" paragraph align="left">
              If you are located in the European Economic Area or Switzerland,
              you have certain rights and protections under the law regarding
              the processing of your personal data.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>Legal Basis for Processing</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              When we process your personal data, we will only do so in the
              following situations:
            </Typography>
            <div style={{ marginBottom: '16px', textAlign: 'left' }}>
              <ul>
                <li style={{ marginBottom: '10px' }}>
                  We need to use your personal data to perform our
                  responsibilities under our contract with you (e.g., processing
                  payments for and providing the products or services you have
                  requested).
                </li>
                <li style={{ marginBottom: '10px' }}>
                  We have a legitimate interest in processing your personal
                  data. For example, we may process your personal data to send
                  you marketing communications, to communicate with you about
                  changes to our products and Service, and to provide, secure,
                  and improve our products and the Service.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  We find such processing is necessary to comply with our legal
                  obligations.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  We have your consent to do so. When consent is the legal basis
                  for our processing, you may withdraw such consent at any time.
                </li>
              </ul>
            </div>
            <Typography variant="body2" paragraph align="left">
              <b>Data Subject Requests</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              You have the right to access personal data we hold about you and
              to ask that your personal data be corrected, erased, or
              transferred. You may also have the right to object to, or request
              that we restrict, certain processing. If you would like to
              exercise any of these rights, you can log into your account.
            </Typography>
            <Typography variant="body2" paragraph align="left">
              <b>Questions or Complaints</b>
            </Typography>
            <Typography variant="body2" paragraph align="left">
              If you have a concern about our processing of personal data that
              we are not able to resolve, you have the right to lodge a
              complaint with the data privacy authority where you reside. For
              contact details of your Data Protection Authority, please see:{' '}
              <a
                href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#0000EE' }}
              >
                https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
              </a>
              , or for Swiss residents, see:{' '}
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#0000EE' }}
              >
                https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html
              </a>
              .
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              className={classes.subtitle}
              align="left"
            >
              CONTACT US
            </Typography>
            <Typography variant="body2" paragraph align="left">
              If you have any questions about this Privacy Policy, please
              contact us at contact@bluecargo.io.
            </Typography>
          </div>
        </Paper>
      </InformationPage>
    );
  }
}
