const basicPopoverBlock = {
  fontSize: '12px',
  display: 'flex',
  alignItems: 'end',
  marginBottom: '10px',
  wordWrap: 'break-word',
};

export default theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
    paddingTop: '15px',
    paddingRight: '15px',
  },
  popoverBlock: {
    ...basicPopoverBlock,
  },
  popoverBlockTimeSlot: {
    ...basicPopoverBlock,
    marginBottom: '-4px',
  },
  popoverText: {
    maxWidth: '110px',
    marginBottom: '5px',
  },
  popoverTextBold: {
    maxWidth: '110px',
    fontWeight: 'bold',
  },
  popoverIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '8px',
  },
  calendar: {
    color: '#35475D',
  },
  green: {
    color: theme.palette.green.main,
  },
  red: {
    color: theme.palette.alert.main,
  },
  yellow: {
    color: theme.palette.secondary.main,
  },
  blue: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  grey: {
    color: theme.palette.grey[500],
  },
});
