import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import css from './ptscSchedule.module.css';
import { upsertPtscSchedule } from '../../../../ducks/admin';

const TableItem = props => {
  const { shiftIndex, currentValue, container } = props;

  const [editMode, setEditMode] = useState(false);
  const [rowValue, setRowVale] = useState();

  useEffect(() => {
    setRowVale(currentValue);
  }, [currentValue, rowValue]);

  const onChange = newValue => {
    props.upsertPtscSchedule(container, shiftIndex, newValue);
    setRowVale(newValue);
    setEditMode(false);
  };

  const onClick = () => {
    setEditMode(true);
  };

  return (
    <td onClick={onClick} className={css.tableItemEditRow}>
      {editMode ? (
        <RowEditorContainer
          onChange={onChange}
          rowValue={currentValue}
          availableStatuses={props.availableStatuses}
          setRowVale={setRowVale}
        />
      ) : (
        <div>{rowValue}</div>
      )}
    </td>
  );
};

const RowEditorContainer = props => {
  const { availableStatuses } = props;

  return (
    <div>
      <select
        onChange={e => props.onChange(e.target.value)}
        value={props.rowValue}
        className={css.selectItemEdit}
      >
        {availableStatuses.map((status, index) => (
          <option
            key={index}
            value={status}
            className={[props.rowValue === status && css.optionItemEdit]}
          >
            {status}
          </option>
        ))}
      </select>
    </div>
  );
};

TableItem.propTypes = {
  shiftIndex: PropTypes.number,
  currentValue: PropTypes.string,
  container: PropTypes.array,
  upsertPtscSchedule: PropTypes.func,
  availableStatuses: PropTypes.array,
};

RowEditorContainer.propTypes = {
  availableStatuses: PropTypes.array,
  onChange: PropTypes.func,
  rowValue: PropTypes.string,
};

const mapStateToProps = state => ({
  availableStatuses: state.admin.ptscSchedule.availableStatuses,
});

const mapDispatchToProps = {
  upsertPtscSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableItem);
