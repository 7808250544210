import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeProvider } from '../../themeProvider';
import styles from '../../styles/typography.module.css';
import UpgradeYourPlan from './UpgradeYourPlan';
import { pageTitlesAndUrls } from '../../constants/pages';
import { SegmentService } from '../../services/helpers/segment';
import { useNavigate } from 'react-router-dom';

class UpgradeYourPlanPage extends React.Component {
  state = { subtitle: '', descriptionTitle: '', bullets: [] };

  componentDidMount() {
    this.setPage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.redirectedFromPage !== this.props.redirectedFromPage) {
      this.setPage();
    }
  }

  setPage = () => {
    const { redirectedFromPage } = this.props;
    SegmentService.trackEvent(
      'missing-permission',
      {
        onPage: redirectedFromPage,
      },
      { integrations: { Slack: false } }
    );

    switch (redirectedFromPage) {
      case pageTitlesAndUrls.containerTracking.path:
        this.setState({
          subtitle:
            'Upgrade your plan to start tracking your containers at the port!',
          descriptionTitle:
            'Improve your operations by tracking your containers at the terminal:',
          bullets: [
            'Track your import containers pickup availability;',
            'Track your empty containers appointments availabilities;',
            'Prioritize your containers by last free day to reduce Demurrage and Per Diem Fees.',
          ],
        });

        break;
      case pageTitlesAndUrls.terminalScreenshots.path:
      case pageTitlesAndUrls.perDiemFighter.path:
        this.setState({
          subtitle:
            'Upgrade your plan to gain access to two years of terminal data, with automated reports to help you fight and prevent per diem fees!',
          descriptionTitle:
            'Reduce your per diem fees with the Per Diem Fighter plan:',
          bullets: [
            'Build your documentation to provide proof for your per diem claims;',
            'Download, receive or send by email automated screenshots of terminal return instructions when no appointment is available;',
            'Download, receive or send by email automated screenshots of terminal return instructions from the past.',
          ],
        });
        break;
      case pageTitlesAndUrls.emptyAppointments.path:
        this.setState({
          subtitle: 'Upgrade your plan to secure empty return appointments!',
          descriptionTitle:
            'Return your empties on time with the Dispatcher Shop or Per Diem Fighter plans:',
          bullets: [
            'Search appointments by shipping line and container size;',
            'Get real-time empty appointments availabilities for the 12 terminals of LA/LB;',
            'Click the terminal link to be redirected to the terminal websites and proceed with your bookings.',
          ],
        });
        break;
      default:
        return;
    }
  };

  redirectToPricing = () => {
    this.props.navigate(pageTitlesAndUrls.plans.path);
  };

  render() {
    const { subtitle, descriptionTitle, bullets } = this.state;
    return (
      <ThemeProvider theme={themeProvider}>
        <div className={styles.font}>
          <UpgradeYourPlan
            handleUpgradeClick={this.redirectToPricing}
            subtitle={subtitle}
            descriptionTitle={descriptionTitle}
            bullets={bullets}
          />
        </div>
      </ThemeProvider>
    );
  }
}

const withHooksHOC = Component => {
  return props => {
    const navigate = useNavigate();

    return <Component navigate={navigate} {...props} />;
  };
};

export default withHooksHOC(UpgradeYourPlanPage);
