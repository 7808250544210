import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BCBrandingFooter from '../../components/BCBrandingFooter';

export default class InformationPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    hideBackButton: PropTypes.bool.isRequired,
    hideFooter: PropTypes.bool.isRequired,
    hideHeader: PropTypes.bool.isRequired,
  };
  static defaultProps = {
    hideBackButton: false,
    hideFooter: false,
    hideHeader: false,
  };

  render() {
    const { classes, children, hideFooter } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.background} />
        <div className={classes.smog} />
        <div className={classes.gradient} />
        <div className={classes.colorFilter} />

        <div className={classes.content}>{children}</div>
        {!hideFooter && (
          <BCBrandingFooter
            classes={{ footer: classes.footer }}
            termsOfUseUri={'/terms-of-use/'}
            privacyPolicy={'/privacy-policy/'}
          />
        )}
      </div>
    );
  }
}
