import moment from 'moment-timezone';
import _ from 'lodash';
import { laTimezone } from '../../../services/helpers/data';
import { getCurrentShift } from '../../../services/helpers/shift';
import {
  ALERT_DAYS_OUT,
  OPEN_GATE_STATUS,
  INSTRUCTIONS_UNAVAILABLE_ID,
  CONTAINER_STATUS_ENUM,
} from '../container-config';
import { getPickupInfo } from './pickup';

export function transformContainers(data = [], inputList = []) {
  const rows = data.map(row => {
    const gatedOutDate = row.gated_out ? laTimezone(row.gated_out) : null;
    const gatedInDate = row.gated_in ? laTimezone(row.gated_in) : null;
    const days = gatedOutDate
      ? (gatedInDate || moment()).diff(gatedOutDate, 'days')
      : null;

    const alert = row.gated_out ? days > ALERT_DAYS_OUT : null;
    const pickupInfo = getPickupInfo(row.pickup_info);
    const status = getStatus(row);
    const bookedAppointmentInfo = getBookedAppointmentInfo(
      status,
      row.booked_appointment_info
    );
    return {
      id: row.container_number,
      size: row.container_type,
      containerTypeKey: row.container_type_key,
      shippingLine: row.shipping_line,
      shippingLineKey: row.shipping_line_key,
      chassis: row.gated_out_chassis,
      gatedOutDate,
      gatedInDate,
      gatedOutTerminalName: row.gated_out_terminal,
      gatedOutTerminalId: row.gated_out_terminal_key,
      gatedInTerminalName: row.gated_in_terminal,
      gatedInTerminalId: row.gated_in_terminal_key,
      status,
      days,
      alert,
      containerNumber: row.container_number,
      ...pickupInfo,
      ...bookedAppointmentInfo,
      transactionCreatedAt: row.transaction__created_at,
    };
  });

  if (!inputList || inputList.length === 0) return rows;

  return _.sortBy(rows, row => inputList.indexOf(row.containerNumber));
}

function getStatus(row) {
  if (row.status) {
    return row.status;
  }

  if (row.container_type || row.last_refreshed)
    return CONTAINER_STATUS_ENUM.PENDING;

  return CONTAINER_STATUS_ENUM.LOADING;
}

export function addTerminalStatuses(containers, rawTerminalStatuses) {
  const terminalStatuses = decorateTerminalStatuses(rawTerminalStatuses);
  const containersWithFirstAppointments = addAvailableAppointments(
    containers,
    terminalStatuses
  );
  return addShiftOpenings(containersWithFirstAppointments, terminalStatuses);
}

function addAvailableAppointments(containers, terminalStatuses) {
  const appointmentsByLineSize = Object.keys(terminalStatuses).reduce(
    (acc, key) => {
      acc[key] = terminalStatuses[key]
        .reduce((acc, row) => {
          return acc.concat(row.appointments);
        }, [])
        .filter(appointmentMatch);
      return acc;
    },
    {}
  );
  return containers.map(container => {
    const { shippingLineKey, containerTypeKey } = container;
    if (_.isNil(shippingLineKey) || _.isNil(containerTypeKey)) {
      return {
        ...container,
        appointments: [],
      };
    }
    const appointments =
      Object.keys(appointmentsByLineSize).length > 0
        ? appointmentsByLineSize[
            `${shippingLineKey}${containerTypeKey}`
          ].sort((a, b) => a.periodFromDate.diff(b.periodFromDate))
        : [];

    return {
      ...container,
      appointments,
    };
  });
}

function addShiftOpenings(containers, terminalStatuses) {
  const { shift: currentShiftId, day: currentDate } = getCurrentShift();

  return containers.map(container =>
    addCurrentOpenings(container, currentDate, currentShiftId, terminalStatuses)
  );
}

function decorateTerminalStatuses(terminalStatusResponse) {
  return Object.keys(terminalStatusResponse).reduce((acc, key) => {
    acc[key] = terminalStatusResponse[key].map(row =>
      decorateTerminalStatusRow(row)
    );
    return acc;
  }, {});
}

function decorateTerminalStatusRow(row) {
  const decoratedRow = Object.keys(row).reduce((acc, key) => {
    acc[_.camelCase(key)] = row[key];
    return acc;
  }, {});
  decoratedRow.appointments = row.appointments.map(appointment => {
    const decoratedAppointment = {
      ...decoratedRow,
      periodFromDate: appointment.period_from
        ? laTimezone(appointment.period_from)
        : null,
      periodToDate: appointment.period_to
        ? laTimezone(appointment.period_to)
        : null,
      slots: appointment.slots,
    };
    delete decoratedAppointment.appointments;
    return decoratedAppointment;
  });
  return decoratedRow;
}

function appointmentMatch(appointment) {
  const currentDateTime = laTimezone();
  const allowedGateSituation =
    appointment.gateStatus === OPEN_GATE_STATUS ||
    appointment.gateStatus === null;
  const timeMatch = currentDateTime.isBefore(appointment.periodToDate);
  return (
    allowedGateSituation &&
    appointment.periodFromDate &&
    timeMatch &&
    appointment.availabilityId &&
    appointment.availabilityId !== INSTRUCTIONS_UNAVAILABLE_ID
  );
}

function addCurrentOpenings(
  container,
  currentDate,
  currentShift,
  statusesByLineSize
) {
  const { shippingLineKey, containerTypeKey } = container;
  const rows = (
    statusesByLineSize[`${shippingLineKey}${containerTypeKey}`] || []
  ).filter(row => {
    const allowedGateSituation =
      row.gateStatus === OPEN_GATE_STATUS || row.gateStatus === null;
    return (
      allowedGateSituation &&
      (row.availabilityId === 'yes' || row.availabilityId === 'dual') &&
      row.date === currentDate &&
      row.shiftId === currentShift
    );
  });

  const byTerminalId = rows.reduce((acc, row) => {
    const { terminalId } = row;
    if (acc[terminalId]) return acc;

    acc[terminalId] = row;
    return acc;
  }, {});

  const currentOpenings = Object.values(byTerminalId);

  return { ...container, currentOpenings };
}

function getBookedAppointmentInfo(containerStatus, bookedAppointmentInfo) {
  const appointmentInfo = {
    showBookedAppointmentPending: false,
    showBookedAppointment: false,
    appointmentStartDate: null,
    appointmentEndDate: null,
  };
  if (
    containerStatus !== CONTAINER_STATUS_ENUM.IMPORT &&
    containerStatus !== CONTAINER_STATUS_ENUM.ON_VESSEL
  ) {
    return appointmentInfo;
  }

  const bookedAppointmentIsImportBooked = _.get(
    bookedAppointmentInfo,
    'latest_details.is_import_booked'
  );
  const bookedAppointmentStartDate = _.get(
    bookedAppointmentInfo,
    'latest_details.appointment_start_date'
  );
  const bookedAppointmentEndDate = _.get(
    bookedAppointmentInfo,
    'latest_details.appointment_end_date'
  );

  return {
    showBookedAppointmentPending: !bookedAppointmentIsImportBooked,
    showBookedAppointment: bookedAppointmentIsImportBooked,
    bookedAppointmentStartDate: bookedAppointmentStartDate
      ? laTimezone(bookedAppointmentStartDate)
      : null,
    bookedAppointmentEndDate: bookedAppointmentEndDate
      ? laTimezone(bookedAppointmentEndDate)
      : null,
  };
}
