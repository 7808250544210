import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

export default class BCBrandingFooter extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    termsOfUseUri: PropTypes.string,
    privacyPolicy: PropTypes.string,
  };
  render() {
    const { classes, termsOfUseUri, privacyPolicy } = this.props;

    return (
      <div className={classes.footer}>
        <Typography className={classes.text}>
          © {new Date().getFullYear()} BlueCargo, Inc.
        </Typography>
        <div className={classes.verticalDivider} />
        <a href="https://www.bluecargo.io">
          <Typography className={cn(classes.text, classes.textUnderline)}>
            www.bluecargo.io
          </Typography>
        </a>
        <div className={classes.verticalDivider} />
        <a href="mailto:contact@bluecargo.io">
          <Typography className={cn(classes.text, classes.textUnderline)}>
            contact@bluecargo.io
          </Typography>
        </a>
        {termsOfUseUri && [
          <div className={classes.verticalDivider} key={'tou-div'} />,
          <Link to={termsOfUseUri} key={'tou-link'}>
            <Typography className={cn(classes.text, classes.textUnderline)}>
              Terms of use
            </Typography>
          </Link>,
        ]}
        {privacyPolicy && [
          <div className={classes.verticalDivider} key={'pp-div'} />,
          <Link to={privacyPolicy} key={'pp-link'}>
            <Typography className={cn(classes.text, classes.textUnderline)}>
              Privacy Policy
            </Typography>
          </Link>,
        ]}
      </div>
    );
  }
}
