import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'components/customDesign/Button';
import { ReactComponent as AnchorIcon } from 'assets/images/anchor.svg';
import { ReactComponent as ContainerIcon } from 'assets/images/container.svg';
import { ReactComponent as BarbIcon } from 'assets/images/barb.svg';
import { ReactComponent as BellIcon } from 'assets/images/ring.svg';
import { ReactComponent as SaveIcon } from 'assets/images/save_circle.svg';

import Autocomplete from 'components/Autocomplete';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function FormContent(props) {
  const {
    classes,
    shippingLines,
    containerTypes,
    terminals,
    possibleShippingLine,
    possibleTerminals,
    possibleContainerTypes,
    status,
    dirty,
  } = props;
  const selectedShippingLines = props.values.shipping_lines.map(v => {
    const ssl = shippingLines.find(sl => sl.key === v);
    return { label: ssl.name, value: ssl.key };
  });
  const selectedContainerTypes = props.values.container_types.map(v => {
    const sct = containerTypes.find(ct => ct.key === v);
    return { label: sct.name, value: sct.key };
  });
  const selectedTerminals = props.values.terminals.map(v => {
    const st = terminals.find(t => t.key === v);
    return { label: st.pier, value: st.key };
  });
  const onChange = event => {
    props.handleChange(event);
    props.setStatus('pending');
  };

  const onAutocompleteChange = name => value =>
    onChange({
      target: {
        value: value.map(v => v.value),
        name,
      },
    });

  const onSwitchChange = name => (event, value) =>
    onChange({
      target: { value, name },
    });
  return (
    <form onSubmit={props.handleSubmit}>
      <div className={classes.row}>
        <div className={classes.item}>
          <div className={classes.subtitle}>
            <AnchorIcon className={classes.icon} />
            <Typography variant="h4">Steamship lines</Typography>
          </div>
          <div className={classes.input}>
            <Autocomplete
              placeholder="Pick shipping lines"
              possibleValuesWithKey={possibleShippingLine}
              value={selectedShippingLines}
              onChange={onAutocompleteChange('shipping_lines')}
              classes={{ root: classes.autocompleteRoot }}
              isMulti
            />
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.subtitle}>
            <ContainerIcon className={classes.icon} />
            <Typography variant="h4">Container types</Typography>
          </div>
          <div className={classes.input}>
            <Autocomplete
              placeholder="Pick container types"
              possibleValuesWithKey={possibleContainerTypes}
              value={selectedContainerTypes}
              onChange={onAutocompleteChange('container_types')}
              classes={{ root: classes.autocompleteRoot }}
              isMulti
            />
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.subtitle}>
            <BarbIcon className={classes.icon} />
            <Typography variant="h4">Terminals</Typography>
          </div>
          <div className={classes.input}>
            <Autocomplete
              placeholder="Pick terminals"
              possibleValuesWithKey={possibleTerminals}
              value={selectedTerminals}
              onChange={onAutocompleteChange('terminals')}
              classes={{ root: classes.autocompleteRoot }}
              isMulti
            />
          </div>
        </div>
      </div>
      <Divider />
      <div className={cn(classes.subtitle, classes.sectionTitle)}>
        <BellIcon className={classes.icon} />
        <Typography variant="h4">Filter event types</Typography>
      </div>
      <div className={classes.row}>
        <FormControlLabel
          className={classes.item}
          control={
            <Switch
              checked={props.values.type_update_allowed}
              value="type_update_allowed"
              color="primary"
              onChange={onSwitchChange('type_update_allowed')}
            />
          }
          label={
            <Typography variant="caption">Return location updates</Typography>
          }
        />
        <FormControlLabel
          className={classes.item}
          control={
            <Switch
              checked={props.values.type_unreturnable_allowed}
              value="type_unreturnable_allowed"
              color="primary"
              onChange={onSwitchChange('type_unreturnable_allowed')}
            />
          }
          label={
            <Typography variant="caption">
              Impossible to return anywhere
            </Typography>
          }
        />
        <FormControlLabel
          className={classes.item}
          control={
            <Switch
              checked={props.values.gate_schedule_notifications}
              value="gate_schedule_notifications"
              color="primary"
              onChange={onSwitchChange('gate_schedule_notifications')}
            />
          }
          label={<Typography variant="caption">Gate Schedule</Typography>}
        />
        <FormControlLabel
          className={classes.item}
          control={
            <Switch
              checked={props.values.vessel_schedule_notifications}
              value="vessel_schedule_notifications"
              color="primary"
              onChange={onSwitchChange('vessel_schedule_notifications')}
            />
          }
          label={<Typography variant="caption">Vessel Schedule</Typography>}
        />
      </div>
      <div className={classes.row}>
        <FormControlLabel
          className={classes.item}
          control={
            <Switch
              checked={props.values.appointments_release_notifications}
              value="appointments_release_notifications"
              color="primary"
              onChange={onSwitchChange('appointments_release_notifications')}
            />
          }
          label={
            <Typography variant="caption">Appointments release</Typography>
          }
        />
      </div>
      <Divider />
      <div className={cn(classes.subtitle, classes.sectionTitle)}>
        <BellIcon className={classes.icon} />
        <Typography variant="h4">Delivery method</Typography>
      </div>
      <div className={classes.row}>
        <FormControlLabel
          className={classes.item}
          control={
            <Switch
              checked={props.values.method_email_allowed}
              value="method_email_allowed"
              color="primary"
              onChange={onSwitchChange('method_email_allowed')}
            />
          }
          label={
            <Typography variant="caption">Get email notifications</Typography>
          }
        />
        <FormControlLabel
          className={classes.item}
          disabled
          control={
            <Switch
              checked={props.values.method_push_allowed}
              value="method_push_allowed"
              color="primary"
              onChange={onSwitchChange('method_push_allowed')}
            />
          }
          label={
            <Typography variant="caption">Get push notifications</Typography>
          }
        />
        <FormControlLabel
          className={classes.item}
          disabled
          control={
            <Switch
              checked={props.values.method_text_allowed}
              value="method_text_allowed"
              color="primary"
              onChange={onSwitchChange('method_text_allowed')}
            />
          }
          label={
            <Typography variant="caption">Get notifications by text</Typography>
          }
        />
        <FormControlLabel
          className={classes.item}
          control={
            <Switch
              checked={props.values.method_notif_center_allowed}
              value="method_notif_center_allowed"
              color="primary"
              onChange={onSwitchChange('method_notif_center_allowed')}
            />
          }
          label={
            <Typography variant="caption">
              Show notifications in the notification center
            </Typography>
          }
        />
      </div>
      <div className={classes.buttonContainer}>
        <div>
          {dirty && status !== 'success' && (
            <Typography variant="caption" className={classes.alert}>
              Do not forget to save your settings
            </Typography>
          )}
          <Button
            isLoading={props.isSubmitting}
            type="submit"
            success={status === 'success'}
            rightIcon={
              status !== 'success' && <SaveIcon className={classes.icon} />
            }
          >
            {status === 'success' ? 'Success' : 'Save'}
          </Button>
        </div>
      </div>
    </form>
  );
}

FormContent.propTypes = {
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  shippingLines: PropTypes.array.isRequired,
  containerTypes: PropTypes.array.isRequired,
  terminals: PropTypes.array.isRequired,
  possibleShippingLine: PropTypes.array.isRequired,
  possibleTerminals: PropTypes.array.isRequired,
  possibleContainerTypes: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
};

const styles = theme => ({
  row: {
    display: 'flex',
  },
  buttonContainer: {
    marginTop: 2 * theme.spacing.unit,
    display: 'flex',
    justifyContent: 'center',
  },
  autocompleteRoot: {
    width: '100%',
  },
  item: {
    flex: 1,
    margin: theme.spacing.unit * 2,
  },
  subtitle: {
    display: 'flex',
    alignItems: 'flex-end',
    fill: 'rgba(31, 51, 75, 0.5)',
    marginLeft: theme.spacing.unit,
  },
  input: {
    marginTop: 2 * theme.spacing.unit,
  },
  icon: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    fill: 'rgba(31, 51, 75, 0.5)',
  },
  sectionTitle: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
  alert: {
    color: theme.palette.alert.main,
  },
});

export default withStyles(styles)(FormContent);
