import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { IMPORT_STATUS_ENUM, CONTAINER_STATUS_ENUM } from '../container-config';

export const tagVariantMapping = (status, pickupImportStatus) => {
  let statusText = status;
  let variant = 'tag-default';

  switch (status) {
    case CONTAINER_STATUS_ENUM.GATED_OUT:
      variant = 'tag-gated-out';
      statusText = 'gated out';
      break;
    case CONTAINER_STATUS_ENUM.ON_VESSEL:
      statusText = 'on vessel';
      variant = 'tag-on-vessel';
      break;
    case CONTAINER_STATUS_ENUM.IMPORT:
      statusText = 'import';
      variant = 'tag-import';

      switch (pickupImportStatus) {
        case IMPORT_STATUS_ENUM.YARD:
          statusText += ' - in yard';
          break;
        case IMPORT_STATUS_ENUM.RAIL:
          statusText += ' - on rail';
          break;
        default:
          break;
      }
      break;
    case CONTAINER_STATUS_ENUM.LOADING:
      variant = 'tag-loading';
      break;
    case CONTAINER_STATUS_ENUM.PENDING:
    case CONTAINER_STATUS_ENUM.TERMINATED:
      variant = 'tag-default';
      break;
    default:
      break;
  }
  return {
    variant,
    statusText,
  };
};

export default function ContainerStatusTag({ status, pickupImportStatus }) {
  const { variant, statusText } = tagVariantMapping(status, pickupImportStatus);
  return status === CONTAINER_STATUS_ENUM.LOADING ? (
    <Button variant={variant}>
      <div className="flex space-x-2 items-center">
        <CircularProgress size={12} />
        <h5 className="text-blue">Loading...</h5>
      </div>
    </Button>
  ) : (
    <Button sx={{ cursor: 'default' }} variant={variant} className="capitalize">
      {statusText}
    </Button>
  );
}
