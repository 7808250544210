import { startLoading, endLoading } from 'ducks/loader';
import * as userClient from 'services/clients/user';
import { getAvailabilityCredentialsEnd } from '../services/clients/availability';
import { CredentialsService } from '../pages/Settings/Credentials/services/credentials.service';
import { SegmentService } from 'services/helpers/segment';
import { createSelector } from 'reselect';
import ReactGA from 'react-ga';
import { setError } from './error';
import { bugsnagClient } from 'services/helpers/bugsnag';
import { setClearAvailabilityCredentials } from './availabilityCredentials';
import { LocalStorageUtil } from 'utils/local-storage.util';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
  USER_CONTEXT_KEY,
} from 'constants/user';

export const SET_USER_DATA = 'core/api/v1/erl/user/SET_USER_DATA';
export const CLEAR_USER_DATA = 'core/api/v1/erl/user/CLEAR_USER_DATA';
export const SET_TEAM_MEMBERS = 'core/api/v1/erl/user/SET_TEAM_MEMBERS';
export const SET_PERMISSIONS = 'core/api/v1/erl/user/SET_PERMISSIONS';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  company_name: '',
  company_nature: '',
  company_role: '',
  nb_empty_return: null,
  phone_number: '',
  teamMembers: [],
  permissions: {},
  company: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, ...action.payload };
    case CLEAR_USER_DATA:
      return initialState;

    case SET_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: action.payload,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};

export const setAccessTokenToLocalStorage = (access_token = '') => {
  LocalStorageUtil.set(ACCESS_TOKEN_LOCAL_STORAGE_KEY, access_token);
};

export const setRefreshTokenToLocalStorage = (refresh_token = '') => {
  LocalStorageUtil.set(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refresh_token);
};

async function fetchUserContext() {
  const profile = await userClient.getProfile();
  let credentials = [];
  let allCredentialsEnteredAndValid = true;
  try {
    credentials = await getAvailabilityCredentialsEnd();
    allCredentialsEnteredAndValid = CredentialsService.checkAllCredentialsEnteredAndValid(
      credentials
    );
  } catch {}

  const userContext = {
    ...profile,
    credentials,
    isAllCredentials: allCredentialsEnteredAndValid,
  };
  LocalStorageUtil.set(USER_CONTEXT_KEY, userContext);
  return userContext;
}

export const setUserData = data => ({
  type: SET_USER_DATA,
  payload: data,
});

export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
});

export const setTeamMembers = members => ({
  type: SET_TEAM_MEMBERS,
  payload: members,
});

export const setPermissions = permissions => ({
  type: SET_PERMISSIONS,
  payload: permissions,
});

export const signUp = form => async dispatch => {
  const formattedForm = {
    ...form,
    company:
      form.company && form.company.scac
        ? {
            name: form.company_name,
            scac: form.company.scac,
            address: form.company.address,
          }
        : null,
  };
  dispatch(startLoading('signUp'));
  try {
    const data = await userClient.signUp(formattedForm);

    const { access_token, refresh_token, ...userData } = data || {};
    setAccessTokenToLocalStorage(access_token);
    setRefreshTokenToLocalStorage(refresh_token);

    dispatch(setUserData(userData));
    dispatch(endLoading('signUp'));

    SegmentService.identifyUser(userData);
    SegmentService.trackEvent(
      'User Created Account',
      {},
      {
        integrations: {
          Slack: false,
        },
      }
    );
    return data;
  } catch (e) {
    const error = await e;
    dispatch(endLoading('signUp'));
    throw error;
  }
};

export const signIn = form => async dispatch => {
  dispatch(startLoading('signIn'));
  try {
    const data = await userClient.signIn(form);

    const { access_token, refresh_token, ...userData } = data || {};
    setAccessTokenToLocalStorage(access_token);
    setRefreshTokenToLocalStorage(refresh_token);

    const userContext = await fetchUserContext();
    const { id, email, first_name, last_name } = userData || {};
    bugsnagClient.user = {
      id,
      email,
      name: `${first_name} ${last_name}`,
    };
    ReactGA.set({ userId: id });
    SegmentService.identifyUser(userContext);
    SegmentService.trackEvent(
      'User Signed In',
      {},
      {
        integrations: {
          Slack: false,
        },
      }
    );
    dispatch(setUserData(userContext));
    dispatch(setPermissions(userContext.permissions));
    dispatch(endLoading('signIn'));
    return data;
  } catch (e) {
    const error = await e;
    dispatch(endLoading('signIn'));
    throw error;
  }
};
export const retrieveUserContext = () => async dispatch => {
  const data = await fetchUserContext();
  dispatch(setUserData(data));
  bugsnagClient.user = {
    id: data.id,
  };
};

export const logout = () => (dispatch, getState) => {
  const state = getState();
  const { user } = state || {};

  dispatch(setClearAvailabilityCredentials());
  dispatch(clearUserData());
  localStorage.clear();
  // Added for appcues to see the real URL.

  SegmentService.trackEvent(
    'User Logged Out',
    {},
    {
      integrations: {
        Slack: false,
      },
    }
  );
  SegmentService.identifyUser(user);

  setTimeout(function() {
    window.location.reload();
  }, 100);
};

export const retrieveTeamMember = () => async dispatch => {
  try {
    const teamMembers = await userClient.getTeamMembers();
    dispatch(setTeamMembers(teamMembers));
  } catch (e) {
    dispatch(setTeamMembers([]));
  }
};

export const loginWithToken = (
  access_token,
  refresh_token,
  successCallBack = () => {}
) => async dispatch => {
  if (access_token && refresh_token) {
    try {
      dispatch(startLoading('signIn'));
      dispatch(setClearAvailabilityCredentials());
      dispatch(clearUserData());
      localStorage.clear();

      setAccessTokenToLocalStorage(access_token);
      setRefreshTokenToLocalStorage(refresh_token);
      await dispatch(retrieveUserContext());
      successCallBack();
      dispatch(endLoading('signIn'));
    } catch (e) {
      console.error(e);
      dispatch(endLoading('signIn'));
      dispatch(setError('Cannot login with the given token'));
    }
  } else {
    dispatch(setError('Wrong link ! Token missing.'));
  }
};

export const isLogged = () => {
  const access_token = LocalStorageUtil.get(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  return access_token ? true : false;
};

export const getFirstLetters = createSelector(
  state => state.user.first_name,
  state => state.user.last_name,
  (first_name, last_name) =>
    first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase()
);
