export default theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    boxShadow: 'unset',
    height: 440,
  },
  main: {
    width: 'auto',
    zIndex: 2,
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  avatar: {
    width: 35,
    height: 35,
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main,
  },
  mailIcon: {
    marginTop: 5 * theme.spacing.unit,
    marginBottom: 5 * theme.spacing.unit,
  },
});
