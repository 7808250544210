import TableFilters from './TableFilters';
import styles from './TableFilters.style';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toggleFilterMenu } from 'ducks/display';
import {
  getShippingLinesList,
  getContainerTypesList,
  getPortsList,
} from 'ducks/erlData';
import {
  setShippingLinesFiltersAndReduceTerminals,
  setContainerTypesFilters,
  setTerminalsFilters,
} from 'ducks/instructionFilters';

const mapStateToProps = state => ({
  user: state.user,
  isOpen: state.display.isFilterMenuOpen,
  shippingLines: getShippingLinesList(state),
  containerTypes: getContainerTypesList(state),
  terminals: getPortsList(state),
  shippingLinesFilters: state.instructionFilters.shippingLines,
  containerTypesFilters: state.instructionFilters.containerTypes,
  terminalsFilters: state.instructionFilters.terminals,
});

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(toggleFilterMenu()),
  setShippingLinesFilters: shippingLines =>
    dispatch(setShippingLinesFiltersAndReduceTerminals(shippingLines)),
  setContainerTypesFilter: containerTypes =>
    dispatch(setContainerTypesFilters(containerTypes)),
  setTerminalsFilters: terminals => dispatch(setTerminalsFilters(terminals)),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(TableFilters);
