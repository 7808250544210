export default theme => ({
  root: {
    paddingBottom: 100,
  },
  transitionText: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    fontWeight: 500,
  },
  transistion: {
    textAlign: 'center',
    marginTop: 2 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit,
  },
  buttonRoot: {
    height: 52,
    width: 200,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  checklist: {
    display: 'flex',
    justifyContent: 'space-around',
    width: 700,
    margin: 'auto',
    marginBottom: 2 * theme.spacing.unit,
  },
  checklistItem: {
    display: 'flex',
    color: theme.palette.primary.main,
    alignItems: 'center',
  },
});
