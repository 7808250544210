import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import DualIcon from '@material-ui/icons/CompareArrows';

import WithWarning from '../WithWarning';

export default class StatusIcon extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    status: PropTypes.string,
    warnings: PropTypes.array,
    isGrey: PropTypes.bool,
    isEmptyAppointments: PropTypes.bool,
  };
  static defaultProps = {
    warnings: [],
  };

  render() {
    const {
      classes,
      status,
      warnings,
      isGrey,
      isEmptyAppointments,
    } = this.props;

    return (
      <React.Fragment>
        {status === 'clock' && (
          <WithWarning
            warnings={warnings}
            status={status}
            isGrey={isGrey}
            isEmptyAppointments={isEmptyAppointments}
          >
            <AccessTimeIcon className={classes.grey} width="24" height="24" />
          </WithWarning>
        )}
        {status === 'yes' && (
          <WithWarning
            warnings={warnings}
            status={status}
            isGrey={false}
            isEmptyAppointments={isEmptyAppointments}
          >
            <CheckIcon className={classes.green} width="24" height="24" />
          </WithWarning>
        )}
        {status === 'no' && (
          <CloseIcon
            className={isGrey ? classes.grey : classes.red}
            width="24"
            height="24"
          />
        )}
        {status === 'dual' && (
          <WithWarning
            warnings={warnings}
            status={status}
            isGrey={false}
            isEmptyAppointments={isEmptyAppointments}
          >
            <DualIcon className={classes.yellow} width="24" height="24" />
          </WithWarning>
        )}
        {status === 'blocked' && <span />}
        {status === 'na' && (
          <WithWarning
            warnings={warnings}
            status={status}
            isGrey={isGrey}
            isEmptyAppointments={isEmptyAppointments}
          >
            <AccessTimeIcon className={classes.grey} width="24" height="24" />
          </WithWarning>
        )}
        {status === null && <span />}
      </React.Fragment>
    );
  }
}
