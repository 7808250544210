import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { globalTheme } from 'globalTheme';

const theme = createTheme({
  palette: {
    primary: {
      main: globalTheme.colors.blue.DEFAULT,
      contrastText: '#ffffff',
    },
    secondary: {
      main: globalTheme.colors.orange.DEFAULT,
      contrastText: '#ffffff',
    },
    error: {
      main: globalTheme.colors.red.DEFAULT,
    },
    info: {
      main: globalTheme.colors.gray[200],
    },
    success: {
      main: globalTheme.colors.green.DEFAULT,
    },
    lightBlue: {
      main: globalTheme.colors.blue[200],
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'tag-default' },
          style: {
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: `${globalTheme.colors.gray[300]}`,
            border: `solid 1px ${globalTheme.colors.gray[500]}`,
            background: globalTheme.colors.blue[600],
            padding: '0px 6px',
            '&:disabled': {
              opacity: 0.4,
              background: globalTheme.colors.blue[600],
              color: globalTheme.colors.gray[300],
            },
          },
        },
        {
          props: { variant: 'tag-primary' },
          style: {
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: `${globalTheme.colors.blue.DEFAULT}`,
            border: `solid 1px ${globalTheme.colors.blue.DEFAULT}`,
            background: globalTheme.colors.blue[600],
            padding: '0px 6px',
            '&:disabled': {
              opacity: 0.4,
              background: globalTheme.colors.blue[600],
              color: globalTheme.colors.blue.DEFAULT,
            },
          },
        },
        {
          props: { variant: 'tag-secondary' },
          style: {
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: `${globalTheme.colors.orange.DEFAULT}`,
            border: `solid 1px ${globalTheme.colors.orange.DEFAULT}`,
            background: globalTheme.colors.orange[600],
            padding: '0px 6px',
            '&:disabled': {
              opacity: 0.4,
              background: globalTheme.colors.orange[600],
              color: globalTheme.colors.orange.DEFAULT,
            },
          },
        },
        {
          props: { variant: 'tag-alert' },
          style: {
            fontWeight: 'medium',
            fontSize: '0.75rem',
            color: `${globalTheme.colors.red.DEFAULT}`,
            border: `solid 1px ${globalTheme.colors.red.DEFAULT}`,
            background: globalTheme.colors.red[600],
            padding: '0px 6px',
            '&:disabled': {
              opacity: 0.4,
              background: globalTheme.colors.red[600],
              color: globalTheme.colors.red.DEFAULT,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          textTransform: 'initial',
          borderRadius: '3px',
        },
        containedPrimary: {
          '&:disabled': {
            opacity: 0.4,
            background: globalTheme.colors.blue.DEFAULT,
            color: globalTheme.colors.white,
          },
          '&:hover': {
            background: globalTheme.colors.blue[100],
            boxShadow: 'none',
          },
        },
        containedSecondary: {
          '&:disabled': {
            opacity: 0.4,
            background: globalTheme.colors.orange.DEFAULT,
            color: globalTheme.colors.white,
          },
          '&:hover': {
            background: globalTheme.colors.orange[100],
            boxShadow: 'none',
          },
        },
      },
    },
  },
});

// TODO: remove overloaded 'label' (MUI already has one) term and switch to children
// so the API of the button stays consistent between brandbutton and MUI
export default function BrandButton({ ...props }) {
  return (
    <ThemeProvider theme={theme}>
      <LoadingButton {...props}>{props.label}</LoadingButton>
    </ThemeProvider>
  );
}
