import { pageTitlesAndUrls } from 'constants/pages';

export const SIDEBAR_WIDTH = 233;
export const USER_MENU_WIDTH = 270;
export const NAV_HEIGHT = 60;

export const USER_PROFILE_DROPDOWN = [
  {
    title: 'Settings',
    children: [
      pageTitlesAndUrls.profile,
      pageTitlesAndUrls.plans,
      pageTitlesAndUrls.invoiceHistory,
      pageTitlesAndUrls.notificationSettings,
      pageTitlesAndUrls.credentials,
      pageTitlesAndUrls.adminGateSchedule,
      pageTitlesAndUrls.adminPTSCSchedule,
    ],
  },
];

export const MENU_DROPDOWNS = [
  {
    title: 'D&D Fighter',
    children: [
      pageTitlesAndUrls.perDiemFighter,
      pageTitlesAndUrls.terminalScreenshots,
    ],
  },
  {
    title: 'Schedules',
    children: [
      pageTitlesAndUrls.emptyReturnLocations,
      pageTitlesAndUrls.vesselSchedule,
      pageTitlesAndUrls.gateSchedule,
    ],
  },
];

export const NAV_TABS = [
  {
    ...pageTitlesAndUrls.containerTracking,
  },
  {
    ...pageTitlesAndUrls.emptyAppointments,
  },
];

export const SHOW_UPGRADE_BUTTON = [
  'pioneer',
  'trial_end',
  'track_n_dispatch',
  'team',
  'starter',
];

export const BANNER_HEIGHT = 40;
