import React from 'react';
import { Link } from 'react-router-dom';
import { BANNER_HEIGHT } from './utils/config';
import CloseIcon from '@material-ui/icons/Close';
import { SegmentService } from 'services/helpers/segment';

const Banner = ({ content, onRemoveBanner }) => {
  let bannerClassType = 'bg-orange-600 border-b border-orange';
  if (content.type === 'alert') {
    bannerClassType = 'bg-red-light border-b border-red';
  }

  const handleAnalytics = (link, textLink) => {
    SegmentService.trackEvent(
      `Banner link Clicked`,
      {
        textLink,
        link,
      },
      { integrations: { Slack: false } }
    );
  };
  return (
    <div
      className={`w-full flex items-center px-4 font-medium ${bannerClassType}`}
      style={{ height: `${BANNER_HEIGHT}px` }}
    >
      <div className="flex w-full items-center space-x-1 justify-center">
        <h4>{content.startCopy}</h4>
        <Link to={content.path} className="text-blue">
          <h4
            className="underline"
            onClick={() => handleAnalytics(content.path, content.link)}
          >
            {content.link}
          </h4>
        </Link>
        <h4> {content.endCopy}</h4>
      </div>

      {content.type === 'warning' && (
        <CloseIcon
          style={{ fontSize: 18 }}
          className="cursor-pointer ml-auto"
          onClick={onRemoveBanner}
        />
      )}
    </div>
  );
};

export default Banner;
