import React, { Component } from 'react';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import TopIcon from '@material-ui/icons/ArrowUpward';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

export default function WithScrollTopButton(WrappedComponent) {
  const styles = theme => ({
    fab: {
      position: 'fixed',
      bottom: theme.spacing.unit,
      right: theme.spacing.unit,
    },
  });
  return withStyles(styles)(
    class extends Component {
      static propTypes = {
        classes: PropTypes.object.isRequired,
      };
      state = {
        displayButton: false,
      };
      scrollTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      };

      componentDidMount() {
        window.onscroll = () => {
          if (window.pageYOffset >= 100 && !this.state.displayButton) {
            this.setState({ displayButton: true });
          }
          if (window.pageYOffset < 100 && this.state.displayButton) {
            this.setState({ displayButton: false });
          }
        };
      }

      render() {
        const { classes, ...other } = this.props;
        return (
          <React.Fragment>
            <WrappedComponent {...other} />
            <Fade in={this.state.displayButton} className={classes.fab}>
              <Fab color="primary" onClick={this.scrollTop}>
                <TopIcon />
              </Fab>
            </Fade>
          </React.Fragment>
        );
      }
    }
  );
}
