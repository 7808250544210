import ShiftTabs from './ShiftTabs';
import styles from './ShiftTabs.style';
import { connect } from 'react-redux';
import { changeSelectedShift } from 'ducks/availabilities';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

const mapStateToProps = state => ({
  selectedShift: state.availabilities.selectedShift,
});

const mapDispatchToProps = dispatch => ({
  changeSelectedShift: shift => dispatch(changeSelectedShift({ shift })),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ShiftTabs);
