import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/customDesign/Tooltip';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCurrentGateHour } from 'ducks/availabilities';
import cn from 'classnames';

const renderGateStatusTitle = (classes, is_open) => {
  const isGateOpen = is_open === false ? false : true;

  return (
    <Typography
      variant="caption"
      align="center"
      className={cn(classes.status, {
        [classes.close]: !isGateOpen,
      })}
    >
      {isGateOpen ? 'OPEN' : 'CLOSED'}
    </Typography>
  );
};

function GateStatusCellContent({ classes, gateHours }) {
  const hour = gateHours;
  return (
    <div
      className={cn(classes.root, {
        [classes.closeRoot]: hour && hour.is_open === false,
      })}
    >
      {hour && (
        <React.Fragment>
          {hour.opening && hour.closing ? (
            <Tooltip
              title={
                <div>
                  {hour.warnings.map(w => (
                    <Typography
                      variant="caption"
                      key={w}
                      className={classes.tooltipText}
                    >
                      {w}
                    </Typography>
                  ))}
                  <Typography variant="caption" className={classes.tooltipText}>
                    {hour.opening && (
                      <React.Fragment>
                        <b> From </b>
                        {moment(hour.opening, 'HH:mm:ss').format('HH:mm')}{' '}
                      </React.Fragment>
                    )}
                    {hour.closing && (
                      <React.Fragment>
                        <b>until </b>
                        {moment(hour.closing, 'HH:mm:ss').format('HH:mm')}
                      </React.Fragment>
                    )}
                  </Typography>
                </div>
              }
            >
              {renderGateStatusTitle(classes, hour.is_open)}
            </Tooltip>
          ) : (
            renderGateStatusTitle(classes, hour.is_open)
          )}
        </React.Fragment>
      )}
    </div>
  );
}

const styles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  closeRoot: {
    backgroundColor: theme.palette.alert.light,
  },
  tooltipText: {
    color: theme.palette.primary.main,
    opacity: 0.5,
  },
  close: {
    color: `${theme.palette.alert.main} !important`,
  },
  status: {
    width: '100%',
    // marginTop: 13,
    color: 'rgba(31, 51, 75, 0.5)',
  },
  closingHour: {
    color: 'rgba(31, 51, 75, 0.25)',
  },
});

GateStatusCellContent.propTypes = {
  terminal: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  gateHours: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  gateHours: getCurrentGateHour(state, ownProps.terminal.key),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(GateStatusCellContent);
