import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import InfoIcon from '@material-ui/icons/Info';
import ShippingLineTooltip from './ShippingLineTooltip';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export default class ShippingLineCell extends Component {
  static propTypes = {
    shippingLine: PropTypes.object.isRequired,
    nbContainerTypes: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
  };
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  closePopper = () => {
    this.setState({ open: false });
  };

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };
  render() {
    const { shippingLine, nbContainerTypes, classes } = this.props;
    const hasInformation =
      shippingLine.emails ||
      shippingLine.websites ||
      shippingLine.phone_numbers;
    return (
      <TableCell
        rowSpan={nbContainerTypes}
        classes={{ root: classes.cellRoot }}
      >
        <Typography
          variant="h5"
          className={classNames(classes.slTypography, {
            [classes.clickable]: hasInformation,
          })}
          align="left"
          onClick={hasInformation ? this.handleClick : () => {}}
        >
          {shippingLine.name}{' '}
          {hasInformation && (
            <InfoIcon fontSize={'inherit'} className={classes.icon} />
          )}
        </Typography>
        <Popper
          className={classes.popper}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          placement={'right'}
          transition
          modifiers={{
            arrow: {
              enabled: Boolean(this.state.arrowRef),
              element: this.state.arrowRef,
            },
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <ClickAwayListener onClickAway={this.closePopper}>
                <React.Fragment>
                  <span className={classes.arrow} ref={this.handleArrowRef} />
                  <ShippingLineTooltip
                    shippingLine={shippingLine}
                    onClose={this.closePopper}
                  />
                </React.Fragment>
              </ClickAwayListener>
            </Fade>
          )}
        </Popper>
      </TableCell>
    );
  }
}
