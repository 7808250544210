import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiTooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

export class Tooltip extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    tooltipText: PropTypes.node,
  };

  render() {
    const { classes, children, tooltipText, ...other } = this.props;
    return (
      <MuiTooltip
        title={
          <Typography className={classes.title} variant="caption">
            {tooltipText}
          </Typography>
        }
        classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        {...other}
      >
        {children}
      </MuiTooltip>
    );
  }
}

const styles = theme => ({
  tooltip: {
    backgroundColor: '#fff',
    borderRadius: 0,
    boxShadow: theme.shadows[2],
  },
  popper: {
    opacity: 1,
  },
  title: {
    color: theme.palette.primary.main,
    opacity: 0.5,
  },
});

export default withStyles(styles)(Tooltip);
