import React, { useEffect, useState } from 'react';
import Banner from './Banner';
import { pageTitlesAndUrls } from '../../constants/pages';
import styles from '../../styles/typography.module.css';
import { BANNER_HEIGHT } from 'layouts/AppPageTemplate/utils/config';
import moment from 'moment';
import _ from 'lodash';
import { useNavHeightContext } from 'common/contexts/NavHeightContext';

const BANNERS = [
  {
    id: 'credentials-banner',
    type: 'warning',
    startCopy: 'Please ',
    shouldBeDisplayed: user => !user.isAllCredentials,
    link: 'enter your credentials ',
    endCopy: 'to unleash the full potential of BlueCargo',
    path: pageTitlesAndUrls.credentials.path,
  },
  {
    type: 'warning',
    id: 'freetrial-banner',
    shouldBeDisplayed: user => {
      const trialEnd = _.get(user, 'company.trial_end');
      if (!trialEnd || user.company.pack !== 'pioneer') return false;
      const days = Math.floor(
        moment.duration(moment(trialEnd).diff(moment())).asDays()
      );
      return days <= 3 && days >= 0;
    },
    startCopy: 'Your are almost at the end of your free trial. Please ',
    link: 'upgrade your subscription',
    endCopy: ' to continue using all features!',
    path: pageTitlesAndUrls.plans.path,
  },
  // TODO: these two banners are identical, we could refactor it and make the type attribute a function
  {
    type: 'alert',
    id: 'container-limit-banner',
    shouldBeDisplayed: user => user.containerCreditsLimitReached,
    startCopy: 'You have reached your total tracked containers.',
    link: 'Add containers to your plan now!',
    endCopy: '',
    path: pageTitlesAndUrls.plans.path,
  },
  {
    type: 'warning',
    id: 'payment-failed',
    shouldBeDisplayed: user =>
      _.get(user, 'company.subscription_status') === 'past_due',
    startCopy:
      'Your payment method has failed.  Please update your payment method',
    link: 'here',
    endCopy: 'to continue using BlueCargo.',
    path: pageTitlesAndUrls.invoiceHistory.path,
  },
  {
    type: 'alert',
    id: 'payment-failed',
    shouldBeDisplayed: user =>
      _.get(user, 'company.subscription_status') === 'unpaid',
    startCopy:
      'Your payment method has failed. Please update your payment method',
    link: 'here',
    endCopy: 'to continue using BlueCargo.',
    path: pageTitlesAndUrls.invoiceHistory.path,
  },
];

const Banners = ({ user }) => {
  const [bannersToDisplay, setBannersToDisplay] = useState([]);
  const [closedBannerIds, setClosedBannerIds] = useState([]);
  const { adjustMarginTop } = useNavHeightContext();

  useEffect(() => {
    setBannersToDisplay(
      BANNERS.filter(
        banner =>
          banner.shouldBeDisplayed(user) && !closedBannerIds.includes(banner.id)
      )
    );
  }, [user]);

  useEffect(() => {
    adjustMarginTop(bannersToDisplay.length * BANNER_HEIGHT);
  }, [bannersToDisplay]);

  const handleOnRemoveBanner = (banner, bannerIdx) => {
    const newBannersToDisplay = bannersToDisplay.filter(
      (v, idx) => idx !== bannerIdx
    );
    setClosedBannerIds(_.concat(closedBannerIds, banner.id));
    setBannersToDisplay(newBannersToDisplay);
  };

  return (
    <div className={styles.font}>
      {bannersToDisplay.map((banner, idx) => (
        <Banner
          key={idx}
          content={banner}
          onRemoveBanner={() => handleOnRemoveBanner(banner, idx)}
        />
      ))}
    </div>
  );
};

export default Banners;
