import React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Navigate } from 'react-router-dom';
import { validateEmail } from '../../services/helpers/user';
import InformationPage from '../../components/InformationPage';
import { Formik } from 'formik';
import Formular from './components/Formular';
import { Link } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from 'assets/images/Profile.svg';

const initialValues = {
  email: '',
  password: '',
};

export default class SignIn extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    signIn: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    isLogged: PropTypes.bool.isRequired,
    clearUserData: PropTypes.func.isRequired,
  };
  state = {
    redirectToReferrer: false,
    redirectToConfirmation: false,
    confirmationEmail: '',
  };

  validate = values => {
    // Remove force step to avoid user to be stuck
    this.setState({ forceStep: null });
    let errors = {};

    if (!values.email) errors.email = 'Required';
    else if (!validateEmail(values.email))
      errors.email = 'Invalid email address';

    if (!values.password) errors.password = 'Required';

    return errors;
  };

  submit = (values, { setSubmitting, setErrors }) => {
    const { clearUserData } = this.props;
    clearUserData();
    localStorage.clear();
    this.props
      .signIn(values)
      .then(data => {
        const { email_validated, email } = data || {};
        if (!email_validated) {
          this.setState({
            redirectToConfirmation: true,
            confirmationEmail: email,
          });
        }
        setSubmitting(false);
      })
      .catch(e => {
        const errors = e;

        setErrors(errors);
        setSubmitting(false);
      });
  };

  componentDidMount() {
    if (this.props.isLogged) {
      this.setState({ redirectToReferrer: true });
    }
  }

  render() {
    const { classes, isLogged } = this.props;

    let { from } = this.props.location.state || { from: { pathname: '/' } };
    let {
      redirectToReferrer,
      redirectToConfirmation,
      confirmationEmail,
    } = this.state;
    if (redirectToConfirmation) {
      return <Navigate to={`/confirmation?email=${confirmationEmail}`} />;
    }

    if (redirectToReferrer || isLogged) {
      return <Navigate replace to={from} />;
    }

    return (
      <InformationPage classes={{ container: classes.root }}>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <ProfileIcon />
            <Typography
              component="h1"
              variant="h5"
              className={classes.title}
              gutterBottom
            >
              Sign in
            </Typography>
            <Typography variant="caption" gutterBottom>
              Credentials
            </Typography>
            <Formik
              initialValues={initialValues}
              validate={this.validate}
              onSubmit={this.submit}
            >
              {props => <Formular {...props} />}
            </Formik>
            <Link to="/signup" className={classes.link}>
              Sign up
            </Link>
            <Link to="/reset-password" className={classes.link}>
              Forgot password?
            </Link>
          </Paper>
        </main>
      </InformationPage>
    );
  }
}
