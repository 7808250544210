import erlClient from './';
import memoize from 'lodash/memoize';

export const signUp = async form => {
  try {
    const res = await erlClient.post(`core/api/v1/auth/signup`, form, {
      headers: { Authorization: '' },
    });
    return res.data;
  } catch (e) {
    const res = e.response;
    if (res.status === 400) throw res.data;
  }
};

export const signIn = async form => {
  try {
    const res = await erlClient.post(`core/api/v1/auth/signin`, form, {
      headers: { Authorization: '' },
    });
    return res.data;
  } catch (e) {
    const res = e.response;
    if (res.status === 400) throw res.data;
  }
};

export const resetPassword = async form => {
  try {
    const res = await erlClient.post('/core/api/v1/auth/password_reset', form, {
      headers: { Authorization: '' },
    });
    return res.data;
  } catch (e) {
    const res = e.response;
    if (res.status === 400) throw res.data;
  }
};

export const changePassword = async form => {
  try {
    const res = await erlClient.post(
      '/core/api/v1/auth/password_reset/confirm',
      form,
      {
        headers: { Authorization: '' },
      }
    );
    return res.data;
  } catch (e) {
    const res = e.response;
    if (res.status === 400) throw res.data;
    if (res.status === 404) {
      const error = { other: 'This link is no longer available' };
      throw error;
    }
  }
};

export const getProfile = async () => {
  const res = await erlClient.get('/core/api/v1/auth/profile');
  return res.data;
};

export const updateProfile = async data => {
  const res = await erlClient.put('/core/api/v1/auth/profile', data);
  return res.data;
};

export const getPaymentsList = async () => {
  const res = await erlClient('/core/api/v1/auth/billing/payment/list');
  return res.data;
};

export const placePlanOrder = async selectedPlanData => {
  await erlClient.put('/core/api/v1/auth/plan', selectedPlanData);
};

export const getTeamMembers = async () => {
  const res = await erlClient.get('/core/api/v1/auth/company/members');
  return res.data;
};

export const validatePromotionalCode = memoize((code, plan) =>
  erlClient.get(`core/api/v1/auth/billing/code`, { params: { code, plan } })
);
