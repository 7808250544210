import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ContainerVolumeSlider from './ContainerVolumeSlider';

const BuildPlan = ({
  selectedPack,
  currentVolume,
  isCurrentPack,
  availableVolumes,
  volumeList,
  preSelectedVolume,
  onUpgrade,
  isStarter,
  onVolumeChange,
  period,
}) => {
  return (
    <div className="w-full flex flex-col space-y-8">
      <div className="flex flex-col space-y-20">
        <div className="flex flex-col space-y-20">
          <div className="flex flex-col space-y-8">
            {/* title */}
            <div className="flex flex-col space-y-4">
              {selectedPack.upsellPack && (
                <div
                  className="flex space-x-2 items-center cursor-pointer"
                  onClick={() => onUpgrade(selectedPack.upsellPack.id)}
                >
                  <h2 className="text-orange font-bold">
                    Upgrade to {selectedPack.upsellPack.displayName} to track
                    more containers
                  </h2>
                  <ArrowRightAltIcon className="text-orange" />
                </div>
              )}
              <h1 style={{ fontSize: '36px' }} className="font-bold text-dark">
                {isCurrentPack ? 'Your' : 'Build your'}{' '}
                <span className="text-blue">{selectedPack.displayName}</span>{' '}
                Plan
              </h1>
            </div>
            {/* description */}
            <div className="flex flex-col space-y-2">
              <h2 className="font-medium text-dark">
                How many containers do you move every year?
              </h2>
              <p className="font-regular">
                Select a volume that reflects the size of your operations.
              </p>
              {isCurrentPack && (
                <h5 className="uppercase font-bold text-gray-200">
                  current plan - {currentVolume} containers
                </h5>
              )}
            </div>
            {/* slider */}
          </div>
          <div>
            <ContainerVolumeSlider
              minVolume={currentVolume}
              volumeList={volumeList}
              preSelectedVolume={preSelectedVolume}
              availableVolumes={availableVolumes}
              period={period}
              onVolumeChange={onVolumeChange}
              disabled={isStarter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildPlan;
