import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import MemberDesc from './RolesDescription/MemberDesc';
import AdminDesc from './RolesDescription/AdminDesc';

const UserRightField = ({ classes, value, name, handleChange }) => {
  return (
    <div>
      <RadioGroup
        name={name}
        className={classes.group}
        value={String(value)}
        onChange={(_, value) => {
          handleChange({
            target: { value: value === 'true', name: 'is_admin' },
          });
        }}
      >
        <FormControlLabel
          value={'false'}
          control={<Radio color="primary" />}
          label={<Typography variant="caption">Member</Typography>}
        />
        <FormControlLabel
          value={'true'}
          control={<Radio color="primary" />}
          label={<Typography variant="caption">Admin</Typography>}
        />
      </RadioGroup>
      <Typography
        align="left"
        variant="caption"
        className={classes.information}
      >
        {value ? (
          <AdminDesc classes={{ root: classes.descRoot }} />
        ) : (
          <MemberDesc classes={{ root: classes.descRoot }} />
        )}
      </Typography>
    </div>
  );
};

UserRightField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const styles = theme => ({
  group: {
    flexDirection: 'row',
  },
  information: {
    margin: 2 * theme.spacing.unit,
    maxWidth: 240,
    minHeight: 100,
  },
  descRoot: {
    width: 240,
  },
});

export default withStyles(styles)(UserRightField);
