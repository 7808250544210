// Actions types
export const SET_SHIPPING_LINES =
  'core/api/v1/erl/instructionFilter/SET_SHIPPING_LINES';
export const SET_CONTAINER_TYPES =
  'core/api/v1/erl/instructionFilter/SET_CONTAINER_TYPES';
export const SET_TERMINALS = 'core/api/v1/erl/instructionFilter/SET_TERMINALS';
export const CLEAR_FILTERS = 'core/api/v1/erl/instructionFilter/CLEAR_FILTERS';

// Reducer
const initialState = {
  shippingLines: [],
  containerTypes: [],
  terminals: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPPING_LINES:
      return {
        ...state,
        shippingLines: action.payload,
      };

    case SET_CONTAINER_TYPES:
      return {
        ...state,
        containerTypes: action.payload,
      };

    case SET_TERMINALS:
      return {
        ...state,
        terminals: action.payload,
      };

    case CLEAR_FILTERS:
      return initialState;

    default:
      return state;
  }
};

// Action creators
export const setShippingLinesFilters = data => ({
  type: SET_SHIPPING_LINES,
  payload: data,
});

export const setContainerTypesFilters = data => ({
  type: SET_CONTAINER_TYPES,
  payload: data,
});

export const setTerminalsFilters = data => ({
  type: SET_TERMINALS,
  payload: data,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});

// Side effects
export const setShippingLinesFiltersAndReduceTerminals = shippingLinesFilter => (
  dispatch,
  getState
) => {
  dispatch(setShippingLinesFilters(shippingLinesFilter));
  const {
    availabilities: { mappingShippingLine },
    erlData: { ports },
  } = getState();

  const relatedPortKey = mappingShippingLine
    .filter(mapping =>
      shippingLinesFilter.find(
        shippingLine => shippingLine.key === mapping.shipping_line
      )
    )
    .map(map => map.port);
  const relatedPort = ports.filter(terminal =>
    relatedPortKey.includes(terminal.key)
  );
  dispatch(
    setTerminalsFilters(
      relatedPort.map(port => ({ value: port.pier, label: port.pier }))
    )
  );
};
