import React from 'react';

const GridTwoCol = ({ leftContent, rightContent }) => {
  return (
    <div className="flex flex-col space-y-10 md:flex-row md:space-y-0 w-full">
      <div className="md:w-7/12">{leftContent}</div>
      <div className="md:w-5/12 md:ml-10">{rightContent}</div>
    </div>
  );
};

export default GridTwoCol;
