import React from 'react';
import { Link, Navigate } from 'react-router-dom';

import BCBrandingFooter from 'components/BCBrandingFooter';
import SingleContainerTypePicker from 'components/SingleContainerTypePicker';

import { ReactComponent as ContainerIcon } from 'assets/images/Vector.svg';
import { ReactComponent as BlueCargoLogo } from 'assets/logos/logo-blue-transparent-horizontal.svg';

import PricingPresentation from 'components/PricingPresentation';
import { pageTitlesAndUrls } from 'constants/pages';
import styles from '../../styles/typography.module.css';

const Home = ({ isAdmin, isLogged }) => {
  if (isLogged) {
    return <Navigate to={pageTitlesAndUrls.containerTracking.path} />;
  }

  return (
    <div className={styles.font}>
      <div className="relative w-full px-4 h-20 flex items-center justify-center">
        <BlueCargoLogo className="w-44" />
        <div className="absolute right-6">
          <Link to="/signin">
            <button className="px-6 py-2 bg-blue-light border border-blue rounded-sm text-sm font-medium text-blue hover:bg-blue hover:text-white">
              Login to your account
            </button>
          </Link>
        </div>
      </div>
      <div className={`w-screen bg-blue-600 pb-16 ${styles.font}`}>
        <div className="w-10/12 m-auto flex flex-col items-center">
          <div className="flex flex-col items-center space-y-10">
            <ContainerIcon />
            <h1 className="text-dark font-bold tracking-wide">
              Minimize the cost of empty returns with BlueCargo
            </h1>
            <SingleContainerTypePicker />
            <h3 className="font-medium">
              Or access the full platform - No credit card required
            </h3>
            <Link to="/signup">
              <button className="px-20 py-4 bg-orange rounded-full text-lg font-bold text-white">
                Try 15 Days Free
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-screen bg-white py-24 text-center">
        <div className="flex flex-col space-y-4">
          <h1 className="font-bold capitalize">Start using BlueCargo now!</h1>
          <div className="flex flex-col space-y-6">
            <h3>Try all Per Diem Fighter Plan features free for 15 days</h3>
            <Link to="/signup">
              <button className="px-20 py-4 bg-orange rounded-full text-lg font-bold text-white">
                Try 15 Days Free
              </button>
            </Link>
          </div>
        </div>
        <PricingPresentation
          showPlanButtons={false}
          showFreeTrialButtons={true}
          isAdmin={isAdmin}
        />
        <Link to="/signup">
          <button className="px-20 py-4 bg-orange rounded-full text-lg font-bold text-white">
            Try 15 Days Free
          </button>
        </Link>
      </div>

      <BCBrandingFooter
        termsOfUseUri={'/terms-of-use/'}
        privacyPolicy={'/privacy-policy/'}
      />
    </div>
  );
};

export default Home;
