import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeProvider } from '../../themeProvider';
import VesselSchedulePage from './VesselSchedulePage';

class VesselScheduleV2 extends React.Component {
  render() {
    return (
      <ThemeProvider theme={themeProvider}>
        <VesselSchedulePage />
      </ThemeProvider>
    );
  }
}

export default VesselScheduleV2;
