import React from 'react';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';

const UpgradeYourPlan = ({
  handleUpgradeClick,
  subtitle,
  descriptionTitle,
  bullets,
}) => {
  return (
    <div className="w-full h-screen bg-white">
      <div className="w-10/12 h-full m-auto pt-32 flex flex-col space-y-12">
        <div className="flex flex-col space-y-2 justify-start items-start">
          <h1 className="font-bold">
            This awesome feature is waiting for you, once you upgrade your plan!
          </h1>
          <h2 className="font-medium pb-4">{subtitle}</h2>
          <Button
            variant="contained"
            color="secondary"
            label="Upgrade Plan"
            onClick={handleUpgradeClick}
            sx={{
              minHeight: 48,
              minWidth: 224,
              fontSize: 18,
              letterSpacing: 0,
              fontWeight: 'medium',
              boxShadow: 5,
            }}
          >
            Upgrade my plan
          </Button>
        </div>
        <div className="flex flex-col space-y-2">
          <hr className="border border-blue-500 mb-4" />
          <h2 className="font-medium">{descriptionTitle}</h2>
          {bullets.map((bullet, index) => {
            return (
              <div key={index} className="flex space-x-2 pt-1">
                <CheckIcon />
                <h3 className="font-regular">{bullet}</h3>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UpgradeYourPlan;
