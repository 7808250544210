import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import BackupIcon from '@mui/icons-material/Backup';
import pluralize from 'pluralize';
import * as mime from 'react-native-mime-types';

const FileUpload = ({
  fileExtensions = ['csv'],
  maxFiles = 1,
  maxSize = null,
  onFileSelected = acceptedFiles => {},
}) => {
  const onDrop = useCallback(acceptedFiles => {
    onFileSelected(acceptedFiles);
  }, []);

  const translateErrorMessage = error => {
    switch (error.code) {
      case 'too-many-files':
        return `You can upload a maximum of ${maxFiles} ${pluralize(
          'file',
          maxFiles
        )}`;
      case 'file-invalid-type':
        return `Wrong file type. Supported files: ${fileExtensions
          .map(e => '.' + e)
          .join(',')}`;
      default:
        return error.message;
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    maxFiles,
    maxSize,
    multiple: maxFiles === 0 || maxFiles > 1,
    onDrop,
    accept: fileExtensions.map(fileType => mime.lookup(fileType)),
  });

  let fileTypeText = '';
  if (fileExtensions.length > 1) {
    fileTypeText =
      'All ' +
      fileExtensions
        .map(fileType => `.${fileType}`)
        .slice(0, -1)
        .join(', ') +
      ' and ' +
      `.${fileExtensions.slice(-1)}`;
  } else {
    fileTypeText = `.${fileExtensions[0]}`;
  }

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path} className="text-dark">
      {file.path}
    </li>
  ));
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path}
      <ul>
        {errors.map(e => (
          <li key={e.code}>{translateErrorMessage(e)}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <div
      className="bg-blue-600 border-dashed border border-gray-400 py-8 rounded-md"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="w-full h-full flex items-center justify-center">
        <div>
          <BackupIcon className="text-gray-300" style={{ fontSize: 64 }} />
        </div>
        <div className="flex flex-col items-center content-center ml-4 text-gray-300">
          {isDragActive ? (
            <h4>Drop your file here</h4>
          ) : acceptedFiles.length === 0 && fileRejections.length === 0 ? (
            <>
              <h4>
                Drag and drop or{' '}
                <span className="text-blue underline cursor-pointer">
                  choose a file
                </span>{' '}
                to upload your Containers.
              </h4>
              <h4>
                {fileTypeText} file {pluralize('type', fileExtensions.length)}{' '}
                are supported.{' '}
                {maxSize && `(Maximum size ${maxSize / (1024 * 1024)} MB)`}
              </h4>
            </>
          ) : (
            <>
              <ul>{acceptedFileItems}</ul>
              <ul className="text-red">{fileRejectionItems}</ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
