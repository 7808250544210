import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageUtil } from 'utils/local-storage.util';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY } from 'constants/user';
import { useUserContext } from 'common/contexts/UserContext';
import LoadingComponent from 'components/LoadingComponent';
import { pageTitlesAndUrls } from 'constants/pages';

const PrivateRoute = ({ children }) => {
  const token = LocalStorageUtil.get(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  const { user, fetchUser } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (!token) {
    return (
      <Navigate
        to={{
          pathname: pageTitlesAndUrls.signIn.path,
          state: { from: location },
        }}
        replace
      />
    );
  }
  if (token && !user) {
    fetchUser().catch(() => {
      localStorage.clear();
      navigate({
        pathname: pageTitlesAndUrls.signIn.path,
        state: { from: location },
      });
    });
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <LoadingComponent isLoading={true} size={55} />
      </div>
    );
  }

  return children;
};

export default PrivateRoute;
