import { createSelector } from 'reselect';
import { bugsnagClient } from 'services/helpers/bugsnag';

// Action types
export const SET_ERROR = 'core/api/v1/erl/error/SET_ERROR';
export const CLEAR_ERROR = 'core/api/v1/erl/error/CLEAR_ERROR';
export const SET_PAYMENT_ERROR = 'core/api/v1/erl/error/SET_PAYMENT_ERROR';
export const CLEAR_PAYMENT_ERROR = 'core/api/v1/erl/error/CLEAR_PAYMENT_ERROR';

// Reducer
const initialState = {
  message: '',
  paymentMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        message: action.payload,
      };
    case SET_PAYMENT_ERROR:
      return {
        ...state,
        paymentMessage: action.payload,
      };
    case CLEAR_PAYMENT_ERROR:
      return {
        ...state,
        paymentMessage: '',
      };
    case CLEAR_ERROR:
      return {
        ...state,
        message: '',
      };
    default:
      return state;
  }
};

// Action creators
export const setError = (errMessage, err) => {
  if (err) {
    bugsnagClient.notify(err);
  }
  return {
    type: SET_ERROR,
    payload: errMessage,
  };
};

export const setPaymentError = (errMessage, err) => {
  bugsnagClient.notify(err);
  return {
    type: SET_PAYMENT_ERROR,
    payload: errMessage,
  };
};

export const clearError = () => ({ type: CLEAR_ERROR });
export const clearPaymentError = () => ({ type: CLEAR_PAYMENT_ERROR });

// Selectors
export const isError = createSelector(
  state => state.error.message,
  errMsg => Boolean(errMsg)
);

export const isPaymentError = createSelector(
  state => state.error.paymentMessage,
  errMsg => Boolean(errMsg)
);
