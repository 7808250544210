import { getPage } from 'constants/pages';
import _ from 'lodash';

export const checkPermissions = (user, path) => {
  const permissions = _.get(user, 'permissions');
  const pageConfig = getPage(path);
  const requiredPermission = _.get(pageConfig, 'requiredPermission', []);
  const requiresSuperUser = _.get(pageConfig, 'isSuperUser', false);
  if (requiresSuperUser) {
    return _.get(user, 'is_superuser', false);
  }
  const hasPermission = _.get(permissions, requiredPermission, true);
  return hasPermission;
};
