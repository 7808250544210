import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import RefreshButton from 'components/RefreshButton';

import { availableShifts, displayShift } from 'services/helpers/shift';

export default class ShiftPicker extends Component {
  static propTypes = {
    selectedShift: PropTypes.object.isRequired,
    changeSelectedShift: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  handleShift = (event, shift) =>
    shift && this.props.changeSelectedShift(shift);

  render() {
    const { selectedShift, classes } = this.props;
    return (
      <div className={classes.container}>
        <ToggleButtonGroup
          value={selectedShift}
          exclusive
          onChange={this.handleShift}
          classes={{ root: classes.buttonGroup }}
        >
          {availableShifts.map(shift => (
            <ToggleButton
              disableRipple
              value={shift}
              key={shift.day + shift.shift}
              classes={{ root: classes.root, selected: classes.selected }}
            >
              <Typography
                variant="caption"
                className={cn(classes.label, {
                  [classes.labelSelected]: selectedShift === shift,
                })}
              >
                {displayShift(shift)}
              </Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div className={classes.reload}>
          <RefreshButton />
        </div>
      </div>
    );
  }
}
