import React, { useEffect, useMemo, useState, useRef } from 'react';
import { TextField } from '@mui/material';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { brandColors } from 'brandColors';
import { Formik } from 'formik';
import countryList from 'react-select-country-list';
import Autocomplete from '@mui/material/Autocomplete';
import _ from 'lodash';

const NewCreditCardForm = ({ onFormIsValid }) => {
  const stripe = useStripe();
  const stripeElements = useElements();
  const [stripeCardFormState, setStripeCardFormState] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const formikRef = useRef();
  const countryOptions = useMemo(() => countryList().getData(), []);
  useEffect(() => onFormIsValid(false, {}), []);

  const validateForm = values => {
    const errors = {};
    if (!values.cardName) errors.cardName = 'Required';
    if (!values.address1) errors.address1 = 'Required';
    if (!values.city) errors.city = 'Required';
    if (!values.zipCode) errors.zipCode = 'Required';
    if (!values.country) errors.country = 'Required';
    if (!values.state && values.country === 'US') errors.state = 'Required';
    onFormIsValid(_.isEmpty(errors) && _.every(stripeCardFormState, Boolean), {
      ...values,
      stripe,
      stripeElements,
      type: 'new_card',
    });
    return errors;
  };

  const handleStripOnChange = (key, isComplete) => {
    setStripeCardFormState({
      ...stripeCardFormState,
      [key]: isComplete,
    });
  };

  useEffect(() => {
    formikRef.current.validateForm();
  }, [stripeCardFormState]);

  return (
    <Formik
      initialValues={{
        cardName: '',
        address1: '',
        address2: '',
        city: '',
        zipCode: '',
        state: '',
        country: 'US',
      }}
      validate={validateForm}
      ref={formikRef}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => {
        return (
          <div className="flex flex-col space-y-4">
            <h3 className="font-bold">Card Informations</h3>
            <div className="flex flex-col space-y-2">
              <h4 className="font-medium">Name on the card *</h4>
              <TextField
                name="cardName"
                onBlur={handleBlur}
                value={values.cardName}
                onChange={handleChange}
                variant="outlined"
                placeholder="Name on the card"
                fullWidth
                error={touched.cardName && Boolean(errors.cardName)}
                helperText={touched.cardName && errors.cardName}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <h4 className="font-medium">Card Number</h4>
              <CardNumberElement
                onChange={e => handleStripOnChange('cardNumber', e.complete)}
                className="border border-blue-400 p-4 hover:border-blue rounded"
                options={{
                  style: {
                    base: {
                      '::placeholder': {
                        color: brandColors.colors.gray[500],
                      },
                    },
                  },
                  showIcon: true,
                }}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <div className="flex space-x-4 space-between">
                <div className="w-full flex flex-col space-y-2">
                  <h4 className="font-medium">Expiration Date</h4>
                  <CardExpiryElement
                    onChange={e =>
                      handleStripOnChange('cardExpiry', e.complete)
                    }
                    className="border border-blue-400 p-4 hover:border-blue rounded"
                    options={{
                      style: {
                        base: {
                          '::placeholder': {
                            color: brandColors.colors.gray[500],
                          },
                        },
                      },
                    }}
                  />
                </div>
                <div className="w-full flex flex-col space-y-2">
                  <h4 className="font-medium">CCV</h4>
                  <CardCvcElement
                    onChange={e => handleStripOnChange('cardCvc', e.complete)}
                    className="border border-blue-400 p-4 hover:border-blue rounded"
                    options={{
                      style: {
                        base: {
                          '::placeholder': {
                            color: brandColors.colors.gray[500],
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <h3 className="font-bold">Billing Details</h3>
            <div className="flex flex-col space-y-2">
              <h4 className="font-medium">Address line 1 *</h4>
              <TextField
                name="address1"
                value={values.address1}
                onChange={handleChange}
                variant="outlined"
                placeholder="Address line 1"
                fullWidth
                onBlur={handleBlur}
                error={touched.address1 && Boolean(errors.address1)}
                helperText={touched.address1 && errors.address1}
              />
              <h4 className="font-medium">Address line 2</h4>
              <TextField
                name="address2"
                value={values.address2}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                placeholder="Address line 2"
                fullWidth
              />
              <div className="flex space-x-4">
                <div className="flex flex-col space-y-2 w-1/2">
                  <h4 className="font-medium">City *</h4>
                  <TextField
                    name="city"
                    value={values.city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder="City"
                    fullWidth
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </div>
                <div className="flex flex-col space-y-2 w-1/2">
                  <h4 className="font-medium">ZIP Code *</h4>
                  <TextField
                    name="zipCode"
                    value={values.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    variant="outlined"
                    placeholder="ZIP"
                    fullWidth
                    error={touched.zipCode && Boolean(errors.zipCode)}
                    helperText={touched.zipCode && errors.zipCode}
                  />
                </div>
              </div>
              <div className="flex space-x-4">
                <div className="flex flex-col space-y-2 w-1/2">
                  <h4 className="font-medium">State *</h4>
                  <div className="flex flex-col space-y-2">
                    <TextField
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      placeholder="State"
                      fullWidth
                      error={touched.state && Boolean(errors.state)}
                      helperText={touched.state && errors.state}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-2 w-1/2">
                  <h4 className="font-medium">Country *</h4>
                  <Autocomplete
                    disablePortal
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    options={countryOptions}
                    name="country"
                    defaultValue={{ value: 'US', label: 'United States' }}
                    onBlur={handleBlur}
                    disableClearable
                    onChange={(e, country) =>
                      setFieldValue('country', country && country.value)
                    }
                    renderInput={params => (
                      <TextField
                        name="country"
                        {...params}
                        variant="outlined"
                        error={touched.country && Boolean(errors.country)}
                        helperText={touched.country && errors.country}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default NewCreditCardForm;
