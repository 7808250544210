import React from 'react';
import Form from './Form';
import styles from './Form.style';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getShippingLines,
  getContainerTypes,
  setShippingLines,
  setContainerTypes,
} from 'ducks/erlData';
import { useNavigate } from 'react-router-dom';

const mapStateToProp = state => ({
  shippingLines: getShippingLines(state),
  containerTypes: getContainerTypes(state),
});

const mapDispatchToProps = dispatch => ({
  importData: () => {
    dispatch(setShippingLines());
    dispatch(setContainerTypes());
  },
});

const withHooksHOC = Component => {
  return props => {
    const navigate = useNavigate();

    return <Component navigate={navigate} {...props} />;
  };
};

export default compose(
  connect(mapStateToProp, mapDispatchToProps),
  withStyles(styles),
  withHooksHOC
)(Form);
