/*
  Inspired by https://github.com/TeamWertarbyte/material-ui-chip-input/blob/v1.1.0/src/ChipInput.js
*/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Input from '@mui/material/Input';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import cx from 'classnames';
import _ from 'lodash';
import { IconButton } from '@material-ui/core';

const keyCodes = {
  BACKSPACE: 8,
  ENTER: 13,
  SPACE: 32,
  UP_ARROW: 38,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  DELETE: 46,
};

export class ChipInput extends React.Component {
  state = {
    chips: [],
    errorText: undefined,
    focusedChip: null,
    inputValue: '',
    isFocused: false,
    chipsUpdated: false,
  };

  constructor(props) {
    super(props);
    if (props.defaultValue) {
      this.state.chips = props.defaultValue;
    }
    this.input = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;

    if (props.disabled) {
      newState = { ...newState, focusedChip: null };
    }

    if (!state.chipsUpdated && props.defaultValue) {
      newState = { ...newState, chips: props.defaultValue };
    }

    return newState;
  }

  focus = () => {
    this.actualInput.focus();
    if (this.state.focusedChip != null) {
      this.setState({ focusedChip: null });
    }
  };

  handleInputBlur = event => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
    this.setState({ isFocused: false });
    if (this.state.focusedChip != null) {
      this.setState({ focusedChip: null });
    }
    const value = event.target.value;
    let addChipOptions;
    switch (this.props.blurBehavior) {
      case 'add-or-clear':
        addChipOptions = { clearInputOnFail: true };
        this.handleAddChip(value, addChipOptions);
        break;
      case 'add':
        this.handleAddChip(value, addChipOptions);
        break;
      case 'clear':
        this.clearInput();
        break;
      default:
        break;
    }
  };

  handleInputFocus = event => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  handleOnChipEdited = (index, value) => {
    let chips = [...this.state.chips];
    if (this.props.dataSourceConfig) {
      chips[index] = {
        ...chips[index],
        value,
      };
    } else {
      chips[index] = value;
    }
    this.setState({ isFocused: false, focusedChip: null });
    this.updateChips(chips);
  };

  scrollToChip = (
    keycode,
    focusedChip,
    chips,
    inputValue,
    scrollToMostLeft = false
  ) => {
    let chipIndex = null;
    switch (keycode) {
      case keyCodes.BACKSPACE:
      case keyCodes.DELETE:
        if (inputValue === '') {
          if (focusedChip === 0) {
            chipIndex = 0;
          } else if (focusedChip) {
            chipIndex = focusedChip - 1;
          } else {
            chipIndex = chips.length - 1;
          }
        }
        break;
      case keyCodes.LEFT_ARROW:
        if (focusedChip == null && inputValue === '' && chips.length) {
          chipIndex = chips.length - 1;
        } else if (focusedChip != null && focusedChip > 0) {
          chipIndex = focusedChip - 1;
        } else {
          return;
        }
        break;
      case keyCodes.RIGHT_ARROW:
        if (focusedChip != null && focusedChip < chips.length - 1) {
          chipIndex = focusedChip + 1;
        } else {
          chipIndex = null;
        }
        break;
      default:
        break;
    }

    const root = document.querySelector('#chip-input-container');
    if (!root) {
      return;
    }
    let elem = null;
    if (chipIndex === null) {
      elem = root.querySelector(`#${this.props.id}`);
    } else {
      elem = root.querySelectorAll('.MuiChip-root')[chipIndex];
    }
    if (elem) {
      const elemBoundRect = elem.getBoundingClientRect();
      const rootBoundRect = root.getBoundingClientRect();
      let targetPosition = null;

      if (elemBoundRect.x <= rootBoundRect.x) {
        targetPosition = root.scrollLeft - elem.offsetWidth;
      } else if (
        elemBoundRect.x + elemBoundRect.width >
        rootBoundRect.x + rootBoundRect.width
      ) {
        targetPosition = root.scrollLeft + elem.offsetWidth;
      }

      if (scrollToMostLeft) {
        targetPosition =
          elem.offsetWidth + rootBoundRect.x + rootBoundRect.width;
      }

      if (targetPosition) {
        root.scrollTo({
          left: targetPosition,
        });
      }
    }
  };

  handleKeyDown = async event => {
    const { focusedChip } = this.state;
    this._keyPressed = false;
    this._preventChipCreation = false;
    if (this.props.onKeyDown) {
      // Needed for arrow controls on menu in autocomplete scenario
      this.props.onKeyDown(event);
      // Check if the callback marked the event as isDefaultPrevented() and skip further actions
      // enter key for example should not always add the current value of the inputField
      if (event.isDefaultPrevented()) {
        return;
      }
    }
    const chips = this.props.value || this.state.chips;
    if (this.props.newChipKeyCodes.indexOf(event.keyCode) >= 0) {
      const result = this.handleAddChip(event.target.value);
      if (result !== false) {
        event.preventDefault();
      }
      return;
    }

    this.scrollToChip(event.keyCode, focusedChip, chips, event.target.value);
    switch (event.keyCode) {
      case keyCodes.BACKSPACE:
        if (event.target.value === '') {
          if (focusedChip != null) {
            this.handleDeleteChip(chips[focusedChip], focusedChip);
            if (focusedChip > 0) {
              this.setState({ focusedChip: focusedChip - 1 });
            }
          } else {
            this.setState({ focusedChip: chips.length - 1 });
          }
          event.preventDefault();
        }
        break;
      case keyCodes.DELETE:
        if (event.target.value === '' && focusedChip != null) {
          this.handleDeleteChip(chips[focusedChip], focusedChip);
          if (focusedChip <= chips.length - 1) {
            this.setState({ focusedChip });
          }
          event.preventDefault();
        }
        break;
      case keyCodes.LEFT_ARROW:
        if (focusedChip == null && event.target.value === '' && chips.length) {
          this.setState({ focusedChip: chips.length - 1 });
        } else if (focusedChip != null && focusedChip > 0) {
          this.setState({ focusedChip: focusedChip - 1 });
        }
        break;
      case keyCodes.RIGHT_ARROW:
        if (focusedChip != null && focusedChip < chips.length - 1) {
          this.setState({ focusedChip: focusedChip + 1 });
        } else {
          this.setState({ focusedChip: null });
        }
        break;
      default:
        this.setState({ focusedChip: null });
        break;
    }
  };

  handleKeyUp = event => {
    if (
      !this._preventChipCreation &&
      this.props.newChipKeyCodes.indexOf(event.keyCode) >= 0 &&
      this._keyPressed
    ) {
      this.clearInput();
    } else {
      this.updateInput(event.target.value);
    }
    if (this.props.onKeyUp) {
      this.props.onKeyUp(event);
    }
  };

  handleKeyPress = event => {
    this._keyPressed = true;
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event);
    }
  };

  handleUpdateInput = e => {
    if (this.props.inputValue == null) {
      this.updateInput(e.target.value);
    }

    if (this.props.onUpdateInput) {
      this.props.onUpdateInput(e);
    }
  };

  handleAddChip(chip, options) {
    let chips = [];
    if (!_.isArray(chip)) {
      chips = [chip];
      if (this.props.onBeforeAdd && !this.props.onBeforeAdd(chip)) {
        this._preventChipCreation = true;
        if (options != null && options.clearInputOnFail) {
          this.clearInput();
        }
        return false;
      }
    } else {
      chips = chip;
    }

    const updatedChips = [];
    const transformChipValue = this.props.transformChipValue;
    this.clearInput();
    for (let chip of chips) {
      const chips = this.props.value || this.state.chips;
      if (this.props.removeCharacters) {
        const re = new RegExp(`${this.props.removeCharacters.join('|')}`, 'g');
        chip = chip.replace(re, '');
      }

      if (this.props.dataSourceConfig) {
        if (typeof chip === 'string') {
          chip = {
            [this.props.dataSourceConfig.text]: chip,
            [this.props.dataSourceConfig.value]: transformChipValue
              ? transformChipValue(chip)
              : chip,
          };
        }

        if (
          this.props.allowDuplicates ||
          !chips.some(
            c =>
              c[this.props.dataSourceConfig.value] ===
              chip[this.props.dataSourceConfig.value]
          )
        ) {
          if (this.props.value && this.props.onAdd) {
            this.props.onAdd(chip);
          } else {
            updatedChips.push(chip);
          }
        }
        return true;
      } else {
        chip = transformChipValue ? transformChipValue(chip) : chip;
        if (chip.trim().length > 0) {
          if (this.props.allowDuplicates || chips.indexOf(chip) === -1) {
            if (this.props.value && this.props.onAdd) {
              this.props.onAdd(chip);
            } else {
              updatedChips.push(chip);
            }
          }
        }
      }
    }

    if (updatedChips.length === 0) {
      return false;
    }
    this.updateChips([...this.state.chips, ...updatedChips]);
    return true;
  }

  handleDeleteChip(chip, i) {
    if (!this.props.value) {
      const chips = this.state.chips.slice();
      const changed = chips.splice(i, 1);
      if (changed) {
        let focusedChip = this.state.focusedChip;
        if (this.state.focusedChip === i) {
          focusedChip = null;
        } else if (this.state.focusedChip > i) {
          focusedChip = this.state.focusedChip - 1;
        }
        this.updateChips(chips, { focusedChip });
      }
    } else if (this.props.onDelete) {
      this.props.onDelete(chip, i);
    }
  }

  copyAllChips = () => {
    const toClipBoard = [];
    for (let chip of this.state.chips) {
      if (this.props.dataSourceConfig) {
        toClipBoard.push(chip.value);
      } else {
        toClipBoard.push(chip);
      }
    }
    navigator.clipboard.writeText(toClipBoard.join(' '));
  };

  handlePaste = event => {
    if (
      !this.props.pasteSeparators ||
      (Array.isArray(this.props.pasteSeparators) &&
        this.props.pasteSeparators.length === 0)
    ) {
      console.warn('Missing pasteSeparators. Paste event unhandled.');
    }
    let pastedText = event.clipboardData.getData('Text');
    event.preventDefault();
    const tmpSeparator = this.props.pasteSeparators[0];
    for (var i = 1; i < this.props.pasteSeparators.length; i++) {
      pastedText = pastedText
        .split(this.props.pasteSeparators[i])
        .join(tmpSeparator);
    }
    const chips = _.compact(pastedText.split(tmpSeparator));
    this.handleAddChip(chips);
    setTimeout(() => {
      this.scrollToChip(null, null, [], '', true);
    }, 500);
  };

  async updateChips(chips, additionalUpdates = {}) {
    await this.setState({ chips, chipsUpdated: true, ...additionalUpdates });
    if (this.props.onChange) {
      this.props.onChange(chips);
    }
  }

  clearInput() {
    this.updateInput('');
  }

  updateInput(value) {
    this.setState({ inputValue: value });
  }

  setActualInputRef = ref => {
    this.actualInput = ref;
    if (this.props.inputRef) {
      this.props.inputRef(ref);
    }
  };

  render() {
    const {
      alwaysShowPlaceholder,
      checkIfChipIsValid,
      chipRenderer = defaultChipRenderer,
      dataSourceConfig,
      disabled,
      disableUnderline,
      error,
      fullWidth,
      id,
      InputProps = {},
      inputValue,
      placeholder,
      name,
      readOnly,
      transformChipLabel,
      value,
      onCopyAlertText,
    } = this.props;
    const { openToast } = this.state;

    const classes = _.merge(defaultProps.classes, this.props.classes || {});

    const chips = value || this.state.chips;
    const actualInputValue =
      inputValue != null ? inputValue : this.state.inputValue;

    const chipComponents = chips.map((chip, i) => {
      const value = dataSourceConfig ? chip[dataSourceConfig.value] : chip;
      return chipRenderer(
        {
          text: dataSourceConfig ? chip[dataSourceConfig.text] : chip,
          isValid: checkIfChipIsValid ? checkIfChipIsValid(value) : true,
          isDisabled: !!disabled,
          isReadOnly: readOnly,
          isFocused: this.state.focusedChip === i,
          handleClick: () => this.setState({ focusedChip: i }),
          handleDelete: () => this.handleDeleteChip(chip, i),
          classes: classes.chip,
          transformChipLabel: transformChipLabel,
          onChipEdited: this.handleOnChipEdited,
        },
        i
      );
    });

    const InputComponent = Input;
    InputComponent.startAdornment = <>{chipComponents}</>;

    return (
      <>
        <div
          id="chip-input-container"
          className={cx(classes.inputContainer, classes.chipContainer, {
            [classes.focused]: this.state.isFocused,
            [classes.disabled]: disabled,
            [classes.error]: error,
          })}
        >
          {chipComponents}
          <InputComponent
            ref={this.input}
            disableUnderline={disableUnderline}
            fullWidth={fullWidth}
            classes={{
              input: cx(classes.input),
              root: cx(classes.inputRoot),
            }}
            id={id}
            name={name}
            value={actualInputValue}
            onChange={this.handleUpdateInput}
            onKeyDown={this.handleKeyDown}
            onKeyPress={this.handleKeyPress}
            onKeyUp={this.handleKeyUp}
            onFocus={this.handleInputFocus}
            onPaste={this.handlePaste}
            onBlur={this.handleInputBlur}
            inputRef={this.setActualInputRef}
            disabled={disabled}
            placeholder={
              alwaysShowPlaceholder
                ? placeholder
                : chips.length > 0
                ? null
                : placeholder
            }
            readOnly={readOnly}
            inputProps={InputProps}
          />
        </div>
        {chips.length > 0 && (
          <div className="h-full bg-blue-600 border-l border-blue-400 rounded-r absolute right-0">
            <IconButton
              disabled={!chips.length}
              onClick={() => {
                this.copyAllChips();
                this.setState({ openToast: true });
              }}
              className="flex space-x-1 items-center p-2.5 w-full h-full"
              children={
                <FileCopyIcon
                  style={{ fontSize: 14 }}
                  className="text-gray-200"
                />
              }
            />
            <Snackbar
              open={openToast}
              autoHideDuration={2000}
              onClose={() => this.setState({ openToast: false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              // TODO: Find a way to do that in themeProvider with anchorOriginTopCenter
              sx={{
                top: '88px !important',
              }}
            >
              <Alert
                icon={false}
                severity="success"
                onClose={() => this.setState({ openToast: false })}
              >
                {onCopyAlertText || 'Successfully copied to your clipboard'}
              </Alert>
            </Snackbar>
          </div>
        )}
      </>
    );
  }
}

const defaultProps = {
  allowDuplicates: false,
  alwaysShowPlaceholder: false,
  blurBehavior: 'add',
  classes: {
    inputContainer: 'w-full h-full flex items-center',
  },
  disableUnderline: true,
  fullWidth: true,
  id: 'chip-input-element',
  name: 'Search',
  newChipKeyCodes: [keyCodes.ENTER, keyCodes.SPACE],
  pasteSeparators: [',', ' ', '\n', '\r'],
};
ChipInput.defaultProps = defaultProps;

export const defaultChipRenderer = (
  {
    text,
    isDisabled,
    isValid,
    isReadOnly,
    isFocused,
    handleClick,
    handleDelete,
    classes,
    transformChipLabel,
    onChipEdited,
  },
  key
) => {
  return (
    <ChipComponent
      key={key}
      index={key}
      text={text}
      isDisabled={isDisabled}
      isValid={isValid}
      isReadOnly={isReadOnly}
      isFocused={isFocused}
      handleClick={handleClick}
      handleDelete={handleDelete}
      classes={classes}
      transformChipLabel={transformChipLabel}
      onChipEdited={onChipEdited}
    />
  );
};

export const ChipComponent = ({
  index,
  text,
  isDisabled,
  isValid,
  isReadOnly,
  isFocused,
  handleClick,
  handleDelete,
  classes,
  transformChipLabel,
  onChipEdited,
}) => {
  let variant = 'chip-primary';

  if (!isValid) {
    variant = 'chip-error';
  }

  const [isOnEdit, setOnEdit] = useState(0);
  const [inputWidth, setInputWidth] = useState(0);

  const handleOnClick = () => {
    setOnEdit(true);
    const context = document.createElement('canvas').getContext('2d');
    setInputWidth(context.measureText(text, '0.75rem').width);
    handleClick();
  };

  const handleOnBlur = e => {
    setOnEdit(false);
    onChipEdited(index, e.target.value);
  };

  return (
    <>
      {isOnEdit ? (
        <Input
          autoFocus
          classes={{
            root: 'first:ml-4',
          }}
          onBlur={handleOnBlur}
          variant="chip-input"
          disableUnderline={true}
          sx={{
            input: {
              width: `${inputWidth + 24}px`,
            },
          }}
          defaultValue={text}
          endAdornment={
            <CloseIcon
              onClick={handleDelete}
              style={{ fontSize: '14px', margin: '0 0 0 0.25rem' }}
            />
          }
        />
      ) : (
        <Chip
          key={index}
          variant={variant}
          className={cx(isFocused ? `hovered` : null)}
          style={{
            pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
            cursor: 'text',
          }}
          classes={classes}
          onDelete={handleDelete}
          onClick={handleOnClick}
          deleteIcon={
            <CloseIcon style={{ fontSize: '14px', margin: '0 0 0 0.25rem' }} />
          }
          label={transformChipLabel ? transformChipLabel(text) : text}
        />
      )}
    </>
  );
};

ChipInput.propTypes = {
  /** Allows duplicate chips if set to true. */
  allowDuplicates: PropTypes.bool,
  /** If true, the placeholder will always be visible. */
  alwaysShowPlaceholder: PropTypes.bool,
  /** Behavior when the chip input is blurred: `'clear'` clears the input, `'add'` creates a chip and `'ignore'` keeps the input. */
  blurBehavior: PropTypes.oneOf(['clear', 'add', 'ignore']),
  /** A function of the type `({ value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) => node` that returns a chip based on the given properties. This can be used to customize chip styles.  Each item in the `dataSource` array will be passed to `chipRenderer` as arguments `chip`, `value` and `text`. If `dataSource` is an array of objects and `dataSourceConfig` is present, then `value` and `text` will instead correspond to the object values defined in `dataSourceConfig`. If `dataSourceConfig` is not set and `dataSource` is an array of objects, then a custom `chipRenderer` must be set. `chip` is always the raw value from `dataSource`, either an object or a string. */
  chipRenderer: PropTypes.func,
  /** Data source for auto complete. This should be an array of strings or objects. */
  dataSource: PropTypes.array,
  /** Config for objects list dataSource, e.g. `{ text: 'text', value: 'value' }`. If not specified, the `dataSource` must be a flat array of strings or a custom `chipRenderer` must be set to handle the objects. */
  dataSourceConfig: PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  /** The chips to display by default (for uncontrolled mode). */
  defaultValue: PropTypes.array,
  /** Disables the chip input if set to true. */
  disabled: PropTypes.bool,
  /** Disable the input underline. Only valid for 'standard' variant */
  disableUnderline: PropTypes.bool,
  /** If true, the chip input will fill the available width. */
  fullWidth: PropTypes.bool,
  /** Props to pass through to the `Input`. */
  InputProps: PropTypes.object,
  /** Use this property to pass a ref callback to the native input component. */
  inputRef: PropTypes.func,
  /** The input value (enables controlled mode for the text input if set). */
  inputValue: PropTypes.string,
  /** The key codes (`KeyboardEvent.keyCode`) used to determine when to create a new chip. */
  newChipKeyCodes: PropTypes.arrayOf(PropTypes.number),
  /** Callback function that is called when a new chip was added (in controlled mode). */
  onAdd: PropTypes.func,
  /** Callback function that is called with the chip to be added and should return true to add the chip or false to prevent the chip from being added without clearing the text input. */
  onBeforeAdd: PropTypes.func,
  /** Callback function that is called when the chips change (in uncontrolled mode). */
  onChange: PropTypes.func,
  /** Text to display on copy */
  onCopyAlertText: PropTypes.string,
  /** Callback function that is called when a new chip was removed (in controlled mode). */
  onDelete: PropTypes.func,
  /** Callback function that is called when the input changes. */
  onUpdateInput: PropTypes.func,
  /** The separators to split the pasted text into container numbers */
  pasteSeparators: PropTypes.array,
  /** A placeholder that is displayed if the input has no values. */
  placeholder: PropTypes.string,
  /** Makes the chip input read-only if set to true. */
  readOnly: PropTypes.bool,
  /** Remove these charecters from user input when creating chip */
  removeCharacters: PropTypes.array,
  /**  Apply function to chip label */
  transformChipLabel: PropTypes.func,
  /**  Apply function to chip value */
  transformChipValue: PropTypes.func,
  /** The chips to display (enables controlled mode if set). */
  value: PropTypes.array,
};

export default ChipInput;
