// Action types
export const OPEN_LEFT_MENU = 'core/api/v1/erl/display/OPEN_LEFT_MENU';
export const CLOSE_LEFT_MENU = 'core/api/v1/erl/display/CLOSE_LEFT_MENU';
export const OPEN_CAPTION = 'core/api/v1/erl/display/OPEN_CAPTION';
export const CLOSE_CAPTION = 'core/api/v1/erl/display/CLOSE_CAPTION';
export const OPEN_FILTER_MENU = 'core/api/v1/erl/display/OPEN_FILTER_MENU';
export const CLOSE_FILTER_MENU = 'core/api/v1/erl/display/CLOSE_FILTER_MENU';
export const SIGNUP_NEXT_STEP = 'core/api/v1/erl/display/SIGNUP_NEXT_STEP';
export const SIGNUP_PREV_STEP = 'core/api/v1/erl/display/SIGNUP_PREV_STEP';
export const SIGNUP_SET_STEP = 'core/api/v1/erl/display/SIGNUP_SET_STEP';

// Reducer
const initialState = {
  isLeftMenuOpen: false,
  isCaptionOpen: true,
  isFilterMenuOpen: true,
  signupStep: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_LEFT_MENU:
      return {
        ...state,
        isLeftMenuOpen: true,
      };

    case CLOSE_LEFT_MENU:
      return {
        ...state,
        isLeftMenuOpen: false,
      };

    case OPEN_CAPTION:
      return {
        ...state,
        isCaptionOpen: true,
      };

    case CLOSE_CAPTION:
      return {
        ...state,
        isCaptionOpen: false,
      };

    case OPEN_FILTER_MENU:
      return {
        ...state,
        isFilterMenuOpen: true,
      };

    case CLOSE_FILTER_MENU:
      return {
        ...state,
        isFilterMenuOpen: false,
      };

    case SIGNUP_NEXT_STEP:
      return {
        ...state,
        signupStep: state.signupStep + 1,
      };

    case SIGNUP_PREV_STEP:
      return {
        ...state,
        signupStep: state.signupStep - 1,
      };

    case SIGNUP_SET_STEP:
      return {
        ...state,
        signupStep: action.payload,
      };

    default:
      return state;
  }
};

// Action creators
export const openLeftMenu = () => ({ type: OPEN_LEFT_MENU });
export const closeLeftMenu = () => ({ type: CLOSE_LEFT_MENU });
export const openCaption = () => ({ type: OPEN_CAPTION });
export const closeCaption = () => ({ type: CLOSE_CAPTION });
export const openFilterMenu = () => ({ type: OPEN_FILTER_MENU });
export const closeFilterMenu = () => ({ type: CLOSE_FILTER_MENU });

// Side effects
export const toggleLeftMenu = () => (dispatch, getState) => {
  const {
    display: { isLeftMenuOpen },
  } = getState();

  if (isLeftMenuOpen) dispatch(closeLeftMenu());
  else dispatch(openLeftMenu());
};

export const toggleFilterMenu = () => (dispatch, getState) => {
  const {
    display: { isFilterMenuOpen },
  } = getState();

  if (isFilterMenuOpen) dispatch(closeFilterMenu());
  else dispatch(openFilterMenu());
};
