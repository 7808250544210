import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import WarningIcon from '@mui/icons-material/Warning';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import fonts from 'styles/typography.module.css';

const Legend = ({ isLegendOpen, handleOpenLegend, handleCloseLegend }) => {
  return (
    <div className={fonts.font}>
      {!isLegendOpen && (
        <div
          className="fixed left-0.5 top-3/4 bg-white px-8 py-2 shadow-xs rounded-sm text-dark cursor-pointer"
          style={{
            transformOrigin: '0 0',
            transform: 'rotate(-90deg)',
            zIndex: '1400',
          }}
          onClick={handleOpenLegend}
        >
          <h4 className="font-bold">Legend</h4>
        </div>
      )}
      <Slide direction="right" in={isLegendOpen} mountOnEnter unmountOnExit>
        <div
          className="fixed left-0.5 top-1/2 cursor-pointer bg-white border border-legacyBlue px-4 py-4 shadow-md rounded-sm"
          style={{ width: '262px', zIndex: '1400' }}
        >
          <div className="flex flex-col space-y-4">
            <div className="w-full flex justify-between">
              <h4 className="font-bold">Legend</h4>
              <div onClick={handleCloseLegend}>
                <CloseIcon style={{ fontSize: '18px' }} />
              </div>
            </div>
            <ul className="flex flex-col space-y-2">
              <li className="flex space-x-2 items-center">
                <CheckIcon
                  className="text-green"
                  style={{ fontSize: '18px' }}
                />
                <h5>Open for receiving</h5>
              </li>
              <li className="flex space-x-2 items-center">
                <CloseIcon className="text-red" style={{ fontSize: '18px' }} />
                <h5>Closed for receiving</h5>
              </li>
              <li className="flex space-x-2 items-center">
                <CompareArrowsIcon
                  className="text-orange"
                  style={{ fontSize: '18px' }}
                />
                <h5>Restrictions: dual transactions only</h5>
              </li>
              <li className="flex space-x-2 items-center">
                <WarningIcon
                  className="text-orange"
                  style={{ fontSize: '18px' }}
                />
                <h5>Restriction on chassis or equipment</h5>
              </li>
              <li className="flex space-x-2 items-center">
                <AccessTimeIcon
                  className="text-gray-200"
                  style={{ fontSize: '18px' }}
                />
                <h5>
                  Pending information from terminal on return restrictions
                </h5>
              </li>
              <li className="flex space-x-2 items-center">
                <DateRangeIcon
                  className="text-gray-200"
                  style={{ fontSize: '18px' }}
                />
                <div className="flex flex-col">
                  <h5>First appointment: </h5>
                  <h5 className="font-bold">13:00 - 17:00</h5>
                </div>
              </li>
              <li className="flex space-x-2 items-center">
                <DateRangeIcon
                  className="text-gray-200 opacity-0"
                  style={{ fontSize: '18px' }}
                />
                <div className="flex flex-col">
                  <h5>Shift total: number of appointments during the shift</h5>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default Legend;
