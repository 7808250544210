import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GroupsIcon from '@mui/icons-material/Groups';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditProfileForm from './components/EditProfileForm';
import CompanyMembers from './components/CompanyMembers';
import UserAvatar from './components/UserAvatar';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFirstLetters, retrieveTeamMember } from 'ducks/user';
import { getProfile } from 'services/clients/user';
import ChangePasswordButton from 'components/ChangePasswordButton';
import PersonIcon from '@mui/icons-material/Person';
import erlClient from 'services/clients';
import NeedJoinCompany from './components/NeedJoinCompany';
import WaittingForValidation from './components/WaittingForValidation';
import MembershipText from './components/MembershipText';
import fonts from 'styles/typography.module.css';

export class Profile extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    retrieveCompanyMembers: PropTypes.func.isRequired,
    refreshToken: PropTypes.bool,
  };
  state = {
    profile: null,
    pendingMembers: [],
    isOpen: false,
  };

  updatePendingInvitation = () => {
    erlClient
      .get('/core/api/v1/auth/company/members/pending')
      .then(res =>
        this.setState({ pendingMembers: res.data.map(a => a.email) })
      )
      .catch(console.error);
  };

  getUserProfile = async () => {
    const profile = await getProfile();
    if (profile.company) {
      this.props.retrieveCompanyMembers();
      this.updatePendingInvitation();
    }
    this.setState({ profile });
  };

  componentDidMount() {
    this.getUserProfile();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { companyMembers, refreshToken } = this.props;
    if (prevProps.companyMembers.length !== companyMembers.length) {
      const profile = await getProfile();
      this.setState({ profile });
    }
    if (prevProps.refreshToken !== refreshToken) {
      this.getUserProfile();
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { firstLetters, companyMembers } = this.props;
    const { profile, pendingMembers, isOpen } = this.state;

    return (
      <div className={fonts.font}>
        {profile && profile.membership && !profile.company && (
          <WaittingForValidation scac={profile.company_scac} />
        )}
        <div className="flex flex-col space-y-20">
          <div className="flex flex-col space-y-4">
            <div className="flex space-x-4">
              <PersonIcon />
              <h2>Profile Settings</h2>
            </div>
            <div>
              <h4>Set your profile preference</h4>
            </div>

            <div className="px-4 py-8 bg-white border border-blue-500 rounded-md flex space-x-10">
              <div className="flex flex-col space-y-2 items-center">
                <UserAvatar size="large" letters={firstLetters} />
                <MembershipText profile={profile} />
                <ChangePasswordButton />
              </div>
              <EditProfileForm initialValues={profile} />
            </div>
          </div>
          {profile && !profile.membership && <NeedJoinCompany />}

          {companyMembers.length > 0 && (
            <div className="flex flex-col space-y-4">
              <div className="flex w-full justify-between">
                <div className="flex flex-col space-y-4">
                  <div className="flex space-x-4">
                    <GroupsIcon />
                    <h2>Team settings</h2>
                  </div>
                  <h4>Set your profile preference</h4>
                </div>
                <div
                  className="flex space-x-2 items-center cursor-pointer"
                  onClick={() => this.setState({ isOpen: true })}
                >
                  <AddCircleIcon style={{ fontSize: '32px' }} />
                  <h4>Add new members</h4>
                </div>
              </div>
              <div className="px-4 py-8 bg-white border border-blue-500 rounded-md">
                <CompanyMembers
                  members={companyMembers}
                  pendingMembers={pendingMembers}
                  company={profile && profile.company}
                  refreshPending={this.updatePendingInvitation}
                  admin={Boolean(profile && profile.is_admin)}
                  isOpen={isOpen}
                  handleClose={this.handleClose}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  firstLetters: getFirstLetters(state),
  companyMembers: state.user.teamMembers,
  refreshToken: state.refreshToken,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  retrieveCompanyMembers: () => dispatch(retrieveTeamMember()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Profile);
