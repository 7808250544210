import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  extractContainerTypesFromURI,
  extractShippingLinesFromURI,
  setPorts,
  setShippingLines,
  setContainerTypes,
} from 'ducks/erlData';
import { globalLoaderSelector } from 'ducks/loader';
import { isLogged } from 'ducks/user';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FreeTable from './FreeTable';
import styles from './FreeTable.style';
import { useLocation } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => ({
  isLoading: globalLoaderSelector(state),
  containerTypes: extractContainerTypesFromURI(state, ownProps),
  shippingLines: extractShippingLinesFromURI(state, ownProps),
  isLogged: isLogged(state),
});

const mapDispatchToProps = dispatch => ({
  importData: () => {
    dispatch(setPorts());
    dispatch(setShippingLines());
    dispatch(setContainerTypes());
  },
});

export const withHooksHOC = Component => {
  return props => {
    const location = useLocation();

    return <Component location={location} {...props} />;
  };
};

export default compose(
  withHooksHOC,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FreeTable);
