import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import css from './gateSchedule.module.css';

const TableHeaders = props => {
  const { headersData } = props;

  return (
    <thead>
      <tr>
        {headersData.map((data, index) => (
          <TableHeader key={index} date={data.date} shift={data.shift} />
        ))}
      </tr>
    </thead>
  );
};

const TableHeader = props => {
  const { date, shift } = props;
  const dateTime = new Date(date);

  let dateCss = css.dateIteration0;
  if (shift !== null) {
    if (dateTime.getDate() % 2) dateCss = css.dateIteration1;
    else dateCss = css.dateIteration2;
  }

  const shiftRepresentation = shift ? 'Shift ' + shift : '';

  let shiftCss = css.tableHeaderShift1;
  if (shift === 2) shiftCss = css.tableHeaderShift2;

  return (
    <th className={[css.tableHeaders, dateCss].join(' ')}>
      <div className={css.tableHeaderDate}>
        {moment(date).format('ddd, MM/D')}
      </div>
      <div className={shiftCss}>{shiftRepresentation}</div>
    </th>
  );
};

TableHeaders.propTypes = {
  headersData: PropTypes.array,
};

TableHeader.propTypes = {
  date: PropTypes.string,
  shift: PropTypes.number,
};

const mapStateToProps = state => ({
  headersData: state.admin.gateSchedule.headers,
});

export default connect(mapStateToProps, {})(TableHeaders);
