import React from 'react';
import PropTypes from 'prop-types';
import CraneLogo from 'assets/images/crane_animated.gif';
import './BookAppointmentModalLoading.style.css';

const BookAppointmentModalLoading = ({ onCancelLoadingClicked }) => (
  <div className="book-appointment-modal-loading-wrapper">
    <div className="book-appointment-modal-loading-title">
      Sit back and let BlueCargo do the heavy lifting!
    </div>
    <div className="book-appointment-modal-loading-subtitle">
      This will take a few minutes...
    </div>
    <div className="book-appointment-modal-loading-logo-wrapper">
      <div
        className="book-appointment-modal-loading-logo"
        style={{ backgroundImage: `url(${CraneLogo})` }}
      ></div>
    </div>
    {/* <div className="book-appointment-modal-loading-button-wrapper">
      <div
        className="book-appointment-modal-button"
        onClick={onCancelLoadingClicked}
      >
        CANCEL
      </div>
    </div> */}
  </div>
);

BookAppointmentModalLoading.propTypes = {
  onCancelClicked: PropTypes.func,
  onCancelLoadingClicked: PropTypes.func,
};

export default BookAppointmentModalLoading;
