import React from 'react';
import PropTypes from 'prop-types';
import { GateScheduleService } from './services/gate-schedule.service';

const GateScheduleTableHeader = ({
  lastUpdate,
  isNextWeek,
  onDownloadClick,
}) => {
  const handleDownloadClick = () => {
    onDownloadClick(isNextWeek);
  };

  const weekDates = GateScheduleService.getStartEndDayOfWeek(isNextWeek);
  const lastUpdateFormatted = GateScheduleService.formatLastUpdateDate(
    lastUpdate
  );
  return (
    <div className="gate-schedule-table-wrapper-header">
      <div className="gate-schedule-table-wrapper-header-container">
        <div>
          <div>
            <span className="gate-schedule-table-wrapper-header-week-title">
              {`${isNextWeek ? 'Next' : 'Current'} Week`}
            </span>
            <span className="gate-schedule-table-wrapper-header-week-dates">
              {weekDates}
            </span>
          </div>
          <div className="gate-schedule-table-wrapper-header-updated">
            Updated: {lastUpdateFormatted}
          </div>
        </div>
        <div
          className="gate-schedule-download-button"
          onClick={handleDownloadClick}
        >
          Download
        </div>
      </div>
    </div>
  );
};

export default GateScheduleTableHeader;

GateScheduleTableHeader.propTypes = {
  lastUpdate: PropTypes.string,
  isNextWeek: PropTypes.bool,
  onDownloadClick: PropTypes.func.isRequired,
};
