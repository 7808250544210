import React, { useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import { useDispatch } from 'react-redux';
import { logout } from 'ducks/user';
import { useUserContext } from 'common/contexts/UserContext';
import { USER_MENU_WIDTH, USER_PROFILE_DROPDOWN } from './utils/config';
import { checkPermissions } from 'components/PrivateRoute/permissions-utils';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';
import DisplayContainerTracking from 'components/DisplayContainerTracking';
import { addPackNameToPlanTitle } from 'constants/pages';

const UserProfileItem = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const { email, company } = user;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div
        className={`relative flex items-center pl-4 border-l cursor-pointer border-blue-100 pr-1 h-14 hover:bg-dark-200 ${
          anchorEl || location.pathname.startsWith('/settings')
            ? 'bg-dark-200 text-white hover:bg-dark-200'
            : 'hover:bg-dark-200'
        }`}
        onClick={e => setAnchorEl(e.currentTarget)}
        style={{
          width: USER_MENU_WIDTH,
        }}
      >
        <div className="w-full">
          <div className="flex h-full space-x-2 items-center pr-4">
            {/* Profile Circle */}
            <div
              style={{ height: '40px', width: '40px' }}
              className="bg-orange-600 border border-orange rounded-full flex justify-center items-center"
            >
              <h2 className="font-bold uppercase text-orange">
                <ProfileLetters name={company ? company.name : email} />
              </h2>
            </div>
            {/* Profile Infos */}
            <div style={{ width: '155px' }} className="overflow-hidden">
              <h4 className="font-bold text-blue-400 truncate">
                {company ? company.name : ''}
              </h4>
              <h5 className="font-medium text-blue-400 truncate">{email}</h5>
            </div>
            <div className="text-blue-400">
              <KeyboardArrowDownIcon style={{ fontSize: '20px' }} />
            </div>
          </div>
        </div>
      </div>
      <ProfileDropdown
        anchorEl={anchorEl}
        onClick={handleOnClick}
        onClose={() => setAnchorEl(null)}
      />
    </>
  );
};

const ProfileLetters = ({ name }: { name: string }) => {
  const arr = name.split('');

  return (
    <>
      {arr[0]}
      {arr[1]}
    </>
  );
};

const ProfileDropdown = ({
  anchorEl,
  onClose,
  onClick,
}: {
  anchorEl: any;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { user } = useUserContext();
  const dropdownItems = useMemo(
    () =>
      _.flatMap(USER_PROFILE_DROPDOWN, (item: any) => item.children).map(item =>
        addPackNameToPlanTitle(item, user)
      ),
    [user]
  );
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          width: `${USER_MENU_WIDTH}px`,
          left: 'unset !important',
          right: 0,
          padding: '1rem 0',
        },
      }}
      style={{
        zIndex: '1500',
      }}
    >
      {user.company && (
        <div className="pb-6 border-b px-4 border-blue-500 mb-4">
          <DisplayContainerTracking />
        </div>
      )}
      <ul
        className="flex flex-col items-start space-y-4 px-4"
        style={{ width: `${USER_MENU_WIDTH}px` }}
      >
        {dropdownItems
          .filter((item: any) => checkPermissions(user, item.path))
          .map((item: any) => {
            return (
              <li key={item.title} onClick={onClose} className="w-full">
                <Link to={item.path} className="w-full">
                  <h4 className="font-medium text-gray-100 hover:text-blue">
                    {item.title}
                  </h4>
                </Link>
              </li>
            );
          })}
        <li onClick={onClose} className="w-full cursor-pointer">
          <h4
            onClick={handleLogout}
            className="font-medium text-gray-100 hover:text-blue"
          >
            Log Out
          </h4>
        </li>
      </ul>
    </Menu>
  );
};

export default UserProfileItem;
