export default theme => ({
  paper: {
    padding: 3 * theme.spacing.unit,
    height: '80vh',
    width: '50vw',
  },
  scrollable: {
    marginTop: 2 * theme.spacing.unit,
    height: 'calc(100% - 100px)',
    overflowY: 'scroll',
  },
  title: {
    marginLeft: 2 * theme.spacing.unit,
  },
  subtitle: {
    marginLeft: 4 * theme.spacing.unit,
  },
});
