import React from 'react';

const ContainersErrorOverlay = ({ error }) => {
  return (
    <div className="flex flex-col space-y-1 items-center pb-10">
      <span
        role="img"
        className="text-orange"
        aria-label="warning emoji"
        style={{ fontSize: '48px' }}
      >
        ⚠️
      </span>
      {typeof error !== 'string' && (
        <h3 className="font-medium">
          Error loading containers for selection. Please try{' '}
          <a
            href="/#/"
            rel="noreferrer"
            onClick={e => {
              e.preventDefault();
              window.location.reload();
            }}
            className="underline"
          >
            refreshing the page
          </a>{' '}
          or change your filters.
        </h3>
      )}
      {typeof error === 'string' && <h3 className="font-medium">{error}</h3>}
    </div>
  );
};

export default ContainersErrorOverlay;
