import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/customDesign/Button';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from 'components/customDesign/DialogContent';
import { ReactComponent as SmileIcon } from 'assets/images/success.svg';
import { Formik } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from 'components/customDesign/Input';
import erlClient from 'services/clients';
import memoize from 'lodash/memoize';
import ListInfoItem from 'components/customDesign/ListInfoItem';
import { Typography } from '@material-ui/core';
import NeedValidation from 'pages/SignUp/components/NeedValidation';

const validateScacExist = memoize(scac =>
  erlClient.get(`/core/api/v1/auth/company/scac/${scac}`)
);

const NeedJoinCompany = ({ classes }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [secondComer, setSecondComer] = useState(false);
  const [needValidation, setNeedValidation] = useState(false);

  const close = () => setIsOpen(false);
  const validate = async (values, props) => {
    let errors = {};
    setSecondComer(false);
    if (!values.scac) errors.scac = 'Required';
    if (values.scac && !errors.scac) {
      try {
        await validateScacExist(values.scac);
      } catch (e) {
        setSecondComer(true);
      }
    }
    throw errors;
  };

  const submit = (values, { setSubmitting, setErrors }) =>
    erlClient
      .post('/core/api/v1/auth/company/join', { scac: values.scac })
      .then(() => {
        setSubmitting(false);

        if (!secondComer) {
          close();
          window.location.reload();
        } else setNeedValidation(true);
      })
      .catch(err => {
        setErrors(err.response.data);
        setSubmitting(false);
      });

  return (
    <Dialog open={isOpen} classes={{ paper: classes.root }}>
      <Formik
        initialStatus={{ scac: '' }}
        validate={validate}
        onSubmit={submit}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
        }) =>
          needValidation ? (
            <React.Fragment>
              <SmileIcon width={36} height={36} className={classes.icon} />
              <Typography variant="h2" gutterBottom align="center">
                Special offer!
              </Typography>
              <NeedValidation scac={values.scac} />
            </React.Fragment>
          ) : (
            <form onSubmit={handleSubmit} className={classes.form}>
              <DialogContent
                TopIcon={SmileIcon}
                title="Special offer!"
                classes={{ cta: classes.callToAction }}
                subtitle={
                  <div>
                    <span>
                      To keep using the platform, unlock a <br />
                      <span className={classes.underline}>Premium</span> account
                      for
                      <span className={classes.trial}> 15 days free</span>!
                    </span>
                  </div>
                }
                callToAction={
                  <React.Fragment>
                    <Button
                      type="submit"
                      classes={{ root: classes.button }}
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Let's go!
                    </Button>
                    <div>
                      <Typography
                        component="a"
                        href="mailto:support@bluecargo.io"
                        variant="caption"
                        className={classes.underline}
                      >
                        Contact us
                      </Typography>
                    </div>
                  </React.Fragment>
                }
              >
                <div>
                  <ListInfoItem
                    primary="No credit card required"
                    secondary="We'll remind you few days before your trial ends"
                  />
                  <ListInfoItem
                    primary="Free"
                    secondary="You won't be charged at all! Enter a credit card after your 15 days free!"
                  />
                  <ListInfoItem
                    primary="Non-binding"
                    secondary="No commitment, cancel anytime"
                  />
                </div>
                <FormControl
                  required
                  fullWidth
                  className={classes.field}
                  error={errors.scac && touched.scac && touched.scac}
                >
                  <Input
                    placeholder="Enter your company SCAC *"
                    onBlur={handleBlur}
                    name="scac"
                    value={values.scac}
                    onChange={event =>
                      handleChange({
                        target: {
                          name: event.target.name,
                          value: event.target.value.toUpperCase(),
                        },
                      })
                    }
                  />
                  <FormHelperText>
                    {errors.scac && touched.scac && errors.scac}
                    {secondComer && 'This company already has an account'}
                  </FormHelperText>
                </FormControl>
              </DialogContent>
            </form>
          )
        }
      </Formik>
    </Dialog>
  );
};

NeedJoinCompany.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  icon: {
    margin: 'auto',
    marginBottom: 3 * theme.spacing.unit,
  },
  button: {
    margin: 'auto',
    marginBottom: 2 * theme.spacing.unit,
    marginTop: 2 * theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    padding: 3 * theme.spacing.unit,
    width: theme.layout.popupWidth,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  underline: {
    textDecoration: 'underline',
  },
  trial: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  callToAction: {
    marginTop: 0,
  },
});

export default withStyles(styles)(NeedJoinCompany);
