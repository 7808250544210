import React from 'react';
import PropTypes from 'prop-types';
import PersonIcon from '@mui/icons-material/Person';

function UserAvatar({ letters, size }) {
  if (size === 'small') {
    return (
      <div
        className="bg-blue rounded-full flex justify-center items-center"
        style={{ width: '32px', height: '32px' }}
      >
        {letters ? (
          <h4 className="font-bold text-white">{letters}</h4>
        ) : (
          <PersonIcon className="text-white" style={{ fontSize: '24px' }} />
        )}
      </div>
    );
  }

  if (size === 'large') {
    return (
      <div
        className="bg-blue rounded-full flex justify-center items-center"
        style={{ width: '128px', height: '128px' }}
      >
        {letters ? (
          <h1 className="font-bold text-white" style={{ fontSize: '64px' }}>
            {letters}
          </h1>
        ) : (
          <PersonIcon className="text-white" style={{ fontSize: '64px' }} />
        )}{' '}
      </div>
    );
  }
}

UserAvatar.propTypes = {
  letters: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
};

export default UserAvatar;
