import React, { useState, useMemo, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getPaymentsList } from 'services/clients/user';
import Switch from 'components/Switch';
import NewCreditCardForm from './NewCreditCardForm';
import SavedCreditCard from './SavedCreditCard';

import config from 'config';
import LoadingComponent from 'components/LoadingComponent';

const PaymentForm = ({ onFormIsValid }) => {
  const [activeItemIdx, setActiveItemIdx] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const stripePromise = useMemo(() => {
    return loadStripe(config.stripe_api_key);
  }, []);

  useEffect(async () => {
    const { payment_method } = await getPaymentsList();
    setPaymentList(payment_method.data);
    if (payment_method.data && payment_method.data.length > 0) {
      setActiveItemIdx(1);
    }
    setIsLoading(false);
  }, []);

  return (
    <div className="w-full">
      <div className="p-4 bg-white border border-blue-500 rounded-md">
        <div className="w-full flex flex-col space-y-4 items-start">
          <Switch
            items={['New credit card', 'Saved credit card']}
            activeItemIdx={activeItemIdx}
            onItemSelected={index => setActiveItemIdx(index)}
            width={160}
          />
          <LoadingComponent isLoading={isLoading}>
            <div className="w-full">
              <Elements stripe={stripePromise}>
                {activeItemIdx === 0 && (
                  <NewCreditCardForm onFormIsValid={onFormIsValid} />
                )}
                {activeItemIdx === 1 && (
                  <SavedCreditCard
                    paymentList={paymentList}
                    onFormIsValid={onFormIsValid}
                  />
                )}
                {activeItemIdx === 2 && <BankAccount />}
              </Elements>
            </div>
          </LoadingComponent>
        </div>
      </div>
    </div>
  );
};

const BankAccount = () => {
  return <div>ACH Coming soon</div>;
};

export default PaymentForm;
