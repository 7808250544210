import Formular from './Formular';
import styles from './Formular.style';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  SIGNUP_NEXT_STEP,
  SIGNUP_PREV_STEP,
  SIGNUP_SET_STEP,
} from 'ducks/display';

const mapStateToProps = state => ({
  activeStep: state.display.signupStep,
});

const mapDispatchToProps = dispatch => ({
  handleNext: () => dispatch({ type: SIGNUP_NEXT_STEP }),
  handleBack: () => dispatch({ type: SIGNUP_PREV_STEP }),
  setStep: step => dispatch({ type: SIGNUP_SET_STEP, payload: step }),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Formular);
