export default theme => ({
  cell: {
    minWidth: 80,
    borderRight: '0.5px solid rgba(31, 51, 75, 0.1)',
    borderLeft: '0.5px solid rgba(31, 51, 75, 0.1)',
  },
  green: {
    color: theme.palette.green.main,
  },
  red: {
    color: theme.palette.alert.main,
  },
  yellow: {
    color: theme.palette.secondary.main,
  },
  blue: {
    color: theme.palette.primary.main,
  },
  pointer: {
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
  },
  broken: {
    background: theme.background.greyHatched,
  },
  close: {
    backgroundColor: theme.palette.alert.light,
  },
});
