import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from 'components/customDesign/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';

export default class CredentialsForm extends Component {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const {
      classes,
      errors,
      touched,
      handleChange,
      handleBlur,
      values,
    } = this.props;
    return (
      <div>
        <Typography variant="caption" className={classes.subtitle}>
          Credentials
        </Typography>
        <FormControl
          required
          fullWidth
          disabled={Boolean(values.token)}
          error={errors.email && touched.email}
        >
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Email *"
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <FormHelperText>
            {errors.email && touched.email && errors.email}
          </FormHelperText>
        </FormControl>
        <FormControl
          required
          fullWidth
          error={errors.password && touched.password}
        >
          <Input
            name="password"
            type="password"
            id="password"
            placeholder="Password *"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          <FormHelperText>
            {errors.password && touched.password && errors.password}
          </FormHelperText>
        </FormControl>
        <FormControl
          required
          fullWidth
          error={errors.password_confirmation && touched.password_confirmation}
        >
          <Input
            name="password_confirmation"
            type="password"
            id="password_confirmation"
            placeholder="Repeat Password *"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password_confirmation}
          />
          <FormHelperText>
            {errors.password_confirmation &&
              touched.password_confirmation &&
              errors.password_confirmation}
          </FormHelperText>
        </FormControl>
        <FormControl
          required
          fullWidth
          error={errors.terms_of_uses && touched.password_confirmation}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={values.terms_of_uses}
                id="terms_of_uses"
                onChange={handleChange}
                color="primary"
              />
            }
            label={
              <Typography variant="caption" className={classes.checkboxLabel}>
                I have read and agree with the <br />
                <Link
                  className={classes.link}
                  to="/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  General Terms and Conditions
                </Link>{' '}
                of BlueCargo
              </Typography>
            }
          />
        </FormControl>
        <FormControl
          required
          fullWidth
          error={errors.terms_of_uses && touched.password_confirmation}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={values.privacy_policy}
                id="privacy_policy"
                onChange={handleChange}
                color="primary"
              />
            }
            label={
              <Typography variant="caption" className={classes.checkboxLabel}>
                I have read and agree with the{' '}
                <Link
                  className={classes.link}
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
              </Typography>
            }
          />
          <FormHelperText>
            {errors.terms_of_uses &&
              touched.password_confirmation &&
              errors.terms_of_uses}
          </FormHelperText>
        </FormControl>
      </div>
    );
  }
}
