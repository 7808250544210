import React from 'react';
import { pageTitlesAndUrls } from 'constants/pages';
import { Navigate } from 'react-router-dom';
import PlansAndBilling from './PlansAndBilling';
import Profile from './Profile';
import InvoiceHistory from './InvoiceHistory';
import NotificationSettings from './NotificationSettings';
import Credentials from './Credentials';

const settingsRoutes = [
  {
    path: pageTitlesAndUrls.profile.path,
    element: <Profile />,
  },
  {
    path: pageTitlesAndUrls.plans.path,
    element: <PlansAndBilling />,
  },
  {
    path: pageTitlesAndUrls.invoiceHistory.path,
    element: <InvoiceHistory />,
  },
  {
    path: pageTitlesAndUrls.notificationSettings.path,
    element: <NotificationSettings />,
  },
  {
    path: pageTitlesAndUrls.notificationSettings.path,
    element: <NotificationSettings />,
  },
  {
    path: pageTitlesAndUrls.credentials.path,
    element: <Credentials />,
  },
  {
    path: '*',
    element: <Navigate replace to={pageTitlesAndUrls.profile.path} />,
  },
];

export default settingsRoutes;
