import moment from 'moment';
import _ from 'lodash';
import ReactGA from 'react-ga';
import {
  terminalsKeysApmTti,
  timeFormat,
  timeRange,
  ptscTerminalKey,
  matsonTerminalKey,
  everportTerminalKey,
  validStatus,
  dateTimeFormat,
} from '../constants/next-appointments.constants';
import { DISALLOWED_PER_DIEM_PACKS } from '../../../../../constants/user';

export const NextAppointmentsService = {
  // BCIC-404 For FMS, YTI, and TTI, convert appointments to "no appointments available" when empty restriction= CLOSED
  // BCIC-709 Remove logic "no appoitnment" for FMS and YTI, and apply to APM 20.10.2020
  checkIsApmTti(termialId) {
    return terminalsKeysApmTti.includes(termialId);
  },

  // BCIC-475 Apply Empty Appointments decision table to Everport column
  checkIsEverport(terminalKey) {
    return terminalKey === everportTerminalKey;
  },

  getGateData(gateHours, terminalKey, shift) {
    return gateHours.filter(item => {
      return (
        item.port === terminalKey &&
        item.shift === shift.shift &&
        moment(item.date).isSame(shift.day, 'day')
      );
    });
  },

  checkIsLastRefreshOutdated(lastRecordingTime) {
    const timeInLA = moment()
      .tz('America/Los_Angeles')
      .format('YYYY-MM-DD hh:mm:ss');
    const outdatedHoursTZ = moment(timeInLA).diff(lastRecordingTime, 'hours');
    return outdatedHoursTZ && outdatedHoursTZ > 1;
  },

  checkIsTerminalDataNotCorrect(
    currentTerminal,
    currentSLKey,
    terminal,
    isHeader
  ) {
    if (!currentTerminal) return false;
    let lastRecordingTime = '';

    if (currentTerminal[currentSLKey]) {
      lastRecordingTime = currentTerminal[currentSLKey].last_recording_time;
    } else if (currentTerminal['ALL']) {
      lastRecordingTime = currentTerminal['ALL'].last_recording_time;
    } else {
      lastRecordingTime = '';
    }

    if (lastRecordingTime) {
      if (
        currentTerminal.terminal === terminal.key &&
        terminal.no_data_appointments
      ) {
        return true;
      }

      // BCIC-806 reduce outdatedHours from 24 to 2
      // if (outdatedHours && outdatedHours > 24) {
      const isLastRefreshOutdated = this.checkIsLastRefreshOutdated(
        lastRecordingTime
      );

      if (isLastRefreshOutdated) {
        return true;
      }

      return false;
    } else {
      return false;
    }
  },

  // BCIC-402 If appointments are after midnight, add a * before the time slot
  checkIsSlotAfterMidnight(start_time, end_time) {
    const from = moment(timeRange.from, timeFormat);
    const to = moment(timeRange.to, timeFormat);
    const isStartBetween = moment(start_time, timeFormat).isBetween(
      from,
      to,
      undefined,
      '[]'
    );
    const isEndBetween = moment(end_time, timeFormat).isBetween(
      from,
      to,
      undefined,
      '[]'
    );
    return isStartBetween && isEndBetween;
  },

  checkIsPtscTerminal(terminal) {
    return terminal && terminal.key === ptscTerminalKey;
  },

  checkIsMatsonTerminal(terminal) {
    return terminal && terminal.key === matsonTerminalKey;
  },

  // BCIC-179 Add a tag to the shifts of the current day (in the row name cell)
  checkIsShiftToday(day) {
    return moment().isSame(day, 'day');
  },

  createShiftsList(filteredAppointments, currentContainerKey, currentSLKey) {
    const allDates = [];
    const allDatesPureArray = [];
    filteredAppointments.forEach(terminal => {
      if (
        terminal[currentSLKey] &&
        terminal[currentSLKey][currentContainerKey] &&
        terminal[currentSLKey][currentContainerKey].availability
      ) {
        terminal[currentSLKey][currentContainerKey].availability.forEach(
          item => {
            const date = Object.keys(item)[0];
            Object.keys(item[date]).forEach(shift => {
              allDates.push({
                day: date,
                shift: shift,
              });
              allDatesPureArray.push(date);
            });
          }
        );
      }
    });

    const uniqDates = _.uniq(allDatesPureArray).sort((a, b) => {
      const dateA = moment(a, 'YYYY-MM-DD').unix();
      const dateB = moment(b, 'YYYY-MM-DD').unix();
      return dateA - dateB;
    });
    const shifts = [];
    uniqDates.forEach(date => {
      const uniqShifts = allDates.filter(shift => shift.day === date);
      const uniqShiftsPerDay = _.uniqBy(uniqShifts, 'shift');
      uniqShiftsPerDay
        .sort((a, b) => {
          return a.shift.localeCompare(b.shift);
        })
        .forEach(item => {
          // const day = moment(item.day).format('dddd');
          // if (day === 'Saturday' && item.shift === SHIFTS_REF.shift2) {
          //   return;
          // }
          // if (day === 'Sunday') {
          //   return;
          // }
          shifts.push(item);
        });
    });
    return shifts;
  },

  checkCredentials(terminalKey, availabilityCredentials) {
    return (
      terminalKey &&
      availabilityCredentials &&
      availabilityCredentials[terminalKey] &&
      availabilityCredentials[terminalKey] === validStatus
    );
  },

  checkIsGateClosed(gateData) {
    return gateData && gateData.length && gateData[0].is_open === false;
  },

  checkWasCredentialsEntered(availabilityCredentials) {
    const statusesArray = Object.keys(availabilityCredentials).map(
      key => availabilityCredentials[key]
    );
    return statusesArray.some(status => status !== null && status !== 'none');
  },

  fireGAcellClickEvent(
    currentSLKey,
    currentContainerKey,
    user,
    terminalKey,
    shift
  ) {
    const timestamp = moment().format(dateTimeFormat);
    const { id } = user;
    ReactGA.event({
      category: 'Empty Appointments',
      action: 'EA table Cell Click',
      label: `${currentSLKey}, ${currentContainerKey}, ${terminalKey}, ${shift.day}/${shift.shift}, ${id}, ${timestamp}`,
    });
  },

  fireGAsearchEvent(user, shipping_lines, container_types) {
    const [shipping_line] = shipping_lines;
    const { key: shippingLineKey } = shipping_line || {};
    const [container_type] = container_types;
    const { key: containerTypeKey } = container_type || {};
    const timestamp = moment().format(dateTimeFormat);
    const { id, first_name, last_name, email } = user;
    ReactGA.event({
      category: 'Empty Appointments',
      action: 'EA Search',
      label: `${shippingLineKey}, ${containerTypeKey}, ${id}, ${email}, ${timestamp}`,
    });
    ReactGA.event({
      category: 'Empty Appointments',
      action: 'EA Search by User',
      label: `${first_name}, ${last_name}, ${id}, ${email}`,
    });
  },

  fireGADownloadEvent(currentSLKey, currentContainerKey, user) {
    const timestamp = moment().format(dateTimeFormat);
    const { id, email } = user;
    ReactGA.event({
      category: 'Backup Bank',
      action: 'Stop the clock button',
      label: `${id}, ${email}, ${currentSLKey}, ${currentContainerKey}, ${timestamp}`,
    });
  },

  fireGASuccessAppointmentBookingEvent(user, appointment_id, container_id) {
    const { id, email } = user;
    ReactGA.event({
      category: 'Appointment Booking',
      action: 'Appointment booking successful',
      label: `${id}, ${email}, ${appointment_id}, ${container_id}`,
    });
  },

  checkIsPerDiemFighterOrFreeTrial(user) {
    const { permissions, company } = user || {};
    if (!permissions.access_per_diem) {
      return false;
    }
    return company.pack && !DISALLOWED_PER_DIEM_PACKS.includes(company.pack);
  },

  copyDataToClipBoard(data) {
    return (
      navigator && navigator.clipboard && navigator.clipboard.writeText(data)
    );
  },

  validateContainerNumber(value) {
    const reg = new RegExp(`^[A-Za-z]{4}[0-9]{7}$`);
    return reg.test(value);
  },

  defineContainerNumberErrorMsg(status) {
    let defaultMsg = 'Please select a shipping line and size';
    switch (status) {
      case 404:
        return 'Please select a shipping line and a size';
      case 422:
        return 'Please enter a valid container number';
      default:
        return defaultMsg;
    }
  },

  getGroupLabel(group) {
    const [shiftDate, shift] = _.split(group, '_') || [];
    const formattedDate = moment(shiftDate, 'YYYY-MM-DD').format('dddd MM/DD');
    const groupLabel = `-- ${formattedDate} - ${_.toUpper(shift)} --`;
    return { shiftDate, shift, groupLabel };
  },
};
