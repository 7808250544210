import {
  downloadSnapshot,
  getSnapshotUrlsBySL,
  getTimeRangePerDiem,
  downloadEaScreenshot,
  sendScreenshotViaEmailEnd,
  downloadDailyReportPdfEnd,
} from '../services/clients/backupBank';
import { startLoading, endLoading } from 'ducks/loader';
import ReactGA from 'react-ga';
import FileSaver from 'file-saver';
import groupBy from 'lodash/groupBy';
import { setError } from './error';
import moment from 'moment';
import { DeviceUtil } from 'utils/device.util';
import { SegmentService } from 'services/helpers/segment';

const PER_DIEM_TITLE = 'Per Diem Daily Report';
const OUT_OF_CREDIT_DOWNLOAD =
  ' cannot be downloaded because you are out of credit. ' +
  'Please, upgrade your plan to get more credits';
// Action types
export const SET_SNAPSHOT_URLS = 'core/api/v1/erl/backupbank/SET_SNAPSHOT_URLS';
export const SET_PER_DIEM_VALUE =
  'core/api/v1/erl/backupbank/SET_PER_DIEM_VALUE';
export const SET_TIMERANGE_RESULTS =
  'core/api/v1/erl/backupbank/SET_TIMERANGE_RESULTS';

// Reducer
const initialState = {
  snapshotUrls: [],
  timeRangeResults: null,
  perDiemFormValue: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SNAPSHOT_URLS:
      return {
        ...state,
        snapshotUrls: action.payload,
      };
    case SET_PER_DIEM_VALUE:
      return {
        ...state,
        perDiemFormValue: action.payload,
      };
    case SET_TIMERANGE_RESULTS:
      return {
        ...state,
        timeRangeResults: action.payload,
      };
    default:
      return state;
  }
};
// Action creators
export const setSnapshotUrls = urls => ({
  type: SET_SNAPSHOT_URLS,
  payload: urls,
});

export const setPerDiemValue = value => ({
  type: SET_PER_DIEM_VALUE,
  payload: value,
});

export const setTimeRangeResults = value => ({
  type: SET_TIMERANGE_RESULTS,
  payload: value,
});

// Side effects
export const getSnapshotUrlByShippingLine = (
  shippingLine,
  date,
  time,
  cb
) => async (dispatch, getState) => {
  const {
    user: { first_name, last_name },
  } = getState();

  dispatch(startLoading('backupBankUrl'));
  ReactGA.event({
    category: 'Backup Bank',
    action: 'user',
    label: `${first_name} ${last_name}`,
  });
  ReactGA.event({
    category: 'Backup Bank',
    action: 'shipping line',
    label: `${shippingLine.key}`,
  });
  ReactGA.event({
    category: 'Backup Bank',
    action: 'date',
    label: `${date}`,
  });
  const data = await getSnapshotUrlsBySL(shippingLine.key, date, time, cb);
  dispatch(setSnapshotUrls(data));
  dispatch(setTimeRangeResults([]));
  dispatch(endLoading('backupBankUrl'));
};

export const setTimeRangePerDiem = (
  shipping_line,
  container_type,
  start_date,
  end_date,
  cb
) => async dispatch => {
  dispatch(startLoading('backupBankUrl'));
  dispatch(setTimeRangeResults(null));
  dispatch(setSnapshotUrls([]));

  const data = await getTimeRangePerDiem({
    shipping_line,
    container_type,
    start_date,
    end_date,
  });
  dispatch(setTimeRangeResults(groupBy(data, 'port')));
  const data2 = await getSnapshotUrlsBySL(
    shipping_line,
    moment(start_date).format('YYYY-MM-DD'),
    moment(start_date).format('HH:mm'),
    cb,
    moment(end_date).format('YYYY-MM-DD')
  );
  dispatch(setSnapshotUrls(data2));
  dispatch(endLoading('backupBankUrl'));
};

export const downloadSnapshotPDF = (key = null) => async (
  dispatch,
  getState
) => {
  const {
    user: { first_name, last_name },
    backupBank: { selectedKey },
  } = getState();
  ReactGA.event({
    category: 'Backup Bank',
    action: 'download',
    label: `${first_name} ${last_name} ${selectedKey}`,
  });
  SegmentService.trackEvent(
    'Terminal Screenshot Downloaded',
    {},
    { integrations: { Slack: false } }
  );
  dispatch(startLoading('downloadBackup'));
  try {
    const data = await downloadSnapshot({ key: key || selectedKey });
    await FileSaver(data, `BlueCargo Screenshot bank`);
  } catch (e) {
    if (e.response.status === 402)
      dispatch(setError('Screenshot' + OUT_OF_CREDIT_DOWNLOAD, e));
  } finally {
    dispatch(endLoading('downloadBackup'));
  }
};

export const getScreenshots = (slKey, cb) => async dispatch => {
  try {
    const dateTimeObj = moment();
    const date = dateTimeObj.format('YYYY-MM-DD');
    const time = dateTimeObj.format('HH:mm');
    const data = await getSnapshotUrlsBySL(slKey, date, time);
    cb(data);
  } catch (error) {
    console.error(error);
    dispatch(setError('Get Screenshots error', error));
  }
};

export const downloadDailyReportPdf = (requestBody, callback) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(startLoading('isDownloadDailyReportLoading'));
    const res = await downloadDailyReportPdfEnd(requestBody);
    const { download } = requestBody || {};
    if (download) {
      const { data } = res || {};
      const { date, shipping_line, container_size } = requestBody;

      const fileName = `${date} - ${container_size} - ${shipping_line} - ${PER_DIEM_TITLE}`;
      const isIE = DeviceUtil.isIE();
      if (isIE) {
        const fileNameIE = `${fileName}.pdf`;
        const textFileAsBlob = new Blob([data], { type: 'application/pdf' });
        window.navigator.msSaveBlob(textFileAsBlob, fileNameIE);
      } else {
        await FileSaver(data, fileName);
      }
    }
    if (callback) {
      callback();
    }
  } catch (error) {
    let message = '';
    console.error(error);
    if (callback) {
      callback(error);
    }
    if (error.response.status === 402) {
      message += PER_DIEM_TITLE + OUT_OF_CREDIT_DOWNLOAD;
    } else {
      message += 'Downloading error';
    }
    dispatch(setError(message, error));
  } finally {
    dispatch(endLoading('isDownloadDailyReportLoading'));
  }
};

export const downloadEaScreenshotPDF = (
  requestBody,
  fileNameData,
  cb
) => async (dispatch, getState) => {
  try {
    const { download } = requestBody || {};
    const res = await downloadEaScreenshot(requestBody);
    if (download) {
      const { data } = res || {};
      const {
        shippingLineKey,
        containerTypeName,
        containerNumber,
      } = fileNameData;
      const containerTypeNameWithoutSpace = containerTypeName.replace(
        /\s/g,
        ''
      );
      const time = moment()
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD hh:mmA z');
      const containerNumString = `${
        containerNumber ? ` ${containerNumber}` : ''
      }`;
      const title = 'BlueCargo Per Diem Report';
      const fileName = `${time} -${containerNumString} ${shippingLineKey} ${containerTypeNameWithoutSpace} - ${title}`;
      const isIE = DeviceUtil.isIE();
      if (isIE) {
        const fileNameIE = `${fileName}.pdf`;
        const textFileAsBlob = new Blob([data], { type: 'application/pdf' });
        window.navigator.msSaveBlob(textFileAsBlob, fileNameIE);
      } else {
        await FileSaver(data, fileName);
      }
    }
    cb();
  } catch (error) {
    cb(error);
    console.error(error);
    dispatch(setError('Downloading error', error));
  }
};

export const sendScreenshotViaEmail = (requestBody, cb) => async (
  dispatch,
  getState
) => {
  try {
    await sendScreenshotViaEmailEnd(requestBody);
    if (cb) cb();
  } catch (error) {
    console.error(error);
    dispatch(setError('Send via email error', error));
    if (cb) cb(error);
  }
};
