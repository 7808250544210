import _ from 'lodash';

const isBot = window.navigator.webdriver;
export const SegmentService = {
  trackPage(pathname) {
    if (isBot === true) {
      return;
    }
    if (window.analytics) {
      window.analytics.page(pathname);
    }
  },

  // Expects a BlueCargo user and transforms it to the
  // shape expected by segment.
  identifyUser(user = {}) {
    if (isBot === true || !user) {
      return;
    }
    if (window.analytics && user && user.id) {
      const {
        id,
        email,
        first_name,
        last_name,
        email_validated,
        phone_number,
        is_admin,
        company = {},
        pack,
        date_joined,
        permissions = {},
        credentials,
        isAllCredentials,
      } = user;

      const betaAccess = _.get(company, 'beta_access');
      const address = _.get(company, 'address');
      const trialEnd = _.get(company, 'trial_end');
      const stripePlanId = _.get(company, 'pack');
      const maxUser = _.get(permissions, 'max_user');

      window.analytics.identify(id, {
        firstName: first_name,
        lastName: last_name,
        email,
        emailValidated: email_validated,
        phone: phone_number,
        plan: pack,
        isAdmin: is_admin,
        createdAt: date_joined,
        betaAccess,
        companyScac: user.company_scac,
        credentials: credentials,
        isAllCredentials: isAllCredentials,
      });

      window.analytics.group(user.company_scac, {
        companyPlan: pack,
        address,
        phone: phone_number,
        trialEnd,
        stripePlanId,
        maxUser,
        website: `https://${user.company_scac}.com/`,
        credentials: credentials,
        isAllCredentials: isAllCredentials,
      });
    }
  },

  trackEvent(eventTitle = '', properties = {}, options = {}) {
    if (isBot === true) {
      return;
    }
    if (window.analytics) {
      window.analytics.track(eventTitle, properties, options);
    }
  },
};
