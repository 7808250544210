import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchPtscSchedule, updatePtscSchedule } from '../../../../ducks/admin';

import TableHeaders from './TableHeaders';
import TableBody from './TableBody';
import css from './ptscSchedule.module.css';
import { Button } from '@material-ui/core';

const BaseSchedule = props => {
  const { ptscSchedule, fetchPtscSchedule, updatePtscSchedule } = props;

  useEffect(() => {
    fetchPtscSchedule();
  }, [fetchPtscSchedule]);

  return props.isScheduleExists ? (
    <>
      <h1>PTSC Schedule</h1>
      <div className={css.tableWrapper}>
        <table className={css.table}>
          <TableHeaders />
          <TableBody />
        </table>
      </div>

      <div className={css.tableSubmitContainer}>
        <SubmitTable
          ptscSchedule={ptscSchedule}
          updatePtscSchedule={updatePtscSchedule}
          fetchPtscSchedule={fetchPtscSchedule}
        />
      </div>
    </>
  ) : null;
};

const SubmitTable = ({
  ptscSchedule,
  updatePtscSchedule,
  fetchPtscSchedule,
}) => {
  const onClick = () => {
    let payload = {};
    ptscSchedule.headers.forEach((header, index) => {
      // first header is empty
      if (header === '') return;

      if (!(header.shift in payload)) {
        payload['shift' + header] = {};
        payload['shift' + header]['HLC'] = {};
      }

      // console.log(header.date, header.shift);
      ptscSchedule.rows.forEach(row => {
        if (row.data[index - 1] !== '')
          payload['shift' + header]['HLC'][row.container] = row.data[index - 1];
      });
    });
    updatePtscSchedule(payload);
  };

  return (
    <Button onClick={onClick} variant="contained">
      Save
    </Button>
  );
};

BaseSchedule.propTypes = {
  ptscSchedule: PropTypes.array,
  fetchPtscSchedule: PropTypes.func,
  updatePtscSchedule: PropTypes.func,
};

SubmitTable.propTypes = {
  ptscSchedule: PropTypes.array,
  updatePtscSchedule: PropTypes.func,
  fetchPtscSchedule: PropTypes.func,
};

BaseSchedule.propTypes = {
  fetchPtscSchedule: PropTypes.func,
  isScheduleExists: PropTypes.bool,
};

const mapStateToProps = state => ({
  isScheduleExists: state.admin.ptscSchedule.rows.length > 0,
  ptscSchedule: state.admin.ptscSchedule,
});

const mapDispatchToProps = {
  fetchPtscSchedule,
  updatePtscSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseSchedule);
