import AvailabilityTable from './AvailabilityTable';
import styles from './AvailabilityTable.style';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getAvailabilities } from 'ducks/availabilities';
import { getFilteredTerminals } from 'ducks/erlData';

const mapStateToProps = (state, ownProps) => ({
  availabilities: state.availabilities.data,
  // TODO use a selector instead for performance purpose
  selectedShifts: ownProps.selectedShifts || [
    state.availabilities.selectedShift,
  ],
  ports: ownProps.noFilter ? state.erlData.ports : getFilteredTerminals(state),
  functionalParsers: state.availabilities.functionalParsers,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAvailabilities: () =>
    dispatch(getAvailabilities(ownProps.shippingLines, ownProps.containerSize)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AvailabilityTable);
