import React from 'react';
import PropTypes from 'prop-types';
import { availableShifts } from 'services/helpers/shift';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import BackCaret from '@material-ui/icons/ArrowBackIos';
import ForwardCaret from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';

function ShiftTabs(props) {
  const { classes, selectedShift, changeSelectedShift } = props;
  const currentShiftIndex = availableShifts.indexOf(selectedShift);
  const nextDisabled = currentShiftIndex + 1 > availableShifts.length - 1;
  const previousDisabled = currentShiftIndex - 1 < 0;
  const nextShift = () => {
    try {
      if (nextDisabled)
        throw new Error('Index out of range for availableShifts');
      changeSelectedShift(availableShifts[currentShiftIndex + 1]);
    } catch (error) {
      console.error(error);
    }
  };
  const previousShift = () => {
    try {
      if (previousDisabled)
        throw new Error('Index out of range for availableShifts');
      changeSelectedShift(availableShifts[currentShiftIndex - 1]);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={classes.root}>
      <IconButton
        id="previous-shift"
        disabled={previousDisabled}
        className={classes.icon}
        onClick={previousShift}
        classes={{ disabled: classes.disabledButton }}
      >
        <BackCaret />
      </IconButton>
      <Typography variant="button" className={classes.currentShift}>{`${moment(
        selectedShift.day
      ).format('ddd D MMM')} - ${selectedShift.shift}`}</Typography>
      <IconButton
        id="next-shift"
        disabled={nextDisabled}
        className={classes.icon}
        onClick={nextShift}
        classes={{ disabled: classes.disabledButton }}
      >
        <ForwardCaret />
      </IconButton>
    </div>
  );
}

ShiftTabs.propTypes = {
  selectedShift: PropTypes.object.isRequired,
  changeSelectedShift: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default ShiftTabs;
