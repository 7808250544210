import React, { useContext, useEffect, useState } from 'react';
import { getWithTokenRefresh } from 'services/clients/axios-with-token';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { SelectedContainerContext } from './SelectedContainerContextProvider';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchContainers } from '../utils/fetch-containers';
import { pageTitlesAndUrls } from 'constants/pages';
import _ from 'lodash';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ContainerStatusTag from '../components/ContainerStatusTag';
import StepperComponent from './StepperComponent';
import {
  CONTAINER_CELL_WIDTH,
  ENABLE_CONTAINER_DETAIL_STEPPER,
  PICKUP_HOLD_KEYS,
} from '../container-config';
import HoldsCell from '../components/HoldsCell';
import LastFreeDayCell from '../components/LastFreeDayCell';
import PickupCell from '../components/PickupCell';
import { getPickupInfo } from '../utils/pickup';
import styles from '../../../styles/typography.module.css';
import { FormattedDateTime, FormattedDate } from '../components/FormattedDate';
import { useUserContext } from 'common/contexts/UserContext';

const TableHeader = ({ name }) => {
  return (
    <div className="px-2 flex space-x-2">
      <h6 className="text-dark uppercase font-bold tracking-wide">{name}</h6>
    </div>
  );
};

const holdColumns = PICKUP_HOLD_KEYS.map(row => row.key);

function displayAsChanged({ isFinalRow, pickupChangedFields }, field) {
  if (isFinalRow) return false;
  if (field === 'createdAt') {
    return pickupChangedFields.includes('import_status');
  }
  if (field === 'holds') {
    return pickupChangedFields.find(field => holdColumns.includes(field));
  }
  if (field === 'pickupLastFreeDay') {
    return (
      pickupChangedFields.includes('last_free_day') ||
      pickupChangedFields.includes('demurrage_fee_us_cents') ||
      pickupChangedFields.includes('good_through_date') ||
      pickupChangedFields.includes('demurrage_due')
    );
  }

  if (field === 'terminalPickup') {
    return pickupChangedFields.includes('available');
  }
}

function getCellClassName(className = '') {
  return function getClass(params) {
    const { row, field } = params;
    return displayAsChanged(row, field)
      ? `${className} bg-blue-600`
      : className;
  };
}

export default function ContainerDetail() {
  const [pickupRows, setPickupRows] = useState(null);

  const navigate = useNavigate();
  const { containerNumber } = useParams();

  const [selectedContainer, setSelectedContainer] = useContext(
    SelectedContainerContext
  );

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Observed On',
      sortable: false,
      renderHeader: params => <TableHeader name={params.colDef.headerName} />,
      minWidth: CONTAINER_CELL_WIDTH,
      flex: 0.5,
      renderCell: ({
        row: { createdAt, pickupStatus, pickupImportStatus },
      }) => (
        <div className="py-4 px-2 flex flex-col space-y-4 items-start">
          <div className="flex flex-col space-y-2">
            <h5 className="font-bold text-gray-200">
              <FormattedDateTime dateTimeString={createdAt} />
            </h5>
          </div>
          <div className="block">
            <ContainerStatusTag
              status={pickupStatus}
              pickupImportStatus={pickupImportStatus}
            />
          </div>
        </div>
      ),
      cellClassName: 'border-r border-blue-400 bg-white w-full',
    },
    {
      field: 'holds',
      headerName: 'Holds',
      sortable: false,
      renderHeader: params => (
        <TableHeader beta name={params.colDef.headerName} />
      ),
      minWidth: 192,
      flex: 0.5,
      renderCell: ({ row: { holds } }) => <HoldsCell holds={holds} />,
      cellClassName: 'bg-white border-r border-blue-400',
    },
    {
      field: 'pickupLastFreeDay',
      headerName: 'Last Free Day',
      sortable: false,
      renderHeader: params => (
        <TableHeader beta name={params.colDef.headerName} />
      ),
      minWidth: 192,
      flex: 0.5,
      renderCell: ({
        row: {
          pickupLastFreeDay,
          status,
          gatedOutDate,
          pickupDemurrageFeeUsCents,
          pickupDemurrageDue,
        },
      }) => (
        <LastFreeDayCell
          pickupLastFreeDay={pickupLastFreeDay}
          status={status}
          gatedOutDate={gatedOutDate}
          pickupDemurrageFeeUsCents={pickupDemurrageFeeUsCents}
          pickupDemurrageDue={pickupDemurrageDue}
          showDelta={false}
        />
      ),
      cellClassName: 'bg-white',
    },
    {
      field: 'terminalPickup',
      headerName: 'terminal pickup',
      sortable: false,
      renderHeader: params => <TableHeader name={params.colDef.headerName} />,
      minWidth: 290,
      flex: 1,
      renderCell: params => (
        <PickupCell
          status={params.row.pickupStatus}
          gatedOutDate={params.row.pickupGatedOutDate}
          gatedOutTerminalName={params.row.pickupTerminalName}
          pickupTerminalName={params.row.pickupTerminalName}
          pickupTerminalAppointmentSystemLink={
            params.row.pickupTerminalAppointmentSystemLink
          }
          pickupAvailable={params.row.pickupAvailable}
          chassis={params.row.chassis}
          releaseBookedAppointment={false}
        />
      ),
      cellClassName: 'bg-white',
    },
  ].map(columnConfig => ({
    ...columnConfig,
    cellClassName: getCellClassName(columnConfig.cellClassName),
  }));

  useEffect(async () => {
    if (!containerNumber) return;
    let watchListRow = selectedContainer.watchListRow;
    if (!selectedContainer.watchListRow) {
      const response = await fetchContainers({}, [containerNumber]);
      watchListRow = _.get(response, 'containerList.0');
    }

    // TODO: add pagination to this page if we see long pickup histories.
    const response = await getWithTokenRefresh(
      `/core/api/v1/containers/${containerNumber}?page_size=${Number.MAX_SAFE_INTEGER}`
    );
    const rows = response.results.map((row, index) => ({
      id: row.id,
      createdAt: row.created_at,
      ...getPickupInfo(row),
      isFinalRow: index === response.results.length - 1,
    }));
    setPickupRows(rows);

    setSelectedContainer({
      ...selectedContainer,
      watchListRow,
    });
  }, [containerNumber]);

  function onClose(event) {
    if (
      event.target.href &&
      event.target.href.includes(pageTitlesAndUrls.containerTracking.path)
    ) {
      return;
    }
    navigate(
      `${pageTitlesAndUrls.containerTracking.path}${selectedContainer.return ||
        ''}`
    );
  }

  const {
    size,
    shippingLine,
    gatedOutDate,
    gatedInDate,
    status,
    pickupImportStatus,
    shippingLineKey,
    containerTypeKey,
    gatedOutTerminalName,
    pickupVesselName,
    gatedInTerminalName,
    transactionCreatedAt,
  } = _.get(selectedContainer || {}, 'watchListRow', {}) || {};
  const {
    user: {
      permissions: { accessPickupTrackChanges },
    },
  } = useUserContext();

  if (!containerNumber || !accessPickupTrackChanges) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={styles.font}>
        <div
          role="presentation"
          className="px-8 py-4 fixed right-0 overflow-y-auto bg-white h-full shadow-lt"
          style={{
            left: 230,
            zIndex: 1000,
          }}
        >
          <div className="flex flex-col">
            <div className="flex flex-col space-y-8">
              <div className="flex justify-between">
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-4">
                      <h1
                        className="font-bold text-dark"
                        style={{ fontSize: '36px' }}
                      >
                        {containerNumber}{' '}
                      </h1>
                      <ContainerStatusTag
                        status={status}
                        pickupImportStatus={pickupImportStatus}
                      />
                    </div>
                    <div className="flex space-x-4 items-center">
                      <div style={{ width: '20%' }}>
                        {containerTypeKey && (
                          <img
                            src={`https://s3.us-west-1.amazonaws.com/erl-cdn.bluecargo.io/container-types/${containerTypeKey}.png`}
                            alt={`logo ${containerTypeKey}`}
                          />
                        )}
                      </div>
                      <h2 className="font-bold">{size}</h2>
                    </div>
                  </div>
                </div>
                {shippingLine && (
                  <div className="w-full flex flex-col items-end">
                    <h1
                      className="font-bold text-dark"
                      style={{ fontSize: '36px' }}
                    >
                      {shippingLine}
                    </h1>
                    <div style={{ width: '100px' }}>
                      <img
                        src={`https://s3-us-west-1.amazonaws.com/erl-cdn.bluecargo.io/shipping-lines/${shippingLineKey}.png`}
                        alt={`logo ${shippingLine}`}
                      />
                    </div>
                  </div>
                )}
              </div>
              {ENABLE_CONTAINER_DETAIL_STEPPER && (
                <StepperComponent
                  status={status}
                  pickupVesselName={pickupVesselName}
                  gatedOutTerminalName={gatedOutTerminalName}
                  gatedOutDate={gatedOutDate}
                  gatedInDate={gatedInDate}
                  gatedInTerminalName={gatedInTerminalName}
                />
              )}
            </div>
            <div className="flex flex-col space-y-4 items-start mt-8">
              <div className="flex flex-col">
                <h4 className="mb-2">
                  <span className="mr-2">
                    Container entered and tracked for the first time:
                  </span>
                  <span className="text-blue">
                    <FormattedDate dateString={transactionCreatedAt} />
                  </span>
                </h4>
                <h5 className="italic">
                  Enter your containers while on vessel to see full historical
                  data
                </h5>
              </div>
              <div className="w-full pb-96">
                <DataGridPro
                  disableVirtualization={
                    process.env.JEST_WORKER_ID !== undefined
                  }
                  autoHeight
                  columnBuffer={columns.length}
                  rowHeight={100}
                  headerHeight={32}
                  rows={pickupRows || []}
                  columns={columns}
                  disableColumnMenu
                  loading={pickupRows === null}
                  rowCount={pickupRows && pickupRows.length}
                  hideFooter
                  initialState={{
                    pagination: {
                      pageSize: Number.MAX_SAFE_INTEGER,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}
