import { createSelector } from 'reselect';
import queryString from 'query-string';
import { startLoading, endLoading } from 'ducks/loader';
import * as availabilityClient from 'services/clients/availability';
import sortBy from 'lodash/sortBy';

// Action Types
export const SET_SHIPPING_LINES = 'core/api/v1/erl/data/SET_SHIPPING_LINES';
export const SET_CONTAINER_TYPES = 'core/api/v1/erl/data/SET_CONTAINER_TYPES';
export const SET_PORTS = 'core/api/v1/erl/data/SET_PORTS';
export const SET_TERMINALS_WITH_APPOINTMENT =
  'core/api/v1/erl/data/SET_TERMINALS_WITH_APPOINTMENT';

// Reducer
const initialState = {
  shippingLines: null,
  containerTypes: null,
  ports: null,
  terminalsWithAppointments: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPPING_LINES:
      return { ...state, shippingLines: action.payload };
    case SET_CONTAINER_TYPES:
      return { ...state, containerTypes: action.payload };
    case SET_PORTS:
      return { ...state, ports: action.payload };
    case SET_TERMINALS_WITH_APPOINTMENT:
      return { ...state, terminalsWithAppointments: action.payload };
    default:
      return state;
  }
};

// Action Creators
export const saveShippingLines = lines => ({
  type: SET_SHIPPING_LINES,
  payload: lines,
});

export const saveContainerTypes = types => ({
  type: SET_CONTAINER_TYPES,
  payload: types,
});

export const savePorts = ports => ({
  type: SET_PORTS,
  payload: ports,
});

export const setTerminalsWithAppointment = terminals => ({
  type: SET_TERMINALS_WITH_APPOINTMENT,
  payload: terminals,
});

// Side effects
export const setShippingLines = () => async (dispatch, getState) => {
  const {
    erlData: { shippingLines },
  } = getState();
  //TODO Add exception management
  if (!shippingLines) {
    dispatch(startLoading('shippingLines'));
    const data = await availabilityClient.getShippingLines();
    dispatch(saveShippingLines(data));
    dispatch(endLoading('shippingLines'));
  }
};

export const setContainerTypes = () => async (dispatch, getState) => {
  const {
    erlData: { containerTypes },
  } = getState();
  //TODO Add exception management
  if (!containerTypes) {
    dispatch(startLoading('containerTypes'));
    const data = await availabilityClient.getContainerTypes();
    dispatch(saveContainerTypes(data));
    dispatch(endLoading('containerTypes'));
  }
};

export const setPorts = isWebsocketTriggered => async (dispatch, getState) => {
  const {
    erlData: { ports },
  } = getState();
  //TODO Add exception management
  if (!ports || isWebsocketTriggered) {
    dispatch(startLoading('ports'));
    const data = await availabilityClient.getPorts();
    dispatch(savePorts(data));
    dispatch(endLoading('ports'));
  }
};

export const retrieveTerminalsWithAppointments = () => async (
  dispatch,
  getState
) => {
  // const {
  //   erlData: { terminalsWithAppointments },
  // } = getState();
  // if (!terminalsWithAppointments.lenght) {
  //   dispatch(startLoading('terminals_with_appointment'));
  //   const res = await erlClient.get('/tas/parser_status');
  //   dispatch(setTerminalsWithAppointment(res.data));
  //   dispatch(endLoading('terminals_with_appointment'));
  // }
};

// Selectors
export const getShippingLinesList = createSelector(
  state => state.erlData.shippingLines,
  shippingLines =>
    shippingLines
      ? shippingLines.sort((slA, slB) => slA.name.localeCompare(slB.name))
      : []
);

export const getContainerTypesList = createSelector(
  state => state.erlData.containerTypes,
  containerTypes => (containerTypes ? sortBy(containerTypes, 'order') : [])
);

export const getPortsList = createSelector(
  state => state.erlData.ports,
  ports =>
    ports ? ports.map(p => p.pier).sort((a, b) => a.localeCompare(b)) : []
);

export const getContainerTypes = createSelector(
  state => state.erlData.containerTypes,
  containerTypes => containerTypes || []
);

export const getShippingLines = createSelector(
  state => state.erlData.shippingLines,
  shippingLines => shippingLines || []
);

export const getPorts = createSelector(
  state => state.erlData.ports,
  ports => ports || []
);

export const extractShippingLinesFromURI = createSelector(
  getShippingLines,
  (state, props) => queryString.parse(props.location.search)['shipping-line'],
  (shippingLinesList, shippingLinesQuery) =>
    shippingLinesQuery
      ? shippingLinesList
          .filter(sl => shippingLinesQuery.split(',').includes(sl.key))
          .sort((a, b) => a.name.localeCompare(b.name))
      : shippingLinesList
          .sort((a, b) => a.name.localeCompare(b.name))
          .slice(1, 2)
);

export const extractShippingLineFromURI = createSelector(
  extractShippingLinesFromURI,
  shippingLines => (shippingLines.length ? shippingLines[0] : null)
);

export const extractContainerTypesFromURI = createSelector(
  getContainerTypes,
  (state, props) => queryString.parse(props.location.search)['container-type'],
  (containerTypesList, containerTypesQuery) =>
    containerTypesQuery
      ? containerTypesList.filter(ct =>
          containerTypesQuery.split(',').includes(ct.key)
        )
      : containerTypesList.slice(0, 1)
);

export const extractContainerTypeFromURI = createSelector(
  extractContainerTypesFromURI,
  containerTypes => (containerTypes.length ? containerTypes[0] : null)
);

export const getFilteredShippingLine = createSelector(
  getShippingLines,
  state => state.instructionFilters.shippingLines,
  (shippingLines, filteredShippingLine) => {
    if (shippingLines.length === 0) return [];
    return filteredShippingLine && filteredShippingLine.length > 0
      ? filteredShippingLine
          .map(filtered => shippingLines.find(sl => sl.key === filtered.value))
          .filter(x => x)
      : shippingLines;
  }
);

export const getFilteredContainerTypes = createSelector(
  getContainerTypes,
  state => state.instructionFilters.containerTypes,
  (containerTypes, filteredContainerTypes) => {
    if (containerTypes.length === 0) return [];
    return filteredContainerTypes.length > 0
      ? filteredContainerTypes
          .map(filtered => containerTypes.find(ct => ct.key === filtered.value))
          .filter(x => x)
      : containerTypes;
  }
);

export const getFilteredTerminals = createSelector(
  getPorts,
  state => state.instructionFilters.terminals,
  (terminals, filteredTerminals) => {
    if (terminals.length === 0) return [];
    return filteredTerminals.length > 0
      ? filteredTerminals
          .map(filtered => terminals.find(term => term.pier === filtered.value))
          .filter(x => x)
      : terminals;
  }
);
