import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from 'components/customDesign/DialogContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import cn from 'classnames';
import Button from 'components/customDesign/Button';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import ListInfoItem from 'components/customDesign/ListInfoItem';
import { pageTitlesAndUrls } from 'constants/pages';
import { useNavigate } from 'react-router-dom';

const LOCAL_STORAGE_KEY = 'trial_confirmation';
const DAYS_TO_WARN = [1, 3, 7];

const TrialWillEndDialog = ({ classes, trialEndDate }) => {
  const [close, setClose] = useState(false);
  const navigate = useNavigate();

  let days;
  if (moment(trialEndDate).isAfter()) {
    days = Math.floor(
      moment.duration(moment(trialEndDate).diff(moment())).asDays()
    );
  }
  const confirmedTrials = localStorage.getItem(LOCAL_STORAGE_KEY) || [];

  const onClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, [...confirmedTrials, days]);
    setClose(!close);
  };

  const handleOnClick = () => {
    setClose(false);
    navigate(pageTitlesAndUrls.plans.path);
  };

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={
        DAYS_TO_WARN.indexOf(days) !== -1 &&
        confirmedTrials.indexOf(days) === -1
      }
      onClose={onClose}
    >
      <DialogContent
        TopIcon={({ className, ...props }) => (
          <Avatar className={cn(className, classes.avatar)} {...props}>
            !
          </Avatar>
        )}
        title="Heads up!"
        subtitle={`Your free trial ends in ${days} days`}
        callToAction={
          <React.Fragment>
            <Button onClick={handleOnClick} classes={{ root: classes.button }}>
              See the plans
            </Button>
            <div>
              <Typography
                className={classes.underline}
                variant="caption"
                component="a"
                align="center"
                href="mailto:support@bluecargo.io,alexandra@bluecargo.io?bcc=laura@bluecargo.io"
              >
                Contact us
              </Typography>
            </div>
          </React.Fragment>
        }
      >
        <ListInfoItem
          primary="Talk to your manager"
          secondary="Ask your manager to input a credit card before the end of your free month to keep using BlueCargo"
        />
        <ListInfoItem
          primary="Share with the team"
          secondary={
            <span>
              Get the best of your free trial by{' '}
              <span className={classes.underline}>inviting the whole crew</span>{' '}
              (dispatcher, CS and beyond) - admins only!
            </span>
          }
        />
        <ListInfoItem
          primary="Need help?"
          secondary={
            <span>
              <a
                className={classes.link}
                href="mailto:support@bluecargo.io,alexandra@bluecargo.io?bcc=laura@bluecargo.io"
              >
                Contact us
              </a>{' '}
              and find the best plan for your team
            </span>
          }
        />
      </DialogContent>
    </Dialog>
  );
};

TrialWillEndDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  trialEndDate: PropTypes.string.isRequired,
};

const styles = theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  root: {
    padding: 3 * theme.spacing.unit,
    width: theme.layout.popupWidth,
  },
  button: {
    margin: 'auto',
    marginBottom: 2 * theme.spacing.unit,
    marginTop: 2 * theme.spacing.unit,
  },
  underline: {
    textDecoration: 'underline',
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
  },
});

export default withStyles(styles)(TrialWillEndDialog);
