import ShiftPicker from './ShiftPicker';
import styles from './ShiftPicker.style';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { changeSelectedShift } from 'ducks/availabilities';

const mapStateToProps = state => ({
  selectedShift: state.availabilities.selectedShift,
});

const mapDispatchToProps = dispatch => ({
  changeSelectedShift: shift => dispatch(changeSelectedShift({ shift })),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ShiftPicker);
