export default theme => ({
  root: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    flex: 1,
  },
  terminalCell: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  table: {},
});
