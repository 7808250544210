import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';

import TerminalCellContent from 'components/TerminalCellContent';
import { ReactComponent as BarbIcon } from 'assets/images/barb.svg';
import { withStyles } from '@material-ui/core/styles';
import {
  LARGE_TABLE_CELL_WIDTH,
  SHIPPING_LINE_TABLE_CELL_WIDTH,
  SMALL_TABLE_CELL_WIDTH,
} from 'constants/layout';
import { SegmentService } from 'services/helpers/segment';

export class TerminalHeader extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    terminals: PropTypes.array.isRequired,
    displayShift: PropTypes.bool.isRequired,
  };

  computeFirstCellStyle() {
    const { displayShift } = this.props;
    const baseWidth = SHIPPING_LINE_TABLE_CELL_WIDTH + SMALL_TABLE_CELL_WIDTH;
    return {
      width: displayShift ? baseWidth + LARGE_TABLE_CELL_WIDTH : baseWidth,
      minWidth: displayShift ? baseWidth + LARGE_TABLE_CELL_WIDTH : baseWidth,
    };
  }

  handleTerminalClick = terminal_key => {
    SegmentService.trackEvent(
      'Outbound Terminal Clicked',
      {
        terminal: terminal_key,
        url: '/terminals',
      },
      { integrations: { Slack: false } }
    );
  };

  render() {
    const { classes, terminals } = this.props;
    return (
      <div className={classes.wrapper}>
        <div
          style={this.computeFirstCellStyle()}
          className={classnames(classes.terminal, classes.labelCell)}
        >
          <div className={classes.label}>
            <BarbIcon className={classes.labelIcon} />
            <div className={classes.labelTexts}>
              <Typography variant="caption" className={classes.labelPrimary}>
                Terminal
              </Typography>
            </div>
          </div>
        </div>
        {terminals.map(terminal => (
          <TerminalCellContent
            key={terminal.name}
            terminal={terminal}
            classes={{ root: classes.terminal }}
            onHandleTerminalClick={this.handleTerminalClick}
          />
        ))}
      </div>
    );
  }
}

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  terminal: {
    verticalAlign: 'top',
    zIndex: 1,
    width: LARGE_TABLE_CELL_WIDTH,
    minWidth: LARGE_TABLE_CELL_WIDTH,
    textAlign: 'center',
    paddingTop: theme.spacing.unit,
    borderLeft: '0.5px solid rgba(31, 51, 75, 0.1)',
    borderRight: '0.5px solid rgba(31, 51, 75, 0.1)',
    borderBottom: '1px solid rgba(31, 51, 75, 0.1)',
    height: 70,
  },
  labelCell: {
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  labelIcon: {
    flex: 2,
    fontSize: 20,
    color: 'rgba(31, 51, 75, 0.5)',
  },
  labelTexts: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  labelPrimary: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'rgba(31, 51, 75, 0.5)',
  },
});

export default withStyles(styles)(TerminalHeader);
