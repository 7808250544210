import erlClient from '../services/clients';

const SET_GATE_SCHEDULE = 'admin/SET_GATE_SCHEDULE';
const SET_EDITABLE_GATE_SCHEDULE_ITEM = 'admin/SET_EDITABLE_GATE_SCHEDULE_ITEM';
const REMOVE_EDITABLE_GATE_SCHEDULE_ITEM =
  'admin/REMOVE_EDITABLE_GATE_SCHEDULE_ITEM';
const FLUSH_EDITABLE_GATE_SCHEDULE_ITEM =
  'admin/FLUSH_EDITABLE_GATE_SCHEDULE_ITEM';

const SET_PTSC_SCHEDULE = 'admin/SET_PTSC_SCHEDULE';
const UPSERT_PTSC_SCHEDULE = 'admin/UPSERT_PTSC_SCHEDULE';

const initialState = {
  gateSchedule: {
    headers: [],
    rows: [],
    availableStatuses: [],
    editableRows: [],
    lastUpdate: null,
    weekNumber: null,
  },
  ptscSchedule: {
    headers: [],
    rows: [],
    availableStatuses: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GATE_SCHEDULE:
      return {
        ...state,
        gateSchedule: {
          ...state.gateSchedule,
          headers: [{ date: 'Terminals', shift: null }].concat(
            action.payload.headers
          ),
          rows: action.payload.data,
          availableStatuses: action.payload['available_statuses'],
          lastUpdate: action.payload['last_update'],
          weekNumber: action.payload.weekNumber,
          year: action.payload.year,
        },
      };
    case SET_EDITABLE_GATE_SCHEDULE_ITEM:
      let updatedEditableRows = [...state.gateSchedule.editableRows];
      updatedEditableRows.push(action.payload);
      return {
        ...state,
        gateSchedule: {
          ...state.gateSchedule,
          editableRows: updatedEditableRows,
        },
      };
    case FLUSH_EDITABLE_GATE_SCHEDULE_ITEM:
      return {
        ...state,
        gateSchedule: {
          ...state.gateSchedule,
          editableRows: [],
        },
      };
    case REMOVE_EDITABLE_GATE_SCHEDULE_ITEM:
      return {
        ...state,
        gateSchedule: {
          ...state.gateSchedule,
          editableRows: [],
        },
      };
    case SET_PTSC_SCHEDULE:
      return {
        ...state,
        ptscSchedule: {
          ...state.ptscSchedule,
          headers: [''].concat(action.payload.headers),
          rows: action.payload.rows,
          availableStatuses: action.payload['available_statuses'],
        },
      };
    case UPSERT_PTSC_SCHEDULE:
      let rows = [...state.ptscSchedule.rows];
      const row_index = rows.findIndex(
        x => x.container === action.payload.container
      );
      rows[row_index].data[action.payload.rowIndex] = action.payload.value;

      return {
        ...state,
        ptscSchedule: {
          ...state.ptscSchedule,
          rows: rows,
        },
      };
    default:
      return state;
  }
};

export const fetchGateSchedule = (weekNumber, year) => async dispatch => {
  let payload = await erlClient
    .get('/core/api/v1/erl/gate_status?week=' + weekNumber + '&year=' + year)
    .then(response => response.data);
  payload = { ...payload, weekNumber: weekNumber, year: year };
  dispatch({ type: SET_GATE_SCHEDULE, payload: payload });
};

export const updateGateSchedule = (
  weekNumber,
  year,
  editableRows,
  status
) => async dispatch => {
  let payload = await erlClient
    .post(
      '/core/api/v1/erl/gate_status?week=' + weekNumber + '&year=' + year,
      editableRows.map(item => ({ ...item, status: status }))
    )
    .then(response => response.data);
  payload = { ...payload, weekNumber: weekNumber, year: year };
  dispatch({ type: FLUSH_EDITABLE_GATE_SCHEDULE_ITEM });
  dispatch({ type: SET_GATE_SCHEDULE, payload: payload });
};

export const setEditableGateScheduleItem = (
  terminal,
  date,
  shift
) => dispatch => {
  dispatch({
    type: SET_EDITABLE_GATE_SCHEDULE_ITEM,
    payload: { terminal, date, shift },
  });
};

export const flushEditableGateScheduleItem = () => dispatch => {
  dispatch({ type: FLUSH_EDITABLE_GATE_SCHEDULE_ITEM });
};

export const sendGateScheduleEmails = () => async dispatch => {
  await erlClient.post('/core/api/v1/notification/notify_about_gate_updates');
};

export const fetchPtscSchedule = () => async dispatch => {
  const payload = await erlClient
    .get('/core/api/v1/erl/instruction-parser/ptsc')
    .then(response => response.data);
  dispatch({ type: SET_PTSC_SCHEDULE, payload: payload });
};

export const upsertPtscSchedule = (container, rowIndex, value) => dispatch => {
  dispatch({
    type: UPSERT_PTSC_SCHEDULE,
    payload: { container, rowIndex, value },
  });
};

export const updatePtscSchedule = payload => async dispatch => {
  await erlClient.post('/core/api/v1/erl/instruction-parser/ptsc', payload);
};
