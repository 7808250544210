import { combineReducers } from 'redux';
import availabilities from './availabilities';
import availabilityCredentials from './availabilityCredentials';
import myAppointments from './myAppointments';
import vesselSchedule from './vesselSchedule';
import gateSchedule from './gateSchedule';
import refreshToken from './refreshToken';
import erlData from './erlData';
import loader from './loader';
import user from './user';
import display from './display';
import backupBank from './backupBank';
import error from './error';
import instructionFilters from './instructionFilters';
import serviceWorker from './serviceWorker';
import admin from './admin';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const backupBankConfig = {
  key: 'ERL_PER_DIEM',
  storage,
  blacklist: ['perDiemFormValue'],
};
const userConfig = {
  key: 'ERL_USER',
  storage,
  blacklist: ['teamMembers'],
};
const displayConfig = {
  key: 'ERL_DISPLAY',
  storage,
  blacklist: ['signupStep'],
};

const appReducer = combineReducers({
  refreshToken,
  availabilityCredentials,
  availabilities,
  myAppointments,
  vesselSchedule,
  gateSchedule,
  erlData,
  loader,
  user: persistReducer(userConfig, user),
  display: persistReducer(displayConfig, display),
  backupBank: persistReducer(backupBankConfig, backupBank),
  error,
  instructionFilters,
  serviceWorker,
  admin,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    localStorage.removeItem('persist:ERL_USER');

    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
