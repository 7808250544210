import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as UpdateIcon } from 'assets/images/update.svg';
import Tooltip from 'components/customDesign/Tooltip';

const RefreshButton = props => {
  return (
    <Tooltip tooltipText="Refresh shift">
      <IconButton onClick={() => window.location.reload()}>
        <UpdateIcon />
      </IconButton>
    </Tooltip>
  );
};

RefreshButton.propTypes = {};

export default RefreshButton;
