import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import queryString from 'query-string';
import erlClient from 'services/clients';
import { Navigate, useLocation } from 'react-router-dom';
import InformationPage from 'components/InformationPage';
import CodeForm from './components/CodeForm';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { SIGNUP_SET_STEP } from 'ducks/display';

const sendValidationMail = async email =>
  await erlClient.post(
    '/core/api/v1/auth/email-validation/send',
    { email },
    {
      headers: { Authorization: '' },
    }
  );

function EmailConfirmation(props) {
  const { classes } = props;
  const email = queryString.parse(props.location.search)['email'];
  const redirect = queryString.parse(props.location.search)['redirect'];
  const [validated, setValidated] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  useEffect(() => {
    if (!emailSent) {
      sendValidationMail(email).then(res => {
        // If email address has already been validated we send a 208 already
        // reported status code.
        res.status === 208 && setValidated(true);
      });
    }
    setEmailSent(true);
  });

  if (validated) {
    return <Navigate replace to={redirect || '/'} />;
  }

  return (
    <InformationPage classes={{ container: classes.root }} hideHeader>
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <MailIcon width={20} height={20} />
          </Avatar>

          <Typography component="h1" variant="h5" gutterBottom>
            Please verify your email
          </Typography>
          <Typography variant="caption" className={classes.subHeader}>
            We just sent a six-digit confirmation code to {email}. It will
            expire in 30 minutes, enter it soon!
          </Typography>
          <CodeForm email={email} successCallback={() => setValidated(true)} />
          <Typography
            variant="caption"
            className={classes.sendLink}
            onClick={() => sendValidationMail(email)}
          >
            Resend confirmation code
          </Typography>
        </Paper>
      </main>
    </InformationPage>
  );
}

EmailConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = theme => ({
  root: {
    justifyContent: 'unset',
  },
  sendLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  main: {
    width: 'auto',
    zIndex: 2,
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
    boxShadow: 'unset',
  },
  avatar: {
    marginBottom: 3 * theme.spacing.unit,
    backgroundColor: theme.palette.primary.main,
    width: 36,
    height: 36,
  },
  subHeader: {
    marginTop: theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
  },
});

const mapDispatchToProps = dispatch => ({
  setStep: step => dispatch({ type: SIGNUP_SET_STEP, payload: step }),
});

const withHooksHOC = Component => {
  return props => {
    const location = useLocation();

    return <Component location={location} {...props} />;
  };
};

export default compose(
  withHooksHOC,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(EmailConfirmation);
