import React from 'react';
import SignUp from './SignUp';
import { withStyles } from '@material-ui/core/styles';
import styles from './SignUp.style';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { signUp, isLogged, logout } from 'ducks/user';
import { SIGNUP_SET_STEP } from 'ducks/display';
import { useLocation } from 'react-router-dom';

const mapStateToProps = state => ({
  isLogged: isLogged(state),
});

const mapDispatchToProps = dispatch => ({
  signUp: form => dispatch(signUp(form)),
  setStep: step => dispatch({ type: SIGNUP_SET_STEP, payload: step }),
  logOut: () => dispatch(logout()),
});

const withHooksHOC = Component => {
  return props => {
    const location = useLocation();

    return <Component location={location} {...props} />;
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withHooksHOC
)(SignUp);
