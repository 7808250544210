import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { brandColors } from 'brandColors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ReactComponent as ThumbIcon } from 'assets/icons/slider_thumb.svg';
import { formatNumber } from 'utils/common';
import cx from 'classnames';
import _ from 'lodash';

const getRailBackgroundColor = (rangeVolume, volumeList) => {
  const stepSizePourcentage = 100 / (volumeList.length - 1);
  const pourcentage = rangeVolume[0] * stepSizePourcentage;
  return `linear-gradient(to right, ${brandColors.colors.gray[300]}, ${brandColors.colors.gray[300]} ${pourcentage}%, ${brandColors.colors.gray[600]} ${pourcentage}%, ${brandColors.colors.gray[600]})`;
};

const ContainerVolumeSlider = ({
  minVolume,
  availableVolumes,
  volumeList,
  preSelectedVolume,
  period,
  onVolumeChange,
  disabled,
}) => {
  const minVolumeIndex = volumeList.indexOf(minVolume);
  const preSelectedVolumeIndex = _.isNil(preSelectedVolume)
    ? volumeList.length
    : volumeList.indexOf(preSelectedVolume);
  const [rangeVolume, setRangeVolume] = useState([
    minVolumeIndex,
    preSelectedVolumeIndex,
  ]);
  const marks = [];
  Object.entries(availableVolumes).forEach(([key, value], idx) => {
    marks.push({
      value: idx,
      label: value.category,
    });
  });
  marks.push({
    value: marks.length,
    label: 'More',
  });

  useEffect(() => setRangeVolume([minVolumeIndex, preSelectedVolumeIndex]), [
    minVolumeIndex,
    preSelectedVolumeIndex,
    availableVolumes,
  ]);

  const handleChange = (event, newValue, activeThumb) => {
    if (activeThumb === 1 && newValue[1] !== minVolumeIndex) {
      setRangeVolume(newValue);
    }
    if (activeThumb === 0 && newValue[0] > minVolumeIndex) {
      setRangeVolume([minVolumeIndex, newValue[0]]);
    }
  };

  return (
    <div className="w-full flex">
      <span
        className="bg-gray-300 rounded-l-sm"
        style={{
          width: '26.5px',
          height: '4px',
          marginTop: '13px',
        }}
      ></span>
      <Slider
        value={rangeVolume}
        marks={marks}
        min={0}
        max={marks.at(-1).value}
        step={null}
        valueLabelDisplay="off"
        onChange={handleChange}
        disabled={disabled}
        onChangeCommitted={(e, rangeVolume) =>
          onVolumeChange(volumeList[rangeVolume[1]])
        }
        components={{
          Mark: CustomMark,
          MarkLabel: CustomMarkLabel,
          Thumb: CustomThumb,
        }}
        sx={{
          '.MuiSlider-track': {
            borderRadius: 0,
            height: '2px',
            zIndex: 2,
          },
          '.MuiSlider-rail': {
            borderRadius: 0,
            opacity: 1,
            background: getRailBackgroundColor(rangeVolume, volumeList),
          },
        }}
        componentsProps={{
          mark: {
            rangeVolume,
            disabled,
          },
          markLabel: {
            availableVolumes,
            volumeList,
            period,
            disabled,
          },
          thumb: {
            maxValue: volumeList[rangeVolume[1]],
            minVolumeIndex,
            period,
            disabled,
          },
        }}
        disableSwap
      />
      <span
        className="relative text-gray-600 bg-gray-600"
        style={{
          width: '36px',
          height: '4px',
          marginTop: '13px',
          zIndex: 1,
        }}
      >
        <ArrowForwardIosIcon
          style={{
            position: 'absolute',
            right: '-9px',
            top: '-10px',
          }}
        />
      </span>
    </div>
  );
};

const CustomMark = ({
  'data-index': dataIndex,
  style,
  rangeVolume,
  disabled,
}) => {
  let color = 'gray-300';
  if (!disabled && dataIndex >= rangeVolume[0] && dataIndex <= rangeVolume[1]) {
    color = 'blue';
  } else if (dataIndex > rangeVolume[1]) {
    color = 'gray-600';
  }
  return (
    <div
      className={`absolute text-${color} bg-${color}`}
      style={{ ...style, width: '2px', height: '8px', top: '17px' }}
    ></div>
  );
};

const CustomThumb = ({
  children,
  className,
  'data-index': dataIndex,
  period,
  maxValue,
  minVolumeIndex,
  disabled,
  ownerState,
  style,
  ...rest
}) => {
  const firstThumb = dataIndex === 0;
  const color =
    firstThumb || disabled
      ? brandColors.colors.gray[300]
      : brandColors.colors.blue.DEFAULT;

  if (firstThumb && minVolumeIndex === -1) {
    return children;
  }

  return (
    <div
      className={cx(
        className,
        'cursor-pointer absolute flex flex-col items-center transform -translate-x-1/2 -translate-y-1/2',
        firstThumb ? '-top-4' : '-top-5'
      )}
      style={{ ...style, pointerEvents: 'auto' }}
      {...rest}
    >
      {children}
      {firstThumb && <h6 className="font-bold text-gray-300">Current</h6>}
      {!firstThumb && (
        <h3 className={`font-bold ${disabled ? 'text-gray-300' : 'text-blue'}`}>
          {maxValue && formatNumber(maxValue)}
        </h3>
      )}
      <ThumbIcon fill={color} stroke={color} />
    </div>
  );
};

const CustomMarkLabel = ({
  availableVolumes,
  volumeList,
  period,
  style,
  className,
  'data-index': dataIndex,
  children,
}) => {
  if (dataIndex >= volumeList.length) {
    return (
      <div
        className={cx(
          className,
          'absolute top-8 flex flex-col text-gray-200 w-14 align-center items-center text-center transform -translate-x-1/2'
        )}
        style={style}
        data-index={dataIndex}
      >
        {children}
      </div>
    );
  }
  const volume = volumeList[dataIndex];
  const category = availableVolumes[volume].category;

  return (
    <div
      className={cx(
        className,
        'absolute top-8 flex flex-col text-gray-200 w-14 align-center items-center text-center transform -translate-x-1/2'
      )}
      style={style}
      data-index={dataIndex}
    >
      <h4 className="font-bold">{formatNumber(volume)}</h4>
      <h6 className="font-medium">{category}</h6>
    </div>
  );
};

export default ContainerVolumeSlider;
