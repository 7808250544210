import React from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from 'common/UI/ModalWrapper';
import { MyAppointmentsService } from './services/my-appointments.service';
import './styles/MyAppointmentsDetailsModal.css';

const MyAppointmentsDetailsModal = ({
  appointmentDetailsModalData,
  onHandleAppointmentDetailsModalClose,
}) => {
  const {
    appointment_id,
    container_number,
    shipping_line,
    shift,
    shift_date,
    pier,
    terminal_name,
    terminal_date,
    end_time,
    start_time,
    chassis_owner,
    chassis_number,
    truck_license,
  } = appointmentDetailsModalData || {};

  const formattedShift = MyAppointmentsService.getFormattedShift(
    shift_date,
    shift
  );
  const formattedDatetime = MyAppointmentsService.getFormattedDatetime(
    terminal_date,
    start_time,
    end_time
  );

  return (
    <ModalWrapper
      width={521}
      withoutBackground
      onModalClose={onHandleAppointmentDetailsModalClose}
    >
      <div className="my-appt-details">
        <div className="my-appt-details-title">Appointment Details</div>

        <div className="my-appt-details-container">
          <div className="my-appt-details-col">
            <div className="my-appt-details-block">
              <div className="my-appt-details-block-title">
                Container Number
              </div>
              <div>{container_number}</div>
            </div>

            <div className="my-appt-details-block">
              <div className="my-appt-details-block-title">SSL</div>
              <div>{shipping_line}</div>
            </div>

            <div className="my-appt-details-block">
              <div className="my-appt-details-block-title">Return Location</div>
              <div>{`${pier} (${terminal_name})`}</div>
            </div>

            <div className="my-appt-details-block">
              <div className="my-appt-details-block-title">
                Appointment Shift
              </div>
              <div>{formattedShift}</div>
            </div>
          </div>

          <div className="my-appt-details-col">
            <div className="my-appt-details-block">
              <div className="my-appt-details-block-title">
                Appointment Datetime
              </div>
              <div>{formattedDatetime}</div>
            </div>

            <div className="my-appt-details-block">
              <div className="my-appt-details-block-title">Confirmation #</div>
              <div>{appointment_id || '-'}</div>
            </div>

            <div className="my-appt-details-block">
              <div className="my-appt-details-block-title">Chassis Owner</div>
              <div>{chassis_owner || '-'}</div>
            </div>

            <div className="my-appt-details-block">
              <div className="my-appt-details-block-title">Chassis #</div>
              <div>{chassis_number || '-'}</div>
            </div>

            <div className="my-appt-details-block">
              <div className="my-appt-details-block-title">Driver License</div>
              <div>{truck_license || '-'}</div>
            </div>
          </div>
        </div>

        <div className="my-appt-details-button-wrapper">
          <div
            className="my-appt-details-button"
            onClick={onHandleAppointmentDetailsModalClose}
          >
            CLOSE
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

MyAppointmentsDetailsModal.propTypes = {
  appointmentDetailsModalData: PropTypes.object,
  onHandleAppointmentDetailsModalClose: PropTypes.func,
};

export default MyAppointmentsDetailsModal;
