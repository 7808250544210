import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

const ShippingLineTooltip = ({ shippingLine, classes, onClose }) => {
  return (
    <Paper square className={classes.root}>
      <IconButton onClick={onClose} className={classes.closeButton}>
        <Close />
      </IconButton>
      <div className={classes.row}>
        {shippingLine.websites && (
          <div className={classes.column}>
            <Typography
              className={classes.sectionTitle}
              variant="h3"
              gutterBottom
            >
              Website(s)
            </Typography>
            {shippingLine.websites.map(w => (
              <div key={w.url} className={classes.item}>
                <a className={classes.link} target="blank" href={w.url}>
                  {w.link_display}
                </a>
                <Typography variant="caption" inline>
                  {' '}
                  {w.additional}
                </Typography>
              </div>
            ))}
          </div>
        )}
        {shippingLine.emails && (
          <div className={classes.column}>
            <Typography
              className={classes.sectionTitle}
              variant="h3"
              gutterBottom
            >
              Email(s)
            </Typography>
            {shippingLine.emails.map(e => (
              <div key={e.email_address} className={classes.item}>
                <a
                  className={classes.link}
                  target="blank"
                  href={`mailto:${e.email_address}`}
                >
                  {e.email_display}
                </a>
                <Typography variant="caption" inline>
                  {' '}
                  {e.additional}
                </Typography>
              </div>
            ))}
          </div>
        )}
        {shippingLine.phone_numbers && (
          <div className={classes.column}>
            <Typography
              className={classes.sectionTitle}
              variant="h3"
              gutterBottom
            >
              Phone number(s)
            </Typography>
            {shippingLine.phone_numbers.map(p => (
              <div key={p.number} className={classes.item}>
                <a
                  className={classes.link}
                  target="blank"
                  href={`tel:${p.number}`}
                >
                  {p.display}
                </a>
                <Typography variant="caption" inline>
                  {' '}
                  {p.additional}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>
    </Paper>
  );
};

ShippingLineTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  shippingLine: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};
const styles = theme => ({
  root: {
    padding: 2 * theme.spacing.unit,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing.unit,
    right: theme.spacing.unit,
  },
  sectionTitle: {
    fontWeight: 'bold',
    color: 'rgba(31, 51, 75, 0.5)',
    marginBottom: 2 * theme.spacing.unit,
  },
  column: {
    padding: theme.spacing.unit,
    paddingLeft: 2 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
    flex: 1,
    borderLeft: `solid 2px rgba(31, 51, 75, 0.1)`,
    '&:first-child': {
      borderLeft: 'unset',
    },
  },
  link: {
    fontSize: 12,
    color: '#3E6696',
    textDecoration: 'underline',
  },
  item: {
    marginBottom: 2 * theme.spacing.unit,
  },
});
export default withStyles(styles)(ShippingLineTooltip);
