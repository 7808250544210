import { createSelector } from 'reselect';

// Action types
export const START_LOADING = 'core/api/v1/erl/loader/START_LOADING';
export const END_LOADING = 'core/api/v1/erl/loader/END_LOADING';

// Reducer
const initialState = {
  shippingLines: false,
  containerTypes: false,
  availabilities: false,
  signUp: false,
  signIn: false,
  backupBankDates: false,
  backupBankKeys: false,
  backupBankUrl: false,
  downloadBackup: false,
  addNotification: false,
  removeNotification: false,
  getNotifications: false,
  isMyAppointmentsLoading: false,
  isVesselScheduleLoading: false,
  isGateScheduleLoading: false,
  isDownloadDailyReportLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        [action.payload]: true,
      };
    case END_LOADING:
      return {
        ...state,
        [action.payload]: false,
      };
    default:
      return state;
  }
};

// Action Creators
export const startLoading = loader => ({
  type: START_LOADING,
  payload: loader,
});

export const endLoading = loader => ({
  type: END_LOADING,
  payload: loader,
});

// Selectors
export const globalLoaderSelector = createSelector(
  state => state.loader,
  loader => Object.values(loader).reduce((a, b) => a || b)
);
