import React, { useEffect } from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';

const SavedCreditCard = ({ paymentList, onFormIsValid }) => {
  const defaultPayment = paymentList[0];
  useEffect(
    () =>
      onFormIsValid(paymentList.length > 0, {
        type: 'saved_card',
        paymentMethodId: defaultPayment ? defaultPayment.id : null,
      }),
    []
  );

  return (
    <Autocomplete
      disablePortal
      disabled={paymentList.length === 0}
      options={paymentList}
      getOptionLabel={option => {
        const card = option.card;
        return `${card.brand.toUpperCase()}····${card.last4}, exp ${
          card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month
        }/${card.exp_year}`;
      }}
      defaultValue={defaultPayment}
      disableClearable
      filterSelectedOptions
      onChange={(e, card) =>
        onFormIsValid(true, {
          type: 'saved_card',
          paymentMethodId: card.id,
        })
      }
      renderInput={params => (
        <TextField
          {...params}
          placeholder={
            paymentList.length === 0 ? 'No saved card' : 'Select a card'
          }
          variant="outlined"
        />
      )}
    />
  );
};

export default SavedCreditCard;
