import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import EmailIcon from '@mui/icons-material/Email';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Input from 'components/customDesign/Input';
import { Formik, Field } from 'formik';
import { isValidEmail } from 'services/helpers/form';
import erlClient from 'services/clients';
import { retrieveTeamMember } from 'ducks/user';
import { compose } from 'redux';
import { connect } from 'react-redux';
import UserRightField from './UserRightField';
import fonts from 'styles/typography.module.css';
import { ThemeProvider } from '@mui/styles';
import { themeProvider } from 'themeProvider';

const Form = props => (
  <ThemeProvider theme={themeProvider}>
    <form
      onSubmit={props.handleSubmit}
      className="w-full flex flex-col justify-center"
    >
      <FormControl required fullWidth error={Boolean(props.errors.email)}>
        <Field
          type="email"
          id="email"
          placeholder="New member's email"
          value={props.values.email}
          component={Input}
          onChange={e => {
            props.handleChange(e);
            props.setStatus({});
          }}
        />
        <FormHelperText>
          {props.errors.email && props.touched.email && props.errors.email}
        </FormHelperText>
      </FormControl>
      {props.isAdmin && (
        <UserRightField
          value={props.values.is_admin}
          name="is_admin"
          handleChange={props.handleChange}
        />
      )}
      <FormControl error={Boolean(props.errors.global)}>
        <FormHelperText>
          {props.errors.global && props.errors.global}
        </FormHelperText>
      </FormControl>
      <h4>{props.status && props.status.msg}</h4>
      <Button
        type="submit"
        disabled={props.isSubmitting}
        isLoading={props.isSubmitting}
        color="primary"
        variant="contained"
      >
        Send a team invite
      </Button>
    </form>
  </ThemeProvider>
);
Form.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  setStatus: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  status: PropTypes.object,
  classes: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
};

const InviteDialog = ({ submitCallback, isAdmin, isOpen, handleClose }) => {
  const initialValues = { email: '', is_admin: false };
  const validate = values => {
    let errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };
  const submit = (
    values,
    { setSubmitting, setErrors, resetForm, setStatus }
  ) => {
    setStatus({});
    erlClient
      .post('/core/api/v1/auth/company/add-member', values)
      .then(res => {
        setSubmitting(false);
        resetForm();
        setStatus({ msg: 'Invite sent' });
        submitCallback && submitCallback();
      })
      .catch(err => {
        setErrors({ global: err.response.data.message });
        setSubmitting(false);
      });
  };

  return (
    <div className={fonts.font}>
      <div className="w-full flex py-4 justify-center">
        <Dialog open={isOpen} onClose={handleClose}>
          <div className={fonts.font}>
            <div className="bg-white w-full py-8 px-4" style={{ width: 384 }}>
              <div
                className="absolute top-4 right-4 cursor-pointer"
                onClick={handleClose}
              >
                <CloseIcon />
              </div>
              <div className="flex flex-col space-y-2 justify-center items-center">
                <div className="w-12 h-12 bg-blue rounded-full flex items-center justify-center">
                  <EmailIcon className="text-white" />
                </div>
                <h2>Invite Team Members</h2>
                <h4> Add new members to your team</h4>
                <Formik
                  initialValues={initialValues}
                  validate={validate}
                  onSubmit={submit}
                  render={props => <Form {...props} isAdmin={isAdmin} />}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

InviteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  submitCallback: PropTypes.func,
  isAdmin: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitCallback: () => {
    dispatch(retrieveTeamMember());
    ownProps.refreshPending();
  },
});

export default compose(connect(null, mapDispatchToProps))(InviteDialog);
