import io from 'socket.io-client';
import { store } from 'store';
import { setFirstAppointment } from 'ducks/availabilities';
import config from 'config';

export default class SocketFirstAppoitnmentClient {
  constructor() {
    this.socket = io(config.wsUrl, {
      query: {
        roomName: 'first_appointment',
      },
    });

    this.socket.on('first appointment update', function(msg) {
      store.dispatch(setFirstAppointment(msg));
    });
  }

  leave() {
    this.socket.close();
  }
}
