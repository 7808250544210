import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/customDesign/Button';
import { ReactComponent as DownloadIcon } from 'assets/icons/download_circle.svg';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { downloadSnapshotPDF } from 'ducks/backupBank';

export class Download extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    snapshotKey: PropTypes.string,
    downloadPdf: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  render() {
    const { snapshotKey, downloadPdf, isLoading, classes } = this.props;
    if (snapshotKey)
      return (
        <Button
          onClick={downloadPdf}
          disabled={isLoading}
          id="download-snapshot"
          isLoading={isLoading}
          rightIcon={<DownloadIcon className={classes.icon} />}
        >
          Get screenshot
        </Button>
      );
    return <div />;
  }
}

const styles = theme => ({
  icon: {
    marginLeft: theme.spacing.unit,
  },
});

const mapStateToProps = state => ({
  isLoading: state.loader.downloadBackup,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  downloadPdf: () => dispatch(downloadSnapshotPDF(ownProps.snapshotKey)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Download);
