import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';

import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import UserAvatar from './UserAvatar';
import Button from 'components/customDesign/Button';

import { ReactComponent as SaveIcon } from 'assets/icons/save_circle.svg';
import { ReactComponent as ProfilIcon } from 'assets/icons/profile_circle.svg';

import { getFirstLetters } from 'services/helpers/user';

import erlClient from 'services/clients';

import { retrieveTeamMember } from 'ducks/user';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ValidateUserDelete from './ValidateUserDelete';
import UserRightField from './UserRightField';

const EditUserRightDialog = props => {
  const {
    isDialogOpen,
    closeDialog,
    selectedMember,
    classes,
    submitCallback,
  } = props;
  const initialValues = {
    is_admin: selectedMember && selectedMember.is_admin,
    email: selectedMember && selectedMember.email,
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={closeDialog}
      classes={{ paper: classes.root }}
    >
      <div className={classes.closeModalIcon}>
        <IconButton onClick={closeDialog}>
          <CloseIcon style={{ fontSize: 18 }} />
        </IconButton>
      </div>
      <ProfilIcon className={classes.headerIcon} />
      <Typography align="center" variant="h5" gutterBottom>
        Access
      </Typography>
      <Typography align="center" variant="caption" className={classes.subtitle}>
        Edit the level of access for a member
      </Typography>
      {selectedMember && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, setValues, setErrors }) => {
            try {
              await erlClient.put('/core/api/v1/auth/company/admins', values);
              submitCallback && submitCallback();
              closeDialog();
              setSubmitting(false);
            } catch (e) {
              setSubmitting(false);
              setErrors(e.response.data);
            }
          }}
        >
          {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.content}>
                <ListItem>
                  <UserAvatar
                    size="small"
                    letters={getFirstLetters(selectedMember)}
                  />
                  <ListItemText
                    classes={{
                      root: classes.userNameRole,
                      primary: classes.textPrimary,
                      secondary: classes.textSecondary,
                    }}
                    primary={`${selectedMember.first_name} ${selectedMember.last_name}`}
                    secondary={selectedMember.is_admin ? 'Admin' : 'Member'}
                  />
                </ListItem>
                <UserRightField
                  value={values.is_admin}
                  name="is_admin"
                  handleChange={handleChange}
                />
                <Typography
                  align="left"
                  variant="caption"
                  className={classes.error}
                >
                  {errors.message}
                </Typography>
                <ValidateUserDelete
                  selectedMember={selectedMember}
                  submitCallback={() => {
                    closeDialog();
                    submitCallback && submitCallback();
                  }}
                />
              </div>
              <Button
                type="submit"
                rightIcon={<SaveIcon className={classes.icon} />}
                isLoading={isSubmitting}
              >
                Save
              </Button>
            </form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

EditUserRightDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  selectedMember: PropTypes.object,
  submitCallback: PropTypes.func,
};

const styles = theme => ({
  root: {
    padding: 4 * theme.spacing.unit,
    textAlign: 'center',
  },
  icon: {
    marginLeft: theme.spacing.unit,
  },
  content: {
    padding: 2 * theme.spacing.unit,
    backgroundColor: 'rgba(31, 51, 75, 0.05)',
    marginBottom: 5 * theme.spacing.unit,
  },
  information: {
    margin: 2 * theme.spacing.unit,
    maxWidth: 240,
    minHeight: 100,
  },
  group: {
    flexDirection: 'row',
  },
  subtitle: {
    marginBottom: 3 * theme.spacing.unit,
  },
  headerIcon: {
    margin: 'auto',
    marginBottom: 3 * theme.spacing.unit,
  },
  error: {
    maxWidth: 240,
    color: theme.palette.alert.main,
  },
  radioLabel: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  descRoot: {
    width: 240,
  },
  userNameRole: {
    marginLeft: '10px',
  },
  closeModalIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

const mapDispatchToProps = dispatch => ({
  submitCallback: () => dispatch(retrieveTeamMember()),
});

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(EditUserRightDialog);
