import { useUserContext } from 'common/contexts/UserContext';
import React from 'react';
import PerDiemArchivePage from './PerDiemArchivePage';

const PerDiemArchiveV2 = () => {
  const { user } = useUserContext();

  return <PerDiemArchivePage currentUser={user} />;
};

export default PerDiemArchiveV2;
