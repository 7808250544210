import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import {
  getShippingLines,
  getContainerTypes,
  getPorts,
  setShippingLines,
  setContainerTypes,
  setPorts,
} from 'ducks/erlData';
import {
  getNotificationSettings,
  updateNotificationSettings,
} from 'services/clients/notification';

import FormContent from './FormContent';

export class Form extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    shippingLines: PropTypes.array.isRequired,
    containerTypes: PropTypes.array.isRequired,
    terminals: PropTypes.array.isRequired,
    importData: PropTypes.func.isRequired,
    refreshToken: PropTypes.bool,
  };
  state = { initialValues: null, isLoading: false };

  handleGetNotificationSettings = async () => {
    try {
      const initialValues = await getNotificationSettings();
      this.setState({ initialValues });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.importData();
    this.handleGetNotificationSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.refreshToken !== this.props.refreshToken) {
      this.props.importData();
      this.handleGetNotificationSettings();
    }
  }

  submit = async (value, actions) => {
    try {
      const data = await updateNotificationSettings(value);
      actions.setValues(data);
      actions.setSubmitting(false);
      actions.setStatus('success');
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { shippingLines, containerTypes, terminals } = this.props;
    const { initialValues, isLoading } = this.state;
    if (isLoading)
      return (
        <div className="w-full text-center">
          <CircularProgress />
        </div>
      );
    if (!initialValues || !containerTypes.length || !shippingLines.length)
      return <div />;

    const possibleShippingLine = shippingLines.map(sl => ({
      value: sl.key,
      label: sl.name,
    }));

    const possibleContainerTypes = containerTypes.map(ct => ({
      value: ct.key,
      label: ct.name,
    }));

    const possibleTerminals = terminals.map(t => ({
      value: t.key,
      label: t.pier,
    }));

    return (
      <div className="w-full bg-white shadow-sm">
        <Formik
          initialValues={this.state.initialValues}
          onSubmit={this.submit}
          initialStatus={'pending'}
        >
          {props => (
            <FormContent
              {...props}
              possibleShippingLine={possibleShippingLine}
              possibleContainerTypes={possibleContainerTypes}
              possibleTerminals={possibleTerminals}
              shippingLines={shippingLines}
              containerTypes={containerTypes}
              terminals={terminals}
            />
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  refreshToken: state.refreshToken,
  shippingLines: getShippingLines(state),
  containerTypes: getContainerTypes(state),
  terminals: getPorts(state),
});

const mapDispatchToProps = dispatch => ({
  importData: () => {
    dispatch(setPorts());
    dispatch(setShippingLines());
    dispatch(setContainerTypes());
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Form);
