import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
import { loginWithToken } from 'ducks/user';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Button from 'components/customDesign/Button';
import { pageTitlesAndUrls } from 'constants/pages';

const ForceLogin = ({ isLoading, loginWithToken }) => {
  const [searchParams] = useSearchParams();
  const access_token = searchParams.get('access_token');
  const refresh_token = searchParams.get('refresh_token');
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    loginWithToken(access_token, refresh_token, () => setIsLogged(true));
  }, [access_token]);

  if (isLogged) return <Navigate replace to={pageTitlesAndUrls.profile.path} />;
  return (
    <Button isLoading={isLoading} onClick={loginWithToken}>
      Login
    </Button>
  );
};

ForceLogin.propTypes = {
  loginWithToken: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  loginWithToken: (access_token, refresh_token, successCallBack) =>
    dispatch(loginWithToken(access_token, refresh_token, successCallBack)),
});

const mapStateToProps = state => ({
  isLoading: state.loader.signIn,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ForceLogin
);
