import React from 'react';
// MUI
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Alert from '@mui/material/Alert';
// Styles
import { themeProvider } from '../../themeProvider';
import { brandColors } from '../../brandColors';
import styles from '../../styles/typography.module.css';
import styleImage from './perDiemArchivePage.module.css';
// Services & Utils
import { sortOnName } from '../../services/clients/availability';
import { getDailyReportPreviewEnd } from '../../services/clients/backupBank';
import { timeList } from './list-of-times';
import * as userClient from 'services/clients/user';
import moment from 'moment';
// Layouts & Components
import { ReactComponent as VesselIcon } from '../../assets/andora-icons/vessel.svg';
import { ReactComponent as ContainerIcon } from '../../assets/andora-icons/container.svg';
import PerDiemDownloadModal from '../../components/PerDiemDownloadModal';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import {
  getDefaultSelections,
  BACKEND_DATE_FORMAT,
  PER_DIEM_RANGE_LIMIT_DAYS,
} from './config';
import { SegmentService } from 'services/helpers/segment';
import PageTitle from 'components/PageTitle/PageTitle';
import Container from 'layouts/Containers/Container';
import { getWithTokenRefresh } from 'services/clients/axios-with-token';
class PerDiemArchivePage extends React.Component {
  state = {
    initialDataLoaded: false,
    loading: true,
    containerTypes: [],
    shippingLines: [],
    selectedRadioButton: 'specific-date',
    selectedShippingLineValue: '',
    selectedContainerTypeValue: '',
    selectedStartDateValue: moment(),
    selectedEndDateValue: moment(),
    selectedTimeValue: '',
    showModal: false,
    teamMemberEmails: [],
    previewImageUrl: null,
    previewImageLoading: true,
    dateRangeError: false,
    dateRangeRestrictionError: false,
  };

  async componentDidMount() {
    const shippingLinesUnsorted = await getWithTokenRefresh(
      '/core/api/v1/erl/shipping_line'
    );
    const shippingLines = sortOnName(shippingLinesUnsorted);
    const containerTypes = await getWithTokenRefresh(
      '/core/api/v1/erl/container_type'
    );
    const teamMembers = await userClient.getTeamMembers();
    const teamMemberEmails = teamMembers.map(row => row.email);

    this.setState({
      containerTypes,
      teamMemberEmails,
      shippingLines,
      loading: false,
      initialDataLoaded: true,
      ...getDefaultSelections(shippingLines, containerTypes),
    });
    this.loadPreviewUrl();
  }

  loadPreviewUrl = async () => {
    const {
      selectedShippingLineValue,
      selectedContainerTypeValue,
      selectedStartDateValue,
      selectedTimeValue,
    } = this.state;
    try {
      const { ea_table: previewImageUrl } = await getDailyReportPreviewEnd({
        date: moment(selectedStartDateValue).format(BACKEND_DATE_FORMAT),
        time: selectedTimeValue,
        shipping_line: selectedShippingLineValue,
        container_size: selectedContainerTypeValue,
      });
      this.setState({
        previewImageLoading: false,
        previewImageUrl,
      });
    } catch (error) {
      this.setState({
        error,
        previewImageLoading: false,
        previewImageUrl: null,
      });
    }
  };

  handleRadioButtonChange = async ({
    target: { value: selectedRadioButton },
  }) => {
    this.setState({ dateRangeError: false, dateRangeRestrictionError: false });
    if (selectedRadioButton === 'specific-date') {
      await this.setState({ selectedRadioButton, selectedEndDateValue: '' });
      this.loadPreviewUrl();
      SegmentService.trackEvent(
        'Specific Date Selected',
        {
          url: '/perdiem-archive',
        },
        { integrations: { Slack: false } }
      );
      return;
    }
    this.setState({ selectedRadioButton });
    SegmentService.trackEvent(
      'Date Range Selected',
      {
        url: '/perdiem-archive',
      },
      { integrations: { Slack: false } }
    );
  };

  handleSelectionChange = async (key, value) => {
    await this.setState({
      [`selected${key}Value`]: value,
      previewImageLoading: true,
      previewImageUrl: null,
      error: null,
      dateRangeError: false,
      dateRangeRestrictionError: false,
    });

    SegmentService.trackEvent(
      'Per Diem Report Searched',
      {
        radioButton: this.state.selectedRadioButton,
        shippingLine: this.state.selectedShippingLineValue,
        containerType: this.state.selectedContainerTypeValue,
        startDate: this.state.selectedStartDateValue.format(),
        endDate:
          this.state.selectedRadioButton === 'specific-date'
            ? ''
            : this.state.selectedEndDateValue.format(),
        time:
          this.state.selectedRadioButton === 'specific-date'
            ? this.state.selectedTimeValue
            : '',
        numberOfDays:
          this.state.selectedRadioButton === 'specific-date'
            ? ''
            : getDayDiff({
                selectedStartDateValue: this.state.selectedStartDateValue,
                selectedEndDateValue: this.state.selectedEndDateValue,
              }),
      },
      { integrations: { Slack: false } }
    );

    if (this.state.selectedRadioButton === 'specific-date') {
      this.loadPreviewUrl();
      return;
    }

    if (
      this.state.selectedStartDateValue.isAfter(this.state.selectedEndDateValue)
    ) {
      this.setState({ dateRangeError: true });
    }

    if (
      getDateRangeRestrictionError({
        selectedStartDateValue: this.state.selectedStartDateValue,
        selectedEndDateValue: this.state.selectedEndDateValue,
      })
    ) {
      this.setState({ dateRangeRestrictionError: true });
    }
  };

  render() {
    const {
      initialDataLoaded,
      containerTypes,
      teamMemberEmails,
      shippingLines,
      error,
      showModal,
      previewImageUrl,
      previewImageLoading,
      dateRangeError,
      selectedRadioButton,
      selectedShippingLineValue,
      selectedContainerTypeValue,
      selectedStartDateValue,
      selectedEndDateValue,
      selectedTimeValue,
      dateRangeRestrictionError,
    } = this.state;

    const { currentUser } = this.props;

    const selectedShippingLine = shippingLines.find(
      line => line.key === selectedShippingLineValue
    );

    const selectedContainerType = containerTypes.find(
      type => type.key === selectedContainerTypeValue
    );
    const shippingLineContainerTypes =
      selectedShippingLine && selectedContainerType
        ? [[selectedShippingLine, selectedContainerType]]
        : [];

    return (
      <ThemeProvider theme={themeProvider}>
        <div className={styles.font}>
          <PerDiemDownloadModal
            isOpen={showModal}
            currentUser={currentUser}
            emails={teamMemberEmails}
            handleOnClose={() => this.setState({ showModal: false })}
            shippingLineContainerTypes={shippingLineContainerTypes}
            startDate={selectedStartDateValue}
            time={selectedTimeValue}
            endDate={
              selectedRadioButton === 'date-range' && selectedEndDateValue
            }
            selectedRange={selectedRadioButton}
            modalType="perDiemArchive"
          />
          {/* page content */}
          <PageTitle title="Per Diem Reports - Historical empty return availability" />

          <Container>
            {!initialDataLoaded ? (
              <div className="h-screen w-full bg-white flex justify-center py-12">
                <LoadingSpinner />
              </div>
            ) : (
              <div>
                <div className="mb-6">
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="date-selection"
                      defaultValue="specific-date"
                      name="radio-buttons-group"
                      value={selectedRadioButton}
                      onChange={this.handleRadioButtonChange}
                    >
                      <FormControlLabel
                        className="font-normal pr-6"
                        value="specific-date"
                        control={
                          <Radio
                            sx={{
                              color: brandColors.colors.gray[200],
                              '&.Mui-checked': {
                                color: brandColors.colors.blue.DEFAULT,
                              },
                            }}
                          />
                        }
                        label={
                          <h4
                            className={`font-medium ${
                              selectedRadioButton === 'specific-date'
                                ? 'text-dark'
                                : 'text-gray-200'
                            }`}
                          >
                            Specific Date
                          </h4>
                        }
                      />
                      <FormControlLabel
                        value="date-range"
                        control={
                          <Radio
                            sx={{
                              color: brandColors.colors.gray[200],
                              '&.Mui-checked': {
                                color: brandColors.colors.blue.DEFAULT,
                              },
                            }}
                          />
                        }
                        label={
                          <h4
                            className={`font-medium ${
                              selectedRadioButton === 'date-range'
                                ? 'text-dark'
                                : 'text-gray-200'
                            }`}
                          >
                            Date Range&nbsp;
                            <span className="font-normal">
                              (time fixed at 08:00 AM PST by default)
                            </span>
                          </h4>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="flex flex-col space-y-8 mb-10">
                  <div className="flex space-x-8">
                    {/* Shipping line dropdown */}
                    <div className="flex flex-col space-y-2">
                      <div className="flex space-x-2 items-center">
                        <VesselIcon className="w-4 h-4" />
                        <h4 className="text-dark font-medium">Shipping Line</h4>
                      </div>
                      <FormControl sx={{ minWidth: 350 }}>
                        <Select
                          MenuProps={{ sx: { maxHeight: 400 } }}
                          onChange={event => {
                            this.handleSelectionChange(
                              'ShippingLine',
                              event.target.value
                            );
                          }}
                          value={selectedShippingLineValue}
                        >
                          {shippingLines.map(listRow => {
                            return (
                              <MenuItem key={listRow.key} value={listRow.key}>
                                {listRow.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    {/* container type dropdown */}
                    <div className="flex flex-col space-y-2">
                      <div className="flex space-x-2 items-center">
                        <ContainerIcon className="w-4 h-4" />
                        <h4 className="text-dark font-medium">
                          Container Type
                        </h4>
                      </div>
                      <FormControl sx={{ minWidth: 350 }}>
                        <Select
                          onChange={event => {
                            this.handleSelectionChange(
                              'ContainerType',
                              event.target.value
                            );
                          }}
                          defaultValue=""
                          value={selectedContainerTypeValue}
                        >
                          {containerTypes.map(listRow => {
                            return (
                              <MenuItem key={listRow.key} value={listRow.key}>
                                {listRow.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {/* Time and date dropdown */}
                  <div className={`flex items-end justify-between `}>
                    <div className="flex space-x-8 items-start justify-between">
                      <div className="flex flex-col space-y-2">
                        <h4 className="text-dark font-medium">
                          {selectedRadioButton === 'specific-date' && 'Date'}
                          {selectedRadioButton === 'date-range' && 'Start Date'}
                        </h4>
                        {/* Start date picker */}
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopDatePicker
                            inputFormat="MM/DD/yyyy"
                            error={error}
                            maxDate={moment()}
                            value={selectedStartDateValue}
                            onChange={value => {
                              this.handleSelectionChange('StartDate', value);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                inputProps={{
                                  style: { fontSize: 16 },
                                  ...params.inputProps,
                                }}
                                sx={{ minWidth: 350 }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      {this.state.selectedRadioButton === 'specific-date' && (
                        <div className="flex flex-col space-y-2">
                          <h4 className="text-dark font-medium">Time</h4>
                          {/* Time dropdown */}
                          <FormControl sx={{ minWidth: 350 }}>
                            <Select
                              onChange={event => {
                                this.handleSelectionChange(
                                  'Time',
                                  event.target.value
                                );
                              }}
                              value={selectedTimeValue}
                            >
                              {timeList.map(listRow => {
                                return (
                                  <MenuItem
                                    key={listRow.key}
                                    value={listRow.key}
                                  >
                                    {listRow.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                      {this.state.selectedRadioButton === 'date-range' && (
                        <div className="flex flex-col space-y-2">
                          <h4 className="text-dark font-medium">End Date</h4>
                          {/* End date picker */}
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                              inputFormat="MM/DD/yyyy"
                              error={dateRangeError}
                              maxDate={moment()}
                              value={selectedEndDateValue}
                              onChange={value => {
                                this.handleSelectionChange('EndDate', value);
                              }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    style: { fontSize: 16 },
                                    ...params.inputProps,
                                  }}
                                  sx={{
                                    minWidth: 350,
                                    '& input::placeholder': {
                                      fontSize: '16px',
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>

                          {dateRangeError && (
                            <Alert severity="warning">
                              Start date must be before end date
                            </Alert>
                          )}
                          {dateRangeRestrictionError && (
                            <Alert severity="warning">
                              Time range cannot be greater than{' '}
                              {PER_DIEM_RANGE_LIMIT_DAYS} days
                            </Alert>
                          )}
                        </div>
                      )}
                    </div>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={dateRangeError || dateRangeRestrictionError}
                      label="Finalise your report"
                      onClick={() => {
                        this.setState({ showModal: true });
                        SegmentService.trackEvent(
                          'Finalize Report Button Clicked',
                          {
                            radioButton: this.state.selectedRadioButton,
                            shippingLine: this.state.selectedShippingLineValue,
                            containerType: this.state
                              .selectedContainerTypeValue,
                            startDate: this.state.selectedStartDateValue.format(),
                            endDate:
                              this.state.selectedRadioButton === 'specific-date'
                                ? ''
                                : this.state.selectedEndDateValue.format(),
                            time:
                              this.state.selectedRadioButton === 'specific-date'
                                ? this.state.selectedTimeValue
                                : '',
                            numberOfDays:
                              this.state.selectedRadioButton === 'specific-date'
                                ? ''
                                : getDayDiff({
                                    selectedStartDateValue: this.state
                                      .selectedStartDateValue,
                                    selectedEndDateValue: this.state
                                      .selectedEndDateValue,
                                  }),
                          },
                          { integrations: { Slack: false } }
                        );
                      }}
                      sx={{
                        minHeight: 58,
                        minWidth: 300,
                        fontSize: 16,
                        letterSpacing: 0,
                      }}
                      className={`${(dateRangeError ||
                        dateRangeRestrictionError) &&
                        'relative bottom-14'}`}
                    >
                      Finalize your report
                    </Button>
                  </div>
                </div>

                {this.state.selectedRadioButton === 'specific-date' && (
                  <div className="w-full flex flex-col space-y-2 mb-36">
                    <h4 className="text-dark font-medium">
                      Empty Dispatch Table Preview
                    </h4>
                    <div
                      className={`w-full h-full ${styleImage['image-size']} bg-white shadow-md`}
                    >
                      {error ? (
                        <div>
                          <Alert severity="warning">
                            Preview currently unavailable, please download full
                            report.
                          </Alert>
                        </div>
                      ) : previewImageLoading ? (
                        <div className="h-full w-full flex justify-center py-12">
                          <LoadingSpinner />
                        </div>
                      ) : previewImageUrl ? (
                        <img
                          src={previewImageUrl}
                          alt="Empty Dispatch Table Preview"
                        />
                      ) : (
                        <div className="h-full w-full py-12 px-4 text-center">
                          <h3 className="font-medium text-gray-400">
                            No preview available for your search. Please make
                            another selection.
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

function getDayDiff({ selectedStartDateValue, selectedEndDateValue }) {
  if (!selectedStartDateValue || !selectedEndDateValue) return;

  return selectedEndDateValue.diff(selectedStartDateValue, 'days');
}

function getDateRangeRestrictionError({
  selectedStartDateValue,
  selectedEndDateValue,
}) {
  if (!selectedStartDateValue || !selectedEndDateValue) return;

  return (
    getDayDiff({ selectedStartDateValue, selectedEndDateValue }) >=
    PER_DIEM_RANGE_LIMIT_DAYS
  );
}

export default PerDiemArchivePage;
