import CellStatus from './CellStatus';
import styles from './CellStatus.style';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCurrentGateHour } from '../../../../ducks/availabilities';

const mapStateToProps = (state, ownProps) => ({
  mappingShippingLine: state.availabilities.mappingShippingLine,
  gateHours: getCurrentGateHour(state, ownProps.terminal.key),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(CellStatus);
