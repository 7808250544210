import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { ReactComponent as VesselIcon } from 'assets/andora-icons/vessel.svg';
import { ReactComponent as DischargedIcon } from 'assets/andora-icons/discharged.svg';
import { ReactComponent as GatedOutIcon } from 'assets/andora-icons/gated-out.svg';
import { ReactComponent as GatedInIcon } from 'assets/andora-icons/gated-in.svg';
import { brandColors } from 'brandColors';
import { FormattedDateTime } from '../components/FormattedDate';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90deg, hsla(36, 100%, 77%, 1) 0%, hsla(36, 100%, 47%, 1) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90deg, hsla(36, 100%, 77%, 1) 0%, hsla(36, 100%, 47%, 1) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: brandColors.colors.orange[500],
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: brandColors.colors.orange[600],
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fill: brandColors.colors.white,

  ...(ownerState.active && {
    background: brandColors.colors.orange.DEFAULT,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    fill: brandColors.colors.white,
  }),
  ...(ownerState.completed && {
    background: brandColors.colors.orange[600],
    fill: brandColors.colors.orange.DEFAULT,
    border: `1px solid ${brandColors.colors.orange.DEFAULT}`,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <VesselIcon />,
    2: <DischargedIcon />,
    3: <GatedOutIcon />,
    4: <GatedInIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const setStep = status => {
  switch (status) {
    case 'vessel':
      return 0;
    case 'import':
      return 1;
    case 'gated_out':
      return 2;
    case 'terminated':
      return 3;
    default:
      return;
  }
};

export default function StepperComponent({
  status,
  pickupVesselName,
  gatedOutTerminalName,
  gatedOutDate,
  gatedInDate,
  gatedInTerminalName,
}) {
  const activeStep = setStep(status);

  const steps = [
    {
      step: 'Vessel',
      vessel: pickupVesselName,
    },
    {
      step: 'Discharged',
      terminal: gatedOutTerminalName,
    },
    {
      step: 'Gated Out',
      date: gatedOutDate,
    },
    {
      step: 'Gated In',
      date: gatedInDate,
      terminal: gatedInTerminalName,
    },
  ];

  return (
    <div className="w-full">
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map(step => (
          <Step key={step.step}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              <div className="flex flex-col space-y-1 items-start">
                <h6 className="uppercase text-gray-200">{step.step}</h6>
                <div className="flex space-x-1">
                  {step.terminal && <h4>{step.terminal}</h4>}
                  {step.vessel && <h4>{step.vessel}</h4>}
                  {step.date && (
                    <h4 className="text-dark">
                      <FormattedDateTime FormattedDateTime={step.date} />
                    </h4>
                  )}
                </div>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
