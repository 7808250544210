export const pinMuiDataGridHeaders = (setter, isFixedHeaders) => {
  const dataGridElem = window.document.getElementsByClassName(
    'MuiDataGrid-main'
  )[0];
  if (!dataGridElem) {
    return;
  }
  const elemRect = dataGridElem.getBoundingClientRect();
  if (elemRect.top <= 64) {
    setter(true);
  } else if (elemRect.top > 64 && isFixedHeaders) {
    setter(false);
  }
};
