import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getGateSchedule, setClearGateSchedule } from 'ducks/gateSchedule';
import GateSchedule from './GateSchedule';
import styles from './GateSchedule.style';
import './styles/GateSchedule.css';

const mapStateToProps = (state, ownProps) => {
  const { user, loader, refreshToken, gateSchedule } = state;
  const { isGateScheduleLoading } = loader;
  return {
    user,
    refreshToken,
    gateSchedule,
    isGateScheduleLoading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getGateSchedule: () => {
    dispatch(getGateSchedule());
  },

  clearMyGateSchedule: () => {
    dispatch(setClearGateSchedule());
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(GateSchedule);
