import React, { Component } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { ReactComponent as DispatcherShopIcon } from '../../../src/assets/icons/dispatcher-shop.svg';
import { ReactComponent as PerDiemFighterIcon } from '../../../src/assets/icons/per-diem-fighter.svg';
import { ReactComponent as BcEnterprise } from '../../../src/assets/icons/bc-enterprise.svg';

class PricingPresentation extends Component {
  render() {
    const {
      starterButtonProps = {},
      showPlanButtons = true,
      showFreeTrialButtons = false,
      isAdmin,
    } = this.props;
    return (
      <div className="w-full bg-white pt-24 pb-10">
        <div className="w-11/12 m-auto">
          <div className="grid grid-cols-1 gap-10 lg:grid-cols-3 lg:gap-4">
            <PriceCard
              icon={<DispatcherShopIcon />}
              title="Track & Dispatch"
              helperText="Save 2+ hours a day per dispatcher and move cargo faster"
              priceText="Starts at $529 / month"
              buttonText="Try 15 days free"
              buttonHelperText="Contact your company account admin to change plan"
              lists={[
                {
                  listTitle:
                    'Real time data & alerts include the following events:',
                  bullets: [
                    { text: 'Pickup and return available appointments' },
                    { text: 'Available for pick up' },
                    { text: 'Holds and last free day' },
                    { text: 'Termination event' },
                    {
                      text:
                        'Gate, vessel and empty return locations schedules ',
                    },
                  ],
                },
                {
                  listTitle: 'Starts with 150 containers included',
                },
              ]}
            />
            <PriceCard
              icon={<PerDiemFighterIcon />}
              showAsMostPopular
              title="Per Diem & Demurrage Fighter"
              helperText="Save hundreds of thousands a year preventing and stopping late fees"
              priceText="Starts at $1,679 / month"
              buttonText="Try 15 days free"
              lists={[
                {
                  listTitle:
                    'All features included in the Track & Dispatch package, plus: ',
                },
                {
                  listTitle: 'Historical data against late fees:',
                  bullets: [
                    { text: 'Available for pick up status change' },
                    {
                      text:
                        'Pick up and return appointment availability changes',
                    },
                    {
                      text: 'Yard closed areas',
                    },
                    {
                      text: '2 years of Empty Return Locations screenshots',
                    },
                    {
                      text: '2 years of available appointments screenshots',
                    },
                  ],
                },
                {
                  listTitle: 'Starts with 250 containers included',
                },
              ]}
            />
            <PriceCard
              icon={<BcEnterprise />}
              title="BC Connect™"
              helperText="Save hours and money from asynchronous manual communication"
              priceText="Volume based pricing"
              lists={[
                {
                  listTitle:
                    'All features included in the Per diem & Demurrage Fighter, plus:',
                },
                {
                  listTitle: 'BlueCargo Connect include:',
                  bullets: [
                    { text: 'Appointments booked' },
                    { text: 'Termination event across all terminals' },
                    {
                      text:
                        'Ability to communicate with your logistics partners',
                    },
                    {
                      text: 'Unlimited number of containers tracked',
                    },
                    {
                      text: 'Priority customer support',
                    },
                  ],
                },
              ]}
            />
          </div>
          {showPlanButtons && (
            <div className="mt-20 flex flex-col space-y-4 items-center pb-16">
              <h3 className="font-medium">
                Or choose the limited free owner operators plan to access the{' '}
                <span className="font-bold text-blue underline">
                  <Link to="/terminals" target="_blank">
                    Empty Receiving Schedules,
                  </Link>
                </span>{' '}
                <span className="font-bold text-blue underline">
                  <Link to="/gate-schedule" target="_blank">
                    Gate Schedules
                  </Link>
                </span>{' '}
                and{' '}
                <span className="font-bold text-blue underline">
                  <Link to="/vessel-schedule" target="_blank">
                    Vessel Schedules
                  </Link>
                </span>
              </h3>
              <button
                component={Link}
                to="/signup"
                className={`bg-blue-light border border-blue py-2 px-20 text-lg font-bold text-blue hover:bg-blue hover:text-white ${
                  starterButtonProps.disabled
                    ? 'opacity-30 cursor-not-allowed'
                    : ''
                }`}
                {...starterButtonProps}
              >
                {starterButtonProps.children}
              </button>
              {!isAdmin && (
                <h5>Contact your company account admin to change plan</h5>
              )}
            </div>
          )}
          {showFreeTrialButtons && (
            <div className="mt-20 flex flex-col space-y-4 items-center">
              <h3 className="font-medium">
                Limited free plan for owner operators available after free trial
                (verified SCAC code required)
              </h3>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function PriceCard({
  icon,
  showAsMostPopular,
  title,
  helperText,
  priceText,
  priceTextYearly,
  buttonText,
  buttonHelperText,
  buttonProps = {},
  lists,
  showPlanButtons,
  showFreeTrialButtons,
  isAdmin,
}) {
  return (
    <div>
      <div
        className={`relative bg-white flex flex-col items-start py-12 px-4 ${
          showAsMostPopular
            ? 'border-2 border-orange shadow-md absolute bottom-4'
            : 'border border-blue-400'
        }`}
      >
        <div className="flex flex-col items-center">
          {showAsMostPopular && (
            <div className="absolute flex w-6/12 m-auto justify-center -top-4 py-2 bg-orange">
              <h4 className="font-medium text-center uppercase tracking-wide text-orange-light">
                Most Popular Choice
              </h4>
            </div>
          )}
          <div className="h-90">
            <div className="h-60 flex flex-col items-center space-y-4">
              {icon}
              <h2 className="font-bold capitalize tracking-wider">{title}</h2>
              <p className="text-lg font-regular text-dark text-center">
                {helperText}
              </p>
            </div>
            <div className="w-full flex flex-col space-y-4">
              <Link to="/signup">
                <button className="bg-orange-light border border-orange text-orange w-full px-20 py-2 text-lg font-bold hover:bg-orange hover:text-white">
                  Try 15 Days Free
                </button>
              </Link>
              <div className="text-center pb-4">
                <h4 className="font-bold tracking-wide">{priceText}</h4>
              </div>
            </div>

            {title === 'BC Enterprise' && (
              <div className="w-full my-4 mb-2">
                <a
                  href="https://calendly.com/bluecargo/sales?month=2021-09"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className={` w-full bg-blue py-2 px-20 text-lg font-bold text-white hover:bg-blue hover:text-white`}
                  >
                    Schedule your call
                  </button>
                </a>
              </div>
            )}
          </div>
          <hr className="h-1 w-full border-blue-500 mb-4 mt-2 justify-end" />
        </div>
        {lists.map(({ listTitle, listTitleTag, bullets }, index) => (
          <div key={listTitle} className="flex flex-col space-y-2">
            <div className={`pb-2 flex ${index !== 0 ? 'pt-8' : ''}`}>
              {listTitleTag && (
                <span className="uppercase px-2 bg-orange rounded-sm mr-2 text-white font-bold">
                  <h5>{listTitleTag}</h5>
                </span>
              )}

              <h3 className="font-medium text-left">{listTitle}</h3>
            </div>
            {bullets &&
              bullets.map(({ text, unavailable }) => (
                <div
                  key={text}
                  className={`flex space-x-2 items-center ${
                    unavailable ? 'line-through' : ''
                  }`}
                >
                  {unavailable ? (
                    <CloseIcon
                      style={{
                        width: '18px',
                        height: '18px',
                      }}
                      className="text-red"
                    />
                  ) : (
                    <CheckIcon
                      style={{
                        width: '18px',
                        height: '18px',
                      }}
                      className="text-blue"
                    />
                  )}
                  <h4 className="capitalize font-regular">{text}</h4>
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PricingPresentation;
