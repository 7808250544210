import React from 'react';
import ReactGA from 'react-ga';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import SendIcon from '@mui/icons-material/Send';
import typography from '../styles/typography.module.css';
import { globalTheme } from '../globalTheme';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import { parseContainerNumbers } from '../services/helpers/containers';
import { generatePerDiemReport } from '../services/clients/backupBank';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { SegmentService } from 'services/helpers/segment';
import { tagVariantMapping } from '../pages/ContainerTracking/components/ContainerStatusTag';
import _ from 'lodash';
import { NAV_HEIGHT } from 'layouts/AppPageTemplate/utils/config';
import { brandColors } from 'brandColors';

const DISPLAY_DATE_FORMAT = 'll';
const POST_FORMAT = 'YYYY-MM-DD';

const style = {
  position: 'absolute',
  top: `${NAV_HEIGHT + 10}px`,
  left: '50%',
  transform: 'translate(-50%, -0%)',
  width: 960,
  // minHeight: 664,
  bgcolor: globalTheme.colors.white,
  boxShadow: 24,
};

function getInitialState() {
  return {
    selectedEmails: [],
    containersInputText: '',
    selectedRadioButton: 'all',
    containerNumbers: [],
    downloadChecked: true,
    loading: false,
    error: null,
    complete: false,
  };
}

class PerDiemDownloadModal extends React.Component {
  state = { ...getInitialState() };

  onContainersInputChange = ({
    target: { value: containersInputText = '' },
  }) => {
    const containerNumbers = parseContainerNumbers(containersInputText);
    this.setState({ containersInputText, containerNumbers });
  };

  handleRadioButtonChange = event => {
    this.setState({ selectedRadioButton: event.target.value });
  };

  toggleEmail = email => {
    const selectedEmails = this.state.selectedEmails.includes(email)
      ? this.state.selectedEmails.filter(
          selectedUserEmail => selectedUserEmail !== email
        )
      : this.state.selectedEmails.concat([email]);
    this.setState({ selectedEmails });
  };

  handleOnSubmit = async () => {
    this.setState({ loading: true });
    const {
      currentUser,
      shippingLineContainerTypes,
      startDate: propsStartDate,
      endDate: propsEndDate,
      time,
      selectedRange: propsSelectedRange,
      modalType = 'perDiemArchive',
      containers,
      onSubmit = () => {},
    } = this.props;
    const { containerNumbers = [] } = this.state;

    const { selectedEmails, selectedRadioButton, downloadChecked } = this.state;

    let startDate = propsStartDate ? propsStartDate : undefined;
    let endDate = propsEndDate ? propsEndDate : undefined;
    let shippingLineKey = shippingLineContainerTypes
      ? shippingLineContainerTypes[0][0].key
      : undefined;
    let containerTypeKey = shippingLineContainerTypes
      ? shippingLineContainerTypes[0][1].key
      : undefined;
    const selectedRange = propsSelectedRange ? propsSelectedRange : undefined;

    if (modalType === 'containerTracking') {
      for (const container of containers) {
        const {
          containerTypeKey: containerType,
          gatedInDate,
          gatedOutDate,
          containerNumber,
          shippingLineKey: shippingLine,
        } = container;
        const containerStartDate = gatedOutDate;
        const containerEndDate = gatedInDate || moment();

        if (_.isNil(startDate) || startDate > containerStartDate) {
          startDate = containerStartDate;
        }
        if (_.isNil(endDate) || endDate < containerEndDate) {
          endDate = containerEndDate;
        }
        shippingLineKey = shippingLine;
        containerTypeKey = containerType;
        containerNumbers.push(containerNumber);
      }
    }

    try {
      triggerAnalytics(
        currentUser,
        shippingLineKey,
        containerTypeKey,
        startDate,
        endDate,
        selectedRange,
        time,
        selectedEmails,
        selectedRadioButton,
        containerNumbers,
        downloadChecked
      );
      await generatePerDiemReport({
        emails: [currentUser.email].concat(selectedEmails),
        include_terminal_screenshots: selectedRadioButton === 'all',
        container_numbers: containerNumbers,
        download: downloadChecked,
        shipping_line: shippingLineKey,
        container_type: containerTypeKey,
        start_date: startDate.format(POST_FORMAT),
        end_date: endDate ? endDate.format(POST_FORMAT) : null,
        time,
      });
      this.setState({ loading: false, complete: true });
      onSubmit(containerNumbers);
    } catch (error) {
      console.log(error);
      this.setState({ error, loading: false });
    }
  };

  handleOnClose = () => {
    this.setState({ ...getInitialState() });
    this.props.handleOnClose();
  };

  render() {
    const {
      emails,
      shippingLineContainerTypes = [],
      startDate,
      endDate,
      isOpen = false,
      currentUser,
      modalType = 'perDiemArchive',
      containers = [],
    } = this.props;

    const {
      containersInputText,
      containerNumbers,
      selectedEmails,
      downloadChecked,
      loading,
      error,
      complete,
    } = this.state;

    const selectedEmailsFilter = currentUser
      ? [currentUser.email].concat(selectedEmails)
      : selectedEmails;

    const sortedEmails = currentUser
      ? [currentUser.email]
          .concat(emails.sort().filter(email => email !== currentUser.email))
          .filter(x => x)
      : emails;
    const allowDownload = shippingLineContainerTypes.length === 1 && !endDate;

    const dayRange = endDate
      ? Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24))
      : 0;

    return (
      <Modal
        open={isOpen}
        onClose={this.handleOnClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: 'scroll' }}
      >
        <Box sx={style} className="relative">
          <div className={`${typography.font}`}>
            <div className="relative flex items-center justify-center py-4 border-b border-blue-500">
              <p className="text-dark font-medium self-center">
                Finalise Your Per Diem Report
              </p>
              <div
                className="right-4 absolute cursor-pointer"
                onClick={this.handleOnClose}
              >
                <CloseIcon />
              </div>
            </div>
            {error ? (
              <div className="px-4">
                {error.message && error.message.includes('402') ? (
                  <Alert
                    severity="info"
                    sx={{
                      '.MuiAlert-icon': {
                        color: brandColors.colors.blue.DEFAULT,
                      },
                    }}
                  >
                    Credit limit reached, please contact support
                  </Alert>
                ) : (
                  <Alert severity="warning">{error.message}</Alert>
                )}
              </div>
            ) : complete ? (
              <div className="my-40 right-0 w-full flex items-center justify-center">
                <div className="flex flex-col items-center space-y-10">
                  <span
                    role="img"
                    aria-label="emoji-party"
                    className="text-7xl"
                  >
                    📧
                  </span>
                  <div className="flex flex-col space-y-2 text-center">
                    <h1 className="font-medium text-dark">
                      Your Per Diem Report is on the way!
                    </h1>
                    <h3 className="font-regular text-dark">
                      Please allow a <strong>few minutes</strong> for the report
                      to reach your email.
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              <form
                onSubmit={this.handleOnSubmit}
                className="select-none px-16 py-8"
              >
                {/* Your selection */}
                <div className="mb-8">
                  <p className="text-dark font-medium mb-3">
                    1. Your selection
                  </p>
                  <div className="flex space-x-2 items-center">
                    {modalType === 'perDiemArchive' &&
                      shippingLineContainerTypes.map(lineType => {
                        const [shippingLine, containerType] = lineType;
                        return (
                          <React.Fragment key={lineType}>
                            <span
                              key={`${shippingLine.key}${containerType.key}`}
                              className="flex space-x-2 items-center"
                            >
                              <Button variant="tag-primary">
                                {shippingLine.name}
                              </Button>
                              <Button variant="tag-primary">
                                {containerType.name}
                              </Button>
                            </span>
                            <h4 className="text-blue font-medium">
                              {endDate ? (
                                <>
                                  <span className="text-dark">from</span>{' '}
                                  {startDate.format(DISPLAY_DATE_FORMAT)} to{' '}
                                  {endDate.format(DISPLAY_DATE_FORMAT)}{' '}
                                  {dayRange !== 0 &&
                                    `(${dayRange}
                                days)`}
                                </>
                              ) : (
                                <>
                                  <span className="font-regular">on </span>{' '}
                                  <span className="font-bold text-blue">
                                    {startDate
                                      ? startDate.format(DISPLAY_DATE_FORMAT)
                                      : ''}
                                  </span>
                                </>
                              )}
                            </h4>
                          </React.Fragment>
                        );
                      })}
                    {modalType === 'containerTracking' &&
                      containers.map(container => {
                        const {
                          containerNumber,
                          days,
                          gatedInDate,
                          shippingLine,
                          size,
                          gatedOutDate,
                          status,
                        } = container;
                        const startDate = gatedOutDate;
                        const endDate = gatedInDate || moment();
                        const { variant, statusText } = tagVariantMapping(
                          status
                        );
                        return (
                          <React.Fragment key={container.containerNumber}>
                            <Button variant={variant}>{statusText}</Button>
                            <Button variant="tag-primary">
                              {shippingLine}
                            </Button>
                            <Button variant="tag-primary">{size}</Button>
                            <Button variant="tag-primary">
                              {containerNumber}
                            </Button>
                            <span className="text-dark">
                              from{' '}
                              <span className="text-blue">
                                {startDate.format(DISPLAY_DATE_FORMAT)}
                              </span>{' '}
                              to{' '}
                              <span className="text-blue">
                                {endDate.format(DISPLAY_DATE_FORMAT)}{' '}
                                {`(${days} days)`}
                              </span>
                            </span>
                            <span></span>
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
                {/* Container in Per Diem */}
                {modalType === 'perDiemArchive' && (
                  <div className="mb-8">
                    <p className="text-dark font-medium mb-3">
                      2. What are your containers on per diem?{' '}
                      <span className="font-light">(optional)</span>
                    </p>
                    <textarea
                      rows={1}
                      tabIndex="1"
                      className={`w-full px-4 py-2 bg-blue-600 border border-blue-400 rounded-sm resize-none`}
                      placeholder={
                        'Enter a container number, or a list of container numbers to include then in your Automated Per Diem Report.'
                      }
                      value={containersInputText}
                      onChange={this.onContainersInputChange}
                      maxLength={634}
                    />
                    {containersInputText && (
                      <div className="font-light">
                        Recognized container numbers:{' '}
                        {containerNumbers.join(', ')}
                      </div>
                    )}
                  </div>
                )}

                {/* What kind of report */}
                <div className="mb-8">
                  <p className="text-dark font-medium mb-3">
                    {modalType === 'perDiemArchive' ? '3.' : '2.'} What kind of
                    proofs do you need to include in your report?
                  </p>
                  <div className="flex space-x-4">
                    <div className="flex items-center relative -left-4">
                      <Radio
                        checked={this.state.selectedRadioButton === 'all'}
                        onChange={this.handleRadioButtonChange}
                        value="all"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'all' }}
                        sx={{
                          color: globalTheme.colors.gray[200],
                          '&.Mui-checked': {
                            color: globalTheme.colors.blue.DEFAULT,
                          },
                        }}
                      />
                      <h4
                        className={`font-regular ${
                          this.state.selectedRadioButton === 'all'
                            ? 'text-dark'
                            : 'text-gray-200'
                        }`}
                      >
                        Preview of return locations + Terminal Screenshots{' '}
                      </h4>
                    </div>
                    <div className="flex items-center">
                      <Radio
                        checked={
                          this.state.selectedRadioButton === 'only-screenshots'
                        }
                        onChange={this.handleRadioButtonChange}
                        value="only-screenshots"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'Only Screenshots' }}
                        sx={{
                          color: globalTheme.colors.gray[200],
                          '&.Mui-checked': {
                            color: globalTheme.colors.blue.DEFAULT,
                          },
                        }}
                      />
                      <h4
                        className={`font-regular ${
                          this.state.selectedRadioButton === 'only-screenshots'
                            ? 'text-dark'
                            : 'text-gray-200'
                        }`}
                      >
                        Preview of return locations only
                      </h4>
                    </div>
                  </div>
                </div>
                {/* Share with team */}
                <div className="mb-8">
                  <p className="text-dark font-medium mb-3">
                    {modalType === 'perDiemArchive' ? '4.' : '3.'} Do you want
                    to share the report with a teammate?{' '}
                    <span className="font-light">
                      (click on email to select)
                    </span>
                  </p>
                  <div className="max-h-28 overflow-scroll flex flex-wrap">
                    {sortedEmails.map(email => {
                      return (
                        <div key={email} className="mr-2 mb-2">
                          <Button
                            variant={
                              selectedEmailsFilter.includes(email)
                                ? 'tag-email-selected'
                                : 'tag-email-default'
                            }
                            onClick={() => this.toggleEmail(email)}
                          >
                            {currentUser && email === currentUser.email
                              ? `${currentUser.email} (me)`
                              : email}
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* Download */}
                {modalType === 'perDiemArchive' && (
                  <div className="flex space-x-4">
                    <div className="flex items-center relative -left-4">
                      <Checkbox
                        disabled={!allowDownload}
                        onChange={() =>
                          this.setState({
                            downloadChecked: !downloadChecked,
                          })
                        }
                        checked={downloadChecked}
                        sx={{
                          color: globalTheme.colors.gray[200],
                          '&.Mui-checked': {
                            color: globalTheme.colors.blue.DEFAULT,
                          },
                          '&.Mui-disabled': {
                            color: globalTheme.colors.gray[500],
                          },
                        }}
                      />
                      <h4
                        className={`font-regular ${
                          allowDownload ? 'text-dark' : 'text-gray-300'
                        }`}
                      >
                        Download report (only available for specific date)
                      </h4>
                    </div>
                  </div>
                )}
              </form>
            )}
          </div>
          <div className="w-full border-t border-blue-500 p-4">
            <div className="px-2 flex items-center justify-end">
              {complete ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.handleOnClose}
                >
                  Close
                </Button>
              ) : modalType === 'perDiemArchive' ? (
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  endIcon={<KeyboardReturnIcon />}
                  disabled={error}
                  loading={loading}
                  type="submit"
                  onClick={this.handleOnSubmit}
                >
                  Generate Report
                </LoadingButton>
              ) : (
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  startIcon={<SendIcon />}
                  disabled={error}
                  loading={loading}
                  type="submit"
                  onClick={this.handleOnSubmit}
                >
                  Send Report
                </LoadingButton>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}

function triggerAnalytics(
  currentUser,
  shippingLineKey,
  containerTypeKey,
  startDate,
  endDate,
  selectedRange,
  time,
  selectedEmails,
  selectedRadioButton,
  containerNumbers,
  downloadChecked
) {
  const countOfDays = endDate ? endDate.diff(startDate, 'days') : 1;
  const timestamp = moment().format('MM/DD/YYYY hh:mm A');
  const { id, email } = currentUser;
  ReactGA.event({
    category: 'Backup Bank',
    action: 'Per Diem Report',
    label: `${id}, ${email}, ${shippingLineKey}, ${containerTypeKey}, ${startDate}, ${timestamp} ${countOfDays}`,
  });

  SegmentService.trackEvent(
    'Generate-report Button Clicked',
    {
      selectedRange: selectedRange,
      selectedEmails: selectedEmails,
      reportPreference: selectedRadioButton,
      startDate: startDate.format(),
      endDate: selectedRange === 'specific-date' ? '' : endDate.format(),
      containerTypeKey: containerTypeKey,
      shippingLineKey: shippingLineKey,
      numberOfDays: countOfDays,
      selectedTime: time,
      containerNumbers: containerNumbers,
      downloadChecked: downloadChecked,
    },
    { integrations: { Slack: false } }
  );
}

export default PerDiemDownloadModal;
