import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GateScheduleTableRowCell extends PureComponent {
  static propTypes = {
    status: PropTypes.string,
    isToday: PropTypes.bool,
  };

  render() {
    const { status, isToday } = this.props;

    return (
      <div
        className={`gate-schedule-table-body-row-cell${
          isToday ? ' today' : ''
        }`}
      >
        <div
          className={`gate-schedule-table-body-row-cell-content${
            status ? ` ${status}` : ''
          }`}
        >
          {status}
        </div>
      </div>
    );
  }
}

export default GateScheduleTableRowCell;
