import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  LARGE_TABLE_CELL_WIDTH,
  SMALL_TABLE_CELL_WIDTH,
  SHIPPING_LINE_TABLE_CELL_WIDTH,
} from '../../constants/layout';

export const getWidth = theme =>
  [...theme.breakpoints.keys].reverse().reduce((output, key) => {
    const matches = useMediaQuery(theme.breakpoints.only(key));

    return !output && matches ? key : output;
  }, null) || 'xs';

export const computeTableWidth = (displayShift, terminals) => {
  const nbTerminals = terminals.length;
  let width;
  if (displayShift)
    width =
      (nbTerminals + 1) * LARGE_TABLE_CELL_WIDTH +
      SMALL_TABLE_CELL_WIDTH +
      SHIPPING_LINE_TABLE_CELL_WIDTH;
  else
    width =
      nbTerminals * LARGE_TABLE_CELL_WIDTH +
      SMALL_TABLE_CELL_WIDTH +
      SHIPPING_LINE_TABLE_CELL_WIDTH;
  return { width, nbTerminals };
};
