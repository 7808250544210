import erlClient from './';
import moment from 'moment';

export const getGateScheduleEnd = async () => {
  const now = moment();
  const year = now.year();
  const weekNumber = now.isoWeek();
  const nextWeekNumber = now.add(1, 'w').isoWeek();
  const currentWeekRequest = erlClient.get(
    `/core/api/v1/erl/gate_status?week=${weekNumber}&year=${year}`
  );
  const nextWeekRequest = erlClient.get(
    `/core/api/v1/erl/gate_status?week=${nextWeekNumber}&year=${year}`
  );
  const res = await Promise.all([currentWeekRequest, nextWeekRequest]);
  const [currentWeek, nextWeek] = res || [];
  const { data: currentWeekData } = currentWeek || {};
  const { data: nextWeekData } = nextWeek || {};
  return { currentWeekData, nextWeekData };
};
