import { objectKeysToCamelCase } from 'utils/common';
import axiosWithToken from './axios-with-token';

export const getPacks = async () => {
  const { data } = await axiosWithToken.get('/core/api/v1/auth/packs');
  return data.map(s => objectKeysToCamelCase(s));
};

export const checkout = async (
  packId,
  volume,
  period,
  coupon,
  paymentMethodId
) => {
  try {
    const { data } = await axiosWithToken.post('/core/api/v1/auth/checkout', {
      pack_id: packId,
      volume: volume,
      period: period,
      coupon: coupon,
      payment_method_id: paymentMethodId,
    });
    return { data };
  } catch (error) {
    return { error };
  }
};

export const getInvoiceAmounts = async (
  packId,
  volume,
  period,
  coupon,
  signal
) => {
  const { data } = await axiosWithToken.post(
    '/core/api/v1/auth/upcoming-invoice',
    {
      pack_id: packId,
      volume,
      period,
      coupon,
    },
    { signal }
  );
  return objectKeysToCamelCase(data);
};

export const getCustomerPortal = async () => {
  const { data } = await axiosWithToken.get(
    '/core/api/v1/auth/customer-portal'
  );
  return objectKeysToCamelCase(data);
};

export const validateCoupon = async (packId, coupon) => {
  const { data } = await axiosWithToken.post(
    '/core/api/v1/auth/validate-coupon',
    {
      pack_id: packId,
      coupon: coupon,
    }
  );
  return objectKeysToCamelCase(data);
};
