import bugsnag from '@bugsnag/js';
import config from 'config';

const options = {
  apiKey: config.BUGSNAG_API_KEY,
  // If testing the bugsnag integration locally, add 'development' here.
  notifyReleaseStages: ['production', 'staging'],
  releaseStage: process.env.REACT_APP_ENV || 'development',
  filter: ['email', 'credit_card'],
};

// Remove bugsnag logging noise in tests https://github.com/bugsnag/bugsnag-js/pull/340
if (process.env.NODE_ENV !== 'production') {
  options.logger = null;
}

export const bugsnagClient = bugsnag(options);
