import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getCurrentGateHour,
  getFirstAppointmentByTerminal,
} from 'ducks/availabilities';
import cn from 'classnames';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { compareShift } from 'services/helpers/shift';

function FirstAppointmentCellHeader({
  classes,
  gateHours,
  firstAppointment,
  terminal,
  terminalWithAppointments,
  selectedShift,
}) {
  const terminalsWithAppointmentsList = terminalWithAppointments.map(
    d => d.port
  );
  const hour = gateHours;
  if (!terminalsWithAppointmentsList.includes(terminal.key))
    return (
      <div
        className={cn(classes.root, {
          [classes.closeRoot]: hour && !hour.is_open,
        })}
      />
    );

  const parserStatus = terminalWithAppointments.find(
    t => t.port === terminal.key
  );
  if (!parserStatus.is_working)
    return (
      <div
        className={cn(classes.root, classes.outdated, {
          [classes.closeRoot]: hour && !hour.is_open,
        })}
      />
    );
  const formatted_start_hour =
    firstAppointment &&
    moment(firstAppointment.start_time, 'HH:mm:ss').format('HH:mm');
  const formatted_date =
    firstAppointment && moment(firstAppointment.date).format('MM/DD/YYYY');

  var tooltipText;
  if (
    !firstAppointment ||
    (firstAppointment &&
      !compareShift(
        { day: firstAppointment.date, shift: firstAppointment.shift },
        selectedShift
      ))
  ) {
    tooltipText =
      'No appointments are available during this shift. Go to per diem archive to start preparing your case.';
  } else {
    tooltipText = `The earliest appointment at this terminal is ${formatted_start_hour}. ${firstAppointment.count_available} slots are available`;
  }

  return (
    <div
      className={cn(classes.root, {
        [classes.closeRoot]: hour && !hour.is_open,
      })}
    >
      <Tooltip
        title={<Typography variant="caption">{tooltipText}</Typography>}
        classes={{ tooltip: classes.tooltip, popper: classes.popper }}
      >
        <div className={classes.firstApp}>
          <Typography
            variant="caption"
            className={cn(classes.typography, {
              [cn(classes.noApp, classes.topSpace)]: !firstAppointment,
              [classes.noApp]:
                firstAppointment &&
                !compareShift(
                  { day: firstAppointment.date, shift: firstAppointment.shift },
                  selectedShift
                ),
            })}
          >
            {firstAppointment ? (
              <React.Fragment>
                <span>{`${formatted_date} \n ${formatted_start_hour} `}</span>
                <b>{`(${firstAppointment.count_available})`}</b>
              </React.Fragment>
            ) : (
              'None'
            )}
          </Typography>
        </div>
      </Tooltip>
    </div>
  );
}

const styles = theme => ({
  noApp: {
    color: theme.palette.alert.main,
  },
  topSpace: {
    paddingTop: '12px !important',
  },
  typography: {
    paddingTop: 4,
  },
  firstApp: {
    verticalAlign: 'middle',
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  closeRoot: {
    backgroundColor: `${theme.palette.alert.light} !important`,
  },
  tooltip: {
    backgroundColor: '#fff',
    borderRadius: 0,
    boxShadow: theme.shadows[2],
  },
  popper: {
    opacity: 1,
  },
  outdated: {
    background: theme.background.greyHatched,
  },
});

FirstAppointmentCellHeader.propTypes = {
  terminal: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  gateHours: PropTypes.object,
  firstAppointment: PropTypes.object,
  terminalWithAppointments: PropTypes.array.isRequired,
  selectedShift: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  gateHours: getCurrentGateHour(state, ownProps.terminal.key),
  selectedShift: state.availabilities.selectedShift,
  firstAppointment: getFirstAppointmentByTerminal(state, ownProps.terminal.key),
  terminalWithAppointments: state.erlData.terminalsWithAppointments,
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(FirstAppointmentCellHeader);
