import { NAV_HEIGHT } from 'layouts/AppPageTemplate/utils/config';
import React, { createContext, useState, useContext } from 'react';

const NavHeightContext = createContext<
  { marginTop: number; adjustMarginTop: Function } | undefined
>(undefined);

function NavHeightProvider({ children }: any) {
  const [marginTop, setMarginTop] = useState(NAV_HEIGHT);

  const adjustMarginTop = (newMargin: number) => {
    setMarginTop(NAV_HEIGHT + newMargin);
  };

  const value = { marginTop, adjustMarginTop };
  return (
    <NavHeightContext.Provider value={value}>
      {children}
    </NavHeightContext.Provider>
  );
}

function useNavHeightContext() {
  const context = useContext(NavHeightContext);
  if (context === undefined) {
    throw new Error(
      'useNavHeightContext must be used within a NavHeightProvider'
    );
  }
  return context;
}

export { NavHeightProvider, useNavHeightContext, NavHeightContext };
