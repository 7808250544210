import _ from 'lodash';
import moment from 'moment';
import {
  dateFormat,
  shifts,
  timeFormat,
  pendingStatus,
} from '../constants/my-appointments.constants';

export const MyAppointmentsService = {
  getFormattedStatus(appointment_status) {
    return _.toLower(appointment_status || pendingStatus);
  },

  getFormattedShift(shift_date, shift) {
    let shiftDateFormatted = '-';
    if (shift_date) {
      shiftDateFormatted = moment(shift_date, dateFormat.input).format(
        dateFormat.output
      );
    }
    let shiftFormatted = '-';
    switch (shift) {
      case shifts.shift1.raw:
        shiftFormatted = shifts.shift1.formatted;
        break;
      case shifts.shift2.raw:
        shiftFormatted = shifts.shift2.formatted;
        break;
      default:
        shiftFormatted = '-';
    }
    return `${shiftDateFormatted} ${shiftFormatted}`;
  },

  getFormattedDatetime(terminal_date, start_time, end_time) {
    const startTimeFormatted = moment(start_time, timeFormat.input).format(
      timeFormat.output
    );
    const endTimeFormatted = moment(end_time, timeFormat.input).format(
      timeFormat.output
    );
    return `${terminal_date} ${startTimeFormatted}-${endTimeFormatted}`;
  },
};
