import React from 'react';
import PricingCard from './PricingCard';
import StarterPlan from './StarterPlan';
import _ from 'lodash';

const PlansPage = ({ packs, user, onPackSelected }) => {
  const displayStarter =
    _.get(user, 'company.pack') !== 'pioneer' ||
    _.get(user, 'pack') === 'trial_end';

  return (
    <div className="flex flex-col space-y-8 items-center ">
      {/* pricing cards */}
      <div className="flex flex-col space-y-8 lg:flex lg:flex-row lg:space-x-4 lg:space-y-0 lg:justify-center">
        {packs
          .filter(pack => pack.id !== 'starter')
          .map(pack => {
            const lowestPrice = Math.min(
              ...Object.values(pack.monthlyAvailableVolumes).map(
                v => v.startsAt
              )
            );
            return (
              <PricingCard
                key={pack.id}
                disabled={!user.company}
                isCurrentPack={pack.id === _.get(user, 'company.pack')}
                packId={pack.id}
                name={pack.displayName}
                buttonText={pack.buttonText}
                descriptions={pack.descriptions}
                displayedColor={pack.displayedColor}
                downSellPack={pack.downSellPack}
                startsAt={lowestPrice}
                onPackSelected={onPackSelected}
              />
            );
          })}
      </div>
      {/* starter plan */}
      {displayStarter && (
        <StarterPlan
          user={user}
          starterPack={packs.find(p => p.id === 'starter')}
          onPackSelected={onPackSelected}
        />
      )}
    </div>
  );
};

export default PlansPage;
