export default theme => ({
  inputs: {
    display: 'flex',
    minWidth: '40%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    alignSelf: 'flex-start',
    height: 48,
    width: 170,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
});
