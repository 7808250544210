import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const PlanText = ({ pack, trialEnd }) => {
  let planText = '';
  if (pack === 'pioneer') {
    if (moment(trialEnd).isAfter()) {
      const days = Math.floor(
        moment.duration(moment(trialEnd).diff(moment())).asDays()
      );
      planText = `Trial ends in ${days} days`;
    } else planText = 'Trial has ended';
  } else planText = `${pack.charAt(0).toUpperCase() + pack.slice(1)} plan`;
  return <span>{planText}</span>;
};

PlanText.propTypes = {
  pack: PropTypes.string.isRequired,
  trialEnd: PropTypes.string,
};

export default PlanText;
