import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import './ShowHideNextShifts.style.css';

export const ShowHideNextShifts = ({
  classes,
  terminalsLength,
  isAllShiftsShown,
  toggleShowHideAllShifts,
}) => {
  const text = isAllShiftsShown ? 'Hide next shifts' : 'Show next shifts';
  const icon = isAllShiftsShown ? (
    <ArrowDropDownIcon style={{ fontSize: 24 }} />
  ) : (
    <ArrowRightIcon style={{ fontSize: 24 }} />
  );
  const className = isAllShiftsShown
    ? classes.showHideNextShiftsHide
    : classes.showHideNextShiftsShow;

  return (
    <TableRow>
      <TableCell colSpan={`${terminalsLength}`} className={className}>
        <div
          className="show-hide-shifts"
          onClick={() => toggleShowHideAllShifts(!isAllShiftsShown)}
        >
          <div className="show-hide-shifts-icon">{icon}</div>
          <div>{text}</div>
        </div>
      </TableCell>
    </TableRow>
  );
};

ShowHideNextShifts.propTypes = {
  classes: PropTypes.object.isRequired,
  terminalsLength: PropTypes.number,
  isAllShiftsShown: PropTypes.bool,
  toggleShowHideAllShifts: PropTypes.func.isRequired,
};
