import { getVesselScheduleEnd } from 'services/clients/vesselSchedule';
import { startLoading, endLoading } from 'ducks/loader';

// Action Types
export const SET_MY_VESSEL_SCHEDULE = 'core/api/v1/erl/SET_MY_VESSEL_SCHEDULE';
export const CLEAR_MY_VESSEL_SCHEDULE =
  'core/api/v1/erl/CLEAR_MY_VESSEL_SCHEDULE';

// Reducer
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_VESSEL_SCHEDULE:
      return action.payload;
    case CLEAR_MY_VESSEL_SCHEDULE:
      return initialState;
    default:
      return state;
  }
};

// Action creators
export const setVesselSchedule = vesselSchedule => {
  return {
    type: SET_MY_VESSEL_SCHEDULE,
    payload: vesselSchedule,
  };
};

export const setClearVesselSchedule = () => {
  return {
    type: CLEAR_MY_VESSEL_SCHEDULE,
  };
};

// Side effects
export const getVesselSchedule = () => async dispatch => {
  try {
    dispatch(startLoading('isVesselScheduleLoading'));
    const data = await getVesselScheduleEnd();
    dispatch(setVesselSchedule(data));
    dispatch(endLoading('isVesselScheduleLoading'));
  } catch (error) {
    console.log('getVesselSchedule error ', error);
    dispatch(endLoading('isVesselScheduleLoading'));
  }
};
