import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GateScheduleTableHeader from './GateScheduleTableHeader';
import GateScheduleTableRowCell from './GateScheduleTableRowCell';
import GateScheduleTableHeaderCell from './GateScheduleTableHeaderCell';
import { GateScheduleService } from './services/gate-schedule.service';

class GateScheduleTable extends Component {
  static propTypes = {
    tableId: PropTypes.string.isRequired,
    tableData: PropTypes.object.isRequired,
    isNextWeek: PropTypes.bool,
    onDownloadClick: PropTypes.func.isRequired,
  };

  render() {
    const { tableId, tableData, isNextWeek, onDownloadClick } = this.props;
    const { data, headers, last_update } = tableData || {};

    return (
      <div
        id={tableId}
        className={`gate-schedule-table-wrapper${isNextWeek ? '' : ' current'}`}
      >
        <GateScheduleTableHeader
          lastUpdate={last_update}
          isNextWeek={isNextWeek}
          onDownloadClick={onDownloadClick}
        />
        <div className="gate-schedule-table">
          <div className="gate-schedule-table-header">
            <div className="gate-schedule-table-header-cell terminal-name">
              Terminal
            </div>
            {headers &&
              headers.map(({ date, shift }, index) => {
                const isToday = GateScheduleService.checkIsDateToday(date);
                return (
                  <GateScheduleTableHeaderCell
                    key={index}
                    date={date}
                    shift={shift}
                    isToday={isToday}
                  />
                );
              })}
          </div>
          <div className="gate-schedule-table-body">
            {data &&
              data.map(({ terminal, data }) => {
                return (
                  <div key={terminal} className="gate-schedule-table-body-row">
                    <div className="gate-schedule-table-body-row-cell terminal-name">
                      {terminal}
                    </div>
                    {data &&
                      data.map((status, index) => {
                        const { date } = headers[index] || {};
                        const isToday = GateScheduleService.checkIsDateToday(
                          date
                        );
                        return (
                          <GateScheduleTableRowCell
                            key={index}
                            status={status}
                            isToday={isToday}
                          />
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default GateScheduleTable;
