import React, { useState, createContext } from 'react';

export const SelectedContainerContext = createContext();

export const SelectedContainerContextProvider = props => {
  const [selectedContainer, setSelectedContainer] = useState({
    watchListRow: null,
  });

  return (
    <SelectedContainerContext.Provider
      value={[selectedContainer, setSelectedContainer]}
    >
      {props.children}
    </SelectedContainerContext.Provider>
  );
};
