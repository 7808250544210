import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class CredentialsSpinner extends Component {
  static propTypes = {
    customMessage: PropTypes.string,
  };

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
  }

  render() {
    const { customMessage } = this.props;
    const title = customMessage || 'CHECKING CREDENTIALS...';

    return (
      <div className="creds-spinner-wrapper">
        <div className="creds-spinner-container">
          <CircularProgress size={85} style={{ color: 'white' }} />
          <div className="creds-spinner-title">{title}</div>
        </div>
      </div>
    );
  }
}
