import Home from './Home';
import styles from './Home.style';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isLogged } from 'ducks/user';

const mapStateToProps = state => ({
  isAdmin: state.user && state.user.is_admin,
  isLogged: isLogged(state),
});

export default compose(connect(mapStateToProps), withStyles(styles))(Home);
