import moment from 'moment-timezone';
import flattenDeep from 'lodash/flattenDeep';
import lodash from 'lodash';
import set from 'lodash/fp/set';

export const laTimezone = time => moment.tz(time, 'America/Los_Angeles');

export const getLastRefreshDate = data => {
  let lrdData = {};

  flattenDeep(
    Object.values(data).map(x => Object.values(x).map(Object.values))
  ).forEach(x => {
    if (x.last_refresh_date) lrdData[x.terminal] = x.last_refresh_date;
  });
  return lrdData;
};

/**
 * Input data :
 *  [
      {
        "id": 8455,
        "port_id": "ssa_piera",
        "shipping_line_id": "CMA",
        "date": "2019-05-06T07:00:00Z",
        "last_update": "2019-05-06T17:00:46.464Z",
        "container_type_id": "20'",
        "shift_id": "shift2",
        "availability_id": "no"
      },
      ...
    ]
    ouput formatted Data :
    {
      "ANL": {
        "45": {
          ssa_pct: {
            "terminal": "ssa_pct",
            "last_refresh_date": "05/06/19 10:00:45",
            "availability": {
              "2019-05-06": {
                "shift1": "yes",
                "shift2": "yes"
              },
              ...
            }
          },
          ...
        },
        ...
      },
      ...
    }

 */
export const formatData = data =>
  lodash(data)
    .groupBy('shipping_line_id')
    .mapValues(a =>
      lodash(a)
        .groupBy('container_type_id')
        .mapValues(b =>
          lodash(b)
            .groupBy('port_id')
            .mapValues(d =>
              d
                .map(c => ({
                  terminal: c.port_id,
                  last_refresh_date: c.last_update,
                  availability: {
                    [laTimezone(c.date).format('YYYY-MM-DD')]: {
                      [c.shift_id]: {
                        status: c.availability_id,
                        warnings: c.instructions
                          ? [c.instructions, ...c.warnings]
                          : c.warnings,
                        chassis_restrictions: c.chassis_restrictions,
                      },
                    },
                  },
                }))
                .reduce((e, f) => ({
                  terminal: e.terminal,
                  last_refresh_date: lodash.max([
                    e.last_refresh_date,
                    f.last_refresh_date,
                  ]),
                  availability: {
                    ...lodash.merge(e.availability, f.availability),
                  },
                }))
            )
            .value()
        )
        .value()
    )
    .value();

export const extractTerminal = (availabilities, sl, cType, terminal) =>
  availabilities[sl.key] &&
  availabilities[sl.key][cType.key] &&
  availabilities[sl.key][cType.key][terminal.key];

export const extractAvailability = (currentTerminal, layout) =>
  currentTerminal &&
  currentTerminal.availability &&
  currentTerminal.availability[layout.day] &&
  currentTerminal.availability[layout.day][layout.shift];

export const availabilityAtomicUpdater = state => ({
  shipping_line,
  container_type,
  port,
  date,
  shift,
  availability,
  warnings,
  instructions,
}) => {
  const accessor = `data["${shipping_line}"]["${container_type}"]["${port}"].availability.${laTimezone(
    date
  ).format('YYYY-MM-DD')}.${shift}`;
  const update = {
    status: availability,
    warnings: instructions ? [instructions, ...warnings] : warnings,
  };

  return set(accessor, update, state);
};

export const cantBeReturnAnywhere = (availabilities, sl, cType, shift) => {
  const unreturnableStatus = ['no', 'na'];
  return !(
    availabilities[sl.key] &&
    availabilities[sl.key][cType.key] &&
    Object.values(availabilities[sl.key][cType.key])
      .map(
        a =>
          a.availability &&
          a.availability[shift.day] &&
          a.availability[shift.day][shift.shift] &&
          !unreturnableStatus.includes(
            a.availability[shift.day][shift.shift].status
          )
      )
      .reduce((a, b) => a || b)
  );
};
