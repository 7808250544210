import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { CALENDLY_LINK } from '../constants';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SegmentService } from 'services/helpers/segment';

const MoreInformationsCard = ({ user, defaultNbContainers }) => {
  const [step, setStep] = useState(0);
  const [formValues, setFormValues] = useState({
    nbContainers: defaultNbContainers,
    needs: '',
  });

  const {
    email,
    first_name,
    last_name,
    phone_number,
    pack,
    company_scac,
  } = user;

  const handleSendRequest = () => {
    SegmentService.trackEvent('Container Volume Request Clicked', {
      email: email,
      firstName: first_name,
      lastName: last_name,
      phone: phone_number,
      currentPlan: pack,
      companyScac: company_scac,
      formValues: formValues,
    });
    setStep(step + 1);
  };

  return (
    <div className="bg-white shadow-sm rounded-md p-4">
      <div className="flex flex-col space-y-8 items-start w-full">
        <div className="flex flex-col space-y-4 w-full">
          <h2 className="font-bold text-dark">
            Talk to Sales - (650) 480 1760
          </h2>
          <hr
            className="w-full bg-blue-500 border-none my-4 rounded-md"
            style={{ height: '1px' }}
          />
          {step === 0 && (
            <DescribeYourNeeds
              formValues={formValues}
              onChange={(key, v) => setFormValues({ ...formValues, [key]: v })}
            />
          )}
          {step === 1 && (
            <BookCalendlyAppointments
              user={user}
              onEventScheduled={() => setStep(step + 1)}
              formValues={formValues}
            />
          )}
          {step === 2 && <EventScheduled onClick={() => setStep(0)} />}
        </div>
        {step === 0 && (
          <div className="w-full flex flex-col space-y-4">
            <LoadingButton
              variant="contained"
              loading={false}
              color="primary"
              onClick={handleSendRequest}
            >
              Send Request
            </LoadingButton>
          </div>
        )}
      </div>
    </div>
  );
};

const DescribeYourNeeds = ({ formValues, onChange }) => {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-1  text-dark">
        <h4 className="font-medium">
          How many containers do you move every year?
        </h4>
        <TextField
          value={formValues.nbContainers}
          onChange={e => onChange('nbContainers', e.target.value)}
          placeholder="Number of containers"
          type="number"
        />
      </div>
      <div className="flex flex-col space-y-1  text-dark">
        <h4 className="font-medium">Tell us more about you needs</h4>
        <TextField
          value={formValues.needs}
          onChange={e => onChange('needs', e.target.value)}
          multiline
          rows={5}
          placeholder="Describe your needs"
        />
      </div>
    </div>
  );
};

const BookCalendlyAppointments = ({ formValues, user, onEventScheduled }) => {
  return (
    <CalendlyEventListener onEventScheduled={onEventScheduled}>
      <InlineWidget
        url={CALENDLY_LINK}
        prefill={{
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          name: `${user.first_name} ${user.last_name}`,
          customAnswers: {
            a1: formValues.needs,
            a2: user.company.name,
            a3: formValues.nbContainers,
            // Duplicated question
            a4: formValues.needs,
          },
        }}
      />
    </CalendlyEventListener>
  );
};

const EventScheduled = ({ onClick }) => {
  return (
    <div
      className="flex flex-col justify-items-center items-center cursor-pointer space-y-4 py-8"
      onClick={onClick}
    >
      <CheckCircleOutlineIcon className="text-blue" style={{ fontSize: 52 }} />
      <h3 className="font-medium text-dark">Your demo has been scheduled</h3>
    </div>
  );
};

export default MoreInformationsCard;
