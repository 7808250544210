import React, { useEffect, useState } from 'react';
import {
  GridToolbarContainer,
  GridToolbarExport,
  DataGridPro,
  LicenseInfo,
} from '@mui/x-data-grid-pro';
import config from 'config';
import { pinMuiDataGridHeaders } from '../../../utils/dataGrid';
import { useNavHeightContext } from 'common/contexts/NavHeightContext';

LicenseInfo.setLicenseKey(config.DATA_GRID_PRO_LICENSE_KEY);

const VesselTable = ({ visibleRows, columns, loading, pinnedColumns }) => {
  const { marginTop } = useNavHeightContext();

  function CustomToolbar() {
    return (
      <div className="flex justify-start px-2 py-2">
        <GridToolbarContainer>
          <div className="bg-blue-600 border border-blue-400 rounded-sm">
            <GridToolbarExport />
          </div>
        </GridToolbarContainer>
      </div>
    );
  }

  const [isFixedHeaders, setIsFixedHeaders] = useState(false);
  useEffect(() => {
    window.addEventListener(
      'scroll',
      pinMuiDataGridHeaders.bind(null, setIsFixedHeaders, isFixedHeaders),
      { passive: true }
    );

    return () => window.removeEventListener('scroll', pinMuiDataGridHeaders);
  }, [isFixedHeaders]);

  return (
    <div className={`w-full`} style={{ height: '800px' }}>
      <DataGridPro
        components={{
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        rowHeight={64}
        headerHeight={32}
        // To get our test to display all columns
        columnBuffer={columns.length}
        rows={visibleRows}
        columns={columns}
        loading={loading}
        initialState={
          pinnedColumns
            ? { pinnedColumns: { left: ['vesselName'] } }
            : undefined
        }
        sx={{
          '& .MuiDataGrid-columnHeaders': isFixedHeaders && {
            top: marginTop, // Navbar height
            left: '1px',
            zIndex: 1198, // User Pop z-index is 1199
            position: 'fixed',
          },
        }}
      />
    </div>
  );
};

export default VesselTable;
