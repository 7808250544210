import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { GateScheduleService } from './services/gate-schedule.service';

class GateScheduleTableHeaderCell extends PureComponent {
  static propTypes = {
    date: PropTypes.string,
    shift: PropTypes.number,
    isToday: PropTypes.bool,
  };

  render() {
    const { date, shift, isToday } = this.props;
    return (
      <div
        className={`gate-schedule-table-header-cell ${isToday ? ' today' : ''}`}
      >
        {isToday ? (
          <div className="gate-schedule-table-today-label">TODAY</div>
        ) : null}
        <div>{GateScheduleService.geDayOfWeek(date)}</div>
        <div>{GateScheduleService.geMonthDay(date)}</div>
        <div>{`SHIFT ${shift}`}</div>
      </div>
    );
  }
}

export default GateScheduleTableHeaderCell;
