export default theme => ({
  toggleButton: {
    height: 37,
    width: 170,
    backgroundColor: theme.palette.white.main,
    borderRadius: '5px 5px 0px 0px',
    borderTop: '1px solid rgba(0, 22, 68, 0.05)',
    borderRight: '1px solid rgba(0, 22, 68, 0.05)',
    borderLeft: '1px solid rgba(0, 22, 68, 0.05)',
    position: 'absolute',
    top: -36,
    right: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: -1,
  },
  toogleButtonText: {
    fontSize: 12,
    color: '#1F334B',
  },
  block: {
    backgroundColor: theme.palette.white.main,
    borderTop: '1px solid rgba(0, 22, 68, 0.05)',
    borderRight: '1px solid rgba(0, 22, 68, 0.05)',
    borderLeft: '1px solid rgba(0, 22, 68, 0.05)',
    transition: 'height .5s',
    display: 'flex',
    padding: 3 * theme.spacing.unit,
    paddingBottom: 7 * theme.spacing.unit,
  },
  collapse: {
    marginTop: 36,
    width: '100%',
  },
  tableOverFilter: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    position: 'relative',
  },
  filterItem: {
    flex: 1,
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
  arrowIcon: {
    marginBottom: 4,
    fill: theme.palette.primary.main,
  },
  inverseIcon: {
    transform: 'rotate(180deg)',
  },
  filterIcon: {
    marginBottom: 2,
    fill: theme.palette.primary.main,
  },
  subtitle: {
    display: 'flex',
    alignItems: 'flex-end',
    fill: 'rgba(31, 51, 75, 0.5)',
    marginLeft: theme.spacing.unit,
  },
  input: {
    marginTop: 2 * theme.spacing.unit,
  },
  autocompleteRoot: {
    width: 'unset',
  },
  warning: {
    color: theme.palette.alert.main,
    fill: theme.palette.alert.main,
  },
});
