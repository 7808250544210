import React from 'react';
import Container from 'layouts/Containers/Container';

const PageTitle = ({ title, actions, subtitle }) => {
  return (
    <Container bg="bg-blue-600">
      <div className="bg-blue-600 py-8">
        <div className="flex justify-between items-end">
          <div className="flex flex-col space-y-4">
            <h1 className="font-bold">{title}</h1>
            {subtitle}
          </div>
          {actions && <div className="">{actions}</div>}
        </div>
        <div className="w-full h-0.5 bg-blue-500 mt-4"></div>
      </div>
    </Container>
  );
};

export default PageTitle;
