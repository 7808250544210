export default theme => ({
  green: {
    color: theme.palette.green.main,
  },
  red: {
    color: theme.palette.alert.main,
  },
  yellow: {
    color: theme.palette.secondary.main,
  },
  blue: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  grey: {
    color: theme.palette.grey[500],
  },
});
