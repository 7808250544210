import React from 'react';
import { laTimezone } from 'services/helpers/data';
import {
  DATE_DISPLAY_FORMAT,
  DEFAULT_HOUR_MINUTE_DISPLAY,
} from '../container-config';

export function FormattedDateTime({
  dateTimeString,
}: {
  dateTimeString: string;
}): JSX.Element {
  if (!dateTimeString) return <>-</>;

  const asMomentDate = laTimezone(new Date(dateTimeString));
  const date = asMomentDate.format(DATE_DISPLAY_FORMAT);
  const time = asMomentDate.format(DEFAULT_HOUR_MINUTE_DISPLAY);
  return (
    <>
      {date} · {time}
    </>
  );
}

export function FormattedDate({
  dateString,
}: {
  dateString: string;
}): JSX.Element {
  if (!dateString) return <>-</>;

  const asMomentDate = laTimezone(new Date(dateString));
  const date = asMomentDate.format(DATE_DISPLAY_FORMAT);
  return <>{date}</>;
}
