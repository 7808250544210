import React from 'react';
import Button from '@mui/material/Button';
import { Dropdown } from 'common/UI/Dropdown';
import PageTitle from 'components/PageTitle/PageTitle';
import fonts from 'styles/typography.module.css';
import Container from 'layouts/Containers/Container';

const Filters = ({
  isLoading,
  selectedSL,
  classNames,
  shippingLines,
  containerTypes,
  selectedContainer,
  onContainerChange,
  onShippingLineChange,
  isDownloadInProgress,
  onHandleStopTheClockModalOpen,
}) => (
  <div className={fonts.font}>
    <PageTitle title="Find Appointments for your singles and duals" />
    <Container bg="bg-blue-600">
      <div className="w-full pt-4 pb-8 flex justify-between items-end">
        <div className="w-8/12 flex space-x-8">
          <div className="w-full">
            <Dropdown
              id="shipingLines"
              label="Select a shipping line"
              options={shippingLines}
              value={selectedSL}
              onDropdownChange={onShippingLineChange}
            />
          </div>
          <div className="w-full">
            <Dropdown
              id="containerTypes"
              label="Select a container type"
              options={containerTypes}
              value={selectedContainer}
              onDropdownChange={onContainerChange}
            />
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={onHandleStopTheClockModalOpen}
            disabled={isDownloadInProgress || isLoading}
            style={{ height: '54px', width: '256px' }}
          >
            <h3>Stop the Clock</h3>
          </Button>
        </div>
      </div>
    </Container>
  </div>
);

export default Filters;
