import { pageTitlesAndUrls } from '../../../constants/pages';

export const SIDE_NAVIGATION_CONTENT = [
  {
    key: 'settings',
    title: 'Settings',
    sideNavigationLinks: [
      pageTitlesAndUrls.profile,
      pageTitlesAndUrls.plans,
      pageTitlesAndUrls.invoiceHistory,
      pageTitlesAndUrls.notificationSettings,
      pageTitlesAndUrls.credentials,
    ],
  },
];
