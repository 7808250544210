import erlClient from './';

export const setCredentialsForTerminal = async (creds, terminalKey, cb) => {
  try {
    const res = await erlClient.post(
      `/core/api/v1/appointments/credentials/${terminalKey}`,
      creds
    );
    cb(null, res);
  } catch (error) {
    console.error(error);
    cb(error);
  }
};
