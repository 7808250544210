import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import EmptyIcon from '@material-ui/icons/NotInterested';
import { withStyles } from '@material-ui/core';

const SnapshotPreview = ({ url, classes }) => {
  if (!url)
    return (
      <div className={classes.empty}>
        <EmptyIcon className={classes.emptyIcon} />
        <Typography className={classes.grey}>Not available</Typography>
      </div>
    );
  return url.includes('png') || url.includes('jpg') ? (
    <img src={url} alt={url} width="100%" className={classes.image} />
  ) : (
    <iframe title={url} src={url} className={classes.iframe} />
  );
};
SnapshotPreview.propTypes = {
  url: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  iframe: {
    // Targeted height 250px
    height: 575,
    width: '250%',
    webkitTransform: 'scale(0.4)',
    webkitTransformOrigin: '0 0',
    transformOrigin: '0 0',
    transform: 'scale(0.4)',
    pointerEvents: 'none',
  },
  grey: {
    color: theme.palette.grey[500],
  },
  empty: {
    width: '100%',
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  emptyIcon: {
    fontSize: 25,
    marginBottom: 2 * theme.spacing.unit,
  },
  image: {
    objectFit: 'contain',
    height: 'inherit',
  },
});

export default withStyles(styles)(SnapshotPreview);
