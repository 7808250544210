import axiosWithToken from 'services/clients/axios-with-token';
import _ from 'lodash';
import { objectKeysToSnakeCase, joinArrayParams } from 'utils/common';

import {
  transformContainers,
  addTerminalStatuses,
} from './transform-containers';

export async function fetchContainers(
  params = {},
  containerNumbers = [],
  signal = null
) {
  const sortBy =
    containerNumbers.length > 0 ? 'container_numbers_input' : 'per_diem';
  const promise = axiosWithToken.post(
    '/core/api/v1/containers/watch-list',
    {
      container_numbers: containerNumbers,
    },
    {
      params: _.mapValues(
        objectKeysToSnakeCase({ sortBy, ...params }),
        joinArrayParams
      ),
      signal,
    }
  );

  const {
    data: { count, results, total_container_watched, no_capacity_containers },
  } = await promise;

  const containersTransformed = transformContainers(results, containerNumbers);
  const terminalStatusResponse = await fetchTerminalStatus(
    containersTransformed,
    signal
  );
  const containerList = addTerminalStatuses(
    containersTransformed,
    terminalStatusResponse
  );

  return {
    noCapacityContainers: no_capacity_containers || [],
    containerList,
    containerListRowCount: count,
    totalContainerWatched: total_container_watched,
  };
}

async function fetchTerminalStatus(containers, signal) {
  const lineSizesMap = containers.reduce((acc, result) => {
    const { shippingLineKey, containerTypeKey, gated_in } = result;
    if (gated_in || !shippingLineKey || !containerTypeKey) return acc;

    acc[`${shippingLineKey}${containerTypeKey}`] = [
      shippingLineKey,
      containerTypeKey,
    ];
    return acc;
  }, {});
  const { data: terminalStatusRows } = await axiosWithToken.post(
    '/core/api/v1/tas/terminal-status',
    {
      'line-sizes': Object.values(lineSizesMap),
    },
    { signal }
  );
  return terminalStatusRows;
}
