import React from 'react';
import { Button } from '@mui/material';

const CALENDLY_DOWNGRADE_REQUEST =
  'https://calendly.com/d/cpy-6cm-c9r/platform-demo-bluecargo';

const DowngradeRequest = () => {
  const onScheduleCallClick = () => {
    window.open(CALENDLY_DOWNGRADE_REQUEST, '_blank');
  };

  return (
    <div className="flex flex-col space-y-8 items-start">
      <div className="flex flex-col space-y-2">
        <h1 style={{ fontSize: '36px' }} className="font-bold">
          Talk with <span className="text-blue">Customer Success</span> to
          adjust your plan - (650) 480 1760
        </h1>
        <h2 className="font-medium text-dark">
          Schedule a call today with one of our representative.
        </h2>
      </div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={onScheduleCallClick}
      >
        <p className="font-bold">Schedule a call</p>
      </Button>
    </div>
  );
};

export default DowngradeRequest;
