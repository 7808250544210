import axios from 'axios';
import config from 'config';
import { LocalStorageUtil } from 'utils/local-storage.util';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
} from 'constants/user';
import _ from 'lodash';

const axiosWithToken = axios.create({
  baseURL: config.backendUrl,
  'Content-Type': 'application/json',
  headers: {
    Authorization: {
      toString() {
        const token = LocalStorageUtil.get(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
        if (token) return `Bearer ${token}`;
      },
    },
  },
});

axiosWithToken.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const originalRequest = error.config;
    const refreshToken = LocalStorageUtil.get(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
    if (
      error.response &&
      error.response.status === 401 &&
      error.config &&
      !error.config.__isRetryRequest &&
      refreshToken
    ) {
      originalRequest._retry = true;

      return fetch(`${config.backendUrl}/core/api/v1/auth/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh: refreshToken,
        }),
      })
        .then(res => res.json())
        .then(res => {
          LocalStorageUtil.set(ACCESS_TOKEN_LOCAL_STORAGE_KEY, res.access);
          originalRequest.headers['Authorization'] = 'Bearer ' + res.access;
          return axios(originalRequest);
        });
    }
    return Promise.reject(error);
  }
);

export function camelCaseResults(response) {
  const results = response.data.results.map(row =>
    Object.keys(row).reduce((acc, key) => {
      acc[_.camelCase(key)] = row[key];
      return acc;
    }, {})
  );
  return { ...response.data, results };
}

export async function getWithTokenRefresh(endpoint = '') {
  const { data } = await axiosWithToken.get(endpoint);
  return data;
}

export default axiosWithToken;
