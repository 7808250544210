import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import {
  SMALL_TABLE_CELL_WIDTH,
  SHIPPING_LINE_TABLE_CELL_WIDTH,
  LARGE_TABLE_CELL_WIDTH,
} from 'constants/layout';
import WarningIcon from '@material-ui/icons/Warning';
import GateStatusCellContent from 'components/GateStatusCellContent';
import { withStyles } from '@material-ui/core/styles';

export class GateStatusHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    terminals: PropTypes.array.isRequired,
    displayShift: PropTypes.bool.isRequired,
  };

  computeFirstCellStyle() {
    const { displayShift } = this.props;
    const baseWidth = SHIPPING_LINE_TABLE_CELL_WIDTH + SMALL_TABLE_CELL_WIDTH;
    return {
      width: displayShift ? baseWidth + LARGE_TABLE_CELL_WIDTH : baseWidth,
      minWidth: displayShift ? baseWidth + LARGE_TABLE_CELL_WIDTH : baseWidth,
    };
  }

  render() {
    const { classes, terminals } = this.props;
    return (
      <div className={classes.wrapper}>
        <div
          style={this.computeFirstCellStyle()}
          className={classnames(classes.gate, classes.labelCell)}
        >
          <div className={classes.label}>
            <WarningIcon className={classes.labelIcon} />
            <div className={classes.labelTexts}>
              <Typography variant="caption" className={classes.labelPrimary}>
                Gate status
              </Typography>
            </div>
          </div>
        </div>
        {terminals.map(terminal => (
          <GateStatusCellContent
            key={terminal.name}
            terminal={terminal}
            classes={{ root: classes.gate }}
          />
        ))}
      </div>
    );
  }
}
const styles = theme => ({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  gate: {
    height: 45,
    width: LARGE_TABLE_CELL_WIDTH,
    minWidth: LARGE_TABLE_CELL_WIDTH,
    borderLeft: '0.5px solid rgba(31, 51, 75, 0.1)',
    borderRight: '0.5px solid rgba(31, 51, 75, 0.1)',
    borderBottom: '1px solid rgba(31, 51, 75, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelCell: {
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  labelIcon: {
    flex: 2,
    fontSize: 20,
    color: 'rgba(31, 51, 75, 0.5)',
  },
  labelTexts: {
    flex: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  labelPrimary: {
    fontWeight: 'bold',
    fontSize: 12,
    color: 'rgba(31, 51, 75, 0.5)',
  },
});

export default withStyles(styles)(GateStatusHeader);
