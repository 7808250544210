export const sortableColsIds = {
  shift: 'shift',
  datetime: 'datetime',
};

export const tableCols = [
  {
    id: 0,
    col_id: 'container_number',
    label: 'Container #',
    isColSortable: true,
  },
  {
    id: 1,
    col_id: 'approval__appointment_status',
    label: 'Status',
    isColSortable: true,
  },
  {
    id: 2,
    col_id: 'shipping_line',
    label: 'SSL',
    isColSortable: true,
  },
  {
    id: 3,
    col_id: 'terminal',
    label: 'Return Location',
    isColSortable: true,
  },
  {
    id: 4,
    col_id: sortableColsIds.shift,
    label: 'Shift',
    isColSortable: true,
  },
  {
    id: 5,
    col_id: sortableColsIds.datetime,
    label: 'Datetime',
    isColSortable: true,
  },
  {
    id: 6,
    col_id: 'confirmation_number',
    label: 'Confirmation #',
    isColSortable: false,
  },
  {
    id: 7,
    col_id: 'chassis_owner',
    label: 'Chassis Owner',
    isColSortable: false,
  },
  {
    id: 8,
    col_id: 'chassis_number',
    label: 'Chassis #',
    isColSortable: false,
  },
  {
    id: 9,
    col_id: 'driver_license',
    label: 'Driver License',
    isColSortable: false,
  },
];

export const sortOrderTypes = {
  asc: 'asc',
  desc: 'desc',
  none: 'none',
};

export const arrowStyle = {
  color: '#1D366A',
  fontSize: '24px',
};

export const dateFormat = {
  input: 'YYYY-MM-DD',
  output: 'ddd MMM DD',
};

export const timeFormat = {
  input: 'HH:mm:ss',
  output: 'HH:mm',
};

export const shifts = {
  shift1: {
    raw: 'shift1',
    formatted: 'Shift 1',
  },
  shift2: {
    raw: 'shift2',
    formatted: 'Shift 2',
  },
};

export const pendingStatus = 'pending';
