import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DualIcon from '@material-ui/icons/CompareArrows';
import WarningIcon from '@material-ui/icons/Warning';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';

const iconFontSize = 18;

export const CAPTION_ITEMS = [
  {
    key: 'yes',
    icon: classes => <CheckIcon fontSize="large" className={classes.green} />,
    caption: 'Open for receiving',
  },
  {
    key: 'no',
    icon: classes => <CloseIcon fontSize="large" className={classes.red} />,
    caption: 'Closed for receiving',
  },
  {
    key: 'dual',
    icon: classes => <DualIcon fontSize="large" className={classes.yellow} />,
    caption: 'Dual transactions only',
  },
  {
    key: 'warnings',
    icon: classes => (
      <WarningIcon fontSize="default" className={classes.grey} />
    ),
    caption: 'Pass the mouse for warnings',
  },
  {
    key: 'broken',
    icon: classes => <div className={classes.broken} />,
    caption: 'Fail to get data, check terminal website',
  },
];

export default class Caption extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    isEmptyAppointments: PropTypes.bool,
  };

  render() {
    const { classes, isOpen, open, close, isEmptyAppointments } = this.props;

    if (isEmptyAppointments) {
      return [
        <Fade in={!isOpen} key="button">
          <Button
            onClick={open}
            className={classes.buttonLegend}
            variant="contained"
          >
            Legend
          </Button>
        </Fade>,
        <Slide in={isOpen} direction="right" key="menu">
          <div className={classes.rootLegend}>
            <IconButton
              onClick={close}
              className={classes.closeButton}
              id="close-caption"
            >
              <CloseIcon style={{ fontSize: iconFontSize }} />
            </IconButton>

            <div className={classes.legendTitle}>Legend</div>

            <div className={classes.legendBlock}>
              <span className={classes.legendIcon}>
                <CheckIcon
                  style={{ fontSize: iconFontSize }}
                  className={classes.green}
                />
              </span>
              <span className={classes.legendText}>
                <div>Open for receiving</div>
              </span>
            </div>

            <div className={classes.legendBlock}>
              <span className={classes.legendIcon}>
                <CloseIcon
                  style={{ fontSize: iconFontSize }}
                  className={classes.red}
                />
              </span>
              <span className={classes.legendText}>
                <div>Closed for receiving</div>
              </span>
            </div>

            <div className={classes.legendBlock}>
              <span className={classes.legendIcon}>
                <DualIcon
                  style={{ fontSize: iconFontSize }}
                  className={classes.yellow}
                />
              </span>
              <span className={classes.legendText}>
                <div>Restrictions: dual transactions only</div>
              </span>
            </div>

            <div className={classes.legendBlock}>
              <span className={classes.legendIcon}>
                <WarningIcon
                  style={{ fontSize: iconFontSize }}
                  className={classes.yellow}
                />
              </span>
              <span className={classes.legendText}>
                <div>Restriction on chassis or equipment</div>
              </span>
            </div>

            <div className={classes.legendBlock}>
              <span className={classes.legendIcon}>
                <AccessTimeIcon
                  style={{ fontSize: iconFontSize }}
                  className={classes.legengGrey}
                />
              </span>
              <span className={classes.legendText}>
                <div>
                  Pending information from terminal on return restrictions
                </div>
              </span>
            </div>

            <div className={classes.legendBlock}>
              <span className={classes.legendIcon}>
                <DateRangeIcon
                  style={{ fontSize: iconFontSize }}
                  className={classes.calendar}
                />
              </span>
              <span className={classes.legendText}>
                <div>{'First appointment:'}</div>
                <div className={classes.legendTextBold}>{'13:00 - 17:00'}</div>
              </span>
            </div>

            <div className={classes.legendBlock}>
              <span
                className={classes.legendIcon}
                style={{ minWidth: iconFontSize }}
              ></span>
              <span className={classes.legendText}>
                <div>{`Shift total: number of appointments during the shift`}</div>
              </span>
            </div>
          </div>
        </Slide>,
      ];
    }

    return [
      <Fade in={!isOpen} key="button">
        <Button
          onClick={open}
          className={classes.button}
          variant="contained"
          classes={{ contained: classes.buttonRoot }}
        >
          Legend
        </Button>
      </Fade>,
      <Slide in={isOpen} direction="right" key="menu">
        <div className={classes.root}>
          <IconButton
            onClick={close}
            className={classes.closeButton}
            id="close-caption"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Legend</Typography>
          {CAPTION_ITEMS.map(caption => (
            <div key={caption.key} className={classes.caption}>
              <div className={classes.icon}>{caption.icon(classes)}</div>
              <Typography variant="caption">{caption.caption}</Typography>
            </div>
          ))}
        </div>
      </Slide>,
    ];
  }
}
