import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ReactComponent as VesselIcon } from 'assets/andora-icons/vessel.svg';
import { ReactComponent as ContainerIcon } from 'assets/andora-icons/container.svg';
import { brandColors } from 'brandColors';

export const Dropdown = ({
  id,
  label,
  options,
  value,
  onDropdownChange,
  disabled = false,
  disableClearable = false,
}) => {
  if (options && options.length === 0) return null;

  return (
    <Autocomplete
      disabled={disabled}
      disableClearable={disableClearable}
      id={id}
      options={options || []}
      value={value}
      onChange={onDropdownChange}
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.name || option.label
      }
      renderInput={params => (
        <div className="flex flex-col space-y-2">
          <div className="flex space-x-2 items-center">
            {id === 'shipingLines' && (
              <VesselIcon fill={brandColors.colors.gray[200]} />
            )}
            {id === 'containerTypes' && (
              <ContainerIcon fill={brandColors.colors.gray[200]} />
            )}
            <h4 className="font-medium text-gray-200">{label}</h4>
          </div>
          <TextField {...params} variant="outlined" />
        </div>
      )}
    />
  );
};

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.object,
  onDropdownChange: PropTypes.func.isRequired,
};
