import React from 'react';
import { Button } from '@mui/material';
import _ from 'lodash';

const StarterPlan = ({ user, starterPack, onPackSelected }) => {
  const pack = _.get(user, 'company.pack');

  if (_.isNil(starterPack)) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-4 items-center">
      <h4 className="text-gray-100 font-bold">
        Or choose the limited free owner operators plan
      </h4>
      <Button
        variant="outlined-info"
        id="starter-plan"
        disabled={!user.company}
        onClick={() => onPackSelected(starterPack.id)}
      >
        <h4 className="medium capitalize text-gray-200">
          {pack === 'starter'
            ? 'See Plan'
            : starterPack.buttonText === 'downgrade'
            ? 'Downgrade'
            : 'Choose Plan'}
        </h4>
      </Button>
    </div>
  );
};

export default StarterPlan;
