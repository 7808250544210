import React, { Component } from 'react';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import TableHeader from './Components/TableHeader/TableHeader';
import LastRefresh from './Components/LastRefresh';
import NextAppointments from './Components/NextAppointments';
import { LocalStorageUtil } from 'utils/local-storage.util';
import Filters from './Components/Filters';
import {
  LocalStorageKeys,
  GAsearchDelay,
  stopClockWarningMessage,
  GET_EMPTY_APPOINTMENTS_INTERVAL,
} from './constants/appointments.constants';
import AppointmentsModal from './Components/AppointmentsModal';
import BookAppointmentModal from './Components/BookAppointmentModal';
import StopTheClockModal from './Components/StopTheClockModal';
import StopTheClockFeedbackMsg from './Components/StopTheClockFeedbackMsg';
import { NextAppointmentsService } from './Components/NextAppointments/services/next-appointments.service';
import {
  getAvailabilityCredentialsEnd,
  getSlAndContainerTypeByContainerIdEnd,
} from 'services/clients/availability';
import { SegmentService } from 'services/helpers/segment';
import Container from 'layouts/Containers/Container';
import Legend from './Components/Legend/Legend';
export default class Appointments extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    shippingLines: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    terminals: PropTypes.array,
    containerTypes: PropTypes.array,
    importData: PropTypes.func.isRequired,
    getAppointmentsAvalabilityCredentials: PropTypes.func.isRequired,
    getAvailabilities: PropTypes.func.isRequired,
    availabilities: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    resetFunctionalParsers: PropTypes.func.isRequired,
    availabilityCredentials: PropTypes.object.isRequired,
    getAvailabilityAppointmentsV2: PropTypes.func,
    clearAvailabilityCredentials: PropTypes.func,
    history: PropTypes.object,
    refreshToken: PropTypes.bool,
    startLoadingOnDownload: PropTypes.func.isRequired,
    endLoadingOnDownload: PropTypes.func.isRequired,
    getEaScreenshotPDF: PropTypes.func.isRequired,
    getScreenshotsBySL: PropTypes.func.isRequired,
    sendScreenshotViaEmail: PropTypes.func.isRequired,
    closeLegend: PropTypes.func.isRequired,
    setDownloadWarning: PropTypes.func.isRequired,
    startLoadingOnContainerNumberGet: PropTypes.func.isRequired,
    endLoadingOnContainerNumberGet: PropTypes.func.isRequired,
    getRecipients: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDownloadInProgress: false,
      isModalOpened: false,
      isBookAppointmentModalData: null,
      isFilterMenuOpen: false,
      shippingLine: '',
      containerType: '',
      port: '',
      shift: {},
      shippingLinesToTerminals: [],
      selectedSL: this.getShippingLine(
        LocalStorageUtil.get(LocalStorageKeys.shippingLineKey)
      ),
      selectedContainer: this.getContainerType(
        LocalStorageUtil.get(LocalStorageKeys.containerKey)
      ),
      currentTime: this.getCurrentDate(),
      isStopTheClockModalOpen: false,
      stopTheClockFeedbackMsg: '',
      isAppointmentsLocked: false,
      isLegendOpen: false,
    };
    this.availabilityTimerId = null;
    this.GAtimer = true;
    this.containerNumberInput = '';
    this.containerNumberWasSuccessfullySubmitted = false;
  }

  handleContainerNumberChange = value => {
    this.containerNumberInput = value;
    this.containerNumberWasSuccessfullySubmitted = false;
  };

  initEmptyAppointments = () => {
    const { shippingLines, containerTypes } = this.props;
    setTimeout(() => {
      this.GAtimer = false;
    }, GAsearchDelay);

    this.props.getAppointmentsAvalabilityCredentials();

    this.props.importData();

    this.availabilityTimerId = setInterval(() => {
      this.getAvailabilities();
      this.props.getAppointmentsAvalabilityCredentials();
    }, GET_EMPTY_APPOINTMENTS_INTERVAL);

    if (
      shippingLines &&
      shippingLines.length &&
      containerTypes &&
      containerTypes.length
    ) {
      const defaultShippingLine = this.getShippingLine(
        LocalStorageUtil.get(LocalStorageKeys.shippingLineKey)
      );
      const defaultContainer = this.getContainerType(
        LocalStorageUtil.get(LocalStorageKeys.containerKey)
      );

      if (defaultShippingLine && defaultContainer) {
        this.setState(
          {
            selectedSL: defaultShippingLine,
            selectedContainer: defaultContainer,
          },
          () => this.getAvailabilities()
        );
      }
    }

    this.timerFunctionalParsers = this.props.resetFunctionalParsers();

    this.timerCurrentDate = setInterval(() => {
      this.setState({ currentTime: this.getCurrentDate() });
    }, GET_EMPTY_APPOINTMENTS_INTERVAL);
  };

  componentDidMount() {
    const { user } = this.props;

    const { permissions } = user || {};
    const { access_empty_appointments_search } = permissions || {};

    if (!access_empty_appointments_search) {
      this.setState({ isAppointmentsLocked: true });
    } else {
      this.initEmptyAppointments();
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      shippingLines,
      containerTypes,
      refreshToken,
      availabilityCredentials,
    } = this.props;

    if (
      !_.isEmpty(availabilityCredentials) &&
      !_.isEqual(prevProps.availabilityCredentials, availabilityCredentials)
    ) {
      const wasCredentialsEntered = NextAppointmentsService.checkWasCredentialsEntered(
        availabilityCredentials
      );
      this.setState({ isModalOpened: !wasCredentialsEntered });
    }

    if (prevProps.refreshToken !== refreshToken) {
      this.props.getAppointmentsAvalabilityCredentials();
      this.props.importData();
      this.getAvailabilities();
    }

    if (
      !_.isEqual(shippingLines, prevProps.shippingLines) &&
      shippingLines.length
    ) {
      const defaultShippingLine = this.getShippingLine(
        LocalStorageUtil.get(LocalStorageKeys.shippingLineKey)
      );
      if (defaultShippingLine) {
        this.setState({ selectedSL: defaultShippingLine }, () =>
          this.getAvailabilities()
        );
      }
    }

    if (
      !_.isEqual(containerTypes, prevProps.containerTypes) &&
      containerTypes.length
    ) {
      const defaultContainer = this.getContainerType(
        LocalStorageUtil.get(LocalStorageKeys.containerKey)
      );
      if (defaultContainer) {
        this.setState({ selectedContainer: defaultContainer }, () =>
          this.getAvailabilities()
        );
      }
    }
  }

  getContainerType = key => {
    const { containerTypes } = this.props;
    let containerType = null;
    if (containerTypes && containerTypes.length) {
      const containerTypeKey = key || `20'`;
      containerType = containerTypes.find(
        container => container.key === containerTypeKey
      );
    }
    return containerType;
  };

  getShippingLine = key => {
    const { shippingLines } = this.props;
    let shippingLine = null;
    if (shippingLines && shippingLines.length) {
      const shippingLineKey = key || 'CMA';
      shippingLine = shippingLines.find(sl => sl.key === shippingLineKey);
    }
    return shippingLine;
  };

  getCurrentDate = () => {
    return moment()
      .tz('America/Los_Angeles')
      .format('dddd, MMMM DD, hh:mm A z');
  };

  getAvailabilities = isManualSearch => {
    const { selectedContainer, selectedSL } = this.state;
    const shipping_lines = selectedSL ? [selectedSL] : [];
    const container_types = selectedContainer ? [selectedContainer] : [];
    if (shipping_lines.length && container_types.length) {
      const [shipping_line] = shipping_lines;
      const [container] = container_types;
      const { key: line } = shipping_line || {};
      const { key: container_type } = container || {};
      this.props.getAvailabilityAppointmentsV2(line, container_type);
      this.props.getAvailabilities(shipping_lines, container_types);

      if (!this.GAtimer && isManualSearch) {
        const { user } = this.props;
        NextAppointmentsService.fireGAsearchEvent(
          user,
          shipping_lines,
          container_types
        );
        SegmentService.trackEvent(
          'Empty-appointment Searched',
          {
            shippingLine: shipping_lines[0].key,
            containerType: container_types[0].key,
          },
          { integrations: { Slack: false } }
        );
      }
    }
  };

  onShippingLineChange = (event, newValue) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ selectedSL: newValue }, () => {
      if (newValue && newValue.key) {
        LocalStorageUtil.set(LocalStorageKeys.shippingLineKey, newValue.key);
      } else {
        LocalStorageUtil.remove(LocalStorageKeys.shippingLineKey);
      }
      this.getAvailabilities(true);
    });
  };

  onContainerChange = (event, newValue) => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ selectedContainer: newValue }, () => {
      if (newValue && newValue.key) {
        LocalStorageUtil.set(LocalStorageKeys.containerKey, newValue.key);
      } else {
        LocalStorageUtil.remove(LocalStorageKeys.containerKey);
      }
      this.getAvailabilities(true);
    });
  };

  handleStopTheClockModalOpen = () => {
    this.setState({ isStopTheClockModalOpen: true });
    const { selectedSL, selectedContainer } = this.state;
    const { key: shippingLineKey } = selectedSL || {};
    const { key: containerTypeKey } = selectedContainer || {};
    SegmentService.trackEvent(
      'Stop-the-clock-button Clicked',
      {
        shippingLine: shippingLineKey,
        containerType: containerTypeKey,
      },
      { integrations: { Slack: false } }
    );
  };

  handleStopTheClockModalClose = () => {
    this.setState({ isStopTheClockModalOpen: false });
  };

  handleGetReportClick = (
    isDownloadReport,
    isSendCopy,
    containersInput,
    selectedTeamMembers
  ) => {
    this.getScreenshot(
      isDownloadReport,
      isSendCopy,
      containersInput,
      selectedTeamMembers
    );
    this.setState({ isStopTheClockModalOpen: false });
  };

  handleGetStartedClick = async () => {
    const { history, clearAvailabilityCredentials } = this.props;
    const creds = await getAvailabilityCredentialsEnd();
    const wasCredentialsEntered = NextAppointmentsService.checkWasCredentialsEntered(
      creds
    );
    if (wasCredentialsEntered) {
      this.setState({ isModalOpened: false });
    } else {
      clearAvailabilityCredentials();
      history.push('/credentials');
    }
  };

  getTerminalByShippingLine = () => {
    const { availabilities, terminals } = this.props;
    var sl = this.state.selectedSL;
    if (!sl || !terminals) return [];

    const map = availabilities.mappingShippingLine
      .filter(item => item.shipping_line === sl.key)
      .map(i => i.port);
    const filteredTerminals = terminals.filter(item => map.includes(item.key));

    const filteredTerminalsBySL = filteredTerminals.filter(terminal => {
      const appointmentsByTerminal =
        availabilities &&
        availabilities.availableAppointment &&
        availabilities.availableAppointment[terminal.key];
      return appointmentsByTerminal && appointmentsByTerminal[sl.key];
    });

    return filteredTerminalsBySL;
  };

  showLastRefresh = () => {
    const { user, availabilities, availabilityCredentials } = this.props;
    const { selectedSL } = this.state;
    if (!selectedSL) return;
    return (
      <LastRefresh
        user={user}
        terminals={this.getTerminalByShippingLine()}
        displayShift={false}
        selectedSlKey={selectedSL.key}
        lastRefreshData={availabilities.availableAppointment}
        availabilityCredentials={availabilityCredentials}
      />
    );
  };

  renderTableHeader = () => {
    const { selectedSL, selectedContainer, currentTime } = this.state;
    const { terminals } = this.props;
    const terminalsList = terminals && Object.values(terminals).flat();
    return selectedSL && selectedSL.key ? (
      <TableHeader
        terminalsCount={terminalsList && terminalsList.length}
        displayShift={false}
        terminalMap={this.getTerminalByShippingLine()}
        selectedSL={selectedSL}
        selectedContainer={selectedContainer}
        currentTime={currentTime}
      />
    ) : null;
  };

  openBookAppointmentModal = cellData => {
    const { availabilities } = this.props;
    const { selectedSL, selectedContainer } = this.state;
    const { key } = selectedSL || {};
    const { key: selectedContainerKey } = selectedContainer || {};
    const { terminalKey } = cellData || {};
    const { availableAppointment, data } = availabilities || {};
    let availabilityBySlAndContainerType = null;
    let instructionsBySlAndContainerType = null;
    if (terminalKey && key && selectedContainerKey) {
      availabilityBySlAndContainerType =
        availableAppointment[terminalKey][key][selectedContainerKey];
      instructionsBySlAndContainerType =
        data[key][selectedContainerKey][terminalKey];
    }
    const { availability } = availabilityBySlAndContainerType || {};
    this.setState({
      isBookAppointmentModalData: {
        terminal: cellData,
        availability,
        selectedContainer,
        selectedSL,
        instructionsBySlAndContainerType,
        containerNumberInput: this.containerNumberInput,
      },
    });
  };

  bookAppointmentModalClose = () => {
    this.setState({ isBookAppointmentModalData: null });
  };

  showNextAppointments = () => {
    const {
      user,
      history,
      availabilities,
      availabilityCredentials,
    } = this.props;
    const { selectedSL, selectedContainer } = this.state;
    if (!selectedSL) return;
    return (
      <NextAppointments
        user={user}
        history={history}
        availabilities={availabilities}
        terminals={this.getTerminalByShippingLine()}
        currentSLKey={selectedSL ? selectedSL.key : ''}
        currentContainerKey={selectedContainer ? selectedContainer.key : ''}
        availabilityCredentials={availabilityCredentials}
        onOpenBookAppointmentModal={this.openBookAppointmentModal}
      />
    );
  };

  handleOpenLegend = () => {
    this.setState({ isLegendOpen: true });
  };

  handleCloseLegend = () => {
    this.setState({ isLegendOpen: false });
  };

  showLegend = () => {
    const { isLegendOpen } = this.state;
    return (
      <Legend
        isLegendOpen={isLegendOpen}
        handleOpenLegend={() => this.setState({ isLegendOpen: true })}
        handleCloseLegend={() => this.setState({ isLegendOpen: false })}
      />
    );
  };

  showStopTheClockFeedbackMsg = msg => {
    this.setState({ stopTheClockFeedbackMsg: msg });
    setTimeout(() => this.setState({ stopTheClockFeedbackMsg: '' }), 2000);
  };

  getScreenshot = (
    isDownloadReport,
    isSendCopy,
    containersInput,
    selectedTeamMembers
  ) => {
    const {
      user,
      isLoading,
      closeLegend,
      setDownloadWarning,
      getEaScreenshotPDF,
      getScreenshotsBySL,
      startLoadingOnDownload,
      endLoadingOnDownload,
    } = this.props;
    const { selectedSL, selectedContainer, isDownloadInProgress } = this.state;
    const { key: shippingLineKey, name: shippingLineName } = selectedSL || {};
    const { name: containerTypeName } = selectedContainer || {};
    if (isDownloadInProgress || isLoading) return;
    closeLegend();
    const isPerDiemFighterOrFreeTrial = NextAppointmentsService.checkIsPerDiemFighterOrFreeTrial(
      user
    );
    if (!isPerDiemFighterOrFreeTrial) {
      setDownloadWarning(stopClockWarningMessage);
      return;
    }
    NextAppointmentsService.fireGADownloadEvent(
      selectedSL.key,
      selectedContainer.key,
      user,
      containersInput,
      selectedTeamMembers,
      isDownloadReport,
      isSendCopy
    );
    SegmentService.trackEvent(
      'Get Report Button Clicked',
      {
        shippingLine: selectedSL.key,
        containerType: selectedContainer.key,
        containersAdded: containersInput,
        selectedTeamMembers: selectedTeamMembers,
        numberOfEmails: selectedTeamMembers.length,
        isDownloadReport: isDownloadReport,
        isSendCopy: isSendCopy,
      },
      { integrations: { Slack: false } }
    );

    this.setState({ isDownloadInProgress: true });
    startLoadingOnDownload();
    getScreenshotsBySL(selectedSL.key, data => {
      const screenshotKeys = data
        .filter(terminal => terminal && terminal.key)
        .map(terminal => terminal.key);
      const targetDiv = document.querySelector('#capture');
      html2canvas(targetDiv, {
        useCORS: true,
        allowTaint: true,
        width: 1400,
        height: 2100,
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const requestBody = {
          screenshotKeys,
          isEmptyAppointmentsTableImg: imgData,
          containersInput,
          metaData: {
            dateTime: moment()
              .tz('America/Los_Angeles')
              .format('YYYY-MM-DD hh:mm A'),
            size: containerTypeName,
            containerNumber: this.containerNumberInput,
            shippingLine: shippingLineName,
          },
          download: isDownloadReport,
          email: isSendCopy,
          emails: selectedTeamMembers,
        };
        const fileNameData = {
          shippingLineKey,
          containerTypeName,
          containerNumber: this.containerNumberInput,
        };
        getEaScreenshotPDF(requestBody, fileNameData, error => {
          this.setState({ isDownloadInProgress: false });
          endLoadingOnDownload();
          if (error) return;
          let feedbackMsg;
          if (isDownloadReport && isSendCopy) {
            feedbackMsg = 'Report succesfully downloaded and sent by email!';
          } else if (isDownloadReport) {
            feedbackMsg = 'Report succesfully downloaded!';
          } else if (isSendCopy) {
            feedbackMsg = 'Report successfully sent by email!';
          } else {
            feedbackMsg = '';
          }
          this.showStopTheClockFeedbackMsg(feedbackMsg);
        });
      });
    });
  };

  handleOnSubmitContainerClick = async (container_id, callback) => {
    const {
      startLoadingOnContainerNumberGet,
      endLoadingOnContainerNumberGet,
    } = this.props;
    try {
      startLoadingOnContainerNumberGet();
      const data = await getSlAndContainerTypeByContainerIdEnd(container_id);
      const { shippingLineCode, shippingLineName } = data || {};
      const shippingLineValue = {
        emails: null,
        key: shippingLineCode,
        name: shippingLineName,
        phone_numbers: null,
        websites: null,
      };

      setTimeout(() => {
        this.containerNumberWasSuccessfullySubmitted = true;
      }, 500);

      this.onShippingLineChange(null, shippingLineValue);
      this.onContainerChange(null, null);
    } catch (error) {
      const { response } = error || {};
      const { status } = response || {};

      if (status === 404) {
        this.onShippingLineChange(null, null);
        this.onContainerChange(null, null);
      }

      const msg = NextAppointmentsService.defineContainerNumberErrorMsg(status);
      callback(msg);
      // callback(detail);
    } finally {
      endLoadingOnContainerNumberGet();
    }
  };

  render() {
    const {
      classes,
      isLoading,
      shippingLines,
      containerTypes,
      history,
      user,
      getRecipients,
    } = this.props;

    const { email, teamMembers } = user || {};

    const {
      selectedSL,
      isModalOpened,
      selectedContainer,
      isBookAppointmentModalData,
      isDownloadInProgress,
      isStopTheClockModalOpen,
      stopTheClockFeedbackMsg,
    } = this.state;

    const classNames = {
      root: classes.shippingLinesDropDown,
      inputRoot: classes.inputWrapper,
      clearIndicator: classes.clearIndicator,
      popupIndicator: classes.popupIndicator,
      endAdornment: classes.endAdornment,
      groupLabel: classes.groupLabel,
    };

    return (
      <>
        <div>
          {isModalOpened && (
            <AppointmentsModal
              classes={classes}
              onGetStartedClick={this.handleGetStartedClick}
            />
          )}
          {isBookAppointmentModalData && (
            <BookAppointmentModal
              user={user}
              appointmentData={isBookAppointmentModalData}
              onModalClose={this.bookAppointmentModalClose}
              history={history}
              classes={{
                ...classNames,
                input: selectedSL ? classes.inputSelected : classes.inputClear,
              }}
            />
          )}
          {isStopTheClockModalOpen && (
            <StopTheClockModal
              email={email}
              teamMembers={teamMembers}
              getRecipients={getRecipients}
              onHandleGetReportClick={this.handleGetReportClick}
              onHandleStopTheClockModalClose={this.handleStopTheClockModalClose}
            />
          )}
          {stopTheClockFeedbackMsg && (
            <StopTheClockFeedbackMsg
              stopTheClockFeedbackMsg={stopTheClockFeedbackMsg}
            />
          )}
          {isLoading && (
            <div className={classes.loaderWrapper}>
              <CircularProgress size={70} />
            </div>
          )}
          <Filters
            classes={classes}
            isLoading={isLoading}
            selectedSL={selectedSL}
            classNames={classNames}
            shippingLines={shippingLines}
            containerTypes={containerTypes}
            selectedContainer={selectedContainer}
            onContainerChange={this.onContainerChange}
            onShippingLineChange={this.onShippingLineChange}
            isDownloadInProgress={isDownloadInProgress}
            onHandleStopTheClockModalOpen={this.handleStopTheClockModalOpen}
          />
          {selectedSL && this.state.selectedContainer && (
            <Container id="capture" bg="bg-white">
              <div className="ml-8">
                {this.renderTableHeader()}
                {this.showLastRefresh()}
                {this.showNextAppointments()}
              </div>
              {this.showLegend()}
            </Container>
          )}
        </div>
      </>
    );
  }

  componentWillUnmount() {
    const { clearAvailabilityCredentials } = this.props;
    clearAvailabilityCredentials();
    clearInterval(this.timerFunctionalParsers);
    clearInterval(this.timerCurrentDate);
    clearInterval(this.availabilityTimerId);
  }
}
