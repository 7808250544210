import React from 'react';
import _ from 'lodash';
import axiosWithToken from 'services/clients/axios-with-token';
import ContainerTrackingComponent from './ContainerTrackingComponent';
import { fetchContainers } from './utils/fetch-containers';
import {
  getUrlSearchParams,
  setUrlSearchParams,
  clearSearchParams,
  objectKeysToSnakeCase,
  joinArrayParams,
  objectKeysToCamelCase,
} from 'utils/common';
import { parseContainerNumbers } from '../../services/helpers/containers';
import { SegmentService } from 'services/helpers/segment';
import {
  REFRESH_INTERVAL_MS,
  DEFAULT_PAGE_SIZE,
  CONTAINER_SEARCH_LIMIT,
  REFRESH_APPOINTMENTS_INTERVAL_MS,
} from './container-config';
import { getFilterCount } from './components/ContainerFilters';
import PerDiemDownloadModal from '../../components/PerDiemDownloadModal';
import AllEmptyAppointmentsModal from 'components/AllEmptyAppointmentsModal';
import { terminals } from 'mocks/terminals';
import { Alert, Snackbar } from '@mui/material';
import pluralize from 'pluralize';

const AbortController = window.AbortController;

class ContainerTrackingPage extends React.Component {
  state = {
    containerList: [],
    loading: true,
    error: null,
    containerListRowCount: 0,
    warningText: null,
    initialLoad: false,
    selectionModel: [],
    isFilterOpen: false,
    containerTypes: [],
    shippingLines: [],
    terminals: [],
    selectedFilters: {},
    filterCount: 0,
    isPerDiemModalOpen: false,
    totalContainerWatched: 0,
    filterTotals: {},
    perDiemModalParameters: {
      teamMemberEmails: [],
      containers: [],
    },
    isAllAppointmentsModalOpen: false,
    modalInformation: {},

    snackbardInfo: {
      isOpen: false,
      text: '',
      severity: 'success',
    },
  };

  constructor(props) {
    super(props);
    this.containerNumbers = [];
  }

  async componentDidMount() {
    let shippingLines;
    let containerTypes;
    let terminals;
    try {
      const shippingLineResponse = await axiosWithToken.get(
        '/core/api/v1/erl/shipping_line'
      );
      shippingLines = shippingLineResponse.data;
      const containerTypesResponse = await axiosWithToken.get(
        '/core/api/v1/erl/container_type'
      );
      containerTypes = containerTypesResponse.data;
      const terminalResponse = await axiosWithToken.get(
        '/core/api/v1/erl/port'
      );
      terminals = terminalResponse.data;
    } catch (error) {
      console.log({ error });
      this.setState({ loading: false, error });
      return;
    }

    const {
      page,
      pageSize,
      containerNumbers,
      ...selectedFilters
    } = getUrlSearchParams({
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    this.containerNumbers = parseContainerNumbers(containerNumbers);

    this.searchContainers();
    this.setState({
      containerTypes,
      shippingLines,
      terminals,
      selectedFilters,
      filterCount: getFilterCount(selectedFilters),
    });

    this.interval = window.setInterval(
      async function refreshInterfal() {
        if (this.fetching || this.props.selectedContainerNumber) return;

        await this.searchContainers(true);
        this.triggerAppointments();
      }.bind(this),
      REFRESH_INTERVAL_MS
    );
    const { data: users } = await axiosWithToken.get(
      '/core/api/v1/auth/company/members'
    );
    this.setState({
      perDiemModalParameters: {
        ...this.state.perDiemModalParameters,
        teamMemberEmails: users.map(row => row.email),
      },
    });

    this.appointmentsInterval = window.setInterval(
      async function refreshAppointmentsInterfal() {
        if (this.fetchingAppointments) return;

        this.triggerAppointments();
      }.bind(this),
      REFRESH_APPOINTMENTS_INTERVAL_MS
    );
    this.triggerAppointments();
  }

  async triggerAppointments() {
    this.fetchingAppointments = true;

    try {
      await axiosWithToken.post(
        '/core/api/v1/tas/appointment-bookings/trigger'
      );
      this.fetchingAppointments = false;
    } catch (error) {
      console.error(`Error posting to appointment-bookings/trigger`, error);
    }
  }

  async componentWillUnmount() {
    window.clearInterval(this.interval);
    window.clearInterval(this.appointmentsInterval);
    this.cancelRequests();
  }

  cancelRequests = () => {
    if (this.abortController) {
      this.abortController.abort();
    }
  };

  createAbortController = () => {
    return AbortController ? new AbortController() : undefined;
  };

  translateUrlParams(urlParams) {
    const translateMap = {
      terminals: 'terminal_pickup_keys',
    };
    return _.reduce(
      urlParams,
      (newObj, value, key) => {
        newObj[translateMap[key] || key] = value;
        return newObj;
      },
      {}
    );
  }

  searchContainers = async (fromInterval = false) => {
    this.cancelRequests();
    this.abortController = this.createAbortController();
    this.fetching = true;

    if (!fromInterval) {
      this.setState({ loading: true });
    }

    if (this.containerNumbers.length > CONTAINER_SEARCH_LIMIT) {
      this.fetching = false;
      this.setState({
        warningText: `Please enter ${CONTAINER_SEARCH_LIMIT} or less container numbers.`,
      });
      return;
    }
    const { containerNumbers, ...params } = this.translateUrlParams(
      getUrlSearchParams({ page: 1, pageSize: DEFAULT_PAGE_SIZE })
    );

    try {
      const {
        noCapacityContainers,
        containerList,
        containerListRowCount,
        totalContainerWatched,
      } = await fetchContainers(
        params,
        this.containerNumbers,
        this.abortController && this.abortController.signal
      );

      if (containerList.length > 0) {
        this.fetchPerDiemReportStatuses(
          containerList,
          this.abortController && this.abortController.signal
        );
      }

      const filterTotals = await fetchFilterTotals(
        params,
        this.containerNumbers,
        this.abortController && this.abortController.signal
      );

      // Reconcile isPerDiemGenerating to avoid glitch in the UI
      const { containerList: oldContainerList } = this.state;
      containerList.forEach((c, idx) => {
        const oldContainer = oldContainerList.find(
          oc => oc.containerNumber === c.containerNumber
        );
        if (oldContainer) {
          c.isPerDiemGenerating = oldContainer.isPerDiemGenerating;
        }
      });

      const noCapacityContainersLength = noCapacityContainers.length;
      const error =
        noCapacityContainersLength > 0
          ? `${noCapacityContainersLength} ${pluralize(
              'container',
              noCapacityContainersLength
            )} not added. You reached your container limit.`
          : null;
      if (noCapacityContainersLength > 0 && !fromInterval) {
        this.setState({
          snackbardInfo: {
            isOpen: true,
            severity: 'error',
            text: `${noCapacityContainersLength} ${pluralize(
              'container',
              noCapacityContainersLength
            )} not added. You reached your container limit.`,
          },
        });
        this.props.fetchUser();
      }

      this.setState({
        containerList,
        containerListRowCount,
        totalContainerWatched,
        filterTotals,
        loading: false,
        error,
      });
    } catch (error) {
      if (
        error.message &&
        (error.message.includes('canceled') ||
          error.message.includes('aborted'))
      ) {
        this.fetching = false;
        return;
      }
      // If we see this error from a background fetch and not a user-action fetch,
      // do not show it. Instead, show the existing error or no error at all.
      const currentError = this.state.error || null;
      const errorToSet = fromInterval ? currentError : error;
      this.setState({ error: errorToSet, loading: false });
    }
    this.fetching = false;
  };

  async fetchPerDiemReportStatuses(containerList, signal = null) {
    const params = {
      containerNumbers: containerList.map(c => c.containerNumber),
    };
    const promise = axiosWithToken.get(
      '/core/api/v1/erl/per_diem_report_range_status',
      {
        params: _.mapValues(objectKeysToSnakeCase(params), joinArrayParams),
        signal,
      }
    );

    try {
      const {
        data: { results },
      } = await promise;
      containerList.forEach((c, idx) => {
        let perDiemStatus = results.find(
          e => e.container_number === c.containerNumber
        );
        c.isPerDiemGenerating = perDiemStatus.generating;
      });
      this.setState({
        containerList,
      });
    } catch {}
  }

  handleContainerInputChange = async (inputContainerNumbers = []) => {
    const oldContainerNumbers = [...this.containerNumbers];
    await this.setState({
      warningText: null,
    });
    this.containerNumbers = parseContainerNumbers(inputContainerNumbers);
    setUrlSearchParams({
      containerNumbers: this.containerNumbers,
    });

    if (inputContainerNumbers) {
      SegmentService.trackEvent(
        'Container Searched',
        {
          // TODO: Do this in a way that the trackEvent function doesn't have to run the parse function and know so much.
          numberOfContainerSearched: this.containerNumbers.length,
        },
        { integrations: { Slack: false } }
      );
    }
    if (
      this.containerNumbers.length === 0 &&
      inputContainerNumbers.length > 0
    ) {
      this.fetching = false;
      this.setState({ warningText: 'Please enter only container numbers' });
      return;
    }
    if (
      _.isEqual(_.sortBy(oldContainerNumbers), _.sortBy(this.containerNumbers))
    ) {
      return;
    }
    this.setState({
      containerList: [],
      loading: true,
    });
    this.removeAllFilters();
  };

  handlePageChange = async pageNumber => {
    if (pageNumber === undefined) {
      return;
    }
    this.setState({ containerList: [], loading: true });
    // DataGrid / XGrid expect a 0 start page,
    // DRF (and our URL state) expect 1
    setUrlSearchParams({ page: pageNumber + 1 });
    this.searchContainers();
  };

  handlePageSizeChange = async pageSize => {
    this.setState({ containerList: [], loading: true });
    setUrlSearchParams({ pageSize: pageSize });
    this.searchContainers();
  };

  handleOnFilterSelection = async filters => {
    this.setState({
      loading: true,
      warningText: null,
      selectedFilters: filters,
      filterCount: getFilterCount(filters),
      containerList: [],
    });
    SegmentService.trackEvent(
      'Container Filter Selected',
      {
        selectedFilter: filters,
      },
      { Slack: false }
    );
    setUrlSearchParams(
      {
        page: 1,
        containerNumbers: this.containerNumbers,
        ...filters,
      },
      true
    );
    this.searchContainers();
  };

  onDeleteClick = async selectionModel => {
    await updateContainerWatchList({
      unwatchContainerNumbers: selectionModel,
    });
    const nbContainers = selectionModel.length;
    this.setState({
      snackbardInfo: {
        isOpen: true,
        severity: 'success',
        text: `${nbContainers} ${pluralize(
          'container',
          nbContainers
        )} archived.`,
      },
    });
    this.removeAllFilters();
  };

  handleOnExport = async (selectionModel, mode) => {
    const { containerListRowCount } = this.state;
    const { pageSize, page, ...params } = getUrlSearchParams({
      containerNumbers: [],
      sortBy: 'per_diem',
    });

    if (mode === 'selection') {
      params.containerNumbers = selectionModel || [];
      params.sortBy = 'container_numbers_input';
    }

    const nbContainers =
      params.containerNumbers.length || containerListRowCount;
    this.setState({
      snackbardInfo: {
        isOpen: true,
        severity: 'info',
        text: `Processing... ${nbContainers} ${pluralize(
          'container',
          nbContainers
        )} ${pluralize('is', nbContainers)} exporting.`,
      },
    });

    const { data } = await axiosWithToken.post(
      '/core/api/v1/containers/watch-list/export',
      objectKeysToSnakeCase(params)
    );
    window.open(data.s3_file);
  };

  toggleDrawer = open => {
    this.setState({ isFilterOpen: open });
  };

  removeAllFilters = () => {
    clearSearchParams(null, ['containerNumbers[]']);

    this.setState({
      selectedFilters: {},
      filterCount: 0,
      containerList: [],
      loading: true,
    });
    this.searchContainers();
  };

  handleOnGetPerDiemReport = containerNumber => {
    this.setState({
      isPerDiemModalOpen: true,
      perDiemModalParameters: {
        ...this.state.perDiemModalParameters,
        containers: [
          this.state.containerList.find(
            c => c.containerNumber === containerNumber
          ),
        ],
      },
    });
  };

  openAllAppointmentsModal = (
    e,
    size,
    status,
    shippingLine,
    containerNumber,
    pickupImportStatus,
    appointments
  ) => {
    e.preventDefault();
    e.stopPropagation();

    SegmentService.trackEvent(
      'All Appointments Modal Opened',
      {
        size,
        status,
        shippingLine,
        containerNumber,
      },
      { integrations: { Slack: false } }
    );

    this.setState({
      modalInformation: {
        size,
        status,
        shippingLine,
        containerNumber,
        pickupImportStatus,
        appointments,
      },
      isAllAppointmentsModalOpen: true,
    });
  };

  handlePerDiemCalled = modalContainerNumbers => {
    const { containerList } = this.state;
    containerList.forEach((c, idx) => {
      if (modalContainerNumbers.includes(c.containerNumber)) {
        c.isPerDiemGenerating = true;
      }
    });
    this.setState({
      containerList,
    });
  };

  render() {
    const {
      containerList,
      warningText,
      loading,
      error,
      containerListRowCount,
      isFilterOpen,
      containerTypes,
      shippingLines,
      selectedFilters,
      filterCount,
      isPerDiemModalOpen,
      perDiemModalParameters,
      isAllAppointmentsModalOpen,
      modalInformation,
      filterTotals,
      totalContainerWatched,
      snackbardInfo,
    } = this.state;

    const { pageSize, page } = getUrlSearchParams({
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    const { currentUser, pinnedColumns = true } = this.props;
    return (
      <>
        <AllEmptyAppointmentsModal
          open={isAllAppointmentsModalOpen}
          onClose={() => this.setState({ isAllAppointmentsModalOpen: false })}
          modalInformation={modalInformation}
        />

        <ContainerTrackingComponent
          containerList={containerList}
          handleContainerInputChange={this.handleContainerInputChange}
          containerListRowCount={containerListRowCount}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          pageSize={parseInt(pageSize)}
          // DataGrid / XGrid expect a 0 start page,
          // DRF (and our URL state) expect 1
          page={page - 1}
          loading={loading}
          warningText={warningText}
          selectedFilters={selectedFilters}
          filterCount={filterCount}
          onFilterSelection={this.handleOnFilterSelection}
          pinnedColumns={pinnedColumns}
          onDeleteClick={this.onDeleteClick}
          toggleDrawer={this.toggleDrawer}
          isFilterOpen={isFilterOpen}
          containerTypes={containerTypes}
          shippingLines={shippingLines}
          removeAllFilters={this.removeAllFilters}
          openAllAppointmentsModal={this.openAllAppointmentsModal}
          error={error}
          onGetPerDiemReport={this.handleOnGetPerDiemReport}
          onExport={this.handleOnExport}
          currentUser={currentUser}
          terminals={terminals}
          filterTotals={filterTotals}
          totalContainerWatched={totalContainerWatched}
        />
        <PerDiemDownloadModal
          isOpen={isPerDiemModalOpen}
          onSubmit={this.handlePerDiemCalled}
          currentUser={currentUser}
          emails={perDiemModalParameters.teamMemberEmails}
          handleOnClose={() => this.setState({ isPerDiemModalOpen: false })}
          containers={perDiemModalParameters.containers}
          modalType="containerTracking"
        />
        {/* call useSnackbar hook once we use function component */}
        <Snackbar
          open={snackbardInfo.isOpen}
          onClose={() => this.setState({ snackbardInfo: { isOpen: false } })}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            icon={false}
            severity={snackbardInfo.severity}
            onClose={() => this.setState({ snackbardInfo: { isOpen: false } })}
          >
            {snackbardInfo.text}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

async function fetchFilterTotals(
  params = {},
  containerNumbers = [],
  signal = null
) {
  const promise = axiosWithToken.post(
    '/core/api/v1/containers/watch-list/count',
    {
      container_numbers: containerNumbers,
    },
    {
      params: _.mapValues(objectKeysToSnakeCase(params), joinArrayParams),
      signal,
    }
  );

  const { data } = await promise;
  return objectKeysToCamelCase(data);
}

async function updateContainerWatchList(params = {}, signal = null) {
  const promise = axiosWithToken.put(
    '/core/api/v1/containers/watch-list',
    objectKeysToSnakeCase(params),
    {
      signal,
    }
  );
  await promise;
}

export default ContainerTrackingPage;
