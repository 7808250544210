import moment from 'moment';
import { pageTitlesAndUrls } from '../../constants/pages';
import { timeList } from './list-of-times';

const DEFAULT_TIME_STRING = '08:00';
const DEFAULT_TIME = timeList.find(time => time.key === DEFAULT_TIME_STRING);
const DEFAULT_SHIPPING_LINE_KEY = 'MSC';
const DEFAULT_CONTAINER_TYPE_KEY = "20'";
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const TERMINAL_SCREENSHOTS_TIMERANGE_LIMIT_DAYS = 7;
export const PER_DIEM_RANGE_LIMIT_DAYS = 10;

export const sideNavigationContent = [
  {
    key: 'perDiemArchive',
    title: 'Per Diem Archive',
    sideNavigationLinks: [
      {
        key: 'perDiemReports',
        url: `${pageTitlesAndUrls.perDiemFighter.path}`,
        text: 'Per Diem Reports',
      },
      {
        key: 'screenshots',
        url: `${pageTitlesAndUrls.perDiemFighter.path}/terminal-screenshots`,
        text: 'terminal screenshots',
      },
    ],
  },
];

export const sideNavigationLinks = [
  {
    url: `${pageTitlesAndUrls.perDiemFighter.path}`,
    text: 'Per Diem Reports',
  },
  {
    url: `${pageTitlesAndUrls.perDiemFighter.path}/terminal-screenshots`,
    text: 'terminal screenshots',
  },
];

export function getDefaultSelections(shippingLines = [], containerTypes = []) {
  const selectedShippingLine = shippingLines.find(
    shippingLine => shippingLine.key === DEFAULT_SHIPPING_LINE_KEY
  );
  const selectedShippingLineValue = selectedShippingLine
    ? selectedShippingLine.key
    : '';
  const selectedContainerType = containerTypes.find(
    containerType => containerType.key === DEFAULT_CONTAINER_TYPE_KEY
  );
  const selectedContainerTypeValue = selectedContainerType
    ? selectedContainerType.key
    : '';
  const selectedStartDateValue = moment();
  const selectedTimeValue = DEFAULT_TIME.key;
  return {
    selectedShippingLineValue,
    selectedContainerTypeValue,
    selectedStartDateValue,
    selectedTimeValue,
  };
}
