export const terminalData = [
  {
    name: 'ssa_piera',
    key: 'ssa_piera',
    location: 'LB',
    logo:
      'https://media.licdn.com/dms/image/C560BAQGOmWEQtSDGJg/company-logo_200_200/0?e=2159024400&v=beta&t=_wyRU5R0hO2ekytJILA1YfzpcorzbGG0KHM24QK7OkM',
    website: 'https://piera.tideworks.com/fc-PA/default.do',
    pier: 'PIER A',
  },
  {
    name: 'ssa_pct',
    key: 'ssa_pct',
    location: 'LB',
    logo:
      'https://media.licdn.com/dms/image/C560BAQGOmWEQtSDGJg/company-logo_200_200/0?e=2159024400&v=beta&t=_wyRU5R0hO2ekytJILA1YfzpcorzbGG0KHM24QK7OkM',
    website: 'https://pct.tideworks.com/fc-PCT/default.do',
    pier: 'PCT',
  },
  {
    name: 'lbct',
    key: 'lbct',
    logo: 'https://www.lbct.com/Content/images/logo_LBCT_h.png',
    website: 'https://www.lbct.com/Operations/EmptyRestrictions',
    location: 'LB',
    pier: 'PIER E',
  },
  {
    name: 'ITS',
    key: 'its',
    logo: 'https://www.itslb.com/wp-content/uploads/2016/12/its-logo.gif',
    website: 'https://www.itslb.com/empty-receiving-updates/',
    location: 'LB',
    pier: 'PIER G',
  },
  {
    name: 'tti',
    key: 'tti',
    logo: 'http://www.polb.com/civica/news/inc/blobfetch.asp?BlobID=9230',
    website: 'http://www.ttilgb.com/main/index.do',
    location: 'LB',
    pier: 'PIER T',
  },

  {
    name: 'Yusen',
    key: 'yti',
    logo:
      'https://media.licdn.com/dms/image/C4E0BAQF25NVYdYD7BA/company-logo_200_200/0?e=2159024400&v=beta&t=Un55UyzrsP6m-uIWTQufdRPTK94DNF5I9QTp1VIXSbU',
    website: 'http://www.yti.com/',
    location: 'LA',
    pier: 'YTI',
  },
  {
    name: 'WBCT',
    key: 'wbct',
    logo:
      'https://voyagertrack.portsamerica.com/Content/SiteSpecific/WBCT_LA/Images/logo.gif?_v=1',
    website: 'https://voyagertrack.portsamerica.com/logon?siteId=WBCT_LA',
    location: 'LA',
    pier: 'WBCT',
  },
  {
    name: 'Trapac',
    key: 'trapac',
    logo:
      'https://kentico.portoflosangeles.org/getattachment/707d76ac-97f8-4b01-bbf9-2ba5335b9c0b/attachment.aspx',
    website: 'https://losangeles.trapac.com/empty-returns/',
    location: 'LA',
    pier: 'Trapac',
  },
  {
    name: 'fms',
    key: 'fms',
    logo:
      'https://www.fenixmarineservices.com/wp-content/uploads/2018/12/FENIX_Logo_HRZ_FullColor_Pantone.svg',
    website: 'https://www.fenixmarineservices.com/empty-receiving/',
    location: 'LA',
    pier: 'PIER 300',
  },
  {
    name: 'APM',
    key: 'apm',
    logo:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUjFPETSpm8khTOwN4uLP-NinkDAetsKwwXHoY1RhptLIE8KOC',
    website:
      'https://www.apmterminals.com/en/los-angeles/practical-information/empty-receivables-and-open-areas',
    location: 'LA',
    pier: 'PIER 400',
  },
  {
    name: 'Everport',
    key: 'everport',
    logo: 'https://www.etslink.com/pictures/home_page/Everport_home.png',
    website: 'https://www.etslink.com/',
    location: 'LA',
    pier: 'Everport',
  },
];
