import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from 'components/customDesign/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const company_natures = [
  'Drayage carrier',
  'Owner operators',
  'Distribution center',
  'Shipper',
  'Broker',
  '3rd party logistics',
  'Terminal operator',
  'Steamship line',
  'Freight forwarder',
  'Other',
];

const sizes = [
  { value: 100, label: '0-200' },
  { value: 500, label: '200-700' },
  { value: 1000, label: '700-1500' },
  { value: 1500, label: '> 1500' },
];
export default class CompanyForm extends Component {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    secondComer: PropTypes.bool.isRequired,
  };

  render() {
    const {
      classes,
      errors,
      touched,
      handleChange,
      handleBlur,
      values,
      secondComer,
    } = this.props;

    return (
      <div>
        <Typography variant="caption" className={classes.subtitle}>
          Your company
        </Typography>
        <FormControl
          required
          fullWidth
          error={errors.company_name && touched.company_name}
        >
          <Input
            id="company_name"
            name="company_name"
            placeholder="Enter company name *"
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.company_name}
          />
          <FormHelperText>
            {errors.company_name && touched.company_name && errors.company_name}
          </FormHelperText>
        </FormControl>
        <FormControl
          required
          fullWidth
          error={errors.company_nature && touched.company_nature}
        >
          <Select
            name="company_nature"
            id="company_nature"
            value={values.company_nature}
            onChange={handleChange}
            input={<Input name="company_nature" id="company_nature" />}
            className={classes.select}
            displayEmpty
          >
            <MenuItem value={''} disabled className={classes.selectItem}>
              Select company type *
            </MenuItem>
            {company_natures.map(nature => (
              <MenuItem
                value={nature}
                key={nature}
                className={classes.selectItem}
              >
                {nature}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {errors.company_nature &&
              touched.company_nature &&
              errors.company_nature}
          </FormHelperText>
        </FormControl>

        <FormControl
          required
          fullWidth
          error={errors.nb_empty_return && touched.nb_empty_return}
        >
          <Select
            name="nb_empty_return"
            id="nb_empty_return"
            value={values.nb_empty_return}
            onChange={handleChange}
            input={<Input name="nb_empty_return" id="nb_empty_return" />}
            className={classes.select}
            displayEmpty
          >
            <MenuItem value={0} disabled className={classes.selectItem}>
              Select size (number of loads per week) *
            </MenuItem>
            {sizes.map(size => (
              <MenuItem
                value={size.value}
                key={size.value}
                className={classes.selectItem}
              >
                {size.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {errors.nb_empty_return &&
              touched.nb_empty_return &&
              errors.nb_empty_return}
          </FormHelperText>
        </FormControl>
        <FormControl
          required
          fullWidth
          className={classes.field}
          error={
            errors.company &&
            errors.company.scac &&
            touched.company.scac &&
            errors.company.scac
          }
        >
          <Input
            id="company.scac"
            name="company.scac"
            placeholder="Enter SCAC code *"
            onChange={event =>
              handleChange({
                target: {
                  name: event.target.name,
                  value: event.target.value.toUpperCase(),
                },
              })
            }
            onBlur={handleBlur}
            value={values.company.scac}
          />
          <FormHelperText>
            {errors.company &&
              errors.company.scac &&
              touched.company.scac &&
              errors.company.scac}
            {secondComer &&
              'Trying to join your company on BlueCargo? Continue and you will be added very soon.'}
          </FormHelperText>
        </FormControl>
      </div>
    );
  }
}
