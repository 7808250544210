import layerImage from 'assets/images/image.png';
import backgroundImage from 'assets/images/container_image.png';

export default theme => ({
  container: {
    height: '1617px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  background: {
    position: 'absolute',
    background: `url(${backgroundImage})`,
    top: 660,
    height: 683,
    width: '100%',
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit,
  },
  buttonRoot: {
    height: 52,
    width: 200,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  ctaText: {
    fontSize: 30,
    marginBottom: 2 * theme.spacing.unit,
    marginTop: 2 * theme.spacing.unit,
    fontWeight: 'normal',
  },
  smog: {
    position: 'absolute',
    width: '100%',
    height: 1178,
    top: 439,

    backgroundImage: `url(${layerImage})`,
    mixBlendMode: 'hard-light',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
  },
  containerIcon: {
    transform: 'rotate(-15deg)',
    margin: 30,
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: 683,
    left: 0,
    top: 660,
    background:
      'linear-gradient(180deg, rgba(250, 250, 250, 0.7) 0%, rgba(250, 250, 250, 0) 51.37%, #FAFAFA 75%);',
  },
  content: {
    zIndex: 1,
    maxWidth: 990,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 12,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  transistion: {
    marginTop: 2 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    textAlign: 'center',
  },
});
