import React from 'react';
import { useUserContext } from 'common/contexts/UserContext';
import LinearProgress from '@mui/material/LinearProgress';
import _ from 'lodash';
import { formatDate } from 'utils/common';

const DisplayContainerTracking = () => {
  const { user } = useUserContext();
  const containerCreditsLimit = _.get(
    user,
    'company.container_credits_limit',
    null
  );
  const containerCreditsUsed = _.get(user, 'company.container_credits_used', 0);
  const containerCreditsCycle = _.get(
    user,
    'company.current_billing_cycle',
    []
  );

  const percentUsage = containerCreditsLimit
    ? (containerCreditsUsed * 100) / containerCreditsLimit
    : 25;

  return (
    <div className="flex flex-col space-y-2">
      <h6 style={{ fontSize: '14px' }}>Container Transactions</h6>
      {containerCreditsCycle.length > 0 &&
        (user.pack === 'd_n_d_fighter' ||
          user.pack === 'track_n_dispatch' ||
          user.pack === 'bc_connect') && (
          <p className="text-gray-300" style={{ fontSize: '0.75rem' }}>
            {formatDate(containerCreditsCycle[0], 'll')} -{' '}
            {formatDate(containerCreditsCycle[1], 'll')}
          </p>
        )}
      <LinearProgress
        className="text-blue"
        variant="determinate"
        color={percentUsage >= 100 ? 'error' : 'primary'}
        value={percentUsage}
        classes={{
          root: 'rounded',
        }}
      />
      {user.pack === 'd_n_d_fighter' ||
      user.pack === 'track_n_dispatch' ||
      user.pack === 'bc_connect' ||
      user.pack === 'pioneer' ||
      user.pack === 'starter' ||
      user.pack === 'trial_end' ? (
        <div className="flex justify-between">
          <h6>Included</h6>
          <h6>{containerCreditsLimit || 'unlimited'}</h6>
        </div>
      ) : (
        <div className="flex justify-between">
          <h6>Included</h6>
          <h6>unlimited</h6>
        </div>
      )}
      <div className="flex justify-between font-bold">
        <h6>Used</h6>
        <h6>{containerCreditsUsed}</h6>
      </div>
    </div>
  );
};

export default DisplayContainerTracking;
