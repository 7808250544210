import React, { createRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import Alert from '@mui/material/Alert';
import ActionDropdown from '../../../components/ActionDropdown';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import ContainerFilters from '../components/ContainerFilters';
import ChipInput from '../../../components/ChipInput';
import { CONTAINER_NUMBERS_RE } from '../../../services/helpers/containers';
import pluralize from 'pluralize';
import { getUrlSearchParams, setUrlSearchParams } from 'utils/common';

class ContainerTrackingControls extends React.Component {
  state = {
    containerNumbers: [],
  };

  constructor(props) {
    super(props);
    this.chipInputRef = createRef();
  }

  componentDidMount() {
    const { containerNumbers } = getUrlSearchParams({
      containerNumbers: [],
    });
    this.setState({
      containerNumbers: containerNumbers,
    });
  }

  handleContainerInputChange = containerNumbers => {
    if (containerNumbers !== undefined && containerNumbers !== null) {
      this.setState({
        containerNumbers,
      });
    }
    this.props.handleContainerInputChange(containerNumbers);
  };

  handleOnDeleteClick = disabled => {
    setUrlSearchParams({ containerNumbers: [] });
    this.chipInputRef.current.updateChips([]);
    this.props.onDeleteClick(disabled);
  };

  render() {
    const {
      selectionModel,
      toggleDrawer,
      isFilterOpen,
      containerTypes,
      shippingLines,
      removeAllFilters,
      selectedFilters,
      filterCount,
      onFilterSelection,
      warningText,
      terminals,
      onExport,
      currentViewCount,
    } = this.props;

    const nbcontainerSelected = selectionModel.length;

    return (
      <div className="flex flex-col space-y-2">
        <div className="px-8 pb-4 flex space-x-4 items-center justify-between">
          <ActionDropdown
            label="Actions"
            actionItems={[
              {
                id: 'export-view',
                name: `Export View (${currentViewCount})`,
                disabled: false,
                actionCallback: () => onExport('view'),
              },
              {
                id: 'export-selection',
                name: `Export Selection (${nbcontainerSelected})`,
                disabled: nbcontainerSelected === 0 ? true : false,
                actionCallback: () => onExport('selection'),
              },
              {
                id: 'delete',
                name: `Archive ${pluralize(
                  'Container',
                  nbcontainerSelected
                )} (${nbcontainerSelected})`,
                disabled: nbcontainerSelected === 0 ? true : false,
                actionCallback: this.handleOnDeleteClick,
              },
            ]}
            active={nbcontainerSelected > 0}
            height="40px"
            minWidth="176px"
            zIndex="100"
          />
          <form
            noValidate
            className="flex flex-grow items-center justify-center bg-white rounded"
            onSubmit={event => event.preventDefault()}
          >
            <div className="relative w-full h-10 flex items-center border border-blue-400 rounded z-40">
              <div
                className={`h-full flex flex-shrink-0 items-center justify-center float-left border-r border-blue-400 rounded-l px-7 py-2 ${this
                  .state.containerNumbers.length &&
                  'bg-blue-600 px-2.5 py-2.5'}`}
              >
                {this.state.containerNumbers.length ? (
                  <button
                    onClick={() => {
                      setUrlSearchParams({ containerNumbers: [] });
                      this.chipInputRef.current.updateChips([]);
                    }}
                    className="flex space-x-1 items-center"
                  >
                    <CloseIcon
                      style={{ fontSize: 14 }}
                      className="text-gray-200"
                    />
                    <h5 className="font-medium text-gray-200">
                      Clear ({this.state.containerNumbers.length})
                    </h5>
                  </button>
                ) : (
                  <svg
                    className="fill-current text-gray-300"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="inherit"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.577 6H2v11.08h20.577V6ZM5.957 7.847H4.902v7.386h1.055V7.847Zm2.374 0h1.055v7.386H8.331V7.847Zm4.485 0h-1.055v7.386h1.055V7.847Zm2.374 0h1.056v7.386H15.19V7.847Zm4.485 0H18.62v7.386h1.055V7.847Z"
                    />
                  </svg>
                )}
              </div>
              <div className="pr-4 w-full h-full flex relative overflow-hidden">
                <ChipInput
                  ref={this.chipInputRef}
                  defaultValue={this.state.containerNumbers}
                  classes={{
                    inputContainer:
                      'w-full h-full flex items-center flex-nowrap overflow-x-auto absolute top-0',
                    inputRoot: 'first:ml-4 min-w-36',
                    chip: {
                      root: 'first:ml-4',
                      label: 'select-text',
                    },
                  }}
                  InputProps={{
                    'aria-label': 'search containers',
                  }}
                  placeholder="Search Containers"
                  transformChipLabel={label => label.toUpperCase()}
                  transformChipValue={value => value.toUpperCase()}
                  removeCharacters={['"', "'", ' ', '“', '”']}
                  checkIfChipIsValid={value => {
                    return CONTAINER_NUMBERS_RE.test(value);
                  }}
                  onChange={this.handleContainerInputChange}
                  onCopyAlertText={
                    'Containers successfully copied to your clipboard!'
                  }
                />
              </div>
            </div>
          </form>
          <div className="flex flex-none space-x-2 items-center cursor-pointer">
            <div
              className="flex justify-center items-center bg-white border border-blue-400 rounded-full"
              style={{ height: '40px', width: '40px' }}
              onClick={() => toggleDrawer(true)}
            >
              <FilterListIcon
                className="text-gray-200"
                style={{ fontSize: '20px' }}
              />
            </div>
            {filterCount === 0 ? (
              <h4 className="text-gray-200" onClick={() => toggleDrawer(true)}>
                Open filters
              </h4>
            ) : (
              <h4 className="text-blue" onClick={removeAllFilters}>
                Remove filters ({filterCount} selected)
              </h4>
            )}
          </div>
          <SideDrawer
            isFilterOpen={isFilterOpen}
            toggleDrawer={toggleDrawer}
            title="Filter Containers"
            list={
              <ContainerFilters
                onFilterSelection={onFilterSelection}
                containerTypes={containerTypes}
                shippingLines={shippingLines}
                selectedFilters={selectedFilters}
                terminals={terminals}
              />
            }
          />
        </div>
        {warningText && (
          <div className="px-8 pb-4">
            <Alert severity="warning">
              <h5>{warningText}</h5>
            </Alert>
          </div>
        )}
      </div>
    );
  }
}

export default ContainerTrackingControls;
