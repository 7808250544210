import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CredentialsForm from './CredentialsForm';
import CredentialsNotification from './CredentialsNotification';
import { setCredentialsForTerminal } from '../../../services/clients/credentials';
import CredentialsSpinner from './CredentialsSpinner';
import { CredentialsService } from './services/credentials.service';
import {
  credsStatuses,
  credsErrorStatuses,
} from './constants/credentials.constants';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {
  terminalsOrdered,
  terminalsNames,
} from './constants/credentials.constants';
import { pageTitlesAndUrls } from 'constants/pages';
import './Credentials.css';
import theme from 'theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

export default class Credentials extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    importData: PropTypes.func.isRequired,
    terminals: PropTypes.array,
    getAppointmentsAvalabilityCredentials: PropTypes.func.isRequired,
    clearAvailabilityCredentials: PropTypes.func.isRequired,
    availabilityCredentials: PropTypes.object.isRequired,
    refreshToken: PropTypes.bool,
    setNetworkError: PropTypes.func,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: 'trapac',
      isNotificationOpen: true,
    };
  }

  componentDidMount() {
    const {
      history,
      importData,
      getAppointmentsAvalabilityCredentials,
    } = this.props;

    const { location } = history || {};
    const { state } = location || {};
    const { terminalKey } = state || {};
    if (terminalKey) {
      this.setState({
        value: terminalKey,
      });
    }

    importData();
    getAppointmentsAvalabilityCredentials();
  }

  componentDidUpdate(prevProps) {
    const { refreshToken } = this.props;
    if (prevProps.refreshToken !== refreshToken) {
      this.props.importData();
      this.props.getAppointmentsAvalabilityCredentials();
    }
  }

  componentWillUnmount() {
    const { clearAvailabilityCredentials } = this.props;
    clearAvailabilityCredentials();
  }

  handleChange = event => {
    this.setState({
      value: event.target.value,
    });
  };

  handleError = (error, cb) => {
    this.setState({ isLoading: false });
    const { response } = error || {};
    const { data, status } = response || {};
    const isCredsErrorStatus = credsErrorStatuses.includes(status);
    if (isCredsErrorStatus && data && typeof data === 'string') {
      cb(data);
    } else {
      const { setNetworkError } = this.props;
      setNetworkError(error);
    }
  };

  handleFormSubmit = (creds, cb) => {
    const { getAppointmentsAvalabilityCredentials } = this.props;
    const { value } = this.state;
    if (!value) return;
    this.setState({ isLoading: true });
    setCredentialsForTerminal(creds, value, (error, res) => {
      if (error) {
        this.handleError(error, cb);
      } else {
        const { data } = res || {};
        if (data && data[value] && data[value] === credsStatuses.valid) {
          getAppointmentsAvalabilityCredentials(availabilityCredentials => {
            this.handleSkipThisStepClick(value, availabilityCredentials);
            this.setState({ isLoading: false });
          });
        } else {
          this.setState({ isLoading: false });
        }
      }
    });
  };

  handleSkipThisStepClick = (currentTerminalKey, terminalsWithStatuses) => {
    const { navigate, availabilityCredentials } = this.props;
    const nextTerminalKey = CredentialsService.getNextTerminal(
      currentTerminalKey,
      terminalsWithStatuses || availabilityCredentials
    );
    if (!nextTerminalKey) {
      navigate(pageTitlesAndUrls.containerTracking.path);
      return;
    }
    this.setState({
      value: nextTerminalKey,
      isNotificationOpen: false,
    });
  };

  renderIcon = terminalKey => {
    const { classes, availabilityCredentials } = this.props;
    const status = CredentialsService.getCredentialsStatus(
      terminalKey,
      availabilityCredentials
    );
    switch (status) {
      case credsStatuses.none:
        return (
          <div className="creds-radiobuttons-icon no-any-credentials"></div>
        );
      case null:
        return (
          <div className="creds-radiobuttons-icon no-any-credentials"></div>
        );
      case credsStatuses.valid:
        return (
          <div className="creds-radiobuttons-icon">
            <CheckIcon className={classes.radiobuttonIcon} />
          </div>
        );
      case credsStatuses.invalid:
        return (
          <div className="creds-radiobuttons-icon not-verified">
            <PriorityHighIcon className={classes.radiobuttonIcon} />
          </div>
        );
      default:
        return null;
    }
  };

  renderActiveLabel = terminalKey => {
    const { value } = this.state;
    if (value && value === terminalKey) {
      return <div className="creds-radiobuttons-icon selected"></div>;
    }
  };

  render() {
    const { classes, terminals, availabilityCredentials } = this.props;
    const { value, isNotificationOpen, isLoading } = this.state;
    const terminalSelected =
      terminals && terminals.find(terminal => terminal.key === value);
    const currentTerminalStatus =
      terminalSelected &&
      CredentialsService.getCredentialsStatus(
        terminalSelected.key,
        availabilityCredentials
      );

    return (
      <MuiThemeProvider theme={theme}>
        <div className="w-full">
          {isLoading && <CredentialsSpinner />}

          <div className="w-full">
            {isNotificationOpen && (
              <CredentialsNotification
                closeNotification={() =>
                  this.setState({ isNotificationOpen: false })
                }
              />
            )}

            <div className="creds-body">
              <div className="creds-radiobuttons">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={value}
                    onChange={this.handleChange}
                  >
                    {terminals &&
                      terminals.length &&
                      terminalsOrdered.map(terminal => (
                        <div
                          key={terminal.key}
                          className="creds-radiobuttons-item"
                        >
                          {this.renderIcon(terminal.key)}

                          <FormControlLabel
                            key={terminal.key}
                            disabled={terminal.isDisabled}
                            value={terminal.key}
                            control={<Radio style={{ display: 'none' }} />}
                            label={`${terminal.pier} (${terminal.name})`}
                            classes={{
                              root: classes.termimalLabelRoot,
                              label:
                                terminalSelected &&
                                terminal.key === terminalSelected.key
                                  ? classes.terminalLabelActive
                                  : classes.terminalLabel,
                            }}
                          />

                          {this.renderActiveLabel(terminal.key)}
                        </div>
                      ))}
                  </RadioGroup>
                </FormControl>
              </div>

              {terminalSelected && (
                <div className="creds-body-details">
                  <div className="creds-body-details-terminal">
                    <div className="creds-body-details-terminal-icon">
                      <img src={terminalSelected.logo} alt="new" />
                    </div>

                    <div className="creds-body-details-terminal-name">
                      <div>{`${terminalSelected.pier} (${terminalSelected.name})`}</div>
                      <div>
                        <a
                          className="creds-notification-link"
                          href={terminalSelected.terminal_portal}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {terminalSelected.appointment_system_name}
                        </a>{' '}
                        credentials
                      </div>
                    </div>
                  </div>

                  <div className="creds-body-details-prompt">
                    {terminalSelected &&
                      terminalSelected.key &&
                      terminalsNames[terminalSelected.key].helper}
                  </div>

                  <CredentialsForm
                    classes={classes}
                    terminalKey={value}
                    terminalsNames={terminalsNames}
                    onHandleFormSubmit={this.handleFormSubmit}
                    onSkipThisStepClick={this.handleSkipThisStepClick}
                    currentTerminalStatus={currentTerminalStatus}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}
