import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Input extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onSetInputActive: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isInputActive: false,
    };
  }

  setInputActive = () => {
    const { onSetInputActive } = this.props;
    this.setState(prevState => {
      const isActive = !prevState.isInputActive;
      if (onSetInputActive) {
        onSetInputActive(isActive);
      }
      return {
        isInputActive: isActive,
      };
    });
  };

  render() {
    const {
      value,
      className,
      maxLength,
      placeholder,
      onBlur,
      onChange,
    } = this.props;

    return (
      <input
        type="text"
        className={className}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={this.setInputActive}
        onBlur={() => {
          this.setInputActive();
          if (onBlur) {
            onBlur();
          }
        }}
      />
    );
  }
}

export default Input;
