import Caption from './Caption';
import styles from './Caption.style';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { openCaption, closeCaption } from '../../ducks/display';

const mapStateToProps = state => ({
  isOpen: state.display.isCaptionOpen,
});

const mapDispatchToProps = dispatch => ({
  open: () => dispatch(openCaption()),
  close: () => dispatch(closeCaption()),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Caption);
