import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Input from 'common/UI/Input';
import { Dropdown } from 'common/UI/Dropdown';
import './styles/MyAppointmentsSearch.css';

class MyAppointmentsSearch extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    terminals: PropTypes.array,
    selectedSL: PropTypes.object,
    shippingLines: PropTypes.array,
    selectedTerminal: PropTypes.object,
    value: PropTypes.string,
    onHandleSearchClick: PropTypes.func.isRequired,
    onShippingLineChange: PropTypes.func.isRequired,
    onHandleTerminalChange: PropTypes.func.isRequired,
    onHandleContainerNumberChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMyAppointmentsInputActive: false,
      value: '',
      warning: '',
    };
  }

  handleSetInputActive = () => {
    this.setState(prevState => ({
      isMyAppointmentsInputActive: !prevState.isMyAppointmentsInputActive,
    }));
  };

  setInputData = event => {
    const { onHandleContainerNumberChange } = this.props;
    const { target } = event || {};
    const { value } = target || {};
    // const isContainerNumberValid = NextAppointmentsService.validateContainerNumber(
    //   value
    // );
    // const notValidContainerNumberMsg = 'Please enter a valid container number';
    this.setState(
      {
        value,
        // warning: value && !isContainerNumberValid ? notValidContainerNumberMsg : '',
      },
      () => onHandleContainerNumberChange(value)
    );
  };

  onSearchClick = () => {
    const { onHandleSearchClick } = this.props;
    const { warning } = this.state;
    if (warning) return;
    onHandleSearchClick();
  };

  render() {
    const {
      value,
      isLoading,
      classes,
      terminals,
      selectedSL,
      shippingLines,
      selectedTerminal,
      onHandleTerminalChange,
      onShippingLineChange,
    } = this.props;
    const { isMyAppointmentsInputActive, warning } = this.state;

    const classNames = {
      root: classes.shippingLinesDropDown,
      inputRoot: classes.inputWrapper,
      clearIndicator: classes.clearIndicator,
      popupIndicator: classes.popupIndicator,
      endAdornment: classes.endAdornment,
      groupLabel: classes.groupLabel,
    };

    return (
      <div className="my-appt-search">
        <div>
          <div
            className={`my-appt-search-title${
              isMyAppointmentsInputActive ? ' active' : ''
            }`}
          >
            Search for your container
          </div>
          <div className="my-appt-search-input-button">
            <Input
              className="my-appt-search-input"
              value={value}
              maxLength={11}
              placeholder="Ex: CMAU1234567"
              onChange={this.setInputData}
              onSetInputActive={this.handleSetInputActive}
            />

            <div className="my-appt-search-button-wrapper">
              <div
                className={`my-appt-search-button${
                  isLoading || warning ? ' disabled' : ''
                }`}
                onClick={this.onSearchClick}
              >
                Search
              </div>
            </div>
          </div>

          {warning && <div className="my-appt-search-warning">{warning}</div>}
        </div>

        <div className="my-appt-search-dropdowns">
          <div className="my-appt-search-dropdown-container">
            <Dropdown
              id="shipingLines"
              label="Select a shipping line"
              classes={{
                ...classNames,
                input: selectedSL ? classes.inputSelected : classes.inputClear,
              }}
              options={shippingLines}
              value={selectedSL}
              onDropdownChange={onShippingLineChange}
            />
          </div>

          <div className="my-appt-search-dropdown-container">
            <Dropdown
              id="terminals"
              label="Select a terminal"
              classes={{
                ...classNames,
                input: selectedTerminal
                  ? classes.inputSelected
                  : classes.inputClear,
              }}
              options={_.sortBy(terminals, [({ name }) => name])}
              value={selectedTerminal}
              onDropdownChange={onHandleTerminalChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MyAppointmentsSearch;
