import React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { validateEmail } from 'services/helpers/user';
import InformationPage from 'components/InformationPage';
import { Formik } from 'formik';
import Formular from './components/Formular';
import { isNormalInteger } from 'services/helpers/form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { ReactComponent as ProfileIcon } from 'assets/images/Profile.svg';
import NeedValidation from './components/NeedValidation';
import { PLAN_MAP } from 'components/UpdatePlan/PlanSelectionForm';
import erlClient from 'services/clients';
import memoize from 'lodash/memoize';
import { Navigate } from 'react-router-dom';
import GetStarted from './components/GetStarted';

import qs from 'query-string';
import TeamForm from './components/TeamForm';

const initialValues = {
  email: '',
  password: '',
  password_confirmation: '',
  company_name: '',
  company_nature: '',
  company_role: '',
  nb_empty_return: 0,
  first_name: '',
  last_name: '',
  phone_number: '',
  terms_of_uses: false,
  privacy_policy: false,
  company: {
    scac: '',
  },
  members: [],
};

const validateEmailExist = memoize(email =>
  erlClient.get(`core/api/v1/auth/user/email/${email}`)
);

const validateScacExist = memoize(scac =>
  erlClient.get(`core/api/v1/auth/company/scac/${scac}`)
);

const STORAGE_GET_STARTED_KEY = 'signup:display_get_started';

export default class SignUp extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    signUp: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    setStep: PropTypes.func.isRequired,
    isLogged: PropTypes.bool.isRequired,
    logOut: PropTypes.func.isRequired,
  };
  state = {
    forceStep: null,
    result: null,
    scac: '',
    secondComer: false,
    redirectTo: null,
    _rerender: false,
  };

  rerender = () => this.setState({ _rerender: !this.state._rerender });

  validate = async (values, props) => {
    // Remove force step to avoid user to be stuck
    this.setState({ forceStep: null, secondComer: false });
    let errors = {};

    if (!values.email) errors.email = 'Required';
    else if (!validateEmail(values.email))
      errors.email = 'Invalid email address';
    if (values.email && !errors.email && this.state.email !== values.email) {
      try {
        await validateEmailExist(values.email);
      } catch (e) {
        errors.email = e.response.data.message;
      }
    }

    if (!values.password) errors.password = 'Required';
    else if (values.password.length < 8)
      errors.password =
        'Your password is too short, it should be at least 8 characters long';

    if (!values.password_confirmation)
      errors.password_confirmation = 'Required';
    else if (values.password !== values.password_confirmation)
      errors.password_confirmation = "Passwords don't match";

    if (!values.terms_of_uses || !values.privacy_policy)
      errors.terms_of_uses =
        'You must agree to the terms of use and privacy policy to register';

    if (!values.company_name) errors.company_name = 'Required';
    if (!values.company_nature) errors.company_nature = 'Required';
    if (!values.company_role) errors.company_role = 'Required';
    if (!values.nb_empty_return) errors.nb_empty_return = 'Required';
    else if (!isNormalInteger(values.nb_empty_return))
      errors.nb_empty_return = 'It must be a number';

    if (!values.first_name) errors.first_name = 'Required';
    if (!values.last_name) errors.last_name = 'Required';
    if (!values.phone_number) errors.phone_number = 'Required';
    if (values.phone_number && !isValidPhoneNumber(values.phone_number))
      errors.phone_number = 'Invalid phone number';

    if (values.company.scac && !errors.company_scac) {
      try {
        await validateScacExist(values.company.scac);
      } catch (e) {
        // errors.scac_exist = e.response.data.message;
        this.setState({ secondComer: true });
      }
    }
    throw errors;
  };

  componentDidMount() {
    const { isLogged, location, logOut } = this.props;

    const { search } = location || {};
    if (search && isLogged) {
      logOut();
      return;
    }

    if (isLogged) {
      this.setState({ redirectTo: '/' });
    }
  }

  submit = (values, { setSubmitting, setErrors, resetForm }) => {
    this.props
      .signUp({ ...values, pricing_plan: PLAN_MAP[values.pricing_plan] })
      .then(data => {
        setSubmitting(false);
        // Signup success let redirect the user
        resetForm();
        localStorage.removeItem('signup-form');
        this.props.setStep(0);
        if (data.membership === null || data.membership.validated)
          if (data.membership.is_admin) this.setState({ result: 'success' });
          else this.setState({ result: 'success_join' });
        else if (!data.membership.validated)
          this.setState({
            result: 'need_validation',
            scac: values.company.scac,
          });
      })
      .catch(e => {
        const errors = e;
        // set form in the step it fails
        if (
          errors.first_name ||
          errors.last_name ||
          errors.phone_number ||
          errors.company_role
        )
          this.setState({ forceStep: 1 });
        else if (
          errors.company_name ||
          errors.company_nature ||
          errors.company_scac ||
          errors.nb_empty_return ||
          (errors.company && errors.company.scac)
        )
          this.setState({ forceStep: 2 });
        else this.setState({ forceStep: 0 });
        setErrors(errors);
        setSubmitting(false);
      });
  };

  render() {
    const {
      classes,
      location: { search },
    } = this.props;

    let { result, redirectTo, scac } = this.state;

    const getStartedAlreadySeen = sessionStorage.getItem(
      STORAGE_GET_STARTED_KEY
    );
    if (!getStartedAlreadySeen) {
      return (
        <GetStarted
          classes={classes}
          onNextClick={() => {
            sessionStorage.setItem(STORAGE_GET_STARTED_KEY, true);
            this.rerender();
          }}
        />
      );
    }
    if (search) {
      // If user came with prefill field, we clean the localstorage to be sure
      // field has the right value.
      localStorage.removeItem('signup-form');
    }
    const queryValues = qs.parse(search);

    if (redirectTo) return <Navigate replace to={redirectTo} />;

    let content;
    if (result === 'success')
      content = (
        <TeamForm
          successCallBack={() => {
            this.setState({ redirectTo: '/welcome?company=true' });
          }}
        />
      );
    else if (result === 'need_validation')
      content = <NeedValidation {...this.props} scac={scac} />;
    else if (result === 'success_join')
      // return <Redirect to="/welcome?company=true" />; changed 26.01.2021
      return <Navigate to="/welcome?company=true" />;
    else
      content = (
        <Formik
          initialValues={{
            ...initialValues,
            ...queryValues,
            company: { scac: queryValues.scac },
          }}
          validate={this.validate}
          onSubmit={this.submit}
          validateOnBlur={false}
        >
          {props => (
            <Formular
              forceStep={this.state.forceStep}
              secondComer={this.state.secondComer}
              onFirstBack={() => {
                sessionStorage.removeItem(STORAGE_GET_STARTED_KEY);
                this.rerender();
              }}
              {...props}
            />
          )}
        </Formik>
      );

    return (
      <InformationPage classes={{ container: classes.root }}>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <ProfileIcon />
            <Typography
              component="h1"
              variant="h5"
              className={classes.title}
              gutterBottom
            >
              Sign up
            </Typography>
            {content}
          </Paper>
        </main>
      </InformationPage>
    );
  }
}
