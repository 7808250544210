import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogContent = ({
  classes,
  TopIcon,
  title,
  subtitle,
  children,
  callToAction,
  withCloseButton,
  close,
}) => {
  return (
    <React.Fragment>
      {withCloseButton && (
        <IconButton onClick={close} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      )}
      <TopIcon width={36} height={36} className={classes.icon} />
      <div className={classes.head}>
        <Typography variant="h2" gutterBottom align="center">
          {title}
        </Typography>
        <Typography variant="caption" align="center" gutterBottom>
          {subtitle}
        </Typography>
      </div>
      <div className={classes.content}>{children}</div>
      <div className={classes.cta}>{callToAction}</div>
    </React.Fragment>
  );
};

DialogContent.propTypes = {
  classes: PropTypes.object.isRequired,
  TopIcon: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  callToAction: PropTypes.node.isRequired,
  withCloseButton: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

DialogContent.defaultProps = {
  withCloseButton: false,
  close: () => console.log('To be implemented'),
};

const styles = theme => ({
  head: {
    display: 'flex',
    flexDirection: 'column',
    justifyConent: 'center',
    marginBottom: 3 * theme.spacing.unit,
  },
  content: {},
  cta: {
    marginTop: 3 * theme.spacing.unit,
    textAlign: 'center',
  },
  icon: {
    margin: 'auto',
    marginBottom: 3 * theme.spacing.unit,
  },
  closeButton: {
    position: 'absolute',
    margin: 'auto',
    top: theme.spacing.unit,
    right: theme.spacing.unit,
  },
});

export default withStyles(styles)(DialogContent);
