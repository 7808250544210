export default theme => ({
  margin: {
    width: '30%',
  },
  form: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  formControl: {
    width: 100,
    marginRight: theme.spacing.unit,
  },
  select: {
    color: theme.palette.common.white,
    '&:before': {
      borderColor: theme.palette.common.white,
    },
    '&:after': {
      borderColor: theme.palette.common.white,
    },
  },
  icon: {
    fill: theme.palette.common.white,
  },
});
