import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as ProfilIcon } from 'assets/icons/profile_circle.svg';
import Button from 'components/customDesign/Button';
import erlClient from 'services/clients';

function PendingInvitationDialog({ isOpen, close, classes, member }) {
  const [invitationSent, setInvitationSent] = useState(false);
  const [invitationLoading, setInvitationLoading] = useState(false);
  const resendInvitation = async email => {
    try {
      setInvitationLoading(true);
      await erlClient.put(`/core/api/v1/auth/company/invitation/${email}`);
      setInvitationSent(true);
      setInvitationLoading(false);
    } catch {
      console.error('Cannot resend mail');
      setInvitationLoading(false);
    }
  };

  const [invitationRevoked, setInvitationRevoked] = useState(false);
  const [revokeLoading, setRevokeLoading] = useState(false);
  const revokeInvitation = async email => {
    try {
      setRevokeLoading(true);
      await erlClient.delete(`/core/api/v1/auth/company/invitation/${email}`);
      setInvitationRevoked(true);
      setRevokeLoading(false);
    } catch {
      console.error('Cannot revoke invitation');
      setRevokeLoading(false);
    }
  };
  const onClose = () => {
    setInvitationRevoked(false);
    close();
  };
  if (invitationRevoked) onClose();

  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.root }}>
      <div className={classes.closeModalIcon}>
        <IconButton onClick={onClose}>
          <CloseIcon style={{ fontSize: 18 }} />
        </IconButton>
      </div>
      <ProfilIcon className={classes.headerIcon} />
      <Typography align="center" variant="h5" gutterBottom>
        Invitation
      </Typography>
      <Typography align="center" variant="caption" className={classes.subtitle}>
        {member}
      </Typography>
      <div className={classes.actions}>
        <Button
          classes={{ root: classes.button }}
          onClick={() => resendInvitation(member)}
          success={invitationSent}
          disabled={invitationLoading}
          isLoading={invitationLoading}
        >
          {invitationSent ? 'Sent' : 'Resend member invitation'}
        </Button>
        <div>
          <Typography
            align="center"
            variant="caption"
            className={classes.revoke}
            onClick={() => !revokeLoading && revokeInvitation(member)}
          >
            Revoke invitation
          </Typography>
        </div>
      </div>
    </Dialog>
  );
}

PendingInvitationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  member: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    padding: 4 * theme.spacing.unit,
    textAlign: 'center',
    width: theme.layout.popupWidth,
  },
  headerIcon: {
    margin: 'auto',
    marginBottom: 3 * theme.spacing.unit,
  },
  actions: {
    marginTop: 4 * theme.spacing.unit,
  },
  button: {
    width: 250,
  },
  revoke: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  closeModalIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export default withStyles(styles)(PendingInvitationDialog);
