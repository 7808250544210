import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPorts, setShippingLines } from 'ducks/erlData';
import {
  getMyAppointments,
  setClearMyAppointments,
} from 'ducks/myAppointments';
import MyAppointments from './MyAppointments';
import styles from './MyAppointments.style';
import './styles/MyAppointments.css';

const mapStateToProps = (state, ownProps) => {
  const { user, refreshToken, myAppointments, loader, erlData } = state;
  const { shippingLines, containerTypes, ports } = erlData || {};
  const { isMyAppointmentsLoading } = loader;
  return {
    user,
    refreshToken,
    shippingLines,
    containerTypes,
    myAppointments,
    isMyAppointmentsLoading,
    terminals: ports,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  importData: () => {
    dispatch(setShippingLines());
    dispatch(setPorts());
  },

  getMyAppointments: (page, sortParams, searchParams) => {
    dispatch(getMyAppointments(page, sortParams, searchParams));
  },

  clearMyAppointments: () => {
    dispatch(setClearMyAppointments());
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MyAppointments);
