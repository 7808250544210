export default theme => ({
  slTypography: {
    fontSize: 12,
    color: theme.palette.primary.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    lineHeight: 2,
    marginBottom: theme.spacing.unit,
  },
  cellRoot: {
    verticalAlign: 'top',
    paddingTop: 2 * theme.spacing.unit,
    paddingLeft: 3 * theme.spacing.unit,
    paddingRight: 2 * theme.spacing.unit,
  },
  clickable: {
    cursor: 'pointer',
  },
  icon: {
    fontSize: 18,
    marginBottom: -3,
  },
  arrow: {
    position: 'absolute',
    fontSize: 10,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: {
    zIndex: 1,
    marginLeft: 8,
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
      },
    },
  },
});
