import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from 'components/customDesign/Input';
import { extractEmails } from 'services/helpers/user';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { withFormik } from 'formik';
import erlClient from 'services/clients';

const TeamForm = ({
  classes,
  handleChange,
  values,
  isSubmitting,
  handleSubmit,
}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const addMember = () => {
    const emails = extractEmails(value);
    if (!emails) {
      setError('Invalid email address');
    } else {
      const filteredEmail = emails
        .map(email => email.toLowerCase())
        .filter(email => values.members.indexOf(email) === -1);
      handleChange({
        target: {
          value: [...values.members, ...filteredEmail],
          name: 'members',
        },
      });
      setValue('');
    }
  };
  const onChange = event => {
    setError('');
    setValue(event.target.value);
  };
  const removeMember = member => {
    handleChange({
      target: {
        value: values.members.filter(v => v !== member),
        name: 'members',
      },
    });
  };
  return (
    <form onSubmit={e => e.preventDefault()}>
      <Typography variant="caption" className={classes.subtitle}>
        Invite teammates to try out
      </Typography>
      <FormControl fullWidth className={classes.field} error={error}>
        <Input
          id="member"
          name="member"
          placeholder="Enter email addresses"
          autoFocus
          onChange={onChange}
          value={value}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              e.preventDefault();
              addMember();
            }
          }}
          endAdornment={
            <IconButton onClick={addMember}>
              <AddIcon />
            </IconButton>
          }
        />

        <FormHelperText>{error}</FormHelperText>
      </FormControl>

      <div className={classes.memberList}>
        {values.members.map(member => (
          <Chip
            key={member}
            label={member}
            onDelete={() => removeMember(member)}
            className={classes.chip}
            deleteIcon={<CloseIcon size="small" />}
          />
        ))}
      </div>

      <div className={classes.buttonsContainer}>
        <div className={classes.buttonWrapper}>
          <button
            className={classes.sendInvitesButton}
            onClick={handleSubmit}
            disabled={values && values.members && !values.members.length}
          >
            {isSubmitting ? (
              <CircularProgress color="white" size={14} />
            ) : (
              'SEND INVITES'
            )}
          </button>
        </div>
        <div className={classes.buttonWrapper}>
          <div className={classes.skipThisStepButton} onClick={handleSubmit}>
            Skip this step
          </div>
        </div>
      </div>
    </form>
  );
};

TeamForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    members: PropTypes.array.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const styles = theme => ({
  memberList: {
    minHeight: '130px',
    maxHeight: '150px',
    overflow: 'auto',
    marginTop: '5px',
  },
  buttonsContainer: {
    margin: '20px 0',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
  },
  sendInvitesButton: {
    outline: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F1A852',
    fontWeight: 'bold',
    letterSpacing: '0.0125em',
    color: '#FFFFFF',
    padding: '9px 50px',
    borderRadius: '20px',
    marginBottom: '20px',
    transition: 'opacity .1s',
    '&:hover': {
      opacity: '.8',
      cursor: 'pointer',
      transition: 'opacity .1s',
    },
    '&:disabled': {
      backgroundColor: '#8E98A4',
      '&:hover': {
        opacity: '1',
        cursor: 'auto',
      },
    },
  },
  skipThisStepButton: {
    color: '#2361FF',
    cursor: 'pointer',
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },
});

export default withFormik({
  mapPropsToValues: () => ({ members: [] }),

  // Custom sync validation
  validate: values => {},

  handleSubmit: (values, { setSubmitting, setErrors, props }) => {
    const promises = values.members.map(email =>
      erlClient.post('/core/api/v1/auth/company/add-member', {
        email,
        is_admin: false,
      })
    );
    Promise.all(promises)
      .then(res => {
        setSubmitting(false);
        props.successCallBack();
      })
      .catch(err => {
        setErrors({ global: err.response.data.message });
        setSubmitting(false);
      });
  },

  displayName: 'TeamForm',
})(withStyles(styles)(TeamForm));
