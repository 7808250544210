import { CELL_WIDTH_192, CELL_WIDTH_130 } from 'constants/layout';

export default theme => ({
  headerCell: {
    border: '1px solid #EFF0F1',
    width: CELL_WIDTH_192,
    maxWidth: CELL_WIDTH_192,
    minWidth: CELL_WIDTH_192,
    backgroundColor: '#FFF',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    fontSize: '0.75rem',
    lineHeight: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    backgroundColor: '#fff',
  },
  icon: {
    verticalAlign: 'middle',
  },
  cell: {
    borderRight: '1px solid #EFF0F1',
    backgroundColor: '#FFF',
    maxWidth: CELL_WIDTH_130,
    minWidth: CELL_WIDTH_130,
    height: '25px',
    textAlign: 'center',
    fontSize: '0.75rem',
  },
  row: {
    borderBottom: '1px solid #EFF0F1',
  },
  table: {
    width: 'auto',
  },
  lastRefreshBadCreds: {
    borderRight: '1px solid #EFF0F1',
    backgroundColor: '#D5D8E1',
    maxWidth: CELL_WIDTH_130,
    minWidth: CELL_WIDTH_130,
    height: '25px',
    textAlign: 'center',
    fontSize: '0.75rem',
    borderBottom: '1px solid #D5D8E1',
  },
  checkTerminalPortalHeader: {
    color: '#2361FF',
    textDecoration: 'underline',
  },
});
