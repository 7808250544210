import React, { useEffect, useState, useMemo } from 'react';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import Hidden from '@material-ui/core/Hidden';
import ContainerCardsMobile from './ContainerCardsMobile';
import config from 'config';
import { pinMuiDataGridHeaders } from '../../../utils/dataGrid';
import NoContainersFoundOverlay from './NoContainersFoundOverlay';
import ContainersErrorOverlay from './ContainersErrorOverlay';
import UploadContainersOverlay from './UploadContainersOverlay';
import { getColumns } from '../utils/columns';
import { useNavHeightContext } from 'common/contexts/NavHeightContext';

LicenseInfo.setLicenseKey(config.DATA_GRID_PRO_LICENSE_KEY);

const ContainerTable = props => {
  const {
    containerList,
    containerListRowCount,
    loading,
    onPageSizeChange,
    onPageChange,
    pageSize,
    page,
    onRowSelect = () => {},
    pinnedColumns,
    selectionModel,
    openAllAppointmentsModal,
    error,
    onGetPerDiemReport,
    currentUser,
    filterCount,
    setImportModalIsOpen,
  } = props;

  const { marginTop } = useNavHeightContext();

  const columns = useMemo(
    () => getColumns(onGetPerDiemReport, openAllAppointmentsModal, currentUser),
    [onGetPerDiemReport, openAllAppointmentsModal, currentUser]
  );

  const [isFixedHeaders, setIsFixedHeaders] = useState(false);
  useEffect(() => {
    window.addEventListener(
      'scroll',
      pinMuiDataGridHeaders.bind(null, setIsFixedHeaders, isFixedHeaders),
      { passive: true }
    );

    return () => window.removeEventListener('scroll', pinMuiDataGridHeaders);
  }, [isFixedHeaders]);

  const initialState = pinnedColumns
    ? { pinnedColumns: { left: ['__check__', 'containers'] } }
    : undefined;

  const NoResultsOrRowsOverlay = !containerList.length
    ? filterCount === 0
      ? UploadContainersOverlay
      : NoContainersFoundOverlay
    : () => <div className="flex flex-col space-y-1 items-center pb-6"></div>;

  return (
    <div className="flex h-full">
      <Hidden xsDown>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGridPro
            disableVirtualization={process.env.JEST_WORKER_ID !== undefined}
            checkboxSelection={true}
            onSelectionModelChange={onRowSelect}
            disableSelectionOnClick={true}
            selectionModel={selectionModel}
            autoHeight
            columnBuffer={columns.length}
            rowHeight={100}
            headerHeight={32}
            pageSize={pageSize}
            rows={containerList}
            columns={columns}
            disableColumnMenu
            rowsPerPageOptions={[25, 50, 75, 100]}
            getRowId={row => row.containerNumber}
            pagination
            paginationMode="server"
            rowCount={containerListRowCount}
            loading={loading}
            page={page}
            onPageChange={newPage => onPageChange(newPage)}
            onPageSizeChange={newPageSize => onPageSizeChange(newPageSize)}
            initialState={initialState}
            components={{
              NoResultsOverlay: NoResultsOrRowsOverlay,
              // Hack: a bug in Datagrid shows a brief flash of this NoRowsOverlay component when there are actual rows.
              // This makes it so that we just show a blank screen when there _are_ rows, for that half second.
              NoRowsOverlay: NoResultsOrRowsOverlay,
              ErrorOverlay: ContainersErrorOverlay,
            }}
            componentsProps={{
              noRowsOverlay: {
                setImportModalIsOpen,
              },
              errorOverlay: {
                error,
              },
            }}
            error={error}
            sx={{
              '& .MuiDataGrid-columnHeaders': isFixedHeaders && {
                top: marginTop, // Navbar height
                left: '1px',
                zIndex: 1198, // User Pop z-index is 1199
                position: 'fixed',
              },
            }}
          />
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="w-10/12 m-auto flex flex-col space-y-4">
          {containerList &&
            containerList.map(containerData => {
              return (
                <ContainerCardsMobile
                  key={containerData.id}
                  index={containerData.id}
                  containerData={containerData}
                />
              );
            })}
        </div>
      </Hidden>
    </div>
  );
};

export default ContainerTable;
