import React from 'react';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import { formatCurrencyNumber } from 'utils/common';
import { brandColors } from 'brandColors';

const PricingCard = ({
  packId,
  buttonText,
  name,
  startsAt,
  descriptions,
  displayedColor,
  isCurrentPack,
  onPackSelected,
  disabled,
}) => {
  return (
    <div
      className={`w-full lg:w-4/12 h-full bg-white ${
        isCurrentPack ? 'shadow-md' : 'shadow-sm'
      } rounded-md`}
    >
      <div
        style={{
          height: '4px',
          borderColor: displayedColor,
          backgroundColor: displayedColor,
        }}
        className="w-full rounded-t-md border"
      ></div>
      <div className="py-4 px-4">
        {/* pricing card title */}
        <div className="flex flex-col space-y-4 items-center text-dark">
          <h1 className="font-bold text-center">{name}</h1>
          <h3 className="font-medium ">
            {isFinite(startsAt)
              ? `Starts at ${formatCurrencyNumber(startsAt / 100, 0)} / month`
              : 'Custom Pricing'}
          </h3>
          <RenderButton
            displayedColor={displayedColor}
            buttonText={buttonText}
            packId={packId}
            onPackSelected={onPackSelected}
            disabled={disabled}
          />
        </div>
        <hr
          className="w-full bg-blue-500 border-none my-4 rounded-md"
          style={{ height: '1px' }}
        />
        {/* description */}
        <div className="flex flex-col space-y-6">
          {descriptions.map(description => {
            const { bulletPoints } = description;
            return (
              <div key={description.title} className="flex flex-col space-y-2">
                <h4 className="font-bold text-dark">{description.title}</h4>
                <ul className="flex flex-col space-y-2">
                  {bulletPoints &&
                    bulletPoints.map((bullet, index) => {
                      return (
                        <div key={index} className="flex space-x-1 items-start">
                          <CheckIcon
                            style={{ fontSize: '14px' }}
                            className="relative top-1"
                          />
                          <li>
                            <h4 className="font-regular text-gray-100">
                              {bullet}
                            </h4>
                          </li>
                        </div>
                      );
                    })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const RenderButton = ({
  buttonText,
  displayedColor,
  onPackSelected,
  packId,
  disabled,
}) => {
  let colorKey = 'blue';
  for (const [key, value] of Object.entries(brandColors.colors)) {
    if (
      value.DEFAULT &&
      value.DEFAULT.toLowerCase() === displayedColor.toLowerCase()
    ) {
      colorKey = key;
      break;
    }
  }

  let style = {
    backgroundColor: brandColors.colors[colorKey][600],
    borderColor: brandColors.colors[colorKey].DEFAULT,
  };
  let text = '';
  switch (buttonText) {
    case 'upgrade':
      text = 'Upgrade';
      break;
    case 'current_plan':
      disabled = true;
      style = {
        ...style,
        color: 'inherit',
      };
      text = 'Current Plan';
      break;
    case 'default':
      text = 'Choose Plan';
      break;
    case 'downgrade':
      text = 'Downgrade';
      colorKey = 'blue';
      style = {
        color: brandColors.colors.gray[200],
      };
      break;
    case 'change_volume':
      text = 'Change Volume';
      colorKey = 'blue';
      style = {
        color: brandColors.colors.gray[200],
        background: brandColors.colors.blue[600],
        border: `1px solid ${brandColors.colors.gray[500]}`,
      };
      break;
    case 'contact_us':
      text = 'Contact us!';
      break;
    default:
      break;
  }
  return (
    <Button
      variant="outlined-info"
      onClick={() => onPackSelected(packId, text)}
      style={style}
      disabled={disabled}
      sx={{
        ':hover': {
          backgroundColor: `${brandColors.colors[colorKey][500]} !important`,
        },
      }}
    >
      <h4 className="medium capitalize">{text}</h4>
    </Button>
  );
};

export default PricingCard;
