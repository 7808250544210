import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AvailabilityTable from './AvailabilityTable';
import ShiftPicker from './ShiftPicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableFilters from './TableFilters';
import ScrollTopButton from 'components/ScrollTopButton';
import { isIE } from 'constants/layout';

class FullTable extends React.Component {
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  state = {
    tableWidth: 0,
  };
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    containerTypes: PropTypes.array.isRequired,
    shippingLines: PropTypes.array.isRequired,
    isLogged: PropTypes.bool.isRequired,
  };
  componentDidUpdate = () => {
    if (
      this.table.current &&
      this.table.current.offsetWidth !== this.state.tableWidth
    )
      this.setState({ tableWidth: this.table.current.offsetWidth });
  };
  render() {
    const {
      isLoading,
      classes,
      containerTypes,
      shippingLines,
      isLogged,
    } = this.props;

    return (
      <React.Fragment>
        {isLoading && (
          <div className={classes.loaderWrapper}>
            <CircularProgress size={70} />
          </div>
        )}
        {containerTypes.length > 0 && shippingLines.length > 0 && (
          <div className={classes.table}>
            <div className={classes.wrapper}>
              <TableFilters maxWidth={this.state.tableWidth} />

              <div className={classes.widthWrapper} ref={this.table}>
                <Paper className={classes.paper}>
                  <div className={classes.tableOverShift}>
                    <ShiftPicker />
                  </div>
                  <AvailabilityTable
                    shippingLines={shippingLines}
                    containerSize={containerTypes}
                    onCellClick={isLogged ? this.openCreateNotif : undefined}
                    stickySpace
                    disableClickEmpty
                  />
                </Paper>
              </div>
              <ScrollTopButton />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  widthWrapper: {
    width: 'fit-content',
  },
  table: {
    // width: 'fit-content',
    margin: 'auto',
    textAlign: 'center',
    marginBottom: 100,
  },
  loaderWrapper: {
    textAlign: 'center',
    marginTop: 5 * theme.spacing.unit,
    marginBottom: 5 * theme.spacing.unit,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  tableOverShift: {
    top: -55,
    marginLeft: theme.spacing.unit,
    position: 'absolute',
    zIndex: 2,
  },
  wrapper: {
    position: 'relative',
    display: isIE ? 'block' : 'inline-block',
    maxWidth: '100%',
  },
  paper: {
    position: 'relative',
    margin: 'auto',
    maxWidth: '100%',
    minWidth: 900,
    width: 'fit-content',
  },
});

export default withStyles(styles)(FullTable);
