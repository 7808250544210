import configDev from './config.dev';
import configProd from './config.prod';
import configStg from './config.stg';
import configCom from './config.common';
import configLocal from './config.local';

let config;

if (process.env.REACT_APP_ENV === 'production') {
  config = configProd;
} else if (process.env.REACT_APP_ENV === 'staging') {
  config = configStg;
} else if (process.env.REACT_APP_ENV === 'development') {
  config = configDev;
} else if (window.location.host.startsWith('mocks.localhost')) {
  const { getWorker } = require('../mocks/browser');
  const backendUrl = 'http://blue-cargo-mocks-this-is-a-fake-url.io';
  const worker = getWorker(backendUrl);
  worker.start();
  config = { ...configLocal, backendUrl };
} else if (window.location.host.startsWith('local.localhost')) {
  config = configLocal;
} else if (window.location.host.startsWith('prod.localhost')) {
  config = configProd;
} else {
  config = configStg;
}
export default { ...configCom, ...config };
