import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { ReactComponent as DownArrowIcon } from 'assets/images/drop-down-arrows.svg';
import { ReactComponent as FilterIcon } from 'assets/images/filter.svg';
import { ReactComponent as AnchorIcon } from 'assets/images/anchor.svg';
import { ReactComponent as ContainerIcon } from 'assets/images/container.svg';
import { ReactComponent as BarbIcon } from 'assets/images/barb.svg';
import Autocomplete from 'components/Autocomplete';
import cn from 'classnames';
import { SegmentService } from 'services/helpers/segment';

export default class TableFilters extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    shippingLines: PropTypes.array.isRequired,
    containerTypes: PropTypes.array.isRequired,
    terminals: PropTypes.array.isRequired,
    shippingLinesFilters: PropTypes.array.isRequired,
    setShippingLinesFilters: PropTypes.func.isRequired,
    containerTypesFilters: PropTypes.array.isRequired,
    setContainerTypesFilter: PropTypes.func.isRequired,
    terminalsFilters: PropTypes.array.isRequired,
    setTerminalsFilters: PropTypes.func.isRequired,
    maxWidth: PropTypes.number,
  };

  triggerAnalytics = () => {
    SegmentService.trackEvent(
      'Empty-location Searched',
      {},
      { integrations: { Slack: false } }
    );
  };

  render() {
    const {
      classes,
      toggleMenu,
      isOpen,
      shippingLines,
      containerTypes,
      terminals,
      shippingLinesFilters,
      setShippingLinesFilters,
      containerTypesFilters,
      setContainerTypesFilter,
      terminalsFilters,
      setTerminalsFilters,
      maxWidth,
    } = this.props;

    const filterSet =
      (containerTypesFilters && Boolean(containerTypesFilters.length)) ||
      (shippingLinesFilters && Boolean(shippingLinesFilters.length)) ||
      (terminalsFilters && Boolean(terminalsFilters.lenght));
    return (
      <div className={classes.tableOverFilter} style={{ width: maxWidth - 16 }}>
        <div className={classes.toggleButton} onClick={toggleMenu}>
          <DownArrowIcon
            className={cn(classes.icon, classes.arrowIcon, {
              [classes.inverseIcon]: !isOpen,
              [classes.warning]: filterSet,
            })}
          />
          <FilterIcon
            className={cn(classes.icon, classes.filterIcon, {
              [classes.warning]: filterSet,
            })}
          />
          <Typography
            className={cn(classes.toogleButtonText, {
              [classes.warning]: filterSet,
            })}
          >
            {filterSet ? 'Filters are set' : 'Filter table'}
          </Typography>
        </div>
        <Collapse
          in={isOpen}
          collapsedHeight="14px"
          classes={{ container: classes.collapse, wrapperInner: classes.block }}
        >
          <div className={classes.filterItem}>
            <div className={classes.subtitle}>
              <AnchorIcon className={classes.icon} />
              <Typography variant="h4">Steamship lines</Typography>
            </div>
            <div className={classes.input}>
              <Autocomplete
                placeholder="Pick shipping lines"
                possibleValuesWithKey={
                  shippingLines &&
                  shippingLines.map(({ key, name }) => ({
                    value: key,
                    label: name,
                    name: name,
                    key,
                  }))
                }
                value={shippingLinesFilters}
                onChange={(data, event) => {
                  this.triggerAnalytics();
                  setShippingLinesFilters(data, event);
                }}
                classes={{ root: classes.autocompleteRoot }}
                isMulti
              />
            </div>
          </div>
          <div className={classes.filterItem}>
            <div className={classes.subtitle}>
              <ContainerIcon className={classes.icon} />
              <Typography variant="h4">Container types</Typography>
            </div>
            <div className={classes.input}>
              <Autocomplete
                placeholder="Pick container types"
                possibleValuesWithKey={
                  containerTypes &&
                  containerTypes.map(({ key, name }) => ({
                    value: key,
                    label: name,
                    name: name,
                    key,
                  }))
                }
                value={containerTypesFilters}
                onChange={(data, event) => {
                  this.triggerAnalytics();
                  setContainerTypesFilter(data, event);
                }}
                classes={{ root: classes.autocompleteRoot }}
                isMulti
              />
            </div>
          </div>
          <div className={classes.filterItem}>
            <div className={classes.subtitle}>
              <BarbIcon className={classes.icon} />
              <Typography variant="h4">Terminals</Typography>
            </div>
            <div className={classes.input}>
              <Autocomplete
                placeholder="Pick terminals"
                possibleValues={terminals}
                value={terminalsFilters}
                onChange={(data, event) => {
                  this.triggerAnalytics();
                  setTerminalsFilters(data, event);
                }}
                classes={{ root: classes.autocompleteRoot }}
                isMulti
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}
