import React from 'react';
import PropTypes from 'prop-types';
import { ChassisIcon } from 'assets/icons/Chassis';
import './Chassis.style.css';

const Chassis = ({ chassis_restrictions, isGrey }) => (
  <div className="ea-chassis">
    <ChassisIcon color={isGrey ? '#8D97A3' : '#223349'} />
    <span className="ea-chassis-name">{chassis_restrictions.join('/')}</span>
  </div>
);

Chassis.propTypes = {
  isGrey: PropTypes.bool.isRequired,
  chassis_restrictions: PropTypes.array.isRequired,
};

export default Chassis;
