import erlClient from './';

export const validateJoinRequest = async token => {
  try {
    const res = await erlClient.post('/core/api/v1/auth/company/validate', {
      token,
    });
    return res.data;
  } catch (e) {
    const res = e.response;
    if (res.status === 400) throw res.data;
    if (res.status === 403) {
      throw res.data;
    }
  }
};
