import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'services/helpers/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
// Import client to be sure is well initialized
// eslint-disable-next-line
import erlClient from 'services/clients';
import { bugsnagClient } from 'services/helpers/bugsnag';
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { UserProvider } from 'common/contexts/UserContext';

import './index.css';
import App from './App';
import theme from './theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import config from './config';
import ReactGA from 'react-ga';
import bugsnagReact from '@bugsnag/plugin-react';
import 'services/helpers/hotjar';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { bustCache } from 'utils/local-storage.util';
import { SnackbarServiceProvider } from 'common/hooks/snackbar';

if (['staging', 'production'].includes(process.env.REACT_APP_ENV)) {
  setInterval(bustCache, 1000 * 60 * 10);
  bustCache();
}

if (process.env.NODE_ENV === 'production') {
  let ga_config = {
    redactEmail: false,
  };
  if (store.getState().user.id) {
    ga_config.gaOptions = {
      userId: store.getState().user.id,
    };
  }
  ReactGA.initialize(config.GA_ID, ga_config);
  ReactGA.pageview(window.location.pathname);
}

bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

const EnhancedApp = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarServiceProvider>
              <UserProvider>
                <App />
              </UserProvider>
            </SnackbarServiceProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);
export const initializedStore = store;

ReactDOM.render(<EnhancedApp />, document.getElementById('root'));
