import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';

const AdminDesc = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Typography>Admin</Typography>
      <div>
        <Typography variant="caption" className={classes.feature}>
          <CheckIcon className={classes.check} />
          Add or approve new users
        </Typography>
      </div>
      <div>
        <Typography variant="caption" className={classes.feature}>
          <CheckIcon className={classes.check} />
          Define user roles
        </Typography>
      </div>
      <div>
        <Typography variant="caption" className={classes.feature}>
          <CheckIcon className={classes.check} />
          Choose billing details (plan, payment method etc)
        </Typography>
      </div>
    </div>
  );
};
AdminDesc.propTypes = { classes: PropTypes.object.isRequired };

const styles = theme => ({
  root: {},
  check: {
    fontSize: 15,
    marginRight: theme.spacing.unit,
    verticalAlign: 'middle',
  },
  feature: {
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles)(AdminDesc);
