import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from 'components/customDesign/Button';
import NextIcon from '@material-ui/icons/ArrowForwardIos';

export class PlanCard extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    planIcon: PropTypes.node,
    title: PropTypes.node,
    price: PropTypes.node,
    features: PropTypes.array.isRequired,
  };
  state = {
    flipped: false,
  };

  flipCard = () => this.setState({ flipped: !this.state.flipped });

  render() {
    const { classes, planIcon, title, price, features } = this.props;
    const { flipped } = this.state;

    let cardContent = (
      <div className={classes.cardContent}>
        {planIcon}
        <Typography className={classes.planName} variant="h2">
          {title}
        </Typography>
        <Typography className={classes.price}>{price}</Typography>
      </div>
    );
    if (flipped)
      cardContent = (
        <ul className={classes.details}>
          {features.map((feature, index) => (
            <Typography
              key={index}
              component="li"
              className={classes.detailItem}
            >
              {feature}
            </Typography>
          ))}
        </ul>
      );
    return (
      <div className={classes.card}>
        {cardContent}

        <Button
          classes={{ label: classes.buttonLabel }}
          className={classes.cardButton}
          rightIcon={<NextIcon className={classes.buttonIcon} />}
          onClick={this.flipCard}
        >
          {flipped ? 'See Pricing' : 'See plan feature'}
        </Button>
      </div>
    );
  }
}

const styles = theme => ({
  card: {
    backgroundColor: 'rgba(31, 51, 75, 0.05)',
    borderRadius: 5,
    height: 265,
    position: 'relative',
    padding: 2 * theme.spacing.unit,
    overflow: 'hidden',
    margin: theme.spacing.unit,
  },
  cardContent: {},
  cardButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
    margin: 0,
    '&:hover': {
      backgroundColor: 'inherit',
      opacity: 0.5,
    },
  },
  details: {
    listStyle: 'none',
    textAlign: 'left',
    padding: 2 * theme.spacing.unit,
    marginTop: 0,
  },
  detailItem: {
    color: 'rgba(31, 51, 75, 0.8)',
    fontSize: 12,
    marginBottom: theme.spacing.unit,
  },
  planName: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  price: {
    fontSize: 14,
    color: '#8E98A4',
    fontFamily: 'Arial',
  },
  buttonIcon: {
    marginLeft: theme.spacing.unit,
    fontSize: 15,
  },
  buttonLabel: {
    color: '#8E98A4',
    fontSize: 12,
    textDecoration: 'underline',
  },
});
export default withStyles(styles)(PlanCard);
