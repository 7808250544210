// Heads up! This file will eventually be deprectated.
// Prefer using services/axios-with-token.
import axios from 'axios';
import config from 'config';
import qs from 'query-string';
import { initializedStore } from '../../index';
import { history } from '../../App';
import { setPaymentError } from 'ducks/error';
import { setTokenRefresh } from 'ducks/refreshToken';
import { LocalStorageUtil } from 'utils/local-storage.util';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
} from 'constants/user';
import { setAccessTokenToLocalStorage } from 'ducks/user';

import { pageTitlesAndUrls } from 'constants/pages';

const REDIRECT_WHITELIST = Object.values(pageTitlesAndUrls)
  .filter(page => page.isPublic)
  .map(page => page.path);

const erlClient = axios.create({
  baseURL: config.backendUrl,
  'Content-Type': 'application/json',
  headers: {
    Authorization: {
      toString() {
        // const token = initializedStore.getState().user.access_token;
        const token = LocalStorageUtil.get(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
        if (token) return `Bearer ${token}`;
      },
    },
  },
});

let isTokenRefreshTry = false;

function handle401Error(error) {
  const refresh_token = LocalStorageUtil.get(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
  return erlClient
    .post('/core/api/v1/auth/refresh', {
      // refresh: initializedStore.getState().user.refresh_token,
      refresh: refresh_token,
      email: initializedStore.getState().user.email,
    })
    .then(response => {
      // initializedStore.dispatch(updateAccessToken(response.data.access));
      setAccessTokenToLocalStorage(response.data.access);
      // initializedStore.dispatch(updateRefreshToken(response.data.refresh));
      error.response.config.headers['Authorization'] =
        'Bearer ' + response.data.access;
      initializedStore.dispatch(setTokenRefresh());
      isTokenRefreshTry = false;
      return axios(error.response.config);
    })
    .catch(error => {
      isTokenRefreshTry = true;

      const { location } = history;
      const { search } = location || {};
      const queryValues = qs.parse(search);
      const { token } = queryValues || {};

      if (!token && !REDIRECT_WHITELIST.includes(location.pathname)) {
        history.push({
          pathname: '/signin',
          state: { from: history.location },
        });
      }
    });
}

function createAxiosResponseInterceptor() {
  erlClient.interceptors.response.use(
    response => {
      isTokenRefreshTry = false;
      return response;
    },
    error => {
      // Reject promise if network error
      if (!error.response) {
        console.error(error, 'NETWORK ERROR');
        // initializedStore.dispatch(
        //   setError(
        //     'Network connection error ! \n Check your internet and try to reload',
        //     error
        //   )
        // );
        return Promise.reject(error);
      }

      if (error.response.status === 402) {
        initializedStore.dispatch(setPaymentError(error.response.data.detail));
        return Promise.reject(error);
      }

      // Reject promise if usual error
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      if (
        error &&
        error.response &&
        error.response.status === 401 &&
        !isTokenRefreshTry
      ) {
        handle401Error(error);
      }

      return Promise.reject(error);
    }
  );
}
createAxiosResponseInterceptor();

export default erlClient;
