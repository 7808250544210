import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';

export class ContainerTypeCell extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    containerType: PropTypes.object.isRequired,
    rowSpan: PropTypes.number.isRequired,
    borderLeft: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    rowSpan: 1,
    borderLeft: false,
  };

  render() {
    const { containerType, rowSpan, classes, borderLeft } = this.props;
    return (
      <TableCell
        rowSpan={rowSpan}
        classes={{
          root: cn(classes.cellRoot, { [classes.borderLeft]: borderLeft }),
        }}
      >
        <Typography variant="h5">{containerType.name}</Typography>
      </TableCell>
    );
  }
}

const styles = theme => ({
  typography: {
    fontSize: 12,
    color: 'rgba(31, 51, 75, 0.7)',
  },
  cellRoot: {
    borderRight: '0.5px solid rgba(31, 51, 75, 0.1)',
  },
  borderLeft: {
    borderLeft: '1px solid rgba(31, 51, 75, 0.1)',
  },
});

export default withStyles(styles)(ContainerTypeCell);
