/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    width: 280,
    margin: theme.spacing.unit,
  },
  input: {
    padding: 2 * theme.spacing.unit,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.inputBackground.main,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  paper: {
    position: 'absolute',
    zIndex: 3,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  indicatorsContainer: {
    display: 'none',
  },
  notched: {
    display: 'none',
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },
  clear: {
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline-flex',
    margin: theme.spacing.unit,
  },
  selectAll: {
    backgroundColor: 'rgba(31, 51, 75, 0.1)',
    textAlign: 'center',
  },
  selectAllText: {
    margin: 'auto',
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: 12,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      id={props.selectProps.formId}
      InputProps={{
        inputComponent,
        classes: {
          notchedOutline: props.selectProps.classes.notched,
        },
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.3)',
        padding: '6px 0px 6px 16px',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      elevation={0}
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
      {props.isMulti && (
        <MenuItem
          className={props.selectProps.classes.selectAll}
          component="div"
          onClick={() => props.setValue(props.options)}
        >
          <Typography
            align="center"
            className={props.selectProps.classes.selectAllText}
          >
            + Search all
          </Typography>
        </MenuItem>
      )}
    </Paper>
  );
}

function IndicatorsContainer(props) {
  return null;
}

function MultiValue(props) {
  return null;
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorsContainer,
  MultiValue,
};

class IntegrationReactSelect extends React.Component {
  state = {
    menuIsOpen: false,
  };
  openMenu = () => this.setState({ menuIsOpen: true });
  closeMenu = () => this.setState({ menuIsOpen: false });
  handleDelete = data => {
    const { onChange, value } = this.props;
    onChange(value.filter(v => v.value !== data.value));
  };

  render() {
    const {
      classes,
      theme,
      placeholder,
      possibleValues,
      possibleValuesWithKey,
      value,
      onChange,
      isMulti,
      isSingleSelect,
      ...otherProps
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };
    const values = possibleValuesWithKey
      ? possibleValuesWithKey
      : possibleValues.map(suggestion => ({
          value: suggestion,
          label: suggestion,
        }));

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            options={values}
            components={components}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            isClearable
            closeMenuOnSelect={isSingleSelect || false}
            isMulti={isMulti}
            {...otherProps}
          />
          <div className={classes.divider} />
          {isMulti && value && value.length > 0 && (
            <div>
              {value.map(data => (
                <Chip
                  key={data.label}
                  label={data.label}
                  onDelete={() => this.handleDelete(data)}
                  className={classes.chip}
                  deleteIcon={<CloseIcon size="small" />}
                />
              ))}
              <Typography
                variant="caption"
                className={classes.clear}
                onClick={() => onChange([])}
              >
                Clear all
              </Typography>
            </div>
          )}
        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isSingleSelect: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
