import React from 'react';
import ChangePassword from './ChangePassword';
import styles from './ChangePassword.style';
import { useSearchParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';

export const withHooksHOC = Component => {
  return props => {
    const [searchParams] = useSearchParams();

    return <Component searchParams={searchParams} {...props} />;
  };
};

export default compose(withHooksHOC, withStyles(styles))(ChangePassword);
