export const SHIFTS_REF = {
  shift1: 'shift1',
  shift2: 'shift2',
};

export const MESSAGES = {
  noAppointmetnAvailable: 'no empty appt available',
  gateClosed: 'gate closed',
  checkTerminalPortal: 'Check terminal portal',
  noAppointmentRequired: 'no appointment required',
  registrationRequired: 'Registration is required',
  noData: 'No data',
};

export const userPlanNames = {
  pioneer: 'pioneer', //"Free trial"
  professional: 'professional', //"Per diem fighter"
  starter: 'starter', //"Starter"
  team: 'team', //"Dispatcher shop"
  trial_end: 'trial_end', //"Trial ended"
};

export const clockStatus = 'clock';

// BCIC-404 For FMS, YTI, and TTI, convert appointments to "no appointments available" when empty restriction= CLOSED
// BCIC-709 Remove logic "no appoitnment" for FMS and YTI, and apply to APM 20.10.2020
export const terminalsKeysApmTti = ['apm', 'tti'];

// BCIC-402 If appointments are after midnight, add a * before the time slot
export const timeFormat = 'hh:mm:ss';
export const timeRange = {
  from: '00:00:00',
  to: '04:59:00',
};

export const itsTerminalKey = 'its';
export const everportTerminalKey = 'everport';
export const ptscTerminalKey = 'ptsc';
export const matsonTerminalKey = 'matson';

export const validStatus = 'valid';

export const dateTimeFormat = 'MM/DD/YYYY hh:mm A';

export const chassis = {
  ownChassis: 'ownChassis',
  portChassis: 'portChassis',
};
