import { getGateScheduleEnd } from 'services/clients/gateSchedule';
import { startLoading, endLoading } from 'ducks/loader';

// Action Types
export const SET_MY_GATE_SCHEDULE = 'core/api/v1/erl/SET_MY_GATE_SCHEDULE';
export const CLEAR_MY_GATE_SCHEDULE = 'core/api/v1/erl/CLEAR_MY_GATE_SCHEDULE';

// Reducer
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_GATE_SCHEDULE:
      return action.payload;
    case CLEAR_MY_GATE_SCHEDULE:
      return initialState;
    default:
      return state;
  }
};

// Action creators
export const setGateSchedule = gateSchedule => {
  return {
    type: SET_MY_GATE_SCHEDULE,
    payload: gateSchedule,
  };
};

export const setClearGateSchedule = () => {
  return {
    type: CLEAR_MY_GATE_SCHEDULE,
  };
};

// Side effects
export const getGateSchedule = () => async dispatch => {
  try {
    dispatch(startLoading('isGateScheduleLoading'));
    const data = await getGateScheduleEnd();
    dispatch(setGateSchedule(data));
    dispatch(endLoading('isGateScheduleLoading'));
  } catch (error) {
    console.log('getGateSchedule error ', error);
    dispatch(endLoading('isGateScheduleLoading'));
  }
};
