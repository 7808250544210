import React, { useEffect, useState } from 'react';
import UpgradeYourPlanPage from '../../pages/UpgradeYourPlanPage';
import { useUserContext } from 'common/contexts/UserContext';
import { checkPermissions } from './permissions-utils';
import { useLocation } from 'react-router-dom';

const PackRestrictedRoute = ({ children }) => {
  const [hasPermission, setHasPermission] = useState(true);
  const { user } = useUserContext();
  const location = useLocation();

  useEffect(() => {
    setHasPermission(checkPermissions(user, location.pathname));
  }, [location, user]);

  if (hasPermission) {
    return children;
  }
  return <UpgradeYourPlanPage redirectedFromPage={location.pathname} />;
};

export default PackRestrictedRoute;
