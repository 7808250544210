import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MyAppointmentsService } from './services/my-appointments.service';

class MyAppointmentsTableRow extends PureComponent {
  static propTypes = {
    container_number: PropTypes.string,
    shipping_line: PropTypes.string,
    pier: PropTypes.string,
    terminal_name: PropTypes.string,
    chassis_owner: PropTypes.string,
    chassis_number: PropTypes.string,
    truck_license: PropTypes.string,
    shift: PropTypes.string,
    appointment_status: PropTypes.string,
    shift_date: PropTypes.string,
    appointment_id: PropTypes.string,
    terminal_date: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    onHandleRowClick: PropTypes.func.isRequired,
  };

  handleRowClick = () => {
    const { onHandleRowClick, ...appointment } = this.props;
    onHandleRowClick(appointment);
  };

  render() {
    const {
      container_number,
      shipping_line,
      pier,
      terminal_name,
      chassis_owner,
      chassis_number,
      truck_license,
      shift,
      appointment_status,
      shift_date,
      appointment_id,
      terminal_date,
      start_time,
      end_time,
    } = this.props;

    const formattedStatus = MyAppointmentsService.getFormattedStatus(
      appointment_status
    );
    const formattedShift = MyAppointmentsService.getFormattedShift(
      shift_date,
      shift
    );
    const formattedDatetime = MyAppointmentsService.getFormattedDatetime(
      terminal_date,
      start_time,
      end_time
    );

    return (
      <div className="my-appt-table-body-row" onClick={this.handleRowClick}>
        <div className="my-appt-table-body-row-col container_number">
          {container_number}
        </div>
        <div className="my-appt-table-body-row-col approval__appointment_status">
          <div
            className={`my-appt-table-body-row-col-status-label ${formattedStatus}`}
          >
            {formattedStatus}
          </div>
        </div>
        <div className="my-appt-table-body-row-col shipping_line">
          {shipping_line}
        </div>
        <div className="my-appt-table-body-row-col terminal">{`${pier} (${terminal_name})`}</div>
        <div className="my-appt-table-body-row-col shift">{formattedShift}</div>
        <div className="my-appt-table-body-row-col datetime">
          {formattedDatetime}
        </div>
        <div className="my-appt-table-body-row-col confirmation_number">
          {appointment_id || '-'}
        </div>
        <div className="my-appt-table-body-row-col chassis_owner">
          {chassis_owner || '-'}
        </div>
        <div className="my-appt-table-body-row-col chassis_number">
          {chassis_number || '-'}
        </div>
        <div className="my-appt-table-body-row-col driver_license">
          {truck_license || '-'}
        </div>
      </div>
    );
  }
}

export default MyAppointmentsTableRow;
