// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import classNames from 'classnames';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

// HOC
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isError, clearError } from 'ducks/error';
import { pageTitlesAndUrls } from 'constants/pages';

import { stopClockWarningMessage } from 'pages/T_Appointments/constants/appointments.constants';

export class ErrorSnackbar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  render() {
    const { classes, message, onClose, open } = this.props;

    const textContent =
      message === stopClockWarningMessage ? (
        <Link
          to={pageTitlesAndUrls.plans.path}
          target="_blank"
          style={{ color: '#FFFFFF' }}
        >
          {message}
        </Link>
      ) : (
        message
      );

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={onClose}
      >
        <SnackbarContent
          className={classes.error}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <ErrorIcon
                className={classNames(classes.icon, classes.iconVariant)}
              />
              {textContent}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    );
  }
}

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const mapStateToProps = state => ({
  open: isError(state),
  message: state.error.message,
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(clearError()),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ErrorSnackbar);
