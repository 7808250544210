import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import Typography from '@material-ui/core/Typography';
import PlanCard from '../PlanCard';
import Slider from 'react-slick';

import { ReactComponent as StarterIcon } from 'assets/icons/starter_icon.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/team_icon.svg';
import { ReactComponent as EnterpriseIcon } from 'assets/icons/enterprise_icon.svg';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import PrevIcon from '@material-ui/icons/ArrowBackIos';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const arrowStyle = theme => ({
  icon: {
    color: theme.palette.primary.main,
    '&:hover': { color: theme.palette.primary.light },
  },
  iconDisabled: {
    display: 'none !important',
  },
});

const NextArrow = withStyles(arrowStyle)(props => {
  const { className, style, onClick, classes } = props;
  return (
    <NextIcon
      className={cn(className, classes.icon, {
        [classes.iconDisabled]: className.includes('disable'),
      })}
      style={style}
      onClick={onClick}
    />
  );
});

const PrevArrow = withStyles(arrowStyle)(props => {
  const { className, style, onClick, classes } = props;
  return (
    <PrevIcon
      className={cn(className, classes.icon, {
        [classes.iconDisabled]: className.includes('disable'),
      })}
      style={style}
      onClick={onClick}
    />
  );
});

export default class PlanForm extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({
      pricing_plan: PropTypes.number.isRequired,
    }).isRequired,
    hideSubtitle: PropTypes.bool.isRequired,
  };
  static defaultProps = {
    hideSubtitle: false,
  };

  handleChange = value =>
    this.props.handleChange({ target: { name: 'pricing_plan', value } });

  render() {
    const { classes, values, hideSubtitle } = this.props;

    return (
      <div>
        {!hideSubtitle && (
          <Typography variant="caption" className={classes.subtitle}>
            Select a plan
          </Typography>
        )}
        <Slider
          initialSlide={values.pricing_plan}
          dots
          infinite={false}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          afterChange={this.handleChange}
        >
          <div>
            <PlanCard
              planIcon={<StarterIcon />}
              title="Pioneer"
              price={
                <React.Fragment>
                  Price: <span className={classes.hightlight}>Free</span>
                </React.Fragment>
              }
              features={[
                '- Terminals instructions',
                '- Gate open hours',
                '- Individual account',
                '- Live chat',
                '- Per diem archive system, $5 /download',
              ]}
            />
          </div>
          <div>
            <PlanCard
              planIcon={<TeamIcon />}
              title="Team"
              price={
                <React.Fragment>
                  Price: $299 /month <br />
                  <span className={classes.hightlight}>free for 15 days</span>
                </React.Fragment>
              }
              features={[
                '- All of the Starter features, plus',
                '- Notifications of changes',
                '- First appointment date',
                '- 50 per diem proof archive downloads, then $1 per download',
                '- Team management, 15 user accounts',
              ]}
            />
          </div>
          <div>
            <PlanCard
              planIcon={<EnterpriseIcon />}
              title="Professional"
              price={<React.Fragment>Price: $889 /month</React.Fragment>}
              features={[
                '- All of the team features, plus',
                '- Unlimited per diem archive downloads',
                '- Unlimited number of users',
                '- Priority support',
              ]}
            />
          </div>
        </Slider>
      </div>
    );
  }
}
