import React from 'react';
import { GridOverlay } from '@mui/x-data-grid-pro';

const NoContainersFoundOverlay = () => {
  return (
    <GridOverlay>
      <div className="flex flex-col space-y-1 items-center">
        <span
          role="img"
          className="text-orange"
          aria-label="warning emoji"
          style={{ fontSize: '48px' }}
        >
          ⚠️
        </span>
        <h3>
          No Containers found for your selection.{' '}
          <span className="font-medium">
            Please add containers in the search bar or change your filter
            selection.
          </span>
        </h3>
      </div>
    </GridOverlay>
  );
};

export default NoContainersFoundOverlay;
