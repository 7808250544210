import { CELL_WIDTH_192, CELL_WIDTH_130 } from 'constants/layout';

const basic = {
  cell: {
    borderRight: '1px solid #EFF0F1',
    maxWidth: CELL_WIDTH_130,
    minWidth: CELL_WIDTH_130,
    fontSize: '0.75rem',
    padding: '8px',
  },
  triangle: {
    width: 0,
    borderWidth: '5px',
    borderStyle: 'solid',
  },
  headerCellTagContentBasic: {
    color: '#FFFFFF',
    fontSize: '7px',
    lineHeight: '8px',
    height: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '24px',
    minWidth: '24px',
  },
};

const checkAppointmentsPortalBasic = {
  ...basic.cell,
  color: '#8D97A3',
};

export default theme => ({
  table: {
    width: 'auto',
  },
  headerCell1: {
    border: '1px solid #EFF0F1',
    width: CELL_WIDTH_192,
    maxWidth: CELL_WIDTH_192,
    minWidth: CELL_WIDTH_192,
    height: '89px',
    backgroundColor: '#FFF',
    fontSize: '0.75rem',
    position: 'relative',
  },
  cell: {
    ...basic.cell,
    backgroundColor: '#FFF',
    cursor: 'pointer',
  },
  headerCellTag: {
    position: 'absolute',
    transform: 'rotate(-45deg)',
    height: '10px',
    top: '7px',
    left: '-10px',
    display: 'flex',
  },
  headerCellTagContent: {
    ...basic.headerCellTagContentBasic,
    backgroundColor: '#8E98A4',
  },
  headerCellTagContentGreen: {
    ...basic.headerCellTagContentBasic,
    backgroundColor: '#51A221',
  },
  triangleLeft: {
    ...basic.triangle,
    borderColor: 'transparent #8E98A4 #8E98A4 transparent',
  },
  triangleRight: {
    ...basic.triangle,
    borderColor: 'transparent transparent #8E98A4 #8E98A4',
  },
  triangleLeftGreen: {
    ...basic.triangle,
    borderColor: 'transparent #51A221 #51A221 transparent',
  },
  triangleRightGreen: {
    ...basic.triangle,
    borderColor: 'transparent transparent #51A221 #51A221',
  },
  shiftTotal: {
    fontStyle: 'italic',
    fontSize: '0.625rem',
  },
  cellNoAppointments: {
    ...basic.cell,
    color: '#8D97A3',
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
  },
  cellNoAppointmentsFat: {
    ...basic.cell,
    color: '#8D97A3',
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
    borderBottom: '3px solid #EFF0F1',
  },
  noRequiredCellFat: {
    ...basic.cell,
    borderBottom: '3px solid #EFF0F1',
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
    color: '#8D97A3',
  },
  noRequiredCell: {
    ...basic.cell,
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
    color: '#8D97A3',
  },
  checkAppointmentsPortalFat: {
    ...checkAppointmentsPortalBasic,
    borderBottom: '3px solid #EFF0F1',
    backgroundColor: '#FFF',
  },
  checkAppointmentsPortalFatGrey: {
    ...checkAppointmentsPortalBasic,
    borderBottom: '3px solid #EFF0F1',
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
    position: 'relative',
  },
  checkAppointmentsPortal: {
    ...checkAppointmentsPortalBasic,
    backgroundColor: '#FFF',
  },
  checkAppointmentsPortalGrey: {
    ...checkAppointmentsPortalBasic,
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
    borderBottom: '1px solid #EFF0F1',
    position: 'relative',
  },
  cellAppointmentsFat: {
    ...basic.cell,
    backgroundColor: '#FFF',
    borderBottom: '3px solid #EFF0F1',
    cursor: 'pointer',
  },
  cellBadCredentials: {
    ...basic.cell,
    backgroundColor: '#D5D8E1',
    borderBottom: 'none',
  },
  cellBadCredentialsText: {
    position: 'relative',
    top: '0px',
    fontSize: '14px',
    lineHeight: '16px',
  },
  cellBadCredentialsLink: {
    color: '#0740D3',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    cursor: 'pointer',
  },
  showHideNextShiftsHide: {
    backgroundColor: '#FAFAFA',
    padding: '4px 20px 4px 17px',
  },
  showHideNextShiftsShow: {
    backgroundColor: '#FAFAFA',
    padding: '4px 20px 8px 17px',
  },
  cellAppointmentsGrey: {
    ...basic.cell,
    color: '#8D97A3',
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
    cursor: 'pointer',
  },
  cellAppointmentsGreyFat: {
    ...basic.cell,
    color: '#8D97A3',
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
    borderBottom: '3px solid #EFF0F1',
    cursor: 'pointer',
  },
});
