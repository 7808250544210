import React from 'react';
import { CELL_WIDTH_192, CELL_WIDTH_130 } from 'constants/layout';
import { SegmentService } from 'services/helpers/segment';
import { brandColors } from 'brandColors';
import fonts from 'styles/typography.module.css';
import { useNavHeightContext } from 'common/contexts/NavHeightContext';

const TableHeader = ({
  terminalsCount,
  terminalMap,
  selectedSL,
  selectedContainer,
  currentTime,
}) => {
  const { marginTop } = useNavHeightContext();

  const handleTriggerAnalytics = terminal => {
    SegmentService.trackEvent(
      'Outbound Terminal Clicked',
      {
        terminal: terminal.key,
        url: '/empty-appointments',
      },
      { integrations: { Slack: false } }
    );
  };

  return (
    <div className="sticky " style={{ zIndex: 100, top: marginTop }}>
      <div className={fonts.font}>
        <RenderTitle
          selectedSL={selectedSL}
          selectedContainer={selectedContainer}
          currentTime={currentTime}
        />
        <div className="flex bg-white">
          <div
            style={{
              minWidth: `${CELL_WIDTH_192}px`,
              borderRight: `0.5px solid ${brandColors.colors.blue[500]}`,
              borderBottom: `0.5px solid ${brandColors.colors.blue[500]}`,
            }}
            className="px-4 flex items-center text-center font-medium border border-blue-500 shadow-b"
          >
            <h5 className="text-dark">
              Available Terminals ({terminalMap.length}/{terminalsCount})
            </h5>
          </div>
          <div className="flex">
            {terminalMap.map((terminal, i) => {
              return (
                <div
                  key={i}
                  className="py-2 flex justify-center border-blue-500 shadow-b"
                  style={{
                    width: `${CELL_WIDTH_130}px`,
                    border: `0.5px solid ${brandColors.colors.blue[500]}`,
                  }}
                  onClick={() => handleTriggerAnalytics(terminal)}
                >
                  <a
                    href={terminal.website}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={`flex flex-col space-y-2 items-center`}
                  >
                    <div style={{ width: '64px' }}>
                      <img src={terminal.logo} alt={terminal.name} />
                    </div>
                    <h5 className="font-medium text-blue underline">
                      {terminal.name}
                    </h5>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;

const RenderTitle = ({ selectedSL, selectedContainer, currentTime }) => {
  return selectedSL &&
    selectedSL.name &&
    selectedContainer &&
    selectedContainer.name ? (
    <div className={fonts.font}>
      <div className="bg-white pt-8 pb-4 w-full flex space-x-4 items-center">
        <h3 className="font-medium text-dark">
          Next available appointments for{' '}
        </h3>
        <div className="px-4 py-2 font-bold text-white bg-legacyBlue rounded-full">
          <h4>{selectedSL.name}</h4>
        </div>
        <div className="px-4 py-2 font-bold text-white bg-legacyBlue rounded-full">
          {selectedContainer ? <h4>{selectedContainer.name}</h4> : <></>}
        </div>
        <h3 className="font-medium text-dark">as of</h3>
        <div className="px-4 py-2 font-bold text-white bg-legacyBlue rounded-full">
          <h4>{currentTime}</h4>
        </div>
      </div>
    </div>
  ) : null;
};
