import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import css from './gateSchedule.module.css';
import {
  setEditableGateScheduleItem,
  updateGateSchedule,
} from '../../../ducks/admin';

const TableItem = props => {
  const { dateIndex, currentValue, headers, terminal } = props;

  const [editMode, setEditMode] = useState();
  const [rowValue, setRowVale] = useState();

  const date = headers[dateIndex];

  useEffect(() => {
    setRowVale(currentValue);
  }, [currentValue]);

  useEffect(() => {
    const editableItem = props.editableRows.find(
      element =>
        element.terminal === terminal &&
        element.date === date.date &&
        element.shift === date.shift
    );
    setEditMode(!!editableItem);
  }, [date, rowValue, props.editableRows]);

  const onChange = newValue => {
    props.updateGateSchedule(
      props.weekNumber,
      props.year,
      props.editableRows,
      newValue
    );
  };

  const onClick = () => {
    if (!editMode)
      props.setEditableGateScheduleItem(terminal, date.date, date.shift);
    setEditMode(true);
  };

  return (
    <td onClick={onClick} className={css.tableItemEditRow}>
      {editMode ? (
        <RowEditorContainer
          onChange={onChange}
          rowValue={currentValue}
          availableStatuses={props.availableStatuses}
          setRowVale={setRowVale}
        />
      ) : (
        <div>{currentValue}</div>
      )}
    </td>
  );
};

const RowEditorContainer = props => {
  const { availableStatuses } = props;

  return (
    <div>
      <select
        onChange={e => props.onChange(e.target.value)}
        value={props.rowValue}
        className={css.selectItemEdit}
      >
        {availableStatuses.map((status, index) => (
          <option
            key={index}
            value={status}
            className={[props.rowValue === status && css.optionItemEdit]}
          >
            {status}
          </option>
        ))}
      </select>
    </div>
  );
};

TableItem.propTypes = {
  dateIndex: PropTypes.number,
  currentValue: PropTypes.string,
  headers: PropTypes.array,
  terminal: PropTypes.string,
  editableRows: PropTypes.array,
  updateGateSchedule: PropTypes.func,
  weekNumber: PropTypes.number,
  year: PropTypes.number,
  setEditableGateScheduleItem: PropTypes.func,
  availableStatuses: PropTypes.array,
};

RowEditorContainer.propTypes = {
  availableStatuses: PropTypes.array,
  onChange: PropTypes.func,
  rowValue: PropTypes.string,
};

const mapStateToProps = state => ({
  availableStatuses: state.admin.gateSchedule.availableStatuses,
  editableRows: state.admin.gateSchedule.editableRows,
  weekNumber: state.admin.gateSchedule.weekNumber,
  year: state.admin.gateSchedule.year,
});

const mapDispatchToProps = {
  setEditableGateScheduleItem,
  updateGateSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableItem);
