import { camelCase, upperFirst } from 'lodash';
import { PICKUP_HOLD_KEYS } from '../container-config';
import { getDateDiff } from '../../../utils/common';
import moment from 'moment';
import { laTimezone } from '../../../services/helpers/data';

export function getPickupInfo(pickupInfo) {
  if (!pickupInfo) return {};
  const result = Object.keys(pickupInfo).reduce(
    (acc, key) => {
      const pickupColumn = PICKUP_HOLD_KEYS.find(hold => hold.key === key);
      if (pickupColumn) {
        acc.holds[pickupColumn.name] = pickupInfo[key] || {};
        return acc;
      }
      acc[`pickup${upperFirst(camelCase(key))}`] = pickupInfo[key];
      return acc;
    },
    { holds: {} }
  );
  result.pickupDemurrageFeeUsCents = getDemurrageFee(
    result.pickupDemurrageFeeUsCents
  );
  result.pickupLastFreeDay =
    result.pickupLastFreeDay || result.pickupGoodThroughDate;
  result.pickupGatedOutDate = result.pickupGatedOutDate
    ? laTimezone(result.pickupGatedOutDate)
    : null;

  return result;
}

export function getDeltaLastFreeDay(lastFreeDay, compareTo) {
  const now = compareTo || moment();
  const nbDays = getDateDiff(now, lastFreeDay, 'days', false, true);
  let text = '';

  if (nbDays === 0) {
    text = 'today';
  } else {
    const absoluteValue = Math.abs(nbDays);
    if (nbDays > 0) {
      text = `+${nbDays}`;
    } else {
      text = `-${absoluteValue}`;
    }
    text = text + (absoluteValue === 1 ? ' day' : ' days');
  }
  return {
    text,
    nbDays,
  };
}

function getDemurrageFee(fee) {
  const parsed = parseInt(fee);
  const asNumber = isNaN(parsed) ? 0 : parsed;
  return Math.abs(asNumber);
}
