// Action Types
export const DISPLAY_SW_SNACKBAR =
  'core/api/v1/erl/serviceWorker/DISPLAY_SW_SNACKBAR';

// Reducer
const initialState = {
  displaySnackbar: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_SW_SNACKBAR:
      return { ...state, displaySnackbar: true };
    default:
      return state;
  }
};
