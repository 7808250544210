// Action Types
export const SET_TOKEN_REFRESH =
  'core/api/v1/erl/availabilities/SET_TOKEN_REFRESH';

// Reducer
const initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN_REFRESH:
      return !state;
    default:
      return state;
  }
};

// Action creators
export const setTokenRefresh = () => {
  return { type: SET_TOKEN_REFRESH };
};
