import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Tooltip from 'components/customDesign/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCurrentGateHour } from 'ducks/availabilities';
import cn from 'classnames';

function TerminalCellContent({
  terminal,
  classes,
  gateHours,
  onHandleTerminalClick,
}) {
  return (
    <div className={cn(classes.root)}>
      <div className={`flex flex-col items-center ${classes.port}`}>
        <div className={classes.logoContainer}>
          <img
            src={terminal.logo}
            alt={terminal.name}
            className={classes.logo}
          />
        </div>
        <div onClick={() => onHandleTerminalClick(terminal.key)}>
          <Tooltip tooltipText={terminal.pier || terminal.name}>
            <ReactGA.OutboundLink
              to={terminal.website}
              target="_blank"
              trackerNames={undefined}
              eventLabel={terminal.name}
            >
              <Typography
                variant="caption"
                color="primary"
                classes={{ root: classes.underlined }}
              >
                {terminal.pier || terminal.name}
              </Typography>
            </ReactGA.OutboundLink>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

const styles = theme => ({
  underlined: {
    textDecoration: 'underline',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    backgroundColor: '#fff',
  },
  closeRoot: {
    backgroundColor: theme.palette.alert.light,
  },
  logoContainer: {
    height: '25px',
    objectFit: 'contain',
    marginBottom: theme.spacing.unit,
    padding: `0 ${theme.spacing.unit}px`,
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  port: {
    textAlign: 'center',
    height: theme.size.portIconLink.height,
  },
});

TerminalCellContent.propTypes = {
  terminal: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  gateHours: PropTypes.object,
  onHandleTerminalClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  gateHours: getCurrentGateHour(state, ownProps.terminal.key),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(TerminalCellContent);
