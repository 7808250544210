import React from 'react';
import { useNavigate } from 'react-router-dom';

import BlueCargoLogo from 'components/BlueCargoLogo';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { SHOW_UPGRADE_BUTTON } from './utils/config';
import { pageTitlesAndUrls } from '../../constants/pages';

const MobileNav = ({ handleMenuOpen, isMenuOpen, user }) => {
  const navigate = useNavigate();

  const onUpgradeClick = () => {
    navigate(pageTitlesAndUrls.plans.path);
  };
  return (
    <div className="w-full h-full px-8 bg-blue flex items-center justify-between">
      <BlueCargoLogo />
      <div className="flex space-x-8">
        {/* upgrade button */}
        {SHOW_UPGRADE_BUTTON.includes(user.pack) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onUpgradeClick}
          >
            <h4 className="font-bold">Upgrade your plan</h4>
          </Button>
        )}
        {!isMenuOpen && (
          <MenuIcon
            className="text-white cursor-pointer"
            style={{ fontSize: '36px' }}
            onClick={handleMenuOpen}
          />
        )}
        {isMenuOpen && (
          <CloseIcon
            className="text-white cursor-pointer"
            style={{ fontSize: '36px' }}
            onClick={handleMenuOpen}
          />
        )}
      </div>
    </div>
  );
};

export default MobileNav;
