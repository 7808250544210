import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/customDesign/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from 'components/customDesign/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

export default class Formular extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  render() {
    const {
      handleSubmit,
      errors,
      touched,
      handleChange,
      handleBlur,
      values,
      classes,
      isSubmitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className={classes.form}>
        <FormControl
          required
          margin="normal"
          fullWidth
          className={classes.field}
          error={errors.email && touched.email}
        >
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your Email"
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <FormHelperText>
            {errors.email && touched.email && errors.email}
          </FormHelperText>
        </FormControl>
        <Button
          type="submit"
          size="large"
          fullWidth
          classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
          className={classes.submit}
          disabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Send a reset link
        </Button>
      </form>
    );
  }
}
