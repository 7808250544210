import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

export default class Select extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    values: PropTypes.array,
    selected: PropTypes.any,
    onChange: PropTypes.func,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    extractValue: PropTypes.func.isRequired,
    extractKey: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleChange: PropTypes.func,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    disableOutlined: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired,
    disableLabel: PropTypes.bool.isRequired,
  };
  state = {
    labelWidth: 0,
  };

  static defaultProps = {
    extractValue: a => a,
    extractKey: a => a,
    isLoading: false,
    isError: false,
    disableOutlined: false,
    disableLabel: false,
    mobile: false,
  };
  componentDidMount() {
    if (!this.props.disableLabel)
      this.setState({
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      });
  }

  handleChange = event => {
    if (this.props.handleChange) this.props.handleChange(event);
    else this.props.onChange(event.target.value);
  };

  render() {
    const {
      classes,
      values,
      selected,
      label,
      id,
      extractValue,
      extractKey,
      isLoading,
      handleChange,
      isError,
      errorMessage,
      onChange,
      disableOutlined,
      mobile,
      disableLabel,
      ...other
    } = this.props;

    if (isLoading)
      return (
        <div className={classes.margin}>
          <CircularProgress />
        </div>
      );

    if (!values) return null;
    return (
      <FormControl
        variant={!disableOutlined ? 'outlined' : 'standard'}
        className={classes.margin}
        margin="normal"
        error={isError}
        {...other}
      >
        {!disableLabel && (
          <InputLabel
            ref={ref => {
              this.InputLabelRef = ref;
            }}
            htmlFor={id}
          >
            {label}
          </InputLabel>
        )}
        <MuiSelect
          native={mobile}
          value={selected}
          onChange={this.handleChange}
          input={
            !disableOutlined ? (
              <OutlinedInput
                labelWidth={!disableLabel ? this.state.labelWidth : 0}
                name={id}
                id={id}
                classes={{
                  input: classes.input,
                  notchedOutline: classes.focused,
                }}
              />
            ) : (
              <Input name={id} id={id} />
            )
          }
          {...other}
        >
          {mobile
            ? [
                <option key="label" value="" disabled>
                  {label}
                </option>,
                values.map(port => (
                  <option key={extractKey(port)} value={port}>
                    {extractValue(port)}
                  </option>
                )),
              ]
            : [
                <MenuItem key="label" disabled>
                  <em>{label}</em>
                </MenuItem>,
                values.map(port => (
                  <MenuItem key={extractKey(port)} value={port}>
                    {extractValue(port)}
                  </MenuItem>
                )),
              ]}
        </MuiSelect>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    );
  }
}
