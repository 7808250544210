import _ from 'lodash';

export const CONTAINER_NUMBERS_RE = /[A-Z]{3}[UJZR]\d{7}$/;
export const MULTIPLE_CONTAINER_NUMBERS_RE = /[A-Z]{3}[UJZR]\d{7}/g;

/* input argument can be list or string */
export function parseContainerNumbers(input = '') {
  let containerNumberList = [];
  if (typeof input === 'string') {
    containerNumberList = input.match(MULTIPLE_CONTAINER_NUMBERS_RE);
  } else if (Array.isArray(input)) {
    containerNumberList = input.filter(i => CONTAINER_NUMBERS_RE.test(i));
  }
  if (_.isNil(containerNumberList)) {
    containerNumberList = [];
  }
  return [...containerNumberList];
}
