import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeProvider } from '../themeProvider';
import { Modal } from '@material-ui/core';
import { Button } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import CloseIcon from '@mui/icons-material/Close';
import { tagVariantMapping } from '../pages/ContainerTracking/components/ContainerStatusTag';
import { DisplayTimeRange } from '../pages/ContainerTracking/utils/cells';
import { showChassisRestrictions } from '../pages/ContainerTracking/utils/cells';
import style from '../styles/typography.module.css';

const AllEmptyAppointmentsModal = ({ open, onClose, modalInformation }) => {
  const {
    size,
    status,
    shippingLine,
    containerNumber,
    pickupImportStatus,
    appointments,
  } = modalInformation;

  const { variant, statusText } = tagVariantMapping(status, pickupImportStatus);

  return (
    <ThemeProvider theme={themeProvider}>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="delete-containers-modal"
        aria-describedby="verifies-user-container-deletion"
        sx={{
          zIndex: '1300',
          borderRadius: '0.375rem',
        }}
      >
        <div className={style.font}>
          <div
            className="absolute left-1/2 bg-blue-600 shadow-md rounded-md"
            style={{
              transform: 'translate(-50%, -50%)',
              width: '448px',
              height: '480px',
              top: '40%',
            }}
          >
            <div className="w-full h-full relative">
              <CloseIcon
                className="absolute top-2 right-2 text-gray-500 cursor-pointer hover:text-gray-300"
                onClick={onClose}
              />
              {/* Title */}
              <div className="px-6 pt-6 pb-4 flex flex-col space-y-4 shadow-xs">
                <div className="flex flex-col space-y-0">
                  <h3 className="font-regular text-gray-200">
                    All return appointments for
                  </h3>
                  <h1 className="font-black tracking-widest">
                    {containerNumber}
                  </h1>
                </div>
                <div className="flex space-x-2">
                  <Button variant={variant}>{statusText}</Button>
                  <Button variant="tag-primary">{shippingLine}</Button>
                  <Button variant="tag-primary">{size}</Button>
                </div>
              </div>
              {/* Appointment List */}
              <div className="">
                <AppointmentList appointments={appointments} />
              </div>
              {/* Footer */}
              <div className="absolute bottom-0 left-0 right-0 p-4 flex justify-end border-t border-blue-400">
                <Button variant="contained" color="info" onClick={onClose}>
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
};

const AppointmentList = ({ appointments }) => {
  return (
    <div className="overflow-y-scroll" style={{ height: '268px' }}>
      {appointments.map((appointment, index) => {
        return (
          <Appointment
            key={index}
            appointment={appointment}
            firstAvailable={index === 0}
          />
        );
      })}
    </div>
  );
};

const Appointment = ({ appointment, firstAvailable }) => {
  return (
    <div className="w-full px-6 py-4 flex flex-col space-y-2 cursor-pointer hover:bg-blue-500">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center underline text-blue space-x-2">
          <a
            target="_blank"
            href={appointment.terminalAppointmentUrl}
            rel="noreferrer"
          >
            <div className="flex space-x-1 items-center">
              <LaunchIcon style={{ fontSize: 14 }} />
              <h5 className="font-semibold">
                <span className="uppercase">{appointment.terminalId}</span>

                <DisplayTimeRange
                  startDateTime={appointment.periodFromDate}
                  endDateTime={appointment.periodToDate}
                />

                {appointment.slots ? `(${appointment.slots})` : ''}
              </h5>
            </div>
          </a>
        </div>
      </div>
      <div className="flex relative bottom-0.5">
        {appointment.gatedInDate && (
          <h6 className="uppercase font-medium text-gray-300">returned</h6>
        )}
        {firstAvailable ? (
          <h6 className="uppercase font-medium text-gray-300">
            first available&nbsp;
          </h6>
        ) : (
          <h6 className="uppercase font-medium text-gray-300">
            <DisplayShift shift={appointment.shiftId} />
            &nbsp;
          </h6>
        )}
        {appointment.availabilityId === null ? (
          <h6 className="uppercase font-medium text-gray-300">
            · Awaiting terminal instructions &nbsp;
          </h6>
        ) : (
          <div className="flex">
            {appointment.availabilityId === 'dual' && (
              <div className="flex items-center">
                <h6 className="uppercase font-medium text-gray-300">
                  · dual only &nbsp;
                </h6>
              </div>
            )}
            <h6 className="uppercase font-medium text-gray-300">
              {showChassisRestrictions(appointment.chassisRestrictions)}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

const DisplayShift = ({ shift }) => {
  const letter = shift.match(/[a-zA-Z]+/g);
  const number = shift.match(/\d+/g);

  const displayShift = `${letter} ${number}`;

  return `${displayShift}`;
};

export default AllEmptyAppointmentsModal;
