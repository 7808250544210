import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import mediaQueries from '../mediaqueries';
import { logout } from 'ducks/user';
import { checkPermissions } from 'components/PrivateRoute/permissions-utils';
import { useUserContext } from 'common/contexts/UserContext';
import { useNavHeightContext } from 'common/contexts/NavHeightContext';
import DisplayContainerTracking from 'components/DisplayContainerTracking';

const SIDEBAR_WIDTH = 226;

const MOBILE_HEIGHT = 50;

const SideNavigationLayout = ({
  children,
  currentPath,
  sideNavigationContent,
  isLogout = false,
}) => {
  const tablet = useMediaQuery(`(max-width:${mediaQueries.md})`);
  const { user } = useUserContext();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  if (!tablet) {
    return (
      <SideNavigationDesktop
        children={children}
        currentPath={currentPath}
        sideNavigationContent={sideNavigationContent}
        isLogout={isLogout}
        handleLogout={handleLogout}
        user={user}
      />
    );
  }

  if (tablet) {
    return (
      <TopNavigationMobile
        children={children}
        currentPath={currentPath}
        sideNavigationContent={sideNavigationContent}
        isLogout={isLogout}
        handleLogout={handleLogout}
        user={user}
      />
    );
  }
};

const SideNavigationDesktop = ({
  children,
  currentPath,
  sideNavigationContent,
  isLogout,
  handleLogout,
  user,
}) => {
  const { marginTop } = useNavHeightContext();
  return (
    <main className="flex w-full">
      {/* sidebar */}
      <div
        style={{
          width: `${SIDEBAR_WIDTH}px`,
          height: `calc(100vh - ${marginTop}px)`,
        }}
        className="fixed flex flex-col bg-white border-r border-blue-500 pt-4 pb-8 pl-2"
      >
        {sideNavigationContent.map((nav, index) => {
          return (
            <Accordion
              disableGutters
              elevation={0}
              defaultExpanded={index === 0}
              key={`p-${index}`}
              sx={[
                () => ({
                  '&:before': {
                    backgroundColor: 'white',
                  },
                }),
              ]}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ margin: 0 }}
              >
                <div className="">
                  <h4 className="font-bold">{nav.title}</h4>
                </div>{' '}
              </AccordionSummary>
              <AccordionDetails>
                <div className="border-l border-blue-500 pl-3">
                  <ul className="flex flex-col space-y-4">
                    {nav.sideNavigationLinks &&
                      nav.sideNavigationLinks
                        .filter(item => checkPermissions(user, item.path))
                        .map((item, idx) => {
                          return (
                            <li key={idx} className="flex">
                              <hr
                                className={` ${currentPath === item.path &&
                                  'absolute left-4 h-5 w-0.5 bg-blue'}`}
                              ></hr>
                              <Link to={item.path}>
                                <h4
                                  className={`capitalize ${
                                    currentPath === item.path
                                      ? 'font-bold text-blue'
                                      : 'font-regular text-gray-100'
                                  }`}
                                >
                                  {item.title}
                                </h4>
                              </Link>
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <div className="w-full mt-auto px-2 border-t border-blue-500 pt-4 px-4">
          {user.company && <DisplayContainerTracking />}
          {isLogout && (
            <div className="mt-16">
              <Button variant="link" onClick={handleLogout}>
                <h4 className="font-bold text-dark">Log Out</h4>
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        className="w-full h-full px-8 py-12 bg-blue-700"
        style={{ marginLeft: `${SIDEBAR_WIDTH}px` }}
      >
        {children}
      </div>
    </main>
  );
};

const TopNavigationMobile = ({
  children,
  currentPath,
  sideNavigationContent,
  isLogout,
  handleLogout,
  user,
}) => {
  return (
    <main>
      <div
        className="overflow-auto	whitespace-nowrap w-full px-8 bg-white border-b border-blue-500"
        style={{ height: `${MOBILE_HEIGHT}px` }}
      >
        {/* content */}
        <ul className=" h-full flex items-center space-x-4">
          {sideNavigationContent &&
            sideNavigationContent.map((nav, index) => {
              return (
                <ul
                  key={nav.title}
                  className="w-full flex space-x-8 items-center"
                >
                  {nav.sideNavigationLinks &&
                    nav.sideNavigationLinks
                      .filter(item => checkPermissions(user, item.path))
                      .map((item, idx) => {
                        return (
                          <li key={idx}>
                            <Link to={item.path}>
                              <h4
                                className={`capitalize ${
                                  currentPath === item.path
                                    ? 'font-bold text-blue'
                                    : 'font-regular text-gray-100'
                                }`}
                              >
                                {item.title}
                              </h4>
                            </Link>
                          </li>
                        );
                      })}
                </ul>
              );
            })}
        </ul>
      </div>
      <div className="w-full px-8 py-12 bg-blue-700">{children}</div>
    </main>
  );
};

export default SideNavigationLayout;
