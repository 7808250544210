export const LocalStorageKeys = {
  shippingLineKey: 'shippingLineKey',
  containerKey: 'containerKey',
};

export const GAsearchDelay = 10000;

export const GET_EMPTY_APPOINTMENTS_INTERVAL = 60000;

export const stopClockWarningMessage =
  'Only accessible to D&D Fighter users, please upgrade';

export const stopTheClockName = 'stopTheClock';
export const stopTheClocDownloadValue = 'download';
export const stopTheClockOptions = {
  title: {
    value: 'stopTheClock',
    label: 'STOP THE CLOCK',
  },
  options: [
    {
      value: stopTheClocDownloadValue,
      label: 'Download PDF',
    },
    {
      value: 'sendViaEmail',
      label: 'Receive by email',
    },
  ],
};

export const more20ContainersMsg = 'Please delete containers (20 max)';
