import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { compose } from 'redux';
import TabContent from './TabContent';

const TerminalTabs = ({ classes, snapshotUrls, ports }) => {
  if (snapshotUrls.length === 0) return null;
  if (ports.length === 0) return null;
  const tabs = snapshotUrls.map(({ terminal }) => {
    const port = ports.find(({ key }) => key === terminal);
    const { pier } = port || {};
    return {
      key: terminal,
      pier: pier,
    };
  });
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);

  return (
    <div className={classes.root}>
      <Tabs
        value={selectedTab}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
          flexContainer: classes.tabsContainer,
        }}
        onChange={(event, value) => setSelectedTab(value)}
      >
        {tabs.map(({ key, pier }) => (
          <Tab
            key={key}
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label={<Typography className={classes.label}>{pier}</Typography>}
            value={key}
          />
        ))}
      </Tabs>
      <TabContent
        key={selectedTab}
        snapshotUrl={snapshotUrls.find(s => s.terminal === selectedTab)}
        terminal={ports.find(p => p.key === selectedTab)}
      />
    </div>
  );
};

TerminalTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  snapshotUrls: PropTypes.array.isRequired,
  ports: PropTypes.array.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    // marginTop: -12 * theme.spacing.unit,
  },
  tabsRoot: {
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: 2 * theme.spacing.unit,
    marginRight: 2 * theme.spacing.unit,
    marginBottom: -1,
  },
  tabsIndicator: {
    display: 'none',
  },
  tabsContainer: {
    alignItems: 'flex-end',
    paddingRight: theme.spacing.unit * 2,
    flex: 1,
  },
  tabRoot: {
    flex: 1,
    maxWidth: 'unset',
    backgroundColor: '#E3E5E8',
    border: '1px solid rgba(31, 51, 75, 0.15)',
    boxSizing: 'border-box',
    borderRadius: '5px 5px 0px 0px',
    textTransform: 'initial',
    height: 40,
    minHeight: 40,
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: -1,
    color: 'rgba(31, 51, 75, 0.4)',
    fillOpacity: 0.4,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
      fillOpacity: 1,
    },
    '&$tabSelected': {
      height: 50,
      backgroundColor: 'white',
      color: theme.palette.primary.main,
      borderBottom: 'unset',
      fillOpacity: 1,
    },
    '&:focus': {
      color: theme.palette.primary.main,
      fillOpacity: 1,
    },
  },
  tabSelected: {},
  label: {
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default compose(withStyles(styles))(TerminalTabs);
