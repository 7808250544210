export default theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.white.main,
  },
  currentShift: {
    color: theme.palette.secondary.main,
  },
  disabledButton: {
    opacity: 0.2,
    color: `${theme.palette.white.main} !important`,
  },
});
