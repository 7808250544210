import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import StatusIcon from '../../../../components/StatusIcon';
import cn from 'classnames';

export default class CellStatus extends Component {
  static propTypes = {
    currentTerminal: PropTypes.object,
    layout: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool.isRequired,
    isBroken: PropTypes.bool.isRequired,
    terminal: PropTypes.object.isRequired,
    shippingLine: PropTypes.object.isRequired,
    mappingShippingLine: PropTypes.array.isRequired,
    gateHours: PropTypes.object.isRequired,
  };
  static defaultProps = {
    isSelected: false,
    isBroken: false,
    gateHours: { is_open: null },
  };

  render() {
    const {
      currentTerminal,
      layout,
      classes,
      onClick,
      isSelected,
      isBroken,
      terminal,
      shippingLine,
      mappingShippingLine,
      gateHours,
    } = this.props;
    let status, warnings;
    if (
      !mappingShippingLine.find(
        m => m.port === terminal.key && m.shipping_line === shippingLine.key
      )
    ) {
      status = 'blocked';
      warnings = [];
    } else if (
      currentTerminal &&
      currentTerminal.availability[layout.day] &&
      currentTerminal.availability[layout.day][layout.shift] &&
      currentTerminal.availability[layout.day][layout.shift].status
    ) {
      const availability =
        currentTerminal.availability[layout.day][layout.shift];
      status = availability.status;
      warnings = availability.warnings;
    } else {
      status = null;
      warnings = [];
    }

    const generatedClasses = cn(classes.cell, {
      [classes.pointer]: onClick,
      [classes.selected]: isSelected,
      [classes.broken]: isBroken || terminal.no_data,
      [classes.close]: gateHours.is_open === false,
    });

    return (
      <TableCell align="center" onClick={onClick} className={generatedClasses}>
        {!isBroken && !terminal.no_data && (
          <StatusIcon status={status} warnings={warnings} />
        )}
      </TableCell>
    );
  }
}
