import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Tooltip from 'components/customDesign/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago';
import {
  convenient, // 'just now', '5 minutes ago', …
} from 'javascript-time-ago/gradation';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCurrentGateHour } from 'ducks/availabilities';
import cn from 'classnames';
import moment from 'moment';
import {
  ptscTerminalKey,
  matsonTerminalKey,
} from 'pages/T_Appointments/Components/NextAppointments/constants/next-appointments.constants';

JavascriptTimeAgo.locale(en);

const getMinFromNow = verboseDate =>
  (new Date() - moment(verboseDate).toDate()) / (1000 * 60);

function TerminalCellContent({ terminal, lrdData, classes, gateHours }) {
  const lrd = lrdData.find(d => d.port === terminal.key);
  const hour = gateHours;
  const { key } = terminal || {};
  return key === ptscTerminalKey || key === matsonTerminalKey ? (
    <div className={cn(classes.root)}></div>
  ) : (
    <div
      className={cn(classes.root, {
        [classes.closeRoot]: hour && hour.is_open === false,
      })}
    >
      {lrd && !terminal.no_data ? (
        <ReactTimeAgo
          timeStyle={{ gradation: convenient, flavour: 'short' }}
          date={moment(lrd.last_update).toDate()}
          container={({ children, verboseDate }) => (
            <div
              className={classes.container}
              style={{
                backgroundColor:
                  getMinFromNow(verboseDate) <= 10
                    ? 'rgba(188, 255, 190, 0.3)'
                    : 'inherit',
              }}
            >
              <Typography variant="caption" className={classes.lastRefresh}>
                {children}
              </Typography>
            </div>
          )}
        />
      ) : (
        <div className={classes.container}>
          <Tooltip
            title={
              <Typography variant="caption" className={classes.tooltipText}>
                This information cannot be retrieve right now. Please manually
                verify the instructions on terminal website. All BlueCargo Team
                apologize for any inconvenience caused.
              </Typography>
            }
          >
            <Typography variant="caption" className={classes.lastRefresh}>
              No data
            </Typography>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

const styles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    height: '100%',
  },
  closeRoot: {
    backgroundColor: theme.palette.alert.light,
  },
  lastRefresh: {
    width: '100%',
    margin: 'auto',
  },
  container: {
    height: '100%',
    paddingTop: 12,
  },
  tooltipText: {
    color: theme.palette.primary.main,
    opacity: 0.5,
  },
});

TerminalCellContent.propTypes = {
  terminal: PropTypes.object.isRequired,
  lrdData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  gateHours: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  gateHours: getCurrentGateHour(state, ownProps.terminal.key),
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(TerminalCellContent);
