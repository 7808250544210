const storage = window.localStorage;
export const LocalStorageUtil = {
  set(storageItemName, value) {
    const stringifiedValue = JSON.stringify(value);
    storage.setItem(storageItemName, stringifiedValue);
  },

  get(storageItemName) {
    const storageItemValue = storage.getItem(storageItemName);
    try {
      return JSON.parse(storageItemValue);
    } catch (error) {
      return storageItemValue;
    }
  },

  remove(storageItemName) {
    storage.removeItem(storageItemName);
  },
};

export const bustCache = async () => {
  const localCommit = LocalStorageUtil.get('latest_commit_sha1');
  const meta = await (await fetch('/meta.json')).json();
  const currentCommit = meta.latest_commit_sha1;

  if (localCommit !== currentCommit) {
    LocalStorageUtil.set('latest_commit_sha1', currentCommit);
    window.location.reload();
  }
};
