import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ModalWrapper.css';
import EA_background_blurred from 'assets/images/EA_background_blurred.png';

const getModalHeight = (modalClassName, cb) => {
  const modal = document.getElementsByClassName(modalClassName);
  if (modal && modal[0] && modal[0].offsetHeight) {
    cb(modal[0].offsetHeight);
  }
};

const MODAL_CLASS_NAME = 'react-modal-wrapper';

class ModalWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalHeight: null,
    };
    this.wrapperRef = React.createRef();
    this.isComponentMounted = true;
  }

  static propTypes = {
    width: PropTypes.number,
    onModalClose: PropTypes.func,
    children: PropTypes.object,
    overflowType: PropTypes.string,
    withoutBackground: PropTypes.bool,
    zIndexModal: PropTypes.string,
    zIndexBackground: PropTypes.string,
  };

  static defaultProps = {
    width: 478,
  };

  listenResize = e => {
    getModalHeight(MODAL_CLASS_NAME, height => {
      if (!this.isComponentMounted) return;
      this.setState({ modalHeight: height });
    });
  };

  handleClickOutside = event => {
    const { onModalClose } = this.props;
    const { target } = event || {};
    const { className } = target || {};
    const { baseVal } = className || {};

    if (this.wrapperRef && !this.wrapperRef.current.contains(target)) {
      if (onModalClose) {
        if (
          baseVal &&
          baseVal.indexOf &&
          baseVal.indexOf(
            'MuiSvgIcon-root MuiAutocomplete-BookAppointmentModal-icon'
          ) !== -1
        )
          return;
        if (
          className &&
          className.indexOf &&
          className.indexOf('MuiAutocomplete-') !== -1
        )
          return;
        if (
          className &&
          className.indexOf &&
          className.indexOf('book-appointment-modal-dropdown-group-label') !==
            -1
        )
          return;
        onModalClose();
      }
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.listenResize);
    window.addEventListener('mousedown', this.handleClickOutside);
    getModalHeight(MODAL_CLASS_NAME, height => {
      this.setState({ modalHeight: height });
    });
    document.body.style.overflow = 'hidden';
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.children, prevProps.children)) {
      getModalHeight(MODAL_CLASS_NAME, height => {
        this.setState({ modalHeight: height });
      });
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener('resize', this.listenResize);
    window.removeEventListener('mousedown', this.handleClickOutside);
    document.body.style.overflow = 'visible';
  }

  render() {
    const {
      children,
      width,
      overflowType,
      withoutBackground,
      zIndexModal,
      zIndexBackground,
    } = this.props;
    const { modalHeight } = this.state;

    const backgroundStyle = withoutBackground
      ? {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          position: 'fixed',
          left: '0px',
          right: '0px',
          top: '0px',
          bottom: '0px',
          zIndex: zIndexBackground ? zIndexBackground : '1',
        }
      : {
          position: 'fixed',
          left: '0px',
          right: '0px',
          top: '130px',
          bottom: '-130px',
          zIndex: zIndexBackground ? zIndexBackground : '1',
          backgroundImage: `url(${EA_background_blurred})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0 0',
          backgroundSize: 'cover',
        };

    return (
      <Fragment>
        <div style={backgroundStyle}></div>
        <div
          ref={this.wrapperRef}
          className={MODAL_CLASS_NAME}
          style={{
            backgroundColor: 'rgb(255, 255, 255)',
            outline: 'none',
            width: `${width}px`,
            padding: '20px 0px',
            borderRadius: '4px',
            position: 'fixed',
            left: '50%',
            top: `calc(40% - ${modalHeight / 2}px)`,
            marginLeft: `-${width / 2}px`,
            overflow: overflowType ? `${overflowType}` : 'auto',
            zIndex: zIndexModal ? zIndexModal : '2',
            animation: '0.3s ease 0s 1 normal none running showSweetAlert',
            display: 'block',
            maxHeight: '90%',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.15)',
          }}
        >
          <Fragment>{children}</Fragment>
        </div>
      </Fragment>
    );
  }
}

export default ModalWrapper;
