import Availability from './Availability';
import styles from './Availability.style';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  extractShippingLineFromURI,
  extractContainerTypeFromURI,
} from 'ducks/erlData';
import { setPorts } from 'ducks/erlData';
import { globalLoaderSelector } from 'ducks/loader';
import WithScrollTopButton from 'components/WithScrollTopButton';

const mapStateToProps = (state, ownProps) => ({
  isLoading: globalLoaderSelector(state),
  containerType: extractContainerTypeFromURI(state, ownProps),
  shippingLine: extractShippingLineFromURI(state, ownProps),
});

const mapDispatchToProps = dispatch => ({
  importData: () => dispatch(setPorts()),
});

export default compose(
  WithScrollTopButton,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Availability);
