import moment from 'moment';
import _ from 'lodash';

export const getWeekNumber = d => {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return [d.getUTCFullYear(), weekNo];
};

export const getDateDiff = (
  d1,
  d2,
  unit = 'days',
  round = false,
  startOfDay = false
) => {
  let momentD1 = moment(d1);
  let momentD2 = moment(d2);

  if (startOfDay) {
    momentD1 = momentD1.startOf('day');
    momentD2 = momentD2.startOf('day');
  }
  const diff = momentD1.diff(momentD2, unit);
  if (round) {
    return Math.floor(diff);
  }
  return diff;
};

export const objectKeysToSnakeCase = obj => {
  return Object.entries(obj).reduce((carry, [key, value]) => {
    carry[_.snakeCase(key)] = value;
    return carry;
  }, {});
};

export const objectKeysToCamelCase = obj => {
  if (!_.isObject(obj)) {
    return obj;
  }
  return Object.entries(obj).reduce((carry, [key, value]) => {
    if (_.isArray(value)) {
      carry[_.camelCase(key)] = value.map(v => objectKeysToCamelCase(v));
    } else {
      carry[_.camelCase(key)] = objectKeysToCamelCase(value);
    }
    return carry;
  }, {});
};

export const setUrlSearchParams = (params, clear = false) => {
  const url = new URL(window.location);

  if (clear) clearSearchParams(url);
  for (const [key, value] of Object.entries(_.omitBy(params, _.isNil))) {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        url.searchParams.delete(`${key}[]`);
      } else {
        url.searchParams.set(`${key}[]`, value);
      }
    } else {
      url.searchParams.set(key, value);
    }
  }
  window.history.pushState({}, '', url);
};

export const getUrlSearchParams = (defaultParams = {}) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  for (const key in params) {
    if (key.includes('[]')) {
      params[`${key.replace('[]', '')}`] = params[key].split(',');
      delete params[key];
    } else if (params[key] === 'true') {
      params[key] = true;
    } else if (params[key] === 'false') {
      params[key] = false;
    }
  }
  return {
    ...defaultParams,
    ...params,
  };
};

export const clearSearchParams = (ogUrl = null, excludeKeys = []) => {
  const url = ogUrl || new URL(window.location);
  const searchParamsObject = Object.fromEntries(url.searchParams.entries());

  for (const key in searchParamsObject) {
    if (excludeKeys.includes(key)) continue;
    url.searchParams.delete(key);
  }
  window.history.pushState({}, '', url);
};

export const joinArrayParams = value => {
  if (Array.isArray(value)) {
    return value.join(',');
  }
  return value;
};

export const readFileAsync = async file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
};

export const formatCurrencyNumber = (number, toFixed = 2) => {
  if (_.isNil(number)) return number;
  const formatedNumber = formatNumber(number.toFixed(toFixed));
  return `$${formatedNumber}`;
};

export const formatNumber = number => {
  if (_.isNil(number)) return number;
  return parseFloat(number).toLocaleString();
};

export const formatDate = (dateStr: string, dateFormat: string) => {
  if (_.isNil(dateStr)) return dateStr;
  return moment(dateStr).format(dateFormat);
};
