import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { validateJoinRequest } from 'services/clients/companyAdmin';
import { setError } from 'ducks/error';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { pageTitlesAndUrls } from 'constants/pages';

const validateToken = async (token, successCb, errorCb) => {
  try {
    await validateJoinRequest(token);
    successCb(true);
  } catch (e) {
    errorCb(e.message, e);
    successCb(true);
  }
};

const JoinRequestValidation = ({ setError }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    validateToken(token, setRedirect, setError);
  }, [token]);

  if (redirect) return <Navigate replace to={pageTitlesAndUrls.profile.path} />;
  return null;
};

JoinRequestValidation.propTypes = {
  setError: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setError: (message, error) => dispatch(setError(message, error)),
});

export default compose(connect(null, mapDispatchToProps))(
  JoinRequestValidation
);
