import { getAvailabilityCredentialsEnd } from 'services/clients/availability';

// Action Types
export const SET_AVAILABILITIES_CREDENTIALS =
  'core/api/v1/erl/availabilities/SET_AVAILABILITIES_CREDENTIALS';
export const CLEAR_AVAILABILITIES_CREDENTIALS =
  'core/api/v1/erl/availabilities/CLEAR_AVAILABILITIES_CREDENTIALS';

// Reducer
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AVAILABILITIES_CREDENTIALS:
      return action.payload;
    case CLEAR_AVAILABILITIES_CREDENTIALS:
      return initialState;
    default:
      return state;
  }
};

// Action creators
export const setAvailabilityCredentials = availabilityCredentials => {
  return {
    type: SET_AVAILABILITIES_CREDENTIALS,
    payload: availabilityCredentials,
  };
};

export const setClearAvailabilityCredentials = () => {
  return {
    type: CLEAR_AVAILABILITIES_CREDENTIALS,
  };
};

// Side effects
export const getAvailabilityCredentials = cb => async dispatch => {
  const data = await getAvailabilityCredentialsEnd();
  dispatch(setAvailabilityCredentials(data));
  if (cb) {
    cb(data);
  }
};
