import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GateScheduleTable from './GateScheduleTable';
import CredentialsSpinner from 'pages/Settings/Credentials/CredentialsSpinner';
import { GateScheduleService } from './services/gate-schedule.service';
import { tableIds } from './constants/gate-schedule.constants';

class GateSchedule extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    getGateSchedule: PropTypes.func.isRequired,
    clearMyGateSchedule: PropTypes.func.isRequired,
    gateSchedule: PropTypes.object.isRequired,
    refreshToken: PropTypes.bool,
    isGateScheduleLoading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { getGateSchedule } = this.props;
    getGateSchedule();
  }

  componentDidUpdate(prevProps) {
    const { refreshToken, getGateSchedule } = this.props;
    if (prevProps.refreshToken !== refreshToken) {
      getGateSchedule();
    }
  }

  componentWillUnmount() {
    const { clearMyGateSchedule } = this.props;
    clearMyGateSchedule();
  }

  handleDownloadClick = isNextWeek => {
    GateScheduleService.saveGateScheduleAsPDF(isNextWeek);
  };

  render() {
    const { gateSchedule, isGateScheduleLoading } = this.props;

    const { currentWeekData, nextWeekData } = gateSchedule;
    return (
      <>
        {isGateScheduleLoading && (
          <CredentialsSpinner customMessage={'Gate schedule loading...'} />
        )}
        <div className="gate-schedule">
          <div className="gate-schedule-header">
            <div className="gate-schedule-header-title">Gate Schedule</div>
          </div>
          {currentWeekData && (
            <GateScheduleTable
              tableId={tableIds.currentWeek}
              tableData={currentWeekData}
              onDownloadClick={this.handleDownloadClick}
            />
          )}
          {nextWeekData && (
            <GateScheduleTable
              isNextWeek
              tableId={tableIds.nextWeek}
              tableData={nextWeekData}
              onDownloadClick={this.handleDownloadClick}
            />
          )}
        </div>
      </>
    );
  }
}

export default GateSchedule;
