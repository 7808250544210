import React, { Component } from 'react';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { getVesselScheduleEnd } from '../../services/clients/vesselSchedule';
import { SegmentService } from '../../services/helpers/segment';
import PageTitle from 'components/PageTitle/PageTitle';
import VesselTable from './Components/VesselTable';
import styles from '../../styles/typography.module.css';
import moment from 'moment';
import { laTimezone } from 'services/helpers/data';

const DATE_FORMAT = 'YYYY-MM-DD HH:MM';

const VesselScheduleCell = ({ value }) => {
  return (
    <div className={`flex flex-col items-start py-4 leading-none text-dark`}>
      <h5 className=" text-gray-200 font-bold">{value ? value : '-'}</h5>
    </div>
  );
};

const VesselScheduleHeader = ({ children, colDef = {} }) => {
  const { headerName } = colDef;
  return (
    <h6 className="text-dark uppercase font-bold tracking-wider leading-4">
      {children ? children : headerName}
    </h6>
  );
};
const columns = [
  {
    field: 'vesselName',
    headerName: 'Vessel Name',
    sortable: true,
    renderHeader: VesselScheduleHeader,
    flex: 0.8,
    minWidth: 128,
    renderCell: params => (
      <div className={`flex flex-col items-start py-4 leading-none`}>
        <h5 className="text-dark font-bold">
          {params.row.vesselName ? params.row.vesselName : '-'}
        </h5>
      </div>
    ),
    cellClassName: 'bg-white',
  },
  {
    field: 'vesselImportName',
    sortable: true,
    headerName: 'Inbount Vessel Voyage',
    renderHeader: () => (
      <VesselScheduleHeader>Inbound Vessel Voyage</VesselScheduleHeader>
    ),
    flex: 0.4,
    minWidth: 96,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'vesselExportName',
    sortable: true,
    headerName: 'Outbound Vessel Voyage',
    renderHeader: () => (
      <VesselScheduleHeader>Outbound Vessel Voyage</VesselScheduleHeader>
    ),
    flex: 0.4,
    minWidth: 96,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'terminal',
    sortable: true,
    headerName: 'Terminal',
    renderHeader: VesselScheduleHeader,
    flex: 0.4,
    minWidth: 96,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'estimatedArrivalDate',
    sortable: true,
    headerName: 'Estimated Arrival',
    renderHeader: () => (
      <VesselScheduleHeader>Estimated Arrival</VesselScheduleHeader>
    ),
    flex: 0.4,
    minWidth: 128,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'actualArrivalDate',
    sortable: true,
    headerName: 'Actual Arrival',
    renderHeader: () => (
      <VesselScheduleHeader>Actual Arrival</VesselScheduleHeader>
    ),
    flex: 0.4,
    minWidth: 128,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'estimatedDepartureDate',
    sortable: true,
    headerName: 'Estimated Departure',
    renderHeader: () => (
      <VesselScheduleHeader>Estimated Departure</VesselScheduleHeader>
    ),
    flex: 0.4,
    minWidth: 128,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'actualDepartureDate',
    headerName: 'Actual Departure',
    sortable: true,
    renderHeader: () => (
      <VesselScheduleHeader>Actual Departure</VesselScheduleHeader>
    ),
    flex: 0.4,
    minWidth: 128,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'dryFirstReceiving',
    sortable: true,
    headerName: 'Dry First Receiving',
    renderHeader: () => (
      <VesselScheduleHeader>Dry First Receiving</VesselScheduleHeader>
    ),
    flex: 0.4,
    minWidth: 128,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'dryCutOff',
    headerName: 'Dry Cutoff',
    sortable: true,
    renderHeader: () => <VesselScheduleHeader>Dry Cutoff</VesselScheduleHeader>,
    flex: 0.4,
    minWidth: 128,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'reeferFirstReceiving',
    sortable: true,
    headerName: 'Reefer First Receiving',
    renderHeader: () => (
      <VesselScheduleHeader>Reefer First Receiving</VesselScheduleHeader>
    ),
    flex: 0.4,
    minWidth: 128,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'reeferCutOff',
    sortable: true,
    headerName: 'Reefer Cutoff',
    renderHeader: () => (
      <VesselScheduleHeader>Reefer Cutoff</VesselScheduleHeader>
    ),
    flex: 0.4,
    minWidth: 144,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
  {
    field: 'lastUpdate',
    sortable: true,
    headerName: 'Last Update',
    renderHeader: VesselScheduleHeader,
    flex: 0.4,
    minWidth: 256,
    renderCell: VesselScheduleCell,
    cellClassName: 'bg-white',
  },
];

class VesselSchedulePage extends Component {
  state = {
    loading: true,
    rows: [],
    visibleRows: [],
    showClear: false,
  };

  async componentDidMount() {
    const data = await getVesselScheduleEnd();
    const rows = transformVesselSchedule(data);
    this.setState({ rows, visibleRows: rows, loading: false });
  }

  handleInputChange(event) {
    const { value } = event.target;
    if (!value || value.trim() === '') {
      this.setState({ visibleRows: this.state.rows });
      return;
    }

    const visibleRows = this.state.rows.filter(
      row =>
        row.vesselName &&
        row.vesselName.toLowerCase().includes(value.toLowerCase())
    );
    SegmentService.trackEvent(
      'Vessel Schedule Searched',
      {
        vesselSearched: value,
      },
      { integrations: { Slack: false } }
    );
    this.setState({ visibleRows, showClear: true });
  }

  render() {
    const { visibleRows, loading, error, showClear } = this.state;
    const { pinnedColumns = true } = this.props;
    if (error) return 'Error';

    return (
      <div className={`w-full h-full ${styles.font}`}>
        <PageTitle title="Vessel Schedule" />
        <form
          noValidate
          className="w-full py-4 px-8 flex items-center justify-center rounded"
          onSubmit={event => event.preventDefault()}
        >
          <div className="relative w-full h-10 px-8 bg-white flex items-center border border-blue-400 rounded z-40">
            <div
              className={`absolute left-0 top-0 bottom-0 w-16 flex items-center justify-center border-r border-blue-400 rounded-l ${this
                .showClear && 'bg-blue-600'}`}
            >
              {showClear ? (
                <button
                  onClick={event => {
                    this.vesselInputEl.value = '';
                    this.setState({ showClear: false });
                    this.handleInputChange(event);
                  }}
                  className="flex space-x-1 items-center"
                >
                  <CloseIcon
                    style={{ fontSize: 12 }}
                    className="text-gray-200"
                  />
                  <h5 className="font-medium text-gray-200">Clear</h5>
                </button>
              ) : (
                <svg
                  className="fill-current text-gray-300"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="inherit"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0024 9.6605L4 12.949L7.01051 21H16.9943L20.0048 12.949L12.0024 9.6605ZM6.24809 7.14793H11.3722V8.31435L6.24809 10.2019V7.14793ZM17.7567 10.2019L12.6326 8.31435V7.14793H17.7567V10.2019ZM11.3722 5.87648H8.22653V4H11.3722V5.87648ZM15.7783 5.87648H12.6326V4H15.7783V5.87648Z"
                  />
                </svg>
              )}
            </div>
            <div className="px-12 h-full w-full flex items-center">
              <input
                ref={el => (this.vesselInputEl = el)}
                type="search"
                name="seach-vessels"
                placeholder="Search vessels"
                onChange={_.debounce(
                  event => this.handleInputChange(event),
                  500
                )}
                className="w-full h-9 outline-none font-regular placeholder-gray-200"
              />
            </div>
          </div>
        </form>
        <VesselTable
          columns={columns}
          visibleRows={visibleRows}
          loading={loading}
          pinnedColumns={pinnedColumns}
        />
      </div>
    );
  }
}

function transformVesselSchedule(backendResponse) {
  return Object.keys(backendResponse).reduce((acc, terminalId) => {
    backendResponse[terminalId].forEach(row => {
      const result = transformVessselRow(row, terminalId);
      acc.push(result);
    });
    return acc;
  }, []);
}

function transformVessselRow(inputRow, terminalId) {
  const { data: inputRowData, last_update } = inputRow;
  const lastUpdate = last_update
    ? laTimezone(last_update).format(DATE_FORMAT)
    : '';
  const camelCasedRow = Object.keys(inputRowData).reduce(
    (acc, key) => {
      const { previous_value, current_value } = inputRowData[key];
      const propertyName = _.camelCase(key);
      acc[propertyName] = current_value;
      // For the dot
      acc[`${propertyName}PreviousValue`] = previous_value;
      acc.id = Math.random();
      return acc;
    },
    { terminalId, lastUpdate, isNew: valueIsNew(last_update) }
  );

  const DATE_AND_TIME_SEPARATE_PROPS = [
    'dryFirstReceiving',
    'reeferFirstReceiving',
    'dryCutOff',
    'reeferCutOff',
  ];

  DATE_AND_TIME_SEPARATE_PROPS.forEach(prop => {
    // If we have both date & time
    if (camelCasedRow[`${prop}Date`] && camelCasedRow[`${prop}Time`]) {
      camelCasedRow[prop] = `${camelCasedRow[`${prop}Date`]} ${
        camelCasedRow[`${prop}Time`]
      }`;
      return;
    }

    // If we have just the date
    if (camelCasedRow[`${prop}Date`]) {
      camelCasedRow[prop] = camelCasedRow[`${prop}Date`];
    }
  });

  return camelCasedRow;
}

function valueIsNew(lastUpdate) {
  const utcLastUpdate = moment.utc(lastUpdate);
  const utcNow = moment.utc();
  const outdatedHours = moment(utcNow).diff(utcLastUpdate, 'hours');
  return outdatedHours < 23;
}

export default VesselSchedulePage;
