import moment from 'moment';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {
  tableIds,
  dateFormat,
  lastUpdateDateFormat,
} from '../constants/gate-schedule.constants';

export const GateScheduleService = {
  geDayOfWeek(date) {
    return moment(date, dateFormat.input).format(dateFormat.outputDayOfWeek);
  },

  geMonthDay(date) {
    return moment(date, dateFormat.input).format(dateFormat.outputMonthDay);
  },

  getStartEndDayOfWeek(isNextWeek) {
    const start = isNextWeek
      ? moment()
          .add(1, 'w')
          .startOf('isoWeek')
      : moment().startOf('isoWeek');
    const end = isNextWeek
      ? moment()
          .add(1, 'w')
          .endOf('isoWeek')
      : moment().endOf('isoWeek');
    const startFormated = start.format(dateFormat.startEndOfWeek);
    const endFormated = end.format(dateFormat.startEndOfWeek);
    const year = moment().year();
    return `${startFormated} - ${endFormated} ${year}`;
  },

  formatLastUpdateDate(lastUpdate) {
    if (!lastUpdate) return '-';
    const lastUpdateFormatted = moment(
      lastUpdate,
      lastUpdateDateFormat.input
    ).format(lastUpdateDateFormat.output);
    return `${lastUpdateFormatted} PST`;
  },

  saveGateScheduleAsPDF(isNextWeek) {
    const tableId = `#${isNextWeek ? tableIds.nextWeek : tableIds.currentWeek}`;
    const targetDiv = document.querySelector(tableId);
    html2canvas(targetDiv, { scrollY: -window.scrollY }).then(canvas => {
      let imgData = canvas.toDataURL('image/png');
      let imgWidth = 210;
      let pageHeight = 295;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let doc = new jsPDF('p', 'mm');
      let position = 0;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(`Gate_schedule_${isNextWeek ? 'next' : 'current'}_week.pdf`);
    });
  },

  checkIsDateToday(date) {
    return moment().isSame(date, 'day');
  },
};
