import React, { useMemo } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import SideNavigationLayout from 'layouts/SideNavigationLayout';
import { SIDE_NAVIGATION_CONTENT } from './utils/config';
import styles from '../../styles/typography.module.css';
import { addPackNameToPlanTitle } from 'constants/pages';
import { useUserContext } from 'common/contexts/UserContext';
import _ from 'lodash';

const Settings = () => {
  const location = useLocation();
  const { user } = useUserContext();

  const sideNavigationContent = useMemo(() => {
    return _.cloneDeep(SIDE_NAVIGATION_CONTENT).map(root => {
      root.sideNavigationLinks = root.sideNavigationLinks.map(item =>
        addPackNameToPlanTitle(item, user)
      );
      return root;
    });
  }, [user]);

  return (
    <div className={styles.font}>
      <SideNavigationLayout
        sideNavigationContent={sideNavigationContent}
        currentPath={location.pathname}
        isLogout={true}
      >
        <Outlet />
      </SideNavigationLayout>
    </div>
  );
};

export default Settings;
