export default theme => ({
  link: {
    fontSize: 10,
    textDecorationLine: 'underline',
    color: theme.palette.grey.link,
  },
  checkboxLabel: {
    fontSize: 10,
    color: theme.palette.grey.link,
  },
});
